import * as types from './types';

export function failFieldsMetaLoad(error) {
  return {
    type: types.FIELDS_META_FAILED
  };
}

export function loadFieldsMeta() {
  return {
    type: types.LOAD_FIELDS_META
  };
}

export function setLoadedFieldsMeta(meta) {
  return {
    payload: {
      meta
    },
    type: types.FIELDS_META_LOADED
  };
}
