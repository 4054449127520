import { connect } from 'react-redux';
import * as authActions from 'redux-modules/auth/actions';
import AutoLogout from "./AutoLogout";


function mapDispatchToProps(dispatch) {
  return {
    onInActivity: () => {
      dispatch(authActions.logout());
    }
  };
}

export default connect(null, mapDispatchToProps)(AutoLogout);
