import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import Collapse from '@material-ui/core/Collapse';
import Title from 'components/Title';
import { Switch } from 'components/buttons';
import Form from './Form';
import { FormContainer, FormItem, FormRow, FormSection } from 'components/styledComponents';
import { useTranslation } from 'hooks';

function PositionReference({ className, getValue, useInput }) {
  const { tr } = useTranslation();
  const { value: reference, onChange } = useInput('reference');
  const handleToggle = useCallback(event => onChange(event.target.checked ? {} : null), [onChange]);

  return (
    <FormContainer className={className}>
      <FormRow>
        <FormItem component={Title} label={tr('message.referenceQuestion')} />
        <FormItem
          checked={!!reference}
          component={Switch}
          leftLabel={tr('button.no')}
          rightLabel={tr('button.yes')}
          size="0"
          onChange={handleToggle}
        />
      </FormRow>
      <FormSection component={Collapse} in={!!reference} timeout="auto" unmountOnExit>
        <Form useInput={useInput} />
      </FormSection>
    </FormContainer>
  );
}

PositionReference.propTypes = {
  className: PropTypes.string,
  getValue: PropTypes.func.isRequired,
  useInput: PropTypes.func.isRequired
};

export default PositionReference;
