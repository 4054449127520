import { connect } from 'react-redux';
import * as resumeActions from 'redux-modules/resume/actions';
import * as resumeSelectors from 'redux-modules/resume/selectors';
import * as uiStateSelectors from 'redux-modules/uiState/selectors';
import CompetenceProfile from './CompetenceProfile';

function mapStateToProps(state) {
  return {
    competenceProfile: resumeSelectors.selectCompetenceProfile(state),
    language: uiStateSelectors.selectResumeLanguage(state),
    syncErrors: resumeSelectors.selectSyncError(state),
    syncing: resumeSelectors.selectSyncState(state)
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onSave: (data, failCallback) => dispatch(resumeActions.saveCompetenceProfile(data, failCallback))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CompetenceProfile);
