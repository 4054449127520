import styled from 'styled-components';
import { Button as BaseButton } from 'components/buttons';
import Link from 'components/Link';

export const Button = styled(BaseButton)`
  min-width: 150px;
  ${props => props.align === 'left' && 'margin-right: auto;'}
`;

export const CVLink = styled(Link)`
  font-size: ${props => props.theme.fontSize.xsmall};
  ${props =>
    props.disabled &&
    // @TODO add disabled state to Links
    `
      &&, && * {
        color: ${props.theme.color.disabled.text};
        pointer-events: none;
      }
  `}
`;

export const LinkText = styled.span`
  color: ${props => props.theme.color.primary.main};
  font-weight: ${props => props.theme.fontWeight.bold};
  margin-left: ${props => props.theme.spacing(1)};
  text-transform: uppercase;
`;

export const Section = styled.div`
  align-items: center;
  display: flex;
  flex-grow: 1;
  flex-wrap: ${props => props.wrap || 'wrap'};
  ${props => props.justify && `justify-content: ${props.justify};`}

  @media (max-width: 400px) {
    justify-content: center;
  }
`;

export const FooterItem = styled.div`
  align-items: center;
  display: flex;

  .MuiFormControlLabel-root {
    margin-left: 0;
    margin-right: 0;
  }
`;

export const FooterContent = styled.div`
  justify-content: center;
  display: flex;
  flex-direction: column;
  flex-wrap: ${props => props.wrap || 'wrap'};
  margin: ${props => props.theme.spacing(-1.5)};
  width: calc(100% + ${props => props.theme.spacing(3)});

  ${FooterItem} {
    margin: ${props => props.theme.spacing(1.5)};
  }
  ${Button} {
    @media (max-width: 400px) {
      flex: 1 1 100%;
    }
  }
`;

export const ActionsFooter = styled.div`
  border-top: 1px solid ${props => props.theme.color.grey[500]};
  box-sizing: border-box;
  margin: 0 ${props => props.theme.spacing(2.5)};
  min-width: 250px;
  padding: ${props => props.theme.spacing(2.5)} 0;
`;
