import styled from 'styled-components';

export const SvgIcon = styled.svg`
  display: inline-block;
  fill: currentColor;
  font-size: 1.5em;
  height: 1em;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  user-select: none;
  vertical-align: middle;
  width: 1em;
`;
