import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Container from '@material-ui/core/Container';
import { AtreusOffer, CardsContainer } from 'components/cards';
import { Section } from 'components/styledComponents';
import { useTranslation } from 'hooks';
import { Title } from './style';

const HEADER_PROPS = {
  size: 'base'
};

function Offers({ offers, onInit }) {
  const { t } = useTranslation();
  useEffect(() => {
    onInit();
  }, [onInit]);

  if (offers.length === 0) return null;

  return (
    <Section padding="5 0 4">
      <Container>
        <Title>{t('title.atreusOffers')}</Title>
        <CardsContainer>
          {offers.map((item, index) => (
            <AtreusOffer headerProps={HEADER_PROPS} key={index} offer={item} spacing="2.5" />
          ))}
        </CardsContainer>
      </Container>
    </Section>
  );
}

Offers.propTypes = {
  offers: PropTypes.array.isRequired,
  onInit: PropTypes.func.isRequired
};

export default Offers;
