import styled from 'styled-components';

export const Subtitle = styled.h3`
  font-size: ${props => props.theme.fontSize.large};
  margin: ${props => props.theme.spacing(0, 0, 2, 0)};
  text-align: left;
  padding: ${props => props.theme.spacing(1.5, 0)};
  ${props => props.underlined && 'border-bottom: 1px solid ' + props.theme.color.grey[500] + ';'}
  ${props => props.pointer && 'cursor: pointer;'}
`;
