import React from 'react';
import PropTypes from 'prop-types';
import { Container, Content, Label } from './style';

function DesktopItem({ className, content, label, weight }) {
  return (
    <Container className={className}>
      {label && <Label weight={weight}>{label}</Label>}
      {!label && <Content title={content}>
          {content || '-'}
        </Content>}
    </Container>
  );
}

DesktopItem.defaultProps = {
  weight: 'bold'
};

DesktopItem.propTypes = {
  className: PropTypes.string,
  content: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  label: PropTypes.string,
  weight: PropTypes.oneOf(['bold', 'normal', 'light']).isRequired
};

export default DesktopItem;
