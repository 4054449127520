import React from 'react';
import { RadioGroupStyled, Box, Text } from '../style';
import Options from '../Options';
import PropTypes from 'prop-types';

function QuestionContainer({ statement, name, useInput, handleChange }) {
  return (
    <Box>
      <Text>{statement}</Text>
      <RadioGroupStyled row name={name} {...useInput(name)} onChange={handleChange}>
        <Options />
      </RadioGroupStyled>
    </Box>
  );
}

QuestionContainer.propTypes = {
  statement: PropTypes.string,
  name: PropTypes.string,
  useInput: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired
};

export default QuestionContainer;
