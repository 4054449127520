import styled from 'styled-components';

export const Button = styled.button`
  align-items: center;
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  padding: 0;

  :focus {
    outline: none;
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: ${props => (props.togglerPosition === 'top' ? 'column-reverse' : 'column')};
  width: 100%;
`;

export const Label = styled.span`
  font-weight: ${props => props.theme.fontWeight.normal};
  margin-right: ${props => props.theme.spacing(0.5)};
`;

export const ToggleIcon = styled.span`
  font-size: 2em;
  transform: rotate(270deg);
`;

export const TogglerContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-end;
  padding-top: ${props => props.theme.spacing(3)};
`;
