import styled from 'styled-components';
import { Card as BaseCard } from 'components/cards';

export const Card = styled(BaseCard)`
  min-height: 200px;
`;

export const EmptySection = styled.div`
  border: 1px dashed ${props => props.theme.color.grey[500]};
  border-radius: ${props => props.theme.borderRadius.base};
  box-sizing: border-box;
  padding: ${props => props.theme.spacing(3)};
`;

export const EmptyText = styled.p`
  line-height: 1.5em;
  margin: 0;
`;
