import * as types from './types';

export function verify(data, failCallback) {
  return {
    payload: {
      failCallback,
      data
    },
    type: types.VERIFY_BILLING
  };
}

export function verifySuccess(data) {
  return {
    payload: {
      status: data.status,
      html: data.html
    },
    type: types.VERIFY_SUCCESS
  };
}

export function save(data, failCallback){
  return {
    payload: {
      failCallback,
      data
    },
    type: types.SAVE_BILLING
  }
}

export function saveSuccess() {
  return {
    payload: null,
    type: types.SAVE_SUCCESS
  };
}


export function reset(){
  return {
    payload: null,
    type: types.RESET_STATE
  }
}