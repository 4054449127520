import { call, put, takeLeading } from 'redux-saga/effects';
import apiClient from 'communication/apiClient';
import * as actions from './actions';
import * as types from './types';

export function* loadOffers() {
  try {
    const resp = yield call(apiClient.get, '/api/v1/manager/offers');
    if (resp && resp.data) {
      yield put(actions.setLoadedOffers(resp.data));
    } else {
      yield put(actions.failOffersLoad(resp));
    }
  } catch (error) {
    yield put(actions.failOffersLoad('error'));
  }
}

export default [takeLeading(types.LOAD_OFFERS, loadOffers)];
