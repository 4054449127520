import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import Collapse from '@material-ui/core/Collapse';
import { Plus } from 'components/icons';
import Title from 'components/Title';
import { Switch } from 'components/buttons';
import { FormContainer, FormItem, FormRow } from 'components/styledComponents';
import { useTranslation } from 'hooks';
import Form from './Form';
import { AddButton, TableGlobalStyle } from './style';

const EMPTY_ROLE = {
  dateTo: null,
  isActualEngagement: false
};

function OtherRoles({
  className,
  getValue,
  language,
  selectedExperience,
  useInput,
  workExperience,
  onAdd,
  onDelete
}) {
  const { t, tr } = useTranslation();
  const { value: sameCompanyRoles, onChange } = useInput('sameCompanyRoles');
  const newRoles = useMemo(() => (Array.isArray(sameCompanyRoles) ? sameCompanyRoles : []), [
    sameCompanyRoles
  ]);
  const oldRoles = useMemo(() => {
    if (selectedExperience) {
      return workExperience.reduce((roles, experience) => {
        const {
          companyId,
          companyName,
          dateFrom,
          dateTo,
          id,
          isActualEngagement,
          name
        } = experience;
        if (id !== selectedExperience.id && companyId === selectedExperience.companyId) {
          roles.push({
            companyName,
            dateFrom,
            dateTo,
            isActualEngagement,
            name
          });
        }
        return roles;
      }, []);
    }

    return [];
  }, [selectedExperience, workExperience]);
  const [displayRoles, setDisplayRoles] = useState(!!oldRoles.length);
  const handleAdd = useCallback(() => onAdd('sameCompanyRoles', EMPTY_ROLE), [onAdd]);
  const handleToggle = useCallback(
    event => {
      setDisplayRoles(event.target.checked);
      onChange(event.target.checked ? [EMPTY_ROLE] : []);
    },
    [onChange]
  );

  return (
    <FormContainer as="div" className={className}>
      <FormRow>
        <FormItem component={Title} label={tr('message.otherRoles')} />
        <FormItem
          checked={displayRoles}
          component={Switch}
          leftLabel={tr('button.no')}
          rightLabel={tr('button.yes')}
          size="0"
          onChange={handleToggle}
        />
      </FormRow>
      <Collapse in={!!displayRoles} timeout="auto" unmountOnExit>
        {newRoles.map((_, index) => (
          <Form
            basePath={`sameCompanyRoles[${index}]`}
            getValue={getValue}
            key={index}
            language={language}
            useInput={useInput}
            onDelete={() => onDelete(`sameCompanyRoles[${index}]`)}
          />
        ))}
        <AddButton
          color="default"
          contentFirst={true}
          label={t('button.addNewRole')}
          variant="outlined"
          onClick={handleAdd}
        >
          <Plus fontSize="small" />
        </AddButton>
        <FormRow>
          <FormItem>
            <TableGlobalStyle />
            <div className="table-wrapper">
              <div className="table-header">
                <div className="table-col">{tr('columnTitle.companyName')}:</div>
                <div className="table-col">{tr('columnTitle.jobTitle')}:</div>
                <div className="table-col">{tr('columnTitle.period')}:</div>
              </div>
              <div className="table-content">
                {oldRoles.map((role, index) => (
                  <div className="table-row" key={index}>
                    <div className="table-cell">{role.companyName || '-'}</div>
                    <div className="table-cell">
                      {role.name[language] ? role.name[language] : '-'}
                    </div>
                    <div className="table-cell">
                      {role.dateFrom} -{' '}
                      {role.isActualEngagement ? tr('label.present') : role.dateTo}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </FormItem>
        </FormRow>
      </Collapse>
    </FormContainer>
  );
}

OtherRoles.propTypes = {
  className: PropTypes.string,
  getValue: PropTypes.func.isRequired,
  language: PropTypes.string.isRequired,
  selectedExperience: PropTypes.object,
  useInput: PropTypes.func.isRequired,
  workExperience: PropTypes.array.isRequired,
  onAdd: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired
};

export default OtherRoles;
