import React from 'react';
import PropTypes from 'prop-types';
import { ProjectStatusTitle, MandateTerminatedContainer, RunningCandidateContainer } from './style';
import CandidateDetailedCard from './CandidateDetailedCard';

function MandateTerminated({ project }) {
  var candidate = null;
  project.candidates.map(item => {
    if (item.isChosen) {
      candidate = item;
    }
    return item;
  });
  return (
    <div>
      <ProjectStatusTitle>Hired Candidate:</ProjectStatusTitle>
      <MandateTerminatedContainer>
        <RunningCandidateContainer className="candidate-card">
          <CandidateDetailedCard candidate={candidate} short />
        </RunningCandidateContainer>
      </MandateTerminatedContainer>
    </div>
  );
}

MandateTerminated.propTypes = {
  project: PropTypes.object.isRequired
};

export default MandateTerminated;
