import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Trash } from 'components/icons';
import { useTranslation } from 'hooks';
import { ActionsFooter, Button, FooterContent, FooterMessage, Section } from './style';

function Footer({ actionLabel, onAction, handleClose }) {
  const { t } = useTranslation();

    return (
      <ActionsFooter key="actions-footer">
        <FooterContent wrap="wrap-reverse">
        <Section justify="flex-end">
            <Button label={t('button.cancel')}  variant="text" onClick={handleClose} />
              <Button
                color="danger"
                contentFirst={true}
                label={actionLabel}
                variant="text"
                onClick={onAction}
              >
                <Trash />
              </Button>
            </Section>
        </FooterContent>
      </ActionsFooter>
    );
  }

Footer.propTypes = {
  actionLabel: PropTypes.string.isRequired,
  onAction: PropTypes.func.isRequired,
};

export default Footer;
