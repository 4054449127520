import React from 'react';
import PropTypes from 'prop-types';
import { Close } from 'components/icons';
import { Chip } from './style';

function MultiValue(props) {
  return (
    <Chip
      deleteIcon={<Close {...props.removeProps} />}
      isFocused={props.isFocused}
      label={props.children}
      tabIndex={-1}
      // eslint-disable-next-line react/jsx-handler-names
      onDelete={props.removeProps.onClick}
    />
  );
}

MultiValue.propTypes = {
  children: PropTypes.node,
  isFocused: PropTypes.bool.isRequired,
  removeProps: PropTypes.shape({
    onClick: PropTypes.func.isRequired,
    onMouseDown: PropTypes.func.isRequired,
    onTouchEnd: PropTypes.func.isRequired
  }).isRequired,
  selectProps: PropTypes.object.isRequired
};

export default React.memo(MultiValue);
