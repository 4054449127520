import styled from 'styled-components';

export const Button = styled.button`
  align-items: center;
  /**
   * Background reset to none is needed in order to override Safari default button
   * background value (background-color: buttonface)
   */
  background: none;
  border-radius: ${props => props.theme.borderRadius.base};
  border: 1px solid transparent;
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  padding: ${props => props.theme.spacing(1)};
  transition: all ${props => props.theme.animation.standard};

  &:disabled,
  &[disabled] {
    cursor: initial;
    pointer-events: none;
  }

  ${props => {
    const color = props.theme.color[props.color];

    if (props.color === 'default') {
      return `
        // visited state needed until proper LinkButtons are created
        &, &:visited {
          color: ${color.main};
        }

        &:hover {
          color: ${props.theme.color.primary.main};
        }

        &:active {
          background: ${props.theme.color.primary.main};
          border-color: ${props.theme.color.primary.main};
          color: ${props.theme.color.primary.contrast};
        }

        &:disabled, &[disabled] {
          background: none;
          border-color: transparent;
          color: ${props.theme.color.disabled.text};
        }
      `;
    }

    return (
      (props.variant === 'text' &&
        `
      // visited state needed until proper LinkButtons are created
      &, &:visited {
        color: ${color.main};
      }

      &:hover {
        border-color: ${color.main};
      }

      &:active {
        background: ${color.main};
        border-color: ${color.main};
        color: ${color.contrast};
      }

      &:disabled, &[disabled] {
        background: none;
        border-color: transparent;
        color: ${props.theme.color.disabled.text};
      }
    `) ||
      (props.variant === 'lightText' &&
        `
      // visited state needed until proper LinkButtons are created
      &, &:visited {
        color: ${color.contrast};
      }

      &:hover {
        border-color: ${color.contrast};
      }

      &:active {
        background: ${color.contrast};
        border-color: ${color.contrast};
        color: ${color.main};
      }

      &:disabled, &[disabled] {
        background: none;
        border-color: transparent;
        color: ${props.theme.color.disabled.text};
      }
    `)
    );
  }}
  /**
   * -webkit-appearance reset to none is needed in order to override
   * Safari default -webkit-appearance: button
   */
  && {
    -webkit-appearance: none;
  }
`;
