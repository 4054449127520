import styled from 'styled-components';
import backgroundImage from './images/opportunity.jpg';

export const HeaderImage = styled.div`
  background: url(${backgroundImage});
  position: relative;
  height: 287px;
  flex-grow: 1;
  background-size: cover;
  padding: ${props => props.theme.spacing(4, 2.5, 2, 2.5)};
  box-sizing: border-box;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: space-between;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Description = styled.p`
  margin: ${props => `${props.theme.spacing(1)} 0 ${props.theme.spacing(2)}`};
`;

export const Subtitle = styled.div`
  color: ${props => props.theme.color.primary.contrast};
  font-size: ${props => props.theme.fontSize.xxl};
  font-weight: ${props => props.theme.fontWeight.bold};
  text-transform: uppercase;
  width: 400px;
  max-width: 100%;
`;
