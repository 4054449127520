import React from 'react';
import PropTypes from 'prop-types';
import { Redirect, Route } from 'react-router-dom';
import { AcceptTermsPage } from 'components/pages';
import AutoLogout from "components/AutoLogout";
import AppDataLoader from 'components/AppDataLoader';

function PrivateRoute({children, component: Component, isAuthenticated, showAgreement, ...rest}) {
    return (
        <>
            <AutoLogout/>
            <AppDataLoader />
            <Route
                {...rest}
                render={routeProps => {
                    if (typeof (showAgreement) == 'string') {
                        showAgreement = JSON.parse(showAgreement);
                    }
                    if (isAuthenticated === true) {
                        if (!!showAgreement !== true) {
                            return children || <Component {...routeProps} />
                        } else {
                            return <AcceptTermsPage/>
                        }
                    } else {
                        return <Redirect
                            to={{
                                pathname: '/login',
                                state: {from: routeProps.location}
                            }}
                        />
                    }
                }}
            />
        </>
    );
}


PrivateRoute.propTypes = {
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.node, PropTypes.object]),
  component: PropTypes.oneOfType([PropTypes.func, PropTypes.node, PropTypes.object]),
  isAuthenticated: PropTypes.bool.isRequired
};

export default PrivateRoute;
