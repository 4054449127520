import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import Dialog from 'components/Dialog';
import {map, forIn, uniqBy, concat, remove} from 'lodash';
import { useForm, useTranslation } from 'hooks';
import DeleteDialogFooter from '../../DeleteDialogFooter';
import {
  GroupInformation,
} from 'components/pages/ResumePage/sections/WorkExperience/Editor/forms';
import { Form } from './style';

const components = {
  Footer: DeleteDialogFooter
};



// @TODO cleanup the code from subcomponents of this editor
function GroupEditor({
  syncErrors,
  syncing,
  groupID,
  onClose,
  onDelete,
  onSaveGroup,
  listOfComp,
  availableCompaniesToGroup
}) {
  const { t, tr } = useTranslation();
  const didMountRef = useRef(false);

  remove(listOfComp.companies, function(currentObject) {
    return currentObject.compId === '';
  });
  remove(availableCompaniesToGroup, function(currentObject) {
    return currentObject.compId === '';
  });

  const companiesDOM = useMemo(
      () => map(listOfComp && listOfComp.companies, function(comp) {
        return {key: comp.compId,value: comp.companyName};
      }),
      [listOfComp]
  );

  var finalCompanies = useMemo(
      () => {
        const mergedArrays = concat(listOfComp.companies, availableCompaniesToGroup);
        return uniqBy(mergedArrays, function(v) {
          return v.compId
        });
      },
      [listOfComp, availableCompaniesToGroup]
  );

  const availableCompaniesDOM = useMemo(
      () => map(finalCompanies, function(comp) {
        return {key: comp.compId,value: comp.companyName};
      }),
      [listOfComp, availableCompaniesToGroup]
  );
  
  if (listOfComp) {
    listOfComp.companiesInGroup = groupID ? map(companiesDOM, 'key') : [];
    listOfComp.availableCompanies = finalCompanies;
  }

  const {getValue,  useInput, handleAdd, handleRemove, handleReset, handleSubmit} = useForm({
    initialValues: listOfComp,
    validationSchema: 'companyGroupSchema',
    onSubmit: onSaveGroup
  });

  const handleClose = useCallback(() => {
    handleReset();
    onClose();
  }, [handleReset, onClose]);

  useEffect(() => {
    if (didMountRef.current) {
      if (!syncErrors && !syncing) {
        handleClose();
      }
    } else {
      didMountRef.current = true;
    }
  }, [handleClose, syncErrors, syncing]);

  const footerProps = useMemo(() => {
    if (groupID) {
      return {
        onDelete: () => onDelete(groupID),
      };
    }
  }, [groupID, onDelete]);

  let formToRender = <Form as={GroupInformation} companiesList={availableCompaniesDOM} isExisting={groupID} useInput={useInput}/>

    return (
        <Dialog
            actionLabel={t('button.save')}
            components={components}
            disabled={syncing}
            footerProps={footerProps}
            open={true}
            syncing={syncing}
            title={tr('title.professionalExperience')}
            onAction={handleSubmit}
            onClose={handleClose}
        >
          {formToRender}
        </Dialog>
    )
}

GroupEditor.propTypes = {
  syncErrors: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  syncing: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSaveGroup: PropTypes.func.isRequired
};

export default GroupEditor;
