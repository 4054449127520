import styled from 'styled-components';
import Link from "components/Link";


export const DownloadLink = styled(Link)`
  font-size: ${props => props.theme.fontSize.xsmall};
`;
export const DownloadText = styled.span`
  font-weight: ${props => props.theme.fontWeight.bold};
  margin-left: ${props => props.theme.spacing(1)};
  text-transform: uppercase;
`;

export const ActionItem = styled.div`
  align-items: center;
  display: flex;
  float:right;
  @media (max-width: 660px) {
    float:left;
  }

  svg {
    font-size: 2em;
  }

`;

export const TermsHeaderText = styled.div`
  width: 100%;
  align-items: left;
  display: flex;
  flex-wrap: wrap;
  flex-flow: column;
  font-size:larger;
`;