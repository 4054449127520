import styled from 'styled-components';
import { Button as BaseButton } from 'components/buttons';

export const Button = styled(BaseButton)`
  max-width: 230px;
  width: 150px;
`;

export const ButtonDelProfile = styled(BaseButton)`
  padding:0;
  margin-right:auto;
  @media (max-width: 522px) {
      margin:auto;
    }
  
`;

export const FooterActions = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: ${props => props.theme.spacing(-1)};
  padding: ${props => props.theme.spacing(4)} 0;

  ${Button} {
    margin: ${props => props.theme.spacing(1)};
    @media (max-width: 400px) {
      flex-grow: 1;
    }
  }
`;
