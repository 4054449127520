import { withStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';

const AppMenuItem = withStyles(theme => {
  return {
    root: {
      '&:hover': {
        background: 'transparent'
      },
      '& .MuiTouchRipple-root': {
        display: 'none'
      },
      '&:not(:last-child)': {
        borderBottom: '1px solid #D8D8D8' //${props => props.theme.color.grey[400]};
      },
      padding: '0px',
      margin: theme.spacing(0, 3)
    }
  };
})(MenuItem);

export default AppMenuItem;
