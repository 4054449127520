import React from 'react';
import PropTypes from 'prop-types';

function DetailValue({ className, value, ...rest }) {
  return (
    <div className={className} title={value} {...rest}>
      {value || '-'}
    </div>
  );
}

DetailValue.propTypes = {
  className: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
};

export default DetailValue;
