import React, { useCallback, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import Dialog from 'components/Dialog';
import { RichTextField } from 'components/inputs';
import { FormContainer, FormItem, FormRow } from 'components/styledComponents';
import { useTranslation } from 'hooks';
import { Title } from './style';

function SubmitDialog({ className, opportunity, syncing, onClose, onSubmit }) {
  const { t } = useTranslation();
  const closeOnUpdate = useRef(false);
  const remarks = useRef();

  const handleRemarksChange = useCallback(event => {
    remarks.current = event;
  }, []);

  const handleSubmit = useCallback(() => {
    closeOnUpdate.current = true;
    onSubmit({ opportunity, remarks: remarks.current, timestamp: new Date() });
  }, [opportunity, remarks, onSubmit]);

  useEffect(() => {
    if (closeOnUpdate.current) {
      onClose();
    }
  }, [opportunity, onClose]);

  useEffect(() => {
    //Stop propagation of the same event on our custom field by attaching same event
    let eventsToStop = ['ArrowLeft', 'ArrowRight', 'ArrowDown', 'ArrowUp'];
    window.addEventListener(
      'keydown',
      e => {
        if (eventsToStop.indexOf(e.key) !== -1) {
          e.stopPropagation();
        }
      },
      true
    );
  }, []);

  return (
    <div className={className}>
      <Dialog
        actionLabel={t('button.sendApplicationNow')}
        disabled={syncing}
        open={true}
        syncing={syncing}
        title={t('title.applicationDetails')}
        onAction={handleSubmit}
        onClose={onClose}
      >
        <Title>{t('title.yourApplicationForThisOpp')}</Title>
        <p>{t('message.interestThankYou')}</p>
        <p>{t('message.useFieldBelowForApplication')}</p>
        <p>{t('message.untilThenUpdateCV')}</p>
        <p>{t('message.thankYouVeryMuch')}</p>
        <FormContainer className={className}>
          <FormRow>
            <FormItem
              component={RichTextField}
              id="opportunitySubmitRemarks"
              label={t('label.applicationBody')}
              value={remarks}
              onChange={handleRemarksChange}
            />
          </FormRow>
        </FormContainer>
      </Dialog>
    </div>
  );
}

SubmitDialog.propTypes = {
  className: PropTypes.string,
  opportunity: PropTypes.object.isRequired,
  syncing: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired
};

export default SubmitDialog;
