import { connect } from 'react-redux';
import * as metaSelectors from 'redux-modules/meta/selectors';
import ProjectView from './ProjectView';

function mapStateToProps(state) {
  return {
    indexedMeta: metaSelectors.selectIndexedMeta(state)
  };
}

export default connect(mapStateToProps)(ProjectView);
