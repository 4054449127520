import * as types from './types';

const initialState = {
  syncing: false,
  error: null,
  documents: null
};

function documents(state = initialState, action) {
  switch (action.type) {
    case types.FETCH_MANAGER_DOCUMENTS:
    case types.UPLOAD_MANAGER_DOCUMENT:
      return { ...state, syncing: true };
    case types.FETCH_MANAGER_DOCUMENTS_SUCCESS:
      return { ...state, syncing: false, documents: action.payload.documents };
    case types.RESET_MANAGER_DOCUMENTS_SYNCING:
    case types.UPLOAD_MANAGER_DOCUMENT_SUCCESS:
      return { ...state, syncing: false, error: null };
    default:
      return state;
  }
}

export default documents;
