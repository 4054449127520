import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import de from './de';
import en from './en';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    defaultNS: 'common',
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false
    },
    load: 'languageOnly',
    react: {
      transKeepBasicHtmlNodesFor: ['br', 'em', 'strong', 'i']
    },
    resources: {
      de,
      en
    }
  });

export default i18n;
