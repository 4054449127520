/**
 * @TODO add helpers for all conversion types and different color actions
 * see LESS helpers as examples https://github.com/less/less.js/blob/master/lib/less/functions/color.js
 */
function parseHexToRgb(hex) {
  let red = 0,
    green = 0,
    blue = 0;

  if (hex.length === 4) {
    red = '0x' + hex[1] + hex[1];
    green = '0x' + hex[2] + hex[2];
    blue = '0x' + hex[3] + hex[3];
  } else if (hex.length === 7) {
    red = '0x' + hex[1] + hex[2];
    green = '0x' + hex[3] + hex[4];
    blue = '0x' + hex[5] + hex[6];
  }
  return {
    red: +red,
    green: +green,
    blue: +blue
  };
}

function parseRgbToHsl(rgb) {
  let sep = rgb.indexOf(',') > -1 ? ',' : ' ';
  rgb = rgb.substr(4).split(')')[0].split(sep);

  // convert %s to 0–255
  for (let R in rgb) {
    let r = rgb[R];
    if (r.indexOf('%') > -1) rgb[R] = Math.round((r.substr(0, r.length - 1) / 100) * 255);
  }

  // make red, green, and blue fractions of 1
  let red = rgb[0] / 255,
    green = rgb[1] / 255,
    blue = rgb[2] / 255,
    // find greatest and smallest channel values
    cmin = Math.min(red, green, blue),
    cmax = Math.max(red, green, blue),
    delta = cmax - cmin,
    hue = 0,
    saturation = 0,
    lightness = 0;

  // calculate hue
  // no difference
  if (delta === 0) hue = 0;
  // red is max
  else if (cmax === red) hue = ((green - blue) / delta) % 6;
  // green is max
  else if (cmax === green) hue = (blue - red) / delta + 2;
  // blue is max
  else hue = (red - green) / delta + 4;

  hue = Math.round(hue * 60);

  // make negative hues positive behind 360°
  if (hue < 0) hue += 360;

  // calculate lightness
  lightness = (cmax + cmin) / 2;

  // calculate saturation
  saturation = delta === 0 ? 0 : delta / (1 - Math.abs(2 * lightness - 1));

  // multiply lightness and saturation by 100
  saturation = +(saturation * 100).toFixed(1);
  lightness = +(lightness * 100).toFixed(1);

  return { hue, saturation, lightness };
}

export function hexToRgb(hex) {
  const { red, green, blue } = parseHexToRgb(hex);
  return `rgb(${red}, ${green}, ${blue})`;
}

export function hexToRgba(hex, alpha = 1) {
  const { red, green, blue } = parseHexToRgb(hex);
  return `rgba(${red}, ${green}, ${blue}, ${alpha})`;
}

export function rgbToHsl(rgb) {
  const { hue, saturation, lightness } = parseRgbToHsl(rgb);
  return `hsl(${hue}, ${saturation}%, ${lightness}%)`;
}

export function hexToHsl(hex) {
  return rgbToHsl(hexToRgb(hex));
}

// @TODO improve this, it should return hex instead of hsl
export function lightenDarkenColor(hex, percent) {
  let { hue, saturation, lightness } = parseRgbToHsl(hexToRgb(hex));
  lightness += (lightness * percent) / 100;
  return `hsl(${hue}, ${saturation}%, ${lightness}%)`;
}

export function updateColorLight(hex, lightPercent) {
  let { hue, saturation } = parseRgbToHsl(hexToRgb(hex));
  return `hsl(${hue}, ${saturation}%, ${lightPercent}%)`;
}
