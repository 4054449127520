import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import Dialog from 'components/Dialog';
import { useTranslation } from 'hooks';
import DeleteDialogFooter from '../DeleteDialogFooter';
import { FormContainer, FormItem, FormRow } from 'components/styledComponents';
import {
  RichTextField
} from 'components/inputs';

import placeholderUserImage from './tick.svg';
import { Image } from './style';

const components = {
  Footer: DeleteDialogFooter
};

function Editor({syncing, syncErrors, onClose, onDelete, onLogout} ) {
  const { t, tr } = useTranslation();
  const closeOnUpdate = useRef(false);
  const [remarks, setRemarks] = useState('');
  const [confirmationBox, setconfirmationBox] = useState(false);
  const history = useHistory();

  const handleRemarksChange = useCallback(event => {
    setRemarks(event);
  }, []);

  const handleSubmit = useCallback(() => {
    
    onDelete({ remarks});
    closeOnUpdate.current = true;
  }, [remarks, onDelete]);

  useEffect(() => {
    if (closeOnUpdate.current && !syncing) {
      setconfirmationBox(true);   
    }
  }, [onClose, syncErrors, syncing]);

  const handleClose = useCallback(() => {
    onClose();
    if (confirmationBox) {history.push('/'); onLogout();}
  }, [onClose, confirmationBox]);

  const footerProps = {
    handleClose
  }
  if (confirmationBox) {
 return ( 
    <Dialog
      actionLabel={t('button.ok')}
      component="button"
      open={true}
      syncing={syncing}
      
      title={t('title.deleteMyProfile')}
      onAction={handleClose}
      onClose={handleClose}
    >
    <Image
      size="large"
      src={placeholderUserImage}
    />
    <p>{t('message.deletionRequestSubmitted')}</p>
    <p>{t('message.deletionConfirmationEmail')}</p>
    
    </Dialog>
    );
  } else {
    return ( 
      <Dialog
        actionLabel={t('button.deleteMyProfileNow')}
        components={components}
        open={true}
        syncing={syncing}
        footerProps={footerProps}
        title={t('title.deleteMyProfile')}
        onAction={handleSubmit}
        onClose={handleClose}
      >
        <FormItem>
        <p>{t('message.deleteQuestion')}</p>
        <p>{t('message.reasonOfDeletion')}</p>
        <p>{t('message.ensuredDeletion')}</p>
        <p>{t('message.manyThanks')}</p>
        </FormItem>
          <FormContainer>
            <FormRow>
              <FormItem
                component={RichTextField}
                id="profileDeletionReason"
                label={t('label.deletionReason')}
                value={remarks}
                onChange={handleRemarksChange}
              />
            </FormRow>
          </FormContainer>
      </Dialog>
    );
  }
}

Editor.propTypes = {
  onClose: PropTypes.func.isRequired,
  syncErrors: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  syncing: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default Editor;
