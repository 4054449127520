import React, { useCallback, useMemo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import TextField from 'components/inputs/TextField';
import { LanguageSelector } from './style';

function MultiLanguageTextField({ id, InputProps, language, value, onChange, onAddSync, ...rest }) {
  const [lang, setLang] = useState(language);
  const memoValue = useMemo(() => (value && typeof value === 'object' && value[lang]) || '', [
    lang,
    value
  ]);

  // RTEXT-859
  useEffect(() => {
    if (!value) {
      onAddSync && onAddSync(id, { de: '', en: '' });
    }
  }, []);

  const handleChange = useCallback(
    event => onChange({ ...(value || {}), [lang]: event.target.value }),
    [lang, value, onChange]
  );

  const handleLanguageChange = useCallback(
    newLang => {
      setLang(newLang);
      // RTEXT-859
      if (value) {
        onChange({ ...(value || {}), multiLangFieldActiveLang: newLang });
      } else {
        onChange({ de: '', en: '', multiLangFieldActiveLang: newLang });
      }
    },
    [onChange, value]
  );

  return (
    <TextField
      InputProps={{
        ...InputProps,
        endAdornment: <LanguageSelector selectedLanguage={lang} onChange={handleLanguageChange} />
      }}
      {...rest}
      value={memoValue}
      onChange={handleChange}
    />
  );
}

MultiLanguageTextField.defaultProps = {
  InputProps: {},
  value: {}
};

MultiLanguageTextField.propTypes = {
  InputProps: PropTypes.object,
  language: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
  onChange: PropTypes.func.isRequired,
  id: PropTypes.string,
  onAddSync: PropTypes.func
};

export default React.memo(MultiLanguageTextField);
