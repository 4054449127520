import { connect } from 'react-redux';
import { EMPTY_RESUME } from 'enums';
import * as metaSelectors from 'redux-modules/meta/selectors';
import * as uiStateSelectors from 'redux-modules/uiState/selectors';
import Resume from './Resume';

function mapStateToProps(state, ownProps) {
  return {
    indexedMeta: ownProps.indexedMeta || metaSelectors.selectIndexedMeta(state),
    language: ownProps.language || uiStateSelectors.selectLanguage(state),
    resume: ownProps.resume || EMPTY_RESUME
  };
}

export default connect(mapStateToProps, null)(Resume);
