import styled from 'styled-components';

export const StyledLink = styled.a`
  text-decoration: none;

  &,
  &:active,
  &:link,
  &:visited {
    color: ${props => {
      const color = props.theme.color[props.color];
      return color ? color.main : props.color;
    }};
  }
  /* disabled needs to remain last, in case there is also another state present (in that case just the last remains) */
  &:disabled,
  &[disabled] {
    color: ${props => props.theme.color.disabled.text};
    cursor: initial;
    pointer-events: none;
  }
`;
