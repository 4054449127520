import React from 'react';
import PropTypes from 'prop-types';
import MuiSnackbar from '@material-ui/core/Snackbar';
import SnackbarContent from './SnackbarContent';

function Snackbar({ message, variant, onClose, ...rest }) {
  return (
    <MuiSnackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left'
      }}
      autoHideDuration={6000}
      onClose={onClose}
      {...rest}
    >
      <SnackbarContent message={message} variant={variant} onClose={onClose} />
    </MuiSnackbar>
  );
}

Snackbar.propTypes = {
  message: PropTypes.node,
  variant: PropTypes.oneOf(['success', 'warning', 'error', 'info']).isRequired,
  onClose: PropTypes.func
};

export default Snackbar;
