import styled from 'styled-components';

const DAY_SIZE = '36px';

export const DayContainer = styled.button`
  -webkit-appearance: none;
  align-items: center;
  border: 0;
  cursor: ${props => props.disablePointer ? 'default' : 'pointer'};
  display: flex;
  /**
   * Height must be "36px - twice the vertical margin value" due to some hardcoded values
   * from the 3rd party calendar library combined with absolute positioning
   */
  height: ${props => `calc(${DAY_SIZE} - ${props.theme.spacing(2 * 0.25)})`};
  justify-content: center;
  flex : 1 1;
  margin: ${props => props.theme.spacing(0.25, 0)};
  outline: 0;
  padding: 0;
  text-decoration: none;
  width: ${DAY_SIZE};
  ${props => props.disabled && 'pointer-events: none;'}
  :focus {
    background-color: ${props => props.theme.color.grey[400]};
  }
  :hover {
    background-color: ${props => props.theme.color.grey[400]};
  }

  && {
    ${props => props.backgroundColor && `background-color: ${props.backgroundColor};`}
    ${props => props.textColor && `color: ${props.textColor};`}
    ${props =>
      props.disabled &&
      `
      background-color: transparent;
      color: ${props.theme.color.disabled.transparentText};
      cursor: not-allowed;
    `}
  }
`;

export const CalendarContainer = styled.div`
  display: inline-block;
  overflow: hidden;
`;

export const Side = styled.div`
  flex: 0 0 10px;
  margin: ${props => props.theme.spacing(0.25, 0)};
  ${props => props.backgroundColor && `background-color: ${props.backgroundColor};`}
`;
