import React from 'react';
import PropTypes from 'prop-types';
import { ElectedCandidateCard, Link } from './style';
import Typography from '@material-ui/core/Typography';
import { CheckMark } from 'components/icons';

function CandidateDetailedCard({ candidate, short }) {
  return (
    <ElectedCandidateCard>
      <div className="row candidate-info">
        <img alt="candidate_photo" src={candidate.photo} />
        <div className="content">
          <Typography className="title" variant="h6">
            {candidate.name}
          </Typography>
          <p>
            {candidate.city} ({candidate.country})
          </p>
        </div>
        <CheckMark />
      </div>
      {!short && (
        <div
          className="row executiveSummary"
          dangerouslySetInnerHTML={{ __html: candidate.executiveSummary }}
        />
      )}
      <div className="row viewDetails">
        <Link to="">VIEW DETAILS</Link>
      </div>
    </ElectedCandidateCard>
  );
}

CandidateDetailedCard.propTypes = {
  candidate: PropTypes.object.isRequired,
  short: PropTypes.bool
};

export default CandidateDetailedCard;
