import { connect } from 'react-redux';
import * as resumeActions from 'redux-modules/resume/actions';
import * as authActions from 'redux-modules/auth/actions.js';
import * as authSelectors from 'redux-modules/auth/selectors';
import * as resumeSelectors from 'redux-modules/resume/selectors';
import * as uiStateSelectors from 'redux-modules/uiState/selectors';
import RegistrationPage from './RegistrationPage';

function mapStateToProps(state) {
  return {
    language: uiStateSelectors.selectLanguageCode(state),
    loading: authSelectors.selectLoadState(state),
    personalDetails: resumeSelectors.selectPersonalDetails(state),
    syncErrors: resumeSelectors.selectSyncError(state),
    syncing: authSelectors.selectSyncState(state),
    registerStatus: authSelectors.selectRegisterState(state),
    isNewUserEmail: authSelectors.selectValidityStatus(state),
    isAutomaticRelease: authSelectors.selectIsAutomaticRelease(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    // onSave: (data, failCallback) => dispatch(resumeActions.savePersonalDetails(data, failCallback))
    onSave: (data, failCallback) => dispatch(authActions.register(data, failCallback)),
    onStepOneNext: (data, failCallback) => dispatch(authActions.checkEmail(data, failCallback))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(RegistrationPage);
