import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import Select, { createFilter } from 'react-select';
import { useMuiError, useTranslation } from 'hooks';
import * as components from './components';
import { baseStyle } from './style';
import { defaultMuiProps } from '../config';

function getValue(indexedOptions, initialValue) {
  if (initialValue) {
    return Array.isArray(initialValue)
      ? initialValue.map(value => indexedOptions[value])
      : indexedOptions[initialValue];
  }

  return initialValue;
}

const getOptionLabel = option => option.value;
const getOptionValue = option => option.key;

const baseFilter = createFilter();
// @TODO clean this and move menu close handlers inside a custom menu component
// @TODO move all TextField related props in a single prop (object)
function SelectField({
  className,
  classNamePrefix,
  controlShouldRenderValue,
  disabled,
  error,
  filterOption,
  getOptionLabel,
  getOptionValue,
  hideSelectedOptions,
  isMulti,
  maxSelections,
  options,
  value,
  onChange,
  ...rest
}) {
  const { t } = useTranslation();
  const [openedMenu, setOpenedMenu] = useState(false);

  const customFilterOption = useCallback(
    (...args) => {
      let result = baseFilter.apply(void 0, args);
      if (filterOption) {
        result = result && filterOption.apply(void 0, args);
      }
      return result;
    },
    [filterOption]
  );
  const handleChange = useCallback(
    values => {
      if (onChange) {
        onChange(
          isMulti
            ? (values || []).map(value => getOptionValue(value))
            : values && getOptionValue(values)
        );
      }
    },
    [getOptionValue, isMulti, onChange]
  );
  const handleMenuClose = useCallback(() => setOpenedMenu(false), [setOpenedMenu]);
  const handleMenuOpen = useCallback(() => setOpenedMenu(true), [setOpenedMenu]);

  const closeOnScroll = useMemo(
    () =>
      openedMenu
        ? event =>
            !(
              event.target &&
              event.target.classList &&
              event.target.classList.contains(`${classNamePrefix}__menu-list`)
            )
        : () => false,
    [classNamePrefix, openedMenu]
  );
  const indexedOptions = useMemo(
    () =>
      options.reduce((indexedOptions, option) => {
        indexedOptions[getOptionValue(option)] = option;
        return indexedOptions;
      }, {}),
    [getOptionValue, options]
  );
  const parsedValue = useMemo(() => getValue(indexedOptions, value), [indexedOptions, value]);
  // @TODO find a better way for maximum selection limit
  const optionsProps = useMemo(() => {
    if (isMulti && value.length >= maxSelections) {
      return {
        noOptionsMessage: () => t('message.maxSelections'),
        options: []
      };
    } else {
      return {
        noOptionsMessage: () => t('message.noOptions'),
        options
      };
    }
  }, [isMulti, maxSelections, options, t, value]);

  return (
    <Select
      className={className}
      classNamePrefix={classNamePrefix}
      closeMenuOnScroll={closeOnScroll}
      components={components}
      controlShouldRenderValue={controlShouldRenderValue}
      isSearchable={options.length > 10}
      filterOption={customFilterOption}
      getOptionLabel={getOptionLabel}
      getOptionValue={getOptionValue}
      hideSelectedOptions={hideSelectedOptions}
      isDisabled={disabled}
      isMulti={isMulti}
      menuPlacement="auto"
      menuPosition="fixed"
      placeholder=""
      styles={baseStyle}
      TextFieldProps={{
        ...defaultMuiProps,
        disabled: disabled,
        fullWidth: true,
        margin: 'none',
        InputLabelProps: {
          shrink: true
        },
        ...useMuiError(error),
        ...rest
      }}
      value={parsedValue || ""}
      onChange={handleChange}
      onMenuClose={handleMenuClose}
      onMenuOpen={handleMenuOpen}
      {...optionsProps}
      {...rest}
    />
  );
}

SelectField.defaultProps = {
  classNamePrefix: 'select',
  getOptionLabel: getOptionLabel,
  getOptionValue: getOptionValue,
  options: []
};

SelectField.propTypes = {
  className: PropTypes.string,
  classNamePrefix: PropTypes.string.isRequired,
  controlShouldRenderValue: PropTypes.bool,
  disabled: PropTypes.bool,
  error: PropTypes.string,
  filterOption: PropTypes.func,
  getOptionLabel: PropTypes.func.isRequired,
  getOptionValue: PropTypes.func.isRequired,
  hideSelectedOptions: PropTypes.bool,
  isMulti: PropTypes.bool,
  maxSelections: PropTypes.number,
  options: PropTypes.array.isRequired,
  value: PropTypes.oneOfType([PropTypes.array, PropTypes.string, PropTypes.object]),
  onChange: PropTypes.func
};

export default React.memo(SelectField);
