import React, { useState, useRef, useEffect } from 'react';
import { Paper, MenuItem, Popper } from '@material-ui/core';
import PropTypes from 'prop-types';
import { Button } from './style';

const DropdownMenu = ({ name, options, language, handleClose }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const divRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = event => {
      if (!divRef?.current?.contains(event.target)) {
        setAnchorEl(null);
      }
    };
    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [divRef]);

  const handleClick = event => {
    if (anchorEl?.contains(event.target)) {
      setAnchorEl(null);
    } else {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  return (
    <div ref={divRef} style={{ display: 'flex', flex: 1 }}>
      <Button color="primary" variant="outlined" onClick={handleClick}>
        {name}
      </Button>
      <Popper open={Boolean(anchorEl)} anchorEl={anchorEl} placement="bottom-center">
        <Paper>
          {options.map(({ key, value }) => (
            <MenuItem
              key={key}
              onClick={() => {
                handleClose && handleClose(key, language);
                handleCloseMenu();
              }}
            >
              {value}
            </MenuItem>
          ))}
        </Paper>
      </Popper>
    </div>
  );
};

DropdownMenu.propTypes = {
  name: PropTypes.string,
  options: PropTypes.array,
  language: PropTypes.string,
  handleClose: PropTypes.func
};

export default DropdownMenu;
