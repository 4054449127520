import React from 'react';
import PropTypes from 'prop-types';
import { Icon, Text, TitleWrapper, SubTitle } from './style';

function Title({ className, component, icon, label, size, subLabel, color }) {
  return (
    <TitleWrapper as={component} className={className} size={size}>
      {icon && <Icon as={icon} fontSize={size} />}
      <Text color={color}>
        {label}&nbsp;<SubTitle>{subLabel && '(' + subLabel + ')'}</SubTitle>{' '}
      </Text>
    </TitleWrapper>
  );
}

Title.defaultProps = {
  size: 'default'
};

Title.propTypes = {
  className: PropTypes.string,
  component: PropTypes.oneOfType([PropTypes.func, PropTypes.node, PropTypes.object]),
  icon: PropTypes.oneOfType([PropTypes.func, PropTypes.node, PropTypes.object]),
  label: PropTypes.string,
  size: PropTypes.oneOf(['small', 'default', 'large']).isRequired
};

export default Title;
