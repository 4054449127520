import styled from 'styled-components';

export const CompanyDetails = styled.span`
  word-break: break-all;
  ${props => props.weight && `font-weight: ${props.weight};`}
  ${props => props.size && `font-size: ${props.size};`}
`;

export const CompanyLabel = styled.span`
  background-color: ${props => (props.black ? props.theme.color.background.dark : props.color)};
  border-radius: ${props => props.theme.borderRadius.large};
  float: left;
  width: 102px;
  text-align: center;
  color: ${props => props.theme.color.primary.contrast};
  font-size: ${props => props.theme.fontSize.xsmall};
  margin-right: ${props => props.theme.spacing(0.5)};
  padding: ${props => props.theme.spacing(0.25, 1)};
  text-transform: uppercase;
`;

export const Duration = styled.span`
  font-size: ${props => props.theme.fontSize.small};
  font-weight: ${props => props.theme.fontWeight.bold};
`;

export const ExtraDetails = styled.span`
  font-size: ${props => props.theme.fontSize.small};
`;

export const Text = styled.p`
  margin: 0;
`;
export const TextSpan = styled.span`
  margin: 0;
`;

export const TextWrapper = styled.div`
  flex-grow: 1;
  line-height: 1.5em;
  // padding: ${props => props.theme.spacing(1)};
  display: flex;
  align-items: center;
`;
export const TextWrapperTest = styled.div`
  flex-grow: 1;
  line-height: 1.5em;
  // padding: ${props => props.theme.spacing(1)};
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const DottedHR = styled.div`
  border-top: 2px dotted #CCCCCC;
`;

export const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;
