import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import Link from 'components/Link';
import ProfilePicture from 'components/ProfilePicture';
import { useBigScreen, useTranslation } from 'hooks';
import { AppMenu, AppMenuItem } from './AppMenu';
import {
  AccountLink,
  Anchor,
  ListItem,
  LogoutListItem,
  ManagerContainer,
  ManagerImage,
  ManagerName,
  MenuContainer
} from './style';
import {ActionItem} from "../../OpportunitiesTable/dialogs/NDADialog/style";

function TopRightMenu({ indexedMeta, personalDetails, onLogout }) {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const history = useHistory();

  const handleClick = useCallback(
    event => {
      setAnchorEl(event.currentTarget);
    },
    [setAnchorEl]
  );
  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, [setAnchorEl]);

  const handleLogout = useCallback(() => {
    history.push('/');
    onLogout && onLogout();
  }, [history, onLogout]);

  const fullName = useMemo(() => {
    if (!personalDetails) return null;
    const fullName = [];
    indexedMeta &&
      indexedMeta.salutations &&
      personalDetails.salutation &&
      fullName.push(indexedMeta.salutations[personalDetails.salutation]);
    indexedMeta &&
      indexedMeta.titles &&
      personalDetails.title &&
      fullName.push(indexedMeta.titles[personalDetails.title]);
    personalDetails.firstName && fullName.push(personalDetails.firstName);
    personalDetails.lastName && fullName.push(personalDetails.lastName);
    return fullName.join(' ');
  }, [personalDetails, indexedMeta]);

  return (
    <MenuContainer>
      <Anchor onClick={handleClick}>
        <ManagerImage as={ProfilePicture} size="small" src={personalDetails.photo} />
      </Anchor>
      <AppMenu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        width="100%"
        onClose={handleClose}
      >
        <ManagerContainer>
          <ManagerImage as={ProfilePicture} size="medium" src={personalDetails.photo} />
          <AccountLink to="/account">
            <ManagerName>{fullName}</ManagerName>
          </AccountLink>
        </ManagerContainer>
        {/* @TODO find a better way, it should be an anchor inside an li */}
        <AppMenuItem component={Link} to="/account" onClick={handleClose}>
          <ListItem>{t('menu.myProfile')}</ListItem>
        </AppMenuItem>
        <AppMenuItem component={Link} to="/documents" onClick={handleClose}>
            <ListItem>{t('menu.myDocuments')}</ListItem>
        </AppMenuItem>
        {!useBigScreen() && [
          <AppMenuItem component={Link} key="dashboard" to="/dashboard" onClick={handleClose}>
            <ListItem>{t('link.home')}</ListItem>
          </AppMenuItem>,
          <AppMenuItem component={Link} key="management" to="/management" onClick={handleClose}>
            <ListItem>{t('link.opportunitiesPlacements')}</ListItem>
          </AppMenuItem>,
          <AppMenuItem component={Link} key="resume" to="/resume" onClick={handleClose}>
            <ListItem>{t('link.resume')}</ListItem>
          </AppMenuItem>
        ]}
        <AppMenuItem disabled={true}>
          <ListItem>{t('menu.loginSettings')}</ListItem>
        </AppMenuItem>
        <AppMenuItem component={Link} to="/notification-settings" onClick={handleClose}>
          <ListItem>{t('menu.notificationSettings')}</ListItem>
        </AppMenuItem>
        <AppMenuItem component="a" href={`/api/v1/manager/faq/file?timestamp=`+new Date().getTime()}
                     target="_blank" onClick={handleClose}>
          <ListItem>{t('menu.downloadFAQ')}</ListItem>
        </AppMenuItem>
        <AppMenuItem onClick={handleLogout}>
          <LogoutListItem>{t('menu.logout')}</LogoutListItem>
        </AppMenuItem>
      </AppMenu>
    </MenuContainer>
  );
}

TopRightMenu.propTypes = {
  indexedMeta: PropTypes.object,
  onLogout: PropTypes.func.isRequired,
  personalDetails: PropTypes.object,
  syncing: PropTypes.bool,
  syncErrors: PropTypes.object
};

export default TopRightMenu;
