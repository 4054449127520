import styled from 'styled-components';
import { Checkbox as BaseCheckbox } from 'components/inputs';
import Link from 'components/Link';

export const ActionItem = styled.div`
  align-items: center;
  display: flex;

  .MuiFormControlLabel-root {
    margin-left: 0;
    margin-right: 0;
  }
`;

export const Checkbox = styled(BaseCheckbox)`
  & + .MuiTypography-body1 {
    font-weight: ${props => props.theme.fontWeight.bold};
    font-size: ${props => props.theme.fontSize.xsmall};
    text-transform: uppercase;
  }
`;

export const DownloadLink = styled(Link)`
  font-size: ${props => props.theme.fontSize.xsmall};
`;

export const DownloadText = styled.span`
  font-weight: ${props => props.theme.fontWeight.bold};
  margin-left: ${props => props.theme.spacing(1)};
  text-transform: uppercase;
`;

export const NDAFooter = styled.div`
  align-items: center;
  display: flex;
  flex-grow: 1;
  flex-wrap: ${props => props.wrap || 'wrap'};
  /* use negative margin bottom to compensate material UI checkbox padding */
  margin-bottom: ${props => props.theme.spacing(-1.25)};
  ${props => props.justify && `justify-content: ${props.justify};`}

  @media (max-width: 650px) {
    justify-content: center;
  }
`;

export const NDAViewer = styled.div`
  margin: ${props => props.theme.spacing(2)} 0;
  min-height: 370px;
`;

export const NDAContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100%;

  ${NDAFooter} {
    flex-grow: 0;
  }
  ${NDAViewer} {
    flex-grow: 1;
  }
`;

export const NDAText = styled.div`
  font-weight: ${props => props.theme.fontWeight.light};
  white-space: pre-wrap;
`;

export const Title = styled.h3`
  font-size: ${props => props.theme.fontSize.large};
  font-weight: ${props => props.theme.fontWeight.light};
  margin: 0;
`;
