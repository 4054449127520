import styled from 'styled-components';
import { Button as BaseButton } from 'components/buttons';
import { updateColorLight } from 'utils/colorHelpers';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
`;

export const ButtonContainer = styled.div`
  display: flex;
  width: 100%;
  margin-top: 10px;
`;

export const Button = styled(BaseButton)`
  margin-top: 5px;
  width: 100%;
  text-align: center;
  color: ${props => props.theme.color.primary.contrast} !important;
`;

export const LinkButton = styled.a`
  background: ${props => props.theme.color.primary.contrast};
  color: ${props => props.theme.color.primary.main};
  text-align: center;
  margin: 5px;
  text-decoration: none;
  padding: 7px;
  line-height: 1.875em;
  font-weight: 700;
  font-size: 14px;
  text-transform: uppercase;
  width: 100%;
  word-break: normal !important;
  border: 2px solid ${props => props.theme.color.primary.main};
  &:hover {
    background: ${props => updateColorLight(props.theme.color.primary.main, 95)};
  }
`;

export const Title = styled.div`
  font-size: ${props => props.theme.fontSize.xsmall};
  margin-left: 5px;
`;

export const Line = styled.hr`
  background: #cccccc;
  border: 0;
  width: 100%;
  height: 1px;
  margin-top: 20px;
`;

export const FlexContainer = styled.div`
  display: flex;
  flex: 1;
`;
