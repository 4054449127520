import React from 'react';
import {useSelector} from 'react-redux';
import {selectFieldLengthGetter} from 'redux-modules/fieldsMeta/selectors';
export default function wrapper(WrappedComponent){
    return function(props){
        let getFieldLength = useSelector(selectFieldLengthGetter);
        let maxFieldLength = getFieldLength(props.id);
        let inputProps =  maxFieldLength && {
            maxLength: maxFieldLength,
        };
        return <WrappedComponent {...props}  inputProps={inputProps}/>
    }
}