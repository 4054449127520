import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { ArrowLeft, ArrowRight } from 'components/icons';
import { ItemContent, SlickSlider, SliderArrow } from './style';

function RotatingSelect({ items, selectedKey, onChange }) {
  const selectedSlideIndex = useMemo(() => {
    if (!items || !selectedKey) {
      return 0;
    } else {
      let index = items.findIndex(item => item.key === selectedKey);
      if (index < 0) index = 0;
      return index;
    }
  }, [selectedKey, items]);
  const onSlideChange = useCallback(
    index => {
      if (index < 0) return;
      let item = items[index];
      if (onChange) onChange(item.key, item);
    },
    [items, onChange]
  );
  const settings = {
    dots: false,
    infinite: true,
    initialSlide: selectedSlideIndex,
    speed: 500,
    slidesToShow: 1,
    arrows: true,
    slidesToScroll: 1,
    prevArrow: (
      <SliderArrow left>
        <ArrowLeft />
      </SliderArrow>
    ),
    nextArrow: (
      <SliderArrow right>
        <ArrowRight />
      </SliderArrow>
    ),
    variableWidth: false,
    afterChange: onSlideChange
  };
  if (!items || items.length === 0 || !selectedKey) return null;
  return (
    <SlickSlider {...settings}>
      {items.map((item, index) => (
        <ItemContent key={index}>{item.name}</ItemContent>
      ))}
    </SlickSlider>
  );
}

RotatingSelect.defaultProps = {
  items: [],
  selectedKey: null
};

RotatingSelect.propTypes = {
  items: PropTypes.array.isRequired,
  selectedKey: PropTypes.string,
  onChange: PropTypes.func
};

export default RotatingSelect;
