import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { de, enGB } from 'date-fns/locale';
import * as uiStateSelectors from 'redux-modules/uiState/selectors';

const locales = { de, en: enGB };

function useLocale(locale) {
  const appLanguage = useSelector(uiStateSelectors.selectLanguage);
  return useMemo(() => locales[locale || appLanguage], [appLanguage, locale]);
}

export default useLocale;
