import React from 'react';
import PropTypes from 'prop-types';
import { Container, Title } from './style';

function Header({ className, children, size, title }) {
  return (
    <Container className={className}>
      <Title size={size}>{title}</Title>
      {children}
    </Container>
  );
}

Header.defaultProps = {
  size: 'large'
};

Header.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.node, PropTypes.object]),
  size: PropTypes.oneOf(['xsmall', 'small', 'base', 'large', 'xlarge', 'xxl', 'huge']).isRequired,
  title: PropTypes.string
};

export default Header;
