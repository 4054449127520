import { connect } from 'react-redux';
import * as placementsSelectors from 'redux-modules/placements/selectors';
import * as authSelectors from 'redux-modules/auth/selectors';
import * as uiStateSelectors from 'redux-modules/uiState/selectors';
import * as placementsActions from 'redux-modules/placements/actions';
import Placements from './Placements';

function mapStateToProps(state) {
  var props = {
    placements: placementsSelectors.selectActivePlacements(state),
    placementsDetails: placementsSelectors.selectPlacementsDetails(state),
    syncing:
      placementsSelectors.selectSyncState(state) ||
      placementsSelectors.selectSyncPlacementsState(state),
    syncingMonth: placementsSelectors.selectSyncMonthState(state),
    videoURLs: authSelectors.selectVideoURLs(state),
    language: uiStateSelectors.selectLanguage(state)
  };
  return props;
}

function mapDispatchToProps(dispatch) {
  return {
    onInit: () => {
      dispatch(placementsActions.loadPlacements());
    },
    loadPlacementOverview: id => {
      dispatch(placementsActions.loadPlacementOverview(id));
    },
    loadPlacementOverviewMonth: (id, year, month) => {
      dispatch(placementsActions.loadPlacementOverviewMonth(id, year, month));
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Placements);
