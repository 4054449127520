import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import Container from '@material-ui/core/Container';
import Resume from './Resume';
import { HeaderSection } from './style';
import ProjectHeader from './ProjectHeader';
import { Section } from 'components/styledComponents';
import { PersonalDetails } from 'components/pages/ResumePage/sections';

function ProjectCandidateCV({ cv, indexedMeta, loading, project, onInit }) {
  useEffect(() => {
    onInit();
  }, [onInit]);

  const fullName = useMemo(() => {
    if (!cv) return null;
    const fullName = [];
    cv.salutation && fullName.push(indexedMeta.salutations[cv.salutation]);
    cv.title && fullName.push(indexedMeta.titles[cv.title]);
    cv.firstName && fullName.push(cv.firstName);
    cv.lastName && fullName.push(cv.lastName);
    return fullName.join(' ');
  }, [cv, indexedMeta]);
  return (
    <React.Fragment>
      <HeaderSection>
        <Container>
          <ProjectHeader loading={loading} project={project} />
        </Container>
      </HeaderSection>

      <Section>
        <Container>
          <PersonalDetails
            fullName={fullName}
            loading={loading}
            readonly={true}
            user={cv ? cv : {}}
          />
        </Container>
      </Section>
      <Section>
        <Container>
          <Resume loading={loading} resume={cv} />
        </Container>
      </Section>
    </React.Fragment>
  );
}

ProjectCandidateCV.propTypes = {
  candidateId: PropTypes.string,
  cv: PropTypes.object,
  indexedMeta: PropTypes.object,
  loading: PropTypes.bool.isRequired,
  project: PropTypes.object,
  onInit: PropTypes.func.isRequired
};

export default ProjectCandidateCV;
