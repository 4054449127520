import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Route, withRouter } from 'react-router-dom';
import Editor from './Editor';
import GroupEditor from './GroupEditor';

function WorkExperience({ history, match: { url }, location }) {
  const handleClose = useCallback(() => history.push(url), [history, url]);

  return (
      <switch>
        <Route
            path={`${url}/work-experience/:id?`}
            render={({ match }) => <Editor workExperienceId={match.params.id} onClose={handleClose} />}
        />
          <Route
              path={`${url}/position-in-company/:id?`}
              render={({ match }) => <Editor companyId={match.params.id} workExperienceId={match.params.id} onClose={handleClose} />}
          />
          <Route
              path={`${url}/add-company-group/:id?`}
              render={({match}) => <GroupEditor groupID={match.params.id} listOfComp={location.state.listOfComp} onClose={handleClose} />}
          />
        <Route
            path={`${url}/company/:id?`}
            render={({ match }) => <Editor workExperienceId={match.params.id} ComponentToRender='CompanyInformation' onClose={handleClose} />}
        />

      </switch>

  );
}

WorkExperience.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired
};

export default withRouter(WorkExperience);
