import React, { useCallback, useMemo, useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import Popover from '@material-ui/core/Popover';
import { format } from 'date-fns';
import { Editor } from '@tinymce/tinymce-react';
import { Button } from 'components/buttons';
import { useLocale, useTranslation } from 'hooks';
import TimeTrackingContainer from './TimeTrackingContainer';
import {
  ButtonContainer,
  Content,
  DateLabel,
  Description,
  Title,
  EditorContainer,
  Border,
  MultilineTextField
} from './style';
import TimeLog from './TimeLog';

const placeOfWorkMapping = {
  '100': '100',
  '010': '125',
  '110': '150',
  '011': '175',
  '001': '200',
  '101': '225',
  '111': '250',
  '000': '300'
};

const tinyMceConfig = {
  menubar: false,
  plugins: ['autolink lists', 'paste autoresize'],
  valid_elements: 'li,br,p,ul',
  valid_children: '-li[ul]',
  content_style: `
  body {  
    font-family: Roboto,Helvetica Neue,Helvetica,sans-serif;
    vertical-align: left;
    color: #444444;
  }`,
  width: '100%',
  toolbar: 'bullist outdent indent',
  branding: false,
  elementpath: false,
  statusbar: false
};

function TimeTrackingPopper({
  date,
  hasData,
  syncing,
  language,
  dateTimetracking,
  timeTrackingOptions,
  placementType,
  feeBilling,
  reportBillingViaPortal,
  travelExpenseManager,
  recordedTimeOptions,
  placeOfWorkOptions,
  businessTripOptions,
  onSave,
  onClose,
  ...rest
}) {
  // Localization hooks
  const { t } = useTranslation();
  const locale = useLocale();

  // State variables
  const [timeTracking, setTimeTracking] = useState(null);
  const [recordedTime, setRecordedTime] = useState(null);
  const [businessTrip, setBusinessTrip] = useState('200');
  const [isTinymceLoaded, setIsTinymceLoaded] = useState(false);
  const [isEmptyDesc, setIsEmptyDesc] = useState(true);
  const [pow, setPOW] = useState({
    travelFromHome: '0',
    travelFromSite: '0',
    stayOvernight: '0'
  });
  const [workFrom, setWorkFrom] = useState('00:00');
  const [workTo, setWorkTo] = useState('00:00');
  const [pauseTimePairs, setPauseTimePairs] = useState([{ id: 0, start: '00:00', end: '00:00' }]);
  const [workStartError, setWorkStartError] = useState(false);
  const [workEndError, setWorkEndError] = useState(false);
  const [pauseErrors, setPauseErrors] = useState([]);

  //initializing tinymce editor
  const editorRef = useRef(null);
  const descValueRef = useRef();

  const showTimeTracking = useMemo(
    () => (['110', '150', '400', '500'].includes(placementType) ? false : true),
    [placementType]
  );

  const showTimeLog =
    (placementType === '400' && reportBillingViaPortal === '100') ||
    (['110', '150', '500'].includes(placementType) && feeBilling === '200');

  const defaultOptions = useMemo(
    () => [
      {
        key: '1',
        value: t('button.yes')
      },
      {
        key: '0',
        value: t('button.no')
      }
    ],
    [t]
  );

  useEffect(() => {
    const {
      timeTracking = null,
      placeOfWork = '300',
      recordedTime = null,
      description = null,
      businessTrip = '200',
      pauseEnd1 = '00:00',
      pauseEnd2 = '00:00',
      pauseEnd3 = '00:00',
      pauseStart1 = '00:00',
      pauseStart2 = '00:00',
      pauseStart3 = '00:00',
      workEnd = '00:00',
      workStart = '00:00'
    } = dateTimetracking || {};

    setTimeTracking(timeTracking);
    setRecordedTime(recordedTime);
    setBusinessTrip(businessTrip);
    let criteria = Object.keys(placeOfWorkMapping).find(k => placeOfWorkMapping[k] === placeOfWork);
    if (criteria && typeof criteria === 'string') {
      let criteriaArr = criteria.split('');
      setPOW(prevState => ({
        ...prevState,
        travelFromHome: criteriaArr?.[0] || '0',
        travelFromSite: criteriaArr?.[1] || '0',
        stayOvernight: criteriaArr?.[2] || '0'
      }));
    }
    descValueRef.current = description || '';
    if (description) {
      descValueRef.current = description;
      setIsEmptyDesc(false);
    } else {
      descValueRef.current = '';
      setIsEmptyDesc(true);
    }

    setWorkFrom(workStart);
    setWorkTo(workEnd);

    if (showTimeLog) {
      const pausePairs = [];

      const pauseTimes = [
        { start: pauseStart1, end: pauseEnd1 },
        { start: pauseStart2, end: pauseEnd2 },
        { start: pauseStart3, end: pauseEnd3 }
      ];

      const timeFormatRegex = /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/;
      for (let i = 0; i < pauseTimes.length; i++) {
        const { start, end } = pauseTimes[i];

        let validStart = start;
        let validEnd = end;

        if (!timeFormatRegex.test(start)) {
          validStart = '00:00';
        }

        if (!timeFormatRegex.test(end)) {
          validEnd = '00:00';
        }

        if (i === 0) {
          pausePairs.push({ id: pausePairs.length, start: validStart, end: validEnd });
          continue;
        }

        if (validStart !== '00:00' && validEnd !== '00:00') {
          pausePairs.push({ id: pausePairs.length, start: validStart, end: validEnd });
        }
      }
      setPauseTimePairs(pausePairs);
    }
  }, [date, dateTimetracking, showTimeLog]);

  useEffect(() => {
    correctCursorPosition();
  }, [isEmptyDesc]);

  const handleChange = useCallback(({ target }) => {
    let value = target?.value;
    let name = target?.name;
    switch (name) {
      case 'timeTracking':
        setTimeTracking(value);
        break;
      case 'recordedTime':
        setRecordedTime(value);
        break;
      case 'travelFromHome':
      case 'travelFromSite':
      case 'stayOvernight':
        setPOW(prevState => ({ ...prevState, [name]: value }));
        break;
      case 'businessTrip':
        setBusinessTrip(value);
        break;
      default:
        break;
    }
  }, []);

  //RTEXT-618
  const handleDescriptionChange = useCallback(() => {
    let content = editorRef?.current?.getContent();
    if (content) {
      descValueRef.current = content;
      setIsEmptyDesc(false);
    } else {
      descValueRef.current = '';
      setIsEmptyDesc(true);
    }
  }, []);

  const correctCursorPosition = useCallback(() => {
    let selection = editorRef?.current?.selection;
    if (selection) {
      selection.select(editorRef.current.getBody(), true);
      selection.collapse(false);
    }
  }, []);

  //replacing descValue with descValue.current in save function
  const handleSave = useCallback(
    event => {
      const criteria = `${pow.travelFromHome}${pow.travelFromSite}${pow.stayOvernight}`;
      const placeOfWork = placeOfWorkMapping[criteria] || '300';
      const timeLog = showTimeLog
        ? {
            workFrom,
            workTo,
            pauseTimePairs
          }
        : null;

      onSave &&
        onSave(
          timeTracking,
          descValueRef.current,
          recordedTime,
          placeOfWork,
          businessTrip,
          timeLog
        );
    },
    [
      workFrom,
      workTo,
      pow.travelFromHome,
      pow.travelFromSite,
      pow.stayOvernight,
      onSave,
      timeTracking,
      recordedTime,
      businessTrip,
      JSON.stringify(pauseTimePairs),
      showTimeLog
    ]
  );

  let disabled = useMemo(() => {
    if (syncing) {
      return true;
    }

    if (showTimeTracking) {
      return !timeTracking;
    }

    switch (recordedTime) {
      case '100':
      case '200':
        return !!(
          isEmptyDesc ||
          (showTimeLog && (workStartError || workEndError || pauseErrors.length))
        );
      case '300':
        if (['110', '150', '500'].includes(placementType)) {
          return false;
        }
        return !!(showTimeLog && isEmptyDesc);
      default:
        return true;
    }
  }, [
    syncing,
    timeTracking,
    showTimeTracking,
    recordedTime,
    isEmptyDesc,
    workStartError,
    workEndError,
    pauseErrors.length,
    showTimeLog,
    placementType
  ]);

  const currentDateStr = format(date, 'PP', { locale: locale });
  useMemo(() => {
    return format(date, 'PP', { locale: locale });
  }, [locale, date]);

  return (
    <div>
      <Popover {...rest}>
        <Content>
          <Title>{t('label.logActivity')}</Title>
          <DateLabel>{currentDateStr}</DateLabel>
          <Border />
          {hasData && (
            <>
              {showTimeTracking ? (
                <>
                  <TimeTrackingContainer
                    handleChange={handleChange}
                    name="timeTracking"
                    options={timeTrackingOptions}
                    state={timeTracking}
                    type="radio"
                  />
                </>
              ) : (
                <>
                  <>
                    <TimeTrackingContainer
                      description={t('label.howLongDidYouWork')}
                      handleChange={handleChange}
                      name="recordedTime"
                      options={recordedTimeOptions}
                      removeBorder={true}
                      state={recordedTime}
                      type="radio"
                    />
                    {['100', '200'].includes(recordedTime) && showTimeLog && (
                      <TimeLog
                        language={language}
                        pauseErrors={pauseErrors}
                        pauseTimePairs={pauseTimePairs}
                        setPauseErrors={setPauseErrors}
                        setPauseTimePairs={setPauseTimePairs}
                        setWorkEndError={setWorkEndError}
                        setWorkFrom={setWorkFrom}
                        setWorkStartError={setWorkStartError}
                        setWorkTo={setWorkTo}
                        workEndError={workEndError}
                        workFrom={workFrom}
                        workStartError={workStartError}
                        workTo={workTo}
                      />
                    )}
                  </>
                  {travelExpenseManager === '500' ? (
                    <>
                      {['100', '200', '300'].includes(recordedTime) && (
                        <>
                          <TimeTrackingContainer
                            description={t('label.didYouTravelFromSite')}
                            handleChange={handleChange}
                            name="travelFromHome"
                            options={defaultOptions}
                            state={pow.travelFromHome}
                            type="radio"
                          />
                          <TimeTrackingContainer
                            description={t('label.didYouTravelFromHome')}
                            handleChange={handleChange}
                            name="travelFromSite"
                            options={defaultOptions}
                            state={pow.travelFromSite}
                            type="radio"
                          />
                          <TimeTrackingContainer
                            description={t('label.willYouStay')}
                            handleChange={handleChange}
                            name="stayOvernight"
                            options={defaultOptions}
                            state={pow.stayOvernight}
                            type="radio"
                          />
                          <TimeTrackingContainer
                            description={t('label.businessTrip')}
                            handleChange={handleChange}
                            name="businessTrip"
                            options={businessTripOptions}
                            removeBorder={businessTrip === '100'}
                            state={businessTrip}
                            type="radio"
                          />
                          {businessTrip === '100' && (
                            <>
                              <MultilineTextField
                                fullWidth
                                id="businessTripDesc"
                                InputProps={{ readOnly: true }}
                                multiline
                                rows={language === 'en' ? '5' : '4'}
                                value={t('message.tripDescription')}
                              />
                              <Border mt="10" />
                            </>
                          )}
                        </>
                      )}
                    </>
                  ) : null}
                </>
              )}
              <Description>{t('message.activityDescription')}</Description>
              <EditorContainer>
                <Editor
                  init={tinyMceConfig}
                  initialValue={descValueRef.current}
                  onEditorChange={handleDescriptionChange}
                  onInit={(evt, editor) => {
                    editorRef.current = editor;
                    setIsTinymceLoaded(!isTinymceLoaded);
                  }}
                />
              </EditorContainer>
            </>
          )}

          <ButtonContainer>
            <Button label={t('button.close')} variant="text" onClick={onClose} />
            <Button
              color="primary"
              disabled={disabled}
              label={t('button.save')}
              onClick={handleSave}
            />
          </ButtonContainer>
        </Content>
      </Popover>
    </div>
  );
}

TimeTrackingPopper.propTypes = {
  date: PropTypes.instanceOf(Date).isRequired,
  hasData: PropTypes.bool,
  syncing: PropTypes.bool.isRequired,
  timeTracking: PropTypes.object,
  timeTrackingOptions: PropTypes.array,
  recordedTimeOptions: PropTypes.array,
  placeOfWorkOptions: PropTypes.array,
  businessTripOptions: PropTypes.array,
  onSave: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  language: PropTypes.string,
  placementType: PropTypes.string,
  travelExpenseManager: PropTypes.string,
  dateTimetracking: PropTypes.object,
  reportBillingViaPortal: PropTypes.string
};

export default TimeTrackingPopper;
