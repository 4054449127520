import styled from 'styled-components';
import { Button as BaseButton } from 'components/buttons';
import { Card } from 'components/cards';
import { Loader } from 'components/styledComponents';

export const UploadLoader = styled(Loader)`
  height: 100%;
`;

export const BillingCard = styled(Card)`
  min-width: 0px;
`;

export const Title = styled.div`
  font-size: ${props => props.theme.fontSize.medium};
  margin-left: 15px;
  margin-bottom: 5px;
  color: ${props => props.theme.color.primary.main};
`;

export const FlexBox = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: ${props => (props.mt ? props.mt + 'px' : '0px')};
`;

export const CardsSubContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 25px;
  width: ${props => (props.size ? (parseFloat(props.size) * 10).toString() + '%' : 0)};

  @media (max-width: 955px) {
    width: 100%;
  }
`;

export const ErrorMessage = styled.p`
  color: red;
`;

export const Message = styled.p`
  margin-top: ${props => (props.mt ? props.mt + 'px' : '0px')};
  color: ${props => props.theme.color.primary.main};
  font-size: ${props => (props.small ? props.theme.fontSize.small : 'medium')};
`;

export const ButtonContainer = styled.div`
  margin: 5px;
  display: flex;
  flex-direction: row-reverse;
  width: 100%;
  flex-wrap: wrap;
`;

export const Button = styled(BaseButton)`
  margin: 15px;
  :disabled {
    background: ${props => (props.disabledColor ? props.disabledColor : '#F5F5F5')};
    color: ${props => (props.disabledColor ? 'white' : '#CCCCCC')};
  }
`;

export const ButtonLink = styled(BaseButton)`
  background: none !important;
  border: none;
  padding: 0 !important;
  color: ${props => props.theme.color.primary.main};
`;

export const TextContainer = styled.div`
  margin-top: ${props => (props.mt ? props.mt + 'px' : '0px')};
  display: flex;
  flex-direction: column;
`;

// RTEXT-751
export const Text = styled.div`
  ${props =>
    props.width &&
    `
      width: ${props.width + 'px'};
    `}
  margin-top: ${props => (props.mt ? props.mt + 'px' : '0px')};
  font-weight: ${props =>
    props.bold ? props.theme.fontWeight.bold : props.theme.fontWeight.normal};
`;
