import React from 'react';
import PropTypes from 'prop-types';
import CandidateCard from './CandidateCard';
import { ProjectStatusTitle } from './style';
import Carousel from 'components/Carousel';

function FoundCandidates({ project }) {
  return (
    <div>
      <ProjectStatusTitle>
        {project && project.candidates && project.candidates.length} Canditates found for this
        project.
      </ProjectStatusTitle>
      <Carousel>
        {project &&
          project.candidates &&
          project.candidates.map(candidate => (
            <CandidateCard candidate={candidate} key={candidate.id} />
          ))}
      </Carousel>
    </div>
  );
}

FoundCandidates.propTypes = {
  project: PropTypes.object.isRequired
};

export default FoundCandidates;
