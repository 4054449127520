import { connect } from 'react-redux';
import * as metaSelectors from 'redux-modules/meta/selectors';
import CompanyInformation from './CompanyInformation';
import * as uiStateSelectors from 'redux-modules/uiState/selectors';

function mapStateToProps(state) {
  return {
    language: uiStateSelectors.selectResumeLanguage(state),
    meta: metaSelectors.selectResumeMeta(state)
  };
}

export default connect(mapStateToProps, null)(CompanyInformation);
