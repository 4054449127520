import localStorage from 'local-storage-fallback';
import { call, put, takeEvery, takeLeading } from 'redux-saga/effects';
import apiClient from 'communication/apiClient';
import * as uiStateActions from 'redux-modules/uiState/actions';
import * as actions from './actions';
import * as types from './types';

export function* loadResume() {
  try {
    const resp = yield call(apiClient.get, '/api/v1/manager');
    if (resp && resp.data) {
      const { communicationLanguage, ...data } = resp.data;
      yield put(actions.setLoadedResume(data));
      yield put(uiStateActions.changeLanguage(communicationLanguage));
    } else {
      yield put(actions.failResumeLoad(resp));
    }
  } catch (error) {
    yield put(actions.failResumeLoad('error'));
  }
}

export function* saveAvailability(actionData) {
  try {
    const resp = yield call(
      apiClient.put,
      '/api/v1/manager/availability',
      actionData.payload.availability,
      actionData.payload.failCallback
    );
    if (resp && resp.data) {
      yield put(actions.updateAvailability(resp.data));
    } else {
      yield put(actions.failAvailabilitySave(resp));
    }
  } catch (error) {
    yield put(actions.failAvailabilitySave('error'));
  }
}

export function* deleteEducation(actionData) {
  const { education } = actionData.payload;

  try {
    const resp = yield call(
      apiClient.delete,
      `/api/v1/manager/education/${education.id}`,
      education,
      actionData.payload.failCallback
    );
    if (resp && resp.data) {
      yield put(actions.removeEducation(education));
    } else {
      yield put(actions.failEducationSave(resp));
    }
  } catch (error) {
    yield put(actions.failEducationSave('error'));
  }
}
export function* saveEducation(actionData) {
  const { education } = actionData.payload;
  const { request, successAction } = education.id
    ? {
        request: apiClient.put,
        successAction: actions.updateEducation
      }
    : {
        request: apiClient.post,
        successAction: actions.addEducation
      };

  try {
    const resp = yield call(
      request,
      '/api/v1/manager/education',
      education,
      actionData.payload.failCallback
    );
    if (resp && resp.data) {
      yield put(successAction(resp.data));
    } else {
      yield put(actions.failEducationSave(resp));
    }
  } catch (error) {
    yield put(actions.failEducationSave('error'));
  }
}

export function* saveExecutiveSummary(actionData) {
  try {
    const resp = yield call(
      apiClient.put,
      '/api/v1/manager/executive-summary',
      actionData.payload.executiveSummary,
      actionData.payload.failCallback
    );
    if (resp && resp.data) {
      yield put(actions.updateExecutiveSummary(resp.data));
    } else {
      yield put(actions.failExecutiveSummarySave(resp));
    }
  } catch (error) {
    yield put(actions.failExecutiveSummarySave('error'));
  }
}

export function* saveCompetenceProfile(actionData) {
  try {
    const resp = yield call(
      apiClient.put,
      '/api/v1/manager/competence-profile',
      actionData.payload.competenceProfile,
      actionData.payload.failCallback
    );
    if (resp && resp.data) {
      yield put(actions.updateCompetenceProfile(resp.data));
    } else {
      yield put(actions.failCompetenceProfileSave(resp));
    }
  } catch (error) {
    yield put(actions.failCompetenceProfileSave('error'));
  }
}

export function* saveItSkills(actionData) {
  try {
    const resp = yield call(
      apiClient.put,
      '/api/v1/manager/it-skills',
      actionData.payload.itSkills,
      actionData.payload.failCallback
    );
    if (resp && resp.data) {
      yield put(actions.updateItSkills(resp.data));
    } else {
      yield put(actions.failItSkillsSave(resp));
    }
  } catch (error) {
    yield put(actions.failItSkillsSave('error'));
  }
}

export function* saveLanguages(actionData) {
  try {
    const resp = yield call(
      apiClient.put,
      '/api/v1/manager/language-proficiency',
      actionData.payload.languages,
      actionData.payload.failCallback
    );
    if (resp && resp.data) {
      yield put(actions.updateLanguages(resp.data));
    } else {
      yield put(actions.failLanguagesSave(resp));
    }
  } catch (error) {
    yield put(actions.failLanguagesSave('error'));
  }
}

export function* saveMissionAbroad(actionData, workId) {
  const missionAbroad = actionData.payload.missionAbroad;
  const { request, successAction } = missionAbroad.id
    ? {
        request: apiClient.put,
        successAction: actions.updateMissionAbroad
      }
    : {
        request: apiClient.post,
        successAction: actions.addMissionAbroad
      };

  try {
    const resp = yield call(
      request,
      '/api/v1/manager/work_experience/' + workId + '/mission-abroad',
      missionAbroad,
      actionData.payload.failCallback
    );
    if (resp && resp.data) {
      yield put(successAction(resp.data));
    } else {
      yield put(actions.failEducationSave(resp));
    }
  } catch (error) {
    yield put(actions.failEducationSave('error'));
  }
}

export function* saveOtherActivities(actionData) {
  try {
    const resp = yield call(
      apiClient.put,
      '/api/v1/manager/other-activities',
      actionData.payload.otherActivities,
      actionData.payload.failCallback
    );
    if (resp && resp.data) {
      yield put(actions.updateOtherActivities(resp.data));
    } else {
      yield put(actions.failOtherActivitiesSave(resp));
    }
  } catch (error) {
    yield put(actions.failOtherActivitiesSave('error'));
  }
}

export function* savePersonalDetails(actionData) {
  try {
    const { photoFile, ...personalDetails } = actionData.payload.personalDetails;
    const resp = yield call(
      apiClient.put,
      '/api/v1/manager/personal-info',
      personalDetails,
      actionData.payload.failCallback
    );

    let isSuccessful = resp && resp.data;
    if (isSuccessful && photoFile) {
      const formData = new FormData();
      formData.append('file', photoFile);
      const photoResp = yield call(
        apiClient.custom,
        {
          url: '/api/v1/manager/photo',
          method: 'POST',
          body: formData
        },
        actionData.payload.failCallback
      );
      isSuccessful = isSuccessful && photoResp && photoResp.data;
      isSuccessful && (resp.data.photo = photoResp.data.photo);
    }

    if (isSuccessful) {
      const { communicationLanguage, ...data } = resp.data;
      yield put(actions.updatePersonalDetails(data));
      yield put(uiStateActions.changeLanguage(communicationLanguage));
    } else {
      yield put(actions.failPersonalDetailsSave(resp));
    }
  } catch (error) {
    yield put(actions.failPersonalDetailsSave('error'));
  }
}

export function* deleteWorkExperience(actionData) {
  const workExperience = actionData.payload.workExperience;

  try {
    const resp = yield call(
      apiClient.delete,
      `/api/v1/manager/work_experience/${workExperience.id}`,
      workExperience,
      actionData.payload.failCallback
    );
    if (resp && resp.data) {
      yield put(actions.removeWorkExperience(workExperience));
    } else {
      yield put(actions.failWorkExperienceSave(resp));
    }
  } catch (error) {
    yield put(actions.failWorkExperienceSave('error'));
  }
}

export function* saveWorkExperience(actionData) {
  const {
    relatedCompanyRoles,
    sameCompanyRoles,
    ...workExperience
  } = actionData.payload.workExperience;
  const { request, successAction } = workExperience.id
    ? {
        request: apiClient.post,
        successAction: actions.updateWorkExperience
      }
    : {
        request: apiClient.post,
        successAction: actions.addWorkExperience
      };

  try {
    const resp = yield call(
      request,
      '/api/v1/manager/work_experience_composed',
      {
        relatedCompanyRoles,
        sameCompanyRoles,
        workExperience
      },
      actionData.payload.failCallback
    );
    if (resp && resp.data) {
      yield put(successAction(resp.data));
    } else {
      yield put(actions.failWorkExperienceSave(resp));
    }
  } catch (error) {
    yield put(actions.failWorkExperienceSave('error'));
  }
}

export function* saveCompanyGroup(actionData) {
  const {
    workExperience
  } = actionData.payload;
  workExperience.updateAllCompWexp = true;
  const { request, successAction } = workExperience.companyGroupId
    ? {
        request: apiClient.post,
        successAction: actions.updateWorkExperience
      }
    : {
        request: apiClient.post,
        successAction: actions.addWorkExperience
      };

  try {
    const resp = yield call(
      request,
      '/api/v1/manager/save_company_group',
      {
        workExperience
      },
      actionData.payload.failCallback
    );
    if (resp && resp.data) {
      yield put(successAction(resp.data));
    } else {
      yield put(actions.failWorkExperienceSave(resp));
    }
  } catch (error) {
    yield put(actions.failWorkExperienceSave('error'));
  }
}

export function* deleteCompanyGroup(actionData) {
  const {
    groupId
  } = actionData.payload;
  try {
    const resp = yield call(
        apiClient.delete,
        `/api/v1/manager/company_group/${groupId}`,
        {},
        actionData.payload.failCallback
    );
    if (resp && resp.data) {
      yield put(actions.updateWorkExperience(resp.data));
    } else {
      yield put(actions.failWorkExperienceSave(resp));
    }
  } catch (error) {
    yield put(actions.failWorkExperienceSave('error'));
  }
}


export function* saveNotificationSettings(actionData) {
  try {
    const notificationSettings = actionData.payload.notificationSettings;
    const resp = yield call(
      apiClient.put,
      '/api/v1/manager/notification-settings',
      notificationSettings,
      actionData.payload.failCallback
    );

    let isSuccessful = resp && resp.data;

    if (isSuccessful) {
      const {...data } = resp.data;
      yield put(actions.updateNotificationSettings(data));
    } else {
      yield put(actions.failNotificationSettingsSave(resp));
    }
  } catch (error) {
    yield put(actions.failNotificationSettingsSave('error'));
  }
}
export function* saveResumeCompleteAck(actionData) {
  try {
    const personalDetails = actionData.payload.personalDetails;
    const resp = yield call(
      apiClient.put,
      '/api/v1/manager/mark-resume-completed',
        personalDetails,
      actionData.payload.failCallback
    );

    let isSuccessful = resp && resp.data;

    if (isSuccessful) {
      const {...data } = resp.data;
      yield put(actions.doneMarkResumeCompleteAck(data));
    } else {
      yield put(actions.failNotificationSettingsSave(resp));
    }
  } catch (error) {
    yield put(actions.failNotificationSettingsSave('error'));
  }
}

export function* deleteMyProfile(actionData) {
  try {
    const {deletionReason} =  actionData.payload;
    const resp = yield call(
      apiClient.put,
      '/api/v1/manager/delete-profile',
      deletionReason.remarks,
      actionData.payload.failCallback
    );

    let isSuccessful = resp && resp.data;

    if (isSuccessful) {
      const {...data } = resp.data;
      localStorage.removeItem('authToken');
      localStorage.removeItem('refreshToken');
      localStorage.removeItem('showAgreement');
      localStorage.removeItem('pLastActive');
      yield put(actions.doneProfileDelete(data));
    } else {
      yield put(actions.failNotificationSettingsSave(resp));
    }
    
  } catch (error) {
    yield put(actions.failNotificationSettingsSave('error'));
  }
}


export default [
  takeLeading(types.LOAD_RESUME, loadResume),
  takeEvery(types.SAVE_AVAILABILITY, saveAvailability),
  takeEvery(types.DELETE_EDUCATION, deleteEducation),
  takeEvery(types.SAVE_EDUCATION, saveEducation),
  takeEvery(types.SAVE_EXECUTIVE_SUMMARY, saveExecutiveSummary),
  takeEvery(types.SAVE_COMPETENCE_PROFILE, saveCompetenceProfile),
  takeEvery(types.SAVE_IT_SKILLS, saveItSkills),
  takeEvery(types.SAVE_LANGUAGES, saveLanguages),
  takeEvery(types.SAVE_OTHER_ACTIVITIES, saveOtherActivities),
  takeEvery(types.SAVE_PERSONAL_DETAILS, savePersonalDetails),
  takeEvery(types.DELETE_WORK_EXPERIENCE, deleteWorkExperience),
  takeEvery(types.DELETE_COMPANY_GROUP, deleteCompanyGroup),
  takeEvery(types.SAVE_WORK_EXPERIENCE, saveWorkExperience),
  takeEvery(types.SAVE_COMPANY_GROUP, saveCompanyGroup),
  takeEvery(types.SAVE_NOTIFICATION_SETTINGS, saveNotificationSettings),
  takeEvery(types.SAVE_RESUME_COMPLETE_ACK, saveResumeCompleteAck),
  takeEvery(types.DELETE_PROFILE, deleteMyProfile),
];
