import { connect } from 'react-redux';
import * as resumeActions from 'redux-modules/resume/actions';
import * as authSelectors from 'redux-modules/auth/selectors';
import PrivateContent from './PrivateContent';

function mapStateToProps(state) {
  return {
    showAgreement: authSelectors.selectShowAgreement(state)
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onInit: () => {
      dispatch(resumeActions.loadResume());
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PrivateContent);
