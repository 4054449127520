import styled from 'styled-components';
import { TableBody as MuiTableBody } from '@material-ui/core';

export const TableBody = styled(MuiTableBody)`
  tr {
    td {
      border: none !important;
      min-width: 150px;
    }
  }
`;
