import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { PlacementsTable } from './style';

function Placements({ className, placements, onInit }) {
  useEffect(() => {
    onInit();
  }, [onInit]);

  return <PlacementsTable className={className} placements={placements} />;
}

Placements.propTypes = {
  className: PropTypes.string,
  placements: PropTypes.array.isRequired,
  onInit: PropTypes.func.isRequired
};

export default Placements;
