import React from 'react';
import createSvgIcon from '../createSvgIcon';

export default createSvgIcon(
  <React.Fragment>
    <path
      d="M23.0537883,7.89705176 C23.3473998,7.6791973 23.7640635,7.70340335 24.0303301,7.96966991 C24.3232233,8.26256313 24.3232233,8.73743687 24.0303301,9.03033009 L24.0303301,9.03033009 L17.561,15.5 L24.0303301,21.9696699 C24.3232233,22.2625631 24.3232233,22.7374369 24.0303301,23.0303301 C23.7640635,23.2965966 23.3473998,23.3208027 23.0537883,23.1029482 L22.9696699,23.0303301 L16.5,16.561 L10.0303301,23.0303301 L9.94621165,23.1029482 C9.65260016,23.3208027 9.23593648,23.2965966 8.96966991,23.0303301 C8.6767767,22.7374369 8.6767767,22.2625631 8.96966991,21.9696699 L8.96966991,21.9696699 L15.439,15.5 L8.96966991,9.03033009 C8.6767767,8.73743687 8.6767767,8.26256313 8.96966991,7.96966991 C9.23593648,7.70340335 9.65260016,7.6791973 9.94621165,7.89705176 L10.0303301,7.96966991 L16.5,14.439 L22.9696699,7.96966991 Z"
      transform="translate(16.500000, 15.500000) rotate(-45.000000) translate(-16.500000, -15.500000)"
    />
  </React.Fragment>,
  'Plus'
);
