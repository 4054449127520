import React, { useEffect, useState, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'hooks';
import { Card } from 'components/cards';
import { PlacementSummary, TimeTracking } from 'components/Placements';
import { ContentSection, Loader, LoaderContent } from './style';

function Placements({
  className,
  loadPlacementOverview,
  loadPlacementOverviewMonth,
  placements,
  placementsDetails,
  syncing,
  syncingMonth,
  onInit,
  videoURLs,
  language,
  ...rest
}) {
  const { t } = useTranslation();

  const [placementId, setPlacementId] = useState(null);
  const [reportDate, setReportDate] = useState({
    isLoaded: false,
    year: new Date().getFullYear(),
    month: new Date().getMonth() + 1
  });
  useEffect(() => {
    onInit();
  }, [onInit]);

  useEffect(() => {
    var currentPlacementId = placementId;
    if (placements && placements.length && currentPlacementId == null) {
      currentPlacementId = placements[0].id;
    }
    if (currentPlacementId != null) {
      setPlacementId(currentPlacementId);
      loadPlacementOverview(currentPlacementId);
    }
  }, [loadPlacementOverview, placements, placementId]);

  const handleMonthChange = useCallback(
    date => {
      setReportDate({
        year: date.getFullYear(),
        month: date.getMonth() + 1,
        isLoaded: true
      });
      loadPlacementOverviewMonth(placementId, date.getFullYear(), date.getMonth() + 1);
    },
    [placementId, loadPlacementOverviewMonth]
  );

  const placementDetails = useMemo(() => {
    if (!placementsDetails || placementId == null || !placementsDetails[placementId]) {
      var placementDetails = {
        placement: {},
        currentYear: reportDate.year,
        currentMonth: reportDate.month,
        timeTrackingOptions: [],
        months: {}
      };
      placementDetails.months[`${reportDate.year}_${reportDate.month}`] = {
        timeTracking: [],
        monthlyReport: {}
      };
      return placementDetails;
    } else {
      placementDetails = placementsDetails[placementId];
      if (reportDate == null || !reportDate.isLoaded) {
        setReportDate({
          year: placementDetails.currentYear,
          month: placementDetails.currentMonth,
          isLoaded: true
        });
      }
      return placementDetails;
    }
  }, [placementsDetails, placementId, reportDate]);

  const onSelectedPlacementChange = useCallback(placement => {
    setPlacementId(placement.id);
    setReportDate({
      isLoaded: false,
      year: new Date().getFullYear(),
      month: new Date().getMonth() + 1
    });
  }, []);

  return (
    <Card margin="none" size="12" title={t('title.currentPlacements')} {...rest}>
      <Loader component={LoaderContent} loading={syncing}>
        <ContentSection
          as={PlacementSummary}
          placement={placementDetails.placement}
          placements={placements}
          size={8.5}
          widget={true}
          onSelectedPlacementChange={onSelectedPlacementChange}
          videoURLs={videoURLs}
          language={language}
        />
        <ContentSection
          as={TimeTracking}
          loading={syncingMonth}
          month={reportDate.month}
          placementDetails={placementDetails}
          size={3.5}
          year={reportDate.year}
          onMonthChange={handleMonthChange}
        />
      </Loader>
    </Card>
  );
}

Placements.propTypes = {
  className: PropTypes.string,
  placements: PropTypes.array.isRequired,
  placementsDetails: PropTypes.object,
  loadPlacementOverview: PropTypes.func.isRequired,
  loadPlacementOverviewMonth: PropTypes.func.isRequired,
  syncing: PropTypes.bool.isRequired,
  syncingMonth: PropTypes.bool.isRequired,
  onInit: PropTypes.func.isRequired,
  videoURLs: PropTypes.object,
  language: PropTypes.string
};

export default Placements;
