import styled from 'styled-components';

export const Detail = styled.div`
  border-left: 1px solid ${props => props.theme.color.grey[500]};
  padding-left: ${props => props.theme.spacing(1)};
  margin-right: ${props => props.theme.spacing(1)};
  padding: ${props => props.theme.spacing(0, 1, 0, 1)};
  ${props => (props.size ? 'flex: 1 1 ' + props.size : '')};

  @media (max-width: 500px) {
    border-bottom: 1px solid ${props => props.theme.color.grey[500]};
  }
`;

export const DetailLabel = styled.div`
  font-size: ${props => props.theme.fontSize.xsmall};
  margin: ${props => props.theme.spacing(0.5)} 0;
  text-transform: uppercase;
`;

export const DetailValue = styled.div`
  color: ${props => (props.black ? props.theme.color.text.main : props.theme.color.primary.main)};
  font-weight: ${props => props.theme.fontWeight.bold};
  margin: ${props => props.theme.spacing(1)} 0;
  ${props => props.nowrap && 'white-space: nowrap;'}
`;

export const DetailLinkValue = styled.a`
  color: ${props => (props.black ? props.theme.color.text.main : props.theme.color.primary.main)};
  font-weight: ${props => props.theme.fontWeight.bold};
  margin: ${props => props.theme.spacing(1)} 0;
  text-decoration: none;
`;
