import styled from 'styled-components';
import { Button } from 'components/buttons';
import { FormItem } from 'components/styledComponents';

export const AddButton = styled(Button)`
  margin: ${props => props.theme.spacing(2)} 0;
`;

export const ContactSection = styled(FormItem)`
  align-content: flex-start;

  &&& {
    margin-bottom: 0;
    margin-top: 0;
  }
`;
