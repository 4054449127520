import { connect } from 'react-redux';
import * as metaSelectors from 'redux-modules/meta/selectors';
import * as uiStateSelectors from 'redux-modules/uiState/selectors';
import Resume from 'components/Resume';

function mapStateToProps(state, ownProps) {
  return {
    indexedMeta: metaSelectors.selectIndexedResumeMeta(state),
    language: uiStateSelectors.selectResumeLanguage(state),
    resume: ownProps.resume
  };
}

export default connect(mapStateToProps, null)(Resume);
