import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'hooks';
import { Document } from 'components/icons';
import {
  ActionsFooter,
  Button,
  CVLink,
  FooterContent,
  FooterItem,
  LinkText,
  Section
} from './style';

function Footer({ disabled, opportunity, readOnly, showCandidateCV, onAction, onClose, onReject, onNotAvailable }) {
  const { t } = useTranslation();

  return (
    <ActionsFooter>
      <FooterContent>
        {readOnly ? (
          <Section justify={'flex-end'}>
            <FooterItem as={Button} color="primary" label={t('button.close')} onClick={onClose} />
          </Section>
        ) : (
          <Section justify="flex-end" wrap="wrap-reverse">
            <FooterItem
              as={Button}
              label={t('button.notAvailable')}
              {...(onAction ? { variant: 'text' } : { color: 'primary' })}
              onClick={onNotAvailable}
            />
            <FooterItem
              as={Button}
              label={t('button.notInterested')}
              {...(onAction ? { variant: 'text' } : { color: 'primary' })}
              onClick={onReject}
            />
            <FooterItem
              as={Button}
              color="primary"
              disabled={disabled}
              label={t('button.agreeContinue')}
              onClick={onAction}
            />
          </Section>
        )}
      </FooterContent>
    </ActionsFooter>
  );
}

Footer.propTypes = {
  disabled: PropTypes.bool,
  opportunity: PropTypes.object.isRequired,
  readOnly: PropTypes.bool.isRequired,
  showCandidateCV: PropTypes.bool.isRequired,
  onAction: PropTypes.func,
  onClose: PropTypes.func.isRequired,
  onReject: PropTypes.func.isRequired
};

export default Footer;
