import styled from 'styled-components';
import { Loader as BaseLoader } from 'components/styledComponents';

const SPACING = 1.875;

export const ContentSection = styled.div`
  flex: ${props => (props.width ? '0 0 ' + props.width : '1 1')};
  margin: ${props => props.theme.spacing(1.5, 0)};
  flex: ${props =>
    `${props.size} 1 calc((100% - 2 * ${props.theme.spacing(SPACING)}) / 12 * ${props.size})`};
`;

export const Loader = styled(BaseLoader)`
  padding-top: 0px;
`;

export const LoaderContent = styled.div`
  display: flex;
  flex-wrap: wrap;
`;
