import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Title from 'components/Title';
import { Switch } from 'components/buttons';
import { DateField, SelectField, TextField, MultiLanguageTextField } from 'components/inputs';
import { FormContainer, FormItem, FormRow, FormTextItem } from 'components/styledComponents';
import { useLanguagesPaths, useTranslation } from 'hooks';
import DropdownTreeSelect from 'react-dropdown-tree-select';
import 'react-dropdown-tree-select/dist/styles.css';
import 'components/pages/ResumePage/sections/MultiTreeDropdown.css';

function Position({
  className,
  language,
  meta: { contractTypes, functions, countries, roleList },
  getValue,
  useInput,
  useTree,
  ...rest
}) {
  const { tr } = useTranslation();
  const { value: dateFrom } = { ...useInput('dateFrom') };
  const { onChange: onChangeDateTo } = useInput('dateTo');
  const isActualEngagement = getValue('isActualEngagement');

  useEffect(() => {
    if (isActualEngagement) {
      onChangeDateTo('');
    }
  }, [isActualEngagement, onChangeDateTo]);

  return (
    <FormContainer className={className} {...rest}>
      <FormRow>
        <FormItem component={Title} label={tr('label.position')} size="large" />
      </FormRow>
      <FormRow>
        <FormItem
          component={TextField}
          disabled
          label={tr('label.companyName')}
          required
          {...useInput('companyName')}
        />
        <FormItem />
      </FormRow>
      <FormRow>
        <FormItem
          component={MultiLanguageTextField}
          label={tr('label.jobTitle')}
          language={language}
          required
          {...useInput('name', { alternateFields: useLanguagesPaths('name') })}
        />
        <FormItem
          component={SelectField}
          label={tr('label.contractType')}
          options={contractTypes}
          required
          {...useInput('contractType')}
        />
      </FormRow>
      <FormRow>
        <FormItem
          component={MultiLanguageTextField}
          label={tr('label.placeOfOccupation')}
          language={language}
          {...useInput('placeOfOccupation', {
            alternateFields: useLanguagesPaths('placeOfOccupation')
          })}
        />
        <FormItem
          component={SelectField}
          label={tr('label.countryOfOccupation')}
          options={countries}
          {...useInput('countryOfOccupation')}
        />
      </FormRow>
      <span id="dTreeLabel">
        {' '}
        {tr('label.function')}
        <span aria-hidden="true" className="MuiFormLabel-asterisk MuiInputLabel-asterisk">
          {' '}
          *
        </span>
      </span>
      <FormRow>
        <FormItem
          className="mdl-demo"
          component={DropdownTreeSelect}
          keepTreeOnSearch={true}
          label={tr('label.function')}
          mode="radioSelect"
          texts={{ placeholder: ' ' }}
          {...useTree('function', functions)}
        />
        <FormItem
          component={SelectField}
          label={tr('label.role')}
          options={roleList}
          required
          {...useInput('role')}
        />
      </FormRow>
      <FormRow>
        <FormItem
          component={MultiLanguageTextField}
          label={tr('label.reportingTo')}
          language={language}
          {...useInput('reportingTo', { alternateFields: useLanguagesPaths('reportingTo') })}
        />
        <FormItem />
      </FormRow>
      <FormRow>
        <FormTextItem>
          {tr('message.doYouCurrentlyWork')}
          <Switch
            leftLabel={tr('button.no')}
            rightLabel={tr('button.yes')}
            {...useInput('isActualEngagement')}
          />
        </FormTextItem>
      </FormRow>
      <FormRow>
        <FormItem
          component={DateField}
          disableFuture
          label={tr('label.dateFrom')}
          required
          {...useInput('dateFrom')}
          wizardMode={true}
        />
        <FormItem
          component={DateField}
          disabled={getValue('isActualEngagement')}
          label={tr('label.dateTo')}
          minDate={dateFrom}
          required
          wizardMode={true}
          {...useInput('dateTo')}
        />
      </FormRow>
    </FormContainer>
  );
}

Position.propTypes = {
  className: PropTypes.string,
  getValue: PropTypes.func.isRequired,
  language: PropTypes.string.isRequired,
  meta: PropTypes.shape({
    contractTypes: PropTypes.array.isRequired,
    functions: PropTypes.array.isRequired,
    countries: PropTypes.array.isRequired,
    roleList: PropTypes.array.isRequired
  }).isRequired,
  useInput: PropTypes.func.isRequired,
  useTree: PropTypes.func.isRequired
};

export default Position;
