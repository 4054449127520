import React from 'react';
import PropTypes from 'prop-types';
import { Facebook, LinkedIn, Xing } from 'components/icons';
import { Container } from 'components/styledComponents';
import { useTranslation } from 'hooks';
import Link from 'components/Link';
import { Footer, Item, ItemsContainer, Section } from './style';

const EXTERNAL_LINKS = {
  de: {
    contact: 'https://www.atreus.de/kontakt',
    legalNotice: 'https://www.atreus.de/impressum/',
    privacyPolicy: 'https://www.atreus.de/datenschutz/'
  },
  en: {
    contact: 'https://www.atreus.de/en/contact/',
    legalNotice: 'https://www.atreus.de/en/legal-notice',
    privacyPolicy: 'https://www.atreus.de/en/data-protection-regulations-group'
  }
};

function AppFooter({ className, language }) {
  const { t } = useTranslation();
  const links = EXTERNAL_LINKS[language];

  return (
    <Container as={Footer} className={className}>
      <Section>
        <ItemsContainer spacing="1">
          <Item as={Link} component="a" href={links.contact} target="_blank">
            {t('link.contact')}
          </Item>
          <Item as={Link} component="a" href={links.legalNotice} target="_blank">
            {t('link.legalNotice')}
          </Item>
          <Item id="privacyPolicyStatement" as={Link} component="a" href={links.privacyPolicy} target="_blank">
            {t('link.privacyPolicy')}
          </Item>
        </ItemsContainer>
      </Section>
      <Section color="primary">
        <ItemsContainer spacing="1">
          <Item
            as={Link}
            component="a"
            href="https://www.linkedin.com/company/atreus-gmbh"
            target="_blank"
          >
            <LinkedIn />
          </Item>
          <Item
            as={Link}
            component="a"
            href="https://www.xing.com/companies/atreusinterimmanagement"
            target="_blank"
          >
            <Xing />
          </Item>
          <Item as={Link} component="a" href="https://www.facebook.com/atreus.gmbh" target="_blank">
            <Facebook />
          </Item>
        </ItemsContainer>
      </Section>
    </Container>
  );
}

AppFooter.propTypes = {
  className: PropTypes.string,
  language: PropTypes.string.isRequired
};

export default AppFooter;
