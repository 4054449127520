import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { selectFieldLengthGetter } from 'redux-modules/fieldsMeta/selectors';
import { stateToHTML } from 'draft-js-export-html';
import {clone} from 'lodash';

export default function wrapper(WrappedComponent) {
    return function (props) {
        let fieldName = "data-rttextarea-id";
        let getFieldLength = useSelector(selectFieldLengthGetter);
        let maxFieldLength = getFieldLength(props[fieldName]);
        const beforeChange = useCallback((nextState, currentState) => {
            if (!maxFieldLength) {
                return nextState;
            }
            let nextValue = stateToHTML(nextState.getCurrentContent());
            if (nextValue.length <= maxFieldLength) {
                return nextState;
            } else {
                return clone(currentState);
            }
        }, [maxFieldLength]);
        return <WrappedComponent {...props} beforeChange={beforeChange}/>
    }
}