import React, { useRef } from 'react';
import TimeField from 'react-simple-timefield';

function TimeInput(props) {
  const inputRef = useRef(null);

  const handleInputClick = () => {
    if (inputRef.current) {
      inputRef.current.setSelectionRange(0, 0);
    }
  };

  return (
    <>
      <TimeField {...props} inputRef={inputRef} onClick={handleInputClick} />
    </>
  );
}

export default TimeInput;
