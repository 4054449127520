import React from 'react';
import { useDateFormat } from 'hooks';
import { DetailValue } from './style';

export const detailsConfig = [
  {
    key: 'placementType',
    label: 'label.plannedPlacement',
    Value: (opportunity, { targetPositions }) => {
      if (opportunity.placementType && opportunity.placementType.length) {
        return opportunity.placementType.map(placementType => (
          <DetailValue key={placementType} value={targetPositions[placementType]} />
        ));
      }
    }
  },
  {
    key: 'positionTitle',
    label: 'label.positionTitle'
  },

  {
    key: 'position',
    label: 'label.function',
    // eslint-disable-next-line react/display-name
    Value: (opportunity, { functions }) => <DetailValue value={functions[opportunity.position]} />
  },
  {
    key: 'role',
    label: 'label.role',
    // eslint-disable-next-line react/display-name
    Value: (opportunity, { roleList }) => <DetailValue value={roleList[opportunity.role]} />
  },
  {
    key: 'industry',
    label: 'label.industry',
    Value: (opportunity, { industries }) => {
      if (opportunity.industry && opportunity.industry.length) {
        return opportunity.industry.map(industry => (
          <DetailValue key={industry} value={industries[industry]} />
        ));
      }
    }
  },
  {
    key: 'businessPerformance',
    label: 'label.businessPerformance',
    // eslint-disable-next-line react/display-name
    Value: (opportunity, { companySituations }) => (
      <DetailValue value={companySituations[opportunity.businessPerformance]} />
    )
  },
  {
    key: 'reportingTo',
    label: 'label.reportingTo'
  },
  {
    key: 'operationArea',
    label: 'label.operationArea',
    Value: (opportunity, { countriesRegion }) => {
      if (opportunity.operationArea && opportunity.operationArea.length) {
        return opportunity.operationArea.map(operationArea => (
          <DetailValue key={operationArea} value={countriesRegion[operationArea]} />
        ));
      }
    }
  },
  {
    key: 'location',
    label: 'label.location'
  },
  {
    key: 'startDate',
    label: 'label.expectedStart',
    // @TODO remove the React hook from here, maybe change value into a React component
    // eslint-disable-next-line react/display-name, react-hooks/rules-of-hooks
    Value: opportunity => {
      const plannedStartDateValue = useDateFormat(opportunity.plannedStartDate);
      const plannedEntryDateValue = useDateFormat(opportunity.startDate);
      if (opportunity.plannedStartDate) {
        return <DetailValue value={plannedStartDateValue} />;
      } else {
        return <DetailValue value={plannedEntryDateValue} />;
      }
    }
  },
  {
    key: 'daysPerMonth',
    label: 'label.daysOfUse',
    // eslint-disable-next-line react/display-name
    Value: (opportunity, { monthlyWorkingDays }) => (
      <DetailValue value={monthlyWorkingDays[opportunity.daysPerMonth]} />
    )
  },
  {
    key: 'durationInMonths',
    label: 'label.monthsDuration'
  },
  {
    key: 'mustHaveCoreCompetencies',
    label: 'label.coreCompetenciesMustHave',
    Value: (opportunity, { coreCompetencies }) => {
      if (opportunity.mustHaveCoreCompetencies && opportunity.mustHaveCoreCompetencies.length) {
        return opportunity.mustHaveCoreCompetencies.map(competency => (
          <DetailValue key={competency} value={coreCompetencies[competency]} />
        ));
      }
    }
  },
  {
    key: 'niceToHaveCoreCompetencies',
    label: 'label.coreCompetenciesNiceToHave',
    Value: (opportunity, { coreCompetencies }) => {
      if (opportunity.niceToHaveCoreCompetencies && opportunity.niceToHaveCoreCompetencies.length) {
        return opportunity.niceToHaveCoreCompetencies.map(competency => (
          <DetailValue key={competency} value={coreCompetencies[competency]} />
        ));
      }
    }
  },
  {
    key: 'annualRevenue',
    label: 'label.revenueResponsibility',
    // eslint-disable-next-line react/display-name
    Value: (opportunity, { anualRevenues }) => (
      <DetailValue value={anualRevenues[opportunity.annualRevenue]} />
    )
  },
  {
    key: 'personnelResponsibility',
    label: 'label.personnelResponsibility',
    // eslint-disable-next-line react/display-name
    Value: (opportunity, { employees }) => (
      <DetailValue value={employees[opportunity.personnelResponsibility]} />
    )
  },
  {
    key: 'directReports',
    label: 'label.numberOfReports',
    // eslint-disable-next-line react/display-name
    Value: (opportunity, { directReports }) => (
      <DetailValue value={directReports[opportunity.directReports]} />
    )
  },
  {
    key: 'costBudgetResponsibility',
    label: 'label.budgetResponsibility',
    // eslint-disable-next-line react/display-name
    Value: (opportunity, { budgets }) => (
      <DetailValue value={budgets[opportunity.costOrBudgetResponsibility]} />
    )
  },
  {
    key: 'mustHavePersonalCompetencies',
    label: 'label.personalCompetencesMustHave',
    Value: (opportunity, { personalCompetencies }) => {
      if (
        opportunity.mustHavePersonalCompetencies &&
        opportunity.mustHavePersonalCompetencies.length
      ) {
        return opportunity.mustHavePersonalCompetencies.map(competency => (
          <DetailValue key={competency} value={personalCompetencies[competency]} />
        ));
      }
    }
  },
  {
    key: 'niceToHavePersonalCompetencies',
    label: 'label.personalCompetencesNiceTotHave',
    Value: (opportunity, { personalCompetencies }) => {
      if (
        opportunity.niceToHavePersonalCompetencies &&
        opportunity.niceToHavePersonalCompetencies.length
      ) {
        return opportunity.niceToHavePersonalCompetencies.map(competency => (
          <DetailValue key={competency} value={personalCompetencies[competency]} />
        ));
      }
    }
  },
  {
    key: 'mustHaveExperience',
    label: 'label.specialExperience',
    Value: (opportunity, { companySituations }) => {
      if (opportunity.mustHaveExperience && opportunity.mustHaveExperience.length) {
        return opportunity.mustHaveExperience.map(experience => (
          <DetailValue key={experience} value={companySituations[experience]} />
        ));
      }
    }
  },
  {
    key: 'niceToHaveExperience',
    label: 'label.specialExperienceNiceTo',
    Value: (opportunity, { companySituations }) => {
      if (opportunity.niceToHaveExperience && opportunity.niceToHaveExperience.length) {
        return opportunity.niceToHaveExperience.map(experience => (
          <DetailValue key={experience} value={companySituations[experience]} />
        ));
      }
    }
  },
  {
    key: 'mustHaveLanguages',
    label: 'label.languagesMustHave',
    Value: (opportunity, { languages }) => {
      if (opportunity.mustHaveLanguages && opportunity.mustHaveLanguages.length) {
        return opportunity.mustHaveLanguages.map(language => (
          <DetailValue key={language} value={languages[language]} />
        ));
      }
    }
  },
  {
    key: 'niceToHaveLanguages',
    label: 'label.languagesNiceToHave',
    Value: (opportunity, { languages }) => {
      if (opportunity.niceToHaveLanguages && opportunity.niceToHaveLanguages.length) {
        return opportunity.niceToHaveLanguages.map(language => (
          <DetailValue key={language} value={languages[language]} />
        ));
      }
    }
  }
];
