import styled from 'styled-components';
import RadioGroup from '@material-ui/core/RadioGroup';

export const RadioGroupStyled = styled(RadioGroup)`
  label:first-child {
    margin-right: 45px;
    span {
      margin-right: 5px;
    }
  }
`;

export const Box = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 15px -3px 15px 10px;
  color: ${props => props.theme.color.grey[800]};

  @media (max-width: 907px) {
    align-items: start;
    flex-direction: column;
  }
`;

export const Text = styled.div`
  width: 600px;
`;
