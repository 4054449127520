import { createSelector } from 'reselect';
import * as uiStateSelectors from '../uiState/selectors';

export const selectAuthToken = state => state.auth.authToken;

export const selectShowAgreement = state => state.auth.showAgreement;

export const selectAgreementData = state => state.auth.agreementData;

export const selectSyncState = state => state.auth.syncing;
export const selectLoadState = state => state.auth.loading;
export const selectRegisterState = state => state.auth.registerStatus;

export const selectSyncError = state => state.auth.error;

export const selectResetPwdStatus = state => state.auth.resetPwdStatus;

export const selectValidityStatus = state => state.auth.isNewUserEmail;

export const selectIsResetPasswordTokenValid = state => state.auth.isResetPasswordTokenValid;

export const selectFullNDA1 = state => state.auth.nda1;
export const selectFullNDA2 = state => state.auth.nda2;
export const selectNDA1 = createSelector(
  [selectFullNDA1, uiStateSelectors.selectLanguage],
  (ndaObject, language) => ndaObject[language] && ndaObject[language].textContent
);
export const selectNDA2 = createSelector(
  [selectFullNDA2, uiStateSelectors.selectLanguage],
  (ndaObject, language) => ndaObject[language] && ndaObject[language].textContent
);
// RTEXT-726
export const selectIsAutomaticRelease = state => state.auth.isAutomaticRelease;

// RTEXT-956
export const selectVideoURLs = state => state.auth.videoURLs;

export const selectResendWelcomeEmail = state => state.auth.resendWelcomeEmail;

export const selectCanSkipAgreement = state => state.auth.canSkipAgreement;
