import styled from 'styled-components';

export const ActionsFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: ${props => props.theme.spacing(2)};
`;

export const Column = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  padding: ${props => props.theme.spacing(1)} 0;
`;

export const Title = styled.div`
  font-size: ${props => props.theme.fontSize.xsmall};
  min-width: 35%;
`;

export const Value = styled.div`
  color: ${props => props.theme.color.primary.main};
  font-size: ${props => props.theme.fontSize.large};
  text-transform: uppercase;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  white-space: nowrap;

  ${Column} {
    flex-grow: 1;
  }

  @media (min-width: 678px) {
    align-items: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
    margin: ${props => props.theme.spacing(-2)};
    overflow: hidden;

    ${Column} {
      align-items: flex-start;
      flex-direction: column;
      flex-grow: 1;
      padding: 0 ${props => props.theme.spacing(2)};
      margin: ${props => props.theme.spacing(2)} 0;
    }

    ${Column} + ${Column} {
      box-shadow: -1px 0px 0px 0px ${props => props.theme.color.grey[500]};
    }

    ${Title} {
      margin-bottom: ${props => props.theme.spacing(1.5)};
    }
  }
`;
