import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import Container from '@material-ui/core/Container';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import { ArrowDown, Document } from 'components/icons';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Snackbar from 'components/Snackbar';
import { Button } from 'components/buttons';
import { useTranslation } from 'hooks';
import useStyles, { FormFooter } from './style';
import moment from 'moment';
import FormHelperText from '@material-ui/core/FormHelperText';
import { ActionItem, DownloadLink, DownloadText, NDAContainer, NDAFooter, NDAText } from './style';
import AgreementHeader from 'components/AgreementHeader';
import { Header } from 'components/cards/cards/Card/components';

function TermsPage({
  history,
  syncing,
  nda1,
  nda2,
  agreementData,
  language,
  onInit,
  onAccept,
  onAbort,
  isRegister = false,
  setPolicyAccepted,
  canSkipAgreement,
  onSkipAgreement
}) {
  const { t } = useTranslation();
  const classes = useStyles();
  const [errors, setErrors] = useState({});
  const [termsAcceptanceData, setTermsAcceptanceData] = useState({
    termsAccepted: false,
    privacyAccepted: false,
    expandedPanel: 'none'
  });

  const [panelExpanded, setPanelExpanded] = useState({
    expanded: null
  });

  useEffect(() => {
    if (agreementData && agreementData.id && agreementData.isSuccess) {
      // RTEXT-748
      history.push('/dashboard', { fromLogin: true });
    }
  }, [agreementData]);

  useEffect(() => {
    onInit();
  }, []);

  useEffect(() => {
    if (isRegister) {
      const isTermsAccepted = JSON.parse(localStorage.getItem('termsAccepted'));
      const isPrivacyAccepted = JSON.parse(localStorage.getItem('privacyAccepted'));

      setTermsAcceptanceData(prev => ({
        ...prev,
        termsAccepted: isTermsAccepted,
        privacyAccepted: isPrivacyAccepted
      }));
    }
  }, [isRegister]);

  useEffect(() => {
    const { termsAccepted, privacyAccepted } = termsAcceptanceData;
    setPolicyAccepted && setPolicyAccepted(termsAccepted && privacyAccepted);
  }, [termsAcceptanceData.termsAccepted, termsAcceptanceData.privacyAccepted]);

  function handleChange(event) {
    const { name, checked } = event.target || {};
    setErrors({
      ...errors,
      [name]: null
    });
    setTermsAcceptanceData({
      ...termsAcceptanceData,
      contractDate: moment(new Date()).format('YYYY-MM-DD'),
      [name]: checked
    });
    localStorage.setItem(`${name}`, checked);
  }

  function handleSubmit(event) {
    const data = {
      ...termsAcceptanceData
    };
    onAccept(data);
  }

  function handleAbort(event) {
    onAbort();
    // decorated component with withRouter, and change the page location here
    // TODO: is there a better way?
    history.push('/login');
  }

  function handlePanelChange(panel, event, expanded) {
    if (!isRegister) {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    }
    setPanelExpanded({
      expanded: expanded ? panel : false
    });
  }

  function handleSkip() {
    onSkipAgreement();
    history.push('/dashboard');
  }

  return (
    <>
      {!isRegister && <AgreementHeader />}
      <Container style={isRegister ? { padding: '10px 0' } : {}}>
        <Snackbar
          message={errors.request}
          open={!!errors.request}
          variant="error"
          onClose={() => setErrors({ request: null })}
        />
        <div className={classes.paper}>
          <ExpansionPanel
            expanded={panelExpanded.expanded === 'terms'}
            id="terms"
            onChange={handlePanelChange.bind(this, 'terms')}
            style={{ width: '100%' }}
          >
            <ExpansionPanelSummary
              aria-controls="panel1a-content"
              expandIcon={<ArrowDown />}
              id="panel1a-header"
            >
              <Header className={classes['navlink.active']} title={t('title.agreementTerms')} />
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <NDAContainer>
                <NDAText>{nda1}</NDAText>
                {/*
                  commented in RTEXT-453 to enable plain text mode  
                  <NDAViewer as={PDFViewer}  data={`/api/v1/manager/nda/agreement-inline-file/${language}?timestamp=`+new Date().getTime()} >   
                  </NDAViewer> */}
                <NDAFooter justify="space-between">
                  <ActionItem
                    as={DownloadLink}
                    color="primary"
                    component="a"
                    href={
                      `/api/v1/manager/nda/agreement-file/${language}?timestamp=` +
                      new Date().getTime()
                    }
                    target="_blank"
                  >
                    <Document />
                    <DownloadText>{t('link.downloadPDF')}</DownloadText>
                  </ActionItem>
                </NDAFooter>
              </NDAContainer>
            </ExpansionPanelDetails>
          </ExpansionPanel>
          <ExpansionPanel
            expanded={panelExpanded.expanded === 'privacy'}
            id="privacy"
            onChange={handlePanelChange.bind(this, 'privacy')}
            style={{ width: '100%' }}
          >
            <ExpansionPanelSummary
              aria-controls="panel1a-content"
              expandIcon={<ArrowDown />}
              id="panel1a-header"
            >
              <Header className={classes['navlink.active']} title={t('title.dataProtection')} />
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <NDAContainer>
                <NDAText>{nda2}</NDAText>
                {/*
                  commented in RTEXT-453 to enable plain text mode 
                  <NDAViewer double_opt_in_cas={PDFViewer} data={`/api/v1/manager/nda/protection-inline-file/${language}?timestamp=`+new Date().getTime()}>
                  </NDAViewer> */}
                <NDAFooter justify="space-between">
                  <ActionItem
                    as={DownloadLink}
                    color="primary"
                    component="a"
                    href={
                      `/api/v1/manager/nda/protection-file/${language}?timestamp=` +
                      new Date().getTime()
                    }
                    target="_blank"
                  >
                    <Document />
                    <DownloadText>{t('link.downloadPDF')}</DownloadText>
                  </ActionItem>
                </NDAFooter>
              </NDAContainer>
            </ExpansionPanelDetails>
          </ExpansionPanel>
          <form className={classes.form} noValidate>
            <FormFooter>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={termsAcceptanceData.termsAccepted}
                    color="primary"
                    id="termsAccepted"
                    name="termsAccepted"
                    onChange={handleChange}
                  />
                }
                label={<label className={classes['text']}>{t('label.acceptTerms')}</label>}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={termsAcceptanceData.privacyAccepted}
                    color="primary"
                    id="privacyAccepted"
                    name="privacyAccepted"
                    onChange={handleChange}
                  />
                }
                label={<label className={classes['text']}>{t('label.acceptPrivacy')}</label>}
              />
            </FormFooter>
            <FormFooter>
              {!isRegister && (
                <>
                  {canSkipAgreement && (
                    <Button
                      className={classes.submit}
                      color="primary"
                      disabled={syncing}
                      label={t('button.skip')}
                      size="large"
                      onClick={handleSkip}
                    />
                  )}
                  <Button
                    className={classes.cancel}
                    color="primary"
                    disabled={syncing}
                    label={t('button.cancel')}
                    size="large"
                    onClick={handleAbort}
                  />
                  <Button
                    className={classes.submit}
                    color="primary"
                    disabled={
                      syncing ||
                      !termsAcceptanceData.termsAccepted ||
                      !termsAcceptanceData.privacyAccepted
                    }
                    label={t('button.agreeRegisters')}
                    size="large"
                    onClick={handleSubmit}
                  />
                </>
              )}
            </FormFooter>
            <div style={{ float: 'right', minWidth: '26%' }}>
              {agreementData && agreementData.error && (
                <FormHelperText error>{agreementData.error.message}</FormHelperText>
              )}
            </div>
          </form>
        </div>
      </Container>
    </>
  );
}

TermsPage.propTypes = {
  history: PropTypes.object.isRequired,
  syncing: PropTypes.bool.isRequired,
  onAccept: PropTypes.func.isRequired,
  language: PropTypes.string.isRequired,
  isRegister: PropTypes.bool,
  nda1: PropTypes.string,
  nda2: PropTypes.string,
  agreementData: PropTypes.object,
  onInit: PropTypes.func.isRequired,
  onAccept: PropTypes.func.isRequired,
  onAbort: PropTypes.func.isRequired,
  setPolicyAccepted: PropTypes.func,
  canSkipAgreement: PropTypes.bool,
  onSkipAgreement: PropTypes.func.isRequired
};
export default withRouter(TermsPage);
