import React, { useCallback } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'hooks';
import { Card } from 'components/cards';
import { Loader } from 'components/styledComponents';
import {
  Container,
  ButtonContainer,
  Button,
  LinkButton,
  Title,
  Line,
  FlexContainer
} from './style';
import Link from 'components/Link';
import PropTypes from 'prop-types';
import DropdownMenu from './DropdownMenu';

const enExamples = [
  {
    key: 'plant-manager',
    value: 'Plant Manager'
  },
  {
    key: 'it-expert',
    value: 'IT-Management'
  },
  {
    key: 'ceo',
    value: 'CEO / CRO'
  },
  {
    key: 'cfo',
    value: 'CFO'
  }
];

const deExamples = [
  {
    key: 'plant-manager',
    value: 'Werksleiter'
  },
  {
    key: 'it-expert',
    value: 'IT-Management'
  },
  {
    key: 'ceo',
    value: 'CEO / CRO'
  },
  {
    key: 'cfo',
    value: 'CFO'
  }
];
function ResumeActions({ loading, initialInputCv, language, onMarkComplete, ...rest }) {
  const { t } = useTranslation();
  const { url } = useRouteMatch();

  const handleMenuItemClose = useCallback((key, lang) => {
    window.location = `/api/v1/resume-actions/${key}/file/${lang}?timestamp=${new Date().getTime()}`;
  }, []);

  return (
    <>
      <Loader component={Card} loading={loading} title={t('title.mainCVActions')} {...rest}>
        <Container>
          <Title>{t('label.exampleProfiles')}</Title>
          <ButtonContainer>
            <FlexContainer>
              <LinkButton
                href={`/api/v1/resume-actions/instructions/file/${language}?timestamp=${new Date().getTime()}`}
              >
                {t('button.instructions')}
              </LinkButton>
            </FlexContainer>
            <DropdownMenu
              handleClose={handleMenuItemClose}
              language="de"
              name={t('label.german')}
              options={deExamples}
            />
            <DropdownMenu
              handleClose={handleMenuItemClose}
              language="en"
              name={t('label.english')}
              options={enExamples}
            />
          </ButtonContainer>
          <Line />
          <ButtonContainer>
            <Button color="primary" component={Link} to={`${url}/pdfcreation`}>
              {t('button.createCV')}
            </Button>
          </ButtonContainer>
          {initialInputCv === '100' && (
            <ButtonContainer>
              <Button color="toreaBay" onClick={() => onMarkComplete({ markComplete: true })}>
                {t('button.submitApplication')}
              </Button>
            </ButtonContainer>
          )}
        </Container>
      </Loader>
    </>
  );
}

ResumeActions.propTypes = {
  language: PropTypes.string,
  networkStatus: PropTypes.string,
  loading: PropTypes.bool.isRequired,
  readonly: PropTypes.bool.isRequired,
  onMarkComplete: PropTypes.func.isRequired,
  initialInputCv: PropTypes.string
};

export default ResumeActions;
