import React from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { StyledLink } from './style';

const Link = React.forwardRef(({ color, component: Component, children, ...rest }, ref) => {
  return (
    <StyledLink as={Component} color={color} ref={ref} {...rest}>
      {children}
    </StyledLink>
  );
});

Link.displayName = 'Link';

Link.defaultProps = {
  color: 'inherit',
  component: RouterLink
};

Link.propTypes = {
  color: PropTypes.string,
  component: PropTypes.oneOfType([PropTypes.func, PropTypes.node, PropTypes.object]),
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.object, PropTypes.string])
};

export default Link;
