export const defsSchema = {
  $id: 'defs.json',
  definitions: {
    address: {
      type: 'object',
      properties: {
        street: {
          allOf: [{ $ref: 'defs.json#/definitions/street' }, { minLength: 1 }]
        },
        postalCode: {
          allOf: [{ $ref: 'defs.json#/definitions/postalCode' }, { minLength: 1 }]
        },
        city: {
          allOf: [{ $ref: 'defs.json#/definitions/name' }, { minLength: 1 }]
        },
        country: {
          $ref: 'defs.json#/definitions/entityIdRequired'
        },
        state: { $ref: 'defs.json#/definitions/name' }
      },
      required: ['city', 'country', 'postalCode', 'street']
    },
    assignmentType: {
      type: 'string',
      allOf: [{ transform: ['trim'] }, { minLength: 1 }]
    },
    birthDate: {
      type: 'string'
      // allOf: [
      //   { transform: ['trim'] },
      //   {
      //     if: {
      //       minLength: 1
      //     },
      //     then: {
      //       //pattern: '^\\d{4}(-)(((0)[0-9])|((1)[0-2]))(-)([0-2][0-9]|(3)[0-1])$'
      //     }
      //   }
      // ]
    },
    city: {
      type: 'string',
      allOf: [{ transform: ['trim'] }, { minLength: 1 }]
    },
    companyName: {
      type: 'string',
      allOf: [{ transform: ['trim'] }, {}]
    },
    contractType: {
      type: 'string',
      allOf: [{ transform: ['trim'] }, { minLength: 1 }]
    },
    dailyRateFrom: {
      allOf: [
        {
          coerceString: {
            type: 'number'
          }
        },
        {
          type: 'number',
          allOf: [{ transform: ['trim'] }, { minLength: 1, minimum: 1 }]
        }
      ]
    },
    date: {
      type: 'string',
      format: 'date'
    },
    email: {
      type: 'string',
      allOf: [{ transform: ['trim'] }, { format: 'email' }]
    },
    entityId: {
      type: ['string']
    },
    entityIdRequired: {
      allOf: [{ $ref: 'defs.json#/definitions/entityId' }, { minLength: 1 }]
    },
    file: {
      type: 'string',
      allOf: [{ transform: ['trim'] }, { minLength: 1 }]
    },
    freeText: {
      type: 'string',
      allOf: [{ transform: ['trim'] }, {}]
    },
    freeTextField: {
      // RTEXT-859
      properties: {
        de: {
          allOf: [{ $ref: 'defs.json#/definitions/freeText' }, { minLength: 1 }]
        },
        en: {
          allOf: [{ $ref: 'defs.json#/definitions/freeText' }, { minLength: 1 }]
        }
      },
      anyRequired: ['de', 'en']
    },
    function: {
      type: 'string',
      allOf: [{ transform: ['trim'] }, { minLength: 1 }],
      items: [{ $ref: 'defs.json#/definitions/entityIdRequired' }]
    },
    isActualEngagement: {
      type: 'boolean'
    },
    jobTitle: {
      type: 'string',
      allOf: [{ transform: ['trim'] }, { minLength: 1 }]
    },
    languageCode: {
      type: 'string'
    },
    monthsSpent: {
      allOf: [
        {
          coerceString: {
            type: 'number'
          }
        },
        {
          type: 'number',
          minimum: 1,
          allOf: [{ transform: ['trim'] }, { minLength: 1 }]
        }
      ]
    },
    name: {
      type: 'string',
      allOf: [{ transform: ['trim'] }, {}]
    },
    nationality: {
      type: 'string'
    },
    operationalReadiness: {
      type: 'array',
      minItems: 1,
      items: [{ $ref: 'defs.json#/definitions/entityIdRequired' }]
    },
    manager_nationality: {
      type: 'array',
      items: [{ $ref: 'defs.json#/definitions/entityId' }]
    },
    password: {
      type: 'string',
      allOf: [{ transform: ['trim'] }, { minLength: 4 }]
    },
    password_create: {
      type: 'string',
      allOf: [
        { transform: ['trim'] },
        {
          pattern:
            '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\\!@#$%^&*()\\\\[\\]{}\\-_+=~`|:;"\'<>,./?])(?=.{6,})'
        }
      ]
    },
    password_login: {
      type: 'string',
      allOf: [{ transform: ['trim'] }, { minLength: 1 }]
    },
    phoneNumber: {
      type: 'string',
      allOf: [
        { transform: ['trim'] },
        {
          minLength: 4,
          pattern: '^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\\s/0-9]*$'
        }
      ]
    },
    postalCode: {
      type: 'string',
      allOf: [{ transform: ['trim'] }, {}]
    },
    richText: {
      type: 'string',
      allOf: [{ transform: ['trim'] }, {}]
    },
    richTextField: {
       // RTEXT-859
      properties: {
        de: {
          allOf: [{ $ref: 'defs.json#/definitions/richText' }, { minLength: 1 }]
        },
        en: {
          allOf: [{ $ref: 'defs.json#/definitions/richText' }, { minLength: 1 }]
        }
      },
      anyRequired: ['de', 'en']
    },
    role: {
      type: 'string',
      allOf: [{ transform: ['trim'] }, { minLength: 1 }]
    },
    socialIdentifier: {
      type: 'string',
      allOf: [{ transform: ['trim'] }]
    },
    street: {
      type: 'string',
      allOf: [{ transform: ['trim'] }, {}]
    },
    title: {
      type: 'string'
    },
    username: {
      type: 'string',
      allOf: [{ transform: ['trim'] }, { maxLength: 64, minLength: 1 }]
    },
    webLink: {
      type: 'string',
      allOf: [{ transform: ['trim'] }, { format: 'uri-reference' }]
    },
    socialNetworks: {
      type: 'string',
      allOf: [{ transform: ['trim'] }, { format: 'uri-reference' }, { minLength: 1 }]
    },
    documents: {
      type: 'array',
      items: [
        {
          type: 'object',
          properties: {
            subcategory: {
              allOf: [{ $ref: 'defs.json#/definitions/entityIdRequired' }]
            },
            file: {
              type: 'object',
            }
          },
          required: ['subcategory', 'file']
        },
        {
          type: 'object',
        }
      ]
    },
  }
};
