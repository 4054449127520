import { createSelector } from 'reselect';
import { EMPTY_META, MAPPING_P2S } from './constants';


const selectFieldsMetaState = state => state.fieldsMeta;
export const selectLoadedState = createSelector(
  [selectFieldsMetaState],
  (meta) =>
    !!(
      meta && meta.loaded
    )
);
export const selectFieldsMeta = createSelector(
  [selectFieldsMetaState],
  (meta) => Object.assign({}, EMPTY_META, meta)
);

export const selectFieldLengthGetter = createSelector(
  [selectFieldsMetaState],
  (fieldsMeta) => {
    return (fieldName) => {
      let sugarPointer = MAPPING_P2S[fieldName];
      if (!sugarPointer || !sugarPointer.module || !sugarPointer.field) {
        console.debug("DEBUG", "Mapping not found", `Portal Field ${fieldName}`, "Its sugar mapping", sugarPointer);
        return;
      }

      let sugarFieldMeta = fieldsMeta[sugarPointer.module] && fieldsMeta[sugarPointer.module][sugarPointer.field];
      if (!sugarFieldMeta) {
        console.log("DEBUG", "Sugar Field Meta not found OR its Null", `Portal Field ${fieldName}`, "Its sugar mapping", sugarPointer);
        return;
      }
      return sugarFieldMeta;
    }
  });
