import { connect } from 'react-redux';
import * as metaSelectors from 'redux-modules/meta/selectors';
import * as opportunitiesSelectors from 'redux-modules/opportunities/selectors';
import * as opportunitiesActions from 'redux-modules/opportunities/actions';
import OpportunityDialog from './OpportunityDialog';

function mapStateToProps(state) {
  return {
    indexedMeta: metaSelectors.selectIndexedMeta(state),
    syncing: opportunitiesSelectors.selectItemSyncStatus(state)
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    onReject: () => {
      dispatch(opportunitiesActions.rejectOpportunity(ownProps.opportunity));
    },
    onNotAvailable: () => {
      dispatch(opportunitiesActions.notAvailableForOpportunity(ownProps.opportunity));
    },
    onInit: () => {
      dispatch(opportunitiesActions.loadOpportunity(ownProps.opportunity));
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(OpportunityDialog);
