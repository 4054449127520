import React from 'react';
import createSvgIcon from '../createSvgIcon';

export default createSvgIcon(
  <React.Fragment>
    <path
      d="M20.4254826,8.47279157 C20.7166518,8.1781844 21.1915174,8.17539735 21.4861245,8.46656653 C21.7539492,8.73126578 21.7806003,9.14778022 21.5644728,9.44266525 L21.4923496,9.52720843 L15.057,16.037 L21.4892462,22.4696699 C21.7555128,22.7359365 21.7797188,23.1526002 21.5618643,23.4462117 L21.4892462,23.5303301 C21.2229796,23.7965966 20.8063159,23.8208027 20.5127045,23.6029482 L20.428586,23.5303301 L13.4696699,16.571414 C13.2045077,16.3062518 13.1792562,15.8916711 13.3947088,15.5980595 L13.4665665,15.5138755 L20.4254826,8.47279157 Z"
      transform="translate(17.479458, 16.000000) scale(-1, 1) translate(-17.479458, -16.000000)"
    />
  </React.Fragment>,
  'ArrowRight'
);
