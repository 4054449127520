import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { EditorState } from 'draft-js';
import { stateFromHTML } from 'draft-js-import-html';
import TextEditor from 'components/TextEditor';

function Details({ className, executiveSummary }) {
  const editorState = useMemo(
    () => EditorState.createWithContent(stateFromHTML(executiveSummary)),
    [executiveSummary]
  );

  return (
    <TextEditor
      className={className}
      data-test-id="executive-summary-text"
      editorState={editorState}
      readOnly
      value={executiveSummary}
    />
  );
}

Details.propTypes = {
  className: PropTypes.string,
  executiveSummary: PropTypes.string.isRequired
};

export default Details;
