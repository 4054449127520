import { useTranslation } from 'hooks';
import { useMemo } from 'react';

const useSubcategories = () => {
  const { t } = useTranslation();

  return useMemo(
    () => [
      {
        key: '400',
        value: t('options.subcategories.400')
      },
      {
        key: '500',
        value: t('options.subcategories.500')
      },
      {
        key: '1000',
        value: t('options.subcategories.1000')
      },
      {
        key: '1100',
        value: t('options.subcategories.1100')
      },
      {
        key: '1300',
        value: t('options.subcategories.1300')
      }
    ],
    [t]
  );
};

export default useSubcategories;
