import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Dialog from 'components/Dialog';
import { Button } from 'components/buttons';
import { Card } from 'components/cards';
import { Loader } from 'components/styledComponents';
import { useDateFormat, useForm, useTranslation } from 'hooks';
import Editor from './Editor';
import { ActionsFooter, Column, Container, Content, Title, Value } from './style';

function Availability({
  availability,
  className,
  loading,
  meta,
  syncErrors,
  syncing,
  onSave,
  ...rest
}) {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const handleClose = useCallback(() => setOpen(false), [setOpen]);
  const handleOpen = useCallback(() => setOpen(true), [setOpen]);
  const { useInput, handleReset, handleSubmit } = useForm({
    initialValues: availability,
    validationSchema: 'availabilityRatesSchema',
    onSubmit: onSave
  });
  let mobilityValue = '';
  if (meta.mobilityTypes.length && availability.mobility) {
    mobilityValue = meta.mobilityTypes.filter(item => {
      return item.key === availability.mobility;
    });
  }

  useEffect(() => {
    if (!syncErrors && !syncing) {
      handleClose();
    }
  }, [handleClose, syncErrors, syncing]);
  useEffect(() => {
    if (open) {
      handleReset();
    }
  }, [open, handleReset]);

  return (
    <React.Fragment>
      <Loader
        className={className}
        component={Card}
        loading={loading}
        title={t('title.availabilityAndRates')}
        {...rest}
      >
        <Container>
          <Content>
            <Column>
              <Title>{t('label.availableFrom')}</Title>
              <Value>{useDateFormat(availability.availableFrom) || '-'}</Value>
            </Column>
            <Column>
              <Title>{t('label.dailyRateFrom')}</Title>
              <Value>
                {availability.dailyRateFrom ? `${Math.round(availability.dailyRateFrom)} €` : '-'}
              </Value>
            </Column>
            <Column>
              <Title>{t('label.dailyRateTo')}</Title>
              <Value>
                {availability.dailyRateTo ? `${Math.round(availability.dailyRateTo)} €` : '-'}
              </Value>
            </Column>
            <Column>
              <Title>{t('label.mobility')}</Title>
              <Value>{availability.mobility ? mobilityValue && mobilityValue[0].value : '-'}</Value>
            </Column>
          </Content>
          <ActionsFooter>
            <Button
              color="primary"
              label={`+ ${t('button.edit')}`}
              size="small"
              variant="text"
              onClick={handleOpen}
            />
          </ActionsFooter>
        </Container>
      </Loader>

      <Dialog
        actionLabel={t('button.save')}
        disabled={syncing}
        fullWidth={true}
        maxWidth="md"
        open={open}
        syncing={syncing}
        title={t('title.editAvailabilityAndRates')}
        onAction={handleSubmit}
        onClose={handleClose}
      >
        <Editor meta={meta} useInput={useInput} />
      </Dialog>
    </React.Fragment>
  );
}

Availability.propTypes = {
  availability: PropTypes.object,
  className: PropTypes.string,
  loading: PropTypes.bool.isRequired,
  meta: PropTypes.object,
  syncErrors: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  syncing: PropTypes.bool.isRequired,
  onSave: PropTypes.func.isRequired
};

export default Availability;
