import React, { useCallback, useMemo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import RichTextField from 'components/inputs/RichTextField';
import { LanguageSelector } from './style';

function MultiLanguageRichTextField({ id, language, value, onChange, onAdd, onAddSync, ...rest }) {
  const [lang, setLang] = useState(language);
  // @TODO find a better way for updates on language change
  const [forceUpdate, setForceUpdate] = useState(true);
  const memoValue = useMemo(() => (value && typeof value === 'object' && value[lang]) || '', [
    value,
    lang
  ]);

  // RTEXT-859
  useEffect(() => {
    if (!value) {
      onAddSync && onAddSync(id, { de: '', en: '' });
    }
  }, []);

  const handleChange = useCallback(
    (newValue, tinymcePath = '') => {
      setForceUpdate(false);
      const tempValue = value && typeof value === 'object' ? value : {};
      onChange(
        {
          ...tempValue,
          [lang]: newValue
        },
        tinymcePath
      );
    },
    [lang, setForceUpdate, value, onChange]
  );

  const handleLanguageChange = useCallback(
    newLang => {
      onChange({}, 'langswitch');
      // RTEXT-859
      if (!value) {
        onChange({ de: '', en: '', multiLangFieldActiveLang: newLang });
      } else {
        onAdd && onAdd(`${id}.multiLangFieldActiveLang`, newLang);
      }
      setForceUpdate(true);
      setLang(newLang);
    },
    [id, onAdd, onChange, value]
  );

  return (
    <RichTextField
      headerComponent={<LanguageSelector selectedLanguage={lang} onChange={handleLanguageChange} />}
      {...rest}
      handleLanguageChange={handleLanguageChange}
      lang={lang}
      updateState={forceUpdate}
      value={memoValue}
      onChange={handleChange}
    />
  );
}

MultiLanguageRichTextField.defaultProps = {
  value: {}
};

MultiLanguageRichTextField.propTypes = {
  language: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
  onChange: PropTypes.func.isRequired,
  id: PropTypes.string,
  onAdd: PropTypes.func,
  onAddSync: PropTypes.func
};

export default React.memo(MultiLanguageRichTextField);
