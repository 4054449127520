import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Route, withRouter } from 'react-router-dom';
import Editor from './Editor';

function Education({ history, match: { url } }) {
  const handleClose = useCallback(() => history.push(url), [history, url]);

  return (
    <Route
      path={`${url}/education/:id?`}
      render={({ match }) => <Editor educationId={match.params.id} onClose={handleClose} />}
    />
  );
}

Education.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired
};

export default withRouter(Education);
