import { EMPTY_RESUME } from 'enums';
import * as types from './types';
import * as authTypes from 'redux-modules/auth/types';

const initialState = {
  error: null,
  loading: false,
  syncing: false,
  ...EMPTY_RESUME
};

// @TODO break into smaller reducers (one for each section)
function resume(state = initialState, action) {
  switch (action.type) {
    case types.LOAD_RESUME:
      return {
        ...state,
        error: null,
        loading: true
      };
    case types.SAVE_AVAILABILITY:
    case types.DELETE_EDUCATION:
    case types.DELETE_WORK_EXPERIENCE:
    case types.SAVE_EDUCATION:
    case types.SAVE_EXECUTIVE_SUMMARY:
    case types.SAVE_COMPETENCE_PROFILE:
    case types.SAVE_IT_SKILLS:
    case types.SAVE_LANGUAGES:
    case types.SAVE_OTHER_ACTIVITIES:
    case types.SAVE_PERSONAL_DETAILS:
    case types.SAVE_WORK_EXPERIENCE:
    case types.SAVE_NOTIFICATION_SETTINGS:
    case types.SAVE_COMPANY_GROUP:
    case types.DELETE_COMPANY_GROUP:
      return {
        ...state,
        error: null,
        syncing: true
      };
    case types.DELETE_PROFILE:
    case types.SAVE_RESUME_COMPLETE_ACK:
      return {
        ...state,
        error: null,
        loading: true,
        syncing: true
      };
    case types.RESUME_FAILED:
    case types.AVAILABILITY_FAILED:
    case types.EDUCATION_FAILED:
    case types.EXECUTIVE_SUMMARY_FAILED:
    case types.COMPETENCE_PROFILE_FAILED:
    case types.IT_SKILLS_FAILED:
    case types.LANGUAGES_FAILED:
    case types.OTHER_ACTIVITIES_FAILED:
    case types.PERSONAL_DETAILS_FAILED:
    case types.WORK_EXPERIENCE_FAILED:
    case types.NOTIFICATION_SETTINGS_FAILED:
    case types.FAILED_RESUME_COMPLETE_ACK:
      return {
        ...state,
        error: action.payload.error,
        loading: false,
        syncing: false
      };
    case types.RESUME_LOADED:
      return {
        ...state,
        ...action.payload.resume,
        loading: false
      };

    case types.UPDATE_AVAILABILITY:
      return {
        ...state,
        availability: action.payload.availability,
        syncing: false
      };

    case types.ADD_EDUCATION:
      return {
        ...state,
        education: [action.payload.education, ...state.education],
        syncing: false
      };
    case types.REMOVE_EDUCATION:
      return {
        ...state,
        education: state.education.filter(
          education => education.id !== action.payload.education.id
        ),
        syncing: false
      };
    case types.UPDATE_EDUCATION:
      return {
        ...state,
        education: state.education.map(education =>
          education.id === action.payload.education.id ? action.payload.education : education
        ),
        syncing: false
      };

    case types.UPDATE_EXECUTIVE_SUMMARY:
      return {
        ...state,
        executiveSummary: action.payload.executiveSummary,
        syncing: false
      };

    case types.UPDATE_COMPETENCE_PROFILE:
      return {
        ...state,
        competenceProfile: action.payload.competenceProfile,
        syncing: false
      };

    case types.UPDATE_IT_SKILLS:
      return {
        ...state,
        itSkills: action.payload.itSkills,
        syncing: false
      };

    case types.UPDATE_LANGUAGES:
      return {
        ...state,
        languageProficiencies: [...action.payload.languages],
        syncing: false
      };

    case types.UPDATE_OTHER_ACTIVITIES:
      return {
        ...state,
        otherActivities: action.payload.otherActivities,
        syncing: false
      };

    case types.UPDATE_PERSONAL_DETAILS:
      return {
        ...state,
        personalDetails: action.payload.personalDetails,
        syncing: false
      };
      case types.DONE_RESUME_COMPLETE_ACK:
      return {
        ...state,
        personalDetails: action.payload.personalDetails,
        syncing: false,
        loading: false
      };
    case types.UPDATE_NOTIFICATION_SETTINGS:
        return {
          ...state,
          notificationSettings: action.payload.notificationSettings,
          syncing: false
        };
    case types.ADD_WORK_EXPERIENCE:
      return {
        ...state,
        workExperience: action.payload.workExperience,
        syncing: false
      };
    case types.REMOVE_WORK_EXPERIENCE:
      return {
        ...state,
        workExperience: state.workExperience.filter(
          workExperience => workExperience.id !== action.payload.workExperience.id
        ),
        syncing: false
      };
    case types.UPDATE_WORK_EXPERIENCE:
      return {
        ...state,
        workExperience: action.payload.workExperience,
        syncing: false
      };
    case authTypes.LOGOUT:
      return initialState;
    default:
      return state;
  }
}

export default resume;
