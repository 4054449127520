import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import * as Icons from 'components/icons';
import { Button } from './style';

function IconButton({
  children,
  className,
  color,
  component: Component,
  disabled,
  icon,
  size,
  type,
  variant,
  onClick,
  ...rest
}) {
  const Icon = useMemo(() => (icon && Icons[icon]) || Icons.Question, [icon]);

  return (
    <Button
      as={Component}
      className={className}
      color={color}
      disabled={disabled}
      size={size}
      type={type}
      variant={variant}
      onClick={onClick}
      {...rest}
    >
      {children || <Icon />}
    </Button>
  );
}

IconButton.defaultProps = {
  color: 'default',
  size: 'medium',
  type: 'button',
  variant: 'text'
};

IconButton.propTypes = {
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.node, PropTypes.object]),
  className: PropTypes.string,
  color: PropTypes.oneOf(['danger', 'default', 'primary', 'secondary']).isRequired,
  component: PropTypes.oneOfType([PropTypes.func, PropTypes.node, PropTypes.object]),
  disabled: PropTypes.bool,
  icon: PropTypes.oneOfType([PropTypes.func, PropTypes.node, PropTypes.object]),
  size: PropTypes.oneOf(['small', 'medium', 'large']).isRequired,
  type: PropTypes.oneOf(['button', 'submit']),
  variant: PropTypes.oneOf(['lightText', 'text']).isRequired,
  onClick: PropTypes.func
};

export default React.memo(IconButton);
