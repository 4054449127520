import { connect } from 'react-redux';
import * as uiStateSelectors from 'redux-modules/uiState/selectors';
import * as placementsSelectors from 'redux-modules/placements/selectors';
import MonthlyReport from './MonthlyReport';

function mapStateToProps(state, ownProps) {
  return {
    language: uiStateSelectors.selectLanguage(state),
    isMonthlyReportSent: placementsSelectors.selectIsSentMonthlyReport(state),
  };
}

export default connect(mapStateToProps, null)(MonthlyReport);
