import styled from 'styled-components';
import { Button as BaseButton } from 'components/buttons';

export const Button = styled(BaseButton)`
  margin: 5px;
  padding: 7px;
  border: 2px solid ${props => props.theme.color.primary.main};
  box-shadow: none;
  min-width: unset !important;
  width: 100%;
  div {
    word-break: normal !important;
  }
`;
