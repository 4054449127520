import styled from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';
import Link from 'components/Link';

export default makeStyles(theme => ({
  paper: {
    margin: theme.spacing(3, 0),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  typo: {
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
    width: '100%',
    textTransform: 'uppercase'
  },
  formFooter: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: theme.spacing(2),
    width: '100%'
  },
  'navlink.active': {
    color: 'white',
    cursor: 'pointer',
    display: 'inline-block',
    marginBottom: '16px',
    paddingBottom: '16px',
    borderBottom: '1px solid #ccc'
  },
  'navlink.inactive': {
    color: '#C8C8C8',
    cursor: 'pointer'
  },
  // RTEXT-701
  input: {
    '& .MuiInputBase-root': {
      background: 'white'
    },
    '& .MuiFormHelperText-root.Mui-error': {
      color: 'white !important'
    },
    '& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline': {
      border: '1px solid white'
    }
  },
  error: {
    '& .MuiFormHelperText-root.Mui-error': {
      color: 'white !important'
    }
  },
  bold: {
    fontWeight: 'bold !important'
  },
  'locklink.active': {
    color: 'white',
    cursor: 'pointer',
    marginBottom: '16px',
    paddingBottom: '16px',
    borderBottom: '1px solid #ccc'
  }
}));

export const BackLink = styled(Link)`
  &::before {
    content: '› ';
  }
`;

export const Warning = styled.div`
  color: white;
  font-size: ${props => props.theme.fontSize.small};
  margin-top: 5px;
`;

export const Text = styled.div`
  font-weight: ${props => props.theme.fontWeight.bold};
  font-size: ${props => props.theme.fontSize.small};
  color: white;
  padding-top: ${props => (props.first ? '30px' : '20px')};
  text-align: center;
  a {
    color: white;
  }
`;

export const LockedContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
