import { connect } from 'react-redux';
import * as projectsSelectors from 'redux-modules/projects/selectors';
import * as projectsActions from 'redux-modules/projects/actions';
import * as authSelectors from 'redux-modules/auth/selectors';
import ProjectsPage from './ProjectsPage';

function mapStateToProps(state) {
  return {
    authToken: authSelectors.selectAuthToken(state),
    projectData: projectsSelectors.selectMyProjects(state)
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onInit: () => {
      var action = projectsActions.loadProjects();
      dispatch(action);
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ProjectsPage);
