import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'hooks';
import { SectionBars, SectionCell } from 'components/SectionBars';

function PlacementContact({ placement }) {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <SectionBars title={t('label.contactPerson')}>
        <SectionCell black={true} label={t('label.name')} value={placement.contactName} />
        <SectionCell
          black={true}
          label={t('label.phone')}
          nowrap={true}
          value={placement.contactPhone}
        />
        <SectionCell
          black={true}
          label={t('label.email')}
          link={placement.contactEmail ? 'mailto:' + placement.contactEmail : null}
          linkLabel={placement.contactEmail}
        />
      </SectionBars>
    </React.Fragment>
  );
}

PlacementContact.defaultProps = {
  placement: {}
};

PlacementContact.propTypes = {
  placement: PropTypes.object.isRequired
};

export default PlacementContact;
