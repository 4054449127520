import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';
import { createGlobalStyle, ThemeProvider } from 'styled-components';
import { StylesProvider } from '@material-ui/styles';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/styles';
import AppDataLoader from 'components/AppDataLoader';
import AppScrollToTop from 'components/AppScrollToTop';
import AuthContent from 'components/AuthContent';
import PrivateContent from 'components/PrivateContent';
import { ManagerExternalCVPage, OpportunityCandidateCVPage } from 'components/pages';
import { AuthRoute, PrivateRoute } from 'components/routes';
import rootStore from 'redux-modules';
import { defaultMuiTheme, defaultTheme } from 'themes';

const GlobalStyle = createGlobalStyle`
  html, body {
    height: 100%;
  }
  body {
    background-color: ${props => props.theme.color.background.default};
    color: ${props => props.theme.color.text.main};
    font-family: ${props => props.theme.fontFamily};
    overflow-y: scroll;
  }
  .public-DraftEditor-content > div > div {
    padding-bottom: 1em;
}
  * { word-break: break-word !important; }
  #root {
    background-color: ${props => props.theme.color.background.surface};
    box-shadow: ${props => props.theme.boxShadow.large};
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    max-width: 1600px;
    min-height: 100%;
    min-width: 350px;
  }
  
::-webkit-scrollbar {
    -webkit-appearance: none;
}

::-webkit-scrollbar:vertical {
    width: 12px;
}

::-webkit-scrollbar:horizontal {
    height: 12px;
}

::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, .20);
    border-radius: 10px;
    border: 2px solid #ffffff;
}

::-webkit-scrollbar-track {
    border-radius: 10px;  
    background-color: #ffffff; 
}
`;

// @TODO remove this when the application version will be displayed inside the interface
if (window) {
  Object.defineProperty(window, 'version', {
    get() {
      var xhr = new XMLHttpRequest();
      xhr.open('GET', '/api/version', false); // synchronous request
      xhr.send(null);
      return {
        'front-end': process.env.REACT_APP_VERSION,
        'back-end': xhr.responseText
      };
    }
  });
}

function App() {
  return (
    <StylesProvider injectFirst>
      <MuiThemeProvider theme={defaultMuiTheme}>
        <ThemeProvider theme={defaultTheme}>
          <Router>
            <AppScrollToTop />
            <Provider store={rootStore}>

              <GlobalStyle />
              <Switch>
                <AuthRoute
                  component={AuthContent}
                  path="/(forgot-password|login|register|reset-password|create-password)"
                />
                <Route
                  path="/manager/cv/:id"
                  render={routeProps => <ManagerExternalCVPage id={routeProps.match.params.id} />}
                />
                <Route
                  path="/candidate/cv/:id"
                  render={routeProps => (
                    <OpportunityCandidateCVPage candidateId={routeProps.match.params.id} />
                  )}
                />
                <PrivateRoute component={PrivateContent} />
              </Switch>
            </Provider>
          </Router>
        </ThemeProvider>
      </MuiThemeProvider>
    </StylesProvider>
  );
}

App.propTypes = {};

export default App;
