import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import Dialog from 'components/Dialog';
import { useForm, useTranslation } from 'hooks';
import DeleteDialogFooter from '../../DeleteDialogFooter';
import Form from './Form';

const components = {
  Footer: DeleteDialogFooter
};

function Editor({ education, educationId, syncErrors, syncing, onClose, onDelete, onSave }) {
  const { t, tr } = useTranslation();
  const didMountRef = useRef(false);
  const selectedEducation = useMemo(() => education.find(item => item.id === educationId), [
    education,
    educationId
  ]);
  const footerProps = useMemo(() => {
    if (selectedEducation) {
      return {
        onDelete: () => onDelete(selectedEducation)
      };
    }
  }, [selectedEducation, onDelete]);
  const { useInput, handleReset, handleSubmit } = useForm({
    initialValues: selectedEducation,
    validationSchema: 'educationSchema',
    onSubmit: onSave
  });
  const handleClose = useCallback(() => {
    handleReset();
    onClose();
  }, [handleReset, onClose]);

  useEffect(() => {
    if (didMountRef.current) {
      if (!syncErrors && !syncing) {
        handleClose();
      }
    } else {
      didMountRef.current = true;
    }
  }, [handleClose, syncErrors, syncing]);

  return (
    <Dialog
      actionLabel={t('button.save')}
      components={components}
      disabled={syncing}
      footerProps={footerProps}
      open={true}
      syncing={syncing}
      title={tr('title.education')}
      onAction={handleSubmit}
      onClose={handleClose}
    >
      <Form data-test-id="education-form" useInput={useInput} />
    </Dialog>
  );
}

Editor.propTypes = {
  education: PropTypes.array.isRequired,
  educationId: PropTypes.string,
  syncErrors: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  syncing: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired
};

export default Editor;
