import { defaultTheme } from 'themes';

const today = new Date();

export function getDayColors(day, utils) {
  if (utils.isSameDay(day, today)) {
    return {
      backgroundColor: defaultTheme.color.primary.main,
      textColor: defaultTheme.color.primary.contrast
    };
  }
  const weekDay = day.getDay();
  if (weekDay === 0 || weekDay === 6) {
    return {
      textColor: defaultTheme.color.disabled.transparentText
    };
  }

  return null;
}
