import React, { useState, useEffect } from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import Slide from '@material-ui/core/Slide';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

const styles = {
  snackbarStyleViaContentProps: {
    backgroundColor: '#206AF5',
    fontWeight: 'bold'
  }
};

function TransitionDown(props) {
  return <Slide {...props} direction="down" />;
}

function Notification({ state, position, duration, classes, message, resetGlobalState }) {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (state) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [state]);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') return;
    setOpen(false);
    resetGlobalState && resetGlobalState();
  };

  return (
    <>
      <Snackbar
        anchorOrigin={position}
        autoHideDuration={duration}
        ContentProps={{
          'aria-describedby': 'message-id',
          className: classes.snackbarStyleViaContentProps
        }}
        message={message}
        open={open}
        style={{ top: '100px' }}
        TransitionComponent={TransitionDown}
        onClose={handleClose}
      />
    </>
  );
}

Notification.propTypes = {
  state: PropTypes.bool,
  position: PropTypes.object,
  duration: PropTypes.number,
  classes: PropTypes.object,
  message: PropTypes.string,
  resetGlobalState: PropTypes.func
};

export default withStyles(styles)(Notification);
