export const baseStyle = {
  clearIndicator: (provided, state) => {
    return { ...provided, padding: '4px' };
  },
  control: (provided, state) => {
    return { ...provided, zIndex: '0' };
  },
  dropdownIndicator: (provided, state) => {
    return { ...provided, padding: '4px 8px' };
  },
  input: (provided, state) => {
    return {
      ...provided,
      display: 'flex',
      height: 'auto',
      margin: 0,
      padding: 0
    };
  },
  menu: (provided, state) => {
    return { ...provided, backgroundColor: 'white', minWidth: '200px', transform: 'translateZ(0)' };
  },
  menuPortal: (provided, state) => {
    return { ...provided, zIndex: 10 };
  },
  placeholder: (provided, state) => {
    return { ...provided, margin: 0 };
  },
  valueContainer: (provided, state) => {
    return {
      ...provided,
      alignItems: 'center',
      display: 'flex',
      flex: 1,
      flexWrap: 'wrap',
      padding: '0.5em 0'
    };
  }
};
