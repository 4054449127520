import React, {useEffect, useMemo} from 'react';
import PropTypes from 'prop-types';
import Title from 'components/Title';
import {MultiLanguageTextField, SelectField, TextField} from 'components/inputs';
import { FormContainer, FormItem, FormRow } from 'components/styledComponents';
import {useLanguagesPaths, useTranslation} from 'hooks';

function GroupInformation({
  className,
  language,
  meta: {
    countries,
  },
  useInput,
  companiesList,
}) {
  const { tr } = useTranslation();

  return (
    <FormContainer className={className}>
      <FormRow>
        <FormItem component={Title} label={tr('label.groupInformation')} size="large" />
      </FormRow>
        <FormRow>
            <FormItem
                component={TextField}
                label={tr('label.companyGroup')}
                required
                {...useInput('companyGroupName')}
            />
            <FormItem
                component={SelectField}
                label={tr('label.country')}
                options={countries}
                required
                {...useInput('companyGroupCountry')}
            />
        </FormRow>
      <FormRow>
          <FormItem
              component={SelectField}
              isMulti
              label={tr('label.companies')}
              options={companiesList}
              required
              {...useInput('companiesInGroup')}
          />
      </FormRow>
    </FormContainer>
  );
}

GroupInformation.propTypes = {
  className: PropTypes.string,
  language: PropTypes.string.isRequired,
  meta: PropTypes.shape({
    countries: PropTypes.array.isRequired,
  }).isRequired,
  useInput: PropTypes.func.isRequired
};

export default GroupInformation;
