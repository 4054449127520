import styled from 'styled-components';
import { ReactComponent as OldLogo } from './oldLogo.svg';
import { ReactComponent as NewLogo } from './newLogo.svg';

export const LogoSVG = styled(NewLogo)`
  color: ${props => props.theme.color.primary.main};
  height: 4em;
  padding: 8px;
`;

export const OldLogoSVG = styled(OldLogo)`
  color: ${props => props.theme.color.primary.contrast};
  ${props =>
    props.size === 'large'
      ? `
    font-size: 38px !important;
    margin-left: 5%;
    margin-top: 5%;
  `
      : ``}
`;
