import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import Dialog from 'components/Dialog';
import { filter, pick, map} from 'lodash';
import { useForm, useTranslation } from 'hooks';
import DeleteDialogFooter from '../../DeleteDialogFooter';
import {
  CompanyInformation,
  GroupInformation,
  OtherRelatedRoles,
  OtherRoles,
  Position,
  PositionReference,
  Responsibilities,
  WorkAbroad
} from './forms';
import { Form } from './style';

const components = {
  Footer: DeleteDialogFooter
};

const EMPTY_WORK_EXPERIENCE = {
  isActualEngagement: false
};

// @TODO cleanup the code from subcomponents of this editor
function Editor({
  syncErrors,
  syncing,
  workExperience,
  workExperienceId,
  onClose,
  onDelete,
  onSave,
  companyId,
  ComponentToRender,
}) {
  const { t, tr } = useTranslation();
  const didMountRef = useRef(false);
  const selectedWorkExperience = useMemo(
    () => workExperienceId && workExperience.find(item => item.id === workExperienceId),
    [workExperience, workExperienceId]
  );

  const wExpInCompany = useMemo(
      () => selectedWorkExperience && selectedWorkExperience.companyId && filter(workExperience, function(wp) { return wp.companyId === selectedWorkExperience.companyId; }).length,
      [workExperience, workExperienceId]
  );

  const selectedWorkExperienceCompany = useMemo(
      () => companyId && workExperience.find(item => item.companyId === companyId),
      [workExperience, companyId]
  );

  const selectedCompany = useMemo(
      () => pick(selectedWorkExperienceCompany, ['companyId', 'companyName', 'city', 'country', 'ownershipStructure', 'companyEmployees', 'companyTurnover', 'industry']),
      [workExperience, companyId]
  );
  const footerProps = useMemo(() => {
    if (selectedWorkExperience) {
      return {
        onDelete: () => onDelete(selectedWorkExperience),
        totalWexp: () => wExpInCompany,
      };
    }
  }, [selectedWorkExperience, onDelete]);


  const activeWorkExperience =  useMemo(
      () => {
        let result = selectedWorkExperience || EMPTY_WORK_EXPERIENCE;
        return selectedCompany ? Object.assign({}, result, selectedCompany) : result;
      },
      [selectedWorkExperience, selectedCompany, companyId]
  );

  if(ComponentToRender && ComponentToRender === 'CompanyInformation') {
    activeWorkExperience.moduleToSave = 'Company';
  } else {
    activeWorkExperience.moduleToSave = 'Position';
  }

  const { getValue, useInput, useTree, handleAdd, handleRemove, handleReset, handleSubmit } = useForm({
    initialValues: activeWorkExperience,
    validationSchema:  (ComponentToRender && ComponentToRender == 'CompanyInformation') ? 'companySchema' : 'workExperienceSchema',
    onSubmit: onSave
  });
  const handleClose = useCallback(() => {
    handleReset();
    onClose();
  }, [handleReset, onClose]);

  useEffect(() => {
    if (didMountRef.current) {
      if (!syncErrors && !syncing) {
        handleClose();
      }
    } else {
      didMountRef.current = true;
    }
  }, [handleClose, syncErrors, syncing]);
  if (ComponentToRender) {
    let formToRender = '';
    switch (ComponentToRender) {
      case 'CompanyInformation':
        formToRender = <Form as={CompanyInformation} useInput={useInput} useTree={useTree}/>
        break;
      default:
        formToRender = '';
        break;
    }
    return (
        <Dialog
            actionLabel={t('button.save')}
            components={components}
            disabled={syncing}
            open={true}
            syncing={syncing}
            title={tr('title.professionalExperience')}
            onAction={handleSubmit}
            onClose={handleClose}
        >
          {formToRender}
        </Dialog>
    )
  } else {
    return (
        <Dialog
            actionLabel={t('button.save')}
            components={components}
            disabled={syncing}
            footerProps={footerProps}
            open={true}
            syncing={syncing}
            title={tr('title.professionalExperience')}
            onAction={handleSubmit}
            onClose={handleClose}
        >
          {!workExperienceId &&
          (
              <Form as={CompanyInformation} useInput={useInput} useTree={useTree} />
          )}
          <Form as={Position} getValue={getValue} useInput={useInput} useTree={useTree} />
          <Form as={Responsibilities} getValue={getValue} useInput={useInput} />
          <Form as={WorkAbroad} useInput={useInput} onAdd={handleAdd} onDelete={handleRemove} />
          <Form as={PositionReference} getValue={getValue} useInput={useInput} />
        </Dialog>
    );
  }
}

Editor.propTypes = {
  syncErrors: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  syncing: PropTypes.bool.isRequired,
  workExperience: PropTypes.array.isRequired,
  workExperienceId: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired
};

export default Editor;
