import React from 'react';
import Radio from '@material-ui/core/Radio';
import Checkbox from '@material-ui/core/Checkbox';
import PropTypes from 'prop-types';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import {
  TimeTrackingOption,
  TimeTrackingOptionsContainer,
  Description,
  RadioGroupStyled,
  Border
} from '../style';

function TimeTrackingContainer({
  name,
  state,
  handleChange,
  options,
  type,
  description,
  removeBorder,
  shrink
}) {
  return (
    <>
      {type === 'radio' && (
        <>
          {description ? <Description>{description}</Description> : null}
          <TimeTrackingOptionsContainer>
            <RadioGroupStyled
              aria-label={name}
              name={name}
              row
              value={state || ''}
              onChange={handleChange}
            >
              {options &&
                options.map((option, index) => {
                  return (
                    <TimeTrackingOption key={index}>
                      <FormControlLabel
                        control={<Radio color="primary" />}
                        label={option.value}
                        labelPlacement="end"
                        value={option.key}
                      />
                    </TimeTrackingOption>
                  );
                })}
            </RadioGroupStyled>
          </TimeTrackingOptionsContainer>
          {!removeBorder && <Border />}
        </>
      )}

      {type === 'checkbox' && (
        <>
          {description ? <Description>{description}</Description> : null}
          <TimeTrackingOptionsContainer shrinkSpace={shrink ? 1 : 0}>
            {options &&
              options.map((option, index) => {
                return (
                  <TimeTrackingOption key={index}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={state}
                          name={name}
                          color="primary"
                          onChange={handleChange}
                        />
                      }
                      label={option.value}
                    />
                  </TimeTrackingOption>
                );
              })}
          </TimeTrackingOptionsContainer>
          {!state && <Border mt={shrink ? '20' : '5'} />}
        </>
      )}
    </>
  );
}

TimeTrackingContainer.propTypes = {
  name: PropTypes.string,
  state: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  handleChange: PropTypes.func.isRequired,
  options: PropTypes.array,
  type: PropTypes.string,
  description: PropTypes.string,
  removeBorder: PropTypes.bool,
  shrink: PropTypes.bool
};

export default TimeTrackingContainer;
