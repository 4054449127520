export const loginSchema = {
  type: 'object',
  properties: {
    username: { $ref: 'defs.json#/definitions/username' },
    password: { $ref: 'defs.json#/definitions/password_login' }
  },
  required: ['password', 'username']
};

export const forgotPasswordSchema = {
  type: 'object',
  properties: {
    email: { $ref: 'defs.json#/definitions/email' }
  },
  required: ['email']
};

export const updatePasswordShema = {
  type: 'object',
  properties: {
    password: {
      $ref: 'defs.json#/definitions/password_create'
    },
    confirmPassword: {
      allOf: [
        {
          $ref: 'defs.json#/definitions/password'
        },
        {
          const: {
            $data: '1/password'
          }
        }
      ]
    }
  },
  required: ['password', 'confirmPassword']
};

export const registerSchema = {
  type: 'object',
  properties: {
    address: { $ref: 'defs.json#/definitions/address' },
    attachmentFile: { $ref: 'defs.json#/definitions/file' },
    country: { $ref: `defs.json#/definitions/entityIdRequired` },
    email: { $ref: 'defs.json#/definitions/email' },
    firstName: {
      allOf: [{ $ref: 'defs.json#/definitions/name' }, { minLength: 1 }]
    },
    lastName: {
      allOf: [{ $ref: 'defs.json#/definitions/name' }, { minLength: 1 }]
    },
    password: { $ref: 'defs.json#/definitions/password_create' },
    zip: { $ref: 'defs.json#/definitions/postalCode' }
  },
  required: [
    'address',
    'attachmentFile',
    'country',
    'email',
    'firstName',
    'lastName',
    'password',
    'zip'
  ]
};
