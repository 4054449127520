import styled from 'styled-components';

export const Content = styled.div`
  font-size: ${props => props.theme.fontSize.base};
  font-weight: ${props => props.theme.fontWeight[props.weight]};
  overflow-wrap: break-word;
  overflow: hidden;
  white-space: pre-wrap;
`;

export const Label = styled.div`
  font-size: ${props => props.theme.fontSize.base};
  font-weight: ${props => props.theme.fontWeight[props.weight]};
`;

export const Container = styled.div`
  box-sizing: border-box;
  overflow: hidden;
  ${Content} {
    margin-top: ${props => props.theme.spacing(1)};
  }  
`;
