import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { format } from 'date-fns';
import { useTheme } from '@material-ui/core/styles';
import Collapse from 'components/Collapse';
import { IconButton } from 'components/buttons';
import Link from 'components/Link';
import { useLocale, useTranslation } from 'hooks';
import { Container, DetailedView } from './Detail/style';
import Detail from './Detail';
import {
  CompanyDetails,
  CompanyLabel,
  Duration,
  ExtraDetails,
  Text,
  TextWrapper,
  TitleWrapper,
  DottedHR,
} from './style';
import {ButtonWrapper} from "components/Resume/style";

const atreusBrandsColors = {
  myhead: '#F7950C',
  atreus: '#206AF5',
  atrova: '#F47320'
};

function Company({ className, data, editPath, expanded, indexedMeta, language, onExpand, expandCollapse }) {
  const [expandedIndex, setExpandedIndex] = React.useState('');
  const { countries, functions, industries, ownershipStructures, companyTurnoverList, employees } = indexedMeta;
  const { tr } = useTranslation();
  const locale = useLocale(language);

  const theme = useTheme();

  function getTimeDiff(from, to) {
    let timeObj = {
      diff: null,
      period: ''
    };
    if (from === '') {
      return timeObj;
    }

    let date1 = new Date(from);
    let date2 = to ? new Date(to) : new Date();
    return date2.getTime() - date1.getTime();
  }

  function getTimeOfCompanyExp(data, locale) {
    if (!data) {
      return null;
    }
    if (data.experiences.length === 1) {
      return getTimeOfExp(data.experiences[0]);
    } else {
      let totalMonthsDiff = data.experiences.reduce((totalDiff, experience) => {
        let diff = getTimeOfExp(experience);
        return totalDiff + (diff.years * 12 + diff.months);
      }, 0);
      const [years, months] = [Math.floor(totalMonthsDiff / 12), totalMonthsDiff % 12];
      const yearsText = years <= 1 ? tr('label.year') : tr('label.years');
      const monthsText = months <= 1 ? tr('label.month') : tr('label.months');
      const yearToShow = years > 0 ? `${years} ${yearsText} ` : '';
      const monthToShow = months > 0 ? `${months} ${monthsText}` : '';
      const periodText = yearToShow + monthToShow;

      return {
        years,
        months,
        text: periodText.trim()
      };
    }
  }

  function getTimeOfExp(experience) {
    if (!experience || !experience.dateFrom) {
      return {
        years: 0,
        months: 0,
        text: `-`
      };
    }
    let diffDate = new Date(getTimeDiff(experience.dateFrom, experience.dateTo));
    const totalMonthsDiff =
        (diffDate.getUTCFullYear() - 1970) * 12 +
        diffDate.getUTCMonth() +
        (diffDate.getDate() >= 15 ? 1 : 0);
    const [years, months] = [Math.floor(totalMonthsDiff / 12), totalMonthsDiff % 12];
    const yearsText = years <= 1 ? tr('label.year') : tr('label.years');
    const monthsText = months <= 1 ? tr('label.month') : tr('label.months');
    const yearToShow = years > 0 ? `${years} ${yearsText} ` : '';
    const monthToShow = months > 0 ? `${months} ${monthsText}` : '';
    const periodText = yearToShow + monthToShow;

    return {
      years,
      months,
      text: periodText.trim()
    };
  }

  // enrich company work experience with timing information
  const companyData = useMemo(() => {
    if (!data) return null;
    let companyData = data;
    companyData.timeInfo = {
      to: companyData.isActualEngagement
        ? tr('label.present')
        : companyData.dateTo
        ? format(new Date(companyData.dateTo), 'MMM yyyy', { locale })
        : '-',
      from: companyData.dateFrom
        ? format(new Date(companyData.dateFrom), 'MMM yyyy', { locale })
        : '-',
      period: getTimeOfCompanyExp(companyData).text
    };
    companyData.experiences.map(experience => {
      experience.timeInfo = {
        to: experience.isActualEngagement
          ? tr('label.present')
          : experience.dateTo
          ? format(new Date(experience.dateTo), 'MMM yyyy', { locale })
          : '-',
        from: experience.dateFrom
          ? format(new Date(experience.dateFrom), 'MMM yyyy', { locale })
          : '-',
        period: getTimeOfExp(experience).text
      };
      return companyData;
    });

    return companyData;
  }, [data, locale, tr]);

  const handleToggle = useCallback(
    dataId => {
      setExpandedIndex(dataId);
      if (dataId === null) {
        onExpand(false);
      } else {
        onExpand(true);
      }
    },
    [onExpand]
  );

  function contractTypeMapping(type)
  {
    let typeMap = {
      100: 'permanent',
      200: 'interim',
      300: 'consulting',
      400: 'selfEmployed',
      500: 'board',
      600: 'careerBreak',
    };
    return typeMap[type];
  }

  //Apply series of sorting after each cycle

  companyData.experiences && companyData.experiences.sort(function(a, b) {
    var keyA = new Date(a.dateTo),
        keyB = new Date(b.dateTo);
    if(a.dateTo == '') return -1;
    if(b.dateTo == '') return 1;
    if (keyA > keyB) return -1;
    if (keyA < keyB) return 1;
    return 0;
  });

  //Sort based on start date if end dates are equal
  companyData.experiences && companyData.experiences.sort(function(a, b) {
    var keyC = new Date(a.dateFrom),
        keyD = new Date(b.dateFrom);

    if (a.dateTo == b.dateTo && keyC > keyD) {
      return -1;
    }
    if (a.dateTo == b.dateTo && keyC < keyD) {
      return 1;
    }
    return 0;
  });

//now again sort the sorted array for start date for open ended end date
  companyData.experiences && companyData.experiences.sort(function(a, b) {

    var keyC = new Date(a.dateFrom),
        keyD = new Date(b.dateFrom);

    if (a.dateTo == '' && keyC > keyD) {
      return -1;
    }
    if(b.dateTo == '' && keyC < keyD) {
      return 1;
    }
    return 0;
  });

  return (
    <Container className={className}>
      <div className="clt">
        <TextWrapper>
          <div>
            <Text className="comp_name">
              <CompanyDetails size='24px' weight={300}>
                {companyData.timeInfo.from} - {companyData.timeInfo.to}{' '}
                {/*RTEXT-529, Hiding Duration in Company and Company Groups*/}
                {/*companyData.timeInfo.period && `(${companyData.timeInfo.period})`*/} {' | '}
                {companyData.companyName}
              </CompanyDetails>
              {editPath && (
                    <IconButton className="comp_edit_btn" component={Link} icon="Edit" to={`resume/company/${companyData.experiences && companyData.experiences[0] ? companyData.experiences[0].id : undefined }`} />
              )}
            </Text>
            <Text>
              <ExtraDetails>
                <b>{`${tr('label.cityCountry')}: `}</b>
                {companyData.city && companyData.city[language] ? `${companyData.city[language]}` : '-'}
                {companyData.companyCountry? `, ${countries[companyData.companyCountry]}` : ', -'}
                {` | `}
                <b>{`${tr('label.turnoverInMillion')}: `}</b>
                {companyData.companyTurnover ? `${companyTurnoverList[companyData.companyTurnover]}` : '-'}

                {` | `}
                <b>{`${tr('label.numberOfEmployees')}: `}</b>
                {companyData.companyEmployees ? `${employees[companyData.companyEmployees]}` : '-'}

                {` | `}
                <b>{`${tr('label.ownershipStructure')}: `}</b>
                {companyData.ownershipStructure ? ownershipStructures[companyData.ownershipStructure] : '-'}

                {` | `}
                <b>{`${tr('label.industry')}: `}</b>
                {companyData.industryDescription && companyData.industryDescription[language] ? companyData.industryDescription[language]: companyData.industry &&
                companyData.industry !== '10' &&
                industries[companyData.industry]
                    ? ` ${industries[companyData.industry]}`
                    : '-'}
              </ExtraDetails>
            </Text>
          </div>
        </TextWrapper>

        <ul className="list">
          {companyData.experiences.map(data => (
            <li className="listItem" key={data.id}>
              <DetailedView>
                <TitleWrapper>
                  <div>
                    <CompanyDetails className="compPost-title" bold size='14px' weight={700}>
                      {data.timeInfo.from} - {data.timeInfo.to} {' '}
                      {data.timeInfo.period && `(${data.timeInfo.period})`} {' '}
                      {data.name[language]
                        ? ' | ' + data.name[language]
                        : data.function
                        ? functions[data.function]
                        : '-'}
                    </CompanyDetails>
                    {editPath && (
                        <IconButton className="compPost-btn" component={Link} icon="Edit" to={`${editPath}/${data.id}`} />
                    )}
                  </div>
                </TitleWrapper>
              </DetailedView>
              <DottedHR className="compDivider"/>
              <Text>
                <div>
                {data.contractType ? (
                    <CompanyLabel className="position-Tag" black>{tr('label.'+ contractTypeMapping(data.contractType))}</CompanyLabel>
                ) : null}
                {data.commitment ? (
                    <CompanyLabel
                        className="position-Tag"
                        color={
                          atreusBrandsColors[data.commitment.toLowerCase()] ||
                          theme.palette.primary.main
                        }
                    >
                      {data.commitment}
                    </CompanyLabel>
                ) : null}
                </div>
                <ExtraDetails className="textClass">
                  <b>{`${tr('label.posLocation')}: `}</b>
                  {data.placeOfOccupation[language] ? `${data.placeOfOccupation[language]}` : '-'}
                  {data.countryOfOccupation ? `, ${countries[data.countryOfOccupation]}` : ', -'}
                </ExtraDetails>
                <Collapse
                    isWe={data.id === expandedIndex && expanded ? true : false}
                    open={data.id === expandedIndex && expanded ? true : false}
                    togglerPosition="top"
                    expandCollapse={expandCollapse}
                    visible={0}
                >
                  <Detail
                      data={data}
                      indexedMeta={indexedMeta}
                      language={language}
                  />
                </Collapse>
              </Text>
            </li>
          ))}

          <ButtonWrapper className="listItem">
            <IconButton component={Link} icon="Plus"
                        to={`resume/position-in-company/${companyData.experiences && companyData.experiences[0] ? companyData.experiences[0].companyId : undefined}`}/>
            <div className="compAddPosition"><Link to={`resume/position-in-company/${companyData.experiences && companyData.experiences[0] ? companyData.experiences[0].companyId : undefined}`}> <span>{tr('label.addPositionWithinCompany')}</span> </Link></div>
          </ButtonWrapper>
        </ul>
        <DottedHR className="compExp-divider"/>
      </div>
    </Container>
  );
}

Company.propTypes = {
  className: PropTypes.string,
  data: PropTypes.object.isRequired,
  editPath: PropTypes.string,
  expanded: PropTypes.bool,
  indexedMeta: PropTypes.shape({
    countries: PropTypes.object.isRequired,
    functions: PropTypes.object.isRequired,
    industries: PropTypes.object.isRequired
  }).isRequired,
  language: PropTypes.string.isRequired,
  onExpand: PropTypes.func
};

export default Company;
