import { connect } from 'react-redux';
import * as resumeSelectors from 'redux-modules/resume/selectors';
import PersonalDetails from './PersonalDetails';
import * as resumeActions from 'redux-modules/resume/actions';
import * as uiStateSelectors from 'redux-modules/uiState/selectors';


function mapStateToProps(state, ownProps) {
  return {
    fullName: ownProps.fullName || resumeSelectors.selectManagerFullName(state),
    user: ownProps.user || resumeSelectors.selectPersonalDetails(state),
    networkStatus: resumeSelectors.selectNetworkStatus(state),
    language: uiStateSelectors.selectResumeLanguage(state),
  };
}
function mapDispatchToProps(dispatch) {
  return {
    onMarkComplete: (data, failCallback) => dispatch(resumeActions.markResumeComplete(data, failCallback))
  };
}


export default connect(mapStateToProps, mapDispatchToProps)(PersonalDetails);
