import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Switch } from 'components/buttons';
import { DateField, MultiLanguageTextField } from 'components/inputs';
import { FormContainer, FormItem, FormRow, FormTextItem } from 'components/styledComponents';
import { useLanguagesPaths, useTranslation } from 'hooks';
import { DeleteButton, RoleWrapper } from './style';

function Form({ basePath, getValue, language, useInput, onDelete }) {
  const { tr } = useTranslation();
  const { onChange: onDateToChange } = useInput(`${basePath}.dateTo`);
  const isActualEngagement = getValue(`${basePath}.isActualEngagement`);

  useEffect(() => {
    if (isActualEngagement) {
      onDateToChange('');
    }
  }, [isActualEngagement, onDateToChange]);

  return (
    <RoleWrapper>
      <FormContainer>
        <FormRow>
          <FormItem
            component={MultiLanguageTextField}
            label={tr('label.jobTitle')}
            language={language}
            required
            {...useInput(`${basePath}.name`, {
              alternateFields: useLanguagesPaths(`${basePath}.name`)
            })}
          />
        </FormRow>
        <FormRow>
          <FormTextItem>
            {tr('message.doYouCurrentlyWork')}
            <Switch
              leftLabel={tr('button.no')}
              rightLabel={tr('button.yes')}
              {...useInput(`${basePath}.isActualEngagement`)}
            />
          </FormTextItem>
        </FormRow>
        <FormRow>
          <FormItem
            component={DateField}
            disableFuture
            label={tr('label.dateFrom')}
            required
            wizardMode
            {...useInput(`${basePath}.dateFrom`)}
          />
          <FormItem
            component={DateField}
            disabled={getValue(`${basePath}.isActualEngagement`)}
            disableFuture
            label={tr('label.dateTo')}
            minDate={getValue(`${basePath}.dateFrom`)}
            required
            wizardMode
            {...useInput(`${basePath}.dateTo`)}
          />
        </FormRow>
      </FormContainer>
      <DeleteButton color="danger" icon="Trash" onClick={onDelete} />
    </RoleWrapper>
  );
}

Form.propTypes = {
  basePath: PropTypes.string.isRequired,
  getValue: PropTypes.func.isRequired,
  language: PropTypes.string.isRequired,
  useInput: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired
};

export default Form;
