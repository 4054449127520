/* prettier-ignore */
/* tslint:disable:quotemark */
export default {
  common: {
    button: {
      add: "Ergänzen",
      addAnotherLanguage: "Sprache ergänzen",
      addContent: "Inhalte hinzufügen",
      addEmail: "E-Mail hinzufügen",
      addNewExperienceAbroad: "Auslandserfahrung hinzufügen",
      addNewRole: "Neue Position anlegen",
      addPhone: "Telefonnummer hinzufügen",
      addDocument: "Dokument hinzufügen",
      addSisterRole: "Position in Schwesterunternehmen hinzufügen",
      addSocialNetwork: "Soziale Netzwerke hinzufügen",
      agreeContinueNDA: "ZUSTIMMEN UND FORTFAHREN",
      agreeContinue: "INTERESSIERT UND VERFÜGBAR",
      agreeRegisters: "Zustimmen",
      cancel: "Abbrechen",
      chooseDocument: "Dokument auswählen",
      close: "Schließen",
      countedHide: "Weniger anzeigen ({{count}})",
      countedViewMore: "Mehr anzeigen ({{count}})",
      delete: "Löschen",
      deleteSisterCompany: "Schwesterunternehmen löschen",
      discardAndClose: "Verwerfen und schließen",
      done: "Fertig",
      downloadContract: "Vertrag herunterladen",
      edit: "Bearbeiten",
      endMonth: "Ende des Monats",
      help: "Hilfe",
      hideDetails: "DETAILS AUSBLENDEN",
      hideAllDetails: "ALLE DETAILS AUSBLENDEN",
      login: "Login",
      next: "Weiter",
      no: "Nein",
      notAvailable: "Nein, ich bin nicht verfügbar",
      notInterested: "Nein, ich bin nicht interessiert",
      ok: "Ok",
      opportunitiesPlacements: "Opportunities & Placements",
      retryPwdReset: "Passwort zurücksetzen",
      save: "Speichern",
      saveReport: "Bericht speichern",
      sendApplicationNow: "Jetzt Bewerbung an Atreus senden",
      sendReport: "Bericht senden",
      showDetails: "DETAILS EINBLENDEN",
      showAllDetails: "ALLE DETAILS EINBLENDEN",
      signIn: "Anmelden",
      signUp: "Registrieren",
      skip: "Überspringen",
      startWizardNow: "CV aktualisieren",
      submit: "Einreichen",
      updateCV: "Update CV",
      uploadProfilePhoto: "Profilfoto hochladen",
      viewDetails: "Details anzeigen",
      viewPaymentDetails: "Zahlungsdetails anzeigen",
      viewSubmittedCV: "Verwendetes Atreus Profil",
      yes: "Ja",
      uploadFile: "Upload",
      submitApplication: "Ich habe meinen CV vollständig und wahrheitsgemäß eingegeben",
      applyNow: "JETZT BEWERBEN",
      back: "ZURÜCK",
      deleteMyProfile: "Meinen Account löschen",
      deleteMyProfileNow: "Mein Profil jetzt löschen",
      createCV :"Mein Profil als PDF exportieren",
      profilePlantManager: "Werksleiter",
      instructions: "Anleitung",
      profileIT: "IT-Managment",
      uploadInvoice: "Upload Honorarrechnung",
      uploadExpenses: "Upload Reisekostenabrechnung",
      howTo: "ANLEITUNG",
      downloadPdf: "Download Pdf",
      addBreak: "+ Pause hinzufügen",
      requestNewLink: "Neuen Link anfordern",
    },
    columnTitle: {
      activity: "Aktivität",
      companyName: "Firmenname",
      contactEmail: "Kontakt E-mail",
      contactName: "Kontakt Atreus",
      contactPhone: "Kontakt Telefon",
      day: "Tag",
      details: "Details",
      jobTitle: "Titel der Position",
      nda: "NDA",
      period: "Zeitraum",
      placementName: "Name des Placements",
      opportunity: "Name der Opportunity",
      status: "Status"
    },
    error: {
      validation: {
        const: {
          confirmPassword: "Die Felder „Passwort“ und „Passwort wiederholen“ müssen übereinstimmen",
        },
        dependencies: {
          default: "$t(error.validation.required.default)"
        },
        format: {
          default: "Ungültiges {{format}} Format",
          dateFrom: "Ungültiges Datumsformat",
          dateTo: "Ungültiges Datumsformat"
        },
        formatMinimum: {
          default: "Ungültiger Wert",
          dateTo: "Das Enddatum muss nach dem Startdatum liegen"
        },
        invalid: "Ungültige Daten",
        minLength: {
          de: "$t(error.validation.required.de)",
          en: "$t(error.validation.required.en)",
          default: "$t(error.validation.required.default)"
        },
        pattern: {
          password: "Das Passwort muss mindestens 6 Zeichen enthalten, darunter mindestens einen Großbuchstaben, einen Kleinbuchstaben, eine Zahl und ein Sonderzeichen",
          default: "$t(error.validation.invalid)"
        },
        required: {
          de: "Eingabe auf Deutsch wird benötigt",
          en: "Eine Eingabe in Englisch ist erforderlich",
          default: "Dieses Feld wird benötigt"
        },
        type: {
          default: "$t(error.validation.invalid)"
        },
        minItems: {
          companiesInGroup: "Bitte hier mindestens ein Unternehmen auswählen"
        },
        minimum: {
          dailyRateTo: "Der Wert sollte größer als Null sein",
          dailyRateFrom: "Der Wert sollte größer als Null sein"
        }
      },
      http: {
        "40001": "Ungültiger Benutzername oder Passwort",
        "50301": "Server Verbindungsfehler"
      },
      file: {
        typeAndSize : "Bitte laden Sie das Dokument als PDF und einer maximalen Dateigröße von 15MB hoch. Danke."
      }
    },
    label: {
      abroadAssignmentType: "Art des Auslandseinsatzes",
      acceptPrivacy : "Verschwiegenheitserklärung akzeptieren",
      acceptTerms : "Grundsätze der Zusammenarbeit akzeptieren",
      agreed: "Einverstanden",
      groupInformation: "Firmen gruppieren",
      addPositionWithinCompany: "Position innerhalb dieser Firma hinzufügen",
      annualTargetIncome: "Jahreszieleinkommen bei Festanstellung",
      applicationBody: "Bewerbungstext",
      applied: "Bewerbung laufend",
      assistantPhone: "Telefon-Assistenz",
      atreusPermanentPosition: "Festanstellung durch Atreus",
      availabilityComment: "Kommentar zur Verfügbarkeit",
      availableFrom: "Verfügbar ab",
      billing: "Abrechnung",
      budgetResponsibility: "Kosten und Einkaufsbudgetverantw.",
      businessPerformance: "Unternehmenssituation",
      category: "Kategorie",
      certificate: "Zertifikat",
      city: "Stadt",
      closedd: "Geschlossen",
      comingSoon: "Coming soon",
      comments: "Ergänzende Informationen",
      company: "Unternehmen",
      companies: "Unternehmen",
      companyGroup: "Firmengruppe",
      companyInformation: "Informationen zur Firma",
      companyName: "Firma",
      companySituation: "Kontext",
      confirmPassword: "Passwort bestätigen",
      contactDetails: "Kontaktdaten",
      contactPerson: "Ihre Kontaktperson bei Atreus",
      contractType: "Vertragsart",
      coreCompetencies: "Kernkompetenzen",
      coreCompetenciesMustHave: "Kernkompetenzen (Must-have)",
      coreCompetenciesNiceToHave: "Kernkompetenzen (Nice-to-have)",
      countries: "Länder",
      country: "Land",
      countryAbroad: "Auslandserfahrung",
      cityCountry: "Firmensitz",
      posLocation: "Einsatzort",
      courseOfStudies: "Studiengang",
      currencyTargetIncome: "$t(label.annualTargetIncome)",
      currencyTargetIncomeTitle: "Jahreszieleinkommen in Euro",
      dailyActivities: "Tätigkeitsnachweis",
      dailyActivitiesProgress: "Tätigkeitsnachweise",
      dailyOverview: "Tätigkeitsnachweis",
      dailyRate: "Tagessatz",
      dailyRateFrom: "Tagessatz von",
      dailyRateTo: "Tagessatz bis",
      dateFrom: "Startdatum",
      dateOfBirth: "Geburtsdatum",
      dateTo: "Enddatum",
      daysOfUse: "Einsatztage pro Monat",
      de: "$t(label.german)",
      degree: "Abschluss",
      description: "Beschreibung",
      details: "Ausbildungsbeschreibung",
      documentDate: "Dokumentendatum",
      email: "E-Mail",
      emailAddress: "E-Mail-Adresse",
      en: "$t(label.english)",
      english: "Englisch",
      executiveSummary: "Executive Summary",
      expectedStart: "Erwartetes Startdatum",
      experienceAbroad: "Auslandserfahrung",
      facebook: "Facebook",
      fax: "Fax",
      firstName: "Vorname",
      fileName: "Dateiname",
      fullName: "Vollständiger Name",
      function: "Funktion",
      gender: "Geschlecht",
      german: "Deutsch",
      highestEducation: "Dies ist mein höchster Bildungsabschluss",
      homePhone: "Telefon privat",
      hours: "Stunden",
      industry: "Branche",
      industryDescription: "Branchenbeschreibung",
      inProgress: "In Vorbereitung",
      institution: "Bildungseinrichtung",
      itSkillsRemarks: "Zusätzliche Angaben zu IT-Kenntnissen",
      jobTitle: "Jobtitel",
      placeOfOccupation: "Tätigkeitsort DE",
      countryOfOccupation: "Einsatzland DE",
      language: "Sprache",
      languagesMustHave: "Sprachen (fließend oder besser) (Must-have)",
      languagesNiceToHave: "Sprachen (fließend oder besser) (Nice-to-have)",
      lastCVUpdate: "Ihr letztes Update",
      lastName: "Nachname",
      level: "Niveau",
      linkedin: "LinkedIn",
      loading: "Wird geladen",
      location: "Ort",
      logActivity: "Aktivität protokollieren",
      lost: "Verloren",
      mandateClosed: "Mandat geschlossen",
      mandateInPrepration: "Mandatsvorbereitung",
      mobileNumber: "Telefon mobil",
      mobilePhone1: "Telefon mobil 1",
      mobilePhone2: "Telefon mobil 2",
      mobility: "Mobilität",
      mobilityComment: "Anmerkungen zur Mobilität",
      monthlyBill: "Monatliche Rechnung",
      monthlyReport: "Monatsbericht",
      monthlyReportClientRelation: "GF, Vorstand, etc.",
      monthlyReportGoals: "Geplant, erreicht",
      monthlyReportSituation: "Was läuft gut? Was läuft weniger gut?",
      monthsDuration: "Dauer in Monaten (max.)",
      monthsSpent: "Dauer in Monaten",
      name: "Name",
      nationality: "Nationalität(en)",
      new: "Neu",
      newPassword: "Neues Passwort",
      notAgreed: "Nicht zugestimmt",
      notificationReceived: "Benachrichtigung erhalten",
      numberOfEmployees: "Anzahl der Mitarbeiter",
      numberOfMonths: "{{count}} Monate",
      numberOfReports: "Anzahl der Direct Reports",
      officeEmail: "E-Mail Arbeit",
      ongoing: "Laufend",
      operationArea: "Einsatzgebiet",
      operationalReadiness: "Einsatzbereitschaft",
      otherPhone: "Weitere Telefonnummer",
      ownCompany: "Eigene Firma",
      ownershipStructure: "Eigentümerstruktur",
      password: "Passwort",
      personalCompetencesMustHave: "Persönliche Kompetenzen (Must-have)",
      personalCompetencesNiceTotHave: "Persönliche Kompetenzen (Nice-to-have)",
      personnelResponsibility: "Eigene Personalverantwortung",
      phone: "Telefon",
      plannedPlacement: "Geplante Platzierungsart",
      portalLanguage: "Portal- & Kommunikationssprache",
      position: "Angaben zur Position",
      positionInformation: "Positionsinformationen",
      positionTitle: "Titel der Position",
      present: "Heute",
      presentation: "Präsentation",
      presented: "Präsentiert",
      primaryEmail: "Primäre E-Mail",
      privateEmail: "E-Mail privat",
      provisionalMandate: "Vorläufiges Mandat",
      readMore: "Weiterlesen",
      referenceComment: "Kommentar zum Referenzgeber",
      referenceContact: "Kontaktdaten des Referenzgebers",
      referenceName: "Name des Referenzgebers",
      referencePosition: "Position des Referenzgebers",
      reportingTo: "Berichtend an (Position)",
      requested: "Angefordert",
      revenueResponsibility: "Umsatzverantwortung",
      salutation: "Anrede",
      select: "Auswählen",
      selectDate: "Tag wählen",
      selectMonth: "Monat wählen",
      sisterCompanyName: "Name der Schwesterfirma",
      skypeId: "Skype ID",
      socialNetwork: "Soziale Netzwerke",
      specialExperience: "Erfahrung in Sondersituationen (Must-have)",
      specialExperienceNiceTo: "Erfahrung in Sondersituationen (Nice-to-have)",
      startingDate: "Startdatum",
      startNow: "Jetzt starten",
      street: "Straße und Hausnummer",
      timesheetMissing: "offen",
      timesheetSubmitted: "eingetragen",
      title: "Titel",
      trainingTitle: "Ausbildungsbezeichnung",
      turnoverInMillion: "Jahresumsatz",
      twitter: "Twitter",
      typeOfSchool: "Schulart",
      typeOfTraining: "Ausbildungsart",
      typeSomething: "Schreiben Sie etwas...",
      uploadCV: "CV hochladen *",
      website: "Webseite",
      websiteUrl: "Webseite URL",
      won: "Gewonnen",
      workPhone: "Telefon Arbeit",
      xing: "Xing",
      youtubeVideo: "Link auf Bewerbungsvideo",
      zip: "Postleitzahl",
      opportunityUpdates: "Opportunity Updates",
      cvUpdates: "CV Updates",
      appointmentUpdates: "Terminerinnerungen",
      month: "Monat",
      months: "Monate",
      year: "Jahr",
      years: "Jahre",
      permanent: "FESTANSTELLUNG",
      interim: "INTERIM",
      consulting: "BERATUNG",
      selfEmployed: "SELBSTSTÄNDIG",
      board: "BEIRAT/AR",
      careerBreak: "AUSZEIT",
      industryFocuses: "Branchenschwerpunkte",
      specialistFocuses: "Fachliche Schwerpunkte",
      managementMethods: "Managementstil / Arbeitsweise",
      showMore: "Mehr anzeigen",
      showLess: "Weniger anzeigen",
      competenceInfoText: "Bitte beschreiben Sie Ihre Schwerpunkte präzise und auf den Punkt. Verwenden Sie dafür Bullet Points.",
      registeredSuccessfully: 'Vielen Dank, Ihre Bewerbung wird geprüft. Wir bitten Sie um einige Tage Geduld!',
      emailAlreadyExists: "\"Dieser Benutzername existiert bereits\" - bitte nutzen Sie die <a href='/forgot-password'>\"Passwort vergessen\"</a>-Funktion, falls Sie Ihr Passwort nicht mehr kennen.",
      maxUploadSizeError: "Leider überschreitet Ihre Datei die maximale Uploadgröße von 10 Megabyte.",
      uploadDocRequired: "CV wird benötigt",
      principlesOfCollaboration: "Grundsätze der Zusammenarbeit",
      confidentialityStatement: "Verschwiegenheitserklärung",
      deletionReason: "Löschgrund",
      role: "Rolle",
      cvType: "CV-Typ",
      howLongDidYouWork: "Wie lange waren Sie heute tätig?",
      didYouTravelFromSite: "Sind Sie heute vom Wohnort zum Projektort gereist?",
      didYouTravelFromHome: "Sind Sie heute vom Projektort zum Wohnort gereist?",
      willYouStay: "Werden Sie heute am Projektort übernachten?",
      placeOfWork: "Einsatzort",
      businessTrip: "Sind Sie heute noch anderweitig vom Kunden beauftragt dienstlich gereist?",
      homeOffice: "Home Office",
      fee: "Honorar",
      travelExpensesFlatRate: "Reisekosten",
      flatRate: "Pauschale",
      firstLevel: "Reisepauschale (One Way)",
      secondLevel: "Übernachtungspauschale",
      travelTravelExpensesFlatRate: "Gesamt RK-Pauschale",
      bonus: "Bonus",
      subTotal: "Zwischensumme",
      invoiceAmount: "Rechnungssumme",
      verify: "Prüfen",
      vat: "MwSt.",
      days: "Tage",
      abort: "Abbrechen",
      submit: "Einreichen",
      saveSuccess: "Erfolgreich gespeichert!",
      atreus: "ATREUS",
      portal: "PORTAL",
      manualReleaseSuccess: "Success",
      automaticReleaseSuccess: "Herzlich Willkommen im Atreus Portal. Los geht´s!",
      welcomeVideo: "WILLKOMMEN BEI ATREUS",
      exampleProfiles: "Anleitung & Beispielprofile",
      chooseColor: "Wählen Sie Ihr Farbschema",
      chooseLanguage: "Wählen Sie die Sprache und den CV-Typ",
      chooseCoverSheet: "Wählen Sie Ihr Deckblatt",
      warning: "Warnung:",
      accountLocked: 'Account Gesperrt',
      workStart: 'Arbeit von',
      workEnd: 'Arbeit bis',
      pauseStart: 'Pause von',
      pauseEnd: 'Pause bis'
    },
    link: {
      aboutAtreus: "Über Atreus",
      backToLogin: "Zurück zum Login",
      contact: "Kontakt",
      downloadPDF: "PDF herunterladen",
      forgotPassword: "Passwort vergessen?",
      home: "Home",
      legalNotice: "Impressum",
      noAccountSignUp: "Noch kein Konto? Jetzt registrieren",
      opportunitiesPlacements: "Opportunities & Placements",
      privacyPolicy: "Datenschutz",
      projects: "Projekte",
      resume: "Mein CV",
      tutorials: "Tutorial",
      viewApplicantCV: "Atreus Profil anzeigen",
      atreusDE: 'ATREUS.DE',
    },
    loremIpsum: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    menu: {
      loginSettings: "Login-Einstellungen",
      loginTitle: "Jetzt anmelden",
      logout: "Ausloggen",
      myProfile: "Mein Profil",
      notificationSettings: "Benachrichtigungseinstellungen",
      downloadFAQ: "FAQ / Anleitung",
      myDocuments: "Meine Dokumente",
    },
    message: {
      activityDescription: "Bitte beschreiben Sie Ihre heutigen Aktivitäten:",
      activityManagement: "Verwalten Sie hier zentral und übersichtlich Ihre Opportunities und Placements.",
      addMoreInformation: "Informationen ergänzen",
      afterEachMonth: "Nach jedem Monat",
      anythingElseToAdd: "Hier ist Raum für Publikationen, Ehrenämter etc.",
      assistantWidget: "Zukünftig wird dieses Feature Sie in Ihrer Arbeit unterstützen. Freuen Sie sich darauf.",
      changePasswordError: "Ihr Passwort konnte nicht geändert werden. Bitte kontaktieren Sie Atreus.",
      changePasswordSuccess: "Ihr Passwort wurde erfolgreich gespeichert. Bitte kehren Sie zum Login zurück, um sich anzumelden.",
      changePasswordTokenExpiredError: "Ihr Passwort-Zurücksetzen-Token ist abgelaufen. Bitte versuchen Sie es noch einmal.",
      confirmDelete: "Sind Sie sicher, dass Sie dies LÖSCHEN möchten? Die Daten können anschließend nicht wieder hergestellt werden.",
      confirmDeleteWithOneWexp: "Sind Sie sicher, dass Sie diese Position löschen möchten? Dabei wird auch die Firma gelöscht.",
      createPasswordTokenExpiredError: "<div>Der verwendete Link ist ungültig oder abgelaufen.</div><div>Bitte fordern Sie einen neuen an. Diesen senden wir umgehend an Ihre bei uns als Benutzernamen hinterlegte E-Mail-Adresse. Überprüfen Sie bitte ggf. auch Ihren Spam-Ordner. Vielen Dank</div>",
      dailyActivities: "Bitte protokollieren Sie während des Monats Ihre Aktivitäten (Tätigkeitsnachweis), indem Sie auf die entsprechenden Tage im Kalender klicken.",
      describeResponsibilities: "Bitte beschreiben Sie Ihre Verantwortung, Aufgaben und Ziele dieser Berufsstation",
      doYouCurrentlyWork: "Ist dies ein aktuelles Engagement ohne Enddatum?",
      documentsMessage: "Info: Wenn Sie eines oder mehrere Ihrer Dokumente löschen möchten, melden Sie sich bitte über <a href='mailto:support@atreus.de'>support@atreus.de</a> bei uns. Nennen Sie uns hierzu den bzw. die exakten Dateinamen, die Sie gelöscht haben möchten.",
      duringMonth: "Während des Monats",
      feedback: "Helfen Sie uns, diesen Service zu verbessern: <a href='mailto:feedback@atreus.de?subject=Atreus Portal Feedback&body=Bitte geben Sie hier Ihr Feedback ein. Vielen Dank.'>Feedback geben</a>",
      fillOutDetails: "Füllen Sie die Details wie Bildung, Erfahrung, Fähigkeiten und andere Abschnitte aus, um Ihren Lebenslauf zu vervollständigen.",
      forgotPassword: "Bitte nennen Sie Ihre hinterlegte <strong>E-Mail Adresse</strong>. Wir senden Ihnen anschließend einen Link zum Zurücksetzen Ihres Passwortes.",
      highlightPrimaryEducation: "Neue Aus- oder Weiterbildung anlegen",
      highlightWorkExperience: "Neue Berufserfahrung anlegen",
      interestThankYou: "Sie sind an dieser Opportunity interessiert und entsprechend kurzfristig verfügbar - das freut uns sehr!",
      introduceYourself: "Was zeichnet Sie aus?",
      listSkills: "IT-Kenntnisse hinzufügen",
      loginError: "Passwort oder Benutzername ungültig. Bitte versuchen Sie es später noch einmal oder kontaktieren Sie uns unter <a href='mailto:support@atreus.de'>support@atreus.de</a>",
      maxSelections: "Auswahllimit erreicht",
      monthlyBill: "Bitte erstellen Sie nach jedem Monat eine Rechnung. Dies ist nur möglich, nachdem die Tätigkeitsnachweise und der monatliche Bericht fertiggestellt wurden.",
      monthlyReport: "Bitte füllen Sie den Monatsbericht im Laufe des Monats aus und reichen Sie ihn nach jedem Monat ein.",
      nda: "$t(loremIpsum)",
      newOpportunities: "Bevor Sie Details zur neuen Opportunity sehen können, akzeptieren Sie unsere Verschwiegenheitserklärung",
      noOptions: "Keine Optionen",
      notificationsWidget: "Bald finden Sie hier gesammelt und übersichtlich Ihre Kommunikation im Portal.",
      otherRoles: "Hatten Sie weitere Positionen in diesem Unternehmen?",
      parentOrSisterCompany: "Hatten Sie weitere Positionen in einer Schwestergesellschaft?",
      referenceQuestion: "Möchten Sie zu dieser Position Referenzen angeben?",
      resetPwdError: "Ihr Passwort konnte nicht zurückgesetzt werden. Bitte kontaktieren Sie uns unter support@atreus.de",
      resetPwdSuccess: "Sollte es bereits einen Nutzer mit derselben E-Mail-Adresse geben, senden wir an diese E-Mail-Adresse eine Aufforderung, das Passwort zurückzusetzen.",
      sectionIsEmpty: "Dieser Bereich ist leer!",
      selectItSkills: "Bitte wählen Sie Ihre IT-Skills aus der Liste",
      speakForeignLanguages: "Sprechen Sie weitere Sprachen?",
      supportMessage: "Wenn Sie noch Hilfe benötigen, kontaktieren Sie den <a href='mailto:support@atreus.de'>Atreus Support</a>",
      thankYouVeryMuch: "Vielen Dank für Ihre Mithilfe.",
      threeCoreCompetencies: "Welche Ihrer Kernkompetenzen (max. 3) haben Ihnen in dieser Position am meisten geholfen? (Auswahlwerte abhängig von ausgewählter Funktion.)",
      timesheetDescription: "Wenn Ihnen ein aktives Placement zugewiesen wird, können Sie hier Ihre Tätigkeitsnachweise erfassen.",
      timeTrackingFinishMonthConfirm: "Nach diesem Vorgang können Sie die aktuellen Monatsdaten nicht mehr bearbeiten. Möchten Sie den aktuellen Monat wirklich absenden?",
      untilThenUpdateCV: "Sobald Sie Ihr Interesse auf diesem Weg bekundet haben, werden wir uns mit Ihnen in Verbindung setzen und weitere Details besprechen. In der Zwischenzeit wäre es hilfreich, wenn Sie Ihren CV im Portal auf den neusten Stand bringen.",
      useFieldBelowForApplication: "Bitte nutzen Sie das folgende Feld, um uns in einer kurzen Zusammenfassung mitzuteilen, welche Ihrer Stärken, Kompetenzen und Fähigkeiten auf die Anforderungen dieses Kundenprojektes einzahlen.",
      workAbroadQuestion: "Waren Sie im Rahmen dieser Position im Ausland tätig?",
      writeEducationDescription: "Bitte beschreiben Sie Ihre Ausbildung",
      deleteQuestion: "Schade, dass Sie Ihren Account bei uns löschen möchten. Auch wenn wir Sie als Manager/-in nur ungern verlieren möchten, kommen wir Ihrer Bitte selbstverständlich nach.",
      reasonOfDeletion: "Wir möchten immer besser werden. Daher ist es hilfreich, wenn Sie uns noch den Grund nennen, warum Sie Ihre Daten bei uns löschen möchten. Selbstverständlich sind diese Angaben optional.",
      ensuredDeletion: "Zusammen mit Ihrem Account werden auch alle zugehörigen bei uns gespeicherten Daten gelöscht. Sobald dies geschehen ist, erhalten Sie eine schriftliche Bestätigung zum Abschluss des Vorgangs.",
      manyThanks: "Vielen Dank",
      deletionRequestSubmitted: "Ihr Antrag auf Löschung Ihrer Daten wurde erfolgreich eingereicht.",
      deletionConfirmationEmail: "Sie erhalten nach Abschluss der Löschung in unseren Systemen eine Bestätigungsmail.",
      tripDescription: "Bitte rechnen Sie ggf. entstandene Reisekosten für vom Kunden beauftragte Dienstreisen gemäß Vertrag direkt mit dem Kunden unter Berücksichtigung dessen Reisekostenrichtlinie ab.",
      noReporting: "Für dieses Placement ist kein Berichtswesen über das Portal vorgesehen.",
      invoiceContentHeader: "Gemäß Vertrag und Ihrer Eingaben im Tätigkeitsnachweis können Sie für diesen Monat folgende Rechnung stellen:",
      uploadFileMsg1: "Loggen Sie zuerst im <b>„Tätigkeitsnachweis“</b> jeden Tag, den Sie gearbeitet haben. Dies kann nach Einreichen Ihrer Abrechnung nicht mehr geändert werden.",
      uploadFileMsg2: "Beantworten Sie im Bereich <b>„Monatsbericht“</b> die Fragen, damit wir stets informiert sind und ggf. unterstützen können.",
      uploadFileMsg3: "Erstellen Sie Ihre Rechnung anhand der Zahlen links und klicken Sie auf <b>„Prüfen“</b>. Ein Parser zeigt Ihnen an, ob wir formelle Fehler in Ihrer Rechnung gefunden haben. Korrigieren Sie diese ggf.",
      uploadFileMsg4: "Klicken Sie auf <b>„Einreichen“</b>, um Atreus die Tätigkeitsnachweise, den Monatsbericht und die Rechnung zur Prüfung zu übermitteln.",
      submitReportMsg1: "Vielen Dank für das Einreichen Ihrer Rechnung. Ihre Angaben werden derzeit von unserer Buchhaltung geprüft. Sobald das Prüfverfahren durchlaufen wurde, wird der Status dieses Monats auf „abgeschlossen“ gesetzt.",
      submitReportMsg2: "Sollten Sie Fragen zu Themen rundum Tätigkeitsnachweis, Monatsbericht oder Abrechnung haben, kontaktieren Sie bitte Ihren Atreus Ansprechpartner.",
      finishReportMsg1: "Die Abrechnung für diesen Monat wurde erfolgreich abgeschlossen.",
      finishReportMsg2: "Sollten Sie Fragen zu Themen rundum Tätigkeitsnachweis, Monatsbericht oder Abrechnung haben, kontaktieren Sie bitte Ihren Atreus Ansprechpartner.",
      monthlyReportSaveConfirm: "Möchten Sie den monatlichen Bericht wirklich speichern?",
      profilePicNote: "Hinweis: Bitte verwenden Sie ein <b>quadratisches</b> Profilbild.",
      newApplicantQuestion1: "Waren oder sind Sie interimistisch und/oder unternehmerisch tätig?",
      newApplicantQuestion2: "Waren Sie in Beratungsunternehmen mit >10 Mitarbeitern als Projektleiter oder Partner tätig?",
      newApplicantQuestion3: "Hatten Sie Führungspositionen in international tätigen Unternehmen inne? (Mittelstand/Konzern)",
      newApplicantQuestion4: "Waren Sie als Geschäftsführer in Unternehmen >30 Mio. EUR Umsatz tätig?",
      newApplicantQuestion5: "Waren Sie in C-Level-Funktionen in Unternehmen >100 Mio. EUR Umsatz tätig?",
      newApplicantQuestion6: "Waren Sie in C-Level-Funktionen in Unternehmen >300 Mitarbeiter tätig?",
      newApplicantQuestion7: "Haben Sie Transformationserfahrung als Projektmitglied oder -leiter von mehr als 12 Monaten in Themen wie z.B. Restrukturierung, Digitalisierung, Sanierung, IT-Transformationen etc.?",
      newApplicantQuestion8: "Waren Sie als Projektleiter in Projekten mit Budgets >1 Mio. EUR oder >20 Mitarbeitern tätig?",
      manualRelease: "Vielen Dank, Ihre Bewerbung wird geprüft. Wir bitten Sie um einige Tage Geduld!",
      automaticReleaseHeader: "Wir bedanken uns für die Beantwortung der vorangegangenen Fragen. Damit wir Sie ins Atreus Netzwerk aufnehmen können, tragen Sie nun bitte Ihr digitales Profil ins Portal ein. Dazu erhalten Sie in den nächsten Minuten eine E-Mail mit Zugangsdaten zum Atreus Portal. (Überprüfen Sie ggf. bitte auch Ihren Spam-Ordner.)<br /><br /> Um Sie optimal auf mögliche Mandatsanfragen matchen zu können, ist es wichtig, dass Sie Ihren Werdegang im Detail hinterlegen. Gehen Sie dazu bitte folgendermaßen vor:",
      automaticReleaseFooter: "Wir überprüfen Ihr Profil und berücksichtigen Sie ab diesem Moment in unseren Suchanfragen. Wir freuen uns, wenn es zu einer Zusammenarbeit kommt. <br/><br/>Ihr Atreus Team",
      videoMessage: 'Dieser Kurzfilm enthält alle wichtigen Informationen für Ihren Bewerbungsprozess. Am Ende dieses Videos kommen Sie durch Klick auf “Weiter” zum nächsten Schritt der Bewerbung.',
      linkedinProfile: 'Bitte hier die URL ihres LinkedIn-Profils hinterlegen',
      xingProfile: 'Bitte hier die URL ihres XING-Profils hinterlegen',
      warningMsg: "Ihr Konto wird nach 3 fehlgeschlagenen Versuchen für 60 Minuten gesperrt.",
      lockedMsg1: "Leider haben Sie die Anzahl der maximal möglichen Anmeldeversuche überschritten.",
      lockedMsg2: "Um Ihren Daten zu schützen wurde Ihr Account für eine Stunde gesperrt.",
      lockedMsg3: "Sollten Sie Fragen haben, melden Sie sich gerne unter <a href='mailto:support@atreus.de' target='_blank'>support@atreus.de</a>",
      travelExpenseConfirmation1: "Sind für den Monat Reisekosten entstanden?",
      travelExpenseConfirmation2: "Falls ja, bitte den UPLOAD-Button nutzen. Wenn nein, mit EINREICHEN-Button fortsetzen.",
      welcomeEmailSent: 'NEUEN LINK ERHALTEN?<br/><br />Überprüfen Sie bitte ggf. auch Ihren Spam-Ordner. Vielen Dank.<br/><br />Wurde bereits ein Passwort vergeben, nutzen Sie bitte den Button "ZURÜCK ZUM LOGIN" und dort bei Bedarf die "Passwort vergessen" Funktion.',
    },
    placeholder: {
      profileImage: "Profilbild"
    },
    title: {
      activityManagement: "Aktivitäts-Management",
      addContent: "Inhalte hinzufügen",
      additionalInformation: "Ergänzende Informationen",
      address: "Adresse",
      addressInformation: "Adress-Information",
      applicantCV: "Lebenslauf des Bewerbers",
      applicationDate: "Antragsdatum",
      applicationDetails: "IHR INTERESSE",
      atreusOffers: "Atreus Angebote für Interim Manager",
      atreusOpportunity: "Opportunity von Atreus",
      availabilityAndRates: "Verfügbarkeit & Tagessatz",
      backToLogin: '› Zurück zum Login',
      commentPersonal: "Persönliche Anforderungen",
      contactDetails: "Kontaktdetails",
      createPassword: "Erstellen Sie Ihr Passwort",
      currentPlacements: "Aktuelle Placements",
      dailyRate: "Tagessatz in Euro",
      dailyRateSubTitle: "für ein sechs- bis zwölfmonatiges Mandat",
      descriptionCompany: "Unternehmensbeschreibung",
      documentUpload: "Dokumente-Upload",
      earliestAvailability: "Früheste Verfügbarkeit",
      editAvailabilityAndRates: "Verfügbarkeit & Tagessatz",
      education: "Ausbildung",
      addCompanyGroup: "Firmengruppe hinzufügen",
      executiveSummary: "Executive Summary",
      forgottenPassword: "Passwort vergessen?",
      fullName: "Vollständiger Name",
      itSkills: "IT-Skills",
      languages: "Sprachen",
      loginTitle: "Jetzt anmelden",
      mobility: "Mobilität",
      monthlyReportActions: "Konkret bearbeitete Aufgaben, Maßnahmen und Aktionen",
      monthlyReportClientRelation: "Beziehung zum Auftraggeber/Sponsor",
      monthlyReportGoals: "Ziele, Erfolge und Ergebnisse",
      monthlyReportInhibitions: "Kritische/hemmende Faktoren",
      monthlyReportNeededSupport: "Unterstützung notwendig?",
      monthlyReportOtherRemarks: "Sonstige Anmerkungen",
      monthlyReportSituation: "Situation",
      myAccount: "Mein Konto",
      myAssistant: "Digitaler Assistent",
      myResume: "Mein CV",
      newOpportunities: "Eine neue Opportunity von Atreus",
      newOpportunities_plural: "{{count}} neue Opportunities von Atreus",
      nonDisclosure: "NDA / Verschwiegenheitserklärung",
      notifications: "Benachrichtigungen",
      opportunities: "Opportunities",
      opportunityGeneralInfo: "Allgemeine Details zu dieser Opportunity",
      opportunityManagement: "Opportunity Management",
      otherActivities: "Andere Aktivitäten",
      personalDetails: "Persönliche Angaben",
      personalInformation: "Persönliche Informationen",
      placements: "Placements",
      placementOverview: "Placement Übersicht",
      professionalExperience: "Berufserfahrung",
      addWorkExperience: "Berufserfahrung hinzufügen",
      projectName: "Projektname",
      references: "Referenz",
      resetPassword: "Passwort zurücksetzen",
      responsibilitiesAndTasks: "Ihre Aufgaben und Verantwortungsbereiche",
      resumeProgress: "Fortschritt CV",
      signIn: "Anmelden",
      socialNetwork: "Soziale Netzwerke",
      technicalRequirement: "Fachliche Anforderungen",
      timeTrackingFinishMonthConfirm: "Aktuellen Monat abschließen?",
      upcomingEvents: "Kommende Veranstaltungen",
      updatePassword: "Ändern Sie Ihr Passwort",
      welcome: "Herzlich Willkommen",
      workExperience: "Berufserfahrung",
      yourApplicationForThisOpp: "Ihr Interesse an dieser Opportunity",
      yourMonthlyChecklist: "Ihre monatliche Checkliste",
      agreementTerms: 'Grundsätze der Zusammenarbeit - bitte lesen und akzeptieren Sie unten',
      dataProtection: 'Verschwiegenheitserklärung - bitte lesen und akzeptieren Sie unten',
      agreementTitle: 'Atreus terms of service',
      notificationInformation: "Benachrichtigungseinstellungen",
      competenceProfile: "Kompetenzprofil",
      profileAgreementTitle: "Aktuelle Grundsätze der Zusammenarbeit und Verschwiegenheitserklärung",
      deleteMyProfile: "Mein Profil löschen",
      pdfConfigTitle: "PDF Export Konfigurator",
      invoiceContent: "Rechnungs-Inhalt",
      invoiceUpload: "Rechnungs-Upload",
      monthlyReportSaveConfirm: "Monatlichen Bericht speichern",
      newApplicantQuestions: "BITTE BEANTWORTEN SIE UNSERE FRAGEN",
      mainCVActions: "Meine CV Aktionen",
    },
    options: {
      billingStatus: {
        "100": "Offen",
        "200": "Eingereicht",
        "250": "Eingereicht",
        "300": "Abgeschlossen"
      },
      subcategories: {
        "400": "CV Deutsch",
        "500": "CV Englisch",
        "1000": "Referenzen",
        "1100": "Zeugnisse",
        "1300": "Sonstiges"
      }
    }
  }
};
