import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { EditorState } from 'draft-js';
import { stateFromHTML } from 'draft-js-import-html';
import { TextEditor } from './style';

function Details({ className, otherActivities }) {
  const editorState = useMemo(() => EditorState.createWithContent(stateFromHTML(otherActivities)), [
    otherActivities
  ]);

  return (
    <TextEditor
      className={className}
      data-test-id="other-activities-text"
      editorState={editorState}
      readOnly
      value={otherActivities}
    />
  );
}

Details.propTypes = {
  className: PropTypes.string,
  otherActivities: PropTypes.string.isRequired
};

export default Details;
