import { connect } from 'react-redux';
import * as uiStateSelectors from 'redux-modules/uiState/selectors';
import * as newsSelectors from 'redux-modules/news/selectors';
import * as newsActions from 'redux-modules/news/actions';
import News from './News';

function mapStateToProps(state) {
  return {
    language: uiStateSelectors.selectLanguage(state),
    news: newsSelectors.selectNews(state)
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onInit: () => {
      dispatch(newsActions.loadNews());
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(News);
