import { put, select, takeLeading } from 'redux-saga/effects';
import * as actions from './actions';
import * as selectors from './selectors';
import * as types from './types';

export function* loadMyProjects() {
  try {
    const isLoaded = yield select(selectors.selectLoadedState);
    if (isLoaded) {
      return;
    }
    const resp = {
      data: [
        {
          id: '1',
          contractType: '100',
          function: 'Chief Opperation Officer',
          companyName: 'Porche',
          city: 'Studdgard',
          country: 'DE',
          status: '1',
          plannedPlacementArt: 'Lorem ipsum is dolorem cocorem',
          jobTitle: 'Chief Opperation Officer',
          clientNamed: false,
          jobHolderInformed: false,
          contactPhone: '+23 2333 2323',
          contactEmail: 'aurel@georgel.ro',
          industry: '400',
          businessPerfomance: 'Lorem ipsum text',
          reporting: '100',
          jobLocation: 'Munich',
          operationalArea: '200.300',
          description:
            'At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui officia deserunt mollitia animi, id est laborum et dolorum fuga. Et harum quidem rerum facilis est et expedita distinctio. Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus id quod maxime placeat facere possimus, omnis voluptas assumenda est, omnis dolor repellendus. Temporibus autem quibusdam et aut officiis debitis aut rerum necessitatibus saepe eveniet ut et voluptates repudiandae sint et molestiae non recusandae. Itaque earum rerum hic tenetur a sapiente delectus, ut aut reiciendis voluptatibus maiores alias consequatur aut perferendis doloribus asperiores repellat.',
          companyDescription:
            'At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui officia deserunt mollitia animi, id est laborum et dolorum fuga. Et harum quidem rerum facilis est et expedita distinctio. Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus id quod maxime placeat facere possimus, omnis voluptas assumenda est, omnis dolor repellendus. Temporibus autem quibusdam et aut officiis debitis aut rerum necessitatibus saepe eveniet ut et voluptates repudiandae sint et molestiae non recusandae. Itaque earum rerum hic tenetur a sapiente delectus, ut aut reiciendis voluptatibus maiores alias consequatur aut perferendis doloribus asperiores repellat.',
          documents: [
            { id: '1', name: 'Rubens.Schneider1.pdf' },
            { id: '2', name: 'Rubens.Schneider2.pdf' }
          ],
          candidates: [
            {
              id: '1',
              name: 'Sarah Kellber',
              city: 'Stuttgard',
              country: 'DE',
              availibility: 'Available to new opportunites',
              isChosen: true
            },
            {
              id: '1',
              name: 'Emily Porter',
              city: 'Stuttgard',
              country: 'DE',
              availibility: 'Available to new opportunites'
            },
            {
              id: '1',
              name: 'Paul William Wattson',
              city: 'Stuttgard',
              country: 'DE',
              availibility: 'Available to new opportunites'
            },
            {
              id: '1',
              name: 'William Kellber',
              city: 'Stuttgard',
              country: 'DE',
              availibility: 'Available to new opportunites'
            },
            {
              id: '1',
              name: 'Paul Wattson',
              city: 'Stuttgard',
              country: 'DE',
              availibility: 'Available to new opportunites'
            },
            {
              id: '1',
              name: 'Sarah Paul William',
              city: 'Stuttgard',
              country: 'DE',
              availibility: 'Available to new opportunites'
            }
          ]
        },
        {
          id: '2',
          contractType: '100',
          function: 'Chief Opperation Officer',
          companyName: 'Porche',
          city: 'Studdgard',
          country: 'DE',
          status: '100',
          plannedPlacementArt: 'Lorem ipsum is dolorem cocorem',
          jobTitle: 'Chief Opperation Officer',
          clientNamed: false,
          jobHolderInformed: false,
          contactPhone: '+23 2333 2323',
          contactEmail: 'aurel@georgel.ro',
          industry: '300',
          businessPerfomance: 'Lorem ipsum text',
          reporting: '200',
          jobLocation: 'Munich',
          operationalArea: '200.300',
          description:
            'At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui officia deserunt mollitia animi, id est laborum et dolorum fuga. Et harum quidem rerum facilis est et expedita distinctio. Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus id quod maxime placeat facere possimus, omnis voluptas assumenda est, omnis dolor repellendus. Temporibus autem quibusdam et aut officiis debitis aut rerum necessitatibus saepe eveniet ut et voluptates repudiandae sint et molestiae non recusandae. Itaque earum rerum hic tenetur a sapiente delectus, ut aut reiciendis voluptatibus maiores alias consequatur aut perferendis doloribus asperiores repellat.',
          companyDescription:
            'At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui officia deserunt mollitia animi, id est laborum et dolorum fuga. Et harum quidem rerum facilis est et expedita distinctio. Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus id quod maxime placeat facere possimus, omnis voluptas assumenda est, omnis dolor repellendus. Temporibus autem quibusdam et aut officiis debitis aut rerum necessitatibus saepe eveniet ut et voluptates repudiandae sint et molestiae non recusandae. Itaque earum rerum hic tenetur a sapiente delectus, ut aut reiciendis voluptatibus maiores alias consequatur aut perferendis doloribus asperiores repellat.',
          documents: [
            { id: '1', name: 'Rubens.Schneider1.pdf' },
            { id: '2', name: 'Rubens.Schneider2.pdf' }
          ],
          candidates: [
            {
              id: '1',
              name: 'Sarah Kellber',
              city: 'Stuttgard',
              country: 'DE',
              availibility: 'Available to new opportunites',
              isChosen: true
            },
            {
              id: '1',
              name: 'Emily Porter',
              city: 'Stuttgard',
              country: 'DE',
              availibility: 'Available to new opportunites'
            },
            {
              id: '1',
              name: 'Paul William Wattson',
              city: 'Stuttgard',
              country: 'DE',
              availibility: 'Available to new opportunites'
            },
            {
              id: '1',
              name: 'William Kellber',
              city: 'Stuttgard',
              country: 'DE',
              availibility: 'Available to new opportunites'
            },
            {
              id: '1',
              name: 'Paul Wattson',
              city: 'Stuttgard',
              country: 'DE',
              availibility: 'Available to new opportunites'
            },
            {
              id: '1',
              name: 'Sarah Paul William',
              city: 'Stuttgard',
              country: 'DE',
              availibility: 'Available to new opportunites'
            }
          ]
        },
        {
          id: '3',
          contractType: '200',
          function: 'Chief Opperation Officer',
          companyName: 'Porche',
          city: 'Studdgard',
          country: 'DE',
          status: '200',
          plannedPlacementArt: 'Lorem ipsum is dolorem cocorem',
          jobTitle: 'Chief Opperation Officer',
          clientNamed: false,
          jobHolderInformed: false,
          contactPhone: '+23 2333 2323',
          contactEmail: 'aurel@georgel.ro',
          industry: '300',
          businessPerfomance: 'Lorem ipsum text',
          reporting: '100',
          jobLocation: 'Munich',
          operationalArea: '200.300',
          description:
            'At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui officia deserunt mollitia animi, id est laborum et dolorum fuga. Et harum quidem rerum facilis est et expedita distinctio. Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus id quod maxime placeat facere possimus, omnis voluptas assumenda est, omnis dolor repellendus. Temporibus autem quibusdam et aut officiis debitis aut rerum necessitatibus saepe eveniet ut et voluptates repudiandae sint et molestiae non recusandae. Itaque earum rerum hic tenetur a sapiente delectus, ut aut reiciendis voluptatibus maiores alias consequatur aut perferendis doloribus asperiores repellat.',
          companyDescription:
            'At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui officia deserunt mollitia animi, id est laborum et dolorum fuga. Et harum quidem rerum facilis est et expedita distinctio. Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus id quod maxime placeat facere possimus, omnis voluptas assumenda est, omnis dolor repellendus. Temporibus autem quibusdam et aut officiis debitis aut rerum necessitatibus saepe eveniet ut et voluptates repudiandae sint et molestiae non recusandae. Itaque earum rerum hic tenetur a sapiente delectus, ut aut reiciendis voluptatibus maiores alias consequatur aut perferendis doloribus asperiores repellat.',
          candidates: [
            {
              id: '1',
              name: 'Sarah Kellber',
              city: 'Stuttgard',
              country: 'DE',
              availibility: 'Available to new opportunites',
              isChosen: true,
              executiveSummary:
                'A successful and experienced manager with long professional practice in various industries as CEO, CFO, CSO and CRO. > 15 years of experience as sole director General management responsibility: finance and sales Employments included functions in stock listed corporations as well as familiy businesses and private equity portfolio companies Fluent in German, English and French',
              photo:
                'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTLhyEj2wZ8KDKwhkoC87OxgVH4sdPybQP3QSbEvqyVY5HS4J5NlA&s'
            },
            {
              id: '1',
              name: 'Emily Porter',
              city: 'Stuttgard',
              country: 'DE',
              availibility: 'Available to new opportunites'
            },
            {
              id: '1',
              name: 'Paul William Wattson',
              city: 'Stuttgard',
              country: 'DE',
              availibility: 'Available to new opportunites'
            },
            {
              id: '1',
              name: 'William Kellber',
              city: 'Stuttgard',
              country: 'DE',
              availibility: 'Available to new opportunites'
            },
            {
              id: '1',
              name: 'Paul Wattson',
              city: 'Stuttgard',
              country: 'DE',
              availibility: 'Available to new opportunites'
            },
            {
              id: '1',
              name: 'Sarah Paul William',
              city: 'Stuttgard',
              country: 'DE',
              availibility: 'Available to new opportunites'
            }
          ]
        },
        {
          id: '4',
          contractType: '200',
          function: 'Chief Opperation Officer',
          companyName: 'Porche',
          city: 'Studdgard',
          country: 'DE',
          status: '300',
          plannedPlacementArt: 'Lorem ipsum is dolorem cocorem',
          jobTitle: 'Chief Opperation Officer',
          clientNamed: false,
          jobHolderInformed: false,
          contactPhone: '+23 2333 2323',
          contactEmail: 'aurel@georgel.ro',
          industry: '300',
          businessPerfomance: 'Lorem ipsum text',
          reporting: '100',
          jobLocation: 'Munich',
          operationalArea: '200.300',
          description:
            'At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui officia deserunt mollitia animi, id est laborum et dolorum fuga. Et harum quidem rerum facilis est et expedita distinctio. Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus id quod maxime placeat facere possimus, omnis voluptas assumenda est, omnis dolor repellendus. Temporibus autem quibusdam et aut officiis debitis aut rerum necessitatibus saepe eveniet ut et voluptates repudiandae sint et molestiae non recusandae. Itaque earum rerum hic tenetur a sapiente delectus, ut aut reiciendis voluptatibus maiores alias consequatur aut perferendis doloribus asperiores repellat.',
          companyDescription:
            'At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui officia deserunt mollitia animi, id est laborum et dolorum fuga. Et harum quidem rerum facilis est et expedita distinctio. Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus id quod maxime placeat facere possimus, omnis voluptas assumenda est, omnis dolor repellendus. Temporibus autem quibusdam et aut officiis debitis aut rerum necessitatibus saepe eveniet ut et voluptates repudiandae sint et molestiae non recusandae. Itaque earum rerum hic tenetur a sapiente delectus, ut aut reiciendis voluptatibus maiores alias consequatur aut perferendis doloribus asperiores repellat.',
          documents: [
            { id: '1', name: 'Rubens.Schneider1.pdf' },
            { id: '2', name: 'Rubens.Schneider2.pdf' },
            { id: '3', name: 'Rubens.Schneider3.pdf' },
            { id: '4', name: 'Rubens.Schneider4.pdf' },
            { id: '5', name: 'Rubens.Schneider5.pdf' }
          ],
          candidates: [
            {
              id: '1',
              name: 'Sarah Kellber',
              city: 'Stuttgard',
              country: 'DE',
              availibility: 'Available to new opportunites',
              isChosen: true,
              executiveSummary:
                'A successful and experienced manager with long professional practice in various industries as CEO, CFO, CSO and CRO. > 15 years of experience as sole director General management responsibility: finance and sales Employments included functions in stock listed corporations as well as familiy businesses and private equity portfolio companies Fluent in German, English and French',
              photo:
                'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTLhyEj2wZ8KDKwhkoC87OxgVH4sdPybQP3QSbEvqyVY5HS4J5NlA&s'
            },
            {
              id: '1',
              name: 'Emily Porter',
              city: 'Stuttgard',
              country: 'DE',
              availibility: 'Available to new opportunites'
            },
            {
              id: '1',
              name: 'Paul William Wattson',
              city: 'Stuttgard',
              country: 'DE',
              availibility: 'Available to new opportunites'
            },
            {
              id: '1',
              name: 'William Kellber',
              city: 'Stuttgard',
              country: 'DE',
              availibility: 'Available to new opportunites'
            },
            {
              id: '1',
              name: 'Paul Wattson',
              city: 'Stuttgard',
              country: 'DE',
              availibility: 'Available to new opportunites'
            },
            {
              id: '1',
              name: 'Sarah Paul William',
              city: 'Stuttgard',
              country: 'DE',
              availibility: 'Available to new opportunites'
            }
          ]
        },
        {
          id: '5',
          contractType: '200',
          function: 'Chief Opperation Officer',
          companyName: 'Porche',
          city: 'Studdgard',
          country: 'DE',
          logo:
            'http://www.multiaccesoriosonline.com/996-large_default/bmw-emblem-82-mm-diameter-carbon-fiber.jpg',
          status: '400',
          plannedPlacementArt: 'Lorem ipsum is dolorem cocorem',
          jobTitle: 'Chief Opperation Officer',
          clientNamed: false,
          jobHolderInformed: false,
          contactPhone: '+23 2333 2323',
          contactEmail: 'aurel@georgel.ro',
          industry: '300',
          businessPerfomance: 'Lorem ipsum text',
          reporting: '10',
          jobLocation: 'Munich',
          operationalArea: '200.300',
          description:
            'At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui officia deserunt mollitia animi, id est laborum et dolorum fuga. Et harum quidem rerum facilis est et expedita distinctio. Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus id quod maxime placeat facere possimus, omnis voluptas assumenda est, omnis dolor repellendus. Temporibus autem quibusdam et aut officiis debitis aut rerum necessitatibus saepe eveniet ut et voluptates repudiandae sint et molestiae non recusandae. Itaque earum rerum hic tenetur a sapiente delectus, ut aut reiciendis voluptatibus maiores alias consequatur aut perferendis doloribus asperiores repellat.',
          companyDescription:
            'At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui officia deserunt mollitia animi, id est laborum et dolorum fuga. Et harum quidem rerum facilis est et expedita distinctio. Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus id quod maxime placeat facere possimus, omnis voluptas assumenda est, omnis dolor repellendus. Temporibus autem quibusdam et aut officiis debitis aut rerum necessitatibus saepe eveniet ut et voluptates repudiandae sint et molestiae non recusandae. Itaque earum rerum hic tenetur a sapiente delectus, ut aut reiciendis voluptatibus maiores alias consequatur aut perferendis doloribus asperiores repellat.',
          documents: [
            { id: '1', name: 'Rubens.Schneider1.pdf' },
            { id: '2', name: 'Rubens.Schneider2.pdf' },
            { id: '3', name: 'Rubens.Schneider3.pdf' },
            { id: '4', name: 'Rubens.Schneider4.pdf' },
            { id: '5', name: 'Rubens.Schneider5.pdf' }
          ],
          candidates: [
            {
              id: '1',
              name: 'Sarah Kellber',
              city: 'Stuttgard',
              country: 'DE',
              availibility: 'Available to new opportunites',
              isChosen: true,
              executiveSummary:
                'A successful and experienced manager with long professional practice in various industries as CEO, CFO, CSO and CRO. > 15 years of experience as sole director General management responsibility: finance and sales Employments included functions in stock listed corporations as well as familiy businesses and private equity portfolio companies Fluent in German, English and French',
              photo:
                'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTLhyEj2wZ8KDKwhkoC87OxgVH4sdPybQP3QSbEvqyVY5HS4J5NlA&s'
            },
            {
              id: '1',
              name: 'Emily Porter',
              city: 'Stuttgard',
              country: 'DE',
              availibility: 'Available to new opportunites'
            },
            {
              id: '1',
              name: 'Paul William Wattson',
              city: 'Stuttgard',
              country: 'DE',
              availibility: 'Available to new opportunites'
            },
            {
              id: '1',
              name: 'William Kellber',
              city: 'Stuttgard',
              country: 'DE',
              availibility: 'Available to new opportunites'
            },
            {
              id: '1',
              name: 'Paul Wattson',
              city: 'Stuttgard',
              country: 'DE',
              availibility: 'Available to new opportunites'
            },
            {
              id: '1',
              name: 'Sarah Paul William',
              city: 'Stuttgard',
              country: 'DE',
              availibility: 'Available to new opportunites'
            }
          ]
        },
        {
          id: '6',
          contractType: '200',
          function: 'Chief Opperation Officer',
          companyName: 'Porche',
          city: 'Studdgard',
          country: 'DE',
          logo:
            'http://www.multiaccesoriosonline.com/996-large_default/bmw-emblem-82-mm-diameter-carbon-fiber.jpg',
          status: '600',
          plannedPlacementArt: 'Lorem ipsum is dolorem cocorem',
          jobTitle: 'Chief Opperation Officer',
          clientNamed: false,
          jobHolderInformed: false,
          contactPhone: '+23 2333 2323',
          contactEmail: 'aurel@georgel.ro',
          industry: '300',
          businessPerfomance: 'Lorem ipsum text',
          reporting: '10',
          jobLocation: 'Munich',
          operationalArea: '200.300',
          description:
            'At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui officia deserunt mollitia animi, id est laborum et dolorum fuga. Et harum quidem rerum facilis est et expedita distinctio. Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus id quod maxime placeat facere possimus, omnis voluptas assumenda est, omnis dolor repellendus. Temporibus autem quibusdam et aut officiis debitis aut rerum necessitatibus saepe eveniet ut et voluptates repudiandae sint et molestiae non recusandae. Itaque earum rerum hic tenetur a sapiente delectus, ut aut reiciendis voluptatibus maiores alias consequatur aut perferendis doloribus asperiores repellat.',
          companyDescription:
            'At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui officia deserunt mollitia animi, id est laborum et dolorum fuga. Et harum quidem rerum facilis est et expedita distinctio. Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus id quod maxime placeat facere possimus, omnis voluptas assumenda est, omnis dolor repellendus. Temporibus autem quibusdam et aut officiis debitis aut rerum necessitatibus saepe eveniet ut et voluptates repudiandae sint et molestiae non recusandae. Itaque earum rerum hic tenetur a sapiente delectus, ut aut reiciendis voluptatibus maiores alias consequatur aut perferendis doloribus asperiores repellat.',
          documents: [
            { id: '1', name: 'Rubens.Schneider1.pdf' },
            { id: '2', name: 'Rubens.Schneider2.pdf' },
            { id: '3', name: 'Rubens.Schneider3.pdf' },
            { id: '4', name: 'Rubens.Schneider4.pdf' },
            { id: '5', name: 'Rubens.Schneider5.pdf' }
          ],
          candidates: [
            {
              id: '1',
              name: 'Sarah Kellber',
              city: 'Stuttgard',
              country: 'DE',
              availibility: 'Available to new opportunites',
              isChosen: true,
              executiveSummary:
                'A successful and experienced manager with long professional practice in various industries as CEO, CFO, CSO and CRO. > 15 years of experience as sole director General management responsibility: finance and sales Employments included functions in stock listed corporations as well as familiy businesses and private equity portfolio companies Fluent in German, English and French',
              photo:
                'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTLhyEj2wZ8KDKwhkoC87OxgVH4sdPybQP3QSbEvqyVY5HS4J5NlA&s'
            },
            {
              id: '1',
              name: 'Emily Porter',
              city: 'Stuttgard',
              country: 'DE',
              availibility: 'Available to new opportunites'
            },
            {
              id: '1',
              name: 'Paul William Wattson',
              city: 'Stuttgard',
              country: 'DE',
              availibility: 'Available to new opportunites'
            },
            {
              id: '1',
              name: 'William Kellber',
              city: 'Stuttgard',
              country: 'DE',
              availibility: 'Available to new opportunites'
            },
            {
              id: '1',
              name: 'Paul Wattson',
              city: 'Stuttgard',
              country: 'DE',
              availibility: 'Available to new opportunites'
            },
            {
              id: '1',
              name: 'Sarah Paul William',
              city: 'Stuttgard',
              country: 'DE',
              availibility: 'Available to new opportunites'
            }
          ]
        }
      ]
    };
    if (resp && resp.data) {
      var action = actions.setLoadedProjects(resp.data);
      yield put(action);
    } else {
      yield put(actions.failProjectsLoad(resp));
    }
  } catch (error) {
    yield put(actions.failProjectsLoad('error'));
  }
}

export default [takeLeading(types.LOAD_PROJECTS, loadMyProjects)];
