import React from 'react';
import PropTypes from 'prop-types';
import { LangSelector, SelectorContainer } from './style';

function LanguageSelector({ className, languages, selectedLanguage, onChange }) {
  return (
    <SelectorContainer className={className}>
      {languages.map(lang => (
        <LangSelector
          key={lang}
          selected={selectedLanguage === lang}
          onClick={() => onChange(lang)}
        >
          {lang}
        </LangSelector>
      ))}
    </SelectorContainer>
  );
}

LanguageSelector.propTypes = {
  className: PropTypes.string,
  languages: PropTypes.array.isRequired,
  selectedLanguage: PropTypes.string,
  onChange: PropTypes.func.isRequired
};

export default LanguageSelector;
