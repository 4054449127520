import styled from 'styled-components';

export const Icon = styled.svg``;

export const Text = styled.span`
  ${props =>
    props.color === 'primary' &&
    `
    color:  ${props.theme.color.primary.main};
    `}
`;

export const TitleWrapper = styled.h3`
  align-items: center;
  display: flex;
  font-size: ${props =>
    props.size === 'large' ? props.theme.fontSize.xlarge : props.theme.fontSize.large};
  font-weight: ${props => props.theme.fontWeight.normal};
  margin: 0;
  padding: 0;

  ${Icon} {
    margin-right: ${props => props.theme.spacing(2)};
  }
  ${Icon} + ${Text} {
    padding-top: 0.25em;
  }
`;
export const SubTitle = styled.span`
  color: ${props => props.theme.color.grey['700']};
  font-size: ${props => props.theme.fontSize.small};
  text-transform: none;
`;
