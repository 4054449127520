import { default as LinkComponent } from 'components/Link';
import styled from 'styled-components';
import Logo from 'components/Logo';

export const LogoLink = styled(LinkComponent)`
  color: inherit;
  margin-right: auto;
`;

export const AppLogo = styled(Logo)`
  color: inherit;
`;
