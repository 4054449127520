import * as types from './types';

// @TODO replace all actions for server communication error with a generic UI state flag/action
export function failOpportunitiesLoad(error) {
  return {
    payload: {
      error
    },
    type: types.OPPORTUNITIES_FAILED
  };
}

export function loadNDA() {
  return {
    type: types.LOAD_NDA
  };
}
export function setLoadedNDA(nda, language) {
  return {
    payload: {
      language,
      nda
    },
    type: types.NDA_LOADED
  };
}

export function loadOpportunities() {
  return {
    type: types.LOAD_OPPORTUNITIES
  };
}
export function setLoadedOpportunities(opportunities) {
  return {
    payload: {
      opportunities
    },
    type: types.OPPORTUNITIES_LOADED
  };
}

export function loadOpportunity(opportunity) {
  return {
    payload: {
      opportunity
    },
    type: types.LOAD_OPPORTUNITY
  };
}
export function setLoadedOpportunity(opportunity) {
  return {
    payload: {
      opportunity
    },
    type: types.OPPORTUNITY_LOADED
  };
}

export function failOpportunitySync(error) {
  return {
    payload: {
      error
    },
    type: types.OPPORTUNITY_SYNC_FAILED
  };
}

export function acceptNDA(opportunity) {
  return {
    payload: {
      opportunity
    },
    type: types.ACCEPT_NDA
  };
}
export function setAcceptedNDA(opportunity) {
  return {
    payload: {
      opportunity
    },
    type: types.NDA_ACCEPTED
  };
}

export function acceptOpportunity(payload) {
  return {
    payload,
    type: types.ACCEPT_OPPORTUNITY
  };
}
export function setAcceptedOpportunity(opportunity) {
  return {
    payload: {
      opportunity
    },
    type: types.OPPORTUNITY_ACCEPTED
  };
}

export function rejectOpportunity(opportunity) {
  return {
    payload: {
      opportunity,
      timestamp: new Date()
    },
    type: types.REJECT_OPPORTUNITY
  };
}
export function notAvailableForOpportunity(opportunity) {
  return {
    payload: {
      opportunity,
      timestamp: new Date()
    },
    type: types.NOT_AVAILABLE_FOR_OPPORTUNITY
  };
}
export function setRejectedOpportunity(opportunity) {
  return {
    payload: {
      opportunity
    },
    type: types.OPPORTUNITY_REJECTED
  };
}
export function setNotAvailableOpportunity(opportunity) {
  return {
    payload: {
      opportunity
    },
    type: types.OPPORTUNITY_UnAvailable
  };
}
