import React from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import { useTranslation } from 'hooks';

function Options() {
  const { t } = useTranslation();
  return (
    <>
      <FormControlLabel
        control={<Radio color="primary" />}
        label={t('button.yes')}
        value="100"
        labelPlacement="end"
      />
      <FormControlLabel
        control={<Radio color="primary" />}
        label={t('button.no')}
        value="200"
        labelPlacement="end"
      />
    </>
  );
}

export default Options;
