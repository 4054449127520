import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import placeholderUserImage from './user.svg';
import { Image } from './style';

function ProfilePicture({ alt, className, size, src, setDeleteButtonState }) {
  const handleLoadError = useCallback(event => {
    if (event.target.src !== placeholderUserImage) {
      event.target.src = placeholderUserImage;
      if (typeof setDeleteButtonState === "function") { 
        setDeleteButtonState(false);
      }
    }
    
  }, []);

  
  useEffect(() => {
    if (src) if(typeof setDeleteButtonState === "function") {setDeleteButtonState(true)}
  },[src]);


  return (
     <Image
      alt={alt}
      className={className}
      size={size}
      src={src || placeholderUserImage}
      onError={handleLoadError}
    />
  );
}

ProfilePicture.defaultProps = {
  size: 'medium'
};

ProfilePicture.propTypes = {
  alt: PropTypes.string,
  className: PropTypes.string,
  size: PropTypes.string.isRequired,
  src: PropTypes.string
};

export default ProfilePicture;
