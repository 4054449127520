import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import SelectField from 'components/inputs/SelectField';
import { FormContainer, FormItem, FormRow, FormSection } from 'components/styledComponents';
import { useTranslation } from 'hooks';

function Language({ className, meta: { communicationLanguages }, supportedLanguages, useInput }) {
  const { t } = useTranslation();
  const languageOptions = useMemo(
    () => communicationLanguages.filter(language => supportedLanguages.includes(language.key)),
    [communicationLanguages, supportedLanguages]
  );

  return (
    <FormContainer className={className}>
      <FormSection divider padding="1 0">
        <FormRow>
          <FormItem
            component={SelectField}
            label={t('label.portalLanguage')}
            options={languageOptions}
            {...useInput('language')}
          />
          <FormItem empty />
        </FormRow>
      </FormSection>
    </FormContainer>
  );
}

Language.propTypes = {
  className: PropTypes.string,
  meta: PropTypes.shape({
    communicationLanguages: PropTypes.array.isRequired
  }).isRequired,
  supportedLanguages: PropTypes.array.isRequired,
  useInput: PropTypes.func.isRequired
};

export default Language;
