import { connect } from 'react-redux';
import * as authActions from 'redux-modules/auth/actions';
import * as authSelectors from 'redux-modules/auth/selectors';
import * as uiStateSelectors from 'redux-modules/uiState/selectors';
import TermsPage from './TermsPage';

function mapStateToProps(state) {
  return {
    syncing: authSelectors.selectSyncState(state),
    agreementData: authSelectors.selectAgreementData(state),
    language: uiStateSelectors.selectLanguage(state),
    nda1: authSelectors.selectNDA1(state),
    nda2: authSelectors.selectNDA2(state),
    canSkipAgreement: authSelectors.selectCanSkipAgreement(state)
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onAccept: (data, failCallback) => {
      dispatch(authActions.agreeTerms(data, failCallback));
    },
    onInit: () => {
      dispatch(authActions.loadNDA1());
      dispatch(authActions.loadNDA2());
    },
    onAbort: () => dispatch(authActions.logout()),
    onSkipAgreement: () => dispatch(authActions.skipAgreement())
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(TermsPage);
