import { call, put, takeLeading, select } from 'redux-saga/effects';
import apiClient from 'communication/apiClient';
import * as actions from './actions';
import * as types from './types';
import * as selectors from './selectors';

export function* loadManagerCandidateCV(actionData) {
  try {
    const candidateCv = yield select(selectors.selectResume, actionData.payload.candidateId);
    if (candidateCv) {
      yield put(actions.resetSyncing());
      return;
    }
    const resp = yield call(
      apiClient.get,
      `/api/v1/manager/candidates/${actionData.payload.candidateId}/cv`
    );
    if (resp && resp.data) {
      resp.data.id = actionData.payload.candidateId;
      yield put(actions.setLoadedMngCandidateCV(resp.data));
    } else {
      yield put(actions.failMngCandidateCVLoad(resp));
    }
  } catch (error) {
    yield put(actions.failMngCandidateCVLoad('error'));
  }
}

export function* loadManagerCV(actionData) {
  try {
    const managerCv = yield select(selectors.selectResume, actionData.payload.managerId);
    if (managerCv) {
      yield put(actions.resetSyncing());
      return;
    }
    const resp = yield call(apiClient.get, `/api/v1/manager/${actionData.payload.managerId}`);
    if (resp && resp.data) {
      resp.data.id = actionData.payload.managerId;
      yield put(actions.setLoadedMngCV(resp.data));
    } else {
      yield put(actions.failMngCVLoad(resp));
    }
  } catch (error) {
    yield put(actions.failMngCVLoad('error'));
  }
}

export default [
  takeLeading(types.LOAD_MNG_CANDIDATE_CV, loadManagerCandidateCV),
  takeLeading(types.LOAD_MNG_CV, loadManagerCV)
];
