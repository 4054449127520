export const LOAD_OPPORTUNITIES = 'load_opportunities';
export const OPPORTUNITIES_FAILED = 'loading_opportunities_has_failed';
export const OPPORTUNITIES_LOADED = 'set_loaded_opportunities';

export const OPPORTUNITY_SYNC_FAILED = 'opportunity_action_has_failed';

export const ACCEPT_NDA = 'accept_nda';
export const LOAD_NDA = 'load_nda';
export const NDA_ACCEPTED = 'set_accepted_nda';
export const NDA_LOADED = 'set_loaded_nda';

export const LOAD_OPPORTUNITY = 'load_opportunity';
export const OPPORTUNITY_LOADED = 'set_loaded_opportunity';

export const ACCEPT_OPPORTUNITY = 'accept_opportunity';
export const OPPORTUNITY_ACCEPTED = 'opportunity_accepted';

export const REJECT_OPPORTUNITY = 'reject_opportunity';
export const NOT_AVAILABLE_FOR_OPPORTUNITY = 'not_available_for_opportunity';
export const OPPORTUNITY_UnAvailable = 'opportunity_unavailbale';
export const OPPORTUNITY_REJECTED = 'opportunity_rejected';
