export const LOAD_RESUME = 'load_resume';
export const RESUME_FAILED = 'loading_resume_has_failed';
export const RESUME_LOADED = 'set_loaded_resume';

export const AVAILABILITY_FAILED = 'availability_failed';
export const SAVE_AVAILABILITY = 'save_availability';
export const UPDATE_AVAILABILITY = 'update_availability';

export const ADD_EDUCATION = 'add_new_education';
export const DELETE_EDUCATION = 'delete_existing_education';
export const EDUCATION_FAILED = 'education_sync_failed';
export const REMOVE_EDUCATION = 'remove_education_from_state';
export const SAVE_EDUCATION = 'save_education';
export const UPDATE_EDUCATION = 'update_existing_education';

export const EXECUTIVE_SUMMARY_FAILED = 'executive_summary_sync_failed';
export const UPDATE_EXECUTIVE_SUMMARY = 'update_executive_summary';
export const SAVE_EXECUTIVE_SUMMARY = 'save_executive_summary';

export const SAVE_RESUME_COMPLETE_ACK = 'save_resume_complete_acknowledgement';
export const DONE_RESUME_COMPLETE_ACK = 'done_resume_complete_acknowledgement';
export const FAILED_RESUME_COMPLETE_ACK = 'failed_resume_complete_acknowledgement';

export const COMPETENCE_PROFILE_FAILED = 'competence_profile_sync_failed';
export const UPDATE_COMPETENCE_PROFILE = 'update_competence_profile';
export const SAVE_COMPETENCE_PROFILE = 'save_competence_profile';

export const IT_SKILLS_FAILED = 'it_skills_sync_failed';
export const UPDATE_IT_SKILLS = 'update_it_skills';
export const SAVE_IT_SKILLS = 'save_it_skills';

export const LANGUAGES_FAILED = 'languages_sync_failed';
export const UPDATE_LANGUAGES = 'update_languages';
export const SAVE_LANGUAGES = 'save_languages';

export const ADD_MISSION_ABROAD = 'add_new_mission_abroad';
export const DELETE_MISSION_ABROAD = 'delete_existing_mission_abroad';
export const MISSION_ABROAD_FAILED = 'mission_abroad_sync_failed';
export const REMOVE_MISSION_ABROAD = 'remove_mission_abroad_from_state';
export const SAVE_MISSION_ABROAD = 'save_mission_abroad';
export const UPDATE_MISSION_ABROAD = 'update_existing_mission_abroad';

export const OTHER_ACTIVITIES_FAILED = 'other_activities_sync_failed';
export const UPDATE_OTHER_ACTIVITIES = 'update_other_activities';
export const SAVE_OTHER_ACTIVITIES = 'save_other_activities';

export const SAVE_PERSONAL_DETAILS = 'save_personal_details';
export const UPDATE_PERSONAL_DETAILS = 'update_personal_details';
export const PERSONAL_DETAILS_FAILED = 'personal_details_failed';

export const ADD_WORK_EXPERIENCE = 'add_work_experience';
export const SAVE_WORK_EXPERIENCE = 'save_work_experience';
export const REMOVE_WORK_EXPERIENCE = 'remove_work_experience_from_state';
export const UPDATE_WORK_EXPERIENCE = 'update_work_experience_from_state';
export const WORK_EXPERIENCE_FAILED = 'work_experience_failed';
export const DELETE_WORK_EXPERIENCE = 'delete_existing_work_experience';

export const SAVE_COMPANY_GROUP = 'save_company_group';
export const UPDATE_COMPANY_GROUP = 'update_company_group_from_state';

export const DELETE_COMPANY_GROUP = 'delete_company_group';

export const SAVE_NOTIFICATION_SETTINGS = 'save_notification_seetings';
export const UPDATE_NOTIFICATION_SETTINGS = 'update_notification_seetings';
export const NOTIFICATION_SETTINGS_FAILED = 'notification_seetings_failed';

export const DELETE_PROFILE = 'delete_profile';