import React from 'react';
import PropTypes from 'prop-types';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { useLocale } from 'hooks';
import MuiCalendar from './MuiCalendar';
import { CalendarContainer } from './style';

function Calendar({ className, ...rest }) {
  return (
    <CalendarContainer className={className}>
      <MuiPickersUtilsProvider locale={useLocale()} utils={DateFnsUtils}>
        <MuiCalendar {...rest} />
      </MuiPickersUtilsProvider>
    </CalendarContainer>
  );
}

Calendar.propTypes = {
  className: PropTypes.string
};

export default Calendar;
