import styled from 'styled-components';
import { IconButton } from 'components/buttons';

export const ImageContainer = styled.label`
  cursor: pointer;

  @media (max-width: 500px) {
    margin: auto;
  }
`;

export const ImageInput = styled.input`
  display: none;
`;

export const Button = styled(IconButton)`
  align-self: flex-end;
  margin: ${props => props.theme.spacing(0.875, 0, 0.875, 1)};
  flex-grow: 0;
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
