import Ajv from 'ajv';
import ajvKeywords from 'ajv-keywords';
import * as schemas from 'jsonSchemas';
import _cloneDeep from 'lodash/cloneDeep';

const ajv = new Ajv({
  $data: true,
  allErrors: true,
  format: 'full',
  schemas: [schemas.defsSchema],
  useDefaults: 'empty'
});

ajvKeywords(ajv, ['formatMinimum', 'regexp', 'transform']);

ajv.addKeyword('coerceString', {
  type: 'string',
  errors: false,
  modifying: true,
  valid: true,
  compile: schema => {
    return (data, dataPath, object, key) => {
      // skip if it's a primitive data type
      if (!object) {
        return;
      }

      let coercedData;
      switch (schema.type) {
        case 'number':
        case 'integer':
          coercedData = isNaN(data) ? data : +data;
          break;
        default:
          coercedData = data;
      }

      object[key] = coercedData;
    };
  }
});

ajv.addKeyword('maxDecimals', {
  validate: function (decimalsNumber, value) {
    const decimalsMultiplier = 10 ** decimalsNumber;
    return Number.isInteger(value * decimalsMultiplier);
  },
  errors: false
});

function parseErrors(errors) {
  return errors.map(function (error) {
    error.fieldPath = error.dataPath;
    // remove leading dot
    if (error.fieldPath.startsWith('.')) {
      error.fieldPath = error.fieldPath.substring(1);
    }
    // add missing prop inside field path
    if (error.params.missingProperty) {
      error.fieldPath = error.fieldPath.length
        ? `${error.fieldPath}.${error.params.missingProperty}`
        : error.params.missingProperty;
    }
    const pathList = error.fieldPath.split('.');
    error.field = pathList[pathList.length - 1];
    error.validationType = error.keyword;
    return error;
  });
}

//defining allowed characters for Social Networks
const deChar = /[äąāöüÄÂÁÀÅÃĄĀÖÜáàåæÆâãèéêëÈÉÊËóòôöõÒÓÔÕÖçÇñÑÏÍíÌìÎîþðøØùúûÙÚÛÿŸýÝšŠœŒžŽĜĝǦǧĠġĢģćĆĉĈčČċĊēĒēĒęĘėĖïÏĩĨįĮīĪĭĭıħĦďĎđĐŵŴŝŜšŠşŞśŚŕŔřŘŗŖœŒōŌũŨűŰůŮŏŎĸőŐųŲūŪťŤţŢŧŦňŇńŃņŅŋĵĴķĶĺĹľĽļĻłŁŷŶźŹżŻ]/g;
const infoChar = /[äöüÄÖÜ]/g;
//reg exp for all umlauts for allowed domains
const umlauts = {
  '.de': deChar,
  '.com': deChar,
  '.net': deChar,
  '.org': /[áéíóúñäöüÁÉÍÓÚÄÖÜÑ]/g,
  '.info': infoChar,
  '.biz': infoChar,
  '.at': /[äöüÄÂÁÀÅÃÖÜáàåæÆâãèéêëÈÉÊËóòôöõÒÓÔÕÖçÇñÑÏÍíÌìÎîþðøØùúûÙÚÛÿŸýÝšŠœŒžŽ]/g,
  '.ch': /[äöüÄÂÁÀÅÃÖÜáàåæÆâãèéêëÈÉÊËóòôöõÒÓÔÕÖçÇñÑÏÍíÌìÎîþðøØùúûÙÚÛÿŸýÝ]/g,
  '.es': /[ÀàèéÈÉóò.ÒÓçÇñÑÍíÌìüùúÜÙÚ]/g
};

//removes the valid Umlautes from object and verifys the url
function removeValidUmlautesFromObj(data) {
  let tempObj = [];
  // RTEXT-856
  // fixed the breaking issue for multi input fields
  data &&
    data.map(function (key, index) {
      tempObj[index] = key;
      if (key && key.value) {
        const { value } = key;
        const tld = getTLD(value);
        if (umlauts[tld]) {
          const allowedUmlaute = value.replace(umlauts[tld], '');
          tempObj[index] = { ...key, value: allowedUmlaute };
        }
      }
    });

  return tempObj;
}

//removes the valid Umlautes from String and verifys the url
function removeValidUmlautesFromString(data) {
  let cleanString;
  if (data !== undefined) {
    const tld = getTLD(data);
    if (umlauts[tld] !== undefined) {
      cleanString = data.replace(umlauts[tld], '');
      return cleanString;
    }
  }
  return data;
}

//breaks down url
function getUrlParts(fullyQualifiedUrl) {
  var url = {},
    tempProtocol;
  var a = document.createElement('a');
  // if doesn't start with something like https:// it's not a url, but try to work around that
  if (fullyQualifiedUrl.indexOf('://') == -1) {
    tempProtocol = 'https://';
    a.href = tempProtocol + fullyQualifiedUrl;
  } else a.href = fullyQualifiedUrl;
  var parts = a.hostname.split('.');
  url.origin = tempProtocol ? '' : a.origin;
  url.domain = a.hostname;
  url.subdomain = parts[0];
  url.domainroot = '';
  url.domainpath = '';
  url.tld = '.' + parts[parts.length - 1];
  url.path = a.pathname.substring(1);
  url.query = a.search.substr(1);
  url.protocol = tempProtocol ? '' : a.protocol.substr(0, a.protocol.length - 1);
  url.port = tempProtocol
    ? ''
    : a.port
    ? a.port
    : a.protocol === 'http:'
    ? 80
    : a.protocol === 'https:'
    ? 443
    : a.port;
  url.parts = parts;
  url.segments = a.pathname === '/' ? [] : a.pathname.split('/').slice(1);
  url.params = url.query === '' ? [] : url.query.split('&');
  for (var j = 0; j < url.params.length; j++) {
    var param = url.params[j];
    var keyval = param.split('=');
    url.params[j] = {
      key: keyval[0],
      val: keyval[1]
    };
  }
  // domainroot
  if (parts.length > 2) {
    url.domainroot = parts[parts.length - 2] + '.' + parts[parts.length - 1];
    // check for country code top level domain
    if (parts[parts.length - 1].length == 2 && parts[parts.length - 1].length == 2)
      url.domainroot = parts[parts.length - 3] + '.' + url.domainroot;
  }
  // domainpath (domain+path without filenames)
  if (url.segments.length > 0) {
    var lastSegment = url.segments[url.segments.length - 1];
    var endsWithFile = lastSegment.indexOf('.') != -1;
    if (endsWithFile) {
      var fileSegment = url.path.indexOf(lastSegment);
      var pathNoFile = url.path.substr(0, fileSegment - 1);
      url.domainpath = url.domain;
      if (pathNoFile) url.domainpath = url.domainpath + '/' + pathNoFile;
    } else url.domainpath = url.domain + '/' + url.path;
  } else url.domainpath = url.domain;
  return url;
}

//function takes url and return the TLD
function getTLD(url) {
  url = getUrlParts(url);
  if (url) {
    return url.tld; //will return tld, incase of .co.tk it will only return .co
  }
  return;
}

//helper function to check if given paramter is object or not
function isLiteralObject(val) {
  return !!val && val.constructor === Object;
}

function validate(schemaName, data) {
  const schema = schemas[schemaName];
  if (isLiteralObject(data)) {
    // RTEXT-859
    let tempDataObj = _cloneDeep(data);
    const uiState = JSON.parse(localStorage.getItem('uiState') || '{}');
    for (const property in tempDataObj) {
      if (
        tempDataObj[property] &&
        typeof tempDataObj[property] === 'object' &&
        tempDataObj[property].hasOwnProperty('en') &&
        tempDataObj[property].hasOwnProperty('de')
      ) {
        if (tempDataObj[property].hasOwnProperty('multiLangFieldActiveLang')) {
          let activeLang = tempDataObj[property]['multiLangFieldActiveLang'];
          if (activeLang && Array.isArray(activeLang)) {
            activeLang = activeLang[activeLang.length - 1];
          }
          if (activeLang === 'de') {
            delete tempDataObj[property]['en'];
          } else {
            delete tempDataObj[property]['de'];
          }
        } else {
          if (uiState.hasOwnProperty('resumeLanguage')) {
            if (uiState.resumeLanguage === 'de') {
              delete tempDataObj[property]['en'];
            } else {
              delete tempDataObj[property]['de'];
            }
          }
        }
      }
    }

    if (tempDataObj['socialNetworks']) {
      tempDataObj['socialNetworks'] = removeValidUmlautesFromObj(tempDataObj['socialNetworks']);
    }
    if (tempDataObj['website']) {
      tempDataObj['website'] = removeValidUmlautesFromString(tempDataObj['website']);
    }
    if (tempDataObj['profileVideo']) {
      tempDataObj['profileVideo'] = removeValidUmlautesFromString(tempDataObj['profileVideo']);
    }
    // RTEXT-856
    if (tempDataObj['emails'] && schemaName === 'accountDetailsSchema') {
      if (!tempDataObj['emails'][0]) {
        tempDataObj['emails'][0] = {};
      }
    }
    return ajv.validate(schema, tempDataObj) ? [] : parseErrors(ajv.errors);
  } else {
    return ajv.validate(schema, data) ? [] : parseErrors(ajv.errors);
  }
}

export { validate };
