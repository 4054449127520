import styled from 'styled-components';

export const CompetenceEditor = styled.div`
// width:100%;
 .column-width-competence{
        width:100%;
    }
//
//
// @media screen and (min-width:768px) and (max-width:1080px){
//         width:100%!important;
//         margin-bottom:15px;
// }
// @media screen and (max-width:767px){
//         width:100%!important;
//         margin-bottom:15px;
// }


// @media screen and (min-width:768px) and (max-width:959px){
//     .column-width-competence{
//         width:100%;
//     }
//
// }
// @media screen and (max-width:767px){
//     .column-width-competence{
//         width:100%;
//     }
//
// }


`;

export const CompetenceTextArea = styled.div`
    width:85%;
    @media screen and (min-width:768px) and (max-width:959px){
        width:100%;
        margin-bottom:15px;
}
@media screen and (max-width:767px){
        width:100%;
        margin-bottom:15px;
}

.limit-industryFocuses-box, .limit-specialistFocuses-box, .limit-managementMethods-box {
        transition: height 3s;
        height: 172px;
}

.industryFocuses-box, .specialistFocuses-box, .managementMethods-box {
        transition: height 3s;
}


`;
export const Label = styled.div`
  font-size: ${props => props.theme.fontSize.base};
  margin-bottom: ${props => props.theme.spacing(1)};
  font-weight: ${props => props.theme.fontWeight.bold};
`;

export const TogglerContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-end;
  padding-top: ${props => props.theme.spacing(3)};
`;

export const Button = styled.button`
  align-items: center;
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  padding: 0;

  :focus {
    outline: none;
  }
`;

export const IconLabel = styled.span`
  font-weight: ${props => props.theme.fontWeight.normal};
  margin-right: ${props => props.theme.spacing(0.5)};
`;

export const ToggleIcon = styled.span`
  font-size: 2em;
  transform: rotate(270deg);
`;

export const EmptySection = styled.div`
  border: 1px dashed ${props => props.theme.color.grey[500]};
  border-radius: ${props => props.theme.borderRadius.base};
  box-sizing: border-box;
  padding: ${props => props.theme.spacing(3)};
`;
export const EmptyText = styled.p`
  line-height: 1.5em;
  margin: 0;
`;

