import React from 'react';
import PropTypes from 'prop-types';
import { ProjectStatusContentContainer } from './style';
import SearchingCandidates from './SearchingCandidates';
import FoundCandidates from './FoundCandidates';
import ElectedCandidate from './ElectedCandidate';
import MandateRunning from './MandateRunning';
import MandateTerminated from './MandateTerminated';

function ProjectStatusContent({ project }) {
  const renderStatusComponent = project => {
    switch (project.status) {
      case '100': // searching candidates
        return <SearchingCandidates project={project} />;
      case '200': // searching candidates
        return <FoundCandidates project={project} />;
      case '300': // searching candidates
        return <ElectedCandidate project={project} />;
      case '400': // mandate in preparation
        return <MandateRunning project={project} />;
      case '500': // mandate ongoing
        return <MandateRunning project={project} />;
      case '600': // mandate ongoing
        return <MandateTerminated project={project} />;
      default:
        return null;
    }
  };
  return (
    <React.Fragment>
      {project.status >= 100 ? (
        <ProjectStatusContentContainer>
          {renderStatusComponent(project)}
        </ProjectStatusContentContainer>
      ) : null}
    </React.Fragment>
  );
}

ProjectStatusContent.propTypes = {
  project: PropTypes.object.isRequired
};

export default ProjectStatusContent;
