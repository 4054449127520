import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'hooks';
import { statuses } from './config';
import { Container } from './style';

function StatusText({ className, statusCode }) {
  const { t } = useTranslation();
  const { label, ...rest } = useMemo(() => {
    switch (statusCode) {
      case '200':
        return statuses.inProgress;
      case '300':
      case '320':
      case '340':
      case '350':
      case '380':
      case '1100':
      case '1120':
        return statuses.ongoing;
      case '400':
      case '500':
      case '600':
      case '700':
      case '800':
      case '1200':
      case '1300':
        return statuses.closed;
      default:
        return statuses.default;
    }
  }, [statusCode]);

  return (
    <Container className={className} {...rest}>
      {t(label)}
    </Container>
  );
}

StatusText.propTypes = {
  className: PropTypes.string,
  statusCode: PropTypes.string
};

export default StatusText;
