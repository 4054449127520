import styled from 'styled-components';
import MuiDialogContent from '@material-ui/core/DialogContent';

export const DialogContent = styled(MuiDialogContent)`
  box-sizing: border-box;
  padding: ${props =>
    props.padding ? props.padding : `${props.theme.spacing(3)} ${props.theme.spacing(3)}`};
  min-height: 230px;
  ${props =>
    props.overflowX &&
    `
      overflow-x: ${props.overflowX}
    `}
`;
