import { createSelector } from 'reselect';

export const selectPlacements = state => state.placements.placements;
export const selectActivePlacements = createSelector([selectPlacements], placements => {
  return placements.filter(placements => {
    return (
      placements.status === 'In Progress' &&
      (placements.salesStage == 200 ||
        placements.salesStage == 300 ||
        placements.salesStage == 320 ||
        placements.salesStage == 340 ||
        placements.salesStage == 350 ||
        placements.salesStage == 380)
    );
  });
});

export const selectPlacement = (state, placementId) => {
  return state.placements.placements.find(item => item.id === placementId);
};

export const selectCurrentState = state => state;

export const selectCurrentDate = (state, placementId) =>
  state.placements.placementsDetails[placementId]
    ? {
        currentMonth: state.placements.placementsDetails[placementId].currentMonth,
        currentYear: state.placements.placementsDetails[placementId].currentYear
      }
    : null;

export const selectPlacementsDetails = state => state.placements.placementsDetails;
export const selectPlacementDetails = (state, placementId) => {
  if (state.placements.placementsDetails) {
    return state.placements.placementsDetails[placementId] ?? null;
  }
  return null;
};

export const selectSyncState = state => state.placements.syncing;
export const selectSyncMonthState = state => state.placements.syncingMonth;
export const selectSyncMonthReportState = state => state.placements.syncingMonthlyReport;
export const selectSyncPlacementsState = state => state.placements.syncingPlacements;

export const selectSyncError = state => state.placements.error;

export const selectIsSentMonthlyReport = state => state.placements.isMonthlyReportSent;
export const selectIsSavedMonthlyReport = state => state.placements.isMonthlyReportSaved;
