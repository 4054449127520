import styled from 'styled-components';
import { Button as BaseButton } from 'components/buttons';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

export default makeStyles(theme => ({
  paper: {
    margin: theme.spacing(3, 0),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '50%'
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  typo: {
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
    width: '100%',
    textTransform: 'uppercase'
  },
  formFooter: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: theme.spacing(2),
    width: '100%'
  },
  'navlink.active': {
    color: theme.palette.primary.main,
    cursor: 'pointer',
    display: 'block',
    width: '100%',
    marginBottom: '16px',
    paddingBottom: '16px',
    borderBottom: '1px solid #ccc'
  },
  'navlink.inactive': {
    color: '#C8C8C8',
    cursor: 'pointer'
  }
}));

export const Button = styled(BaseButton)`
  max-width: 230px;
  width: 167px;
  :disabled {
    background: ${props => (props.disabledColor ? props.disabledColor : '#F5F5F5')};
    color: ${props => (props.disabledColor ? 'white' : '#CCCCCC')};
  }
`;
export const UploadButton = styled(BaseButton)`
  // max-width: 230px;
  // width: 165px;
  width: 100%;
`;

export const FooterActions = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: ${props => props.theme.spacing(-1)};
  padding: ${props => props.theme.spacing(4)} 0;

  ${Button} {
    margin: ${props => props.theme.spacing(1)};
    @media (max-width: 400px) {
      flex-grow: 1;
    }
  }
`;
export const FormFooter = styled.div`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

export const AttachmentContainer = styled.div`
  // align-items: center;
  position: relative;
  // display: flex;
  // flex-wrap: wrap;
  // justify-content: left;
  // margin: 20px 0px 10px 0px;
  // cursor: pointer;
`;

export const FileUploadComponent = styled.input`
  position: absolute;
  top: 0px;
  left: 0px;
  max-width: 100%;
  width: 100%;
  height: 55px;
  opacity: 0;
  // cursor: pointer;
`;

export const Section = styled.div`
  position: absolute;
  top: 0px;
  bottom: 0px;
  background: ${props => props.theme.color.background.section};
  max-width: 100%;
  width: 930px;
  overflow: auto;
`;

export const Footer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row-reverse;
  padding-bottom: 20px;

  button:last-child {
    margin-right: 20px;
  }
`;

export const MuiContainer = styled(Container)`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;

export const LinkButton = styled.a`
  background: transparent;
  color: ${props => props.theme.color.primary.main};
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 5px;
  text-decoration: none;
  padding: 3px 4px;
  line-height: 1.875em;
  font-weight: 700;
  font-size: 14px;
  text-transform: uppercase;
  width: 100px;
  border: 1px solid ${props => props.theme.color.primary.main};
`;

export const FlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
`;

export const Text = styled.p``;

export const Image = styled.img`
  width: 100%;
  object-fit: cover;
  ${props =>
    props.mt &&
    `
      margin-top: ${props.mt + 'px'};
    `}
  ${props =>
    props.mb &&
    `
      margin-bottom: ${props.mb + 'px'};
    `}
`;

export const VideoContainer = styled.div`
  width: 100%;
  .react-player {
    height: 500px !important;
  }

  @media (max-width: 925px) {
    .react-player {
      height: auto !important;
    }
    iframe {
      height: calc(100vw * 0.56) !important;
    }
  }
`;
