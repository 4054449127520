export const EMPTY_META = {
  anualRevenues: [],
  budgets: [],
  certificateTypes: [],
  communicationLanguages: [],
  companySituations: [],
  companyTurnoverList: [],
  contractTypes: [],
  coreCompetencies: [],
  coreCompetenciesByFunction: [],
  countries: [],
  countriesRegion: [],
  courseTypes: [],
  directReports: [],
  educationLevels: [],
  educationTypes: [],
  employees: [],
  functions: [],
  industries: [],
  itSkills: [],
  languageLevels: [],
  languages: [],
  mobilityTypes: [],
  monthlyWorkingDays: [],
  nationalities: [],
  operationalReadinessTypes: [],
  ownershipStructures: [],
  personalCompetencies: [],
  salutations: [],
  schoolTypes: [],
  statusShortList: [],
  titles: [],
  workAbroadAssignmentTypes: []
};
