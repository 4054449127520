import React from 'react';
import PropTypes from 'prop-types';
import { Container } from 'components/styledComponents';
import Logo from './Logo';
import { Header} from './style';

function AgreementHeader({ className }) {
  return (
    <Container as={Header} className={className}>
      <Logo />
    </Container>
  );
}

AgreementHeader.propTypes = {
  className: PropTypes.string
};

export default AgreementHeader;
