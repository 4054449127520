import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import * as BaseComponents from './components';
import { StyledContainer, StyledContent, StyledHeader } from './style';

function Card({
  children,
  className,
  components,
  contentProps,
  disableGutters,
  dividers,
  headerContent,
  headerProps,
  margin,
  spacing,
  title,
  ...rest
}) {
  const { Container, Content, Header } = useMemo(() => ({ ...BaseComponents, ...components }), [
    components
  ]);

  return (
    <StyledContainer
      as={Container}
      className={className}
      disableGutters={disableGutters}
      margin={margin}
      spacing={spacing}
      {...rest}
    >
      {title && (
        <StyledHeader as={Header} spacing={spacing} title={title} {...headerProps}>
          {headerContent}
        </StyledHeader>
      )}
      <StyledContent as={Content} dividers={dividers} spacing={spacing} {...contentProps}>
        {children}
      </StyledContent>
    </StyledContainer>
  );
}

Card.defaultProps = {
  components: {},
  contentProps: {},
  disableGutters: false,
  dividers: true,
  headerProps: {},
  margin: 'normal',
  spacing: 3
};

Card.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.node, PropTypes.object]),
  components: PropTypes.object.isRequired,
  contentProps: PropTypes.object.isRequired,
  disableGutters: PropTypes.bool.isRequired,
  dividers: PropTypes.bool.isRequired,
  margin: PropTypes.oneOf(['dense', 'none', 'normal']).isRequired,
  headerContent: PropTypes.oneOfType([PropTypes.func, PropTypes.node, PropTypes.object]),
  headerProps: PropTypes.object.isRequired,
  spacing: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  title: PropTypes.string
};

export default Card;
