import styled from 'styled-components';

export const FlexBox = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: ${props => (props.mt ? props.mt + 'px' : '0px')};
`;

export const Icon = styled.span``;

export const TextFieldContainer = styled.div`
  margin-top: ${props => (props.mt ? props.mt + 'px' : '0px')};
  width: 100%;
  display: flex;
  justify-content: space-between;
  max-width: 160px;
`;

export const List = styled.ul`
  margin-left: ${props => (props.ml ? props.ml + 'px' : '0px')};
  margin-top: ${props => (props.mt ? props.mt + 'px' : '0px')};
`;

export const ListItem = styled.li``;

export const Value = styled.span`
`;
