import styled from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  paper: {
    margin: theme.spacing(4, 0),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  },
  typo: {
    width: '100%',
    textTransform: 'uppercase'
  },
  'navlink.active': {
    color: theme.palette.primary.main,
    cursor: 'pointer'
  },
  'navlink.inactive': {
    color: '#C8C8C8',
    cursor: 'pointer'
  }
}));

export const FormFooter = styled.div`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

export const AttachmentContainer = styled.div`
  align-items: center;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
  margin: 20px 0px 10px 0px;
  cursor: pointer;
`;

export const FileUploadComponent = styled.input`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
`;
