import styled from 'styled-components';

const SIZES = {
  small: '2.375em',
  medium: '4.25em',
  large: '9.25em'
};

export const Image = styled.img`
  background-color: ${props => props.theme.color.primary.contrast};
  border-radius: 50%;
  height: ${props => SIZES[props.size]};
  object-fit: cover;
  width: ${props => SIZES[props.size]};
`;
