import React, { useEffect, useMemo, useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import Container from '@material-ui/core/Container';
import { useTranslation } from 'hooks';
import { Card, CardsContainer } from 'components/cards';
import { PlacementSummary, TimeTracking } from 'components/Placements';
// import MonthlyChecklist from './cards/MonthlyChecklist';
import PlacementDetails from './cards/PlacementDetails';
import { PlacementsSection } from './style';
import Notification from 'components/Notification';

function PlacementsPage({
  currentDate,
  meta,
  placementId,
  placementDetails,
  placements,
  allPlacements,
  syncing,
  syncingMonth,
  syncingMonthlyReport,
  onInit,
  onSaveMonthlyReport,
  onSendMonthlyReport,
  loadPlacementOverviewMonth,
  isMonthlyReportSaved,
  resetIsReportSaved,
  videoURLs,
  language
}) {
  const { t } = useTranslation();
  const timeTrackingOptionsList = meta.timeTrackingOptions;

  // RTEXT-618
  // fetched recorded time options dropdown list
  const recordedTimeOptionsList = meta.recordedTimeOptions;

  const [toggleMonthChange, setToggleMonthChange] = useState(false);

  const vatList = meta.vatList;
  // const reportBillingViaPortal =
  //   placementDetails && placementDetails.placement.reportBillingViaPortal;

  // RTEXT-690
  const [disableMouseControls, setDisableMouseControls] = useState(false);

  useEffect(() => {
    onInit(placementId);
  }, [onInit, placementId]);

  // RTEXT-620
  // for reseting state on first mount
  useEffect(() => {
    resetIsReportSaved && resetIsReportSaved();
  }, []);

  const [reportDate, setReportDate] = useState({
    isLoaded: false,
    year: new Date().getFullYear(),
    month: new Date().getMonth() + 1
  });

  const [currentCompDate, setCurrentCompDate] = useState({});
  const [selectedIndex, setSelectedIndex] = useState(0);

  useEffect(() => {
    if (
      currentDate &&
      (!currentCompDate ||
        currentDate.currentYear !== currentCompDate.currentYear ||
        currentDate.currentMonth !== currentCompDate.currentMonth)
    ) {
      setReportDate({
        year: currentDate.currentYear,
        month: currentDate.currentMonth,
        isLoaded: true
      });
      setCurrentCompDate({
        currentYear: currentDate.currentYear,
        currentMonth: currentDate.currentMonth
      });
    }
  }, [currentDate, currentCompDate]);

  const handleMonthChange = useCallback(
    date => {
      setReportDate({
        year: date.getFullYear(),
        month: date.getMonth() + 1,
        isLoaded: true
      });
      loadPlacementOverviewMonth(placementId, date.getFullYear(), date.getMonth() + 1);
      setToggleMonthChange(!toggleMonthChange);
    },
    [placementId, loadPlacementOverviewMonth, toggleMonthChange]
  );

  function handleSelectedIndexChange(index) {
    setSelectedIndex(index);
  }

  const placementDetailsData = useMemo(() => {
    var placementDetailsData = null;
    if (!placementDetails) {
      placementDetailsData = {
        placement: {},
        currentYear: reportDate.year,
        currentMonth: reportDate.month,
        timeTrackingOptions: [],
        months: {}
      };
      placementDetailsData.months[`${reportDate.year}_${reportDate.month}`] = {
        timeTracking: [],
        monthlyReport: {}
      };
      return placementDetailsData;
    } else {
      if (reportDate == null || !reportDate.isLoaded) {
        setReportDate({
          year: placementDetails.currentYear,
          month: placementDetails.currentMonth,
          isLoaded: true
        });
      }
      return placementDetails;
    }
  }, [placementDetails, reportDate]);

  const history = useHistory();
  const onSelectedPlacementChange = useCallback(
    placement => {
      history.push(`/placement/${placement.id}`);
    },
    [history]
  );

  return (
    <React.Fragment>
      <PlacementsSection>
        <Container>
          <Notification
            duration={4000}
            message={t('label.saveSuccess')}
            position={{ vertical: 'top', horizontal: 'center' }}
            resetGlobalState={resetIsReportSaved}
            state={isMonthlyReportSaved}
          />
          <CardsContainer>
            <Card size="4" title={t('label.dailyActivitiesProgress')}>
              <TimeTracking
                disableMouseControls={disableMouseControls}
                loading={syncingMonth || syncing || syncingMonthlyReport}
                month={reportDate.month}
                placementDetails={placementDetailsData}
                setDisableMouseControls={setDisableMouseControls}
                year={reportDate.year}
                onMonthChange={handleMonthChange}
              />
            </Card>
            <Card size="7" title={t('title.placementOverview')}>
              <PlacementSummary
                allPlacements={allPlacements}
                loading={syncing}
                placement={placementDetailsData.placement}
                placements={placements}
                showAllPlacements={true}
                onSelectedPlacementChange={onSelectedPlacementChange}
              />
            </Card>
            {/* RTEXT-620  */}
            {/* <MonthlyChecklist /> */}
          </CardsContainer>
        </Container>
      </PlacementsSection>
      <PlacementsSection>
        <Container>
          <PlacementDetails
            language={language}
            loading={syncingMonth || syncing || syncingMonthlyReport}
            month={reportDate.month}
            placementDetails={placementDetailsData}
            recordedTimeOptions={recordedTimeOptionsList}
            selectedIndex={selectedIndex}
            setDisableMouseControls={setDisableMouseControls}
            timeTrackingOptions={timeTrackingOptionsList}
            toggleMonthChange={toggleMonthChange}
            vatList={vatList}
            videoURLs={videoURLs}
            year={reportDate.year}
            onMonthChanged={handleMonthChange}
            onSaveMonthlyReport={onSaveMonthlyReport}
            onSelectedIndexChange={handleSelectedIndexChange}
            onSendMonthlyReport={onSendMonthlyReport}
          />
        </Container>
      </PlacementsSection>
    </React.Fragment>
  );
}

PlacementsPage.propTypes = {
  currentDate: PropTypes.object,
  meta: PropTypes.object.isRequired,
  placementId: PropTypes.string.isRequired,
  placementDetails: PropTypes.object,
  placements: PropTypes.array,
  syncing: PropTypes.bool,
  syncingMonth: PropTypes.bool,
  syncingMonthlyReport: PropTypes.bool,
  onInit: PropTypes.func.isRequired,
  onSaveMonthlyReport: PropTypes.func.isRequired,
  onSendMonthlyReport: PropTypes.func.isRequired,
  loadPlacementOverviewMonth: PropTypes.func.isRequired,
  isMonthlyReportSaved: PropTypes.bool,
  resetIsReportSaved: PropTypes.func.isRequired,
  allPlacements: PropTypes.array,
  videoURLs: PropTypes.object,
  language: PropTypes.string
};

export default PlacementsPage;
