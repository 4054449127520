import React from 'react';
import PropTypes from 'prop-types';
import { Redirect, Route } from 'react-router-dom';

function AuthRoute({ component: Component, isAuthenticated, showAgreement, ...rest }) {
  return (
    <Route
      {...rest}
      render={routeProps => {
        if (typeof showAgreement == 'string') {
          showAgreement = JSON.parse(showAgreement);
        }

        if (isAuthenticated) {
          if (showAgreement) {
            return <Redirect to={{ pathname: '/agreement-terms' }} />;
          } else {
            return (
              <Redirect
                to={
                  routeProps.location.state
                    ? {
                        ...routeProps.location.state.from,
                        // RTEXT-748
                        // not mutating state and passing pathname to check
                        // that previous path is /login
                        state: {
                          ...routeProps.location.state,
                          fromLogin:
                            routeProps.location && routeProps.location.pathname === '/login'
                        }
                      }
                    : {
                        pathname: '/'
                      }
                }
              />
            );
          }
        } else {
          return <Component {...routeProps} />;
        }
      }}
    />
  );
}

AuthRoute.propTypes = {
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.node, PropTypes.object]),
  component: PropTypes.oneOfType([PropTypes.func, PropTypes.node, PropTypes.object]),
  isAuthenticated: PropTypes.bool.isRequired,
  showAgreement: PropTypes.bool
};

export default AuthRoute;
