import { connect } from 'react-redux';
import * as opportunitiesSelectors from 'redux-modules/opportunities/selectors';
import * as opportunitiesActions from 'redux-modules/opportunities/actions';
import Opportunities from './Opportunities';

function mapStateToProps(state) {
  return {
    opportunities: opportunitiesSelectors.selectOpportunities(state)
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onInit: () => {
      dispatch(opportunitiesActions.loadOpportunities());
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Opportunities);
