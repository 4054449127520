import * as types from './types';
import * as authTypes from 'redux-modules/auth/types';

const initialState = {
  error: null,
  placements: [],
  placementsDetails: {},
  syncing: false,
  syncingMonth: false,
  syncingMonthlyReport: false,
  syncingPlacements: false,
  isMonthlyReportSent: false
};

function placements(state = initialState, action) {
  switch (action.type) {
    case types.TIMETRACKING_SAVE_ERROR:
    case types.SAVE_MONTHLY_REPORT_FAILED:
    case types.SEND_MONTHLY_REPORT_FAILED:
    case types.PLACEMENTS_FAILED:
      return {
        ...state,
        error: action.payload.error,
        syncing: false
      };
    case types.LOAD_PLACEMENTS:
      return {
        ...state,
        error: null,
        syncingPlacements: true
      };
    case types.PLACEMENTS_LOADED:
      return {
        ...state,
        placements: action.payload.placements,
        syncingPlacements: false
      };
    case types.LOAD_PLACEMENT_OVERVIEW:
      return {
        ...state,
        error: null,
        syncing: true
      };
    case types.LOAD_PLACEMENT_OVERVIEW_MONTH:
      return {
        ...state,
        error: null,
        syncingMonth: true
      };
    case types.SAVE_TIMETRACKING:
      return {
        ...state,
        error: null,
        syncingMonth: true
      };
    case types.PLACEMENT_OVERVIEW_LOADED:
    case types.TIMETRACKING_SAVED:
    case types.MONTHLY_REPORT_SENT:
    case types.MONTHLY_REPORT_SAVED:
      var placementsDetails = Object.assign({}, state.placementsDetails);
      var previousCurrentMonth = null;
      var previousCurrentYear = null;
      if (!placementsDetails) placementsDetails = {};
      var placementDetails = placementsDetails[action.payload.placement.id];
      if (!placementDetails) {
        placementDetails = {};
      } else {
        placementDetails = Object.assign({}, placementDetails);
        previousCurrentMonth = placementDetails.currentMonth;
        previousCurrentYear = placementDetails.currentYear;
      }
      placementsDetails[action.payload.placement.id] = placementDetails;

      placementDetails.placement = action.payload.placement;
      placementDetails.currentMonth = action.payload.currentMonth;
      placementDetails.currentYear = action.payload.currentYear;
      placementDetails.timeTrackingOptions = action.payload.timeTrackingOptions;
      if (!placementDetails.months) {
        placementDetails.months = {};
      } else {
        placementDetails.months = Object.assign({}, placementDetails.months);
      }
      placementDetails.months[`${action.payload.dataYear}_${action.payload.dataMonth}`] = {
        timeTracking: action.payload.timeTracking,
        monthlyReport: action.payload.monthlyReport
      };
      if (
        action.type === types.MONTHLY_REPORT_SENT &&
        previousCurrentMonth !== placementDetails.currentMonth
      ) {
        delete placementDetails.months[`${previousCurrentYear}_${previousCurrentMonth}`];
      }
      return {
        ...state,
        placementsDetails,
        syncing: false,
        syncingMonth: false,
        syncingMonthlyReport: false
      };
    case types.SAVE_MONTHLY_REPORT:
    case types.SEND_MONTHLY_REPORT:
      return {
        ...state,
        error: null,
        syncingMonthlyReport: true
      };
    case types.START_SENDING:
      // RTEXT-620
      // for getting the updated status of monthly-report (prevent reloading)
      const { placementId, month, year } = action.payload;
      var placementsDetails = Object.assign({}, state.placementsDetails);
      var placementDetails = placementsDetails[placementId];
      delete placementDetails.months[`${year}_${month}`];
      return {
        ...state,
        placementsDetails,
        isMonthlyReportSent: false
      };
    case types.FINISH_SENDING:
      return {
        ...state,
        isMonthlyReportSent: true
      };
    case types.START_SAVING:
      return {
        ...state,
        isMonthlyReportSaved: false
      };
    case types.FINISH_SAVING:
      return {
        ...state,
        isMonthlyReportSaved: true
      };
    case types.RESET_IS_MONTHLY_REPORT_SAVED:
      return {
        ...state,
        isMonthlyReportSaved: false
      };
    case types.RESET_SYNCING:
      return {
        ...state,
        error: null,
        syncing: false,
        syncingMonth: false,
        syncingPlacements: false,
        syncingMonthlyReport: false
      };
    case types.RESET_PLACEMENTS_SYNCING:
      return {
        ...state,
        error: null,
        syncingPlacements: false
      };
    case authTypes.LOGOUT:
      return initialState;
    default:
      return state;
  }
}

export default placements;
