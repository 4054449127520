import * as types from './types';

const initialState = {
  news: [],
  error: null
};

function news(state = initialState, action) {
  switch (action.type) {
    case types.LOAD_NEWS:
      return {
        ...state,
        error: null
      };
    case types.NEWS_LOADED:
      return {
        ...state,
        news: action.payload.news,
        error: null
      };
    case types.NEWS_FAILED:
      return {
        ...state,
        error: action.payload.error
      };
    default:
      return state;
  }
}

export default news;
