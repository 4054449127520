import React from 'react';
import PropTypes from 'prop-types';
import { MultiLanguageRichTextField } from 'components/inputs';
import { FormContainer, FormItem, FormRow } from 'components/styledComponents';
import { useTranslation } from 'hooks';

function Form({ className, language, useInput, ...rest }) {
  const { tr } = useTranslation();

  return (
    <FormContainer className={className} {...rest}>
      <FormRow>
        <FormItem
          component={MultiLanguageRichTextField}
          data-test-id="executive-summary-rich-editor"
          data-rttextarea-id="executive-summary-rich-editor"
          label={tr('label.executiveSummary')}
          language={language}
          {...useInput()}
        />
      </FormRow>
    </FormContainer>
  );
}

Form.propTypes = {
  className: PropTypes.string,
  language: PropTypes.string.isRequired,
  useInput: PropTypes.func.isRequired
};

export default Form;
