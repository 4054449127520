import { connect } from 'react-redux';
import * as uiStateSelectors from 'redux-modules/uiState/selectors';
import AtreusOffer from './AtreusOffer';

function mapStateToProps(state) {
  return {
    language: uiStateSelectors.selectLanguage(state)
  };
}

export default connect(mapStateToProps, null)(AtreusOffer);
