import styled from 'styled-components';

export const Item = styled.div``;

export const ItemsContainer = styled.div`
  margin: 0 ${props => props.theme.spacing(-1.5)};
  ${Item} {
    margin: 0 ${props => props.theme.spacing(1.5)};
  }
  @media (max-width: 600px) {
    ${Item} {
      margin: 0 ${props => props.theme.spacing(1)};
    }
    ${Item}#privacyPolicyStatement {
      display: inline-block;
    }
  }
`;

export const Section = styled.div`
  align-items: center;
  display: flex;

  ${props =>
    props.color === 'primary' &&
    `
    color: ${props.theme.color.primary.main};
  `}
`;

export const Footer = styled.footer`
  align-items: center;
  color: ${props => props.theme.color.text.light};
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-bottom: ${props => props.theme.spacing(2.5)};
  padding-top: ${props => props.theme.spacing(2.5)};

  ${Section} + ${Section} {
    margin: ${props => props.theme.spacing(2)} 0 0 0;
  }

  @media (min-width: 600px) {
    flex-direction: row;
    justify-content: flex-start;

    ${Section} + ${Section} {
      border-left: 1px solid;
      margin: 0  0 0 ${props => props.theme.spacing(3)};
      padding-left: ${props => props.theme.spacing(3)};
    }
  }
`;
