import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDateFormat, useTranslation } from 'hooks';
import { SectionBars, SectionCell } from 'components/SectionBars';

function ProjectOverview({
  indexedMeta: { countriesRegion },
  company,
  personalInformationHeader,
  operationCity,
  operationArea,
  positionTitle,
  startDate
}) {
  const { t } = useTranslation();
  const location = useMemo(() => {
    var location;
    if (operationCity) {
      location = operationCity;
    }

    if (
      Object.entries(countriesRegion).length !== 0 &&
      operationArea &&
      operationArea.length >= 0
    ) {
      if (!location) location = countriesRegion[operationArea[0]];
      else location += ' / ' + countriesRegion[operationArea[0]];
    }
    return location;
  }, [countriesRegion, operationArea, operationCity]);

  const startDateValue = useDateFormat(startDate);

  return (
    <React.Fragment>
      <SectionBars
        header={
          <React.Fragment>
            {personalInformationHeader}
            <h3>{t('label.positionInformation')}</h3>
          </React.Fragment>
        }
        title={t('label.positionInformation')}
      >
        <SectionCell label={t('label.company')} size="30%" value={company ? company : '-'} />
        <SectionCell
          label={t('label.position')}
          size="30%"
          value={positionTitle ? positionTitle : '-'}
        />
        <SectionCell label={t('label.location')} size="20%" value={location ? location : '-'} />
        <SectionCell
          label={t('label.dateFrom')}
          size="20%"
          value={startDate ? startDateValue : '-'}
        />
      </SectionBars>
    </React.Fragment>
  );
}

ProjectOverview.defaultProps = {
  company: null,
  positionTitle: null,
  startDate: null,
  indexedMeta: { countriesRegion: {} },
  operationArea: [],
  operationCity: null
};

ProjectOverview.propTypes = {
  company: PropTypes.string,
  indexedMeta: PropTypes.shape({
    countriesRegion: PropTypes.object.isRequired
  }).isRequired,
  operationArea: PropTypes.array,
  operationCity: PropTypes.string,
  personalInformationHeader: PropTypes.object,
  positionTitle: PropTypes.string,
  startDate: PropTypes.string
};

export default ProjectOverview;
