import { connect } from 'react-redux';
import * as resumeActions from 'redux-modules/resume/actions';
import * as resumeSelectors from 'redux-modules/resume/selectors';
import * as metaSelectors from 'redux-modules/meta/selectors';
import Availability from './Availability';

function mapStateToProps(state) {
  return {
    availability: resumeSelectors.selectAvailability(state),
    loading: resumeSelectors.selectLoadState(state),
    meta: metaSelectors.selectMeta(state),
    syncErrors: resumeSelectors.selectSyncError(state),
    syncing: resumeSelectors.selectSyncState(state)
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onSave: (data, failCallback) => dispatch(resumeActions.saveAvailability(data, failCallback))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Availability);
