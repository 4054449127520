import React from 'react';
import PropTypes from 'prop-types';
import Collapse from 'components/Collapse';
import { Detail } from './style';

function Details({ indexedMeta: { itSkills: indexedItSkills }, itSkills }) {
  return (
    <Collapse>
      {itSkills.map(itSkillId => (
        <Detail key={itSkillId}>
          <em>{indexedItSkills[itSkillId]}</em>
        </Detail>
      ))}
    </Collapse>
  );
}

Details.propTypes = {
  indexedMeta: PropTypes.shape({
    itSkills: PropTypes.object.isRequired
  }).isRequired,
  itSkills: PropTypes.array.isRequired
};

export default Details;
