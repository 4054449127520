import styled from 'styled-components';

export const Text = styled.p`
  margin: 0;
  ${props => props.small && `font-size: ${props.theme.fontSize.xsmall};`}

  em {
    word-break: break-all;
    font-style: normal;
    font-weight: ${props => props.theme.fontWeight.bold};
  }
  span {
    word-break: break-all;
  }
`;

export const TextWrapper = styled.div`
  line-height: 1.5em;
`;

export const Container = styled.div`
  align-items: center;
  display: flex;

  ${TextWrapper} {
    flex-grow: 1;
  }
`;
