import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import Container from '@material-ui/core/Container';
import { useForm, useTranslation } from 'hooks';
import { Loader, Section } from 'components/styledComponents';
import { Address, Contact, Language, Personal, Photo, Social } from './forms';
import { parseData, serializeData } from './dataHelpers';
import { Button, FooterActions, ButtonDelProfile } from './style';
import { Trash } from 'components/icons';
import DeleteProfile from './DeleteProfile/DeleteProfile';
import Link from 'components/Link';

function MyAccountPage({
  history,
  language,
  lang,
  loading,
  personalDetails,
  syncErrors,
  syncing,
  onSave
}) {
  const { t } = useTranslation();
  const [syncInitiated, setSyncInitiated] = useState(false);
  const handleClose = useCallback(() => history.push('/'), [history]);
  const handleSave = useCallback(
    data => {
      onSave(serializeData(data));
      setSyncInitiated(true);
    },
    [onSave, setSyncInitiated]
  );

  const { errors, useInput, handleAdd, handleRemove, handleSubmit, handleResetError } = useForm({
    initialValues: useMemo(() => ({ ...parseData(personalDetails), language: language }), [
      language,
      personalDetails
    ]),
    validationSchema: 'accountDetailsSchema',
    onSubmit: handleSave
  });

  useEffect(() => {
    if (syncInitiated && !syncing) {
      !syncErrors && handleClose();
      setSyncInitiated(false);
    }
  }, [handleClose, setSyncInitiated, syncErrors, syncing, syncInitiated]);

  const getMobileView = () => {
    return (
      <FooterActions>
        <ButtonDelProfile
          component={Link}
          color="danger"
          contentFirst={true}
          label={t('button.deleteMyProfile')}
          variant="text"
          to="/account/deleteProfile"
        >
          <Trash />
        </ButtonDelProfile>
      </FooterActions>
    );
  };

  const mql = window.matchMedia('(max-width: 522px)');
  let mobileView = mql.matches;

  let DynamicComp = mobileView ? getMobileView() : '';
  const [screenSize, setScreenSize] = useState(mobileView);

  mql.addEventListener('change', e => {
    mobileView = e.matches;
    DynamicComp = mobileView ? getMobileView() : '';
    setScreenSize(mobileView);
  });

  return (
    <React.Fragment>
      <Section>
        <Loader component={Container} loading={loading || syncing}>
          <Photo useInput={useInput} />
          <Personal useInput={useInput} />
          <DeleteProfile />
        </Loader>
      </Section>
      <Section>
        <Loader component={Container} loading={loading || syncing}>
          <Address useInput={useInput} />
          <Contact useInput={useInput} onAdd={handleAdd} onDelete={handleRemove} formErrors={errors} onResetError={handleResetError} />
        </Loader>
      </Section>
      <Section>
        <Loader component={Container} loading={loading || syncing}>
          <Social
            useInput={useInput}
            onAdd={handleAdd}
            onDelete={handleRemove}
            onResetError={handleResetError}
          />
          <Language useInput={useInput} />
          <FooterActions>
            {!mobileView && (
              <ButtonDelProfile
                component={Link}
                color="danger"
                contentFirst={true}
                label={t('button.deleteMyProfile')}
                variant="text"
                to="/account/deleteProfile"
              >
                <Trash />
              </ButtonDelProfile>
            )}

            <Button label={t('button.cancel')} variant="text" onClick={handleClose} />
            <Button
              id="profile-submit"
              color="primary"
              disabled={loading || syncing}
              label={t('button.save')}
              onClick={handleSubmit}
            />
          </FooterActions>
          {mobileView && DynamicComp}
        </Loader>
      </Section>
    </React.Fragment>
  );
}

MyAccountPage.propTypes = {
  className: PropTypes.string,
  history: PropTypes.object.isRequired,
  language: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  personalDetails: PropTypes.object,
  syncErrors: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  syncing: PropTypes.bool.isRequired,
  onSave: PropTypes.func.isRequired
};

export default MyAccountPage;
