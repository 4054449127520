import React, { useState } from 'react';
import Container from '@material-ui/core/Container';
import { useTranslation } from 'hooks';
import { Loader, Section } from 'components/styledComponents';
import {
  Address,
  Contact,
  Language,
  Personal,
  Documents
} from 'components/pages/RegistrationPage/forms';
import { Button, FooterActions } from 'components/pages/RegistrationPage/style';
import Attachment from 'components/pages/RegistrationPage/forms/Attachment';
import { AcceptTermsPage } from 'components/pages';
import PropTypes from 'prop-types';

function Step2({
  currentStep,
  documents,
  loading,
  syncing,
  useInput,
  handleLanguageChange,
  handlePrev,
  onStepNext,
  isNewUserEmail,
  setDocuments
}) {
  const { tr } = useTranslation();
  const [policyAccepted, setPolicyAccepted] = useState(false);

  if (currentStep !== 2) {
    return null;
  }

  let uploadedFileName = [];
  uploadedFileName.name = '';

  return (
    <>
      <style>.registration-form-page{'{max-width: 100%; width: 970px}'}</style>
      <Section>
        <Loader component={Container} loading={loading || syncing}>
          <Language useInput={useInput} onChange={handleLanguageChange} />
        </Loader>
      </Section>
      <Section>
        <Loader component={Container} loading={loading || syncing}>
          <Personal useInput={useInput} />
        </Loader>
      </Section>
      <Section>
        <Loader component={Container} loading={loading || syncing}>
          <Address useInput={useInput} />
          <Contact useInput={useInput} isNewUserEmail={isNewUserEmail} />
          {/* <Attachment
            useInput={useInput}
            loading={loading}
            syncing={syncing}
            uploadedFileName={uploadedFileName}
          /> */}
        </Loader>
      </Section>
      <Section>
        <Loader component={Container} loading={loading || syncing}>
          <Documents documents={documents} setDocuments={setDocuments} useInput={useInput} />
        </Loader>
      </Section>
      <Section>
        <Loader component={Container} loading={loading || syncing}>
          <AcceptTermsPage isRegister={true} setPolicyAccepted={setPolicyAccepted} />
        </Loader>
      </Section>
      <Section>
        <Loader component={Container} loading={loading || syncing}>
          <FooterActions>
            <Button label={tr('button.back')} variant="text" onClick={handlePrev} />
            <Button
              color="primary"
              disabled={loading || syncing || !policyAccepted}
              label={tr('button.next')}
              onClick={onStepNext}
            />
          </FooterActions>
        </Loader>
      </Section>
    </>
  );
}

Step2.propTypes = {
  currentStep: PropTypes.number,
  documents: PropTypes.array,
  loading: PropTypes.bool,
  syncing: PropTypes.bool,
  useInput: PropTypes.func.isRequired,
  handleLanguageChange: PropTypes.func.isRequired,
  handlePrev: PropTypes.func.isRequired,
  onStepNext: PropTypes.func.isRequired,
  isNewUserEmail: PropTypes.string,
  setDocuments: PropTypes.func.isRequired
};

export default Step2;
