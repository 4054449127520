import styled from 'styled-components';
import { default as LinkComponent } from 'components/Link';

const menuItemSize = 200;

export const MenuContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  position: relative;
  margin-top: ${props => props.theme.spacing(5)};
  margin-bottom: ${props => props.theme.spacing(5)};
  align-items: flex-start;
  &:after {
    content: '';
    position: absolute;
    border-bottom: 3px solid #206AF5;
    width: ${menuItemSize}px;
    bottom: ${props => props.theme.spacing(-2)};
    display: block;
    left: 0px;
    transition-duration: 0.3s;
    transform: ${props => 'translate(' + menuItemSize * props.selectedIndex + 'px,0)}'};
  }
`;

export const Link = styled(LinkComponent)`
  align-items: center;
  color: ${props => (props.selected ? '#206AF5' : '#666666')};
  display: inline-flex;
  ${props =>
    props.selected &&
    `
    font-weight: ${props.theme.fontWeight.bold};
  `}
  height: 100%;
  line-height: 100%;
  position: relative;
  width: ${menuItemSize}px;
  justify-content: center;
`;
