import styled from 'styled-components';
import { Button as BaseButton } from 'components/buttons';
import { default as RotatingSelectBase } from 'components/RotatingSelect';

export const Button = styled(BaseButton)`
  margin-right: ${props => props.theme.spacing(2)};
  @media (max-width: 500px) {
    // margin: 0 auto;
    // width: 100%;
    align-self: center;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  margin: ${props => props.theme.spacing(4, 0, 0, 0)};
  white-space: nowrap;

  ${Button} {
    max-width: 300px;
    flex-shrink: 1;
  }

  @media (max-width: 1210px) {
    ${Button} {
      margin-bottom: ${props => props.theme.spacing(2)};
    }
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 100%;
`;

export const Content = styled.div`
  display: flex;
  margin: ${props => props.theme.spacing(0, 0, 2, 0)};

  @media (max-width: 500px) {
    flex-direction: column;
  }
`;

export const InformationContainer = styled.div`
  & + & {
    ::before {
      border-top: 1px solid ${props => props.theme.color.grey[500]};
      content: '';
      display: block;
      left: 0;
      margin-right: ${props => (props.widget ? props.theme.spacing(5) : '0px')};
      margin-bottom: ${props => props.theme.spacing(2)};
    }
  }

  @media (max-width: 1014px) {
    & + & {
      ::before {
        margin-right: 0px;
      }
    }
  }

  @media (max-width: 500px) {
    & + & {
      ::before {
        border-top: none;
      }
    }
  }
`;

export const LoaderContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const RotatingSelect = styled(RotatingSelectBase)`
  display: flex;
  flex: 1 1 100%;
  background: red;
`;
