import { connect } from 'react-redux';
import * as resumeSelectors from 'redux-modules/resume/selectors';

import Editor from './Editor';

function mapStateToProps(state) {
  return {
    manager_id: resumeSelectors.selectManagerId(state)
  };
}



export default connect(mapStateToProps)(Editor);
