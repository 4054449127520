import React from 'react';
import PropTypes from 'prop-types';
import { NewsSection } from './style';
function News({ className, language, news, onInit }) {
  return <NewsSection />;
}

News.propTypes = {
  className: PropTypes.string,
  language: PropTypes.string.isRequired,
  news: PropTypes.array.isRequired,
  onInit: PropTypes.func.isRequired
};

export default News;
