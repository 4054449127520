export const PLACEMENTS_FAILED = 'loading_placements_failed';

export const LOAD_PLACEMENTS = 'load_placements';
export const PLACEMENTS_LOADED = 'placements_loaded';

export const LOAD_PLACEMENT_OVERVIEW = 'load_placement_overview';
export const LOAD_PLACEMENT_OVERVIEW_MONTH = 'load_placement_overview_month';
export const PLACEMENT_OVERVIEW_LOADED = 'placement_overview_loaded';

export const SAVE_TIMETRACKING = 'save_time_tracking';
export const TIMETRACKING_SAVED = 'timetracking_saved';
export const TIMETRACKING_SAVE_ERROR = 'timetracking_save_error';

export const SAVE_MONTHLY_REPORT = 'save_monthly_summary';
export const SEND_MONTHLY_REPORT = 'send_monthly_summary';
export const SAVE_MONTHLY_REPORT_FAILED = 'monthly_summary_save_failure';
export const SEND_MONTHLY_REPORT_FAILED = 'monthly_summary_send_failure';
export const MONTHLY_REPORT_SAVED = 'monthly_summary_saved';
export const MONTHLY_REPORT_SENT = 'monthly_summary_sent';
export const START_SENDING = 'start_sending';
export const FINISH_SENDING = 'finish_sending';
export const START_SAVING = 'start_saving';
export const FINISH_SAVING = 'finish_saving';
export const RESET_IS_MONTHLY_REPORT_SAVED = 'reset_is_monthly_report_saved';

export const RESET_SYNCING = 'reset_all_placements_syncing';
export const RESET_PLACEMENTS_SYNCING = 'reset_load_placements_syncing';
