import React from 'react';
import PropTypes from 'prop-types';
import Title from 'components/Title';
import { FormContainer, FormItem, FormRow, FormSection } from 'components/styledComponents';
import { useTranslation } from 'hooks';
import { ActionItem, DownloadLink, TermsHeaderText } from './style';
import { Download } from 'components/icons';

function AgreementTerms({ className, lang }) {
  const { t } = useTranslation();

  return (
    <FormContainer className={className}>
      <FormSection divider="bottom" padding="4 0">
        <Title label={t('title.profileAgreementTitle')} size="large" />
      </FormSection>
      <FormSection padding="3 0 3">
        <FormRow style={{ paddingRight: '20px' }}>
          <FormItem>
            <TermsHeaderText>{t('label.principlesOfCollaboration')}</TermsHeaderText>
          </FormItem>
          <FormItem>
            <ActionItem
              as={DownloadLink}
              color="primary"
              component="a"
              href={`/api/v1/manager/nda/agreement-file/${lang}?timestamp=` + new Date().getTime()}
              target="_blank"
            >
              <Download />
            </ActionItem>
          </FormItem>
        </FormRow>
        <FormRow style={{ padding: '20px 20px 0 0' }}>
          <FormItem>
            <TermsHeaderText>{t('label.confidentialityStatement')}</TermsHeaderText>
          </FormItem>
          <FormItem>
            <ActionItem
              as={DownloadLink}
              color="primary"
              component="a"
              href={`/api/v1/manager/nda/protection-file/${lang}?timestamp=` + new Date().getTime()}
              target="_blank"
            >
              <Download />
            </ActionItem>
          </FormItem>
        </FormRow>
      </FormSection>
    </FormContainer>
  );
}

AgreementTerms.propTypes = {
  className: PropTypes.string
};

export default AgreementTerms;
