import { connect } from 'react-redux';
import * as metaSelectors from 'redux-modules/meta/selectors';
import * as uiStateSelectors from 'redux-modules/uiState/selectors';
import Responsibilities from './Responsibilities';

function mapStateToProps(state) {
  return {
    language: uiStateSelectors.selectResumeLanguage(state),
    meta: metaSelectors.selectResumeMeta(state)
  };
}

export default connect(mapStateToProps, null)(Responsibilities);
