import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Route, withRouter } from 'react-router-dom';
import Editor from './Editor';

function PdfConfiguration({ history, templates, match: { url } }) {
  const handleClose = useCallback(() => history.push(url), [history, url]);
  return (
    <Route
      path={`${url}/pdfcreation/`}
      render={({ match }) => <Editor onClose={handleClose} templates={templates} />}
    />
  );
}

PdfConfiguration.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired
};

export default withRouter(PdfConfiguration);
