import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {each, split, map, filter, isUndefined, isEmpty, isNaN,forOwn, isObject, isNull, isArray, cloneDeep, isString, pull } from 'lodash';
import { MultiLanguageRichTextField, SelectField } from 'components/inputs';
import { FormContainer, FormItem, FormRow, FormSection } from 'components/styledComponents';
import { useTranslation } from 'hooks';
import DropdownTreeSelect from 'react-dropdown-tree-select'
import 'react-dropdown-tree-select/dist/styles.css'
import 'components/pages/ResumePage/sections/MultiTreeDropdown.css'

const noSubmit = event => event.preventDefault();

function Form({ className, language, meta: { itSkills }, useInput, useTree }) {
  const { t, tr } = useTranslation();

  return (
    // onSubmit suppression needed in order to block implicit submit for forms with a single input
    <FormContainer className={className} onSubmit={noSubmit}>
        <span id="dTreeLabel"> {tr('title.itSkills')}</span>
        <FormRow>
        <FormItem
            component={DropdownTreeSelect}
            helperText={t('message.selectItSkills')}
            label={tr('title.itSkills')}
            maxSelections={50}
            mode="hierarchical"
            keepOpenOnSelect={true}
            keepTreeOnSearch={true}
            className="mdl-demo"
            texts={{ placeholder: ' '}}
            {...useTree('itSkills', itSkills)}
        />

      </FormRow>
      <FormSection padding="2 1 0">
        <div>{tr('label.itSkillsRemarks')}</div>
      </FormSection>
      <FormRow>
        <FormItem
          component={MultiLanguageRichTextField}
          data-rttextarea-id="itskill-remarks-rich-editor"
          language={language}
          {...useInput('itSkillRemarks')}
        />
      </FormRow>
    </FormContainer>
  );
}

Form.propTypes = {
  className: PropTypes.string,
  language: PropTypes.string.isRequired,
  meta: PropTypes.object.isRequired,
  useInput: PropTypes.func.isRequired
};

export default React.memo(Form);
