import { connect } from 'react-redux';
import * as metaSelectors from 'redux-modules/meta/selectors';
import Address from './Address';

function mapStateToProps(state) {
  return {
    meta: metaSelectors.selectMeta(state)
  };
}

export default connect(mapStateToProps, null)(Address);
