// Example of using the $data option: https://github.com/epoberezkin/ajv#data-reference
// Relative json path documentation https://tools.ietf.org/html/draft-luff-relative-json-pointer-00

export const availabilityRatesSchema = {
  type: 'object',
  properties: {
    annualTargetIncome: {
      allOf: [
        {
          coerceString: {
            type: 'number'
          }
        },
        {
          type: 'number',
          allOf: [{ transform: ['trim'] }, { maxLength: 64, minLength: 1, minimum: 0 }]
        }
      ]
    },
    availableFrom: { $ref: 'defs.json#/definitions/date' },
    dailyRateFrom: { $ref: 'defs.json#/definitions/dailyRateFrom' },
    dailyRateTo: { $ref: 'defs.json#/definitions/dailyRateFrom' },
    mobility: { $ref: 'defs.json#/definitions/entityIdRequired' },
    operationalReadiness: { $ref: 'defs.json#/definitions/operationalReadiness' }
  },
  required: [
    'annualTargetIncome',
    'availableFrom',
    'dailyRateFrom',
    'dailyRateTo',
    'operationalReadiness',
    'mobility'
  ]
};

export const educationSchema = {
  type: 'object',
  properties: {
    fieldOfStudy: {
      $ref: 'defs.json#/definitions/freeTextField',
      default: {}
    },
    details: {
      $ref: 'defs.json#/definitions/richTextField',
      default: {}
    },
    dateFrom: { $ref: 'defs.json#/definitions/date' },
    dateTo: {
      allOf: [
        { $ref: 'defs.json#/definitions/date' },
        {
          format: 'date',
          formatMinimum: { $data: '1/dateFrom' }
        }
      ]
    },
    certificate: { $ref: 'defs.json#/definitions/entityId', default: '' },
    degree: { $ref: 'defs.json#/definitions/entityIdRequired' },
    country: { $ref: 'defs.json#/definitions/entityIdRequired' },
    course: { $ref: 'defs.json#/definitions/entityId', default: '' },
    institution: {
      $ref: 'defs.json#/definitions/freeTextField',
      default: {}
    },
    schoolType: { $ref: 'defs.json#/definitions/entityId', default: '' }
  },
  required: ['country', 'dateFrom', 'dateTo', 'degree', 'details', 'fieldOfStudy', 'institution']
};

export const languagesSchema = {
  type: 'array',
  items: {
    type: ['object', 'null'],
    properties: {
      languageCode: { $ref: `defs.json#/definitions/entityIdRequired` },
      languageLevelCode: { $ref: `defs.json#/definitions/entityIdRequired` }
    },
    dependencies: {
      languageCode: ['languageLevelCode'],
      languageLevelCode: ['languageCode']
    }
  }
};

export const otherRolesSchema = {
  type: 'object',
  properties: {
    name: {
      $ref: 'defs.json#/definitions/freeTextField',
      default: {}
    },
    dateFrom: { $ref: 'defs.json#/definitions/date' }
  },
  if: {
    properties: {
      isActualEngagement: { enum: [false] }
    }
  },
  then: {
    properties: {
      dateTo: {
        allOf: [
          { $ref: 'defs.json#/definitions/date' },
          {
            format: 'date',
            formatMinimum: { $data: '1/dateFrom' }
          }
        ]
      }
    },
    required: ['dateTo']
  },
  required: ['dateFrom', 'name']
};

export const parentSisterCompanySchema = {
  type: 'object',
  properties: {
    companyName: { allOf: [{ $ref: 'defs.json#/definitions/companyName' }, { minLength: 1 }] },
    name: { $ref: 'defs.json#/definitions/freeTextField', default: {} },
    dateFrom: { $ref: 'defs.json#/definitions/date' }
  },
  if: {
    properties: {
      isActualEngagement: { enum: [false] }
    }
  },
  then: {
    properties: {
      dateTo: {
        allOf: [
          { $ref: 'defs.json#/definitions/date' },
          {
            format: 'date',
            formatMinimum: { $data: '1/dateFrom' }
          }
        ]
      }
    },
    required: ['dateTo']
  },
  required: ['companyName', 'dateFrom', 'name']
};

export const workExperienceSchema = {
  type: 'object',
  properties: {
    companyEmployees: { $ref: 'defs.json#/definitions/entityId', default: '' },
    companyName: { allOf: [{ $ref: 'defs.json#/definitions/companyName' }, { minLength: 1 }] },
    name: { $ref: 'defs.json#/definitions/freeTextField', default: {} },
    function: { $ref: 'defs.json#/definitions/function' },
    companySituation: { $ref: 'defs.json#/definitions/entityId', default: '' },
    companyTurnover: { $ref: 'defs.json#/definitions/entityId', default: '' },
    contractType: { $ref: 'defs.json#/definitions/contractType' },
    costBudgetResponsability: { $ref: 'defs.json#/definitions/entityId', default: '' },
    country: { $ref: 'defs.json#/definitions/entityId', default: '' },
    dateFrom: { $ref: 'defs.json#/definitions/date' },
    dateTo: { default: '' },
    industry: { $ref: 'defs.json#/definitions/entityId', default: '' },
    isActualEngagement: { $ref: 'defs.json#/definitions/isActualEngagement' },
    missionsAbroad: {
      type: 'array',
      items: {
        type: 'object',
        properties: {
          country: { $ref: 'defs.json#/definitions/entityIdRequired' },
          monthsSpent: { $ref: 'defs.json#/definitions/monthsSpent' },
          assignmentType: { $ref: 'defs.json#/definitions/entityIdRequired' }
        },
        required: ['assignmentType', 'country', 'monthsSpent']
      }
    },
    noOfDirectReports: { $ref: 'defs.json#/definitions/entityId', default: '' },
    ownershipStructure: { $ref: 'defs.json#/definitions/entityId', default: '' },
    personnelResponsability: { $ref: 'defs.json#/definitions/entityId', default: '' },
    reference: {
      type: ['null', 'object'],
      properties: {
        comments: { type: 'string' },
        contactDetails: { type: 'string' },
        name: { allOf: [{ $ref: 'defs.json#/definitions/name' }, { minLength: 1 }] },
        position: { type: 'string' }
      },
      required: ['name']
    },
    relatedCompanyRoles: {
      type: 'array',
      items: {
        ...parentSisterCompanySchema
      }
    },
    revenueResponsability: { $ref: 'defs.json#/definitions/entityId', default: '' },
    role: { $ref: 'defs.json#/definitions/role' },
    sameCompanyRoles: {
      type: 'array',
      items: {
        ...otherRolesSchema
      }
    }
  },
  allOf: [
    {
      if: {
        properties: {
          isActualEngagement: { enum: [false] }
        }
      },
      then: {
        properties: {
          dateTo: {
            allOf: [
              { $ref: 'defs.json#/definitions/date' },
              {
                format: 'date',
                formatMinimum: { $data: '1/dateFrom' }
              }
            ]
          }
        },
        required: ['dateTo']
      }
    },
    {
      if: {
        properties: {
          relatedCompanyRoles: {
            type: 'array',
            items: {
              type: 'object'
            },
            minItems: 1
          }
        },
        required: ['relatedCompanyRoles']
      },
      then: {
        properties: {
          companyGroupName: {
            type: 'string',
            allOf: [{ transform: ['trim'] }, { minLength: 1 }]
          },
          companyGroupCountry: { $ref: 'defs.json#/definitions/entityIdRequired' }
        }
      }
    }
  ],
  required: ['companyName', 'contractType', 'dateFrom', 'function', 'role']
};
export const companySchema = {
  type: 'object',
  properties: {
    companyEmployees: { $ref: 'defs.json#/definitions/entityId', default: '' },
    companyName: { allOf: [{ $ref: 'defs.json#/definitions/companyName' }, { minLength: 1 }] },
    companyTurnover: { $ref: 'defs.json#/definitions/entityId', default: '' },
    country: { $ref: 'defs.json#/definitions/entityId', default: '' },
    industry: { $ref: 'defs.json#/definitions/entityId', default: '' },
    isActualEngagement: { $ref: 'defs.json#/definitions/isActualEngagement' },
    ownershipStructure: { $ref: 'defs.json#/definitions/entityId', default: '' }
  },
  required: ['companyName']
};

export const companyGroupSchema = {
  type: 'object',
  properties: {
    companyGroupCountry: { $ref: 'defs.json#/definitions/entityIdRequired' },
    companyGroupName: {
      type: 'string',
      allOf: [{ transform: ['trim'] }, { minLength: 1 }]
    },
    companiesInGroup: {
      type: 'array',
      minItems: 1
    }
  },
  required: ['companyGroupName', 'companyGroupCountry', 'companiesInGroup']
};
