import { connect } from 'react-redux';
import * as offersSelectors from 'redux-modules/offers/selectors';
import * as offersActions from 'redux-modules/offers/actions';
import Offers from './Offers';

function mapStateToProps(state) {
  return {
    offers: offersSelectors.selectOffers(state)
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onInit: () => {
      dispatch(offersActions.loadOffers());
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Offers);
