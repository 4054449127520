import * as types from './types';

const initialState = {
  error: null,
  resumes: {},
  syncing: false
};

function resumes(state = initialState, action) {
  switch (action.type) {
    case types.LOAD_MNG_CANDIDATE_CV:
      return {
        ...state,
        error: null,
        syncing: true
      };
    case types.MNG_CANDIDATE_CV_FAILED:
    case types.MNG_CV_FAILED:
      return {
        ...state,
        error: action.payload.error,
        syncing: false
      };
    case types.MNG_CANDIDATE_CV_LOADED:
    case types.MNG_CV_LOADED:
      return {
        ...state,
        resumes: {
          ...state.resumes,
          [action.payload.id]: action.payload
        },
        error: null,
        syncing: false
      };
    case types.LOAD_MNG_CV:
      return {
        ...state,
        error: null,
        syncing: true
      };
    case types.RESET_SYNCING:
      return {
        ...state,
        error: null,
        syncing: false
      };
    default:
      return state;
  }
}

export default resumes;
