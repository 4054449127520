import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Snackbar from 'components/Snackbar';
import Link from 'components/Link';
import { Button } from 'components/buttons';
import { TextField } from 'components/inputs';
import { useTranslation } from 'hooks';
import { validate } from 'utils/validators';
import { withRouter } from 'react-router-dom';

import useStyles from './style';
import Attachment from './Attachment';

function RegisterPage({ syncing, onGetData, onRegister, history }) {
  const { t } = useTranslation();
  const [errors, setErrors] = useState({});
  const [registerData, setRegisterData] = useState({
    firstName: '',
    lastName: '',
    address: '',
    zip: '',
    country: '',
    email: '',
    password: '',
    attachmentFile: '',
    file: null
  });
  const classes = useStyles();

  useEffect(() => {
    onGetData(data => {
      if (typeof data !== 'undefined') {
        setRegisterData(data);
      }
    });
  });

  function handleChange(event) {
    setErrors({
      ...errors,
      [event.target.name]: null
    });
    setRegisterData({
      ...registerData,
      [event.target.name]: event.target.value
    });
  }
  function handleCVFileSelected(event) {
    if (event.target.files && event.target.files.length > 0) {
      setErrors({
        ...errors,
        [event.target.name]: null
      });
      setRegisterData({
        ...registerData,
        [event.target.name]: event.target.files[0].name,
        file: event.target.files[0]
      });
    } else {
      setErrors({
        ...errors,
        [event.target.name]: null
      });
      setRegisterData({
        ...registerData,
        [event.target.name]: ''
      });
    }
  }
  function handleSubmit(event) {
    const data = {
      ...registerData
    };
    const errorsList = validate('registerSchema', data);
    if (errorsList.length) {
      const errors = {};
      errorsList.forEach(error => (errors[error.field] = error.message));
      setErrors(errors);
      return;
    }
    onRegister(data);
    // decorated component with withRouter, and change the page location here
    // TODO: is there a better way?
    // history.push('/terms');
  }

  return (
    <Container maxWidth="xs">
      <Snackbar
        message={errors.request}
        open={!!errors.request}
        variant="error"
        onClose={() => setErrors({ request: null })}
      />
      <div className={classes.paper}>
        <Typography component="h1" variant="h5" className={classes['typo']}>
          <Link
            to="/login"
            variant="h6"
            style={{ marginRight: '20px' }}
            className={classes['navlink.inactive']}
          >
            {t('button.signIn')}
          </Link>
          <Link to="/register" variant="h6" className={classes['navlink.active']}>
            {t('button.signUp')}
          </Link>
        </Typography>
        <form className={classes.form} noValidate>
          <TextField
            autoComplete="given-name"
            autoFocus
            error={!!errors.firstName}
            fullWidth
            helperText={errors.firstName}
            id="firstName"
            label={t('label.firstName')}
            name="firstName"
            required
            value={registerData.firstName}
            onChange={handleChange}
          />
          <TextField
            autoComplete="family-name"
            autoFocus
            error={!!errors.lastName}
            fullWidth
            helperText={errors.lastName}
            id="lastName"
            label={t('label.lastName')}
            name="lastName"
            required
            value={registerData.lastName}
            onChange={handleChange}
          />
          <TextField
            autoComplete="street-address"
            autoFocus
            error={!!errors.address}
            fullWidth
            helperText={errors.address}
            id="address"
            label={t('label.street')}
            name="address"
            required
            value={registerData.address}
            onChange={handleChange}
          />
          <TextField
            autoComplete="postal-code"
            autoFocus
            error={!!errors.zip}
            fullWidth
            helperText={errors.zip}
            id="zip"
            label={t('label.zip')}
            name="zip"
            required
            value={registerData.zip}
            onChange={handleChange}
          />
          <TextField
            autoComplete="country-name"
            autoFocus
            error={!!errors.country}
            fullWidth
            helperText={errors.country}
            id="country"
            label={t('label.country')}
            name="country"
            required
            value={registerData.country}
            onChange={handleChange}
          />
          <TextField
            autoComplete="email"
            autoFocus
            error={!!errors.email}
            fullWidth
            helperText={errors.email}
            id="email"
            label={t('label.emailAddress')}
            name="email"
            required
            value={registerData.email}
            onChange={handleChange}
          />
          <TextField
            autoComplete="current-password"
            error={!!errors.password}
            fullWidth
            helperText={errors.password}
            id="password"
            label={t('label.password')}
            name="password"
            required
            type="password"
            value={registerData.password}
            onChange={handleChange}
          />
          <Attachment
            error={errors.attachmentFile}
            value={registerData.attachmentFile}
            onChange={handleCVFileSelected}
          />
          <Button
            color="primary"
            disabled={syncing}
            fullWidth
            label={t('button.next')}
            onClick={handleSubmit}
          />
        </form>
      </div>
    </Container>
  );
}

RegisterPage.propTypes = {
  syncing: PropTypes.bool.isRequired,
  onRegister: PropTypes.func.isRequired
};
const RegisterPageWithRouter = withRouter(RegisterPage);

export default RegisterPageWithRouter;
