import React, { useCallback, useMemo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Plus } from 'components/icons';
import Title from 'components/Title';
import { FormContainer, FormItem, FormRow, FormSection } from 'components/styledComponents';
import { useTranslation } from 'hooks';
import TypeValue from '../TypeValue';
import { emailTypes, phoneTypes } from './config';
import { AddButton, ContactSection } from './style';

function Contact({ className, useInput, onAdd, onDelete, formErrors, onResetError }) {
  const { t } = useTranslation();
  // RTEXT-856
  const [isEmailAdded, setIsEmailAdded] = useState(false);

  const { value: emails } = useInput('emails');
  const { value: phones } = useInput('phones');

  // RTEXT-856
  useEffect(() => {
    if (formErrors && formErrors['emails[0].value'] && emails && !emails.length) {
      onAdd && onAdd('emails', null);
      setIsEmailAdded(true);
    }
  }, [formErrors, emails, onAdd]);

  // RTEXT-856
  useEffect(() => {
    if (isEmailAdded && formErrors && formErrors['emails[0].value'] && emails && !emails[0]) {
      const field = document.getElementById('emails[0].value');
      if (field) {
        field.focus();
        setIsEmailAdded(false);
      }
    }
  }, [emails, formErrors, isEmailAdded]);

  const emailOptions = useMemo(
    () => emailTypes.map(emailType => ({ key: emailType, value: t(`label.${emailType}`) })),
    [t]
  );
  const phoneOptions = useMemo(
    () => phoneTypes.map(phoneType => ({ key: phoneType, value: t(`label.${phoneType}`) })),
    [t]
  );
  const selectedPhones = useMemo(
    () =>
      phones.reduce((selectionMap, phone) => {
        phone && (selectionMap[phone.type] = true);
        return selectionMap;
      }, {}),
    [phones]
  );
  const filterOption = useCallback(({ value }) => !selectedPhones[value], [selectedPhones]);

  return (
    <FormContainer className={className}>
      <FormSection padding="0 0 3">
        <FormRow>
          <FormItem>
            <Title component="h4" label={t('title.contactDetails')} />
          </FormItem>
        </FormRow>
        <FormRow>
          <ContactSection>
            {phones.map((item, index) => (
              <TypeValue
                basePath={`phones[${index}]`}
                defaultLabel={t('label.mobileNumber')}
                filterOption={filterOption}
                key={index}
                options={phoneOptions}
                useInput={useInput}
                onDelete={onDelete}
              />
            ))}
            <FormSection>
              <AddButton
                contentFirst={true}
                disabled={phones.length >= phoneOptions.length}
                label={t('button.addPhone')}
                variant="outlined"
                onClick={() => onAdd('phones', null)}
              >
                <Plus fontSize="small" />
              </AddButton>
            </FormSection>
          </ContactSection>
          <ContactSection>
            {emails.map((item, index) => (
              <TypeValue
                basePath={`emails[${index}]`}
                defaultLabel={t(index === 0 ? 'label.primaryEmail' : 'label.email')}
                fixedLabel
                key={index}
                options={emailOptions}
                useInput={useInput}
                onDelete={onDelete}
                onResetError={onResetError}
              />
            ))}
            <FormSection>
              <AddButton
                contentFirst={true}
                label={t('button.addEmail')}
                variant="outlined"
                onClick={() => onAdd('emails', null)}
              >
                <Plus fontSize="small" />
              </AddButton>
            </FormSection>
          </ContactSection>
        </FormRow>
      </FormSection>
    </FormContainer>
  );
}

Contact.propTypes = {
  className: PropTypes.string,
  useInput: PropTypes.func.isRequired,
  onAdd: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired
};

export default Contact;
