import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Snackbar from 'components/Snackbar';
import { defaultTheme } from 'themes';
import { Button } from 'components/buttons';
import { useForm, useTranslation } from 'hooks';
import useStyles, { BackLink } from './../style';
import { Message, LargeLink, Subtext } from './../style';
import { FormContainer, FormItem, FormRow } from 'components/styledComponents';
import { TextField } from 'components/inputs';

function ForgotPasswordPage({ syncing, resetPwdStatus, onResetPassword, onInit }) {
  useEffect(() => {
    onInit();
  }, [onInit]);

  const { t } = useTranslation();
  const [errors, setErrors] = useState({});
  const classes = useStyles();
  const { handleSubmit, useInput } = useForm({
    onSubmit: onResetPassword,
    validationSchema: 'forgotPasswordSchema'
  });
  const useInputVal = useInput('email');
  return (
    <Container maxWidth="xs">
      <Snackbar
        message={errors.request}
        open={!!errors.request}
        variant="error"
        onClose={() => setErrors({ request: null })}
      />
      <div className={classes.paper}>
        <div className={!resetPwdStatus ? '' : classes['center']}>
          <h3 className={classes['navlink.active']}>{t('title.forgottenPassword')}</h3>
        </div>
        <FormContainer>
          {resetPwdStatus !== 'success' && (
            <FormRow>
              <FormItem
                component={TextField}
                placeholder={t('label.emailAddress')}
                required
                className={classes['input']}
                {...useInputVal}
              />
            </FormRow>
          )}
          {resetPwdStatus && (
            <Typography className={classes['typo']} component="p">
              <Message isError={resetPwdStatus !== 'success'}>
                {t(
                  resetPwdStatus === 'success' ? 'message.resetPwdSuccess' : 'message.resetPwdError'
                )}
              </Message>
            </Typography>
          )}
          {!resetPwdStatus && (
            <div className={classes.formFooter}>
              <BackLink color={defaultTheme.color.primary.contrast} to="/login">
                {t('link.backToLogin')}
              </BackLink>
              <Button
                color="primary"
                variant="lightContained"
                disabled={syncing}
                label={t('button.retryPwdReset')}
                onClick={handleSubmit}
              />
            </div>
          )}
          {resetPwdStatus === 'success' && (
            <FormRow>
              <LargeLink fullWidth size="large" textAlign="center" to="/login">
                <Button
                  color="primary"
                  variant="lightContained"
                  disabled={syncing}
                  label={t('link.backToLogin')}
                />
              </LargeLink>
            </FormRow>
          )}
          <Subtext dangerouslySetInnerHTML={{ __html: t('message.supportMessage') }} />
        </FormContainer>
      </div>
    </Container>
  );
}

ForgotPasswordPage.propTypes = {
  resetPwdStatus: PropTypes.string,
  syncing: PropTypes.bool.isRequired,
  onInit: PropTypes.func.isRequired,
  onResetPassword: PropTypes.func.isRequired
};

export default ForgotPasswordPage;
