import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${props => props.theme.spacing(1, 1, 1.5, 1)};
  margin: ${props => props.theme.spacing(-1, -1, -1.5, -1)};
  :before {
    top: ${props => props.theme.spacing(7)};
  }
`;

export const FlexContainer = styled.div`
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  height: 300px;
  color: ${props => props.theme.color.primary.main};
  border: 1px solid ${props => props.theme.color.grey[500]};
`;

export const Legend = styled.div`
  display: flex;
  justify-content: space-around;
  margin-top: ${props => props.theme.spacing(1)};
`;

export const LegendItem = styled.div`
  display: flex;
  font-size: ${props => props.theme.fontSize.small};
  justify-content: space-around;
  margin: 0 ${props => props.theme.spacing(0.5)};

  ${props =>
    props.color &&
    `
    :before {
      background-color: ${props.color};
      content: '';
      display: inline-block;
      flex: 0 0 auto;
      height: 1em;
      margin-right: ${props.theme.spacing(1.25)};
      width: 1em;
    }
  `}
`;
