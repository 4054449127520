import styled from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';
import Link from 'components/Link';

export default makeStyles(theme => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    margin: theme.spacing(1, 0)
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  typo: {
    width: '100%'
  },
  formFooter: {
    borderBottom: '1px solid #ccc',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingBottom: '24px',
    marginBottom: '24px',
    marginTop: '16px',
    width: '100%'
  },
  navlink: {
    color: '#0088D1',
    margin: `${theme.spacing(1, 0)}`,
    textDecoration: 'none'
  },
  'navlink.active': {
    color: 'white',
    display: 'inline-block',
    marginBottom: '16px',
    paddingBottom: '16px',
    textTransform: 'uppercase',
    borderBottom: '1px solid #ccc',
    width: 'max-content'
  },
  // RTEXT-701
  input: {
    '& .MuiInputBase-root': {
      background: 'white'
    },
    '& .MuiFormHelperText-root.Mui-error': {
      color: 'white !important'
    },
    '& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline': {
      border: '1px solid white'
    }
  },
  center: {
    textAlign: 'center'
  }
}));

export const Message = styled.div`
  ${props => (props.isError ? 'color:' + props.theme.color.danger.contrast : '')};
  padding: ${props => props.theme.spacing(3, 0)};
  text-align: center;
  color: white;
  a {
    color: white;
  }
`;

export const LargeLink = styled(Link)`
  width: 100%;
  margin: ${props => props.theme.spacing(1.5, 1)};
  text-align: ${props => (props.textAlign ? props.textAlign : 'unset')};
`;

export const Title = styled.div`
  border-bottom: '1px solid #ccc';
  color: 'white';
  font-weight: ${props => props.theme.fontWeight.bold};
  margin-bottom: '6px';
  margin: ${props => props.theme.spacing(1, 0)};
  padding-bottom: '6px';
`;

export const Subtext = styled.div`
  color: ${props => props.theme.color.primary.contrast};
  font-size: ${props => props.theme.fontSize.small};
  font-weight: ${props => props.theme.fontWeight.normal};
  text-align: center;
  padding-bottom: ${props => props.theme.spacing(3)};
  width: 100%;
  a {
    color: ${props => props.theme.color.primary.contrast};
    display: block;
    margin-top: 5px;
  }
`;

export const BackLink = styled(Link)`
  &::before {
    content: '› ';
  }
`;

export const Text = styled.div`
  color: white;
  padding: 20px 0;
  text-align: center;
`;
