import { connect } from 'react-redux';
import * as metaSelectors from 'redux-modules/meta/selectors';
import * as opportunitiesActions from 'redux-modules/opportunities/actions';
import * as opportunitiesSelectors from 'redux-modules/opportunities/selectors';
import * as resumesActions from 'redux-modules/resumes/actions';
import * as resumesSelectors from 'redux-modules/resumes/selectors';
import CandidateCV from 'components/ProjectCandidateCV';

function mapStateToProps(state, ownProps) {
  return {
    cv: resumesSelectors.selectResume(state, ownProps.candidateId),
    indexedMeta: metaSelectors.selectIndexedMeta(state),
    project: opportunitiesSelectors.selectOpportunity(state, ownProps.opportunityId),
    loading:
      resumesSelectors.selectSyncState(state) || opportunitiesSelectors.selectSyncState(state)
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    onInit: () => {
      dispatch(resumesActions.loadMngCandidateCV(ownProps.candidateId));
      ownProps.opportunityId &&
        dispatch(opportunitiesActions.loadOpportunity({ id: ownProps.opportunityId }));
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CandidateCV);
