import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import TextFieldInput from './TextFieldInput';

const BASE_STYLE = {
  alignItems: 'center',
  display: 'flex',
  height: 'auto',
  minHeight: '3.5em',
  paddingBottom: 0,
  paddingRight: 0,
  paddingTop: 0
};

function Control(props) {
  const {
    children,
    innerProps,
    innerRef,
    selectProps: { TextFieldProps }
  } = props;

  return (
    <TextField
      InputProps={{
        inputComponent: TextFieldInput,
        inputProps: {
          children,
          ref: innerRef,
          style: BASE_STYLE,
          ...innerProps
        }
      }}
      {...TextFieldProps}
    />
  );
}

Control.propTypes = {
  children: PropTypes.node,
  innerProps: PropTypes.shape({
    onMouseDown: PropTypes.func.isRequired
  }).isRequired,
  innerRef: PropTypes.oneOfType([
    PropTypes.oneOf([null]),
    PropTypes.func,
    PropTypes.shape({
      current: PropTypes.any.isRequired
    })
  ]).isRequired,
  selectProps: PropTypes.object.isRequired
};

export default React.memo(Control);
