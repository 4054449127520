import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Route, withRouter } from 'react-router-dom';
import Dialog from 'components/Dialog';
import { useForm, useTranslation } from 'hooks';
import Form from './Form';

function CompetenceProfile({
  competenceProfile,
  history,
  language,
  match: { url },
  syncErrors,
  syncing,
  onSave
}) {
  const { t, tr } = useTranslation();
  const { useInput, handleReset, handleSubmit } = useForm({
    initialValues: competenceProfile,
    onSubmit: onSave
  });
  const handleClose = useCallback(() => {
    handleReset();
    history.push(url);
  }, [handleReset, history, url]);
  useEffect(() => {
    if (!syncErrors && !syncing) {
      handleClose();
    }
  }, [handleClose, syncErrors, syncing]);
  return (
    <Route
      path={`${url}/competence-profile`}
      render={() => (
        <Dialog
          actionLabel={t('button.save')}
          disabled={syncing}
          open={true}
          fullWidth={true}
          maxWidth="800px"
          syncing={syncing}
          title={tr('title.competenceProfile')}
          subTitle={tr('label.competenceInfoText')}
          onAction={handleSubmit}
          onClose={handleClose}
        >
          <Form data-test-id="competence-profile-form" language={language} useInput={useInput} />
        </Dialog>
      )}
    />
  );
}

CompetenceProfile.propTypes = {
  competenceProfile: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  language: PropTypes.string.isRequired,
  match: PropTypes.object.isRequired,
  syncErrors: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  syncing: PropTypes.bool.isRequired,
  onSave: PropTypes.func.isRequired
};

export default withRouter(CompetenceProfile);
