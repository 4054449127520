import * as types from './types';

export function failNewsLoad(error) {
  return {
    payload: {
      error
    },
    type: types.NEWS_FAILED
  };
}
export function loadNews(news) {
  return {
    type: types.LOAD_NEWS
  };
}

export function setLoadedNews(news) {
  return {
    payload: {
      news
    },
    type: types.NEWS_LOADED
  };
}
