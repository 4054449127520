import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { CardsContainer } from 'components/cards';
import {
  Title,
  CardsSubContainer,
  ButtonContainer,
  Button,
  ButtonLink,
  BillingCard,
  UploadLoader
} from './style';
import { Inhalt, Upload } from './Sections';
import { useTranslation } from 'hooks';

function BillingTab({
  monthlyReport,
  vatList,
  language,
  onVerify,
  toggleMonthChange,
  syncing,
  html,
  isVerified,
  placement,
  onSave,
  reset,
  month,
  year
}) {
  const { t } = useTranslation();
  const { id: monthlyReportId, billingStatus } = monthlyReport || {};
  const { id: placementId, travelExpenseManager, feeBilling } = placement || {};
  const [reports, setReports] = useState({});
  const [errors, setErrors] = useState({});
  const [showConfirmation, setShowConfirmation] = useState(false);

  useEffect(() => {
    reset && reset();
    handleAbort(false);
  }, [toggleMonthChange, placementId]);

  const handleVerify = () => {
    reports?.invoice &&
      onVerify &&
      onVerify({ id: monthlyReportId, language, invoiceReport: reports.invoice });
  };

  const handleSave = () => {
    if (travelExpenseManager === '200' && !reports?.expenses && !showConfirmation) {
      setShowConfirmation(true);
      return;
    }
    setShowConfirmation(false);
    onSave &&
      onSave({
        id: monthlyReportId,
        expensesReport: reports?.expenses,
        placementId,
        month,
        year
      });
  };

  const handleAbort = (doReset = true) => {
    if (!syncing) {
      setReports({});
      setErrors({});
      if (document.getElementById('invoice')) document.getElementById('invoice').value = '';
      if (document.getElementById('expenses')) document.getElementById('expenses').value = '';
      reset && doReset && reset();
    }
  };

  return (
    <>
      <CardsContainer jc="space-between">
        <CardsSubContainer size="4.5">
          <Title>{t('title.invoiceContent')}</Title>
          <BillingCard>
            <Inhalt
              feeBilling={feeBilling}
              language={language}
              monthlyReport={monthlyReport}
              vatList={vatList}
            />
          </BillingCard>
        </CardsSubContainer>
        <CardsSubContainer size="5.5">
          <Title>{t('title.invoiceUpload')}</Title>
          <BillingCard>
            <UploadLoader loading={syncing}>
              <Upload
                errors={errors}
                reports={reports}
                billingStatus={billingStatus}
                html={html}
                isVerified={isVerified}
                setReports={setReports}
                setErrors={setErrors}
                travelExpense={travelExpenseManager}
                showConfirmation={showConfirmation}
                setShowConfirmation={setShowConfirmation}
              />
            </UploadLoader>
          </BillingCard>
        </CardsSubContainer>
      </CardsContainer>
      {monthlyReportId && billingStatus === '100' && (
        <ButtonContainer>
          <Button
            color="primary"
            disabled={!isVerified}
            disabledColor="gray"
            label={t('label.submit')}
            onClick={handleSave}
          />
          {reports?.invoice && (
            <>
              <Button
                color="primary"
                disabled={isVerified}
                label={t('label.verify')}
                onClick={handleVerify}
              />
              <ButtonLink label={t('label.abort')} onClick={handleAbort} />
            </>
          )}
        </ButtonContainer>
      )}
    </>
  );
}

BillingTab.propTypes = {
  monthlyReport: PropTypes.object,
  vatList: PropTypes.array,
  language: PropTypes.string,
  onVerify: PropTypes.func.isRequired,
  toggleMonthChange: PropTypes.bool,
  syncing: PropTypes.bool,
  html: PropTypes.string,
  isVerified: PropTypes.bool,
  placement: PropTypes.object,
  month: PropTypes.number,
  year: PropTypes.number,
  onSave: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired
};

export default BillingTab;
