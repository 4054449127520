import React, { useMemo, useCallback } from 'react';
import { useTranslation } from 'hooks';
import QuestionContainer from './QuestionContainer';
import PropTypes from 'prop-types';

function Questions({ useInput, setDisabled }) {
  const { t } = useTranslation();

  const questions = useMemo(
    () => [
      {
        name: 'newApplicantQuestions.question1',
        statement: `${t('message.newApplicantQuestion1')}`
      },
      {
        name: 'newApplicantQuestions.question2',
        statement: `${t('message.newApplicantQuestion2')}`
      },
      {
        name: 'newApplicantQuestions.question3',
        statement: `${t('message.newApplicantQuestion3')}`
      },
      {
        name: 'newApplicantQuestions.question4',
        statement: `${t('message.newApplicantQuestion4')}`
      },
      {
        name: 'newApplicantQuestions.question5',
        statement: `${t('message.newApplicantQuestion5')}`
      },
      {
        name: 'newApplicantQuestions.question6',
        statement: `${t('message.newApplicantQuestion6')}`
      },
      {
        name: 'newApplicantQuestions.question7',
        statement: `${t('message.newApplicantQuestion7')}`
      },
      {
        name: 'newApplicantQuestions.question8',
        statement: `${t('message.newApplicantQuestion8')}`
      }
    ],
    [t]
  );

  const { value: q1, onChange: handleQ1Change } = useInput('newApplicantQuestions.question1');
  const { value: q2, onChange: handleQ2Change } = useInput('newApplicantQuestions.question2');
  const { value: q3, onChange: handleQ3Change } = useInput('newApplicantQuestions.question3');
  const { value: q4, onChange: handleQ4Change } = useInput('newApplicantQuestions.question4');
  const { value: q5, onChange: handleQ5Change } = useInput('newApplicantQuestions.question5');
  const { value: q6, onChange: handleQ6Change } = useInput('newApplicantQuestions.question6');
  const { value: q7, onChange: handleQ7Change } = useInput('newApplicantQuestions.question7');
  const { value: q8, onChange: handleQ8Change } = useInput('newApplicantQuestions.question8');

  // RTEXT-726
  // enable button if all questions are answered
  if (q1 && q2 && q3 && q4 && q5 && q6 && q7 && q8) {
    setDisabled(false);
  }

  const handleChange = useCallback(
    event => {
      switch (event.target.name) {
        case 'newApplicantQuestions.question1':
          handleQ1Change(event);
          break;
        case 'newApplicantQuestions.question2':
          handleQ2Change(event);
          break;
        case 'newApplicantQuestions.question3':
          handleQ3Change(event);
          break;
        case 'newApplicantQuestions.question4':
          handleQ4Change(event);
          break;
        case 'newApplicantQuestions.question5':
          handleQ5Change(event);
          break;
        case 'newApplicantQuestions.question6':
          handleQ6Change(event);
          break;
        case 'newApplicantQuestions.question7':
          handleQ7Change(event);
          break;
        case 'newApplicantQuestions.question8':
          handleQ8Change(event);
          break;
        default:
          break;
      }
    },
    [
      handleQ1Change,
      handleQ2Change,
      handleQ3Change,
      handleQ4Change,
      handleQ5Change,
      handleQ6Change,
      handleQ7Change,
      handleQ8Change
    ]
  );

  return (
    <>
      {questions &&
        questions.map(({ name, statement }, index) => (
          <QuestionContainer
            key={index}
            name={name}
            statement={statement}
            useInput={useInput}
            handleChange={handleChange}
          />
        ))}
    </>
  );
}

Questions.propTypes = {
  useInput: PropTypes.func.isRequired,
  setDisabled: PropTypes.func.isRequired
};

export default Questions;
