import React, { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import TimeField from './TimeInput';
import { useTranslation } from 'hooks';
import { Border } from '../style';
import { FlexContainer, TimeFieldEntry, AddBreakButton, DeleteButton } from './style';

function TimeLog({
  language,
  pauseErrors,
  pauseTimePairs,
  setPauseErrors,
  setPauseTimePairs,
  setWorkEndError,
  setWorkFrom,
  setWorkStartError,
  setWorkTo,
  workEndError,
  workFrom,
  workStartError,
  workTo
}) {
  const { t } = useTranslation();

  useEffect(() => {
    validateTimeFields();
  }, [JSON.stringify(pauseTimePairs), workFrom, workTo]);

  const handleAddPair = () => {
    setPauseTimePairs([
      ...pauseTimePairs,
      { id: pauseTimePairs.length, start: '00:00', end: '00:00' }
    ]);
  };

  const handlePauseTimeChange = (id, field, value) => {
    const updatedPairs = pauseTimePairs.map(pair => {
      if (pair.id === id) {
        return { ...pair, [field]: value };
      }
      return pair;
    });
    setPauseTimePairs(updatedPairs);
  };

  const handleWorkTimeChange = (value, key) => {
    key === 'from' ? setWorkFrom(value) : setWorkTo(value);
  };

  const handlePauseTimeDelete = id => {
    const filteredPairs = pauseTimePairs.filter(p => p.id !== id);
    setPauseTimePairs(filteredPairs);
  };

  const convertTimeToMinutes = useCallback(timeString => {
    const [hours, minutes] = timeString.split(':');
    return parseInt(hours, 10) * 60 + parseInt(minutes, 10);
  }, []);

  const validateTimeFields = useCallback(() => {
    // Reset previous errors
    setWorkStartError(false);
    setWorkEndError(false);
    setPauseErrors([]);

    const workStartMinutes = convertTimeToMinutes(workFrom);
    const workEndMinutes = convertTimeToMinutes(workTo);

    if (workStartMinutes === 0 && workEndMinutes === 0) {
      setWorkStartError(true);
      return;
    }

    // Validate "work_end" and "work_start"
    if (workEndMinutes < workStartMinutes) {
      setWorkEndError(true);
      return;
    }

    // Validate "pause" intervals
    const filteredPairs = pauseTimePairs.filter(p => p.start !== '00:00' || p.end !== '00:00');
    for (let index = 0; index < filteredPairs.length; index++) {
      const { id, start, end } = filteredPairs[index] || {};
      const startMinutes = convertTimeToMinutes(start);
      const endMinutes = convertTimeToMinutes(end);

      if (startMinutes == 0 && endMinutes == 0) continue;

      const previousEndMinutes =
        index > 0 ? convertTimeToMinutes(filteredPairs[index - 1].end) : workEndMinutes;

      if (index === 0) {
        if (startMinutes < workStartMinutes || startMinutes > previousEndMinutes) {
          setPauseErrors([{ index: id, field: 'start' }]);
          return;
        }

        if (endMinutes < startMinutes || endMinutes > previousEndMinutes) {
          setPauseErrors([{ index: id, field: 'end' }]);
          return;
        }
      } else {
        if (startMinutes < previousEndMinutes || startMinutes > workEndMinutes) {
          setPauseErrors([{ index: id, field: 'start' }]);
          return;
        }

        if (
          endMinutes < startMinutes ||
          endMinutes < previousEndMinutes ||
          endMinutes > workEndMinutes
        ) {
          setPauseErrors([{ index: id, field: 'end' }]);
          return;
        }
      }
    }
  }, [
    convertTimeToMinutes,
    pauseTimePairs,
    setPauseErrors,
    setWorkEndError,
    setWorkStartError,
    workFrom,
    workTo
  ]);

  const hasError = (pairId, field) => {
    return pauseErrors.some(error => error.index === pairId && error.field === field);
  };

  return (
    <>
      <FlexContainer direction="column">
        <FlexContainer alignItems="baseline">
          <TimeFieldEntry>
            <label>{t('label.workStart')}:</label>
            <TimeField
              className={workStartError ? 'error' : ''}
              value={workFrom}
              onChange={(e, value) => handleWorkTimeChange(value, 'from')}
            />
          </TimeFieldEntry>
          <TimeFieldEntry>
            <label>{t('label.workEnd')}:</label>
            <TimeField
              className={workEndError ? 'error' : ''}
              value={workTo}
              onChange={(e, value) => handleWorkTimeChange(value, 'to')}
            />
          </TimeFieldEntry>
        </FlexContainer>
        {pauseTimePairs.map(pair => (
          <FlexContainer key={pair.id}>
            <TimeFieldEntry>
              <label>{t('label.pauseStart')}:</label>
              <TimeField
                className={hasError(pair.id, 'start') ? 'error' : ''}
                value={pair.start}
                onChange={(e, value) => handlePauseTimeChange(pair.id, 'start', value)}
              />
            </TimeFieldEntry>
            <TimeFieldEntry>
              <label>{t('label.pauseEnd')}:</label>
              <TimeField
                className={`${hasError(pair.id, 'end') ? 'error' : ''} ${
                  pair.id > 0 && pair.id === pauseTimePairs.length - 1 ? `${language}` : ''
                }`}
                value={pair.end}
                onChange={(e, value) => handlePauseTimeChange(pair.id, 'end', value)}
              />
              {pair.id > 0 && pair.id === pauseTimePairs.length - 1 && (
                <DeleteButton
                  color="danger"
                  icon="Trash"
                  size="small"
                  onClick={() => handlePauseTimeDelete(pair.id)}
                />
              )}
            </TimeFieldEntry>
          </FlexContainer>
        ))}
        {pauseTimePairs.length <= 2 && (
          <AddBreakButton onClick={handleAddPair}>
            <span>{t('button.addBreak')}</span>
          </AddBreakButton>
        )}
        <Border />
      </FlexContainer>
    </>
  );
}

TimeLog.propTypes = {
  language: PropTypes.string,
  pauseErrors: PropTypes.array,
  pauseTimePairs: PropTypes.array,
  setPauseErrors: PropTypes.func,
  setPauseTimePairs: PropTypes.func,
  setWorkEndError: PropTypes.func,
  setWorkFrom: PropTypes.func,
  setWorkStartError: PropTypes.func,
  setWorkTo: PropTypes.func,
  workEndError: PropTypes.bool,
  workFrom: PropTypes.string,
  workStartError: PropTypes.bool,
  workTo: PropTypes.string
};

export default TimeLog;
