import React, { useCallback, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import Dialog from 'components/Dialog';
import { useForm, useTranslation } from 'hooks';
import Form from './Form';

function ITSkills({ itSkills, syncErrors, syncing, onClose, onSave }) {
  const { t, tr } = useTranslation();
  const didMountRef = useRef(false);
  const { handleReset, handleSubmit, useInput, useTree } = useForm({
    initialValues: itSkills,
    onSubmit: onSave
  });
  const handleClose = useCallback(() => {
    handleReset();
    onClose();
  }, [handleReset, onClose]);
  useEffect(() => {
    if (didMountRef.current) {
      if (!syncErrors && !syncing) {
        handleClose();
      }
    } else {
      didMountRef.current = true;
    }
  }, [syncErrors, syncing, handleClose]);

  return (
    <Dialog
      actionLabel={t('button.save')}
      disabled={syncing}
      open={true}
      syncing={syncing}
      title={tr('title.itSkills')}
      onAction={handleSubmit}
      onClose={handleClose}
    >
      <Form useInput={useInput} useTree={useTree} />
    </Dialog>
  );
}

ITSkills.propTypes = {
  className: PropTypes.string,
  itSkills: PropTypes.object.isRequired,
  syncErrors: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  syncing: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired
};

export default ITSkills;
