import * as types from './types';
import * as actions from './actions';
import apiClient from 'communication/apiClient';
import * as placementActions from '../placements/actions';
import { call, put, takeLeading } from 'redux-saga/effects';

export function* verifyBilling(actionData) {
  const { id, language, invoiceReport } = actionData.payload.data;
  const formData = new FormData();
  formData.append('id', id);
  formData.append('language', language);
  formData.append('file', invoiceReport);
  const resp = yield call(
    apiClient.custom,
    {
      url: '/api/v1/verify-billing-report',
      method: 'POST',
      body: formData
    },
    actionData.payload.failCallback
  );
  if (resp && resp.data) {
    yield put(actions.verifySuccess(resp.data));
  } else {
    // error handling (todos)
  }
}

export function* saveBilling(actionData) {
  const { id, placementId, month, year, expensesReport } = actionData.payload.data;
  const formData = new FormData();
  formData.append('id', id);
  if (expensesReport) {
    formData.append('file', expensesReport);
  }
  const resp = yield call(
    apiClient.custom,
    {
      url: '/api/v1/save-billing-report',
      method: 'POST',
      body: formData
    },
    actionData.payload.failCallback
  );
  if (resp && resp.data) {
    // fetching updated placement information after saving billing report - prevents reloading
    yield put(placementActions.loadPlacementOverviewMonth(placementId, year, month, true));
    yield put(actions.saveSuccess());
  } else {
    // error handling (todos)
  }
}

export default [
  takeLeading(types.VERIFY_BILLING, verifyBilling),
  takeLeading(types.SAVE_BILLING, saveBilling)
];
