import React from 'react';
import PropTypes from 'prop-types';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import MuiCheckbox from '@material-ui/core/Checkbox';

function Checkbox({ label, ...rest }) {
  return <FormControlLabel control={<MuiCheckbox {...rest} />} label={label} />;
}

Checkbox.defaultProps = {
  color: 'primary'
};

Checkbox.propTypes = {
  label: PropTypes.string
};

export default React.memo(Checkbox);
