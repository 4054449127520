import React, { useState, useCallback, useEffect, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import Dialog from 'components/Dialog';
import { useForm, useTranslation } from 'hooks';
import DeleteDialogFooter from '../../../DeleteDialogFooter';
import Form from './Form';
import { makeStyles } from '@material-ui/core/styles';

const colors = ['#c0cbd7', '#dddddd', '#ffffff', '#9da7a2', '#e6e9e6', '#c31924', '#237ec5'];

const useStyles = makeStyles(theme => ({
  paper: {
    maxWidth: '665px'
  }
}));

const components = {
  Footer: DeleteDialogFooter
};

function Editor({ onClose, manager_id, templates }) {
  const { t } = useTranslation();

  // RTEXT-652
  const [selectedDesign, setSelectedDesign] = useState(1);
  const [selectedColor, setSelectedColor] = useState('#c0cbd7');

  const { values, useInput, handleReset } = useForm({
    initialValues: useMemo(() => ({ cvType: 'short', language: '100' }), [])
  });

  const handleClose = useCallback(() => {
    handleReset();
    onClose();
  }, [handleReset, onClose]);

  const { value: cvType } = useInput('cvType');
  const { value: language } = useInput('language');

  const handleSubmit = () => {
    window.open(
      `/api/v1/manager/pdf/file/${language}/${cvType}/${selectedDesign}/${selectedColor.substring(
        1
      )}/${manager_id}`
    );
    handleClose();
  };

  const disableCreateCVButton = useMemo(() => !(Object.keys(values).length == 2), [values]);
  const classes = useStyles();

  return (
    <Dialog
      actionLabel={t('button.createCV')}
      component="a"
      disabled={disableCreateCVButton}
      open={true}
      title={t('title.pdfConfigTitle')}
      onAction={handleSubmit}
      onClose={handleClose}
      maxWidth="sm"
      footerProps={{
        disableBorder: 'none'
      }}
      paperClass={classes.paper}
      overflowX="hidden"
    >
      <Form
        data-test-id="create-cv-form"
        useInput={useInput}
        // RTEXT-652
        selectedDesign={selectedDesign}
        selectedColor={selectedColor}
        setSelectedDesign={setSelectedDesign}
        setSelectedColor={setSelectedColor}
        colors={colors}
        templates={templates}
      />
    </Dialog>
  );
}

Editor.propTypes = {
  onClose: PropTypes.func.isRequired,
  templates: PropTypes.array
};

export default Editor;
