import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'hooks';
import { statuses } from './config';
import { Container } from './style';

function StatusText({ className, statusCode }) {
  const { t } = useTranslation();
  const { label, ...rest } = useMemo(() => {
    switch (statusCode) {
      case '100':
        return statuses.new;
      case '150':
      case '200':
      case '250':
      case '300':
        return statuses.applied;
      case '350':
      case '400':
        return statuses.presentation;
      case '500':
        return statuses.mandateInPrepration;
      case '550':
        return statuses.won;
      case '1100':
        return statuses.closed;
      default:
        return statuses.default;
    }
  }, [statusCode]);

  return (
    <Container className={className} {...rest}>
      {t(label)}
    </Container>
  );
}

StatusText.propTypes = {
  className: PropTypes.string,
  statusCode: PropTypes.string
};

export default StatusText;
