import { connect } from 'react-redux';
import * as accountSelectors from 'redux-modules/resume/selectors';
import * as accountActions from 'redux-modules/resume/actions';
import * as authActions from 'redux-modules/auth/actions';
import Editor from './Editor';

function mapStateToProps(state) {
  return {
    syncing: accountSelectors.selectSyncState(state),
    syncErrors: accountSelectors.selectSyncError(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onDelete: (data, failCallback) => dispatch(accountActions.deleteProfile(data, failCallback)),
    onLogout: () => dispatch(authActions.logout())
  };
}

export default connect(mapStateToProps,mapDispatchToProps)(Editor);
