import styled from 'styled-components';
import { default as BaseItem } from './Item';
import { default as BaseItemDesktop } from './DesktopItem';
import { default as BaseItemMobile } from './MobileItem';

export const Item = styled(BaseItem)`
  box-sizing: border-box;
  flex-grow: ${props => props.grow || 0};
  flex-shrink: 0;
  min-width: 200px;
  padding: ${props => props.theme.spacing(2)};
  white-space: normal;

  @media (min-width: 1101px) {
    flex-basis: 33%;
  }

  @media (max-width: 1100px) and (min-width: 501px) {
    flex-basis: 50%;
  }

  @media (max-width: 500px) {
    flex-basis: 100%;
  }
`;

export const DesktopItem = styled(BaseItemDesktop)`
  box-sizing: border-box;
  flex-grow: ${props => props.grow || 0};
  flex-shrink: 0;
  min-width: 200px;
  padding: ${props => props.theme.spacing(2)};
  white-space: normal;
  padding-bottom:8px;
  padding-top:8px;

  @media (min-width: 1101px) {
    flex-basis: 33%;
  }

  @media (max-width: 1100px) and (min-width: 501px) {
    flex-basis: 50%;
  }

  @media (max-width: 500px) {
    flex-basis: 100%;
  }
`;

export const MobileItem = styled(BaseItemMobile)`
  box-sizing: border-box;
  flex-grow: ${props => props.grow || 0};
  flex-shrink: 0;
  min-width: 200px;
  padding: ${props => props.theme.spacing(2)};
  white-space: normal;
  padding-bottom:4px;

  @media (max-width: 500px) {
    flex-basis: 100%;
  }
`;

export const ExperienceAbroadHeader = styled(BaseItemDesktop)`
  box-sizing: border-box;
  flex-grow: ${props => props.grow || 0};
  flex-shrink: 0;
  min-width: 200px;
  padding: ${props => props.theme.spacing(2)};
  white-space: normal;
  padding-bottom:0px;
  padding-top:8px;

  @media (min-width: 1101px) {
    flex-basis: 33%;
  }

  @media (max-width: 1100px) and (min-width: 501px) {
    flex-basis: 50%;
  }

  @media (max-width: 500px) {
    flex-basis: 100%;
  }
`;
export const DescriptionItem = styled(BaseItem)`
  box-sizing: border-box;
  flex-grow: ${props => props.grow || 0};
  flex-shrink: 0;
  padding: ${props => props.theme.spacing(2)};
  
  @media (min-width: 1101px) {
    flex-basis: 100%;
  }

  @media (max-width: 1100px) and (min-width: 501px) {
    flex-basis: 100%;
  }

  @media (max-width: 500px) {
    flex-basis: 100%;
  }

`;


export const DetailsRow = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

export const DetailedView = styled.div`
  width: 100%;
`;

export const DetailsWrapper = styled.div`
`;

// @TODO clean this file
export const Title = styled.div`
  font-size: ${props => props.theme.fontSize.large};
  font-weight: ${props => props.theme.fontWeight.normal};
  line-height: ${props => props.theme.spacing(3)};
  margin: ${props => props.theme.spacing(2)};
  text-align: left;
  text-transform: uppercase;
  width: 100%;
`;
export const TitleDescription = styled.div`
  font-size: ${props => props.theme.fontSize.base};
  font-weight: ${props => props.theme.fontWeight.bold};
  line-height: ${props => props.theme.spacing(2)};
  margin: 16px 16px 0px 16px;
  text-align: left;
  width: 100%;
`;

export const Container = styled.div`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  hr {
    border-top: 1px solid ${props => props.theme.color.grey[500]};
    border-bottom: none;
    margin: 0;
  }

  .compParent {
    word-break: break-all;
    padding: 15px 0;

    h2 {
      word-break: break-all;
      font-size: ${props => props.theme.fontSize.large};
    }
  }
  .clt,
  .clt .list,
  .clt .listItem {
    position: relative;
    width: 100%;
  }

  .clt .list {
    list-style: none;
    padding-left: 15px;
    margin-top: 10px;
    box-sizing: border-box;
    margin-bottom: 16px;
    margin-top: 15px;
  }

  .clt .list .listItem {
    margin-top: 10px;
    width: 100%;
  }

  .clt .listItem::before,
  .clt .listItem::after {
    content: '';
    position: absolute;
    left: -12px;
  }

  .clt .listItem::before {
    border-top: 1px solid #444444;
    top: 12px;
    width: 8px;
    height: 1px;
  }

  .clt .listItem::after {
    border-left: 1px solid #444444;
    height: calc(100% + 10px);
    width: 1px;
    top: -5px;
  }

  .clt .list > .listItem:last-child::after {
    height: 23px;
  }
    
  .clt p {
    line-height: 1.4;
  }
  
   .compParent h2.WexpGroupName {
    font-weight: 400;
    color: #444;
    font-size: 24px;
    margin-top: 10px;
    line-height: 1.083;
    margin-bottom: 5px;
  }
  .compParent {
    margin-left: -7px;
    padding: 0;
  }
  
  .compParent > div{ display: inline-flex; }
  .compParent .CompanyGroup-btn{ margin-top: 5px; margin-left: 5px; padding: 0; }
  .compParent .comp_edit_btn{ position: relative; padding: 10px 17px;}
  .compParent .comp_edit_btn > svg{
    position: absolute;
    top: 2px;
  }
  .comp_name{
    margin-top: -8px;
  }
  .compParent .list li.listItem{
    padding-left: 20px;
    width: calc(100% - 20px);
    /* Lines overlap at small screens */
    padding-top: 10px;
    margin-top: 0px;
  }
  .compParent li.listItem::before{ top: 18px;  }
  .compParent div.listItem::before{ top: 18px;  }
  .compParent .list div.listItem{
    margin-top: 0;
    align-items: flex-start;
  }
  .compParent div.listItem {
    padding-top: 10px;
  }
  .compParent .list div.listItem > a{
    /* padding-top: 3px;
    padding-bottom: 5px; */
    padding-top: 0;
    padding-bottom: 0;
    border: none;
    color: ${props => props.theme.color.primary.main};
    margin-left: 10px;
    font-size: 12px;
  }
  .compParent .listItem::before{ width: 25px; }
  .compParent .compPost-btn{
    position: relative;
    margin-left: 5px;
    padding-top: 3px;
   }
  .compParent .compPost-btn > svg{
    position: absolute;
    /* top: 5px; */
    top: 0;
    height: 14px;
    color: ${props => props.theme.color.primary.main};
  }
  .compParent .compPost-title{
    font-size: 16px;
    font-weight: 700;
    line-height: 1.25;
    color: ${props => props.theme.color.primary.main};
    
  }
  .compParent > div{ padding-bottom: 5px; }
  .compParent > .list > .listItem::after{ top: -15px; height: calc(100% + 33px); }
  .CompanyGroup-btn > svg {height: 22px;}
  .compParent .compAddPosition{ margin-top: 0px; }
  .compDivider{ margin: 7px auto 13px; }
  .textClass + div{ background-color: #fff; }
  .textClass + div button{
    font-size: 13px;
   }
  .compParent .textClass{
    padding-right: 110px;
    
   }
  .textClass + div button{ margin-top: -25px; }
   .compGroup-btn > svg,
  .textClass + div button > span{
    color: ${props => props.theme.color.primary.main};
   }
  .compParent .compExp-divider{
    margin-top: -10px;
    margin-bottom: 20px;
   }
   /* .compParent .list > .listItem:only-child::after { height: 27px; } */
   .add-comp-grp {    
     display: flex !important;
     margin-bottom: 10px; 
    }
    /* The position badges are not sorted vertically but horizontally. */
    .compParent .position-Tag{
        clear: both;
        margin-bottom: 5px;
    }
    /* In the company description pane, the width of company gets shrunk in case of having few data. */
    .compParent > div{ width: 100%; }
    /* The connecting lines are tightly linked with the 'Add Position within company' option. */
    .textClass + div .MuiCollapse-wrapperInner{ padding-bottom: 15px; }
    /* Remove the gap of last list item of company group */
    .compParent > .list > .listItem:only-child::after,
    .compParent > .list > .listItem:last-child::after { height: 34px; }
    
    .des-content {
        padding: 8px 16px 0px 16px;
    }
    
`;

export const DottedHR = styled.div`
  border-top: 2px dotted #CCCCCC;
`;

