import React from 'react';
import PropTypes from 'prop-types';
import {MultiLanguageRichTextField} from 'components/inputs';
import {FormContainer, FormItem, FormRow, FormSection} from 'components/styledComponents';
import {CompetenceEditor, Label} from './style';
import Grid from '@material-ui/core/Grid';
import {useTranslation} from 'hooks';

function Form({className, language, useInput, ...rest}) {
    const {tr} = useTranslation();
    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12} md={4}>

                    <CompetenceEditor>
                        <Label>{tr('label.industryFocuses')}</Label>
                        <FormItem
                            className="column-width-competence"
                            component={MultiLanguageRichTextField}
                            data-test-id="competence-profile-rich-editor"
                            data-rttextarea-id="competence-profile-rich-editor1"
                            label={tr('label.industryFocuses')}
                            language={language}
                            {...useInput('industryFocuses')} />
                    </CompetenceEditor>
                </Grid>
                <Grid item xs={12} md={4}>
                    <CompetenceEditor>
                        <Label>{tr('label.specialistFocuses')}</Label>
                        <FormItem
                            component={MultiLanguageRichTextField}
                            data-test-id="competence-profile-rich-editor"
                            data-rttextarea-id="competence-profile-rich-editor2"
                            label={tr('label.specialistFocuses')}
                            language={language}
                            {...useInput('specialistFocuses')}
                            className="column-width-competence"
                        />
                    </CompetenceEditor>
                </Grid>
                <Grid item xs={12} md={4}>
                    <CompetenceEditor>
                        <Label>{tr('label.managementMethods')}</Label>
                        <FormItem
                            component={MultiLanguageRichTextField}
                            data-test-id="competence-profile-rich-editor"
                            data-rttextarea-id="competence-profile-rich-editor3"
                            label={tr('label.managementMethods')}
                            language={language}
                            {...useInput('managementMethods')}
                            className="column-width-competence"
                        />
                    </CompetenceEditor>
                </Grid>


            </Grid>
        </>
    );
}

Form.propTypes = {
    className: PropTypes.string,
    language: PropTypes.string.isRequired,
    useInput: PropTypes.func.isRequired
};

export default Form;
