import React, { useMemo, useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { StyledSelectField } from './style';
import { format } from 'date-fns';
import { useLocale } from 'hooks';

// commented in RTETX-620
// const today = new Date();

function MonthSelector({ language, placementDetails, year, month, onMonthChanged}) {
  const { monthlyReports } = placementDetails.placement || [];
  const [currentMonth, setCurrentMonth] = useState(null);
  const locale = useLocale(language);
  
  useEffect(() => {
    var newDate = new Date(parseInt(year), parseInt(month) - 1, 1);
    setCurrentMonth(newDate);
  }, [year, month, language]);

  // RTEXT-620
  // sort dates in descending order
  const sortDates = useCallback(dates => {
    return dates.sort((dateA, dateB) => new Date(dateB) - new Date(dateA));
  }, []);

  // RTEXT-620
  // displaying only those months that have monthly report record in sugar
  const placementMonths = useMemo(() => {
    const months = [];
    if (monthlyReports) {
      const sortedMonthlyReports = sortDates(monthlyReports);
      sortedMonthlyReports.forEach(reportDate => {
        reportDate = new Date(reportDate);
        reportDate = new Date(reportDate.getFullYear(), reportDate.getMonth(), 1);
        var month = format(reportDate, 'MMMM yyyy', { locale: locale });
        var item = { key: new Date(reportDate), value: month };
        months.push(item);
      });
    }
    return months;
  }, [monthlyReports, locale]);

  // commented in RTEXT-620
  // const [placementMonths] = useMemo(() => {
  //   const months = [];
  //   if (placementDetails) {
  //     var startDate = new Date(placementDetails.placement.startDate);
  //     const currentDate = new Date(
  //       placementDetails.currentYear,
  //       placementDetails.currentMonth - 1,
  //       1
  //     );
  //     var date = startDate;
  //     date = new Date(date.getFullYear(), date.getMonth(), 1);
  //     while (date <= today && date <= currentDate) {
  //       var month = format(date, 'MMMM yyyy', { locale: locale });
  //       var item = { key: new Date(date), value: month };
  //       months.push(item);
  //       date = new Date(date.setMonth(date.getMonth() + 1));
  //     }
  //   }
  //   return [months];
  // }, [placementDetails, locale]);

  return (
    <StyledSelectField
      options={placementMonths}
      value={currentMonth}
      onChange={value => {
        setCurrentMonth(value);
        onMonthChanged && onMonthChanged(value);
      }}
    />
  );
}

MonthSelector.propTypes = {
  className: PropTypes.string,
  language: PropTypes.string.isRequired,
  year: PropTypes.number,
  month: PropTypes.number,
  placementDetails: PropTypes.object,
  onMonthChanged: PropTypes.func
};

export default MonthSelector;
