import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Details from './Details';
import Section from '../Section';

function OtherActivities({ className, editPath, language, otherActivities }) {
  const otherActivitiesData = useMemo(() => otherActivities[language], [otherActivities, language]);
  const path = useMemo(() => editPath && `${editPath}/other-activities`, [editPath]);

  return (
    <Section className={className} editPath={path} title="title.otherActivities">
      {/* Boolean conversion needed to avoid displaying "0" when length is 0 */}
      {!!(otherActivitiesData && otherActivitiesData.length) && (
        <Details otherActivities={otherActivitiesData} />
      )}
    </Section>
  );
}

OtherActivities.propTypes = {
  className: PropTypes.string,
  editPath: PropTypes.string,
  language: PropTypes.string.isRequired,
  otherActivities: PropTypes.object.isRequired
};

export default OtherActivities;
