import React from 'react';
import PropTypes from 'prop-types';
import MenuItem from '@material-ui/core/MenuItem';
import { defaultTheme } from 'themes';

const EMPTY_STYLE = {};
const SELECTED_STYLE = {
  fontWeight: defaultTheme.fontWeight.bold
};

function Option({ children, innerProps, innerRef, isFocused, isSelected }) {
  return (
    <MenuItem
      component="div"
      ref={innerRef}
      selected={isFocused}
      style={isSelected ? SELECTED_STYLE : EMPTY_STYLE}
      {...innerProps}
    >
      {children}
    </MenuItem>
  );
}

Option.propTypes = {
  children: PropTypes.node,
  innerProps: PropTypes.shape({
    id: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    onMouseMove: PropTypes.func.isRequired,
    onMouseOver: PropTypes.func.isRequired,
    tabIndex: PropTypes.number.isRequired
  }).isRequired,
  innerRef: PropTypes.oneOfType([
    PropTypes.oneOf([null]),
    PropTypes.func,
    PropTypes.shape({
      current: PropTypes.any.isRequired
    })
  ]),
  isFocused: PropTypes.bool.isRequired,
  isSelected: PropTypes.bool.isRequired
};

export default React.memo(Option);
