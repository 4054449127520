import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Menu from '@material-ui/core/Menu';

const AppMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
    boxShadow:
      '0 1px 5px 0 rgba(0, 0, 0, 0.16), 0 3px 4px 0 rgba(0, 0, 0, 0.16), 0 2px 4px 0 rgba(0, 0, 0, 0.16)',
    marginTop: '8px'
  }
})(props => (
  <Menu
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center'
    }}
    elevation={0}
    getContentAnchorEl={null}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center'
    }}
    {...props}
  />
));

export default AppMenu;
