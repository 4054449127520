import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import Container from '@material-ui/core/Container';
import Link from 'components/Link';
import { IconButton } from 'components/buttons';
import { Loader } from 'components/styledComponents';
import ProfilePicture from 'components/ProfilePicture';
import LanguageSelector from './LanguageSelector';
import { useTranslation } from 'hooks';
import {
  DetailsContainer,
  DetailsWrapper,
  Name,
  ProfileImage,
  UserDetails,
  UserInfo
} from './style';
import { Button } from 'components/buttons';

function PersonalDetails({
  editPath,
  className,
  fullName,
  loading,
  readonly,
  user,
  networkStatus,
  onMarkComplete,
  language
}) {
  const { t, tr } = useTranslation();
  const handleClick = useCallback(() => {
    onMarkComplete({ markComplete: true });
  }, []);

  const path = useMemo(() => editPath && `${editPath}/pdfcreation`, [editPath]);

  return (
    <Loader className={className} component={UserInfo} loading={loading}>
      <Container>
        <LanguageSelector />
        <DetailsWrapper>
          <DetailsContainer>
            <ProfileImage
              alt={t('placeholder.profileImage')}
              as={ProfilePicture}
              size="large"
              src={user.photo}
            />
            <UserDetails>
              <Name>{fullName || t('title.fullName')}</Name>
              {!readonly && <IconButton component={Link} icon="Edit" to="/account" />}
              {/* 
              commented in RTEXT-713
              <div className="submitAppButton">
                {networkStatus && networkStatus == 200 && (
                  <>
                    <Button
                      color="primary"
                      disabled={false}
                      label={
                        <span className="SubmitAppButtonLabel">
                          {t('button.submitApplication')}
                        </span>
                      }
                      onClick={handleClick}
                    />
                    <div style={{ paddingTop: '10px' }}></div>
                  </>
                )}

                <Button
                  component={Link}
                  color="primary"
                  style={{ color: 'white' }}
                  disabled={false}
                  label={<span className="SubmitAppButtonLabel">{t('button.createCV')}</span>}
                  to={path}
                />
              </div> */}
            </UserDetails>
          </DetailsContainer>
        </DetailsWrapper>
      </Container>
    </Loader>
  );
}

PersonalDetails.defaultProps = {
  readonly: false
};

PersonalDetails.propTypes = {
  className: PropTypes.string,
  fullName: PropTypes.string,
  loading: PropTypes.bool.isRequired,
  readonly: PropTypes.bool.isRequired,
  user: PropTypes.object
};

export default PersonalDetails;
