import * as types from './types';

export function failMetaLoad(error) {
  return {
    type: types.META_FAILED
  };
}

export function loadMeta() {
  return {
    type: types.LOAD_META
  };
}

export function setLoadedMeta(meta, language) {
  // filter empty values from meta lists
  Object.keys(meta).forEach(key => {
    const metaItem = meta[key];
    if (Array.isArray(metaItem)) {
      if (key == 'countries' || key == 'certificateTypes' || key == 'titles') {
        meta[key] = metaItem;
      } else {
        meta[key] = metaItem.filter(item => item.value);
      }
    }
  });

  return {
    payload: {
      language,
      meta
    },
    type: types.META_LOADED
  };
}
