import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const GROW_BASE = 999;

export const FormItem = styled(({ component: Component, empty, size, ...rest }) => (
  <Component {...rest} />
))`
  ${props =>
    props.size === '0'
      ? `
    flex: 1 0 auto;
  `
      : `
    flex: ${GROW_BASE * props.size} 1 15%;
    min-width: 300px;
  `}
  ${props =>
    props.empty &&
    `&&& {
      margin-bottom: auto;
      margin-top: auto;
      min-height: 1px;
    }
  `}
`;

FormItem.defaultProps = {
  component: 'div',
  size: '1'
};

FormItem.propTypes = {
  component: PropTypes.oneOfType([PropTypes.func, PropTypes.node, PropTypes.object]),
  empty: PropTypes.bool,
  size: PropTypes.string.isRequired
};

export const FormSection = styled(
  ({ component: Component, divider, padding, widthPercent, wrap, ...rest }) => (
    <Component {...rest} />
  )
)`
  box-sizing: border-box;
  border: 0 solid ${props => props.theme.color.grey[500]};
  display: flex;
  flex-wrap: ${props => props.wrap};
  flex: 1 1 ${props => `${props.widthPercent}%`};

  ${props => props.padding && `padding: ${props.theme.spacing(...props.padding.split(' '))};`}
  ${props =>
    props.divider &&
    ((props.divider === 'bottom' && `border-bottom-width: 1px;`) ||
      (props.divider === 'top' && `border-top-width: 1px;`) ||
      `border-width: 1px 0;`)}
`;

FormSection.defaultProps = {
  component: 'div',
  wrap: 'wrap',
  widthPercent: 100
};

FormSection.propTypes = {
  component: PropTypes.oneOfType([PropTypes.func, PropTypes.node, PropTypes.object]),
  divider: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  padding: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  widthPercent: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  wrap: PropTypes.string.isRequired
};

export const FormRow = styled(FormSection)`
  margin: 0 ${props => props.theme.spacing(-1)};
  /* container width + twice the negative margin */
  width: calc(100% + ${props => props.theme.spacing(2)});

  ${FormItem} {
    margin: ${props => props.theme.spacing(1.5, 1)};
  }
`;

export const FormContainer = styled.form`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-left: ${props => (props.ml ? props.ml + 'px' : '0px')};
`;

// @TODO find a better approach, this should not be here
export const FormTextItem = styled(FormItem)`
  &&& {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-top: ${props => props.theme.spacing(1)};
    margin-bottom: 0;
  }
`;
