import styled from 'styled-components';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';

export const SlickSlider = styled(Slider)`
  margin-bottom: ${props => props.theme.spacing(3)};
`;

export const SliderArrow = styled.div`
  align-items: center;
  background-color: ${props => props.theme.color.primary.contrast};
  border-radius: 32px;
  border: 1px solid ${props => props.theme.color.grey['400']};
  box-shadow: 0px 6px 12px 0px ${props => props.theme.color.grey['400']};
  display: flex !important;
  font-weight: ${props => props.theme.fontWeight.bold};
  height: 64px;
  justify-content: center;
  left: ${props => (props.left ? '-10px' : 'auto')};
  position: absolute;
  right: ${props => (props.right ? '-10px' : 'auto')};
  top: 50%;
  transform: translate(0, -50%);
  width: 64px;
  z-index: 1000;

  ::before {
    content: '';
  }
  svg {
    height: 32px;
    width: 32px;
  }
  :hover {
    background-color: ${props => props.theme.color.primary.contrast};
  }
`;
