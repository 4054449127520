import * as types from './types';

const initialState = {
  offers: [],
  error: null
};

function offers(state = initialState, action) {
  switch (action.type) {
    case types.LOAD_OFFERS:
      return {
        ...state,
        error: null
      };
    case types.OFFERS_LOADED:
      return {
        ...state,
        offers: action.payload.offers,
        error: null
      };
    case types.OFFERS_FAILED:
      return {
        ...state,
        error: action.payload.error
      };
    default:
      return state;
  }
}

export default offers;
