import { connect } from 'react-redux';
import * as authActions from 'redux-modules/auth/actions';
import * as resumeActions from 'redux-modules/resume/actions';
import * as authSelectors from 'redux-modules/auth/selectors';
import RegisterPage from './RegisterPage';

function mapStateToProps(state) {
  return {
    syncing: authSelectors.selectSyncState(state)
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onRegister: (data, failCallback) => {
      dispatch(resumeActions.savePersonalDetails(data, failCallback));
    },
    onGetData: callback => {
      dispatch(authActions.getRegisterData(callback));
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(RegisterPage);
