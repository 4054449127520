import { useTranslation } from 'hooks';
import PropTypes from 'prop-types';
import React from 'react';
import useStyles, { AttachmentContainer, FileUploadComponent } from './style';
import Typography from '@material-ui/core/Typography';

function Attachment({ onChange, error, inputProps, value }) {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <AttachmentContainer>
      <Typography className={classes['navlink.active']} component="p">
        {value !== '' ? value : t('label.uploadCV')}
      </Typography>
      {error ? <p style={{ color: 'red' }}>{error}</p> : null}
      <FileUploadComponent
        id="attachmentFile"
        name="attachmentFile"
        type="file"
        onChange={onChange}
        {...inputProps}
      />
    </AttachmentContainer>
  );
}

Attachment.propTypes = {
  error: PropTypes.string,
  inputProps: PropTypes.object,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
};

export default Attachment;
