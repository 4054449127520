import React from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import AppFooter from 'components/AppFooter';
import Logo from 'components/Logo';
import {
  LoginPage,
  RegistrationPage,
  ForgotPasswordPage,
  UpdatePasswordPage
} from 'components/pages';
import { Container, MainSection, CornerLogo } from './style';

function AuthContent() {
  const { pathname } = useLocation();
  return (
    <React.Fragment>
      <MainSection isRegisterPage={pathname === '/register' ? 1 : 0}>
        <CornerLogo />
        <Logo size={pathname === '/register' ? 'normal' : 'large'} old={1} />
        <Container className="registration-form-page" transparent>
          <Switch>
            <Route exact component={LoginPage} path="/login" />
            <Route path="/register/:id?" component={RegistrationPage} />
            <Route component={ForgotPasswordPage} path="/forgot-password" />
            <Route
              path="/reset-password/:token"
              render={routeProps => (
                <UpdatePasswordPage mode="update" token={routeProps.match.params.token} />
              )}
            />
            <Route
              path="/create-password/:token"
              render={routeProps => (
                <UpdatePasswordPage mode="create" token={routeProps.match.params.token} />
              )}
            />
          </Switch>
        </Container>
      </MainSection>
      <AppFooter />
    </React.Fragment>
  );
}

AuthContent.propTypes = {};

export default AuthContent;
