import React from 'react';
import { LogoLink, AppLogo } from './style';

export default function Logo() {
  return (
    <LogoLink to="/dashboard">
      <AppLogo />
    </LogoLink>
  );
}
