import { connect } from 'react-redux';
import * as resumeSelectors from 'redux-modules/resume/selectors';
import ResumePage from './ResumePage';

function mapStateToProps(state) {
  return {
    loading: resumeSelectors.selectLoadState(state)
  };
}

export default connect(mapStateToProps, null)(ResumePage);
