import styled from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';
import { createMuiTheme } from '@material-ui/core';

export const datePickerTheme = createMuiTheme({
  overrides: {
    MuiPickersBasePicker: {
      container: {
        alignItems: 'center'
      },
      pickerView: {}
    }
  }
});

export default makeStyles(theme => ({
  paper: {
    minHeight: '450px',
    maxHeight: '450px',
    width: '310px',
    '@media (max-width: 800px)': {
      width: '100%',
      marginLeft: '0px',
      marginRight: '0px',
      justifyContent: 'center'
    }
  },
  pickerContainer: {
    background: 'red',
    fontSize: '50px'
  }
}));

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: ${props => props.theme.spacing(2)};
`;
