import styled from 'styled-components';
import { default as LinkComponent } from 'components/Link';

export const Link = styled(LinkComponent)`
  align-items: center;
  color: #206AF5;
  display: inline-flex;
  margin-top: ${props => props.theme.spacing(0.5)};
  ${props =>
    props.selected &&
    `
    font-weight: ${props.theme.fontWeight.bold};
  `}
  height: 100%;
  line-height: 100%;
  justify-content: center;
`;

export const ProjectStatusContentContainer = styled.div`
  border-bottom: 2px solid #000;
  border-top: 1px solid #cccccc;
  display: flex;
  flex-direction: column;
  position: relative;
  margin: ${props => props.theme.spacing(0, 5, 5)};
`;

export const CarouselContainer = styled.div`
  border-bottom: 2px solid #000;
  display: block;
  height: 100%;
  width: 100%;
`;

export const ProjectStatusTitle = styled.div`
  font-weight: ${props => props.theme.fontWeight.bold};
  margin: ${props => props.theme.spacing(5)};
`;

export const CandidateCardContainer = styled.div`
  align-items: center;
  border: 1px solid ${props => props.theme.color.grey['400']};
  display: flex;
  flex-direction: column;
  height: 100%;
  margin: 0 auto;
  position: relative;
  width: 80%;
  padding: ${props => props.theme.spacing(2)};
`;

export const CandidateEmptyRowPlaceholder = styled.div`
  background: ${props => props.theme.color.background.default}
  border-radius: 4px;
  height:16px;
  margin-bottom : ${props => props.theme.spacing(2)};
  text-align:center;
  width: ${props => (props.longest ? '80%' : props.long ? '65%' : '33%')};
`;

export const CandidateEmptyRow = styled.div`
  height: 16px;
  margin-bottom: ${props => props.theme.spacing(2)};
  width: ${props => (props.longest ? '80%' : props.long ? '65%' : '33%')};
  text-align: center;
`;

export const ElectedCandidateContainer = styled.div`
  margin: ${props => props.theme.spacing(5)};
  background: ${props => props.theme.color.background.card};
`;

export const RunningCandidateContainer = styled.div`
  margin: ${props => props.theme.spacing(5)};
  background: ${props => props.theme.color.primary.contrast};
  background-color: #ffffff;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 1px 10px 0 rgba(0, 0, 0, 0.12),
    0 4px 5px 0 rgba(0, 0, 0, 0.14);
  max-height: 184px;
`;

export const ElectedCandidateCard = styled.div`
  padding: ${props => props.theme.spacing(5)};
  .row {
    display: flex;
    flex-direction: row;
    width: 100%;
  }
  .row.candidate-info {
    img {
      height: 102px;
      width: 102px;
    }
    .content {
      flex-grow: 1;
      padding: ${props => props.theme.spacing(2)};
      display: flex;
      flex-direction: column;
      .title {
        font-weight: ${props => props.theme.fontWeight.bold};
      }
    }
  }
  .row.executiveSummary {
    padding: ${props => props.theme.spacing(5)} 0 0 0;
  }
  .row.viewDetails {
    justify-content: flex-end;
  }
`;

export const MandateRunningContainer = styled.div`
  margin: ${props => props.theme.spacing(5)};
  display: flex;
  background: ${props => props.theme.color.background.card};
  .candidate-card {
    width: 100%;
  }
`;

export const PointsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-content: center;
  align-items: center;
  justify-items: center;
  width: 90px;
  min-width: 100px;
`;

export const Point = styled.div`
  background-color: ${props => props.color};
  border-radius: 100%;
  width: ${props => props.size}px;
  height: ${props => props.size}px;
`;

export const ProjectCardContainer = styled.div`
  margin: ${props => props.theme.spacing(5)};
  width: 40%;
`;

export const ProjectCard = styled.div`
  display: flex;
  flex-direction: row;
  display: flex;
  padding: ${props => props.theme.spacing(5)};
  background: ${props => props.theme.color.primary.contrast};
  background-color: #ffffff;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 1px 10px 0 rgba(0, 0, 0, 0.12),
    0 4px 5px 0 rgba(0, 0, 0, 0.14);
  max-height: 184px;
  img {
    height: 102px;
    width: 102px;
  }
  .emptyContainer {
    padding: 0 ${props => props.theme.spacing(2)};
    flex: 1;
  }
`;

export const MandateTerminatedContainer = styled.div`
  margin: ${props => props.theme.spacing(5)};
  display: flex;
  align-content: center;
  justify-content: center;
  background: ${props => props.theme.color.background.card};
  .candidate-card {
    min-width: 40%;
    width: 100%;
  }
`;
