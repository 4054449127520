import { connect } from 'react-redux';
import * as metaSelectors from 'redux-modules/meta/selectors';
import * as resumeSelectors from 'redux-modules/resume/selectors';
import * as uiStateSelectors from 'redux-modules/uiState/selectors';
import Resume from 'components/Resume';

function mapStateToProps(state) {
  return {
    indexedMeta: metaSelectors.selectIndexedResumeMeta(state),
    language: uiStateSelectors.selectResumeLanguage(state),
    resume: resumeSelectors.selectResume(state)
  };
}

export default connect(mapStateToProps, null)(Resume);
