import React from 'react';
import PropTypes from 'prop-types';
import { MultiLanguageRichTextField } from 'components/inputs';
import { FormContainer, FormItem, FormRow, FormSection } from 'components/styledComponents';
import { CompetenceEditor, Label, Title, SubTitle, Heading } from './style';
import Grid from '@material-ui/core/Grid';
import { useTranslation } from 'hooks';
import Container from '@material-ui/core/Container';
import { Loader, Section } from 'components/styledComponents';
import { Button, FooterActions } from 'components/pages/RegistrationPage/style.js';
import Divider from '@material-ui/core/Divider';

function Form({
  className,
  language,
  useInput,
  loading,
  handleNext,
  handlePrev,
  syncing,
  handleSubmit,
  handleClose,
  ...rest
}) {
  const { tr } = useTranslation();
  return (
    <>
      <Section>
        <Container>
          <Heading>
            <Title>
              {tr('title.competenceProfile')} &nbsp;{' '}
              <SubTitle>({tr('label.competenceInfoText')})</SubTitle>
            </Title>
            <Divider variant="middle" className="competence-divider" />
          </Heading>
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <CompetenceEditor>
                <Label>{tr('label.industryFocuses')}</Label>
                <FormItem
                  className="column-width-competence"
                  component={MultiLanguageRichTextField}
                  data-test-id="competence-profile-rich-editor"
                  data-rttextarea-id="competence-profile-rich-editor1"
                  label={tr('label.industryFocuses')}
                  language={language}
                  {...useInput('industryFocuses')}
                />
              </CompetenceEditor>
            </Grid>

            <Grid item xs={12} md={4}>
              <CompetenceEditor>
                <Label>{tr('label.specialistFocuses')}</Label>
                <FormItem
                  component={MultiLanguageRichTextField}
                  data-test-id="competence-profile-rich-editor"
                  data-rttextarea-id="competence-profile-rich-editor2"
                  label={tr('label.specialistFocuses')}
                  language={language}
                  {...useInput('specialistFocuses')}
                  className="column-width-competence"
                />
              </CompetenceEditor>
            </Grid>

            <Grid item xs={12} md={4}>
              <CompetenceEditor>
                <Label>{tr('label.managementMethods')}</Label>
                <FormItem
                  component={MultiLanguageRichTextField}
                  data-test-id="competence-profile-rich-editor"
                  data-rttextarea-id="competence-profile-rich-editor3"
                  label={tr('label.managementMethods')}
                  language={language}
                  {...useInput('managementMethods')}
                  className="column-width-competence"
                />
              </CompetenceEditor>
            </Grid>
          </Grid>
        </Container>
      </Section>
      <Section>
        <Container>
          <FooterActions>
            <Button label={tr('button.back')} variant="text" onClick={handlePrev} />
            <Button color="primary" label={tr('button.next')} onClick={handleNext} />
          </FooterActions>
        </Container>
      </Section>
    </>
  );
}

Form.propTypes = {
  className: PropTypes.string,
  language: PropTypes.string.isRequired,
  useInput: PropTypes.func.isRequired
};

export default Form;
