import styled from 'styled-components';
import { Button as BaseButton } from 'components/buttons';

export const Button = styled(BaseButton)`
  ${props => props.align === 'left' && 'margin-left: auto;'}
`;

export const FooterMessage = styled.div`
  width: 300px;
`;

export const Section = styled.div`
  display: flex;
  flex-grow: 1;
  flex-wrap: ${props => props.wrap || 'wrap'};
  ${props => props.justify && `justify-content: ${props.justify};`}

  @media (max-width: 768px) {
    justify-content: right;
  }
`;

export const FooterContent = styled.div`
  align-items: center;
  display: flex;
  flex-wrap: ${props => props.wrap || 'wrap'};
  margin: ${props => props.theme.spacing(-1.5)};
  width: calc(100% + ${props => props.theme.spacing(3)});

  ${Button}, ${FooterMessage} {
    margin: ${props => props.theme.spacing(1.5)};
  }
  ${Button} {
    @media (max-width: 400px) {
      flex: 1 1 100%;
    }
  }
  ${FooterMessage} {
    flex-grow: 1;
  }
`;

export const ActionsFooter = styled.div`
  border-top: 1px solid ${props => props.theme.color.grey[500]};
  box-sizing: border-box;
  margin: 0 ${props => props.theme.spacing(2.5)};
  min-width: 250px;
  padding: ${props => props.theme.spacing(2.5)} 0;

  ${props =>
    props.danger &&
    `
    background-color: ${props.theme.color.danger.main};
    color: ${props.theme.color.danger.contrast};
    margin: 0;
    padding: ${props.theme.spacing(2.5)};

    ${Button} {
      min-width: 100px;
    }
  `};
`;
