import React from 'react';
import PropTypes from 'prop-types';
import { Redirect, Route, Switch } from 'react-router-dom';
import { Section } from 'components/styledComponents';
import { useTranslation } from 'hooks';
import Opportunities from './Opportunities';
import Placements from './Placements';
import { Container, Link, LinkContainer } from './style';

function ManagementPage({ match: { url } }) {
  const { t } = useTranslation();

  return (
    <Section fullSize>
      <Container>
        <LinkContainer>
          <Link to={`${url}/opportunities`}>{t('title.opportunities')}</Link>
          <Link to={`${url}/placements`}>{t('title.placements')}</Link>
        </LinkContainer>
        <Switch>
          <Route component={Opportunities} path={`${url}/opportunities`} />
          <Route component={Placements} path={`${url}/placements`} />
          <Redirect to={`${url}/opportunities`} />
        </Switch>
      </Container>
    </Section>
  );
}

ManagementPage.propTypes = {
  match: PropTypes.object.isRequired
};

export default ManagementPage;
