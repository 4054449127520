import React from 'react';
import { Loader } from 'components/styledComponents';
import { Paper } from './style';

function DialogPaper(props) {
  return <Loader component={Paper} {...props} />;
}

DialogPaper.propTypes = {};

export default DialogPaper;
