import { connect } from 'react-redux';
import * as metaSelectors from 'redux-modules/meta/selectors';
import * as uiStateSelectors from 'redux-modules/uiState/selectors';
import * as uiStateActions from 'redux-modules/uiState/actions';
import Language from './Language';

function mapStateToProps(state) {
  return {
    meta: metaSelectors.selectMeta(state),
    supportedLanguages: uiStateSelectors.selectAppLanguageCodes(state)
  };
}
function mapDispatchToProps(dispatch) {
  const languageMap = {
    '100': 'de',
    '200': 'en'
  };
  return {
    onLangChange: (data, failCallback) => {
      dispatch(uiStateActions.changeLanguage(data, failCallback));
      dispatch(uiStateActions.changeResumeLanguage(languageMap[data], failCallback));
    }

  };
}
// export default connect(mapStateToProps, mapDispatchToProps)(LanguageSelector);
export default connect(mapStateToProps, mapDispatchToProps)(Language);
