import React from 'react';
import PropTypes from 'prop-types';
import Title from 'components/Title';
import { FormContainer, FormItem, FormRow, FormSection } from 'components/styledComponents';
import { useTranslation } from 'hooks';
import { TextField } from 'components/inputs';
import { ContactSection, Subtext, HelpText } from './style';

function Contact({ className, useInput, isNewUserEmail }) {
  const { t } = useTranslation();
  const { error } = useInput('primaryEmail');

  return (
    <FormContainer className={className}>
      <FormSection padding="0 0 3">
        <FormRow>
          <FormItem>
            <Title component="h4" label={t('title.contactDetails')} />
          </FormItem>
        </FormRow>
        <FormRow>
          <ContactSection>
            <FormRow>
              <FormItem
                component={TextField}
                label={t('label.mobilePhone1')}
                required
                {...useInput('mobilePhone1')}
                helperText={<HelpText>Format: +49 89 123456789</HelpText>}
              />
            </FormRow>
          </ContactSection>
          <ContactSection>
            <FormRow>
              <FormItem
                component={TextField}
                label={t('label.primaryEmail')}
                required
                {...useInput('primaryEmail')}
                error={
                  isNewUserEmail === 'duplicate' ? (
                    <Subtext dangerouslySetInnerHTML={{ __html: t('label.emailAlreadyExists') }} />
                  ) : (
                    error
                  )
                }
              />
            </FormRow>
          </ContactSection>
        </FormRow>
      </FormSection>
    </FormContainer>
  );
}

Contact.propTypes = {
  className: PropTypes.string,
  useInput: PropTypes.func.isRequired,
  isNewUserEmail: PropTypes.string
};

export default Contact;
