import { connect } from 'react-redux';
import * as resumeActions from 'redux-modules/resume/actions';
import * as resumeSelectors from 'redux-modules/resume/selectors';
import Editor from './Editor';

function mapStateToProps(state) {
  return {
    education: resumeSelectors.selectEducation(state),
    syncErrors: resumeSelectors.selectSyncError(state),
    syncing: resumeSelectors.selectSyncState(state)
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onDelete: (data, failCallback) => dispatch(resumeActions.deleteEducation(data, failCallback)),
    onSave: (data, failCallback) => dispatch(resumeActions.saveEducation(data, failCallback))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Editor);
