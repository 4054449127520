import styled from 'styled-components';

export const Text = styled.p`
  color: ${props => props.theme.color.primary.main};
  a {
    color: ${props => props.theme.color.primary.main};
  }
`;

export const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: end;
`;
