import styled from 'styled-components';
import RadioGroup from '@material-ui/core/RadioGroup';
import { TextField } from 'components/inputs';

export const RadioGroupStyled = styled(RadioGroup)`
  width: 100%;
`;

export const MultilineTextField = styled(TextField)`
  margin-top: 5px;
`;

export const Content = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: ${props => props.theme.spacing(2)};
  max-width: calc(100% - ${props => props.theme.spacing(4)});
  width: ${props => props.theme.spacing(50)};
`;

export const Description = styled.p`
  margin: ${props => props.theme.spacing(2, 0, 0)};
  width: 100%;
  padding-top: 5px;
`;

//RTEXT-618
export const EditorContainer = styled.div`
  border: 1px solid #ddd;
  width: 100%;
  margin-top: 10px;
  border-radius: ${props => props.theme.borderRadius.base};
`;

export const Title = styled.h3`
  color: ${props => props.theme.color.primary.main};
  font-size: ${props => props.theme.fontSize.large};
  margin: ${props => props.theme.spacing(0, 0, 2, 0)};
  text-transform: uppercase;
`;

export const DateLabel = styled.div`
  color: ${props => props.theme.color.primary.main};
  font-size: ${props => props.theme.fontSize.normal};
  margin: ${props => props.theme.spacing(0, 0, 1, 0)};
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin: ${props => props.theme.spacing(1)};
  width: 100%;
`;

export const TimeTrackingOptionsContainer = styled.div`
  align-items: left;
  display: flex;
  flex-direction: row;
  justify-content: left;
  padding-top: ${props => (props.shrinkSpace ? '0px' : '10px')};
  margin: ${props => (props.shrinkSpace ? '-5px' : props.theme.spacing(1))};
  width: 100%;
`;
export const TimeTrackingOption = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1 0 50%;
  justify-content: left;
  position: relative;
`;

export const Border = styled.div`
  width: 100%;
  margin-top: ${props => (props.mt ? props.mt + 'px' : '0px')};
  border-bottom: 1px solid ${props => props.theme.color.grey[500]};
`;
