import React from 'react';
import PropTypes from 'prop-types';

function TextFieldInput({ inputRef, ...props }) {
  return <div ref={inputRef} {...props} />;
}

TextFieldInput.propTypes = {
  inputRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({
      current: PropTypes.any.isRequired
    })
  ])
};

export default React.memo(TextFieldInput);
