import styled from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';
import Link from '../../Link';

export default makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(6),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  },
  cancel: {
    margin: theme.spacing(3, 3, 2)
  },
  heading: {
    width: '100%',
    color: '#003049',
    textAlign: 'center'
  },
  'navlink.active': {
    color: '#206AF5',
    cursor: 'pointer'
  },
  'navlink.inactive': {
    color: '#C8C8C8',
    cursor: 'pointer'
  },
  inactive: {
    color: '#CCCCCC'
  },
  'text.container': {
    height: '50vh',
    overflowY: 'scroll'
  },
  text: {
    color: '#003049'
  },
  pointer: {
    cursor: 'pointer'
  }
}));

export const FormFooter = styled.div`
  display: flex;
  align-items: flex-end;
  flex-direction: row;
  margin-top: 5px;
  justify-content: flex-end;
`;

export const TermsHeader = styled.div`
  width: 100%;
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  flex-flow: row;
  justify-content: left;
  margin-bottom: 25px;
`;

export const TermsHeaderText = styled.div`
  width: 100%;
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  flex-flow: column;
  justify-content: center;
`;

export const NDAText = styled.div`
  font-weight: ${props => props.theme.fontWeight.light};
  white-space: pre-wrap;
`;
export const Title = styled.h3`
  font-size: ${props => props.theme.fontSize.large};
  font-weight: ${props => props.theme.fontWeight.light};
  margin: 0;
`;

export const DownloadLink = styled(Link)`
  font-size: ${props => props.theme.fontSize.xsmall};
`;
export const DownloadText = styled.span`
  font-weight: ${props => props.theme.fontWeight.bold};
  margin-left: ${props => props.theme.spacing(1)};
  text-transform: uppercase;
`;

export const ActionItem = styled.div`
  align-items: center;
  display: flex;

  .MuiFormControlLabel-root {
    margin-left: 0;
    margin-right: 0;
  }
`;
export const NDAFooter = styled.div`
  align-items: center;
  display: flex;
  flex-grow: 1;
  flex-wrap: ${props => props.wrap || 'wrap'};
  /* use negative margin bottom to compensate material UI checkbox padding */
  margin-bottom: ${props => props.theme.spacing(-1.25)};
  ${props => props.justify && `justify-content: ${props.justify};`}

  @media (max-width: 650px) {
    justify-content: center;
  }
`;

export const NDAViewer = styled.div`
  margin: ${props => props.theme.spacing(2)} 0;
  min-height: 370px;
`;

export const NDAContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100%;
  width: 100%;

  ${NDAFooter} {
    flex-grow: 0;
  }
  ${NDAViewer} {
    flex-grow: 1;
  }
`;
