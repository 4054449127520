import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import { FormContainer, FormSection, FormRow } from 'components/styledComponents';
import { useTranslation } from 'hooks';
import Title from 'components/Title';
import { Button } from 'components/buttons';
import Document from './Document';

function Documents({ documents, setDocuments, useInput }) {
  const { t } = useTranslation();
  const { error } = useInput('documents');
  const { value: language } = useInput('language');

  const handleSubcategoryChange = useCallback(
    (index, subcategory) => {
      setDocuments(prevDocuments => {
        const updatedDocuments = [...prevDocuments];
        updatedDocuments[index].subcategory = subcategory;
        return updatedDocuments;
      });
    },
    [setDocuments]
  );

  const handleFileUpload = useCallback(
    (index, file) => {
      setDocuments(prevDocuments => {
        const updatedDocuments = [...prevDocuments];
        updatedDocuments[index].file = file;
        return updatedDocuments;
      });
    },
    [setDocuments]
  );

  const addDocument = useCallback(() => {
    setDocuments(prevDocuments => [...prevDocuments, { subcategory: '1000', file: null }]);
  }, [setDocuments]);

  const handleDeleteDocument = useCallback(
    index => {
      setDocuments(prevDocuments => {
        const updatedDocuments = [...prevDocuments];
        updatedDocuments.splice(index, 1);
        return updatedDocuments;
      });
    },
    [setDocuments]
  );

  return (
    <FormContainer>
      <FormSection divider="bottom" padding="4 0">
        <Title label={t('title.documentUpload')} size="large" />
      </FormSection>
      <FormSection divider="bottom" padding="3 0">
        {documents.map(({ subcategory, file }, index) => (
          <Document
            key={index}
            error={error}
            fileName={file ? file.name : ''}
            index={index}
            language={language}
            subcategoryId={subcategory}
            handleFileUpload={handleFileUpload}
            handleSubcategoryChange={handleSubcategoryChange}
            handleDeleteDocument={handleDeleteDocument}
          />
        ))}
        <FormRow>
          <Button color="primary" variant="text" onClick={addDocument}>
            {`+ ${t('button.addDocument')}`}
          </Button>
        </FormRow>
      </FormSection>
    </FormContainer>
  );
}

Documents.propTypes = {
  documents: PropTypes.array.isRequired,
  setDocuments: PropTypes.func.isRequired,
  useInput: PropTypes.func.isRequired
};

export default Documents;
