import * as types from './types';

export function failResumeLoad(error) {
  return {
    payload: {
      error
    },
    type: types.RESUME_FAILED
  };
}
export function loadResume() {
  return {
    type: types.LOAD_RESUME
  };
}
export function setLoadedResume(resume) {
  const {
    availability,
    completionPercent,
    education,
    executiveSummary,
    competenceProfile,
    id,
    itSkills,
    languageProficiencies,
    lastChangeCV: lastModified,
    otherActivities,
    workExperience,
    notificationSettings,
    ...personalDetails
  } = resume;

  return {
    payload: {
      resume: {
        availability,
        completionPercent,
        education,
        executiveSummary,
        competenceProfile,
        itSkills,
        languageProficiencies,
        lastModified,
        otherActivities,
        personalDetails,
        workExperience,
        notificationSettings
      }
    },
    type: types.RESUME_LOADED
  };
}

export function failAvailabilitySave(error) {
  return {
    payload: {
      error
    },
    type: types.AVAILABILITY_FAILED
  };
}
export function saveAvailability(availability, failCallback) {
  return {
    payload: {
      availability,
      failCallback
    },
    type: types.SAVE_AVAILABILITY
  };
}
export function updateAvailability(availability) {
  return {
    payload: {
      availability
    },
    type: types.UPDATE_AVAILABILITY
  };
}

export function addEducation(education) {
  return {
    payload: {
      education
    },
    type: types.ADD_EDUCATION
  };
}
export function deleteEducation(education) {
  return {
    payload: {
      education
    },
    type: types.DELETE_EDUCATION
  };
}
export function removeEducation(education) {
  return {
    payload: {
      education
    },
    type: types.REMOVE_EDUCATION
  };
}
export function failEducationSave(error) {
  return {
    payload: {
      error
    },
    type: types.EDUCATION_FAILED
  };
}
export function saveEducation(education, failCallback) {
  return {
    payload: {
      education,
      failCallback
    },
    type: types.SAVE_EDUCATION
  };
}
export function updateEducation(education) {
  return {
    payload: {
      education
    },
    type: types.UPDATE_EDUCATION
  };
}

export function failExecutiveSummarySave(error) {
  return {
    payload: {
      error
    },
    type: types.EXECUTIVE_SUMMARY_FAILED
  };
}
export function saveExecutiveSummary(executiveSummary, failCallback) {
  return {
    payload: {
      executiveSummary,
      failCallback
    },
    type: types.SAVE_EXECUTIVE_SUMMARY
  };
}
export function markResumeComplete(personalDetails, failCallback) {
  return {
    payload: {
      personalDetails,
      failCallback
    },
    type: types.SAVE_RESUME_COMPLETE_ACK
  };
}
export function failMarkResumeComplete(error) {
  return {
    payload: {
        error
    },
    type: types.FAILED_RESUME_COMPLETE_ACK
  };
}
export function doneMarkResumeCompleteAck(personalDetails) {
  return {
    payload: {
      personalDetails
    },
    type: types.DONE_RESUME_COMPLETE_ACK
  };
}
export function updateExecutiveSummary(executiveSummary) {
  return {
    payload: {
      executiveSummary
    },
    type: types.UPDATE_EXECUTIVE_SUMMARY
  };
}

export function failCompetenceProfileSave(error) {
  return {
    payload: {
      error
    },
    type: types.COMPETENCE_PROFILE_FAILED
  };
}
export function saveCompetenceProfile(competenceProfile, failCallback) {
  return {
    payload: {
      competenceProfile,
      failCallback
    },
    type: types.SAVE_COMPETENCE_PROFILE
  };
}
export function updateCompetenceProfile(competenceProfile) {
  return {
    payload: {
      competenceProfile
    },
    type: types.UPDATE_COMPETENCE_PROFILE
  };
}

export function failItSkillsSave(error) {
  return {
    payload: {
      error
    },
    type: types.IT_SKILLS_FAILED
  };
}
export function saveItSkills(itSkills, failCallback) {
  return {
    payload: {
      itSkills,
      failCallback
    },
    type: types.SAVE_IT_SKILLS
  };
}
export function updateItSkills(itSkills) {
  return {
    payload: {
      itSkills
    },
    type: types.UPDATE_IT_SKILLS
  };
}

export function failLanguagesSave(error) {
  return {
    payload: {
      error
    },
    type: types.LANGUAGES_FAILED
  };
}
export function saveLanguages(languages, failCallback) {
  return {
    payload: {
      languages,
      failCallback
    },
    type: types.SAVE_LANGUAGES
  };
}
export function updateLanguages(languages) {
  return {
    payload: {
      languages
    },
    type: types.UPDATE_LANGUAGES
  };
}

export function addMissionAbroad(missionAbroad) {
  return {
    payload: {
      missionAbroad
    },
    type: types.ADD_MISSION_ABROAD
  };
}
export function deleteMissionAbroad(missionAbroad) {
  return {
    payload: {
      missionAbroad
    },
    type: types.DELETE_MISSION_ABROAD
  };
}
export function removeMissionAbroad(missionAbroad) {
  return {
    payload: {
      missionAbroad
    },
    type: types.REMOVE_MISSION_ABROAD
  };
}
export function failMissionAbroadSave(error) {
  return {
    payload: {
      error
    },
    type: types.MISSION_ABROAD_FAILED
  };
}
export function saveMissionAbroad(missionAbroad, failCallback) {
  return {
    payload: {
      missionAbroad,
      failCallback
    },
    type: types.SAVE_MISSION_ABROAD
  };
}
export function updateMissionAbroad(missionAbroad) {
  return {
    payload: {
      missionAbroad
    },
    type: types.UPDATE_MISSION_ABROAD
  };
}

export function failOtherActivitiesSave(error) {
  return {
    payload: {
      error
    },
    type: types.OTHER_ACTIVITIES_FAILED
  };
}
export function saveOtherActivities(otherActivities, failCallback) {
  return {
    payload: {
      otherActivities,
      failCallback
    },
    type: types.SAVE_OTHER_ACTIVITIES
  };
}
export function updateOtherActivities(otherActivities) {
  return {
    payload: {
      otherActivities
    },
    type: types.UPDATE_OTHER_ACTIVITIES
  };
}

export function failPersonalDetailsSave(error) {
  return {
    payload: {
      error
    },
    type: types.PERSONAL_DETAILS_FAILED
  };
}
export function savePersonalDetails(personalDetails, failCallback) {
  return {
    payload: {
      personalDetails,
      failCallback
    },
    type: types.SAVE_PERSONAL_DETAILS
  };
}
export function updatePersonalDetails(personalDetails) {
  return {
    payload: {
      personalDetails
    },
    type: types.UPDATE_PERSONAL_DETAILS
  };
}

export function addWorkExperience(workExperience, failCallback) {
  return {
    payload: {
      workExperience,
      failCallback
    },
    type: types.ADD_WORK_EXPERIENCE
  };
}
export function deleteWorkExperience(workExperience) {
  return {
    payload: {
      workExperience
    },
    type: types.DELETE_WORK_EXPERIENCE
  };
}
export function failWorkExperienceSave(error) {
  return {
    payload: {
      error
    },
    type: types.WORK_EXPERIENCE_FAILED
  };
}
export function removeWorkExperience(workExperience) {
  return {
    payload: {
      workExperience
    },
    type: types.REMOVE_WORK_EXPERIENCE
  };
}
export function saveWorkExperience(workExperience, failCallback) {
  return {
    payload: {
      workExperience,
      failCallback
    },
    type: types.SAVE_WORK_EXPERIENCE
  };
}
export function saveCompanyGroup(workExperience, failCallback) {
  return {
    payload: {
      workExperience,
      failCallback
    },
    type: types.SAVE_COMPANY_GROUP
  };
}
export function deleteCompanyGroup(groupId, failCallback) {
  return {
    payload: {
      groupId,
      failCallback
    },
    type: types.DELETE_COMPANY_GROUP
  };
}
export function updateWorkExperience(workExperience) {
  return {
    payload: {
      workExperience
    },
    type: types.UPDATE_WORK_EXPERIENCE
  };
}


export function saveNotificationSettings(notificationSettings, failCallback) {
  return {
    payload: {
      notificationSettings,
      failCallback
    },
    type: types.SAVE_NOTIFICATION_SETTINGS
  };
}
export function updateNotificationSettings(notificationSettings) {
  return {
    payload: {
      notificationSettings
    },
    type: types.UPDATE_NOTIFICATION_SETTINGS
  };
}
export function failNotificationSettingsSave(error) {
  return {
    payload: {
      error
    },
    type: types.NOTIFICATION_SETTINGS_FAILED
  };
}
export function deleteProfile(deletionReason, failCallback) {
  return {
    payload: {
      deletionReason,
      failCallback
    },
    type: types.DELETE_PROFILE
  };
}

export function doneProfileDelete(personalDetails) {
  return {
    type: types.DONE_RESUME_COMPLETE_ACK
  };
}