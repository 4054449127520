import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Trash } from 'components/icons';
import {isFunction} from 'lodash';
import { useTranslation } from 'hooks';
import { ActionsFooter, Button, FooterContent, FooterMessage, Section } from './style';

function Footer({ actionLabel, disabled, onAction, onDelete, totalWexp }) {
  const { t } = useTranslation();
  const [confirmDelete, setConfirmDelete] = useState(false);

  function handleDelete() {
    setConfirmDelete(true);
  }
  function handleDeleteConfirmation() {
    setConfirmDelete(false);
    onDelete();
  }
  function handleDeleteAbort() {
    setConfirmDelete(false);
  }

  if (confirmDelete) {
    let confirmationMsg = isFunction(totalWexp) && totalWexp() === 1 ? <FooterMessage>{t('message.confirmDeleteWithOneWexp')}</FooterMessage> : <FooterMessage>{t('message.confirmDelete')}</FooterMessage>
    return (
      <ActionsFooter danger key="delete-footer">
        <FooterContent>
          <Section>
            {confirmationMsg}
          </Section>
          <Section justify="flex-end" wrap="wrap-reverse">
            <Button
              color="danger"
              label={t('button.no')}
              variant="lightOutlined"
              onClick={handleDeleteAbort}
            />
            <Button
              color="danger"
              label={t('button.yes')}
              variant="lightContained"
              onClick={handleDeleteConfirmation}
            />
          </Section>
        </FooterContent>
      </ActionsFooter>
    );
  } else {
    return (
      <ActionsFooter key="actions-footer">
        <FooterContent wrap="wrap-reverse">
          {onDelete && (
            <Section>
              <Button
                align="left"
                color="danger"
                contentFirst={true}
                disabled={disabled}
                label={t('button.delete')}
                variant="text"
                onClick={handleDelete}
              >
                <Trash />
              </Button>
            </Section>
          )}
          <Section justify="flex-end">
            <Button color="primary" disabled={disabled} label={actionLabel} onClick={onAction} />
          </Section>
        </FooterContent>
      </ActionsFooter>
    );
  }
}

Footer.propTypes = {
  actionLabel: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  onAction: PropTypes.func.isRequired,
  onDelete: PropTypes.func
};

export default Footer;
