import styled from 'styled-components';

export const Container = styled.div`
  overflow: hidden;
`;
export const Content = styled.div`
  display: flex;
  margin: ${props => props.theme.spacing(0, 0, 2, -1)};

  @media (max-width: 500px) {
    flex-direction: column;
  }
`;

export const Subtitle = styled.div`
  margin: ${props => props.theme.spacing(0, 0, 2, 0)};
  padding: ${props => props.theme.spacing(0, 0, 1.5, 0)};
  ${props => props.underlined && 'border-bottom: 1px solid ' + props.theme.color.grey[500] + ';'}
  ${props => props.pointer && 'cursor: pointer;'}
  display:flex;
  justify-content: space-between;
  margin-right: ${props => props.theme.spacing(5)};
  flex-wrap: wrap;
  flex-direction: row-reverse;
  h3 {
    font-size: ${props => props.theme.fontSize.large};
    font-weight: ${props => props.theme.fontWeight.normal};
    text-align: left;
    flex: 1 1 auto;
    white-space: nowrap;
    margin: ${props => props.theme.spacing(0.6, 2, 0, 0)};
  }
  @media (max-width: 450px) {
    margin-right: ${props => props.theme.spacing(2)};
  }
`;
