import * as types from './types';

const initialState = {
  syncing: false,
  error: null,
  isVerified: false
};

function billing(state = initialState, action) {
  switch (action.type) {
    case types.VERIFY_BILLING:
      return { ...state, isVerified: false, syncing: true };
    case types.VERIFY_SUCCESS:
      return {
        ...state,
        status: action.payload.status,
        html: action.payload.html,
        isVerified: true,
        syncing: false
      };
    case types.SAVE_BILLING:
      return { ...state, syncing: true };
    case types.SAVE_SUCCESS:
      return { ...state, syncing: false };
    case types.RESET_STATE:
      return { ...state, syncing: false, error: null, isVerified: false };
    default:
      return state;
  }
}

export default billing;
