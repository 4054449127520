import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import DateFnsUtils from '@date-io/date-fns';
import Button from '@material-ui/core/Button';
import { Dialog, DialogTitle } from '@material-ui/core';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import { ThemeProvider } from '@material-ui/styles';
import { format } from 'date-fns';
import { defaultMuiProps } from '../config';
import { useLocale } from 'hooks';
import { TextField } from './../';
import useStyles, { ButtonsContainer, datePickerTheme } from './style';

function CustomDateField({
  cancelLabel,
  className,
  disableFuture,
  doneLabel,
  label,
  selectDateLabel,
  selectMonthLabel,
  value,
  onChange
}) {
  const getDate = useCallback(value => {
    let year = null;
    let month = null;
    let day = null;
    if (value) {
      let dateParts = value.split('/');
      if (dateParts && dateParts.length === 1) {
        if (!isNaN(dateParts[0])) {
          year = parseInt(dateParts[0]);
        }
      }
      if (dateParts && dateParts.length === 2) {
        if (!isNaN(dateParts[0]) && !isNaN(dateParts[1])) {
          month = parseInt(dateParts[0]);
          year = parseInt(dateParts[1]);
        }
      }
      if (dateParts && dateParts.length === 3) {
        if (!isNaN(dateParts[0]) && !isNaN(dateParts[1]) && !isNaN(dateParts[2])) {
          day = parseInt(dateParts[0]);
          month = parseInt(dateParts[1]);
          year = parseInt(dateParts[2]);
        }
      }
    }
    var date = new Date(
      year != null ? year : new Date().getFullYear(),
      month != null ? month - 1 : new Date().getMonth(),
      day != null ? day : new Date().getDate()
    );
    return date;
  }, []);
  const locale = useLocale();
  const [currentDate, setCurrentDate] = useState(getDate(value));

  useEffect(() => {
    setCurrentDate(getDate(value));
  }, [value, getDate]);

  const classes = useStyles();

  const [isOpen, setOpen] = useState(false);
  const [currentView, setCurrentView] = useState('year');
  const handleChange = useCallback(() => {
    var date = currentDate;
    let dateFormat;
    switch (currentView) {
      case 'year':
        dateFormat = 'yyyy';
        break;
      case 'month':
        dateFormat = 'MM/yyyy';
        break;
      case 'date':
        dateFormat = 'dd/MM/yyyy';
        break;
      default:
        dateFormat = null;
        break;
    }
    if (dateFormat) {
      onChange(format(date, dateFormat));
    }
    setOpen(false);
  }, [onChange, currentView, currentDate]);
  const handlePickerChange = useCallback(value => {
    setCurrentDate(value);
  }, []);

  return (
    <React.Fragment>
      <TextField
        className={className}
        label={label}
        readOnly={true}
        style={{ margin: '12px 8px' }}
        value={value}
        onClick={() => {
          setOpen(!isOpen);
          setCurrentView('year');
        }}
      />
      <Dialog classes={{ paper: classes.paper }} open={isOpen}>
        <DialogTitle>{label}</DialogTitle>
        {currentView && currentView === 'year' && (
          <MuiPickersUtilsProvider locale={locale} utils={DateFnsUtils}>
            <ThemeProvider theme={datePickerTheme}>
              <DatePicker
                autoOk={false}
                disableFuture={disableFuture}
                open={true}
                openTo="year"
                ToolbarComponent={props => <div />}
                value={currentDate}
                variant="static"
                views={['year']}
                onChange={handlePickerChange}
              />
            </ThemeProvider>
          </MuiPickersUtilsProvider>
        )}
        {currentView && currentView === 'month' && (
          <MuiPickersUtilsProvider locale={locale} utils={DateFnsUtils}>
            <ThemeProvider theme={datePickerTheme}>
              <DatePicker
                autoOk={false}
                disableFuture={disableFuture}
                open={true}
                openTo="month"
                ToolbarComponent={props => <div />}
                value={currentDate}
                variant="static"
                views={['year', 'month']}
                onChange={handlePickerChange}
              />
            </ThemeProvider>
          </MuiPickersUtilsProvider>
        )}
        {currentView && currentView === 'date' && (
          <MuiPickersUtilsProvider locale={locale} utils={DateFnsUtils}>
            <ThemeProvider theme={datePickerTheme}>
              <DatePicker
                autoOk={false}
                disableFuture={disableFuture}
                open={true}
                openTo="date"
                ToolbarComponent={props => <div />}
                value={currentDate}
                variant="static"
                views={['date']}
                onChange={handlePickerChange}
              />
            </ThemeProvider>
          </MuiPickersUtilsProvider>
        )}

        <ButtonsContainer>
          <Button
            className="MuiButtonBase-root MuiButton-root MuiButton-text MuiButton-textPrimary"
            onClick={() => setOpen(false)}
          >
            {cancelLabel}
          </Button>
          {currentView && currentView === 'year' && (
            <Button color="primary" onClick={() => setCurrentView('month')}>
              {selectMonthLabel}
            </Button>
          )}
          {currentView && currentView === 'month' && (
            <Button color="primary" onClick={() => setCurrentView('date')}>
              {selectDateLabel}
            </Button>
          )}
          <Button color="secondary" onClick={() => handleChange()}>
            {doneLabel}
          </Button>
        </ButtonsContainer>
      </Dialog>
    </React.Fragment>
  );
}

CustomDateField.defaultProps = {
  ...defaultMuiProps,
  autoOk: false,
  disableFuture: false,
  format: 'dd-MM-yyyy',
  InputLabelProps: {
    shrink: true
  },
  inputVariant: 'outlined',
  variant: 'dialog'
};

CustomDateField.propTypes = {
  cancelLabel: PropTypes.string,
  className: PropTypes.string,
  disableFuture: PropTypes.bool.isRequired,
  doneLabel: PropTypes.string,
  label: PropTypes.string,
  selectDateLabel: PropTypes.string.isRequired,
  selectMonthLabel: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
};

export default React.memo(CustomDateField);
