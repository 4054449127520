import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import * as uiStateSelectors from 'redux-modules/uiState/selectors';

function useLanguagesPaths(basePath) {
  const appLanguages = useSelector(uiStateSelectors.selectAppLanguages);
  return useMemo(() => appLanguages.map(language => `${basePath}.${language}`), [
    appLanguages,
    basePath
  ]);
}

export default useLanguagesPaths;
