import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import { Plus } from 'components/icons';
import Dialog from 'components/Dialog';
import Form from './Form';
import { useForm, useTranslation } from 'hooks';
import { AddButton } from './style';

function serializeData(data) {
  return data.filter(item => item && item.languageCode && item.languageLevelCode);
}

function Editor({ languages, syncErrors, syncing, onClose, onSave }) {
  const { t, tr } = useTranslation();
  const didMountRef = useRef(false);
  const handleSave = useCallback(
    data => {
      onSave(serializeData(data));
    },
    [onSave]
  );
  const initialData = useMemo(() => (languages.length ? languages : [null]), [languages]);
  const { handleAdd, handleRemove, handleReset, handleSubmit, useInput, values } = useForm({
    initialValues: initialData,
    validationSchema: 'languagesSchema',
    onSubmit: handleSave
  });
  const selectionMap = useMemo(
    () =>
      values.reduce((selectionObject, value) => {
        value && (selectionObject[value.languageCode] = true);
        return selectionObject;
      }, {}),
    [values]
  );
  const handleClose = useCallback(() => {
    handleReset();
    onClose();
  }, [handleReset, onClose]);
  const filterOption = useCallback(({ value }) => !selectionMap[value], [selectionMap]);

  useEffect(() => {
    if (didMountRef.current) {
      if (!syncErrors && !syncing) {
        handleClose();
      }
    } else {
      didMountRef.current = true;
    }
  }, [syncErrors, syncing, handleClose]);

  return (
    <Dialog
      actionLabel={t('button.save')}
      disabled={syncing}
      open={true}
      syncing={syncing}
      title={tr('title.languages')}
      onAction={handleSubmit}
      onClose={handleClose}
    >
      {values.map((value, index) => (
        <Form
          basePath={`[${index}]`}
          filterOption={filterOption}
          key={index}
          useInput={useInput}
          onDelete={handleRemove}
        />
      ))}
      <AddButton
        color="default"
        label={t('button.addAnotherLanguage')}
        variant="outlined"
        onClick={() => handleAdd('', null)}
      >
        <Plus fontSize="small" />
      </AddButton>
    </Dialog>
  );
}

Editor.propTypes = {
  className: PropTypes.string,
  languages: PropTypes.array.isRequired,
  syncErrors: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  syncing: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired
};

export default Editor;
