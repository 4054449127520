import localStorage from 'local-storage-fallback';
import * as types from './types';

const DEFAULT_LANGUAGE = '200';
const SUPPORTED_LANGUAGES = ['100', '200'];

const { language: localLanguage, ...localState } =
  JSON.parse(localStorage.getItem('uiState')) || {};
const initialState = Object.assign(
  {
    language: SUPPORTED_LANGUAGES.includes(localLanguage) ? localLanguage : '100',
    languages: SUPPORTED_LANGUAGES,
    resumeLanguage: 'de'
  },
  localState
);

function uiState(state = initialState, action) {
  let nextState;

  switch (action.type) {
    case types.CHANGE_LANGUAGE:
      nextState = {
        ...state,
        language: SUPPORTED_LANGUAGES.includes(action.payload.language)
          ? action.payload.language
          : DEFAULT_LANGUAGE
      };
      break;
    case types.CHANGE_RESUME_LANGUAGE:
      nextState = {
        ...state,
        resumeLanguage: action.payload.language
      };
      break;
    default:
      return state;
  }

  localStorage.setItem(
    'uiState',
    JSON.stringify({
      language: nextState.language,
      resumeLanguage: nextState.resumeLanguage
    })
  );

  return nextState;
}

export default uiState;
