import React from 'react';
import PropTypes from 'prop-types';
import { IconButton } from 'components/buttons';
import Link from 'components/Link';
import { useDateFormat } from 'hooks';
import { Container, Text, TextWrapper } from './style';

function Details({
  className,
  editPath,
  education,
  indexedMeta: { countries, educationLevels },
  language
}) {
  const { country, dateFrom, dateTo, degree, fieldOfStudy = {}, id, institution = {} } = education;

  return (
    <Container className={className}>
      <TextWrapper>
        <Text>
          <em>{fieldOfStudy[language] || '-'}</em>, <span>{institution[language] || '-'} </span>
        </Text>
        <Text small>
          <em>
            {useDateFormat(dateFrom, { dateFormat: 'MMM yyyy', language: language }) || '-'} -{' '}
            {useDateFormat(dateTo, { dateFormat: 'MMM yyyy', language: language }) || '-'}
          </em>
          {` | ${educationLevels[degree] || '-'} | ${countries[country] || '-'}`}
        </Text>
      </TextWrapper>
      {editPath && <IconButton component={Link} icon="Edit" to={`${editPath}/${id}`} />}
    </Container>
  );
}

Details.propTypes = {
  className: PropTypes.string,
  editPath: PropTypes.string,
  education: PropTypes.object.isRequired,
  indexedMeta: PropTypes.shape({
    countries: PropTypes.object.isRequired,
    educationLevels: PropTypes.object.isRequired
  }).isRequired,
  language: PropTypes.string.isRequired
};

export default Details;
