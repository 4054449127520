import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { format } from 'date-fns';
import { useMuiError } from 'hooks';
import { defaultMuiProps } from '../config';
import { useLocale } from 'hooks';

function DateField({ error, minDate, value, wizardMode, onChange, ...rest }) {
  // @TODO review change behavior and handling
  const handleChange = useCallback(
    (date, value) => {
      if (date) {
        try {
          var dateString = format(date, 'yyyy-MM-dd');
          onChange(dateString);
        } catch {
          onChange(value);
        }
      } else {
        onChange('');
      }
    },
    [onChange]
  );

  return (
    <MuiPickersUtilsProvider locale={useLocale()} utils={DateFnsUtils}>
      <KeyboardDatePicker
        // @TODO find a better way to guard the minDate empty string
        minDate={minDate || undefined}
        openTo={wizardMode ? 'year' : 'date'}
        value={value || null}
        views={wizardMode ? ['year', 'month', 'date'] : ['date']}
        onChange={handleChange}
        {...useMuiError(error)}
        {...rest}
      />
    </MuiPickersUtilsProvider>
  );
}

DateField.defaultProps = {
  ...defaultMuiProps,
  autoOk: false,
  format: 'dd-MM-yyyy',
  inputVariant: 'outlined',
  variant: 'dialog',
  wizardMode: false,
  InputLabelProps: {
    shrink: true
  }
};

DateField.propTypes = {
  error: PropTypes.string,
  minDate: PropTypes.string,
  value: PropTypes.string.isRequired,
  wizardMode: PropTypes.bool,
  onChange: PropTypes.func.isRequired
};

export default React.memo(DateField);
