import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import RichText from 'components/inputs/RichText';
import { useMuiError } from 'hooks';
import { StyledRichText } from './style';

function RichTextField({ className, error, label, required, handleLanguageChange, lang, ...rest }) {
  const [hasFocus, setHasFocus] = useState(false);
  const { error: hasError, helperText } = useMuiError(error);
  const composedLabel = useMemo(() => (required && label ? `${label} *` : label), [
    label,
    required
  ]);

  return (
    <FormControl className={className} error={hasError}>
      <StyledRichText

        as={RichText}
        error={error}
        hasFocus={hasFocus}
        label={composedLabel}
        onBlur={() => setHasFocus(false)}
        onFocus={() => setHasFocus(true)}
        handleLanguageChange={handleLanguageChange}
        lang={lang}
        {...rest}

      />
      {helperText && <FormHelperText variant="outlined">{helperText}</FormHelperText>}
    </FormControl>
  );
}

RichTextField.propTypes = {
  className: PropTypes.string,
  error: PropTypes.string,
  label: PropTypes.string,
  lang: PropTypes.string.isRequired,
  handleLanguageChange: PropTypes.func,
  required: PropTypes.bool
};

export default React.memo(RichTextField);
