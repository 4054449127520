import { phoneTypes } from './forms/Contact/config';
import { socialTypes } from './forms/Social/config';

export function parseData({ email = [], phones = {}, socialNetworks = {}, ...rest } = {}) {
  const parsedData = {
    emails: [],
    phones: [],
    socialNetworks: [],
    ...rest
  };
  // check needed in order to guard against server null value
  email &&
    email.forEach(emailItem => {
      const typeValue = { value: emailItem.email };

      if (emailItem.isOffice) {
        typeValue.type = 'officeEmail';
      }
      if (emailItem.isPrivate) {
        typeValue.type = 'privateEmail';
      }
      // add primary email at the begining of emails list
      if (emailItem.isPrimary) {
        parsedData.emails.unshift(typeValue);
      } else {
        parsedData.emails.push(typeValue);
      }
    });
  Object.keys(phones).forEach(phoneKey => {
    const phoneValue = phones[phoneKey];
    phoneValue && parsedData.phones.push({ type: phoneKey, value: phoneValue });
  });
  Object.keys(socialNetworks).forEach(socialNetworkKey => {
    const socialNetworkValue = socialNetworks[socialNetworkKey];
    // TODO find a more general solution for filtering the social networks
    socialNetworkValue &&
      socialTypes.includes(socialNetworkKey) &&
      parsedData.socialNetworks.push({ type: socialNetworkKey, value: socialNetworkValue });
  });

  // ensure at least one element exists inside each list
  !parsedData.emails.length && parsedData.emails.push(null);
  !parsedData.phones.length && parsedData.phones.push(null);
  !parsedData.socialNetworks.length && parsedData.socialNetworks.push(null);

  return parsedData;
}

export function serializeData({ emails, language, phones, socialNetworks, ...rest }) {
  const serializedData = {
    communicationLanguage: language,
    email: [],
    phones: phoneTypes.reduce((phones, phoneType) => {
      phones[phoneType] = '';
      return phones;
    }, {}),
    socialNetworks: socialTypes.reduce((socialNetworks, socialNetwork) => {
      socialNetworks[socialNetwork] = '';
      return socialNetworks;
    }, {}),
    ...rest
  };

  emails.forEach((email, index) => {
    if (email) {
      serializedData.email.push({
        email: email.value,
        isOffice: email.type === 'officeEmail',
        isPrimary: index === 0, // always set the first email as primary
        isPrivate: email.type === 'privateEmail'
      });
    }
  });
  phones.forEach(phone => {
    if (phone) {
      serializedData.phones[phone.type] = phone.value;
    }
  });
  socialNetworks.forEach(socialNetwork => {
    if (socialNetwork) {
      serializedData.socialNetworks[socialNetwork.type] = socialNetwork.value;
    }
  });

  return serializedData;
}
