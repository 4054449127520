import React, { useCallback, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import SelectField from 'components/inputs/SelectField';
import { FormContainer, FormItem, FormRow, FormSection } from 'components/styledComponents';
import Title from 'components/Title';
import { useTranslation } from 'hooks';

function Language({
  className,
  meta: { communicationLanguages },
  supportedLanguages,
  useInput,
  onLangChange
}) {
  const { t } = useTranslation();
  const { value: languageValue, onChange: onlanguageChange } = useInput('language');

  // RTEXT-725
  useEffect(() => {
    onLangChange(languageValue);
  }, []);

  const languageOptions = useMemo(
    () => communicationLanguages.filter(language => supportedLanguages.includes(language.key)),
    [communicationLanguages, supportedLanguages]
  );

  // commented in RTEXT-726
  // onLangChange(languageValue);
  const handleLanguageChange = useCallback(
    event => {
      localStorage.setItem('regUploadedFileName', '');
      onlanguageChange(event);
      onLangChange(event);
    },
    [onlanguageChange]
  );

  return (
    <FormContainer className={className}>
      <FormSection divider="bottom" padding="4 0">
        <Title label={t('label.portalLanguage')} size="large" color="primary" />
      </FormSection>
      <FormSection divider padding="1 0">
        <FormRow>
          <FormItem
            component={SelectField}
            label={t('label.portalLanguage')}
            options={languageOptions}
            {...useInput('language')}
            onChange={handleLanguageChange}
          />
          <FormItem empty />
        </FormRow>
      </FormSection>
    </FormContainer>
  );
}

Language.propTypes = {
  className: PropTypes.string,
  meta: PropTypes.shape({
    communicationLanguages: PropTypes.array.isRequired
  }).isRequired,
  supportedLanguages: PropTypes.array.isRequired,
  useInput: PropTypes.func.isRequired
};

export default Language;
