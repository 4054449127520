import React, { useMemo } from 'react';
import { Button } from 'components/buttons';
import { useTranslation } from 'hooks';
import StatusText from './StatusText';

export function useTableColumns(handleView) {
  const { t } = useTranslation();

  return useMemo(
    () => [
      {
        id: 'positionTitle',
        Header: t('columnTitle.opportunity'),
        minWidth: '150px',
        width: '35%'
      },
      {
        // eslint-disable-next-line react/display-name
        Cell: data => <StatusText statusCode={data.candidate.status} />,
        Header: t('columnTitle.status'),
        id: 'status',
        minWidth: '150px',
        width: '25%'
      },
      {
        Cell: data => (data.candidate.nda ? t('label.agreed') : t('label.notAgreed')),
        Header: t('columnTitle.nda'),
        id: 'nda',
        minWidth: '150px',
        width: '20%'
      },
      {
        align: 'right',
        // eslint-disable-next-line react/display-name
        Cell: data => (
          <Button
            color="primary"
            label={t('button.viewDetails')}
            variant="outlined" // RTEXT-852
            onClick={() => handleView(data)}
          />
        ),
        id: 'actions',
        Header: '',
        minWidth: '150px',
        width: '20%'
      }
    ],
    [handleView, t]
  );
}
