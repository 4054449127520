import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import Collapse from '@material-ui/core/Collapse';
import { Plus, Trash } from 'components/icons';
import Title from 'components/Title';
import { Switch } from 'components/buttons';
import { TextField, SelectField } from 'components/inputs';
import { FormContainer, FormItem, FormRow } from 'components/styledComponents';
import { useTranslation } from 'hooks';
import Form from './Form';
import { Button, GroupRow, RelationLink, RoleSection } from './style';
const objectPath = require('object-path');

const EMPTY_ROLE = {
  isActualEngagement: false
};

function getRoleProps(role) {
  const getValue = path => objectPath.get(role, path);
  const useInput = path => {
    const value = getValue(path);
    return {
      ...(typeof value === 'boolean' ? { checked: value } : { value: value || '' }),
      onChange: () => {}
    };
  };

  return {
    getValue,
    useInput
  };
}

function OtherRelatedRoles({
  className,
  getValue,
  meta: { countries },
  selectedExperience,
  useInput,
  workExperience,
  onAdd,
  onDelete
}) {
  const { t, tr } = useTranslation();
  const { value: relatedCompanyRoles, onChange } = useInput('relatedCompanyRoles');
  const { value: companyName } = useInput('companyName');
  const { value: companyGroupName, onChange: onGroupNameChange } = useInput('companyGroup');
  const newRoles = useMemo(() => (Array.isArray(relatedCompanyRoles) ? relatedCompanyRoles : []), [
    relatedCompanyRoles
  ]);
  const oldRoles = useMemo(() => {
    if (selectedExperience) {
      return workExperience.reduce((roles, experience) => {
        const {
          companyGroupId,
          companyName,
          dateFrom,
          dateTo,
          id,
          isActualEngagement,
          name
        } = experience;
        if (
          companyGroupId &&
          id !== selectedExperience.id &&
          companyGroupId === selectedExperience.companyGroupId
        ) {
          roles.push({
            companyName,
            dateFrom,
            dateTo,
            isActualEngagement,
            name
          });
        }
        return roles;
      }, []);
    }
    return [];
  }, [selectedExperience, workExperience]);
  const [displayRoles, setDisplayRoles] = useState(!!oldRoles.length);
  const allowRoleRemoval = useMemo(
    () =>
      // do not remove the last role (at least one role is needed for tree view)
      oldRoles.length || newRoles.length > 1,
    [newRoles.length, oldRoles.length]
  );

  const handleAdd = useCallback(() => onAdd('relatedCompanyRoles', EMPTY_ROLE), [onAdd]);
  const handleToggle = useCallback(
    event => {
      setDisplayRoles(event.target.checked);
      onChange(event.target.checked ? [EMPTY_ROLE] : []);
    },
    [onChange]
  );

  useEffect(() => {
    if (companyName && !companyGroupName) {
      onGroupNameChange(companyName);
    }
  }, [companyGroupName, companyName, onGroupNameChange]);

  return (
    <FormContainer as="div" className={className}>
      <FormRow>
        <FormItem component={Title} label={tr('message.parentOrSisterCompany')} />
        <FormItem
          checked={displayRoles}
          component={Switch}
          leftLabel={tr('button.no')}
          rightLabel={tr('button.yes')}
          size="0"
          value="parentCompany"
          onChange={handleToggle}
        />
      </FormRow>
      <Collapse in={displayRoles} timeout="auto" unmountOnExit>
        <FormContainer as="div">
          <RoleSection>
            <RelationLink main />
            <GroupRow as={FormRow}>
              <FormItem
                component={TextField}
                label={tr('label.companyGroup')}
                required
                {...useInput('companyGroupName')}
              />
              <FormItem
                component={SelectField}
                label={tr('label.country')}
                options={countries}
                required
                {...useInput('companyGroupCountry')}
              />
            </GroupRow>
          </RoleSection>
          {oldRoles.map((role, index) => (
            <RoleSection key={index}>
              <RelationLink last={!newRoles.length && index === oldRoles.length - 1} />
              <Form basePath="" disabled {...getRoleProps(role)} />
            </RoleSection>
          ))}
          {newRoles.map((_, index) => (
            <RoleSection key={index}>
              <RelationLink last={index === newRoles.length - 1} />
              <Form
                basePath={`relatedCompanyRoles[${index}].`}
                getValue={getValue}
                useInput={useInput}
              />
              {allowRoleRemoval && (
                <Button
                  color="danger"
                  contentFirst={true}
                  label={t('button.deleteSisterCompany')}
                  variant="text"
                  onClick={() => {
                    onDelete(`relatedCompanyRoles[${index}]`);
                  }}
                >
                  <Trash fontSize="small" />
                </Button>
              )}
            </RoleSection>
          ))}
          <Button
            color="default"
            contentFirst={true}
            label={t('button.addSisterRole')}
            variant="outlined"
            onClick={handleAdd}
          >
            <Plus fontSize="small" />
          </Button>
        </FormContainer>
      </Collapse>
    </FormContainer>
  );
}

OtherRelatedRoles.propTypes = {
  className: PropTypes.string,
  getValue: PropTypes.func.isRequired,
  meta: PropTypes.shape({
    countries: PropTypes.array.isRequired
  }).isRequired,
  selectedExperience: PropTypes.object,
  workExperience: PropTypes.array.isRequired,
  useInput: PropTypes.func.isRequired,
  onAdd: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired
};

export default OtherRelatedRoles;
