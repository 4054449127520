import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Card } from 'components/cards';
import { ActionsContent, Button, Container, Subject, Text } from './style';

const COMPONENTS = {
  Content: Container
};

function AtreusOffer({ className, language, offer, ...rest }) {
  const contentProps = useMemo(() => ({ image: offer.image }), [offer]);

  return (
    <Card
      className={className}
      components={COMPONENTS}
      contentProps={contentProps}
      disableGutters={true}
      dividers={false}
      margin="none"
      size={offer.type === 'wide' ? '12' : '5'}
      title={offer.title[language]}
      {...rest}
    >
      <div>
        <Subject dangerouslySetInnerHTML={{ __html: offer.subject[language] }} />
        <Text dangerouslySetInnerHTML={{ __html: offer.description[language] }} />
      </div>
      <ActionsContent align={offer.type === 'wide' ? 'left' : 'right'}>
        {offer.actions.map((item, index) => (
          <Button
            color="primary"
            component="a"
            href={item.url}
            key={index}
            label={'+ ' + item.text[language]}
            target="_blank"
            variant={index === 0 ? 'lightContained' : 'lightOutlined'}
          />
        ))}
      </ActionsContent>
    </Card>
  );
}

AtreusOffer.propTypes = {
  className: PropTypes.string,
  language: PropTypes.string.isRequired,
  offer: PropTypes.object.isRequired
};

export default AtreusOffer;
