import styled from 'styled-components';
import { Button } from 'components/buttons';

export const AccountImage = styled.div`
  display: flex;
  margin-right: 25px;
  & svg {
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin: 16px 16px 16px 0;
  }

  & label {
    margin-top: 30px;
  }

  & img {
    width: 109px;
    height: 109px;
    object-fit: cover;
  }
`;

export const AddButton = styled(Button)`
  margin: ${props => props.theme.spacing(2)} 0;
`;
