import styled from 'styled-components';

export const Container = styled.div`
  background-color: ${props => props.backgroundColor};
  color: ${props => props.textColor};
  display: inline-block;
  font-size: ${props => props.theme.fontSize.xsmall};
  min-width: 70px;
  padding: ${props => props.theme.spacing(0, 1)};
  text-align: center;
  text-transform: uppercase;
`;
