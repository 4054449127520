import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Route, withRouter } from 'react-router-dom';
import Dialog from 'components/Dialog';
import { useForm, useTranslation } from 'hooks';
import Form from './Form';

function CompetencePage({
  competencePage,
  history,
  language,
  match: { url },
  syncErrors,
  syncing,
  useInput,
  loading,
  handleNext,
  handlePrev,
  handleSubmit,
  handleClose
}) {
  return (
    <Form
      data-test-id="competence-profile-form"
      language={language}
      useInput={useInput}
      loading={loading}
      syncing={syncing}
      handleNext={handleNext}
      handlePrev={handlePrev}
      handleSubmit={handleSubmit}
      handleClose={handleClose}
    />
  );
}

CompetencePage.propTypes = {
  competencePage: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  language: PropTypes.string.isRequired,
  match: PropTypes.object.isRequired,
  syncErrors: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  syncing: PropTypes.bool.isRequired,
  onSave: PropTypes.func.isRequired
};

export default withRouter(CompetencePage);
