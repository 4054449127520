import styled from 'styled-components';
import { SelectField } from 'components/inputs';

// RTEXT-620 (modified)
export const StyledSelectField = styled(SelectField)`
  margin-top: 50px;
  margin-bottom: 50px;
  ${props => `
    min-width: 100px;
    width: ${props.theme.spacing(40)};
  `}
  flex: 1;
  max-width: 320px !important;
  width: auto !important;
`;
