import * as types from './types';

const initialState = {};

function meta(state = initialState, action) {
  switch (action.type) {
    case types.META_LOADED:
      return {
        ...state,
        [action.payload.language]: {
          ...action.payload.meta,
          loaded: true
        }
      };
    default:
      return state;
  }
}

export default meta;
