import * as types from './types';
import * as authTypes from 'redux-modules/auth/types';

const initialState = {
  error: null,
  nda: {},
  opportunities: [],
  syncing: false,
  syncingItem: false
};

function opportunities(state = initialState, action) {
  switch (action.type) {
    case types.OPPORTUNITIES_FAILED:
      return {
        ...state,
        error: action.payload.error,
        syncing: false
      };
    case types.OPPORTUNITY_SYNC_FAILED:
      return {
        ...state,
        error: action.payload.error,
        syncingItem: false
      };
    case types.NDA_LOADED:
      return {
        ...state,
        nda: {
          ...state.nda,
          [action.payload.language]: action.payload.nda
        }
      };
    case types.LOAD_OPPORTUNITIES:
      return {
        ...state,
        error: null,
        syncing: true
      };
    case types.OPPORTUNITIES_LOADED:
      return {
        ...state,
        opportunities: action.payload.opportunities,
        syncing: false
      };
    case types.ACCEPT_NDA:
    case types.ACCEPT_OPPORTUNITY:
    case types.LOAD_OPPORTUNITY:
    case types.REJECT_OPPORTUNITY:
    case types.NOT_AVAILABLE_FOR_OPPORTUNITY:
      return {
        ...state,
        error: null,
        syncingItem: true
      };
    case types.NDA_ACCEPTED:
    case types.OPPORTUNITY_LOADED:
    case types.OPPORTUNITY_ACCEPTED:
    case types.OPPORTUNITY_REJECTED:
    case types.OPPORTUNITY_UnAvailable:
      return {
        ...state,
        opportunities:
          state.opportunities.length > 0
            ? state.opportunities.map(opportunity =>
                opportunity.id === action.payload.opportunity.id
                  ? action.payload.opportunity
                  : opportunity
              )
            : [action.payload.opportunity],
        syncingItem: false
      };
    case authTypes.LOGOUT:
      return initialState;
    default:
      return state;
  }
}

export default opportunities;
