import * as types from './types';

const initialState = {};

function projects(state = initialState, action) {
  switch (action.type) {
    case types.PROJECTS_LOADED:
      return {
        ...state,
        projects: action.payload.projects
      };
    default:
      return state;
  }
}

export default projects;
