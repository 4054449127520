import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import Link from 'components/Link';
import { useTranslation } from 'hooks';
import PlacementOverview from 'components/Placements/PlacementOverview';
import PlacementContact from 'components/Placements/PlacementContact';

import {
  Button,
  ButtonContainer,
  Container,
  InformationContainer,
  LoaderContainer,
  RotatingSelect
} from './style';
import { Loader } from 'components/styledComponents';

function PlacementSummary({
  className,
  loading,
  placement,
  placements,
  allPlacements,
  showAllPlacements,
  widget,
  onSelectedPlacementChange,
  videoURLs,
  language
}) {
  const { t } = useTranslation();
  const hasData = useMemo(() => {
    return placement && placement !== null && placement.id != null;
  }, [placement]);
  var finalPlacements = showAllPlacements ? allPlacements : placements;
  const placementsKeyValue = useMemo(() => {
    return finalPlacements
      ? finalPlacements.map(placement => {
          return {
            key: placement.id,
            name: placement.name,
            placement
          };
        })
      : [];
  }, [finalPlacements]);

  const onPlacementChange = useCallback(
    (_, item) => {
      if (onSelectedPlacementChange) onSelectedPlacementChange(item.placement);
    },
    [onSelectedPlacementChange]
  );

  return (
    <Container className={className}>
      <Loader
        className={className + ' placement-overview'}
        component={LoaderContainer}
        loading={loading}
      >
        <InformationContainer widget={widget}>
          <PlacementOverview
            personalInformationHeader={
              <RotatingSelect
                items={placementsKeyValue}
                selectedKey={placement ? placement.id : null}
                onChange={onPlacementChange}
              />
            }
            placement={placement}
          />
        </InformationContainer>
        <InformationContainer widget={widget}>
          <PlacementContact placement={placement} />
        </InformationContainer>
        <ButtonContainer>
          {/* @TODO add back coming soon sticker after a proper component is created */}
          {widget && placement && placement.reportBillingViaPortal === '100' && (
            <Button
              color="primary"
              component={Link}
              disabled={!hasData}
              label={'+ ' + t('button.viewDetails')}
              to={'/placement/' + placement.id}
              variant="outlined"
            />
          )}
          {videoURLs && (
            <Button
              color="primary"
              variant="outlined"
              label={t('button.howTo')}
              onClick={() => {
                language === 'de' ? window.open(videoURLs?.de) : window.open(videoURLs?.en);
              }}
            />
          )}
        </ButtonContainer>
      </Loader>
    </Container>
  );
}

PlacementSummary.defaultProps = {
  placement: {},
  widget: false
};

PlacementSummary.propTypes = {
  className: PropTypes.string,
  loading: PropTypes.bool,
  placement: PropTypes.object.isRequired,
  placements: PropTypes.array.isRequired,
  allPlacements: PropTypes.array.isRequired,
  showAllPlacements: PropTypes.bool,
  widget: PropTypes.bool.isRequired,
  onSelectedPlacementChange: PropTypes.func,
  videoURLs: PropTypes.object,
  language: PropTypes.string
};

export default PlacementSummary;
