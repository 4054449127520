import React from 'react';
import PropTypes from 'prop-types';
import { DayContainer, Side } from './style';

function Day({
  backgroundColor,
  children,
  disabled,
  hidden,
  leftSideColor,
  rightSideColor,
  textColor,
  disablePointer,
  ...rest
}) {
  return (
    <React.Fragment>
      <Side backgroundColor={disabled ? 'transparent' : leftSideColor} />
      <DayContainer
        backgroundColor={backgroundColor}
        disabled={disabled}
        tabIndex={hidden || disabled ? -1 : 0}
        textColor={textColor}
        // RTEXT-690
        disablePointer={disablePointer}
        {...rest}
      >
        {!hidden && <span>{children}</span>}
      </DayContainer>
      <Side backgroundColor={disabled ? 'transparent' : rightSideColor} />
    </React.Fragment>
  );
}

Day.defaultProps = {
  type: 'button'
};

Day.propTypes = {
  backgroundColor: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.node, PropTypes.object]),
  disabled: PropTypes.bool,
  hidden: PropTypes.bool,
  leftSideColor: PropTypes.string,
  rightSideColor: PropTypes.string,
  textColor: PropTypes.string,
  type: PropTypes.string.isRequired,
  disablePointer: PropTypes.bool,
};

export default Day;
