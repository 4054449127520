import errorHandler from './errorHandler';
import initRequest from './initRequest';
import rootStore from 'redux-modules';

const DEFAULT_HEADERS = {
  'Content-Type': 'application/json'
};

function initApiRequest({ headers = DEFAULT_HEADERS, ...requestOptions }) {
  return initRequest({
    cache: 'no-store',
    headers: {
      Accept: 'application/json, text/plain',
      'Cache-control': 'no-cache, no-store',
      pragma: 'no-cache',
      ...headers
    },
    ...requestOptions
  });
}

function parseResponse(response) {
  if (response.ok) {
    return response.status === 204 ? { status: 'ok' } : response.json();
  } else {
    const error = {
      code: response.status,
      message: response.statusText
    };
    throw error;
  }
}

var refreshTokenResponseTask = null;
var refreshTokenResponseTaskPending = false;
async function refreshToken(initialCallResponse) {
  var refreshTokenUrl = '/api/v1/refresh-token';
  var payload = {
    refreshToken: localStorage.getItem('refreshToken')
  };
  var refreshTokenReq = initApiRequest({
    method: 'POST',
    body: JSON.stringify(payload)
  });
  refreshTokenReq.headers.delete('Authorization');
  if (!refreshTokenResponseTask || !refreshTokenResponseTaskPending) {
    refreshTokenResponseTaskPending = true;
    refreshTokenResponseTask = doRequest(refreshTokenUrl, refreshTokenReq, err => {
      rootStore.dispatch({ type: 'user_logout' });
      return parseResponse(initialCallResponse);
    }).then(resp => {
      refreshTokenResponseTaskPending = false;
      if (resp.error) {
        rootStore.dispatch({ type: 'user_logout' });
      }
      return resp;
    });
  }
  var refreshTokenResponse = await refreshTokenResponseTask;

  if (refreshTokenResponse && refreshTokenResponse.data) {
    localStorage.setItem('authToken', refreshTokenResponse.data.accessToken);
    localStorage.setItem('refreshToken', refreshTokenResponse.data.refreshToken);
  }
  return refreshTokenResponse;
}

function doRequest(url, options, failCallback) {
  return fetch(url, options)
    .then(async response => {
      if (response.status === 401) {
        var data = await response.json();
        if (data && data.error && data.error.code === '401_ACCESS_TOKEN_EXPIRED') {
          // access token expired, need to refresh it
          var refreshTokenResponse = await refreshToken(response);
          if (refreshTokenResponse && refreshTokenResponse.error) {
            // could not refresh token, throw initial error
            return parseResponse(response);
          }

          // retry the initial request
          options.headers.delete('Authorization');
          options.headers.append(
            'Authorization',
            `Bearer ${refreshTokenResponse.data.accessToken}`
          );
          return doRequest(url, options, failCallback);
        }
      }
      return parseResponse(response);
    })
    .catch(error => {
      failCallback && failCallback(error);
      errorHandler.handle(error);
    });
}

class ApiClient {
  custom({ method, url, ...options }, failCallback) {
    return doRequest(
      url,
      initApiRequest({
        method: method || 'POST',
        headers: {},
        ...options
      }),
      failCallback
    );
  }
  delete(url, failCallback) {
    return doRequest(
      url,
      initApiRequest({
        method: 'DELETE'
      }),
      failCallback
    );
  }
  get(url, failCallback) {
    return doRequest(
      url,
      initApiRequest({
        method: 'GET'
      }),
      failCallback
    );
  }
  post(url, payload, failCallback) {
    return doRequest(
      url,
      initApiRequest({
        body: JSON.stringify(payload),
        method: 'POST'
      }),
      failCallback
    );
  }
  put(url, payload, failCallback) {
    return doRequest(
      url,
      initApiRequest({
        body: JSON.stringify(payload),
        method: 'PUT'
      }),
      failCallback
    );
  }
}

export default new ApiClient();
