import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Container from '@material-ui/core/Container';
import PropTypes from 'prop-types';

import { Section, Loader, FormContainer, FormSection } from 'components/styledComponents';
import { AgreementTerms, Documents } from './sections';
import { useTranslation } from 'hooks';
import { Text } from './style';
import { Button } from 'components/buttons';
import { FooterActions } from '../MyAccountPage/style';

function MyDocumentsPage({ lang, syncing, documents, fetchDocuments, uploadDocument }) {
  const { t } = useTranslation();
  const history = useHistory();

  useEffect(() => {
    fetchDocuments();
  }, []);

  return (
    <>
      <Section>
        <Loader component={Container} loading={syncing}>
          <Documents
            documents={documents ? documents : []}
            lang={lang}
            uploadDocument={uploadDocument}
          />
          <FormContainer>
            <FormSection divider="bottom" padding="2 0">
              <Text dangerouslySetInnerHTML={{ __html: t('message.documentsMessage') }} />
            </FormSection>
          </FormContainer>
        </Loader>
      </Section>
      <Section>
        <Loader component={Container} loading={syncing}>
          <AgreementTerms lang={lang} />
          <FooterActions>
            <Button color="primary" onClick={() => history.push('/')}>
              {t('button.back')}
            </Button>
          </FooterActions>
        </Loader>
      </Section>
    </>
  );
}

MyDocumentsPage.propTypes = {
  lang: PropTypes.string,
  syncing: PropTypes.bool,
  documents: PropTypes.array,
  fetchDocuments: PropTypes.func.isRequired,
  uploadDocument: PropTypes.func.isRequired
};

export default MyDocumentsPage;
