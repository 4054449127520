import { all, call, put, select, takeLeading } from 'redux-saga/effects';
import apiClient from 'communication/apiClient';
import * as actions from './actions';
import * as selectors from './selectors';
import * as types from './types';
import * as uiStateSelectors from 'redux-modules/uiState/selectors';
import * as uiStateTypes from 'redux-modules/uiState/types';

export function* loadMeta(language) {
  try {
    const resp = yield call(apiClient.get, `/api/v1/meta/labels_and_enums/lang/${language}`);
    if (resp && resp.data) {
      yield put(actions.setLoadedMeta(resp.data, language));
    } else {
      yield put(actions.failMetaLoad(resp));
    }
  } catch (error) {
    yield put(actions.failMetaLoad('error'));
  }
}

export function* loadMetas() {
  const isLoaded = yield select(selectors.selectLoadedState);
  if (isLoaded) {
    return;
  }
  const languages = yield select(uiStateSelectors.selectAppLanguages);
  yield all(languages.map(language => call(loadMeta, language)));
}

export default [
  takeLeading(types.LOAD_META, loadMetas),
  takeLeading(uiStateTypes.CHANGE_RESUME_LANGUAGE, loadMetas),
  takeLeading(uiStateTypes.CHANGE_LANGUAGE, loadMetas)
];
