import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Close } from 'components/icons';
import IconButton from '@material-ui/core/IconButton';
import MuiSnackbarContent from '@material-ui/core/SnackbarContent';
import useStyles from './style';

const variantIcon = {
  success: null,
  warning: null,
  error: null,
  info: null
};

function SnackbarContent(props) {
  const classes = useStyles();
  const { className, message, variant, onClose, ...other } = props;
  const Icon = variantIcon[variant];

  return (
    <MuiSnackbarContent
      action={[
        <IconButton color="inherit" key="close" onClick={onClose}>
          <Close className={classes.icon} />
        </IconButton>
      ]}
      className={clsx(classes[variant], className)}
      message={
        <span className={classes.message}>
          <Icon className={clsx(classes.icon, classes.iconVariant)} />
          {message}
        </span>
      }
      {...other}
    />
  );
}

SnackbarContent.propTypes = {
  className: PropTypes.string,
  message: PropTypes.node,
  onClose: PropTypes.func,
  variant: PropTypes.oneOf(['error', 'info', 'success', 'warning']).isRequired
};

export default SnackbarContent;
