import React, { useCallback, useState } from 'react';
import { TableRow, TableCell } from '@material-ui/core';
import { useDropzone } from 'react-dropzone';
import PropTypes from 'prop-types';

import { SelectField } from 'components/inputs';
import { useSubcategories, useTranslation } from 'hooks';
import { Button, IconButton } from 'components/buttons';
import { Text } from './style';

function NewDocument({
  index,
  file,
  lang,
  subcategoryId,
  handleSubcategoryChange,
  handleDeleteDocument,
  handleFileUpload,
  uploadDocument
}) {
  const { t } = useTranslation();
  const [errorMessage, setErrorMessage] = useState('');
  const subcategories = useSubcategories();

  const onDrop = useCallback(
    (acceptedFiles, rejectedFiles) => {
      const error = rejectedFiles[0]?.errors?.[0];
      if (error?.code === 'file-too-large' || error?.code === 'file-invalid-type') {
        setErrorMessage(t('error.file.typeAndSize'));
        handleFileUpload(index, null);
      } else {
        setErrorMessage('');
      }

      const file = acceptedFiles[0];
      if (file) {
        handleFileUpload(index, file);
      }
    },
    [handleFileUpload]
  );

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      'application/pdf': ['.pdf']
    },
    maxFiles: 1,
    maxSize: 15728640,
    onDrop
  });

  return (
    <TableRow>
      <TableCell width="20%">
        <SelectField
          value={subcategoryId}
          options={subcategories}
          style={{ minWidth: lang === 'en' ? '185px' : '150px' }}
          onChange={value => handleSubcategoryChange(index, value)}
        />
      </TableCell>
      <TableCell className="dropzone" style={{ position: 'relative' }} {...getRootProps()}>
        <input {...getInputProps()} />
        <Button color="primary">{t('button.chooseDocument')}</Button>
        {file && <Text>{file.name}</Text>}
        <Text color="red">{errorMessage}</Text>
      </TableCell>
      <TableCell align="right">
        <Button
          color="primary"
          disabled={!!!file}
          onClick={() =>
            uploadDocument(subcategoryId, file, () => {
              handleDeleteDocument(index);
            })
          }
        >
          {t('button.uploadFile')}
        </Button>
      </TableCell>
      <TableCell align="right" width="10%">
        <IconButton color="danger" icon="Trash" onClick={() => handleDeleteDocument(index)} />
      </TableCell>
    </TableRow>
  );
}

NewDocument.propTypes = {
  index: PropTypes.number.isRequired,
  file: PropTypes.object,
  lang: PropTypes.string.isRequired,
  subcategoryId: PropTypes.string.isRequired,
  handleSubcategoryChange: PropTypes.func.isRequired,
  handleDeleteDocument: PropTypes.func.isRequired,
  handleFileUpload: PropTypes.func.isRequired,
  uploadDocument: PropTypes.func.isRequired
};

export default NewDocument;
