import styled from 'styled-components';

export const StyledHeader = styled.div``;

export const StyledContent = styled.div``;

export const StyledContainer = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding: ${props => props.theme.spacing(0, props.spacing)};
  ${props =>
    props.disableGutters &&
    `
    padding: 0;

    ${StyledHeader} {
      padding-left: ${props.theme.spacing(props.spacing)};
    }
  `}

  ${StyledContent} {
    flex: 1 1 auto;
    ${props => props.margin === 'normal' && `padding: ${props.theme.spacing(props.spacing)} 0;`}
  }
`;
