import { connect } from 'react-redux';
import * as resumeActions from 'redux-modules/resume/actions';
import * as resumeSelectors from 'redux-modules/resume/selectors';
import * as uiStateSelectors from 'redux-modules/uiState/selectors';
import CompetencePage from './CompetencePage';

function mapStateToProps(state) {
  return {
    competencePage: resumeSelectors.selectCompetenceProfile(state),
    language: uiStateSelectors.selectResumeLanguage(state),
    syncErrors: resumeSelectors.selectSyncError(state),
    syncing: resumeSelectors.selectSyncState(state)
  };
}


export default connect(mapStateToProps, null)(CompetencePage);
