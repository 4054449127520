import React, { useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import Container from '@material-ui/core/Container';
import FormHelperText from '@material-ui/core/FormHelperText';
import { defaultTheme } from 'themes';
import { Button } from 'components/buttons';
import { useForm, useTranslation } from 'hooks';
import { FormContainer, FormItem, FormRow } from 'components/styledComponents';
import { TextField } from 'components/inputs';
import useStyles, { BackLink, Warning, Text, LockedContainer } from './style';

function LoginPage({ syncError, syncing, onLogin }) {
  const { t } = useTranslation();
  const classes = useStyles();

  const { handleSubmit, useInput } = useForm({
    onSubmit: onLogin,
    validationSchema: 'loginSchema'
  });

  const username = useInput('username');
  const password = useInput('password');

  const translatedError = useMemo(() => syncError && t(`error.http.${syncError.status}01`), [
    syncError,
    t
  ]);

  useEffect(() => {
    localStorage.setItem('regUploadedFileName', '');
    return () => {
      localStorage.setItem('regUploadedFileName', '');
    };
  }, []);

  // @TODO : To find a more generalistic way of handleing key events
  const handlePwdKeyUp = useCallback(
    event => {
      if (event.keyCode === 13) {
        // trigger submit on Enter pressed
        handleSubmit(event);
      }
    },
    [handleSubmit]
  );

  const pwdInputProps = useMemo(() => {
    return { onKeyUp: handlePwdKeyUp };
  }, [handlePwdKeyUp]);

  return (
    <Container maxWidth="xs">
      <div className={classes.paper}>
        {syncError?.payload?.locked ? (
          <LockedContainer>
            <div className={classes['typo']}>
              <h3 className={classes['locklink.active']} style={{ margin: '0' }}>
                {t('label.accountLocked')}
              </h3>
            </div>
            <Text first={1}>{t('message.lockedMsg1')}</Text>
            <Text>{t('message.lockedMsg2')}</Text>
            <Text
              dangerouslySetInnerHTML={{
                __html: t('message.lockedMsg3')
              }}
            />
          </LockedContainer>
        ) : (
          <>
            <div className={classes['typo']}>
              <h3 className={classes['navlink.active']} style={{ margin: '0' }}>
                {t('title.loginTitle')}
              </h3>
            </div>
            <FormContainer>
              <FormRow>
                <FormItem
                  className={classes.input}
                  component={TextField}
                  placeholder={t('label.emailAddress')}
                  required
                  {...username}
                />
              </FormRow>
              <FormRow>
                <FormItem
                  className={classes.input}
                  component={TextField}
                  inputProps={pwdInputProps}
                  placeholder={t('label.password')}
                  required
                  type="password"
                  {...password}
                />
              </FormRow>
              <div className={classes.error}>
                {translatedError && <FormHelperText error>{translatedError}</FormHelperText>}
              </div>
              {syncError?.payload?.locked === false && syncError?.payload?.left_attempts > 0 && (
                <Warning className={classes.error}>
                  <FormHelperText className={classes.bold} error>
                    {t('label.warning')}
                  </FormHelperText>
                  <FormHelperText error>{t('message.warningMsg')}</FormHelperText>
                </Warning>
              )}

              <div className={classes.formFooter}>
                <BackLink color={defaultTheme.color.primary.contrast} to="/forgot-password">
                  {t('link.forgotPassword')}
                </BackLink>
                <Button
                  color="primary"
                  disabled={syncing}
                  label={t('button.login')}
                  variant="lightContained"
                  onClick={handleSubmit}
                />
              </div>
            </FormContainer>
          </>
        )}
      </div>
    </Container>
  );
}

LoginPage.propTypes = {
  syncError: PropTypes.object,
  syncing: PropTypes.bool.isRequired,
  onLogin: PropTypes.func.isRequired
};

export default LoginPage;
