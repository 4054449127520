import { connect } from 'react-redux';
import * as authActions from 'redux-modules/auth/actions';
import * as authSelectors from 'redux-modules/auth/selectors';
import LoginPage from './LoginPage';

function mapStateToProps(state) {
  return {
    syncing: authSelectors.selectSyncState(state),
    syncError: authSelectors.selectSyncError(state)
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onLogin: (data, failCallback) => dispatch(authActions.login(data, failCallback))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);
