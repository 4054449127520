import styled from 'styled-components';
import { MainSection as BaseMainSection } from 'components/styledComponents';
import loginImage from './images/login.jpg';
import registerImage from './images/register.jpg';
import Logo from 'components/Logo';

export const Container = styled.div`
  background: ${props =>
    props.transparent
      ? props.theme.color.background.transparent
      : props.theme.color.background.surface};
  border-radius: ${props => props.theme.borderRadius.base};
  z-index: ${props => props.theme.layer.above};
`;

export const MainSection = styled(BaseMainSection)`
  align-items: center;
  background: ${props =>
    props.isRegisterPage
      ? `center / cover no-repeat url(${registerImage})`
      : `center / cover no-repeat url(${loginImage})`};
  justify-content: center;
  position: relative;

  svg[old='1'] {
    font-size: ${props => props.theme.fontSize.xlarge};
    left: 5%;
    position: absolute;
    top: 5%;
    @media (max-width: 446px) {
      top: 10%;
    }
  }
`;

export const CornerLogo = styled(Logo)`
  right: 1%;
  position: absolute;
  top: 1%;
`;
