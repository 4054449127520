import styled from 'styled-components';
import { IconButton } from 'components/buttons';

export const FlexContainer = styled.div`
  display: flex;
  flex-direction: ${props => (props.direction ? props.direction : 'row')};
  align-items: ${props => (props.alignItems ? props.alignItems : 'unset')};
  width: 100%;
`;

export const TimeFieldEntry = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: baseline;
  margin: 10px 0;

  input {
    border: none;
    width: 40px !important;
    margin-right: 45px;
    border: 2px solid #206af5;
    padding: 4px 7px;
  }

  input.de {
    margin-right: 0px;
    margin-left: 5px;
  }

  input.en {
    margin-right: 0px;
    margin-left: 15px;
  }

  input.error {
    color: red;
  }
`;

export const AddBreakButton = styled.div`
  color: ${props => props.theme.color.primary.main};
  cursor: pointer;
  margin: 10px 0;
  font-weight: bold;
`;

export const DeleteButton = styled(IconButton)`
  align-self: center;
  padding: 0px;
  margin-top: -5px;
`;
