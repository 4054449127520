import { default as LinkComponent } from 'components/Link';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

export const Link = styled(LinkComponent).attrs(props => ({
  component: NavLink,
  ...props
}))`
  box-sizing: border-box;
  color: inherit;
  font-size: ${props => props.theme.fontSize.large};

  &.active {
    border-bottom: 1px solid;
    border-top: 1px solid transparent;
  }
`;

export const Header = styled.header`
  align-items: center;
  background: ${props => props.theme.color.primary.main};
  box-sizing: border-box;
  color: ${props => props.theme.color.primary.contrast};
  display: flex;
  padding-bottom: ${props => props.theme.spacing(1)};
  padding-top: ${props => props.theme.spacing(1.625)};
  position: sticky;
  top: 0%;
  z-index: ${props => props.theme.layer.above};
`;
