import React from 'react';
import PropTypes from 'prop-types';
import { ActionsFooter, Button } from './style';

function Footer({ actionLabel, disabled, onAction, disableBorder }) {
  return (
    <ActionsFooter disableBorder={disableBorder}>
      <Button color="primary" disabled={disabled} label={actionLabel} onClick={onAction} />
    </ActionsFooter>
  );
}

Footer.propTypes = {
  actionLabel: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  onAction: PropTypes.func.isRequired
};

export default Footer;
