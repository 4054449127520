import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import Collapse from '@material-ui/core/Collapse';
import Title from 'components/Title';
import { Switch } from 'components/buttons';
import { Plus } from 'components/icons';
import { FormContainer, FormItem, FormRow } from 'components/styledComponents';
import { useTranslation } from 'hooks';
import Form from './Form';
import { AddButton } from './style';

const EMPTY_MISSION = { monthsSpent: 0, assignmentType: '', country: '' };

function WorkAbroad({ className, useInput, onAdd, onDelete }) {
  const { t, tr } = useTranslation();
  const { value: workAbroad, onChange } = useInput('missionsAbroad');
  const displayWorkAbroad = useMemo(() => !!(workAbroad && workAbroad.length), [workAbroad]);
  const handleAdd = useCallback(() => onAdd('missionsAbroad', EMPTY_MISSION), [onAdd]);
  const handleChange = useCallback(
    event => {
      return onChange(event.target.checked ? [EMPTY_MISSION] : []);
    },
    [onChange]
  );

  return (
    <FormContainer className={className}>
      <FormRow>
        <FormItem component={Title} label={tr('message.workAbroadQuestion')} />
        <FormItem
          checked={displayWorkAbroad}
          component={Switch}
          leftLabel={tr('button.no')}
          rightLabel={tr('button.yes')}
          size="0"
          value="workAbroad"
          onChange={handleChange}
        />
      </FormRow>
      <Collapse in={displayWorkAbroad} timeout="auto" unmountOnExit>
        <React.Fragment>
          {workAbroad &&
            workAbroad.map((_, index) => (
              <Form
                basePath={`missionsAbroad[${index}]`}
                key={index}
                useInput={useInput}
                onDelete={() => onDelete(`missionsAbroad[${index}]`)}
              />
            ))}
          <AddButton
            color="default"
            contentFirst={true}
            label={t('button.addNewExperienceAbroad')}
            variant="outlined"
            onClick={handleAdd}
          >
            <Plus fontSize="small" />
          </AddButton>
        </React.Fragment>
      </Collapse>
    </FormContainer>
  );
}

WorkAbroad.propTypes = {
  className: PropTypes.string,
  useInput: PropTypes.func.isRequired,
  onAdd: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired
};

export default WorkAbroad;
