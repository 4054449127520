import React, { useState, useCallback } from 'react';
import { Table, TableHead, TableContainer, TableRow, TableCell } from '@material-ui/core';
import { format } from 'date-fns';
import PropTypes from 'prop-types';

import { useLocale, useTranslation } from 'hooks';
import { FormContainer, FormSection, FormRow } from 'components/styledComponents';
import Title from 'components/Title';
import { Button, IconButton } from 'components/buttons';
import { TableBody } from './style';
import NewDocument from './NewDocument';

function Documents({ documents, lang, uploadDocument }) {
  const { t } = useTranslation();
  const locale = useLocale();
  const [newDocuments, setNewDocuments] = useState([]);

  const handleAddDocument = useCallback(() => {
    setNewDocuments(prevDocuments => [...prevDocuments, { subcategoryId: '400', file: null }]);
  }, [setNewDocuments]);

  const handleSubcategoryChange = useCallback(
    (index, subcategoryId) => {
      setNewDocuments(prevDocuments => {
        const updatedDocuments = [...prevDocuments];
        updatedDocuments[index].subcategoryId = subcategoryId;
        return updatedDocuments;
      });
    },
    [setNewDocuments]
  );

  const handleFileUpload = useCallback(
    (index, file) => {
      setNewDocuments(prevDocuments => {
        const updatedDocuments = [...prevDocuments];
        updatedDocuments[index].file = file;
        return updatedDocuments;
      });
    },
    [setNewDocuments]
  );

  const handleDeleteDocument = useCallback(
    index => {
      setNewDocuments(prevDocuments => {
        const updatedDocuments = [...prevDocuments];
        updatedDocuments.splice(index, 1);
        return updatedDocuments;
      });
    },
    [setNewDocuments]
  );

  return (
    <FormContainer>
      <FormSection divider="bottom" padding="4 0">
        <Title label={t('menu.myDocuments')} size="large" />
      </FormSection>
      <FormSection style={{ width: '100%' }} padding="4 0">
        <TableContainer>
          <Table size="medium">
            <TableHead>
              <TableRow>
                <TableCell width="20%">{t('label.category')}</TableCell>
                <TableCell>{t('label.fileName')}</TableCell>
                <TableCell align="right">{t('label.documentDate')}</TableCell>
                <TableCell align="right" width="10%"></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {documents.map(({ id, subcategoryId, documentName, activeDate }) => (
                <TableRow key={id}>
                  <TableCell width="20%">{t(`options.subcategories.${subcategoryId}`)}</TableCell>
                  <TableCell>{documentName}</TableCell>
                  <TableCell align="right">
                    {format(new Date(activeDate), 'd MMM yyyy', { locale: locale })}
                  </TableCell>
                  <TableCell align="right" width="10%">
                    <a
                      href={`/api/v1/manager/pdf/file/${id}/revision?timestamp=${new Date().getTime()}`}
                    >
                      <IconButton color="primary" icon="Download" />
                    </a>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
            <TableBody>
              {newDocuments.map(({ subcategoryId, file }, index) => (
                <NewDocument
                  key={index}
                  index={index}
                  file={file}
                  lang={lang}
                  subcategoryId={subcategoryId}
                  handleFileUpload={handleFileUpload}
                  handleSubcategoryChange={handleSubcategoryChange}
                  handleDeleteDocument={handleDeleteDocument}
                  uploadDocument={uploadDocument}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </FormSection>
      <FormSection>
        <FormRow>
          <Button color="primary" variant="text" onClick={handleAddDocument}>
            {`+ ${t('button.addDocument')}`}
          </Button>
        </FormRow>
      </FormSection>
    </FormContainer>
  );
}

Documents.propTypes = {
  documents: PropTypes.array.isRequired,
  lang: PropTypes.string.isRequired,
  uploadDocument: PropTypes.func.isRequired
};

export default Documents;
