import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import OpportunitiesTable from 'components/OpportunitiesTable';
import { Button } from 'components/buttons';
import Link from 'components/Link';

import { Card } from 'components/cards';
import { useTranslation } from 'hooks';
import { Container, HeaderImage, Subtitle } from './style';

function Opportunities({ className, opportunities, onInit, ...rest }) {
  const { t } = useTranslation();
  useEffect(() => {
    onInit();
  }, [onInit]);

  if (!opportunities || !opportunities.length) return null;
  return (
    <Card
      className={className}
      disableGutters={true}
      dividers={false}
      margin="none"
      title={t('title.opportunityManagement')}
      {...rest}
    >
      <Container>
        <HeaderImage>
          <Subtitle>
            {t('title.newOpportunities', {
              count: opportunities.length
            })}
          </Subtitle>
          <Button
            color="primary"
            component={Link}
            label={t('button.updateCV')}
            to="/resume"
            variant="lightContained"
          />
        </HeaderImage>
        <OpportunitiesTable opportunities={opportunities} />
      </Container>
    </Card>
  );
}

Opportunities.propTypes = {
  className: PropTypes.string,
  opportunities: PropTypes.array.isRequired,
  onInit: PropTypes.func.isRequired
};

export default Opportunities;
