import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useForm, useTranslation } from 'hooks';
import { Loader, Section } from 'components/styledComponents';
import { Step1, Step2, Step3, Step4 } from './forms';
import AppDataLoader from 'components/AppDataLoader';
import Dialog from 'components/Dialog';
import { useParams } from 'react-router-dom';
import { Text, Image } from './style';
import enDemo from './images/atreus-portal-en.jpg';
import deDemo from './images/atreus-portal-de.jpg';

const initialValues = {
  birthDate: '',
  mobilePhone1: '',
  primaryEmail: '',
  nationality: '',
  nameAffix: '',
  firstName: '',
  lastName: '',
  title: '',
  salutation: '',
  primaryAddress: {
    street: '',
    city: '',
    country: '',
    postalCode: ''
  },
  industryFocuses: [],
  specialistFocuses: [],
  managementMethods: [],
  newApplicantQuestions: {
    question1: null,
    question2: null,
    question3: null,
    question4: null,
    question5: null,
    question6: null,
    question7: null,
    question8: null
  },
  documents: []
};

function RegistrationPage({
  history,
  language,
  loading,
  syncErrors,
  syncing,
  onSave,
  onStepOneNext,
  isNewUserEmail,
  registerStatus,
  isAutomaticRelease
}) {
  const { t } = useTranslation();
  const { id } = useParams();
  const [currentStep, setCurrentStep] = useState(1);
  const [comingBack, setComingBack] = useState(true);
  const [canGoNext, setCanGoNext] = useState(true);
  const [userID, setUserID] = useState(null);
  const [syncInitiated, setSyncInitiated] = useState(false);
  const [documents, setDocuments] = useState([
    { subcategory: language === '100' ? '400' : '500', file: null }
  ]);
  const { useInput, handleAdd, handleSet, handleSubmit, registrationValidate } = useForm({
    initialValues: useMemo(() => ({ initialValues, language: language }), [language]),
    validationSchema: 'registrationPageSchema',
    onSubmit: onSave
  });
  const { value: languageValue, onChange: onlanguageChange } = useInput('language');

  useEffect(() => {
    handleSet('documents', documents);
  }, [documents]);

  // RTEXT-726
  // fetching base64 encoded User ID from url and decode it
  useEffect(() => {
    let base64EncodedID = id;
    if (base64EncodedID) {
      try {
        let base64DecodedID = window.atob(base64EncodedID);
        if (base64DecodedID) {
          let uid = JSON.parse(base64DecodedID).id;
          uid && setUserID(uid);
        }
      } catch {
        setUserID(null);
      }
    }
    localStorage.setItem('termsAccepted', false);
    localStorage.setItem('privacyAccepted', false);
  }, []);

  useEffect(() => {
    if (syncInitiated && !syncing) {
      !syncErrors && handleClose();
      setSyncInitiated(false);
    }
  }, [handleClose, setSyncInitiated, syncErrors, syncing, syncInitiated]);

  const _next = useCallback(() => {
    setComingBack(false);
    let nextStep = currentStep >= 4 ? 4 : currentStep + 1;
    setCurrentStep(nextStep);
  }, [currentStep]);

  const _prev = useCallback(() => {
    setComingBack(true);
    let nextStep = currentStep <= 1 ? 1 : currentStep - 1;
    setCurrentStep(nextStep);
  }, [currentStep]);

  const handleClose = useCallback(() => history.push('/'), [history]);

  const onStepNext = event => {
    event.persist();
    event.preventDefault();
    // RTEXT-726
    // adding user id in result data set
    handleAdd('userID', userID);
    const isValidated = registrationValidate('stepOneRegisterSchema', onStepOneNext);
    if (isValidated === false) {
      setCanGoNext(false);
    } else {
      setCanGoNext(true);
    }
    if (currentStep > 2) {
      setComingBack(true);
    } else {
      setComingBack(false);
    }
  };

  if (!!canGoNext && isNewUserEmail === 'success') {
    setTimeout(function () {
      if (currentStep === 2 && !comingBack) {
        _next();
      }
      setCanGoNext(false);
    }, 250);
  }

  const handleLanguageChange = useCallback(
    event => {
      onlanguageChange(event);
    },
    [onlanguageChange]
  );

  const automaticReleaseHeader = useCallback(node => {
    if (node) {
      node.innerHTML = t('message.automaticReleaseHeader');
    }
  }, []);

  const automaticReleaseFooter = useCallback(node => {
    if (node) {
      node.innerHTML = t('message.automaticReleaseFooter');
    }
  }, []);

  return (
    <React.Fragment>
      <AppDataLoader />
      <form>
        <Step1 currentStep={currentStep} handleNext={_next} />
        <Step2
          currentStep={currentStep}
          documents={documents}
          loading={loading}
          syncing={syncing}
          useInput={useInput}
          handlePrev={_prev}
          handleLanguageChange={handleLanguageChange}
          onStepNext={onStepNext}
          isNewUserEmail={isNewUserEmail}
          setDocuments={setDocuments}
        />
        <Step3
          currentStep={currentStep}
          handleClose={handleClose}
          loading={loading}
          syncing={syncing}
          useInput={useInput}
          handleNext={_next}
          handlePrev={_prev}
          handleSubmit={handleSubmit}
          handleLanguageChange={handleLanguageChange}
        />
        <Step4
          currentStep={currentStep}
          useInput={useInput}
          handlePrev={_prev}
          loading={loading}
          syncing={syncing}
          handleSubmit={handleSubmit}
        />

        {registerStatus === 'success' && (
          <Dialog
            actionLabel={t('button.ok')}
            disabled={syncing}
            open={true}
            syncing={syncing}
            title={
              isAutomaticRelease
                ? t('label.automaticReleaseSuccess')
                : t('label.manualReleaseSuccess')
            }
            onAction={handleClose}
            onClose={handleClose}
            padding="0px 24px"
          >
            {isAutomaticRelease ? (
              <>
                <Text ref={automaticReleaseHeader}></Text>
                <Image src={language === '200' ? enDemo : deDemo} mt="-14" mb="-19" />
                <Text ref={automaticReleaseFooter}></Text>
              </>
            ) : (
              <h3>{t('message.manualRelease')}</h3>
            )}
          </Dialog>
        )}
      </form>
    </React.Fragment>
  );
}

RegistrationPage.propTypes = {
  className: PropTypes.string,
  history: PropTypes.object.isRequired,
  language: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  syncErrors: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  syncing: PropTypes.bool.isRequired,
  onSave: PropTypes.func.isRequired,
  isAutomaticRelease: PropTypes.bool
};

export default RegistrationPage;
