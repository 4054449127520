import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import Title from 'components/Title';
import { DateField, SelectField, TextField } from 'components/inputs';
import { FormContainer, FormItem, FormRow, FormSection } from 'components/styledComponents';
import { useTranslation } from 'hooks';

function Editor({
  className,
  useInput,
  meta: { mobilityTypes, operationalReadinessTypes },
  ...rest
}) {
  const { t } = useTranslation();
  // RTEXT-628
  let dailyRate = { ...useInput('dailyRateFrom') };
  // !isNaN(dailyRate.value) && (dailyRate.value = Math.round(dailyRate.value));
  let dailyRateTo = { ...useInput('dailyRateTo') };
  // !isNaN(dailyRateTo.value) && (dailyRateTo.value = Math.round(dailyRateTo.value));
  let annualTargetIncome = { ...useInput('annualTargetIncome') };
  //   !isNaN(annualTargetIncome.value) && (annualTargetIncome.value = Math.round(annualTargetIncome.value));
  const { value } = { ...useInput('operationalReadiness') };

  // RTEXT-628
  // validates value of currency type field
  const validateRates = useCallback(rate => {
    if (rate && !rate.value) {
      rate.value = 0;
    } else {
      if (rate && !rate.value.toString().includes('.') && rate.value != 0) {
        rate.value = rate.value.toString().replace(/^0+/, '');
      }
    }
  }, []);

  // RTEXT-628
  validateRates(dailyRate);
  validateRates(dailyRateTo);
  validateRates(annualTargetIncome);

  const isPermanent = useMemo(() => {
    if (
      (value[0] === '100' || value[1] === '100') &&
      (value[0] === '200' || value[1] === '200') &&
      value.length === 2
    ) {
      return true;
    } else if (value.length < 2) {
      return false;
    }
  }, [value]);

  return (
    <FormContainer className={className} {...rest}>
      <FormSection divider="bottom" padding="0 0 2">
        <FormRow>
          <FormItem component={Title} label={t('title.earliestAvailability')} size="large" />
        </FormRow>
        <FormRow>
          <FormItem
            component={DateField}
            disablePast={true}
            label={t('label.availableFrom')}
            required
            {...useInput('availableFrom')}
          />
        </FormRow>
        <FormRow>
          <FormItem
            component={TextField}
            label={t('label.availabilityComment')}
            multiline
            rows="4"
            {...useInput('availableFromRemarks')}
          />
        </FormRow>
        <FormRow>
          <FormItem
            component={SelectField}
            isMulti
            label={t('label.operationalReadiness')}
            options={operationalReadinessTypes}
            required
            {...useInput('operationalReadiness')}
          />
        </FormRow>
      </FormSection>
      <FormSection divider="bottom" padding="2 0">
        <FormRow>
          <FormItem
            component={Title}
            label={t('title.dailyRate')}
            size="large"
            subLabel={t('title.dailyRateSubTitle')}
          />
        </FormRow>
        <FormRow>
          <FormItem
            component={TextField}
            label={t('label.dailyRateFrom')}
            required
            {...dailyRate}
          />
          <FormItem
            component={TextField}
            label={t('label.dailyRateTo')}
            required
            {...dailyRateTo}
          />
        </FormRow>
      </FormSection>
      <FormSection divider="bottom" padding="2 0">
        <FormRow>
          <FormItem component={Title} label={t('label.currencyTargetIncomeTitle')} size="large" />
        </FormRow>
        <FormRow>
          <FormItem
            component={TextField}
            label={t('label.currencyTargetIncome')}
            {...annualTargetIncome}
          />
        </FormRow>
      </FormSection>
      <FormSection padding="2 0 0">
        <FormRow>
          <FormItem component={Title} label={t('title.mobility')} size="large" />
        </FormRow>
        <FormRow>
          <FormItem
            component={SelectField}
            label={t('label.mobility')}
            options={mobilityTypes}
            required
            {...useInput('mobility')}
          />
        </FormRow>
        <FormRow>
          <FormItem
            component={TextField}
            label={t('label.mobilityComment')}
            multiline
            rows="4"
            {...useInput('mobilityRemarks')}
          />
        </FormRow>
      </FormSection>
    </FormContainer>
  );
}

Editor.propTypes = {
  className: PropTypes.string,
  useInput: PropTypes.func.isRequired,
  meta: PropTypes.object.isRequired
};

export default Editor;
