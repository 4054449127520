import React from 'react';
import PropTypes from 'prop-types';
import { Label, StyledSwitch, SwitchBox, SwitchHandle, WrappingLabel } from './style';

function Switch({ checked, className, disabled, leftLabel, rightLabel, size, onChange }) {
  return (
    <WrappingLabel className={className} disabled={disabled} size={size}>
      <StyledSwitch checked={checked} disabled={disabled} onChange={onChange} />
      {leftLabel && <Label>{leftLabel}</Label>}
      <SwitchBox>
        <SwitchHandle />
      </SwitchBox>
      {rightLabel && <Label checked={checked}> {rightLabel}</Label>}
    </WrappingLabel>
  );
}

Switch.defaultProps = {
  size: 'medium'
};

Switch.displayName = 'Switch';

Switch.propTypes = {
  checked: PropTypes.bool,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  leftLabel: PropTypes.string,
  rightLabel: PropTypes.string,
  size: PropTypes.oneOf(['medium']).isRequired,
  onChange: PropTypes.func.isRequired
};

export default Switch;
