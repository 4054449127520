import { connect } from 'react-redux';
import BillingTab from './BillingTab';
import * as uiStateSelectors from 'redux-modules/uiState/selectors';
import * as billingStateSelectors from 'redux-modules/billing/selectors';
import * as billingActions from 'redux-modules/billing/actions';

function mapStateToProps(state) {
  return {
    language: uiStateSelectors.selectLanguage(state),
    syncing: billingStateSelectors.selectSyncState(state),
    html: billingStateSelectors.selectHtmlState(state),
    isVerified: billingStateSelectors.selectVerifiedState(state)
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onVerify: (data, failCallback) => dispatch(billingActions.verify(data, failCallback)),
    onSave: (data, failCallback) => dispatch(billingActions.save(data, failCallback)),
    reset: () => dispatch(billingActions.reset())
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(BillingTab);
