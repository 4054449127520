import { useEffect } from 'react';
import PropTypes from 'prop-types';

function AppDataLoader({ onInit }) {
  useEffect(() => {
    onInit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
}

AppDataLoader.propTypes = {
  onInit: PropTypes.func.isRequired
};

export default AppDataLoader;
