import { defaultTheme } from 'themes';

export const statuses = {
  applied: {
    backgroundColor: defaultTheme.color.success.main,
    label: 'label.applied',
    textColor: defaultTheme.color.success.contrast
  },
  atreusPermanentPosition: {
    backgroundColor: defaultTheme.color.secondary.main,
    label: 'label.atreusPermanentPosition',
    textColor: defaultTheme.color.secondary.contrast
  },
  default: { backgroundColor: 'initial', label: '', textColor: 'initial' },
  loading: {
    backgroundColor: defaultTheme.color.grey[700],
    label: 'label.loading',
    textColor: defaultTheme.color.primary.contrast
  },
  lost: {
    backgroundColor: defaultTheme.color.danger.main,
    label: 'label.lost',
    textColor: defaultTheme.color.danger.contrast
  },
  closed: {
    backgroundColor: defaultTheme.color.danger.main,
    label: 'label.closedd',
    textColor: defaultTheme.color.danger.contrast
  },
  mandateClosed: {
    backgroundColor: defaultTheme.color.grey[400],
    label: 'label.mandateClosed',
    textColor: 'initial'
  },
  notificationReceived: {
    backgroundColor: defaultTheme.color.danger.main,
    label: 'label.notificationReceived',
    textColor: defaultTheme.color.danger.contrast
  },
  presented: {
    backgroundColor: defaultTheme.color.success.main,
    label: 'label.presented',
    textColor: defaultTheme.color.success.contrast
  },
  presentation: {
    backgroundColor: defaultTheme.color.success.main,
    label: 'label.presentation',
    textColor: defaultTheme.color.success.contrast
  },
  provisionalMandate: {
    backgroundColor: defaultTheme.color.primary.main,
    label: 'label.provisionalMandate',
    textColor: defaultTheme.color.primary.contrast
  },
  mandateInPrepration: {
    backgroundColor: defaultTheme.color.success.main,
    label: 'label.mandateInPrepration',
    textColor: defaultTheme.color.primary.contrast
  },
  requested: {
    backgroundColor: defaultTheme.color.success.main,
    label: 'label.requested',
    textColor: defaultTheme.color.success.contrast
  },
  new: {
    backgroundColor: defaultTheme.color.success.main,
    label: 'label.new',
    textColor: defaultTheme.color.success.contrast
  },
  won: {
    backgroundColor: defaultTheme.color.success.main,
    label: 'label.won',
    textColor: defaultTheme.color.success.contrast
  }
};
