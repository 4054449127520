import styled from 'styled-components';
import { IconButton } from 'components/buttons';
import { FormContainer } from 'components/styledComponents';

export const DeleteButton = styled(IconButton)`
  transform: translateX(${props => props.theme.spacing(1)});
`;

export const RoleWrapper = styled.div`
  align-items: center;
  border-bottom: 1px solid ${props => props.theme.color.grey[400]};
  box-sizing: border-box;
  display: flex;

  ${FormContainer} {
    flex-grow: 1;
  }
`;
