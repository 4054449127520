import { connect } from 'react-redux';
import * as resumeSelectors from 'redux-modules/resume/selectors';
import Resume from './Resume';

function mapStateToProps(state) {
  return {
    loading: resumeSelectors.selectLoadState(state),
    resume: resumeSelectors.selectResume(state)
  };
}

// empty mapDispatchToProps needed in order to avoid sending dispatch as prop
function mapDispatchToProps() {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(Resume);
