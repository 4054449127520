import React from 'react';
import PropTypes from 'prop-types';
import { SelectField } from 'components/inputs';
import { FormContainer, FormItem, FormRow } from 'components/styledComponents';
import { useTranslation } from 'hooks';
import { DeleteButton, LanguageWrapper } from './style';

function Form({
  basePath,
  className,
  filterOption,
  meta: { languageLevels, languages },
  useInput,
  onDelete
}) {
  const { tr } = useTranslation();

  return (
    <LanguageWrapper>
      <FormContainer className={className}>
        <FormRow>
          <FormItem
            component={SelectField}
            filterOption={filterOption}
            label={tr('label.language')}
            options={languages}
            {...useInput(`${basePath}.languageCode`)}
          />
          <FormItem
            component={SelectField}
            label={tr('label.level')}
            options={languageLevels}
            {...useInput(`${basePath}.languageLevelCode`)}
          />
        </FormRow>
      </FormContainer>
      <DeleteButton color="danger" icon="Trash" onClick={() => onDelete(basePath)} />
    </LanguageWrapper>
  );
}

Form.propTypes = {
  basePath: PropTypes.string.isRequired,
  className: PropTypes.string,
  filterOption: PropTypes.func.isRequired,
  meta: PropTypes.shape({
    languageLevels: PropTypes.array.isRequired,
    languages: PropTypes.array.isRequired
  }).isRequired,
  useInput: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired
};

export default Form;
