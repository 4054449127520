import { connect } from 'react-redux';
import * as metaSelectors from 'redux-modules/meta/selectors';
import * as resumesActions from 'redux-modules/resumes/actions';
import * as resumesSelectors from 'redux-modules/resumes/selectors';
import CandidateCV from 'components/ProjectCandidateCV';

function mapStateToProps(state, ownProps) {
  return {
    cv: resumesSelectors.selectResume(state, ownProps.id),
    indexedMeta: metaSelectors.selectIndexedMeta(state),
    loading: resumesSelectors.selectSyncState(state) || !metaSelectors.selectLoadedState(state)
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    onInit: () => {
      dispatch(resumesActions.loadMngCV(ownProps.id));
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CandidateCV);
