import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { MenuContainer, Link } from './style';

function Menu({ onChange, items }) {
  const [selectedIndex, setSelectedIndex] = useState(0);

  const handleItemChanged = newIndex => {
    setSelectedIndex(newIndex);
    if (onChange) {
      onChange(newIndex);
    }
  };
  return (
    <MenuContainer selectedIndex={selectedIndex}>
      {items &&
        items.map((item, index) => (
          <Link
            key={index}
            selected={selectedIndex === index}
            to=""
            variant="h6"
            onClick={handleItemChanged.bind(this, index)}
          >
            {item}
          </Link>
        ))}
    </MenuContainer>
  );
}

Menu.propTypes = {
  items: PropTypes.array.isRequired,
  onChange: PropTypes.func
};

export default Menu;
