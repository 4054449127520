import React from 'react';
import PropTypes from 'prop-types';
import { Subtitle, Container, Content } from './style';

function SectionBars({ header, title, children }) {
  return (
    <Container>
      <Subtitle>{header ? header : <h3>{title}</h3>}</Subtitle>
      <Content>{children}</Content>
    </Container>
  );
}

SectionBars.defaultProps = {
  title: '',
  children: null
};

SectionBars.propTypes = {
  header: PropTypes.object,
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.node, PropTypes.object]),
  title: PropTypes.string.isRequired
};

export default SectionBars;
