import styled from 'styled-components';

export const LangSelector = styled.div`
  align-items: center;
  background: ${props =>
    props.selected ? props.theme.color.background.default : props.theme.color.background.surface};
  box-sizing: border-box;
  ${props => props.selected && 'color:' + props.theme.color.primary.main};
  cursor: pointer;
  display: flex;
  padding: 0.5em 1em;
  text-transform: uppercase;
`;

export const SelectorContainer = styled.div`
  align-self: stretch;
  display: flex;
  flex-shrink: 0;
  overflow: hidden;
`;
