import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import Container from '@material-ui/core/Container';
import { useForm, useTranslation } from 'hooks';
import { Loader, Section } from 'components/styledComponents';
import { Notification } from './forms';
import { Button, FooterActions } from './style';

function MyNotificationSettings({
  history,
  language,
  loading,
  notificationSettings,
  syncErrors,
  syncing,
  onSave
}) {
  const { t } = useTranslation();
  const [syncInitiated, setSyncInitiated] = useState(false);
  const handleClose = useCallback(() => history.push('/'), [history]);
  const handleSave = useCallback(
    data => {
      onSave(data);
      setSyncInitiated(true);
    },
    [onSave, setSyncInitiated]
  );

  const { useInput, handleSubmit } = useForm({
    initialValues: useMemo(() => (notificationSettings), [
      notificationSettings
    ]),
    onSubmit: handleSave
  });

  useEffect(() => {
    if (syncInitiated && !syncing) {
      !syncErrors && handleClose();
      setSyncInitiated(false);
    }
  }, [handleClose, setSyncInitiated, syncErrors, syncing, syncInitiated]);

  return (
    <React.Fragment>
      <Section>
        <Loader component={Container} loading={loading || syncing}>
          <Notification useInput={useInput} />
        </Loader>
      </Section>
      <Section>
        <Loader component={Container} loading={loading || syncing}>
          <FooterActions>
            <Button label={t('button.cancel')} variant="text" onClick={handleClose} />
            <Button
              color="primary"
              disabled={loading || syncing}
              label={t('button.save')}
              onClick={handleSubmit}
            />
          </FooterActions>
        </Loader>
      </Section>
    </React.Fragment>
  );
}

MyNotificationSettings.propTypes = {
  className: PropTypes.string,
  history: PropTypes.object.isRequired,
  language: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  notificationSettings: PropTypes.object,
  syncErrors: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  syncing: PropTypes.bool.isRequired,
  onSave: PropTypes.func.isRequired
};

export default MyNotificationSettings;
