import { connect } from 'react-redux';
import * as uiStateSelectors from 'redux-modules/uiState/selectors';
import LanguageSelector from './LanguageSelector';

function mapStateToProps(state) {
  return {
    languages: uiStateSelectors.selectAppLanguages(state)
  };
}

export default connect(mapStateToProps)(LanguageSelector);
