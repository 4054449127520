import React from 'react';
import PropTypes from 'prop-types';
import {
  ProjectStatusTitle,
  MandateRunningContainer,
  RunningCandidateContainer,
  PointsContainer,
  Point,
  ProjectCardContainer,
  ProjectCard,
  CandidateEmptyRowPlaceholder
} from './style';
import CandidateDetailedCard from './CandidateDetailedCard';
import useMediaQuery from '@material-ui/core/useMediaQuery';

function MandateRunning({ project }) {
  const bigScreen = useMediaQuery('@media (min-width: 1300px)');

  var candidate = null;
  project.candidates.map(item => {
    if (item.isChosen) {
      candidate = item;
    }
    return item;
  });
  return (
    <div>
      <ProjectStatusTitle>Elected Candidate:</ProjectStatusTitle>
      <MandateRunningContainer>
        <RunningCandidateContainer className="candidate-card">
          <CandidateDetailedCard candidate={candidate} short />
        </RunningCandidateContainer>
        {bigScreen && (
          <PointsContainer>
            <Point color="#D8D8D8" size="4" />
            <Point color="#D8D8D8" size="4" />
            <Point color="#D8D8D8" size="4" />
            <Point color="#00A1E9" size="18" />
            <Point color="#50C2F6" size="12" />
            <Point color="#C6E3F0" size="8" />
            <Point color="#D8D8D8" size="4" />
            <Point color="#D8D8D8" size="4" />
          </PointsContainer>
        )}
        {bigScreen && (
          <ProjectCardContainer className="candidate-card">
            <ProjectCard>
              <img alt="logo" src={project.logo} />
              <div className="emptyContainer">
                <CandidateEmptyRowPlaceholder longest />
                <CandidateEmptyRowPlaceholder long />
                <CandidateEmptyRowPlaceholder long />
              </div>
            </ProjectCard>
          </ProjectCardContainer>
        )}
      </MandateRunningContainer>
    </div>
  );
}

MandateRunning.propTypes = {
  project: PropTypes.object.isRequired
};

export default MandateRunning;
