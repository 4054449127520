import React from 'react';
import PropTypes from 'prop-types';
import { SelectField, TextField } from 'components/inputs';
import { FormContainer, FormItem, FormRow } from 'components/styledComponents';
import { useTranslation } from 'hooks';
import { DeleteButton, WorkWrapper } from './style';

function Form({ basePath, meta: { countries, workAbroadAssignmentTypes }, useInput, onDelete }) {
  const { tr } = useTranslation();
  let monthsSpent = { ...useInput(`${basePath}.monthsSpent`) };
  !isNaN(monthsSpent.value) && (monthsSpent.value = Math.round(monthsSpent.value));

  return (
    <WorkWrapper>
      <FormContainer as="div">
        <FormRow>
          <FormItem
            component={SelectField}
            label={tr('label.country')}
            options={countries}
            required
            {...useInput(`${basePath}.country`)}
          />
          <FormItem
            component={TextField}
            label={tr('label.monthsSpent')}
            required
            {...monthsSpent}
          />
        </FormRow>
        <FormRow>
          <FormItem
            component={SelectField}
            label={tr('label.abroadAssignmentType')}
            options={workAbroadAssignmentTypes}
            required
            {...useInput(`${basePath}.assignmentType`)}
          />
        </FormRow>
      </FormContainer>
      <DeleteButton color="danger" icon="Trash" onClick={onDelete} />
    </WorkWrapper>
  );
}

Form.propTypes = {
  basePath: PropTypes.string.isRequired,
  meta: PropTypes.shape({
    countries: PropTypes.array.isRequired,
    workAbroadAssignmentTypes: PropTypes.array.isRequired
  }).isRequired,
  useInput: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired
};

export default Form;
