function getListItemSchema(name) {
  let schema = {
    type: 'array',
    items: {
      type: ['object', 'null'],
      properties: {
        type: {
          type: 'string',
          allOf: [{ transform: ['trim'] }, { minLength: 1 }]
        },
        value: { $ref: `defs.json#/definitions/${name}` }
      },
      required: ['type', 'value']
    }
  };
  // RTEXT-856
  if (name === 'socialNetworks') {
    schema['items']['allOf'] = [
      {
        if: {
          properties: {
            type: { minLength: 1 }
          }
        },
        then: {
          required: ['value']
        }
      },
      {
        if: {
          properties: {
            value: { minLength: 1 }
          }
        },
        then: {
          required: ['type']
        }
      }
    ];
    schema['items']['required'] = []; 
  }
  return schema;
}

export const accountDetailsSchema = {
  type: 'object',
  properties: {
    birthDate: { $ref: 'defs.json#/definitions/birthDate' },
    emails: getListItemSchema('email'),
    firstName: {
      allOf: [{ $ref: 'defs.json#/definitions/name' }, { minLength: 1 }]
    },
    language: { $ref: 'defs.json#/definitions/entityIdRequired' },
    lastName: {
      allOf: [{ $ref: 'defs.json#/definitions/name' }, { minLength: 1 }]
    },
    nationality: { $ref: 'defs.json#/definitions/manager_nationality', default: [] },
    ownCompany: { $ref: 'defs.json#/definitions/companyName' },
    phones: getListItemSchema('phoneNumber'),
    primaryAddress: { $ref: 'defs.json#/definitions/address' },
    profileVideo: { $ref: 'defs.json#/definitions/webLink' },
    salutation: { $ref: 'defs.json#/definitions/entityIdRequired' },
    skypeId: { $ref: 'defs.json#/definitions/socialIdentifier' },
    socialNetworks: getListItemSchema('socialNetworks'),
    title: { $ref: 'defs.json#/definitions/entityId', default: '' },
    website: { $ref: 'defs.json#/definitions/webLink' }
  },
  required: [
    'emails',
    'firstName',
    'language',
    'lastName',
    'phones',
    'primaryAddress',
    'salutation',
    'socialNetworks'
  ]
};

export const registrationPageSchema = {
  type: 'object',
  properties: {
    firstName: {
      allOf: [{ $ref: 'defs.json#/definitions/name' }, { minLength: 1 }]
    },
    language: { $ref: 'defs.json#/definitions/entityIdRequired' },
    lastName: {
      allOf: [{ $ref: 'defs.json#/definitions/name' }, { minLength: 1 }]
    },
    nationality: { $ref: 'defs.json#/definitions/manager_nationality', default: [] },
    mobilePhone1: { $ref: `defs.json#/definitions/phoneNumber` },
    salutation: { $ref: 'defs.json#/definitions/entityIdRequired' },
    title: { $ref: 'defs.json#/definitions/entityId', default: '' }
  },
  required: ['firstName', 'language', 'lastName', 'mobilePhone1', 'salutation']
};

export const stepOneRegisterSchema = {
  type: 'object',
  properties: {
    primaryEmail: { $ref: `defs.json#/definitions/email` },
    firstName: {
      allOf: [{ $ref: 'defs.json#/definitions/name' }, { minLength: 1 }]
    },
    language: { $ref: 'defs.json#/definitions/entityIdRequired' },
    documents: { $ref: 'defs.json#/definitions/documents' },
    lastName: {
      allOf: [{ $ref: 'defs.json#/definitions/name' }, { minLength: 1 }]
    },
    nationality: { $ref: 'defs.json#/definitions/manager_nationality', default: [] },
    mobilePhone1: { $ref: `defs.json#/definitions/phoneNumber` },
    primaryAddress: { $ref: 'defs.json#/definitions/address', default: { country: '' } },
    salutation: { $ref: 'defs.json#/definitions/entityIdRequired' },
    title: { $ref: 'defs.json#/definitions/entityId', default: '' }
  },
  required: [
    'firstName',
    'language',
    'documents',
    'lastName',
    'mobilePhone1',
    'primaryEmail',
    'salutation',
    'primaryAddress'
  ]
};
