import styled from 'styled-components';
import { Button as BaseButton } from 'components/buttons';

export const AttachmentContainer = styled.div`
  position: relative;
`;

export const FileUploadComponent = styled.input`
  position: absolute;
  top: 0px;
  left: 0px;
  max-width: 100%;
  width: 100%;
  height: 55px;
  opacity: 0;
`;

export const UploadButton = styled(BaseButton)`
  width: 100%;
  margin-bottom: 5px;
`;

export const List = styled.ol`
  margin-top: ${props => (props.mt ? props.mt + 'px' : '0px')};
  padding-inline-start: 20px;
`;

export const ListItem = styled.li`
  margin-top: ${props => (props.mt ? props.mt + 'px' : '0px')};
  padding-left: 10px;
  color: ${props => props.theme.color.primary.main};
  font-weight: ${props =>
    props.bold ? props.theme.fontWeight.bold : props.theme.fontWeight.normal};
`;
