import React from 'react';
import Title from 'components/Title';
import { FormContainer, FormRow, FormSection } from 'components/styledComponents';
import {
  Section,
  Footer,
  Button,
  MuiContainer,
  LinkButton,
  FlexContainer
} from 'components/pages/RegistrationPage/style';
import { useTranslation } from 'hooks';
import PropTypes from 'prop-types';

function Step1({ currentStep, handleNext }) {
  const { t } = useTranslation();

  if (currentStep !== 1) {
    return null;
  }

  return (
    <>
      <style>.registration-form-page{'{max-width: 100%; width: 930px}'}</style>
      <Section>
        <MuiContainer>
          <FormContainer>
            <FormSection divider="bottom" padding="4 0">
              <FlexContainer>
                <Title label={t('label.welcomeVideo')} size="large" color="primary" />
                <LinkButton href="https://www.atreus.de/">{t('link.atreusDE')}</LinkButton>
              </FlexContainer>
            </FormSection>
            <FormSection divider="top" padding="8 2">
              <FormRow>
                <img
                  alt="5 Steps to be part of Atreus Network"
                  src="https://assets-eur.mkt.dynamics.com/dcec06c1-768b-479b-b827-91b3ebf8682b/digitalassets/images/8bbea1a9-393c-ee11-bdf4-0022489931f5?ts=638277897025257146"
                  width="100%"
                />
              </FormRow>
            </FormSection>
          </FormContainer>
          <Footer>
            <Button color="primary" label={t('button.next')} onClick={handleNext} />
          </Footer>
        </MuiContainer>
      </Section>
    </>
  );
}

Step1.propTypes = {
  currentStep: PropTypes.number,
  handleNext: PropTypes.func.isRequired
};

export default Step1;
