import React from 'react';
import createSvgIcon from '../createSvgIcon';

export default createSvgIcon(
  <React.Fragment>
    <path
      d="M12.7155418,1.43108351 L23.4211146,22.8422291 C23.618706,23.2374119 23.4585265,23.7179504 23.0633437,23.9155418 C22.9522593,23.9710839 22.8297689,24 22.7055728,24 L1.29442719,24 C0.852599391,24 0.494427191,23.6418278 0.494427191,23.2 C0.494427191,23.0758039 0.523343264,22.9533135 0.578885438,22.8422291 L11.2844582,1.43108351 C11.4820496,1.03590071 11.9625881,0.87572123 12.3577709,1.07331263 C12.5125928,1.15072359 12.6381308,1.27626158 12.7155418,1.43108351 Z"
      fill="none"
      stroke="currentcolor"
    />
    <circle cx="12" cy="20" r="1" />
    <line stroke="currentcolor" x1="12" x2="12" y1="8.06448102" y2="16.6975098" />
  </React.Fragment>,
  'Warning'
);
