import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import CompanyGroup from './CompanyGroup';
import Link from 'components/Link';
import { IconButton } from 'components/buttons';
import {useTranslation} from "hooks";
import {ButtonWrapper, Button, Label, ToggleIcon, ExpandCollapseContainer} from "components/Resume/style";
import { ArrowLeft, ArrowRight } from 'components/icons';
import {filter, each} from 'lodash';

// @TODO add memoization, cleanup filtering and remove data alteration
function Details({ editPath, indexedMeta, language, workExperience, expandCollapse, handleCollapseExpand }) {
  const { t, tr } = useTranslation();
  let filteredWork = workExperience.sort(function (a, b) {
    return new Date(b.dateFrom).getTime() - new Date(a.dateFrom).getTime();
  });
  let result = filteredWork.reduce(function (r, a) {
    let index = r.findIndex((v, i) => {
      return v.companyGroupName === a.companyGroupName;
    });
    var companyGroup = {};
    if (index < 0) {
      companyGroup = {
        companyGroupName: a.companyGroupName,
        companyGroupId: a.companyGroupId,
        companyGroupCountry: a.companyGroupCountry,
        companies: [],
        dateFrom: a.dateFrom,
        isActualEngagement: a.dateTo == "" ?  true : a.isActualEngagement,
        industry: a.industry,
        dateTo: a.dateTo == '' ? '' : a.dateTo
      };
      r.push(companyGroup);
    } else {
      companyGroup = r[index];
      if (a.dateFrom < companyGroup.dateFrom) companyGroup.dateFrom = a.dateFrom;
      if(a.dateTo == "") {
        companyGroup.dateTo = "";
        companyGroup.isActualEngagement = true;
      }
      else if (a.dateTo > companyGroup.dateTo) companyGroup.dateTo = a.dateTo;
    }
    let cindex = companyGroup.companies.findIndex((v, i) => {
      // commented in RTEXT-693
      // removed grouping of companies by name
      // grouped them by unique ids so that experiences get not merged under companies with same name
      // return v.companyName === a.companyName;
      return v.compId === a.companyId;
    });
    if (cindex < 0) {
      companyGroup.companies.push({
        companyName: a.companyName,
        compId: a.companyId,
        experiences: [a],
        dateFrom: a.dateFrom,
        isActualEngagement: a.dateTo == "" ? true : a.isActualEngagement,
        industry: a.industry,
        dateTo: a.dateTo == '' ? '' : a.dateTo,
        city: a.city,
        companyCountry: a.country,
        industryDescription: a.industryDescription,
        ownershipStructure: a.ownershipStructure,
        companyEmployees: a.companyEmployees,
        companyTurnover: a.companyTurnover
      });
    } else {
      var obj = companyGroup.companies[cindex];
      obj.experiences.push(a);
      if (a.dateFrom < obj.dateFrom) obj.dateFrom = a.dateFrom;
      if(a.dateTo == "") {
        obj.dateTo = "";
        obj.isActualEngagement = true;
      }
      else if (a.dateTo > obj.dateTo) obj.dateTo = a.dateTo;
    }
    return r;
  }, []);

  function getLabelData(isExpanded) {
    return [isExpanded ? 'button.hideAllDetails' : 'button.showAllDetails'];
  }

let nonGroupedComp = [];
  each(result, function(group, key){
    if(group.companyGroupName === '') {
      nonGroupedComp = group.companies;
    }
  });

  result = filter(result, function(val) { return val.companyGroupName !== ''; });


  each(nonGroupedComp, function (comp) {
    let companyTemplate = {
      companyGroupName: '',
      companyGroupId: '',
      companyGroupCountry: '',
      companies: [],
      dateFrom: comp.dateFrom,
      isActualEngagement: comp.dateTo == '' ? true : comp.isActualEngagement,
      industry: comp.industry,
      dateTo: comp.dateTo == '' ? '' : comp.dateTo
    };
    companyTemplate.companies.push(comp);
    result.push(companyTemplate);
  });


  each(result, function (gVal, gKey) {
    each(gVal.companies, function (cVal, cKey) {
      each(cVal.experiences, function (eVal, eKey) {
        if(eVal.dateTo == '') {
          result[gKey]['dateTo'] = '';
          result[gKey]['isActualEngagement'] = true;

          result[gKey]['companies'][cKey]['dateTo'] = '';
          result[gKey]['companies'][cKey]['isActualEngagement'] = true;
        }
      });
    });
  });



  result.sort(function(a, b) {
    var keyA = new Date(a.dateTo),
        keyB = new Date(b.dateTo);
    if(a.dateTo == '') return -1;
    if(b.dateTo == '') return 1;
    if (keyA > keyB) return -1;
    if (keyA < keyB) return 1;
    return 0;
  });

  //Sort based on start date if end dates are equal
  result.sort(function(a, b) {
    var keyC = new Date(a.dateFrom),
        keyD = new Date(b.dateFrom);

    if (a.dateTo == b.dateTo && keyC > keyD) {
      return -1;
    }
    if (a.dateTo == b.dateTo && keyC < keyD) {
      return 1;
    }
    return 0;
  });

//now again sort the sorted array for start date for open ended end date
  result.sort(function(a, b) {

    var keyC = new Date(a.dateFrom),
        keyD = new Date(b.dateFrom);

    if (a.dateTo == '' && keyC > keyD) {
      return -1;
    }
    if(b.dateTo == '' && keyC < keyD) {
      return 1;
    }
    return 0;
  });

  return (
    <React.Fragment>
      <ExpandCollapseContainer>
        {<Button onClick={handleCollapseExpand}>
          <Label>{tr(...getLabelData(expandCollapse))}</Label>
          <ToggleIcon as={expandCollapse ? ArrowRight : ArrowLeft} />
        </Button>}
      </ExpandCollapseContainer>

      {result.map((item, index) => (
        <CompanyGroup
          data={item}
          editPath={editPath}
          indexedMeta={indexedMeta}
          key={index}
          language={language}
          expandCollapse={expandCollapse}
        />
      ))}
      <ButtonWrapper>
        <div>
          <Link to={editPath}> <span>{tr('title.addWorkExperience')}</span> </Link>
        </div>
        <IconButton className="wexp-add-bottom" component={Link} icon="Plus" to={editPath} />
      </ButtonWrapper>
    </React.Fragment>
  );
}

Details.propTypes = {
  editPath: PropTypes.string,
  indexedMeta: PropTypes.object.isRequired,
  language: PropTypes.string.isRequired,
  workExperience: PropTypes.array.isRequired
};

export default Details;
