import * as types from './types';

export function failProjectsLoad(error) {
  return {
    type: types.PROJECTS_FAILED
  };
}

export function loadProjects() {
  return {
    type: types.LOAD_PROJECTS
  };
}

export function setLoadedProjects(projects) {
  return {
    payload: {
      projects
    },
    type: types.PROJECTS_LOADED
  };
}
