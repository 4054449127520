import { connect } from 'react-redux';
import * as authActions from 'redux-modules/auth/actions';
import * as authSelectors from 'redux-modules/auth/selectors';
import ForgotPasswordPage from './ForgotPasswordPage';

function mapStateToProps(state) {
  return {
    syncing: authSelectors.selectSyncState(state),
    synkError: authSelectors.selectSyncError(state),
    resetPwdStatus: authSelectors.selectResetPwdStatus(state)
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onInit: () => dispatch(authActions.resetResetPasswordStatus()),
    onResetPassword: (data, failCallback) => dispatch(authActions.resetPassword(data, failCallback))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPasswordPage);
