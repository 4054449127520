import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Container from '@material-ui/core/Container';
import { Content, Header, Title } from './style';
import ProjectView from './ProjectView';
import Menu from './Menu';

function ProjectsPage({ className, history, projectData, syncErrors, syncing, onInit }) {
  useEffect(() => {
    onInit();
  }, [onInit]);

  const menuItems = ['Current projects', 'Past projects', 'Other projects', 'Some other item'];
  const [syncInitiated, setSyncInitiated] = useState(false);
  const [selectedIndex] = useState(0);
  const handleClose = useCallback(() => history.push('/resume'), [history]);
  const projects = projectData ? projectData.projects : [];

  useEffect(() => {
    if (syncInitiated && !syncing) {
      !syncErrors && handleClose();
      setSyncInitiated(false);
    }
  }, [handleClose, setSyncInitiated, syncErrors, syncing, syncInitiated]);
  return (
    <React.Fragment>
      <Header>
        <Container className={className}>
          <Title>Your Projects</Title>
        </Container>
      </Header>
      <Content>
        <Container className={className}>
          <Menu items={menuItems} selectedIndex={selectedIndex} />
          {projects && projects.map((item, index) => <ProjectView key={item.id} project={item} />)}
        </Container>
      </Content>
    </React.Fragment>
  );
}

ProjectsPage.propTypes = {
  className: PropTypes.string,
  history: PropTypes.object.isRequired,
  onInit: PropTypes.func,
  personalDetails: PropTypes.object,
  projectData: PropTypes.object,
  syncErrors: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  syncing: PropTypes.bool
};

export default ProjectsPage;
