import React from 'react';
import PropTypes from 'prop-types';
import { Container, Content, Label } from './style';

function Item({ className, content, label, weight }) {
  return (
    <Container className={className}>
      <Label weight={weight}>{label}</Label>
      {Array.isArray(content) ? (
        content.map((item, index) => (
          <Content key={index} title={item}>
            {item || '-'}
          </Content>
        ))
      ) : (
        <Content title={content}>
          {content || '-'}
        </Content>
      )}
    </Container>
  );
}

Item.defaultProps = {
  weight: 'bold'
};

Item.propTypes = {
  className: PropTypes.string,
  content: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  label: PropTypes.string,
  weight: PropTypes.oneOf(['bold', 'normal', 'light']).isRequired
};

export default Item;
