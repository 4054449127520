import styled from 'styled-components';
import { IconButton } from 'components/buttons';
import { SelectField } from 'components/inputs';
import { FormRow, FormItem } from 'components/styledComponents';

export const FixedSection = styled.div`
  display: flex;
  flex-wrap: nowrap;
  flex-grow: 1;
`;

export const DeleteButton = styled(IconButton)`
  /**
   * @TODO find a better way for spacing
   * https://jira.atreus.de/browse/MILK-643
   */
  align-self: flex-start;
  margin: ${props => props.theme.spacing(0.875, 0, 0.875, 1)};
  flex-grow: 0;
`;

export const TypeSelector = styled(SelectField)`
  flex-grow: 1;
  min-width: 200px;
`;

export const StyledFormRow = styled(FormRow)`
  ${props =>
    props.rowReverse
      ? `
  flex-direction: row-reverse !important;
  margin-bottom: 15px;
    `
      : null}
`;

export const StyledFormItem = styled(FormItem)`
  margin: 0px !important;
  &:after {
    color: ${props => props.theme.color.primary.main};
    content: ${props => (props.content ? `'${props.content}'` : '')};
    font-size: small;
    position: absolute;
    bottom: -20px;
    left: 5px;
  }
`;

export const FormItemWrapper = styled.div`
  display: flex;
  padding: 12px 8px;
`;
