import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Snackbar from 'components/Snackbar';
import { Button } from 'components/buttons';
import { useForm, useTranslation } from 'hooks';
import useStyles from './../style';
import { Message, LargeLink, Title, Subtext, Text } from './../style';
import { FormContainer, FormItem, FormRow } from 'components/styledComponents';
import { TextField } from 'components/inputs';

function UpdatePasswordPage({
  isResetPasswordTokenValid,
  mode,
  syncing,
  token,
  onInit,
  onUpdatePassword,
  resetPwdStatus,
  onRequestNewLink,
  isWelcomeEmailResent
}) {
  const { t } = useTranslation();
  const [errors, setErrors] = useState({});
  const classes = useStyles();
  const initialValues = useMemo(() => ({ token }), [token]);
  const { handleSubmit, useInput } = useForm({
    initialValues,
    onSubmit: onUpdatePassword,
    validationSchema: 'updatePasswordShema'
  });
  const passInputVal = useInput('password');
  const confirmPassInputVal = useInput('confirmPassword');

  const [status, message] = useMemo(() => {
    var status = null;
    var message = null;
    if (isResetPasswordTokenValid === false) {
      status = mode === 'create' ? 'initial_fail_expired' : 'fail_expired';
    } else {
      status = resetPwdStatus;
    }
    switch (status) {
      case 'success':
        message = t('message.changePasswordSuccess');
        break;
      case 'initial_fail_expired':
        message = t('message.createPasswordTokenExpiredError');
        break;
      case 'fail_expired':
        message = t('message.changePasswordTokenExpiredError');
        break;
      case 'fail':
        message = t('message.changePasswordError');
        break;
      default:
        message = null;
        break;
    }
    return [status, message];
  }, [isResetPasswordTokenValid, mode, resetPwdStatus, t]);

  useEffect(() => {
    onInit();
  }, [onInit]);

  return (
    <Container maxWidth="xs">
      <Snackbar
        message={errors.request}
        open={!!errors.request}
        variant="error"
        onClose={() => setErrors({ request: null })}
      />
      <div className={classes.paper}>
        <Typography
          className={`${classes['typo']} ${
            mode === 'create' || (mode === 'update' && status === 'fail_expired')
              ? classes['center']
              : ''
          }`}
          component="h1"
          variant="h6"
        >
          <Title className={classes['navlink.active']}>
            {mode === 'create' ? t('title.createPassword') : t('title.updatePassword')}
          </Title>
        </Typography>
        <FormContainer>
          {!status && (
            <FormRow>
              <FormItem
                autoComplete="password"
                className={classes['input']}
                component={TextField}
                placeholder={t('label.newPassword')}
                required
                type="password"
                {...passInputVal}
              />
            </FormRow>
          )}
          {!status && (
            <FormRow>
              <FormItem
                autoComplete="confirm-password"
                className={classes['input']}
                component={TextField}
                placeholder={t('label.confirmPassword')}
                required
                type="password"
                {...confirmPassInputVal}
              />
            </FormRow>
          )}
          {status && !(isWelcomeEmailResent && status === 'initial_fail_expired') && (
            <Message
              dangerouslySetInnerHTML={{
                __html: message
              }}
              isError={status !== 'success'}
            />
          )}
          {isWelcomeEmailResent && status === 'initial_fail_expired' && (
            <Text dangerouslySetInnerHTML={{ __html: t('message.welcomeEmailSent') }} />
          )}
          <FormRow>
            {!status && (
              <FormItem>
                <Button
                  className={classes.submit}
                  color="primary"
                  disabled={syncing}
                  fullWidth={true}
                  variant="lightContained"
                  onClick={handleSubmit}
                >
                  {t('button.submit')}
                </Button>
              </FormItem>
            )}
            {(status === 'success' || isWelcomeEmailResent) && (
              <LargeLink size="large" to="/login">
                <Button
                  color="primary"
                  disabled={syncing}
                  fullWidth={true}
                  label={t('link.backToLogin')}
                  variant="lightContained"
                />
              </LargeLink>
            )}
            {status === 'fail_expired' && (
              <LargeLink size="large" to="/forgot-password">
                <Button
                  color="primary"
                  disabled={syncing}
                  fullWidth
                  label={t('button.retryPwdReset')}
                  variant="lightContained"
                />
              </LargeLink>
            )}
            {status === 'initial_fail_expired' && !isWelcomeEmailResent && (
              <FormItem>
                <Button
                  color="primary"
                  disabled={syncing}
                  fullWidth={true}
                  variant="lightContained"
                  onClick={() => onRequestNewLink(token)}
                >
                  {t('button.requestNewLink')}
                </Button>
              </FormItem>
            )}
          </FormRow>
          <Subtext dangerouslySetInnerHTML={{ __html: t('message.supportMessage') }} />
        </FormContainer>
      </div>
    </Container>
  );
}

UpdatePasswordPage.propTypes = {
  isResetPasswordTokenValid: PropTypes.bool,
  mode: PropTypes.string,
  resetPwdStatus: PropTypes.string,
  syncing: PropTypes.bool.isRequired,
  token: PropTypes.string.isRequired,
  onInit: PropTypes.func.isRequired,
  onUpdatePassword: PropTypes.func.isRequired,
  onRequestNewLink: PropTypes.func,
  isWelcomeEmailResent: PropTypes.bool
};

export default UpdatePasswordPage;
