import { connect } from 'react-redux';
import * as resumeSelectors from 'redux-modules/resume/selectors';
import * as uiStateSelectors from 'redux-modules/uiState/selectors';
import DashboardPage from './DashboardPage';

function mapStateToProps(state) {
  return {
    initialInputCv: resumeSelectors.selectInitialInputCv(state),
    language: uiStateSelectors.selectLanguageCode(state)
  };
}

export default connect(mapStateToProps, null)(DashboardPage);
