import { all } from 'redux-saga/effects';
import auth from './auth/sagas';
import resumes from './resumes/sagas';
import meta from './meta/sagas';
import news from './news/sagas';
import offers from './offers/sagas';
import opportunities from './opportunities/sagas';
import placements from './placements/sagas';
import resume from './resume/sagas';
import projects from './projects/sagas';
import fieldsMeta from './fieldsMeta/sagas';
import billing from './billing/sagas';
import documents from './documents/sagas';

export default function* rootSaga() {
  yield all([
    ...auth,
    ...meta,
    ...news,
    ...offers,
    ...opportunities,
    ...placements,
    ...resume,
    ...resumes,
    ...projects,
    ...fieldsMeta,
    ...billing,
    ...documents
  ]);
}
