import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Content,
  Header,
  PropertyContainer,
  PropertyContainerTitle,
  PropertyContainerText,
  PropertyContainerRow,
  PropertyContainerCell,
  AttachmentsContainer,
  InformationContainer,
  InformationContainerTitle,
  InformationContainerSubTitle,
  ShowHideDetailsContainer,
  Link,
  DownArrow,
  UpArrow
} from './style';
import Typography from '@material-ui/core/Typography';
import ProjectStatus from './../ProjectStatus';
import ProjectStatusContent from './../ProjectStatusContent';
import { Switch } from 'components/buttons';

function ProjectView({ indexedMeta: { directReports, industries }, project, syncErrors, syncing }) {
  const [syncInitiated, setSyncInitiated] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  useEffect(() => {
    if (syncInitiated && !syncing) {
      setSyncInitiated(false);
    }
  }, [setSyncInitiated, syncErrors, syncing, syncInitiated]);

  return (
    <Content>
      <Header>
        <Typography component="h6" variant="h5">
          <b>{project.contractType}</b> - {project.jobTitle}
        </Typography>
        {project.companyName}, {project.city} ({project.country})
        <ProjectStatus
          items={[
            'Draft',
            'Search in progress',
            'Candidates found',
            'Review candidates',
            'Mandate in preparation',
            'Mandata ongoing',
            'Mandate terminated'
          ]}
          selectedIndex={project.status / 100}
        />
      </Header>
      <ProjectStatusContent project={project} />
      <ShowHideDetailsContainer>
        <Link to="#" onClick={setShowDetails.bind(this, !showDetails)}>
          {showDetails ? 'Hide details' : 'Show details'}
          {showDetails && <UpArrow />}
          {!showDetails && <DownArrow />}
        </Link>
      </ShowHideDetailsContainer>
      {showDetails && (
        <React.Fragment>
          <PropertyContainer>
            <PropertyContainerTitle component="h6" variant="h6">
              General information about the opportunity
            </PropertyContainerTitle>
            <PropertyContainerRow>
              <PropertyContainerCell>
                <label>Planned placement art:</label>
                <label>{project.plannedPlacementArt}</label>
              </PropertyContainerCell>
              <PropertyContainerCell>
                <label>Title of the position:</label>
                <label>{project.jobTitle}</label>
              </PropertyContainerCell>
              <PropertyContainerCell>
                <label>Funktion:</label>
                <label>{project.function}</label>
              </PropertyContainerCell>

              <PropertyContainerCell>
                <label>May the client be named ?</label>
                <label>{project.clientNamed ? 'YES' : 'NO'}</label>
              </PropertyContainerCell>
              <PropertyContainerCell>
                <label>Is the current job holder informed?</label>
                <label>{project.jobHolderInformed ? 'YES' : 'NO'}</label>
              </PropertyContainerCell>
              <PropertyContainerCell>
                <label>Primary Contact:</label>
                <label>{project.contactPhone}</label>
                <label>{project.contactEmail}</label>
              </PropertyContainerCell>

              <PropertyContainerCell>
                <label>Industry: </label>
                <label>{industries[project.industry]}</label>
              </PropertyContainerCell>
              <PropertyContainerCell>
                <label>Business performance:</label>
                <label>{project.businessPerfomance}</label>
              </PropertyContainerCell>
              <PropertyContainerCell>
                <label>Reporting :</label>
                <label>{directReports[project.reporting]}</label>
              </PropertyContainerCell>

              <PropertyContainerCell>
                <label>Operation area: </label>
                <label>{project.operationalArea}</label>
              </PropertyContainerCell>
              <PropertyContainerCell>
                <label>Location:</label>
                <label>{project.jobLocation}</label>
              </PropertyContainerCell>
              <PropertyContainerCell />
            </PropertyContainerRow>

            <PropertyContainerTitle component="h6" variant="h6">
              Project description
            </PropertyContainerTitle>
            <PropertyContainerText>{project.description}</PropertyContainerText>

            <PropertyContainerTitle component="h6" variant="h6">
              Company description, goals & taks.
            </PropertyContainerTitle>
            <PropertyContainerText>{project.companyDescription}</PropertyContainerText>

            <AttachmentsContainer>
              <header>Attachments</header>
              {project.documents &&
                project.documents.map((doc, index) => (
                  <a href={'/api/v1/manager/documents/' + doc.id + '/file'} key={index}>
                    {doc.name}
                  </a>
                ))}
            </AttachmentsContainer>
          </PropertyContainer>

          <InformationContainer>
            <InformationContainerTitle component="h5" variant="h5">
              <label>Important information</label>
            </InformationContainerTitle>
            <InformationContainerSubTitle>
              May I please draw your attention to the following:
              <Switch leftLabel="EN" rightLabel="DE" style={{ float: 'right' }} />
            </InformationContainerSubTitle>
            <PropertyContainerText>
              <li>
                Atreus Managers can only start an assignment in your company or affiliated company
                via Atreus
              </li>
              <li>
                We would like to remind you to make all confidential personal data (Atreus profile,
                further personal information available only to those persons who are involved in the
                decision-making proccess for the assignment.
              </li>
              <li>
                Personal data can only be stored for the duration of the decision making process or
                for the appointed manager for duration of the interim assignment.
              </li>
              <li>
                We would like to ask you to cover all travel expenses related to the presentation of
                candidates, for the Manager as well as for the Atreus Director in charge.
              </li>
            </PropertyContainerText>
          </InformationContainer>
        </React.Fragment>
      )}
    </Content>
  );
}

ProjectView.propTypes = {
  className: PropTypes.string,
  indexedMeta: PropTypes.shape({
    directReports: PropTypes.object.isRequired,
    industries: PropTypes.object.isRequired
  }).isRequired,
  personalDetails: PropTypes.object,
  project: PropTypes.object,
  syncErrors: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  syncing: PropTypes.bool
};

export default ProjectView;
