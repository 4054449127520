import { connect } from 'react-redux';
import ProjectOverview from 'components/ProjectOverview';

function mapStateToProps(_, ownProps) {
  return {
    company: ownProps.placement ? ownProps.placement.company : null,
    positionTitle: ownProps.placement ? ownProps.placement.positionTitle : null,
    operationArea: ownProps.placement ? ownProps.placement.operationArea : null,
    operationCity: ownProps.placement ? ownProps.placement.operationCity : null,
    startDate: ownProps.placement ? ownProps.placement.startDate : null
  };
}

export default connect(mapStateToProps, null)(ProjectOverview);
