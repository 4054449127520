import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Button as BaseButton } from 'components/buttons';

export const Button = styled(BaseButton)`
  margin: ${props => props.theme.spacing(2)} 0;
`;

export const GroupRow = styled.div`
  background-color: ${props => props.theme.color.grey[400]};
`;

export const RoleSection = styled.div`
  width: 100%;
`;

export const RelationLink = styled(({ className }) => (
  <div className={className}>
    <span className="circle" />
    <span className="sphere" />
  </div>
))`
  float: left;
  height: 100%;
  left: -7px;
  position: relative;
  top: 33px;
  width: 0;
  z-index: 1;

  :after,
  :before {
    border: 0px dotted ${props => props.theme.color.primary.main};
    content: '';
    display: block;
    left: -7px;
    position: absolute;
    top: 7px;
  }
  :after {
    border-top-width: 2px;
    width: 7px;
  }
  :before {
    border-left-width: 2px;
    height: 100%;
  }

  .circle,
  .sphere {
    border-radius: 50%;
    box-sizing: border-box;
    position: absolute;
  }
  .circle {
    border: 1px solid ${props => props.theme.color.primary.main};
    height: 13px;
    left: 1px;
    top: 1px;
    width: 13px;
    z-index: 1;
  }
  .sphere {
    background-color: ${props => props.theme.color.primary.main};
    height: 15px;
    width: 15px;
  }

  ${props =>
    props.main &&
    `
    .circle,
    .sphere {
      background-color: #000000;
      border-color: #FFFFFF;
    }

    :after, :before {
      border: 0px solid #000000;
    }
    :after {
      border-top-width: 1px;
    }
    :before {
      border-left-width: 1px;
    }
  `}

  ${props =>
    props.last &&
    `
    :before {
      height: 1px;
    }
  `}
`;

RelationLink.propTypes = {
  last: PropTypes.bool,
  main: PropTypes.bool
};
