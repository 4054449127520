import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import Company from './Company';
/**
 * @TODO improve components structure and imports
 * (Container import from child component should not exist, it creates abnormal dependencies)
 */
import { Container } from './Company/Detail/style';
import { ButtonWrapper } from 'components/Resume/style';
import Link from 'components/Link';
import { IconButton } from 'components/buttons';
import {useLocale, useTranslation } from 'hooks';
import {format, min, max, isValid} from "date-fns";
import { indexOf } from 'lodash';


function CompanyGroup({ className, data, editPath, indexedMeta, language, expandCollapse }) {
  const [expanded, setExpanded] = React.useState(false);
  const { tr } = useTranslation();
  const locale = useLocale(language);


  function getTimeDiff(from, to) {
    let timeObj = {
      diff: null,
      period: ''
    };
    if (from === '') {
      return timeObj;
    }

    let date1 = new Date(from);
    let date2 = to ? new Date(to) : new Date();
    return date2.getTime() - date1.getTime();
  }

  function getTimeOfCompanyExp(data, locale) {
    if (!data) {
      return null;
    }
    if (data.experiences.length === 1) {
      return getTimeOfExp(data.experiences[0]);
    } else {
      let totalMonthsDiff = data.experiences.reduce((totalDiff, experience) => {
        let diff = getTimeOfExp(experience);
        return totalDiff + (diff.years * 12 + diff.months);
      }, 0);
      const [years, months] = [Math.floor(totalMonthsDiff / 12), totalMonthsDiff % 12];
      const yearsText = years <= 1 ? tr('label.year') : tr('label.years');
      const monthsText = months <= 1 ? tr('label.month') : tr('label.months');
      const yearToShow = years > 0 ? `${years} ${yearsText} ` : '';
      const monthToShow = months > 0 ? `${months} ${monthsText}` : '';
      const periodText = yearToShow + monthToShow;

      return {
        years,
        months,
        text: periodText.trim()
      };
    }
  }

  function getTimeOfExp(experience) {
    if (!experience || !experience.dateFrom) {
      return {
        years: 0,
        months: 0,
        text: `-`
      };
    }
    let diffDate = new Date(getTimeDiff(experience.dateFrom, experience.dateTo));
    const totalMonthsDiff =
        (diffDate.getUTCFullYear() - 1970) * 12 +
        diffDate.getUTCMonth() +
        (diffDate.getDate() >= 15 ? 1 : 0);
    const [years, months] = [Math.floor(totalMonthsDiff / 12), totalMonthsDiff % 12];
    const yearsText = years <= 1 ? tr('label.year') : tr('label.years');
    const monthsText = months <= 1 ? tr('label.month') : tr('label.months');
    const yearToShow = years > 0 ? `${years} ${yearsText} ` : '';
    const monthToShow = months > 0 ? `${months} ${monthsText}` : '';
    const periodText = yearToShow + monthToShow;

    return {
      years,
      months,
      text: periodText.trim()
    };
  }

  // enrich company work experience with timing information
  const companyData = useMemo(() => {
    if (!data) return null;
    data.companies.map(companyData => {

      companyData.timeInfo = {
        to: companyData.isActualEngagement
            ? tr('label.present')
            : companyData.dateTo
                ? format(new Date(companyData.dateTo), 'MMM yyyy', { locale })
                : '-',
        from: companyData.dateFrom
            ? format(new Date(companyData.dateFrom), 'MMM yyyy', { locale })
            : '-',
        period: getTimeOfCompanyExp(companyData).text,
        years: getTimeOfCompanyExp(companyData).years,
        months: getTimeOfCompanyExp(companyData).months,
      };

      companyData.experiences.map(experience => {
        experience.timeInfo = {
          to: experience.isActualEngagement
              ? tr('label.present')
              : experience.dateTo
                  ? format(new Date(experience.dateTo), 'MMM yyyy', { locale })
                  : '-',
          from: experience.dateFrom
              ? format(new Date(experience.dateFrom), 'MMM yyyy', { locale })
              : '-',
          period: getTimeOfExp(experience).text
        };
        return companyData;
      });

    });
    return companyData;
  }, [data, locale, tr]);



  const groupTimeInfo = useMemo(() => {
    let groupTimeInfo = {
      years: 0,
      months: 0,
      period: '',
      from: '',
      to: ''
    };
    let groupFrom = [];
    let groupTo = [];
    data.companies.map(comp => {
      groupTimeInfo.years += comp.timeInfo.years;
      groupTimeInfo.months += comp.timeInfo.months;
      groupFrom.push(comp.dateFrom ? new Date(comp.dateFrom) : '');
      groupTo.push(comp.isActualEngagement ? tr('label.present') : comp.dateTo ? new Date(comp.dateTo) : '');
    });

    groupTimeInfo.from = isValid(min(groupFrom)) ? format(min(groupFrom), 'MMM yyyy', {locale}) : '-';
    groupTimeInfo.to = indexOf(groupTo, tr('label.present')) !== -1 ? tr('label.present') : isValid(max(groupTo)) ? format(max(groupTo), 'MMM yyyy', {locale}) : '-';

    let totalMonthsDiff = groupTimeInfo.years > 0 ? groupTimeInfo.years * 12 + groupTimeInfo.months : groupTimeInfo.months;
    const [years, months] = [Math.floor(totalMonthsDiff / 12), totalMonthsDiff % 12];
    const yearsText = years <= 1 ? tr('label.year') : tr('label.years');
    const monthsText = months <= 1 ? tr('label.month') : tr('label.months');
    const yearToShow = years > 0 ? `${years} ${yearsText} ` : '';
    const monthToShow = months > 0 ? `${months} ${monthsText}` : '';
    groupTimeInfo.period = (yearToShow + monthToShow).trim();
    return groupTimeInfo;

  }, [data, locale, tr]);


  //Apply series of sorting after each cycle

  data.companies && data.companies.sort(function(a, b) {
    var keyA = new Date(a.dateTo),
        keyB = new Date(b.dateTo);
    if(a.dateTo == '') return -1;
    if(b.dateTo == '') return 1;
    if (keyA > keyB) return -1;
    if (keyA < keyB) return 1;
    return 0;
  });

  //Sort based on start date if end dates are equal
  data.companies && data.companies.sort(function(a, b) {
    var keyC = new Date(a.dateFrom),
        keyD = new Date(b.dateFrom);

    if (a.dateTo == b.dateTo && keyC > keyD) {
      return -1;
    }
    if (a.dateTo == b.dateTo && keyC < keyD) {
      return 1;
    }
    return 0;
  });

//now again sort the sorted array for start date for open ended end date
  data.companies && data.companies.sort(function(a, b) {

    var keyC = new Date(a.dateFrom),
        keyD = new Date(b.dateFrom);

    if (a.dateTo == '' && keyC > keyD) {
      return -1;
    }
    if(b.dateTo == '' && keyC < keyD) {
      return 1;
    }
    return 0;
  });



  return (
    <Container className={className}>
      {data.companyGroupName ? (
        <div className="clt compParent">
          <ButtonWrapper>
          <div>
            <h2 className="WexpGroupName"> {groupTimeInfo.from} - {groupTimeInfo.to}{' '}
              {/*RTEXT-529, Hiding Duration in Company and Company Groups*/}
              {/*groupTimeInfo.period && `(${groupTimeInfo.period})`*/} {' | '} { data.companyGroupName}</h2>
          </div>

          <IconButton className="CompanyGroup-btn" component={Link} listOfComp={data.companies} icon="Edit" to={{
            pathname: 'resume/add-company-group/'+data.companyGroupId,
            state: {
              listOfComp: data
            }
          }} />
          </ButtonWrapper>

          <ul className="list">
            {data.companies.map((item, index) => (
              <li className="listItem" key={index}>
                <Company
                  data={item}
                  editPath={editPath}
                  expanded={expanded}
                  indexedMeta={indexedMeta}
                  language={language}
                  onExpand={setExpanded}
                  expandCollapse={expandCollapse}
                />
              </li>
            ))}
          </ul>
        </div>
      ) : (
        <div className="clt compParent">
          <ButtonWrapper className="add-comp-grp">
            <IconButton className="compGroup-btn" component={Link} listOfComp={data.companies}   icon="Plus" to={{
              pathname: 'resume/add-company-group',
              state: {
                listOfComp: data
              }
            }} />
          <div><Link listOfComp={data.companies} to={{
            pathname: 'resume/add-company-group',
            state: {
              listOfComp: data
            }
          }} > <span>{tr('title.addCompanyGroup')}</span> </Link>
          </div>
          </ButtonWrapper>


          {data.companies.map((item, index) => (
            <Company
              data={item}
              editPath={editPath}
              expanded={expanded}
              indexedMeta={indexedMeta}
              key={index}
              language={language}
              onExpand={setExpanded}
              expandCollapse={expandCollapse}
            />
          ))}
        </div>
      )}
    </Container>
  );
}

CompanyGroup.propTypes = {
  className: PropTypes.string,
  data: PropTypes.object.isRequired,
  editPath: PropTypes.string,
  indexedMeta: PropTypes.object.isRequired,
  language: PropTypes.string.isRequired
};

export default CompanyGroup;
