import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'hooks';
import { Card } from 'components/cards';
import { Loader } from 'components/styledComponents';
import ProjectOverview from 'components/ProjectOverview';

function ProjectHeader({ className, loading, project }) {
  const { t } = useTranslation();
  if (!project) return null;
  return (
    <Card className={className} title={t('title.applicantCV')}>
      <Loader loading={loading}>
        <ProjectOverview
          company={project ? project.company : null}
          operationArea={project ? project.operationArea : null}
          operationCity={project ? project.operationCity : null}
          positionTitle={project ? project.positionTitle : null}
          startDate={project ? project.startDate : null}
        />
      </Loader>
    </Card>
  );
}

ProjectHeader.propTypes = {
  className: PropTypes.string,
  loading: PropTypes.bool.isRequired,
  project: PropTypes.object
};

export default ProjectHeader;
