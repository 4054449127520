import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { Editor } from '@tinymce/tinymce-react';
import { Container, GlobalEditorStyle } from './style';

const TextEditor = React.forwardRef(function (
  { className, value, boxClass, tinyMceInit, ...rest },
  ref
) {
  //initializing tinymce editor
  const editorRef = useRef(null);
  //definig tinymce configurations
  const tinyMceConfig = {
    height: 500,
    statusbar: false,
    plugins: ['autoresize'],
    toolbar: false,
    menubar: false,
    inline: true,
    selector: 'textarea',
    valid_elements: 'li,br,p,ul',
    valid_children: '-li[ul]',
    content_style:
      `
    body {  
      font-family: Roboto,Helvetica Neue,Helvetica,sans-serif;
      vertical-align: left;
      color: #444444;
      margin:0;

    }` +
      `
    p:first-of-type {
      margin-top:0;
    }`,
    autoresize_bottom_margin: 0
  };
  return (
    <Container className={className}>
      <GlobalEditorStyle />
      <Editor
        disabled
        init={tinyMceConfig}
        initialValue={value}
        onInit={(evt, editor) => {
          editorRef.current = editor;
          if (typeof tinyMceInit == 'function') tinyMceInit(boxClass);
        }}
        {...rest}
      />
    </Container>
  );
});
TextEditor.displayName = 'TextEditor';

TextEditor.propTypes = {
  className: PropTypes.string,
  value: PropTypes.string,
  boxClass: PropTypes.string,
  tinyMceInit: PropTypes.func
};

export default TextEditor;
