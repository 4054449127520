import React from 'react';
import styled from 'styled-components';
import MuiChip from '@material-ui/core/Chip';
import { updateColorLight } from 'utils/colorHelpers';

export const Chip = styled(({ isFocused, ...rest }) => <MuiChip {...rest} />)`
  ${props => `
    background-color: ${updateColorLight(
      props.theme.color.primary.main,
      props.isFocused ? 90 : 85
    )};
    margin: ${props.theme.spacing(0.25)} ${props.theme.spacing(0.5)} ${props.theme.spacing(0.25)} 0;
  `}
`;
