import styled, { createGlobalStyle } from 'styled-components';
import { Button } from 'components/buttons';

export const AddButton = styled(Button)`
  margin: ${props => props.theme.spacing(2)} 0;
`;

export const TableGlobalStyle = createGlobalStyle`
  .table-wrapper {
    background-color: #fafafa;
    border-radius: 4px;
    min-height: 150px;
    box-shadow: ${props => props.theme.boxShadow.base};

    .table-header,
    .table-row {
      display: flex;
      padding: 6px;

      & > * {
        flex: 1;
        margin: 4px;
        width: calc(33% - 12px);
      }
    }

    .table-header {
      border-bottom: 1px solid #e6e6e6;
      font-size: 16px;
      line-height: 24px;
    }

    .table-row {
      color: #003049;
      font-weight: ${props => props.theme.fontWeight.bold};
      font-size: 16px;

      &:nth-child(odd) {
        background: white;
      }

      &:last-of-type {
        border: none;
      }
    }
  }
`;
