import styled from 'styled-components';
import { updateColorLight } from 'utils/colorHelpers';
/**
 * SPECS:
 * - base font size: 14px
 * - sizes (heights): 42px, 32px, 22px
 */
const LINE_HEIGHTS = {
  large: '1.875em',
  medium: '1.75em',
  small: '1.1875em'
};
const MIN_WIDTHS = {
  large: '150px',
  medium: '100px',
  small: '50px'
};
const PADDINGS = {
  large: [1, 3],
  medium: [0.5, 2],
  small: [0.375, 1.5]
};

export const CustomContent = styled.div`
  display: flex;
`;

export const Label = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const Button = styled.button`
  align-items: center;
  /**
   * Background reset to none is needed in order to override Safari default button
   * background value (background-color: buttonface)
   */
  background: none;
  border: none;
  border-radius: ${props => props.theme.borderRadius.base};
  box-sizing: border-box;
  cursor: pointer;
  display: inline-flex;
  font-weight: ${props => props.theme.fontWeight.bold};
  font-size: ${props => props.theme.fontSize.small};
  justify-content: center;
  overflow: hidden;
  text-transform: uppercase;
  transition: all ${props => props.theme.animation.standard};
  ${props => props.fullWidth && 'width: 100%;'}
  ${props =>
    `
    line-height: ${LINE_HEIGHTS[props.size]};
    min-width: ${MIN_WIDTHS[props.size]};
    padding: ${props.theme.spacing(...PADDINGS[props.size])};
  `};

  &:disabled,
  &[disabled] {
    cursor: initial;
    pointer-events: none;
  }

  ${props => {
    const color = props.theme.color[props.color];
    /**
     * @TODO analyze box-shadow performance impact and find a more maintainable way to
     * handle this (we should allow box-shadow to be set from external context, maybe
     * use an absolute positioned pseudo element or internal tag to simulate the border)
     * reduced padding and real border is also a solution
     */

    return (
      (props.variant === 'contained' &&
        `
      // visited state needed until proper LinkButtons are created
      &, &:visited {
        background: linear-gradient(270deg, ${color.main} 0%, ${color.light} 100%);
        color: ${color.contrast};
      }

      &:hover {
        background: linear-gradient(270deg, ${color.light} 0%, ${color.main} 100%);
      }

      &:active {
        background: linear-gradient(270deg, ${color.dark} 0%, ${color.main} 100%);
      }

      &:disabled, &[disabled] {
        background: ${props.theme.color.disabled.background};
        color: ${props.theme.color.disabled.text};
      }
    `) ||
      (props.variant === 'outlined' &&
        `
      // visited state needed until proper LinkButtons are created
      &, &:visited {
        box-shadow: inset 0px 0px 0px 1px ${color.main};
        color: ${color.main};
      }

      &:hover {
        background: ${updateColorLight(color.main, 95)};
      }

      &:active {
        background: ${updateColorLight(color.main, 85)};
      }

      &:disabled, &[disabled] {
        background: ${props.theme.color.disabled.background};
        box-shadow: inset 0px 0px 0px 1px ${props.theme.color.disabled.border};
        color: ${props.theme.color.disabled.text};
      }
    `) ||
      (props.variant === 'text' &&
        `
      // visited state needed until proper LinkButtons are created
      &, &:visited {
        color: ${color.main};
      }

      &:hover {
        background: ${updateColorLight(color.main, 95)};
      }

      &:active {
        background: ${updateColorLight(color.main, 85)};
      }

      &:disabled, &[disabled] {
        background: ${props.theme.color.disabled.background};
        color: ${props.theme.color.disabled.text};
      }
    `) ||
      (props.variant === 'lightContained' &&
        `
      // visited state needed until proper LinkButtons are created
      &, &:visited {
        background: ${color.contrast};
        color: ${color.main};
      }

      &:hover {
        background: ${updateColorLight(color.main, 95)};
      }

      &:active {
        background: ${updateColorLight(color.main, 85)};
      }

      &:disabled, &[disabled] {
        background: ${props.theme.color.disabled.background};
        color: ${props.theme.color.disabled.text};
      }
    `) ||
      (props.variant === 'lightOutlined' &&
        `
      // visited state needed until proper LinkButtons are created
      &, &:visited {
        box-shadow: inset 0px 0px 0px 1px ${color.contrast};
        color: ${color.contrast};
      }

      &:hover {
        background: ${color.contrast};
        color: ${color.main};
      }

      &:active {
        background: ${updateColorLight(color.main, 95)};
        color: ${color.main};
      }

      &:disabled, &[disabled] {
        background: none;
        box-shadow: inset 0px 0px 0px 1px ${props.theme.color.disabled.border};
        color: ${props.theme.color.disabled.text};
      }
    `) ||
      (props.variant === 'lightText' &&
        `
      // visited state needed until proper LinkButtons are created
      &, &:visited {
        color: ${color.contrast};
      }

      &:hover {
        background: ${color.contrast};
        color: ${color.main};
      }

      &:active {
        background: ${updateColorLight(color.main, 95)};
        color: ${color.main};
      }

      &:disabled, &[disabled] {
        background: none;
        color: ${props.theme.color.disabled.text};
      }
    `)
    );
  }}
  /**
   * -webkit-appearance reset to none is needed in order to override
   * Safari default -webkit-appearance: button
   */
  && {
    -webkit-appearance: none;
  }

  * + ${Label}, ${Label} + * {
    margin-left: ${props => props.theme.spacing(0.5)};
  }
`;
