import { connect } from 'react-redux';
import * as metaSelectors from 'redux-modules/meta/selectors';
import Form from './Form';

function mapStateToProps(state) {
  return {
    meta: metaSelectors.selectResumeMeta(state)
  };
}

export default connect(mapStateToProps, null)(Form);
