import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Link from 'components/Link';
import { IconButton } from 'components/buttons';
import { useTranslation } from 'hooks';
import { Card, EmptySection, EmptyText} from './style';

function Section({ addPath, children, className, editPath, title, showExpandCollapse, isExpanded, handleCollapseExpand, ...rest }) {
  const { t, tr } = useTranslation();
  const headerAction = useMemo(
    () => (
      <React.Fragment>
        {addPath && <IconButton component={Link} icon="Plus" to={addPath} />}
        {editPath && <IconButton component={Link} icon="Edit" to={editPath} />}
      </React.Fragment>
    ),
    [addPath, editPath, isExpanded]
  );

  return (
    <Card className={className} headerContent={headerAction} title={tr(title)} {...rest}>
      {children || (
        <EmptySection>
          <EmptyText>{t('message.sectionIsEmpty')}</EmptyText>
          <EmptyText>{t('message.addMoreInformation')}</EmptyText>
        </EmptySection>
      )}
    </Card>
  );
}

Section.defaultProps = {
  size: '12'
};

Section.propTypes = {
  addPath: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
  className: PropTypes.string,
  editPath: PropTypes.string,
  title: PropTypes.string
};

export default Section;
