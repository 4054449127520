import * as types from './types';

export function changeLanguage(language) {
  return {
    payload: {
      language: language
    },
    type: types.CHANGE_LANGUAGE
  };
}

export function changeResumeLanguage(language) {
  return {
    payload: {
      language: language
    },
    type: types.CHANGE_RESUME_LANGUAGE
  };
}
