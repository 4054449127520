import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import Dialog from 'components/Dialog';
// import PDFViewer from 'components/PDFViewer';
import { Document } from 'components/icons';
import { useTranslation } from 'hooks';
import {
  ActionItem,
  Checkbox,
  DownloadLink,
  DownloadText,
  NDAContainer,
  NDAFooter,
  NDAText,
  // NDAViewer,
  Title
} from './style';

function NDADialog({ language, nda, onClose, opportunity, syncing, onAccept, onInit, ...rest }) {
  const { t } = useTranslation();
  const [agreed, setAgreed] = useState(false);
  const handleAgreeToggle = useCallback(() => setAgreed(!agreed), [agreed, setAgreed]);
  const disableAction = useMemo(() => {
    return syncing || !agreed;
  }, [agreed, syncing]);

  useEffect(() => {
    onInit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Dialog
      actionLabel={t('button.agreeContinueNDA')}
      disabled={disableAction}
      open={true}
      syncing={syncing}
      title={t('title.atreusOpportunity')}
      onAction={onAccept}
      onClose={onClose}
      {...rest}
    >
      <NDAContainer>
        <Title>{t('title.nonDisclosure')}</Title>
        <NDAText>{nda}</NDAText>
        {/*
        commented in RTEXT-453 to enable plain text preview
        <NDAViewer as={PDFViewer} data={`/api/v1/manager/nda/inline-file/${language}?timestamp=`+new Date().getTime()}>
        </NDAViewer> */}
        <NDAFooter justify="space-between">
          <ActionItem
            as={DownloadLink}
            color="primary"
            component="a"
            href={`/api/v1/manager/nda/file/${language}?timestamp=` + new Date().getTime()}
            target="_blank"
          >
            <Document />
            <DownloadText>{t('link.downloadPDF')}</DownloadText>
          </ActionItem>
          <ActionItem>
            <Checkbox
              checked={agreed}
              disabled={agreed && disableAction}
              label={t('label.acceptPrivacy')}
              size="small"
              onChange={handleAgreeToggle}
            />
          </ActionItem>
        </NDAFooter>
      </NDAContainer>
    </Dialog>
  );
}

NDADialog.propTypes = {
  language: PropTypes.string.isRequired,
  nda: PropTypes.string,
  opportunity: PropTypes.object.isRequired,
  syncing: PropTypes.bool.isRequired,
  onAccept: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onInit: PropTypes.func.isRequired
};

export default NDADialog;
