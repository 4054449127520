import React, { useMemo, useCallback } from 'react';
import { TextContainer, Text } from '../../style';
import { useTranslation } from 'hooks';
import { FlexBox, Icon, TextFieldContainer, List, ListItem, Value } from './style';
import PropTypes from 'prop-types';

function Inhalt({ feeBilling, language, monthlyReport, vatList }) {
  const { t } = useTranslation();
  let {
    sumActivityTime,
    sumTravelExpensDay1,
    sumTravelExpensDay2,
    vat,
    sumHonorarium,
    totalTravelExpenses,
    totalBonus,
    subTotal,
    vatAmount,
    invoiceAmountAtreus,
    sumTravelExpensesDay,
    isoCode,
    currencySymbol,
    workingTime
  } = monthlyReport || {};

  // split vat description based into header and body based on language
  let vatDesc = useMemo(() => {
    // RTEXT-751
    let [header, msg] = (vatList && vatList[0].value.split(':')) || ['', ''];
    return [`${t('label.vat')} ${header}:`, msg];
  }, [vatList]);

  // format currency and day values based on language
  const format = useCallback(
    (value, isDay = false) => {
      if (value) {
        const formattedValue = new Intl.NumberFormat(
          language === 'en' ? 'en-IN' : 'de-DE',
          !isDay && isoCode
            ? {
                currency: isoCode,
                style: 'currency'
              }
            : {
                style: 'decimal',
                maximumFractionDigits: 1
              }
        ).format(value);
        if (language === 'en' && !isDay) {
          return `${formattedValue.replace(currencySymbol || '', '')} ${currencySymbol}`;
        }
        return formattedValue;
      }
      return '';
    },
    [language, isoCode]
  );

  return (
    <>
      <Text>{t('message.invoiceContentHeader')}</Text>

      {parseFloat(sumHonorarium) !== 0 && (
        <FlexBox mt="15">
          <Text mt="10">
            {t('label.fee')}:{' '}
            {feeBilling === '100' && (
              <>
                {format(sumActivityTime, true)} {t('label.days')}
              </>
            )}
            {feeBilling === '200' && (
              <>
                {workingTime} {t('label.hours')}
              </>
            )}
          </Text>
          <TextFieldContainer mt="10">
            <Icon>=</Icon>
            <Value>{format(sumHonorarium)}</Value>
          </TextFieldContainer>
        </FlexBox>
      )}

      <FlexBox mt="15">
        <TextContainer>
          {!!(sumTravelExpensesDay || sumTravelExpensDay1 || sumTravelExpensDay2) && (
            <Text mt="10">{t('label.travelExpensesFlatRate')}:</Text>
          )}
          <List ml="-20" mt="2">
            {parseFloat(sumTravelExpensesDay) !== 0 && (
              <ListItem>
                {t('label.flatRate')} {format(sumTravelExpensesDay, true)} {t('label.days')}
              </ListItem>
            )}
            {parseFloat(sumTravelExpensDay1) !== 0 && (
              <ListItem>
                {t('label.firstLevel')}: {format(sumTravelExpensDay1, true)}
              </ListItem>
            )}
            {parseFloat(sumTravelExpensDay2) !== 0 && (
              <ListItem>
                {t('label.secondLevel')}: {format(sumTravelExpensDay2, true)}
              </ListItem>
            )}
          </List>
        </TextContainer>
      </FlexBox>

      {parseFloat(totalTravelExpenses) !== 0 && (
        <FlexBox mt="-12">
          <Text>{t('label.travelTravelExpensesFlatRate')}</Text>
          <TextFieldContainer>
            <Icon>=</Icon>
            <Value>{format(totalTravelExpenses)}</Value>
          </TextFieldContainer>
        </FlexBox>
      )}

      {parseFloat(totalBonus) !== 0 && (
        <FlexBox mt="15">
          <Text mt="10">{t('label.bonus')}</Text>
          <TextFieldContainer mt="10">
            <Icon>=</Icon>
            <Value>{format(totalBonus)}</Value>
          </TextFieldContainer>
        </FlexBox>
      )}

      <FlexBox mt="100">
        <Text mt="10" bold>
          {t('label.subTotal')}
        </Text>
        <TextFieldContainer mt="10">
          <Icon>=</Icon>
          <Value>{format(subTotal)}</Value>
        </TextFieldContainer>
      </FlexBox>

      {parseFloat(vat) !== 0 ? (
        <FlexBox mt="15">
          <Text mt="10">
            {t('label.vat')} {vat && vatList[1].value}:
          </Text>
          <TextFieldContainer mt="10">
            <Icon>=</Icon>
            <Value>{format(vatAmount)}</Value>
          </TextFieldContainer>
        </FlexBox>
      ) : (
        <TextContainer mt="15">
          <Text>{vatDesc[0]}</Text>
          <Text width="230">{vatDesc[1]}</Text>
        </TextContainer>
      )}

      <FlexBox mt="15">
        <Text mt="10" bold>
          {t('label.invoiceAmount')}
        </Text>
        <TextFieldContainer mt="10">
          <Icon>=</Icon>
          <Value>{format(invoiceAmountAtreus)}</Value>
        </TextFieldContainer>
      </FlexBox>
    </>
  );
}

Inhalt.propTypes = {
  monthlyReport: PropTypes.object,
  vatList: PropTypes.array,
  language: PropTypes.string
};

export default Inhalt;
