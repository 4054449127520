import { connect } from 'react-redux';
import * as uiStateSelectors from 'redux-modules/uiState/selectors';
import * as documentsActions from 'redux-modules/documents/actions';
import * as documentsStateSelectors from 'redux-modules/documents/selectors';

import MyDocumentsPage from './MyDocumentsPage';

function mapStateToProps(state) {
  return {
    lang: uiStateSelectors.selectLanguage(state),
    documents: documentsStateSelectors.selectDocuments(state),
    syncing: documentsStateSelectors.selectDocumentsSyncing(state)
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchDocuments: () => dispatch(documentsActions.fetchDocuments()),
    uploadDocument: (subcategoryId, file, successCallback) =>
      dispatch(documentsActions.uploadDocument(subcategoryId, file, successCallback))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(MyDocumentsPage);
