import styled from 'styled-components';

export const ControlsContainer = styled.div`
  padding: ${props => props.theme.spacing(1)};
`;

export const EditorContainer = styled.div`
  border: 1px solid #ddd;
  border-radius: ${props => props.theme.borderRadius.base};
`;

export const EditorBody = styled.div`
  border-top: 1px solid #ddd;
  cursor: text;

  ${props => props.hidePlaceholder && '.public-DraftEditorPlaceholder-root { display: none; }'}

  .DraftEditor-root {
    min-height: 120px;
    padding: ${props => props.theme.spacing(1.5)};
  }
`;

export const EditorHeader = styled.div`
  display: flex;
`;
