import styled from 'styled-components';

export const ActionsFooter = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Description = styled.p`
  margin-top: ${props => props.theme.spacing(3)};

  em {
    color: ${props => props.theme.color.primary.main};
    font-weight: ${props => props.theme.fontWeight.bold};
    font-style: normal;
  }
`;

export const Summary = styled.div`
  text-transform: uppercase;
`;

export const Title = styled.div`
  font-size: ${props => props.theme.fontSize.xsmall};
  margin-bottom: ${props => props.theme.spacing(1)};
`;

export const Value = styled.div`
  color: ${props => props.theme.color.secondary.main};
  font-size: ${props => props.theme.fontSize.large};
`;
