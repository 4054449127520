/* prettier-ignore */
/* tslint:disable:quotemark */
export default {
  common: {
    button: {
      add: "Add",
      addAnotherLanguage: "Add Another Language",
      addContent: "Add Content",
      addEmail: "Add Email",
      addNewExperienceAbroad: "Add Experience Abroad",
      addNewRole: "Add Position",
      addPhone: "Add Phone Number",
      addSisterRole: "Position in Sister Company",
      addSocialNetwork: "Add Social Network",
      addDocument: "Add Document",
      agreeContinueNDA: "AGREE AND CONTINUE",
      agreeContinue: "INTERESTED AND AVAILABLE",
      agreeRegisters: "Agree",
      cancel: "Cancel",
      chooseDocument: "Choose document",
      close: "Close",
      countedHide: "Hide ({{count}})",
      countedViewMore: "View More ({{count}})",
      delete: "Delete",
      deleteSisterCompany: "Delete Sister Company",
      discardAndClose: "Discard & Close",
      done: "Done",
      downloadContract: "Download Contract",
      edit: "Edit",
      endMonth:"End of Month",
      help: "Help",
      hideDetails: "HIDE DETAILS",
      hideAllDetails: "HIDE All DETAILS",
      login: "Login",
      next: "Next",
      no: "No",
      notAvailable: "No, I'm not Available",
      notInterested: "No, I'm not Interested",
      ok: "Ok",
      opportunitiesPlacements: "Opportunities & Placements",
      retryPwdReset: "Reset Password",
      save: "Save",
      saveReport: "Save Report",
      sendApplicationNow: "Send Application to Atreus now",
      sendReport: "Send Report",
      showDetails: "SHOW DETAILS",
      showAllDetails: "SHOW All DETAILS",
      signIn: "Sign In",
      signUp: "Sign Up",
      skip: "Skip",
      startWizardNow: "Edit CV",
      submit: "Submit",
      updateCV: "Update CV",
      uploadProfilePhoto: "Upload Profile Photo",
      viewDetails: "View Details",
      viewPaymentDetails: "View Payment Details",
      viewSubmittedCV: "Submitted Atreus Profile",
      yes: "Yes",
      uploadFile: "Upload",
      submitApplication: "I have entered my resume completely and truthfully",
      applyNow: "APPLY NOW",
      back: "BACK",
      deleteMyProfile: "Delete my account",
      deleteMyProfileNow: "Delete my Profile Now",
      createCV :"Export my profile as PDF",
      profilePlantManager: "Plant Manager",
      instructions: "Instruction",
      profileIT: "IT-Management",
      uploadInvoice: "Upload Fee Invoice",
      uploadExpenses: "Upload Traveling Expenses",
      howTo: "How To",
      downloadPdf: "Download Pdf",
      addBreak: "+ Add Break",
      requestNewLink: "Request new link",
    },
    columnTitle: {
      activity: "Activity",
      companyName: "Company Name",
      contactEmail: "Contact Email",
      contactName: "Contact Atreus",
      contactPhone: "Contact Phone",
      day: "Day",
      details: "Details",
      jobTitle: "Job Title",
      nda: "NDA",
      period: "Period",
      placementName: "Placement",
      opportunity: "Opportunity",
      status: "Status"
    },
    error: {
      http: {
        "40001": "Invalid Username or Password",
        "50301": "Server connection error"
      },
      validation: {
        const: {
          confirmPassword: "”Password” and “Confirm Password” must match",
        },
        dependencies: {
          default: "$t(error.validation.required.default)"
        },
        format: {
          default: "Invalid {{format}} format"
        },
        formatMinimum: {
          default: "Invalid value",
          dateTo: "End date must be after start date"
        },
        invalid: "Invalid data",
        minLength: {
          de: "$t(error.validation.required.de)",
          en: "$t(error.validation.required.en)",
          default: "$t(error.validation.required.default)"
        },
        pattern: {
          password: "Password must be at least 6 characters long and contain at least 1 uppercase character, 1 lowercase character, 1 numeric character and 1 special character",
          default: "$t(error.validation.invalid)"
        },
        required: {
          de: "German value is mandatory",
          en: "English value is mandatory",
          default: "This field is required"
        },
        type: {
          default: "$t(error.validation.invalid)"
        },
        minItems: {
          companiesInGroup: "Please select at least one company here"
        },
        minimum: {
          dailyRateTo: "Value should be greater than zero",
          dailyRateFrom: "Value should be greater than zero"
        }
      },
      file: {
        typeAndSize : "Please upload the document as a PDF with a maximum file size of 15MB. Thank you."
      }
    },
    label: {
      abroadAssignmentType: "Type of Assignment Abroad",
      acceptPrivacy : "Accept Confidentiality Statement",
      acceptTerms : "Accept Principles of Collaboration",
      agreed: "Agreed",
      addPositionWithinCompany: "Add position within company",
      groupInformation: "Group companies",
      annualTargetIncome: "Annual Target Income",
      applicationBody: "Application Text",
      applied: "Application Ongoing",
      assistantPhone: "Assistant Phone",
      atreusPermanentPosition: "Atreus Permanent Position",
      availabilityComment: "Availability Comment",
      availableFrom: "Available from",
      billing: "Billing",
      budgetResponsibility: "Cost or Purchasing Budget Responsibility",
      businessPerformance: "Business Performance",
      category: "Category",
      certificate: "Certificate",
      city: "City",
      closedd: "Closed",
      comingSoon: "Coming Soon",
      comments: "Comments",
      company: "Company",
      companies: "Companies",
      companyGroup: "Company Group",
      companyInformation: "Company Information",
      companyName: "Company Name",
      companySituation: "Context",
      confirmPassword: "Confirm Password",
      contactDetails: "Contact Details",
      contactPerson: "Your contact at Atreus",
      contractType: "Contract Type",
      coreCompetencies: "Core Competences",
      coreCompetenciesMustHave: "Core Competencies (must have)",
      coreCompetenciesNiceToHave: "Core Competencies (nice to have)",
      countries: "Countries",
      country: "Country",
      countryAbroad: "Experience Abroad",
      cityCountry: "Headquarters",
      posLocation: "Location",
      courseOfStudies: "Field of Studies",
      currencyTargetIncome: "$t(label.annualTargetIncome)",
      currencyTargetIncomeTitle: "Annual Target Income in Euro",
      dailyActivities: "Daily Activity Report",
      dailyActivitiesProgress: "Daily Activity Report",
      dailyOverview: "Daily Overview",
      dailyRate: "Daily Rate",
      dailyRateFrom: "Daily Rate min.",
      dailyRateTo: "Daily Rate max.",
      dateFrom: "Date from",
      dateOfBirth: "Date of Birth",
      dateTo: "Date to",
      daysOfUse: "Days per Month",
      de: "$t(label.german)",
      degree: "Degree",
      description: "Description",
      details: "Details",
      documentDate: "Document Date",
      email: "Email",
      emailAddress: "Email Address",
      en: "$t(label.english)",
      english: "English",
      executiveSummary: "Executive Summary",
      expectedStart: "Expected Start Date",
      experienceAbroad: "Experience Abroad",
      facebook: "Facebook",
      fax: "Fax",
      firstName: "First Name",
      fileName: "File Name",
      fullName: "Full Name",
      function: "Function",
      gender: "Gender",
      german: "German",
      highestEducation: "This is my highest educational attainment",
      homePhone: "Home Phone",
      hours: "Hours",
      industry: "Industry",
      industryDescription: "Industry Description",
      inProgress: "In Progress",
      institution: "Name of School",
      itSkillsRemarks: "Additional Information on IT Skills",
      jobTitle: "Job Title",
      placeOfOccupation: "Place of Occupation",
      countryOfOccupation: "Country of Occupation",
      language: "Language",
      languagesMustHave: "Languages (fluent or better) (must have)",
      languagesNiceToHave: "Languages (fluent or better) (nice to have)",
      lastCVUpdate: "Your Last Update",
      lastName: "Last Name",
      level: "Level",
      linkedin: "LinkedIn",
      loading: "Loading",
      location: "Location",
      logActivity: "Log Activity",
      lost: "Lost",
      mandateClosed: "Mandate closed",
      mandateInPrepration: "Mandate in preparation",
      mobileNumber: "Mobile Phone Number",
      mobilePhone1: "Mobile Phone 1",
      mobilePhone2: "Mobile Phone 2",
      mobility: "Mobility",
      mobilityComment: "Mobility Comment",
      monthlyBill: "Monthly Bill",
      monthlyReport: "Monthly Report",
      monthlyReportClientRelation: "Managing director, board, etc.",
      monthlyReportGoals: "Planned, achieved",
      monthlyReportSituation: "What is going well? What is going less well?",
      monthsDuration: "Duration in Months (max.)",
      monthsSpent: "Months Spent",
      name: "Name",
      nationality: "Nationality(ies)",
      new: "New",
      newPassword: "New password",
      notAgreed: "Not Agreed",
      notificationReceived: "Notification Received",
      numberOfEmployees: "Number of Employees",
      numberOfMonths: "{{count}} months",
      numberOfReports: "Number of Direct Reports",
      officeEmail: "Office Email",
      ongoing: "Ongoing",
      operationArea: "Operational Area",
      operationalReadiness: "Desired Type of Opportunity",
      otherPhone: "Other Phone",
      ownCompany: "Own Company",
      ownershipStructure: "Ownership Structure",
      password: "Password",
      personalCompetencesMustHave: "Personal Competences (must have)",
      personalCompetencesNiceTotHave: "Personal Competences (nice to have)",
      personnelResponsibility: "Personnel Responsibility",
      phone: "Phone",
      plannedPlacement: "Planned Placement Type",
      portalLanguage: "Portal & Communication Language",
      position: "Position",
      positionInformation: "Position Information",
      positionTitle: "Title of Position",
      present: "Present",
      presentation: "Presentation",
      presented: "Presented",
      primaryEmail: "Primary Email",
      privateEmail: "Private Email",
      provisionalMandate: "Provisional Mandate",
      readMore: "Read More",
      referenceComment: "Comment Reference",
      referenceContact: "Contact Details Reference",
      referenceName: "Name Reference",
      referencePosition: "Position Reference",
      reportingTo: "Reporting to (Position)",
      requested: "Requested",
      revenueResponsibility: "Revenue Responsibility",
      salutation: "Salutation",
      select: "Select",
      selectDate: "Select Date",
      selectMonth: "Select Month",
      sisterCompanyName: "Sister Company Name",
      skypeId: "Skype ID",
      socialNetwork: "Social Network",
      specialExperience: "Experience in Special Situations (must have)",
      specialExperienceNiceTo: "Experience in Special Situations (nice to have)",
      startingDate: "Starting date",
      startNow: "Start now",
      street: "Street",
      timesheetMissing: "open",
      timesheetSubmitted: "done",
      title: "Title",
      trainingTitle: "Training Title",
      turnoverInMillion: "Turnover",
      twitter: "Twitter",
      typeOfSchool: "Type of School",
      typeOfTraining: "Type of Training",
      typeSomething: "Type something ...",
      uploadCV : "Upload CV *",
      website: "Website",
      websiteUrl: "Website URL",
      won: "Won",
      workPhone: "Work Phone",
      xing: "Xing",
      youtubeVideo: "Link to Application Video",
      zip: "Zip",
      opportunityUpdates: "Opportunity Updates",
      cvUpdates: "CV Updates",
      appointmentUpdates: "Appointment Updates",
      month: "Month",
      months: "Months",
      year: "Year",
      years: "Years",
      permanent: "PERMANENT",
      interim: "INTERIM",
      consulting: "CONSULTING",
      selfEmployed: "SELF-EMPLOYED",
      board: "BOARD",
      careerBreak: "CAREER BREAK",
      industryFocuses: "Industry Focuses",
      specialistFocuses: "Specialist Focuses",
      managementMethods: "Management Methods",
      showMore: "Show more",
      showLess: "Show less",
      competenceInfoText: "Please describe your focuses precisely and to the point using bullet points.",
      registeredSuccessfully: 'Thank you. Your application will be reviewed. We kindly ask you to be patient for a few days!',
      emailAlreadyExists: "This username already exists. Please use the <a href='/forgot-password'>\"Forgot Password\"</a>-function, if you do not know your password anymore.",
      maxUploadSizeError: "Unfortuntely your file exceeds our max. upload limit of 10 Megabyte.",
      uploadDocRequired: "CV is necessary",
      principlesOfCollaboration: "Principles Of Collaboration",
      confidentialityStatement: "Confidentiality Statement",
      deletionReason: "Reason for Deletion",
      role: "Role",
      cvType: "CV Type",
      howLongDidYouWork: "How long did you work today?",
      didYouTravelFromSite: "Did you travel from your home to the project venue today?",
      didYouTravelFromHome: "Did you travel from project venue to your home today?",
      willYouStay: "Will you stay overnight at your project venue today?",
      placeOfWork: "Place of Work",
      businessTrip: "Have you conducted any other business travel by order of the client today?",
      homeOffice: "Work from Home",
      fee: "Fee",
      travelExpensesFlatRate: "Travel Expenses",
      flatRate: "Flat Rate",
      firstLevel: "Travel Flat Rate (One Way)",
      secondLevel: "Overnight Flat Rate",
      travelTravelExpensesFlatRate: "	Total Travel Expenses Flat Rate",
      bonus: "Bonus",
      subTotal: "Subtotal",
      invoiceAmount: "Invoice Amount",
      verify: "Verify",
      vat: "VAT",
      days: "Days",
      abort: "Abort",
      submit: "Submit",
      saveSuccess: "Saved Successfully!",
      atreus: "ATREUS",
      portal: "PORTAL",
      manualReleaseSuccess: "Success",
      automaticReleaseSuccess: "Welcome to the Atreus Portal. Let's start!",
      welcomeVideo: "WELCOME TO ATREUS",
      exampleProfiles: "Instructions & Sample Profiles",
      chooseColor: "Choose your color scheme",
      chooseLanguage: "Choose your language and CV type",
      chooseCoverSheet: "Choose your cover sheet",
      warning: "Warning:",
      accountLocked: 'Account Locked',
      workStart: 'Work from',
      workEnd: 'Work to',
      pauseStart: 'Break from',
      pauseEnd: 'Break to'
    },
    link: {
      aboutAtreus: "About Atreus",
      backToLogin: "Back to Login",
      contact: "Contact",
      downloadPDF: "Download PDF",
      forgotPassword: "Forgot password?",
      home: "Home",
      legalNotice: "Legal notice",
      noAccountSignUp: "Don't have an account? Sign Up",
      opportunitiesPlacements: "Opportunities & Placements",
      privacyPolicy: "Data Protection",
      projects: "Projects",
      resume: "My CV",
      tutorials: "Tutorials",
      viewApplicantCV: "View Atreus Profile",
      atreusDE: 'ATREUS.DE',
    },
    loremIpsum: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    menu: {
      loginTitle: "Login now",
      loginSettings: "Login Settings",
      logout: "Sign Out",
      myProfile: "My Profile",
      notificationSettings: "Notification Settings",
      downloadFAQ: "FAQ / Manual",
      myDocuments: "My Documents",
    },
    message: {
      activityDescription: "Please describe your activities today:",
      activityManagement: "Centrally manage your Opportunities and Placements here.",
      addMoreInformation: "Please add more information",
      afterEachMonth: "After each month",
      anythingElseToAdd: "Here is room for publications, honorary offices, etc.",
      assistantWidget: "This feature will help you stay organized very soon. Look forward to it.",
      changePasswordError: "Your password could not be changed. Please contact Atreus.",
      changePasswordSuccess: "You password has been successfully updated. Please return to the login page to use your new password.",
      changePasswordTokenExpiredError: "Your reset password token has expired. You can retry to reset your password. ",
      confirmDelete: "Are you sure you want to DELETE this? You won't be able to restore it.",
      confirmDeleteWithOneWexp: "Do you really want to delete this work experience? The company will also be deleted.",
      createPasswordTokenExpiredError: "<div>The link used is invalid or expired.</div><div>Please request a new one. We will send it to the e-mail address you provided as your user name promptly. Check your spam-folder too. Thank you.</div>",
      dailyActivities: "Please log your daily activities by clicking on the days you worked on the calendar.",
      describeResponsibilities: "Please describe your responsibilities, tasks and achievements in this position.",
      doYouCurrentlyWork: "Is this a current position without a known end date?",
      documentsMessage: "Info: If you would like to delete one or more of your documents, please contact us via <a href='mailto:support@atreus.de'>support@atreus.de</a>. To do so, tell us the exact file name(s) you want to have deleted.",
      duringMonth: "During the month",
      feedback: "Help us improve this service. <a href='mailto:feedback@atreus.de?subject=Atreus Portal feedback&body=Please provide your feedback here. Thank you.'>Give Feedback</a>",
      fillOutDetails: "Fill out the details like Education, Experience, Skills and other Sections to create your Resume.",
      forgotPassword: "Enter your or the <strong>e-mail address</strong> that you used to register. We'll send you an email with your username and a link to reset your password.",
      highlightPrimaryEducation: "Add new education phase",
      highlightWorkExperience: "Add a new work experience",
      interestThankYou: "You are interested in this opportunity and available on short notice - we are very happy to hear!",
      introduceYourself: "Please introduce yourself in a few words",
      listSkills: "Add IT Skills",
      loginError: "Invalid username or password. Please try again or contact atreus at <a href='mailto:support@atreus.de'>support@atreus.de</a>",
      maxSelections: "Selection limit reached",
      monthlyBill: "Please generate a bill. This is only possible after daily activities and monthly report were submited.",
      monthlyReport: "Please fill in the monthly report during the month and submit it after each month.",
      nda: "$t(loremIpsum)",
      newOpportunities: "Before you can see details of this opportunity below you need to agree to our NDA",
      noOptions: "No Options",
      notificationsWidget: "Soon this is where you'll find all your portal communication nicely organized.",
      otherRoles: "Did you hold other positions in this company?",
      parentOrSisterCompany: "Did you hold other positions in a sister company?",
      referenceQuestion: "Do you want to add a reference to this position?",
      resetPwdError: "Your password could not be reset. Please contact Atreus at support@atreus.de",
      resetPwdSuccess: "If there is a user with the provided email in our system, you will receive a message with instructions on resetting your password.",
      sectionIsEmpty: "This section is empty!",
      selectItSkills: "Select your IT skills from the list",
      speakForeignLanguages: "Do you speak foreign languages?",
      supportMessage: "If you still need help, contact <a href='mailto:support@atreus.de'>Atreus Support</a>",
      thankYouVeryMuch: "Thank you very much for your assistance.",
      threeCoreCompetencies: "What were your core competences (max. 3) that helped you to succeed in this position? (Values depend on selected function.)",
      timesheetDescription: "When you will be assigned an active placement, you will be able to add your time tracking data here.",
      timeTrackingFinishMonthConfirm: "After this step you will not be able to edit any of the current month data. Are you sure you want to finalize the current month?",
      untilThenUpdateCV: "Once you have expressed your interest in this way, we will contact you to discuss further details. In the meantime, it would be helpful if you update your CV in the portal.",
      useFieldBelowForApplication: "Please use the following field to provide us with a brief summary of which of your strengths, competencies and skills match the requirements of this client project.",
      workAbroadQuestion: "Did you work abroad as part of this position?",
      writeEducationDescription: "Please describe your education",
      deleteQuestion: "You want us to delete your account? Even though we don't like to lose you as a manager, we will of course comply with your request.",
      reasonOfDeletion: "Please help us to keep improving by briefly letting us know the reason behind your decision. Of course, this information is optional.",
      ensuredDeletion: "Together with your account, all related data will of course also be deleted. As soon as this is completed, you will receive a written confirmation.",
      manyThanks: "Many Thanks",
      deletionRequestSubmitted: "Your request has been submitted.",
      deletionConfirmationEmail: "You will receive a confirmation email after we have deleted all data.",
      tripDescription: "Please settle any travel expenses incurred for business trips ordered by the customer directly with the customer in accordance with your contract, taking into account the customer's travel expense guidelines.",
      noReporting: "No reporting via the portal is provided for this placement.",
      invoiceContentHeader: "According to our contract and your entries in the activity report, you can invoice the following for this month:",
      uploadFileMsg1: "Log your activity reports each day you have worked. Please note that these entries cannot be changed after you have submitted your invoice.",
      uploadFileMsg2: 'Answer all questions in the <b>"Monthly Report"</b> section so that we are informed about how things are going and can assist if necessary.',
      uploadFileMsg3: 'Create your invoice guided by the figures in the <b>"invoice contents"</b> section on the left and upload it. A parser will show you if we have found any formal errors. Correct them if necessary.',
      uploadFileMsg4: 'Click on <b>"Submit"</b> to send Atreus the activity reports, the monthly report and the invoice for review.',
      submitReportMsg1: 'Thank you for submitting your invoice. Your details are currently being checked by our accounting department. Once this has been completed, this months status will be set to "completed".',
      submitReportMsg2: ' Should you have any further questions regarding the activity reports, monthly reports or your invoice, please contact your Atreus representative.',
      finishReportMsg1: "Your invoice for this month has been completed successfully.",
      finishReportMsg2: "Should you have any further questions regarding the activity reports, monthly reports or your invoice, please contact your Atreus representative.",
      monthlyReportSaveConfirm: "Are you sure you want to save the monthly report?",
      profilePicNote: "Note: Please use a <b>square</b> profile picture.",
      newApplicantQuestion1: "Have you been or are you interim and/or entrepreneurial?",
      newApplicantQuestion2: "Have you worked in consulting firms with >10 employees as a project manager or partner?",
      newApplicantQuestion3: "Have you held management positions in internationally active companies? (Mid-sized company/corporate group)",
      newApplicantQuestion4: "Have you worked as a managing director in companies with sales of >30 million euros?",
      newApplicantQuestion5: "Have you worked in C-level functions in companies with sales of >100 million EUR?",
      newApplicantQuestion6: "Have you served in C-level roles in companies with >300 employees?",
      newApplicantQuestion7: "Do you have any transformation experience as a project member or leader of more than 12 months in topics such as restructuring, digitization, turnaround, IT transformations, etc.?",
      newApplicantQuestion8: "Have you worked as a project manager in projects with budgets >1 million EUR or >20 employees?",
      manualRelease: "Thank you. Your application will be reviewed. We kindly ask you to be patient for a few days!",
      automaticReleaseHeader: "Thank you for answering the previous questions. To enable us to add you to the Atreus Network, please enter your digital profile in the portal now. You will receive an email with access data to the Atreus Portal in the next few minutes (please check your spam folder if necessary).<br/><br/>To ensure that you are optimally matched to possible client inquiries, it is important that you enter your background in detail. Please follow the steps below:",
      automaticReleaseFooter: "We will review your profile and consider you in our searches from this point onwards. We look forward to working with you.<br/><br/>Your Atreus Team",
      videoMessage: ' This film contains all the important information for your application process. At the end of this video, you get to the next step of your application by clicking “Continue”.',
      linkedinProfile: 'Please enter the URL of your LinkedIn profile here',
      xingProfile: 'Please enter the URL of your XING profile here',
      warningMsg: "Your account will be locked for 60 minutes after 3 failed attempts.",
      lockedMsg1: "Unfortunately, you have exceeded the maximum number of login attempts.",
      lockedMsg2: "To protect your data, your account has been locked for one hour.",
      lockedMsg3: "If you have any questions, please contact us at <a href='mailto:support@atreus.de' target='_blank'>support@atreus.de</a>",
      travelExpenseConfirmation1: "Are travel expenses incurred for the month?",
      travelExpenseConfirmation2: "If yes, please use the UPLOAD button. If no, continue using the SUBMIT button.",
      welcomeEmailSent: 'RECEIVED NEW LINK?<br /><br/>Check your spam-folder too. Thank you.<br /><br/>If a password has already been assigned, please use the "Back to login" button and there, if necessary, the "Forgot password" function.',
    },
    placeholder: {
      profileImage: "Profile Image"
    },
    title: {
      activityManagement: "Activity Management",
      addContent: "Add Content",
      additionalInformation: "Additional Information",
      address: "Address",
      addressInformation: "Address Information",
      applicantCV: "Applicant CV",
      applicationDate: "Application Date",
      applicationDetails: "YOUR INTEREST",
      atreusOffers: "Atreus Offers for Interim Manager",
      atreusOpportunity: "Opportunity from Atreus",
      availabilityAndRates: "Availability & Daily Rate",
      backToLogin: '› Back to Login',
      commentPersonal: "Personal Requirements",
      contactDetails: "Contact Details",
      createPassword: "Create your password",
      currentPlacements: "Current Placements",
      dailyRate: "Daily Rate in Euro",
      dailyRateSubTitle: "for a placement of a period of six to twelve months",
      descriptionCompany: "Company Description",
      documentUpload: "Document-Upload",
      earliestAvailability: "Earliest Availability",
      editAvailabilityAndRates: "Availability & Daily Rate",
      education: "Education",
      addCompanyGroup: "Add company group",
      executiveSummary: "Executive Summary",
      forgottenPassword: "Forgotten password?",
      fullName: "Full Name",
      itSkills: "IT Skills",
      languages: "Languages",
      loginTitle: "Login now",
      mobility: "Mobility",
      monthlyReportActions: "Processed tasks and actions",
      monthlyReportClientRelation: "Relationship with client",
      monthlyReportGoals: "Goal achievements, results",
      monthlyReportInhibitions: "Critical inhibitory factors",
      monthlyReportNeededSupport: "Support necessary",
      monthlyReportOtherRemarks: "Other remarks",
      monthlyReportSituation: "Situation",
      myAccount: "My Account",
      myAssistant: "Digital Assistant",
      myResume: "My CV",
      newOpportunities: "One new Opportunity from Atreus",
      newOpportunities_plural: "{{count}} new Opportunities from Atreus",
      nonDisclosure: "Non-Disclosure Agreement",
      notifications: "Notifications",
      opportunities: "Opportunities",
      opportunityGeneralInfo: "General information about the opportunity",
      opportunityManagement: "Opportunity Management",
      otherActivities: "Other Activities",
      personalDetails: "Personal Details",
      personalInformation: "Personal Information",
      placements: "Placements",
      placementOverview: "Placement Overview",
      professionalExperience: "Work Experience",
      addWorkExperience: "Add Work Experience",
      references: "References",
      resetPassword: "Reset password",
      responsibilitiesAndTasks: "Your Responsibilities & Tasks",
      resumeProgress: "Your CV Progress",
      signIn: "Sign In",
      socialNetwork: "Social Network",
      technicalRequirement: "Professional Requirements",
      timeTrackingFinishMonthConfirm: "Finalize current month?",
      upcomingEvents: "Upcoming Events",
      updatePassword: "Change your password",
      welcome: "Welcome!",
      workExperience: "Work Experience",
      yourApplicationForThisOpp: "Your interest in this opportunity",
      yourMonthlyChecklist: "Your monthly checklist",
      agreementTerms: 'Principles of Collaboration - please read and accept below',
      dataProtection: 'Confidentiality Statement - please read and accept below',
      agreementTitle: 'Atreus terms of service',
      notificationInformation: "Notification Settings",
      competenceProfile: "Competence Profile",
      profileAgreementTitle: "Current Principles of Collaboration & Confidentiality Statement",
      deleteMyProfile: "Delete my Profile",
      pdfConfigTitle: "PDF Export Configurator",
      invoiceContent: "Invoice Content",
      invoiceUpload: "Invoice Upload",
      monthlyReportSaveConfirm: "Save Monthly Report",
      newApplicantQuestions: "PLEASE ANSWER OUR QUESTIONS",
      mainCVActions: "My CV Actions",
    },
    options: {
      billingStatus: {
        "100": "Open",
        "200": "Submitted",
        "250": "Submitted",
        "300": "Closed"
      },
      subcategories: {
        "400": "CV German",
        "500": "CV English",
        "1000": "References",
        "1100": "Work Certificates",
        "1300": "Others"
      }
    }
  }
};
