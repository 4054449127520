import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Table from 'components/Table';
import { useLocale, useTranslation } from 'hooks';
import { getTimetrackingTableColumns } from './config';
import { format } from 'date-fns';
import MonthSelector from './../MonthSelector';
import { Button } from 'components/buttons';

function TimeTrackingTab({
  month,
  placementDetails,
  timeTrackingOptions,
  year,
  onMonthChanged,
  recordedTimeOptions,
  language,
  monthlyReportId,
  isMandate,
  onSaveMonthlyReport
}) {
  const { t } = useTranslation();
  const locale = useLocale();
  // RTEXT-620
  const reportBillingViaPortal =
    placementDetails && placementDetails.placement.reportBillingViaPortal;

  const monthlyReport =
    placementDetails && placementDetails.months[`${year}_${month}`]
      ? Object.assign({}, placementDetails.months[`${year}_${month}`].monthlyReport)
      : {};
  const { billingStatus } = monthlyReport;

  const columns = useMemo(() => {
    const tableColumns = getTimetrackingTableColumns(t, monthlyReportId, language);
    isMandate &&
      billingStatus === '100' &&
      tableColumns.splice(tableColumns.length - 1, 0, {
        Header: () => (
          <Button
            color="primary"
            onClick={() => {
              onSaveMonthlyReport(placementDetails.placement.id, {
                ...monthlyReport,
                billingStatus: '200',
                withTimeStamp: true
              });
            }}
          >
            {t('button.submit')}
          </Button>
        ),
        id: 'submit',
        width: '150px',
        minWidth: '150px'
      });
    return tableColumns;
  }, [
    t,
    monthlyReportId,
    language,
    monthlyReport,
    placementDetails.placement.id,
    billingStatus,
    isMandate
  ]);

  const data = useMemo(() => {
    var data = [];
    var timeTracking =
      placementDetails && placementDetails.months[`${year}_${month}`]
        ? placementDetails.months[`${year}_${month}`].timeTracking
        : null;
    if (timeTracking) {
      var sortedTimeTracking = timeTracking.sort(function (a, b) {
        if (a.date < b.date) {
          return 1;
        }
        if (b.date < a.date) {
          return -1;
        }
        return 0;
      });
      timeTracking &&
        timeTrackingOptions &&
        sortedTimeTracking.map(item => {
          // RTEXT-618
          // showing the "new" "Tätigkeitsnachweise" in the list
          let options = item.timeTracking ? timeTrackingOptions : recordedTimeOptions;
          var ttOptionIndex = options.findIndex(ttitem => {
            return ttitem.key === (item.timeTracking ? item.timeTracking : item.recordedTime);
          });
          if (ttOptionIndex >= 0) {
            data.push({
              date: format(new Date(item.date), 'd MMM yyyy', { locale: locale }),
              activity: options[ttOptionIndex].value,
              description: item.description
            });
          }
          return 0;
        });
    }
    return data;
  }, [locale, month, placementDetails, year, timeTrackingOptions, recordedTimeOptions]);

  return (
    <React.Fragment>
      {/* RTEXT-620 */}
      {reportBillingViaPortal !== '100' && (
        <MonthSelector
          month={month}
          placementDetails={placementDetails}
          year={year}
          onMonthChanged={onMonthChanged}
        />
      )}
      <Table columns={columns} data={data} timeTracking={true} />
    </React.Fragment>
  );
}

TimeTrackingTab.propTypes = {
  className: PropTypes.string,
  placementDetails: PropTypes.object,
  month: PropTypes.number,
  year: PropTypes.number,
  timeTrackingOptions: PropTypes.array,
  syncing: PropTypes.bool,
  onMonthChanged: PropTypes.func,
  recordedTimeOptions: PropTypes.array,
  language: PropTypes.string,
  monthlyReportId: PropTypes.string,
  isMandate: PropTypes.bool,
  onSaveMonthlyReport: PropTypes.func
};

export default TimeTrackingTab;
