import { connect } from 'react-redux';
import * as metaSelectors from 'redux-modules/meta/selectors';
import * as opportunitiesSelectors from 'redux-modules/opportunities/selectors';
import * as opportunitiesActions from 'redux-modules/opportunities/actions';
import SubmitDialog from './SubmitDialog';

function mapStateToProps(state) {
  return {
    indexedMeta: metaSelectors.selectIndexedMeta(state),
    syncing: opportunitiesSelectors.selectItemSyncStatus(state)
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onSubmit: data => {
      dispatch(opportunitiesActions.acceptOpportunity(data));
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SubmitDialog);
