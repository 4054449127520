import React from 'react';
import createSvgIcon from '../createSvgIcon';

export default createSvgIcon(
  <React.Fragment>
    <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
      <g stroke="currentColor" strokeWidth="1.5">
        <path d="M8.01524082,9.5 L8.01524082,28.5 L24.0152408,28.5 L24.0152408,9.5 M5,7.5 L27.0152408,7.5 M12.0152408,4.5 L20.0289896,4.5 M18.0152408,12.6847483 L18.0152408,23.6847483 M14.0152408,12.6847483 L14.0152408,23.6847483" />
      </g>
    </g>
  </React.Fragment>,
  'Trash'
);
