import React from 'react';
import PropTypes from 'prop-types';
import { SvgIcon } from './style';

// @TODO add size prop support (discuss available sizes with design team)
export default function createSvgIcon(path, displayName, options) {
  const Component = React.forwardRef((props, ref) => (
    <SvgIcon ref={ref} viewBox="0 0 32 32" {...options} {...props}>
      {path}
    </SvgIcon>
  ));

  if (process.env.NODE_ENV !== 'production') {
    Component.displayName = `${displayName}Icon`;
  }

  Component.propTypes = {
    displayName: PropTypes.string
  };

  return React.memo(Component);
}
