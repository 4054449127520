import styled from 'styled-components';
import { Button } from 'components/buttons';
import { FormRow as Row } from 'components/styledComponents';

export const UploadButton = styled(Button)`
  width: 100%;
  height: 100%;
`;

export const FlexBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Text = styled.p`
  color: ${props => (props.color ? props.color : props.theme.color.primary.main)};
  position: absolute;
  font-size: ${props => props.theme.fontSize.small};
  margin: 5px 0;
`;

export const FormRow = styled(Row)`
  margin: 10px 0;
`;

export const TextContainer = styled.div`
  position: relative;
`;
