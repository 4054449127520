import styled from 'styled-components';
import { IconButton } from 'components/buttons';
import { FormContainer } from 'components/styledComponents';

export const DeleteButton = styled(IconButton)`
  /**
   * @TODO find a better way for spacing
   * https://jira.atreus.de/browse/MILK-643
   */
  align-self: flex-start;
  margin: ${props => props.theme.spacing(2.375, 0)};
  transform: translateX(${props => props.theme.spacing(1)});
`;

export const LanguageWrapper = styled.div`
  align-items: center;
  display: flex;

  ${FormContainer} {
    flex-grow: 1;
  }
`;
