import { connect } from 'react-redux';
import * as resumeActions from 'redux-modules/resume/actions';
import * as resumeSelectors from 'redux-modules/resume/selectors';
import * as uiStateSelectors from 'redux-modules/uiState/selectors';
import MyAccountPage from './MyAccountPage';

function mapStateToProps(state) {
  return {
    language: uiStateSelectors.selectLanguageCode(state),
    loading: resumeSelectors.selectLoadState(state),
    lang: uiStateSelectors.selectLanguage(state),
    personalDetails: resumeSelectors.selectPersonalDetails(state),
    syncErrors: resumeSelectors.selectSyncError(state),
    syncing: resumeSelectors.selectSyncState(state)
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onSave: (data, failCallback) => dispatch(resumeActions.savePersonalDetails(data, failCallback))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(MyAccountPage);
