import styled from 'styled-components';

export const Container = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  line-height: 4em;
`;

export const Title = styled.span`
  color: ${props => props.theme.color.primary.main};
  font-size: ${props => props.theme.fontSize[props.size]};
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: uppercase;
`;
