import localStorage from 'local-storage-fallback';
import * as types from './types';

const initialState = {
  authToken: localStorage.getItem('authToken') || null,
  resetPasswordToken: null,
  isResetPasswordTokenValid: null,
  error: null,
  nda1: {},
  nda2: {},
  syncing: false,
  loading: false,
  registerStatus: 'none',
  showAgreement: localStorage.getItem('showAgreement') || false,
  isAutomaticRelease: false,
  videoURLs: JSON.parse(localStorage.getItem('videoURLs')) || null,
  resendWelcomeEmail: false,
  canSkipAgreement: JSON.parse(localStorage.getItem('canSkipAgreement')) || false
};

function auth(state = initialState, action) {
  switch (action.type) {
    case types.LOGIN_FAILED:
      return {
        ...state,
        error: action.payload.error,
        syncing: false
      };
    case types.LOGIN_INITIATED:
      return {
        ...state,
        error: null,
        syncing: true
      };
    case types.LOGIN_SUCCESSFUL:
      return {
        ...state,
        authToken: action.payload.token,
        syncing: false,
        showAgreement: action.payload.showAgreement,
        videoURLs: action.payload.videoURLs,
        canSkipAgreement: action.payload.canSkipAgreement
      };
    case types.LOGOUT:
      return {
        ...state,
        authToken: null,
        showAgreement: null
      };
    case types.GET_REGISTER_DATA:
      if (action.payload.callback) {
        action.payload.callback(state.registerData);
      }
      return state;
    case types.SET_REGISTER_DATA:
      return {
        ...state,
        registerData: action.payload.registerData
      };
    case types.RESET_PWD_RESET_STATUS:
      return {
        ...state,
        syncing: false,
        resetPwdStatus: null
      };
    case types.RESET_PWD_INITIATED:
    case types.UPDATE_PWD_INITIATED:
      return {
        ...state,
        syncing: true,
        resetPwdStatus: null
      };
    case types.VALIDATE_PWD_RESET_TOKEN:
      return {
        ...state,
        syncing: true,
        resetPasswordToken: action.payload.token,
        isResetPasswordTokenValid: null
      };
    case types.RESET_PWD_SUCCESSFUL:
    case types.UPDATE_PWD_SUCCESSFUL:
      return {
        ...state,
        syncing: false,
        resetPwdStatus: 'success'
      };
    case types.REGISTER_PORTAL_USER:
      return {
        ...state,
        syncing: true,
        loading: true
      };
    case types.REGISTER_PORTAL_USER_SUCCESS:
      return {
        ...state,
        syncing: false,
        loading: false,
        registerStatus: 'success',
        // RTEXT-726
        isAutomaticRelease: action.payload.isAutomaticRelease
      };
    case types.REGISTER_PORTAL_USER_FAILED:
      return {
        ...state,
        syncing: false,
        loading: false,
        registerStatus: 'failed'
      };
    case types.CHECK_EMAIL:
      return {
        ...state,
        syncing: true,
        loading: true,
        isNewUserEmail: null
      };
    case types.CHECK_EMAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        syncing: false,
        isNewUserEmail: 'success'
      };
    case types.CHECK_EMAIL_DUPLICATE:
      return {
        ...state,
        loading: false,
        syncing: false,
        isNewUserEmail: 'duplicate'
      };
    case types.CHECK_EMAIL_FAILED:
      return {
        ...state,
        loading: false,
        syncing: false,
        isNewUserEmail: 'failed'
      };
    case types.VALIDATE_PWD_RESET_TOKEN_SUCCESSFUL:
      return {
        ...state,
        syncing: false,
        isResetPasswordTokenValid: true
      };
    case types.RESET_PWD_FAILED:
    case types.UPDATE_PWD_FAILED:
      var newStatus = 'fail';
      var data = action.payload.error;
      if (data && data.error) {
        if (data.error.code === 'token_expired') {
          newStatus = 'fail_expired';
        }
      }
      return {
        ...state,
        syncing: false,
        resetPwdStatus: newStatus
      };
    case types.VALIDATE_PWD_RESET_TOKEN_FAILED:
      return {
        ...state,
        syncing: false,
        isResetPasswordTokenValid: false
      };
    case types.AGREE_TERMS:
      return {
        ...state,
        termsData: action.payload.termsData
      };
    case types.AGREEMENT_SUCCESS:
      return {
        ...state,
        agreementData: action.payload.agreementData
      };
    case types.NDA_LOADED1:
      return {
        ...state,
        nda1: {
          ...state.nda1,
          [action.payload.language]: action.payload.nda
        }
      };
    case types.NDA_LOADED2:
      return {
        ...state,
        nda2: {
          ...state.nda2,
          [action.payload.language]: action.payload.nda
        }
      };
    case types.REQUEST_NEW_LINK:
      return {
        ...state,
        resendWelcomeEmail: false
      };
    case types.REQUEST_NEW_LINK_SUCCESS:
      return {
        ...state,
        resendWelcomeEmail: true
      };
    case types.SKIP_AGREEMENT:
      localStorage.setItem('showAgreement', false);
      return {
        ...state,
        showAgreement: false
      };
    default:
      return state;
  }
}

export default auth;
