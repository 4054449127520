import React from 'react';
import PropTypes from 'prop-types';
import MuiTextField from '@material-ui/core/TextField';
import { useMuiError } from 'hooks';
import { defaultMuiProps } from '../config';

function TextField({ error, ...rest }) {
  return <MuiTextField {...rest} {...useMuiError(error)} />;
}

TextField.defaultProps = defaultMuiProps;

TextField.propTypes = {
  error: PropTypes.string
};

export default React.memo(TextField);
