import React, { useState, useEffect, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useForm, useTranslation } from 'hooks';
import { FormContainer, FormItem } from 'components/styledComponents';
import {
  FormRowContainer,
  Explain,
  ButtonContainer,
  Button,
  Section,
  FlexContainer
} from './style';
import { TextField } from 'components/inputs';
import { Subtitle } from 'components/pages/PlacementsPage/cards/style';
import Dialog from 'components/Dialog';
import MonthSelector from './../MonthSelector';
import { Border } from 'components/Placements/TimeTracking/TimeTrackingPopper/style';

const today = new Date();
const lastBusinessDayBefore = (year, month, day) => {
  var offset = 0;
  var result = null;
  if (day) {
    offset = day;
  } else {
    // get the next month to go backwards from there
    var date = new Date(year, month, 0);
    date.setMonth(date.getMonth() + 1);
    year = date.getFullYear();
    month = date.getMonth();
    offset = 0;
  }
  do {
    result = new Date(year, month, offset);
    offset--;
  } while (0 === result.getDay() || 6 === result.getDay());
  return result;
};

function MonthlyReport({
  className,
  placementDetails,
  year,
  month,
  loading,
  onMonthChanged,
  onSave,
  onSend,
  isMonthlyReportSent,
  setDisableMouseControls
}) {
  const { t } = useTranslation();
  const { currentMonth, currentYear } = placementDetails;
  //RTEXT-620
  const reportBillingViaPortal =
    placementDetails && placementDetails.placement.reportBillingViaPortal;

  const [monthlyReport, setMonthlyReport] = useState(
    placementDetails && placementDetails.months[`${year}_${month}`]
      ? Object.assign({}, placementDetails.months[`${year}_${month}`].monthlyReport)
      : {}
  );

  const [finishMonthOpen, setFinishMonthState] = useState(false);

  useEffect(() => {
    setMonthlyReport(
      placementDetails && placementDetails.months[`${year}_${month}`]
        ? Object.assign({}, placementDetails.months[`${year}_${month}`].monthlyReport)
        : {}
    );
  }, [placementDetails, year, month]);

  useEffect(() => {
    setFinishMonthState(false);
  }, [loading]);

  // RTEXT-620
  // reset calender to current month after sending monthly report
  useEffect(() => {
    if (isMonthlyReportSent) {
      onMonthChanged(new Date(`${currentYear}-${currentMonth}-01`));
    }
  }, [isMonthlyReportSent]);

  const { useInput, values } = useForm({
    initialValues: monthlyReport
  });

  // RTEXT-690
  const handleFocus = () => {
    setDisableMouseControls(true);
  }

  // RTEXT-690
  const handleBlur = () => {
    setDisableMouseControls(false);
  }

  const [disabled, finishMonthDisabled] = useMemo(() => {
    var disabled =
      placementDetails.currentMonth !== month ||
      placementDetails.currentYear !== year ||
      new Date(placementDetails.placement.startDate) > today;
    var finishMonthDisabled = disabled;
    if (!disabled) {
      var lastWorkDay = lastBusinessDayBefore(parseInt(year), parseInt(month) - 1);
      if (placementDetails.placement.endDate) {
        var endDate = new Date(placementDetails.placement.endDate);
        var lastPlacementWorkDay = lastBusinessDayBefore(
          endDate.getFullYear(),
          endDate.getMonth(),
          endDate.getDate()
        );
        if (lastPlacementWorkDay <= lastWorkDay) {
          lastWorkDay = lastPlacementWorkDay;
        }
      }

      finishMonthDisabled = !(lastWorkDay <= today);
      if (!finishMonthDisabled && placementDetails && placementDetails.months[`${year}_${month}`]) {
        var currentMonthData = placementDetails.months[`${year}_${month}`];
        if (currentMonthData.monthlyReport.wasSent) {
          finishMonthDisabled = true;
          disabled = true;
        }
      }
    }
    return [disabled, finishMonthDisabled];
  }, [placementDetails, year, month, monthlyReport]);

  // RTEXT-696
  // using separate flag for billing status
  let isBillingStatusOpen = useMemo(() => {
    return monthlyReport.billingStatus === '100';
  }, [monthlyReport.billingStatus]);

  return (
    <React.Fragment>
      {/* RTEXT-620 */}
      {reportBillingViaPortal !== '100' && (
        <MonthSelector
          month={month}
          placementDetails={placementDetails}
          year={year}
          onMonthChanged={onMonthChanged}
        />
      )}
      <FormContainer className={className}>
        <FormRowContainer>
          {/* RTEXT-620 */}
          <FlexContainer>
            <Subtitle>1. {t('title.monthlyReportSituation')}</Subtitle>
            <span>
              <Button
                disabled={!isBillingStatusOpen}
                color="primary"
                label={t('button.saveReport')}
                onClick={() => {
                  onSave(placementDetails.placement.id, values);
                }}
              />
            </span>
          </FlexContainer>
          <Border />
          <Explain>{t('label.monthlyReportSituation')}</Explain>
          <FormItem
            component={TextField}
            disabled={!isBillingStatusOpen}
            multiline
            placeholder={t('label.typeSomething')}
            rows={8}
            onFocus={handleFocus}
            onBlur={handleBlur}
            {...useInput('situation')}
          />
        </FormRowContainer>
        <FormRowContainer>
          <Subtitle underlined>2. {t('title.monthlyReportActions')}</Subtitle>
          <FormItem
            component={TextField}
            disabled={!isBillingStatusOpen}
            multiline
            placeholder={t('label.typeSomething')}
            rows={8}
            onFocus={handleFocus}
            onBlur={handleBlur}
            {...useInput('processedTasksAndActions')}
          />
        </FormRowContainer>
        <FormRowContainer>
          <Subtitle underlined>3. {t('title.monthlyReportGoals')}</Subtitle>
          <Explain>{t('label.monthlyReportGoals')}</Explain>
          <FormItem
            component={TextField}
            disabled={!isBillingStatusOpen}
            multiline
            placeholder={t('label.typeSomething')}
            rows={8}
            onFocus={handleFocus}
            onBlur={handleBlur}
            {...useInput('goalAchievementsResults')}
          />
        </FormRowContainer>
        <FormRowContainer>
          <Subtitle>4. {t('title.monthlyReportInhibitions')}</Subtitle>
          <FormItem
            component={TextField}
            disabled={!isBillingStatusOpen}
            multiline
            placeholder={t('label.typeSomething')}
            rows={8}
            onFocus={handleFocus}
            onBlur={handleBlur}
            {...useInput('criticalInhibitoryFactors')}
          />
        </FormRowContainer>
        <FormRowContainer>
          <Subtitle underlined>5. {t('title.monthlyReportClientRelation')}</Subtitle>
          <Explain>{t('label.monthlyReportClientRelation')}</Explain>
          <FormItem
            component={TextField}
            disabled={!isBillingStatusOpen}
            multiline
            placeholder={t('label.typeSomething')}
            rows={8}
            onFocus={handleFocus}
            onBlur={handleBlur}
            {...useInput('relationshipWithClient')}
          />
        </FormRowContainer>
        <FormRowContainer>
          <Subtitle underlined>6 {t('title.monthlyReportNeededSupport')}</Subtitle>
          <FormItem
            component={TextField}
            disabled={!isBillingStatusOpen}
            multiline
            placeholder={t('label.typeSomething')}
            rows={8}
            onFocus={handleFocus}
            onBlur={handleBlur}
            {...useInput('supportNeccesary')}
          />
        </FormRowContainer>
        <FormRowContainer>
          <Subtitle underlined>7. {t('title.monthlyReportOtherRemarks')}</Subtitle>
          <FormItem
            component={TextField}
            disabled={!isBillingStatusOpen}
            multiline
            placeholder={t('label.typeSomething')}
            rows={8}
            onFocus={handleFocus}
            onBlur={handleBlur}
            {...useInput('otherRemarks')}
          />
        </FormRowContainer>
        {reportBillingViaPortal === '100' && (
          <ButtonContainer>
            <Section justify="flex-end" wrap="wrap-reverse">
              <Button
                disabled={!isBillingStatusOpen}
                label={t('button.saveReport')}
                color="primary"
                onClick={() => {
                  onSave(placementDetails.placement.id, values);
                }}
                disabledColor="#ededed"
              />

              {/* 
              // RTEXT-620
              <Button
                color="primary"
                disabled={monthlyReport.id ? monthlyReport.wasSent : true}
                label={t('button.sendReport')}
                onClick={() => setFinishMonthState(true)}
              /> */}
            </Section>
          </ButtonContainer>
        )}
      </FormContainer>
      <Dialog
        actionLabel={t('button.submit')}
        disabled={loading}
        open={finishMonthOpen ? true : false}
        title={t('title.timeTrackingFinishMonthConfirm')}
        onAction={() => {
          onSend(placementDetails.placement.id, values, month, year);
        }}
        onClose={() => setFinishMonthState(false)}
      >
        <div>
          <p>{t('message.timeTrackingFinishMonthConfirm')}</p>
        </div>
      </Dialog>
    </React.Fragment>
  );
}

MonthlyReport.propTypes = {
  className: PropTypes.string,
  loading: PropTypes.bool,
  placementDetails: PropTypes.object.isRequired,
  year: PropTypes.number,
  month: PropTypes.number,
  syncing: PropTypes.bool,
  onSave: PropTypes.func.isRequired,
  onSend: PropTypes.func.isRequired,
  onMonthChanged: PropTypes.func.isRequired,
  isMonthlyReportSent: PropTypes.bool,
  setDisableMouseControls: PropTypes.func
};

export default MonthlyReport;
