import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Collapse from 'components/Collapse';
import Details from './Details';
import Section from '../Section';

function Languages({ className, editPath, indexedMeta, languages }) {
  const path = useMemo(() => editPath && `${editPath}/languages`, [editPath]);

  return (
    <Section className={className} editPath={path} title="title.languages">
      {/* Boolean conversion needed to avoid displaying "0" when length is 0 */}
      {!!languages.length && (
        <Collapse>
          {languages.map(language => (
            <Details indexedMeta={indexedMeta} key={language.id} language={language} />
          ))}
        </Collapse>
      )}
    </Section>
  );
}

Languages.propTypes = {
  className: PropTypes.string,
  editPath: PropTypes.string,
  indexedMeta: PropTypes.object.isRequired,
  languages: PropTypes.array.isRequired
};

export default Languages;
