import { createSelector } from 'reselect';
import * as metaSelectors from 'redux-modules/meta/selectors';
import {map, forIn, pick, uniqBy, each} from 'lodash';

// @TODO remove "url?access_token=..." approach when file service is implemented
const selectRawEducation = state => state.resume.education;
const selectRawPersonalDetails = state => state.resume.personalDetails;

export const selectManagerId = state => state.resume.personalDetails.manager_id;
export const selectResume = state => state.resume;
export const selectLoadState = state => state.resume.loading;
export const selectSyncState = state => state.resume.syncing;
export const selectSyncError = state => state.resume.error;

export const selectAvailability = state => state.resume.availability;
export const selectEducation = createSelector(
  [selectRawEducation],
  education => education && education.sort((a, b) => (!a.dateTo || a.dateTo > b.dateTo ? -1 : 1))
);
export const selectExecutiveSummary = state => state.resume.executiveSummary;
export const selectCompetenceProfile = state => state.resume.competenceProfile;
export const selectItSkills = state => state.resume.itSkills;
export const selectLanguageProficiencies = state => state.resume.languageProficiencies || [];
export const selectOtherActivities = state => state.resume.otherActivities;
export const selectPersonalDetails = createSelector(
  [selectRawPersonalDetails],
  personalDetails =>
    personalDetails && {
      ...personalDetails,
      photo: personalDetails.photo
    }
);
export const selectNetworkStatus = state => state.resume.personalDetails.networkStatus;

export const selectManagerFullName = createSelector(
  [selectRawPersonalDetails, metaSelectors.selectIndexedMeta],
  (personalDetails, indexedMeta) => {
    if (Object.entries(personalDetails).length === 0) {
      return '';
    } else {
      const fullName = [];
      personalDetails.salutation &&
        fullName.push(indexedMeta.salutations[personalDetails.salutation]);
      personalDetails.title && fullName.push(indexedMeta.titles[personalDetails.title]);
      personalDetails.firstName && fullName.push(personalDetails.firstName);
      personalDetails.lastName && fullName.push(personalDetails.lastName);
      return fullName.join(' ');
    }
  }
);

export const selectWorkExperience = state => state.resume.workExperience;

export const selectAvailableCompaniesToGroup = createSelector(
    [selectWorkExperience],
    workExperience => {
        let groups = [];
        each(workExperience, function (ele, index, list) {
            groups.push(ele.companyGroupId)
        });

        let gg = [];
        each(groups, function (element, index, list) {
            gg[element] = [];
            each(workExperience, function (element1, index1, list1) {
                if (element === element1.companyGroupId) {
                    gg[element].push(element1);
                }
            });
        });

        forIn(gg, function (value, key) {
            gg[key] = uniqBy(value, function (v) {
                return v.companyId;
            });
        });

        forIn(gg, function (val, k) {

            forIn(val, function (value, key) {
                gg[k] = pick(value, ['companyGroupCountry', 'companyGroupId', 'companyGroupName', 'companyId', 'companyName']);

            });

            gg[k].companies = map(val, function (comp) {

                return {compId: comp.companyId, companyName: comp.companyName}
            })
        });

        return gg[''] && gg['']['companies'] ? gg['']['companies'] : [];
    }
);

export const selectNotificationSettings = createSelector([
  state => state.resume.notificationSettings
], notificationSettings => ({...notificationSettings}));

export const selectInitialInputCv = state => state.resume.personalDetails.initialInputCv;
