export const EMPTY_RESUME = {
  availability: {},
  completionPercent: {},
  education: [],
  executiveSummary: {},
  competenceProfile: { industryFocuses: [], specialistFocuses: [], managementMethods: [] },
  itSkills: { itSkills: [] },
  languageProficiencies: [],
  otherActivities: {},
  personalDetails: {},
  workExperience: []
};
