import { all, call, put, select, takeLeading } from 'redux-saga/effects';
import apiClient from 'communication/apiClient';
import * as actions from './actions';
import * as selectors from './selectors';
import * as types from './types';
export function* loadFieldsMeta() {
  try {
    const resp = yield call(apiClient.get, `/api/v1/manager/fields-meta`);
    if (resp && resp.data) {
      yield put(actions.setLoadedFieldsMeta(resp.data));
    } else {
      yield put(actions.failFieldsMetaLoad(resp));
    }
  } catch (error) {
    yield put(actions.failFieldsMetaLoad('error'));
  }
}

export function* loadMeta() {
  const isLoaded = yield select(selectors.selectLoadedState);
  if (isLoaded) {
    return;
  }
  yield* loadFieldsMeta();
}

export default [
  takeLeading(types.LOAD_FIELDS_META, loadMeta)
];
