import React, { useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Loader } from 'components/styledComponents';
import { useTranslation } from 'hooks';
import { TimeTrackingTab, MonthlyReport } from './Tabs';
import {
  Container,
  TabContainer,
  TabHeader,
  TabHeaderItem,
  TabContent,
  FlexBox,
  Button,
  FlexContainer
} from './style';
import MonthSelector from './Tabs/MonthSelector';
import BillingTab from './Tabs/BillingTab';

function PlacementDetails({
  className,
  loading,
  month,
  placementDetails,
  selectedIndex,
  timeTrackingOptions,
  year,
  onMonthChanged,
  onSaveMonthlyReport,
  onSelectedIndexChange,
  onSendMonthlyReport,
  recordedTimeOptions,
  vatList,
  toggleMonthChange,
  setDisableMouseControls,
  videoURLs,
  language
}) {
  const { t } = useTranslation();
  //RTEXT-620
  const { placement, months } = placementDetails || {};
  const { type, reportBillingViaPortal } = placement || {};
  const placementMonth = months?.[`${year}_${month}`];
  const monthlyReport = placementMonth?.monthlyReport;
  const billingStatus = monthlyReport?.billingStatus;

  const color = useMemo(() => {
    switch (billingStatus) {
      case '100':
        return 'green';
      case '200':
      case '250':
        return 'orange';
      case '300':
        return 'grey';
      default:
        return 'black';
    }
  }, [billingStatus]);

  const isMandate = type === '400' && reportBillingViaPortal === '100';

  useEffect(() => {
    if (type === '400' && selectedIndex === 2) {
      onSelectedIndexChange(0);
    }
  }, [type, selectedIndex]);

  return (
    <Loader className={className} component={Container} loading={loading}>
      {reportBillingViaPortal === '100' && (
        <FlexContainer alignItems="center" justifyContent="space-between" wrap={1}>
          <FlexContainer alignItems="center" justifyContent="flex-start">
            <MonthSelector
              month={month}
              placementDetails={placementDetails}
              year={year}
              onMonthChanged={onMonthChanged}
            />
            {videoURLs && (
              <Button
                color="primary"
                label={t('button.howTo')}
                variant="outlined"
                onClick={() => {
                  language === 'de' ? window.open(videoURLs?.de) : window.open(videoURLs?.en);
                }}
              />
            )}
          </FlexContainer>

          {billingStatus && (
            <FlexBox color={color}>{t(`options.billingStatus.${billingStatus}`)}</FlexBox>
          )}
        </FlexContainer>
      )}
      <TabContainer>
        <TabHeader>
          <TabHeaderItem selected={selectedIndex === 0} onClick={() => onSelectedIndexChange(0)}>
            {t('label.dailyOverview')}
          </TabHeaderItem>
          <TabHeaderItem selected={selectedIndex === 1} onClick={() => onSelectedIndexChange(1)}>
            {t('label.monthlyReport')}
          </TabHeaderItem>
          {reportBillingViaPortal === '100' && type !== '400' && (
            <TabHeaderItem selected={selectedIndex === 2} onClick={() => onSelectedIndexChange(2)}>
              {t('label.billing')}
            </TabHeaderItem>
          )}
        </TabHeader>
        {selectedIndex === 0 && (
          <TabContent>
            <TimeTrackingTab
              isMandate={isMandate}
              language={language}
              month={month}
              monthlyReportId={monthlyReport?.id}
              placementDetails={placementDetails}
              recordedTimeOptions={recordedTimeOptions}
              timeTrackingOptions={timeTrackingOptions}
              year={year}
              onMonthChanged={onMonthChanged}
              onSaveMonthlyReport={onSaveMonthlyReport}
            />
          </TabContent>
        )}
        {selectedIndex === 1 && (
          <TabContent>
            <MonthlyReport
              loading={loading}
              month={month}
              placementDetails={placementDetails}
              setDisableMouseControls={setDisableMouseControls}
              year={year}
              onMonthChanged={onMonthChanged}
              onSave={onSaveMonthlyReport}
              onSend={onSendMonthlyReport}
            />
          </TabContent>
        )}
        {selectedIndex === 2 && (
          <TabContent>
            <BillingTab
              month={month}
              monthlyReport={monthlyReport}
              placement={placement}
              toggleMonthChange={toggleMonthChange}
              vatList={vatList}
              year={year}
            />
          </TabContent>
        )}
      </TabContainer>
    </Loader>
  );
}

PlacementDetails.defaultProps = {
  placementDetails: {}
};

PlacementDetails.propTypes = {
  className: PropTypes.string,
  month: PropTypes.number.isRequired,
  year: PropTypes.number.isRequired,
  placementDetails: PropTypes.object.isRequired,
  selectedIndex: PropTypes.number.isRequired,
  loading: PropTypes.bool,
  timeTrackingOptions: PropTypes.array,
  onMonthChanged: PropTypes.func.isRequired,
  onSaveMonthlyReport: PropTypes.func.isRequired,
  onSelectedIndexChange: PropTypes.func.isRequired,
  onSendMonthlyReport: PropTypes.func.isRequired,
  recordedTimeOptions: PropTypes.array,
  vatList: PropTypes.array,
  toggleMonthChange: PropTypes.bool,
  setDisableMouseControls: PropTypes.func,
  videoURLs: PropTypes.object,
  language: PropTypes.string
};

export default PlacementDetails;
