import styled from 'styled-components';
import { Button as BaseButton } from 'components/buttons';

export const Button = styled(BaseButton)`
  text-decoration: none;
`;

export const ActionsContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: ${props => (props.align === 'right' ? 'flex-end' : 'flex-start')};
  margin: ${props => props.theme.spacing(-1)};

  ${Button} {
    margin: ${props => props.theme.spacing(1)};
  }
`;

export const Container = styled.div`
  align-content: flex-start;
  background: top right / cover url(${props => props.image}) no-repeat;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  min-height: 250px;
  padding: ${props => props.theme.spacing(3)};
`;

export const Subject = styled.h2`
  color: ${props => props.theme.color.primary.contrast};
  font-weight: ${props => props.theme.fontWeight.bold};
  margin: 0px;
  text-transform: uppercase;
`;

export const Text = styled.p`
  color: ${props => props.theme.color.primary.contrast};
`;
