import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Container from '@material-ui/core/Container';
import { Section } from 'components/styledComponents';
import Resume from './Resume';
import WidgetsHeader from './WidgetsHeader';
import PdfConfiguration from './sections/PersonalDetails/PdfConfiguration/PdfConfiguration';
import Eisblau1 from './images/EisblauT1.png';
import Eisblau2 from './images/EisblauT2.png';
import Eisblau3 from './images/EisblauT3.png';
import Grau1 from './images/GrauT1.png';
import Grau2 from './images/GrauT2.png';
import Grau3 from './images/GrauT3.png';
import Weiß1 from './images/WeibT1.png';
import Weiß2 from './images/WeibT2.png';
import Weiß3 from './images/WeibT3.png';
import Olivgrau1 from './images/OlivgrauT1.png';
import Olivgrau2 from './images/OlivgrauT2.png';
import Olivgrau3 from './images/OlivgrauT3.png';
import Hellgrau1 from './images/HellgrauT1.png';
import Hellgrau2 from './images/HellgrauT2.png';
import Hellgrau3 from './images/HellgrauT3.png';
import Rot1 from './images/RotT1.png';
import Rot2 from './images/RotT2.png';
import Rot3 from './images/RotT3.png';
import Blau1 from './images/BlauT1.png';
import Blau2 from './images/BlauT2.png';
import Blau3 from './images/BlauT3.png';

import {
  Education,
  ExecutiveSummary,
  CompetenceProfile,
  ITSkills,
  Languages,
  OtherActivities,
  PersonalDetails,
  WorkExperience
} from './sections';

function ResumePage({ loading, match: { url } }) {
  // RTEXT-652
  const templates = useMemo(() => {
    return {
      '#c0cbd7': [Eisblau1, Eisblau2, Eisblau3],
      '#dddddd': [Grau1, Grau2, Grau3],
      '#ffffff': [Weiß1, Weiß2, Weiß3],
      '#9da7a2': [Olivgrau1, Olivgrau2, Olivgrau3],
      '#e6e9e6': [Hellgrau1, Hellgrau2, Hellgrau3],
      '#c31924': [Rot1, Rot2, Rot3],
      '#237ec5': [Blau1, Blau2, Blau3]
    };
  }, []);

  return (
    <React.Fragment>
      <WidgetsHeader />
      <PersonalDetails loading={loading} editPath={url} />
      <PdfConfiguration templates={templates} />
      <Section>
        <Container>
          <Resume editPath={url} loading={loading} />
          <ExecutiveSummary />
          <CompetenceProfile />
          <WorkExperience className="bottom-separator" />
          <Education />
          <Languages className="bottom-separator" />
          <ITSkills className="bottom-separator" />
          <OtherActivities />
        </Container>
      </Section>
    </React.Fragment>
  );
}

ResumePage.propTypes = {
  loading: PropTypes.bool,
  match: PropTypes.object.isRequired
};

export default ResumePage;
