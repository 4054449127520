import { connect } from 'react-redux';
import * as placementsSelectors from 'redux-modules/placements/selectors';
import * as placementsActions from 'redux-modules/placements/actions';
import Placements from './Placements';

function mapStateToProps(state) {
  return {
    placements: placementsSelectors.selectPlacements(state)
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onInit: () => {
      dispatch(placementsActions.loadPlacements());
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Placements);
