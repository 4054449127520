import styled from 'styled-components';

export const StyledRichText = styled.div`
  ${props =>
    props.hasFocus &&
    `
    border-color: ${props.theme.color.primary.main};
    box-shadow: 0px 0px 0px 1px ${
      props.error ? props.theme.color.danger.main : props.theme.color.primary.main
    };
  `}
  ${props => props.error && `border-color: ${props.theme.color.danger.main};`}
  :hover {
    ${props =>
      !props.disabled &&
      !props.error &&
      !props.hasFocus &&
      `border-color: ${props.theme.color.grey[700]};`}
  }
`;
