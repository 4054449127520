import React, { useState } from 'react';
import { useTranslation } from 'hooks';
import { FormContainer, FormSection, FormRow } from 'components/styledComponents';
import Title from 'components/Title';
import { Section, Footer, Button, MuiContainer } from 'components/pages/RegistrationPage/style';
import { Questions } from 'components/pages/RegistrationPage/forms';
import { Loader } from 'components/styledComponents';
import PropTypes from 'prop-types';

function Step4({ currentStep, useInput, handlePrev, loading, syncing, handleSubmit }) {
  const { t } = useTranslation();
  const [disabled, setDisabled] = useState(true);

  if (currentStep !== 4) {
    return null;
  }
  return (
    <>
      <style>.registration-form-page{'{max-width: 100%; width: 930px}'}</style>
      <Section>
        <Loader component={MuiContainer} loading={loading || syncing}>
          <FormContainer>
            <FormSection divider="bottom" padding="4 0">
              <Title label={t('title.newApplicantQuestions')} size="large" color="primary" />
            </FormSection>
            <FormSection>
              <FormRow>
                <Questions useInput={useInput} setDisabled={setDisabled} />
              </FormRow>
            </FormSection>
          </FormContainer>
          <Footer>
            <Button
              color="primary"
              disabled={loading || syncing || disabled}
              label={t('button.applyNow')}
              disabledColor="gray"
              onClick={handleSubmit} //update with the form submit
            />
            <Button label={t('button.back')} variant="text" onClick={handlePrev} />
          </Footer>
        </Loader>
      </Section>
    </>
  );
}

Step4.propTypes = {
  currentStep: PropTypes.number,
  loading: PropTypes.bool,
  syncing: PropTypes.bool,
  useInput: PropTypes.func.isRequired,
  handlePrev: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

export default Step4;
