import React from 'react';
import PropTypes from 'prop-types';
import { useBigScreen, useTranslation } from 'hooks';
import { Container } from 'components/styledComponents';
import TopRightMenu from './TopRightMenu';
import Logo from './Logo';
import { Header, LinkContainer, Link } from './style';

function AppHeader({ className }) {
  const { t } = useTranslation();
  return (
    <Container as={Header} className={className}>
      <Logo />
      {useBigScreen() && (
        <LinkContainer>
          <Link to="/dashboard">{t('link.home')}</Link>
          <Link to="/resume">{t('link.resume')}</Link>
          <Link to="/management">{t('link.opportunitiesPlacements')}</Link>
        </LinkContainer>
      )}
      <TopRightMenu />
    </Container>
  );
}

AppHeader.propTypes = {
  className: PropTypes.string
};

export default AppHeader;
