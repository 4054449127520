import { call, put, takeLeading, select, takeLatest } from 'redux-saga/effects';
import apiClient from 'communication/apiClient';
import * as actions from './actions';
import * as types from './types';
import * as selectors from './selectors';

export function* loadPlacements() {
  try {
    const placements = yield select(selectors.selectPlacements);
    if (placements && placements.length > 0) {
      yield put(actions.resetLoadPlacementsSyncing());
      return;
    }
    const resp = yield call(apiClient.get, `/api/v1/manager/placements`);
    if (resp && resp.data) {
      yield put(actions.setLoadedPlacements(resp.data));
    } else {
      yield put(actions.failPlacementsLoad(resp));
    }
  } catch (error) {
    yield put(actions.failPlacementsLoad('error'));
  }
}

export function* loadPlacementOverview(actionData) {
  var currentPlacementId = actionData.payload;
  const placementsDetails = yield select(selectors.selectPlacementsDetails);
  if (placementsDetails[currentPlacementId]) {
    yield put(actions.resetSyncing());
    return;
  }
  try {
    const resp = yield call(
      apiClient.get,
      `/api/v1/manager/placements/${currentPlacementId}/overview`
    );
    if (resp && resp.data) {
      yield put(actions.setLoadedPlacementOverview(resp.data));
    } else {
      yield put(actions.failPlacementsLoad(resp));
    }
  } catch (error) {
    yield put(actions.failPlacementsLoad('error'));
  }
}

export function* loadPlacementOverviewMonth(actionData) {
  const { placementId, year, month, force } = actionData.payload;
  const placementsDetails = yield select(selectors.selectPlacementsDetails);
  // force => forcefully calling api
  if (
    !force &&
    placementsDetails[placementId] &&
    placementsDetails[placementId].months &&
    placementsDetails[placementId].months[`${year}_${month}`]
  ) {
    yield put(actions.resetSyncing());
    return;
  }
  try {
    const resp = yield call(
      apiClient.get,
      `/api/v1/manager/placements/${placementId}/overview/${year}/${month}`
    );
    if (resp && resp.data) {
      yield put(actions.setLoadedPlacementOverview(resp.data));
    } else {
      yield put(actions.failPlacementsLoad(resp));
    }
  } catch (error) {
    yield put(actions.failPlacementsLoad('error'));
  }
}

export function* saveTimetracking(actionData) {
  const { timetracking, placementId, month, year } = actionData.payload;
  try {
    const resp = yield call(
      apiClient.post,
      `/api/v1/manager/placements/${placementId}/time-tracking`,
      timetracking
    );
    if (resp && resp.data) {
      yield put(actions.setTimetrackingSaved(resp.data));
      // RTEXT-696
      // it was implemented only for first daily report in RTEXT-620
      // fetching month information on saving daily report to prevent reloading
      yield put(actions.loadPlacementOverviewMonth(placementId, year, month, true));
    } else {
      yield put(actions.failTimetrackingSave(resp));
    }
  } catch (error) {
    yield put(actions.failTimetrackingSave('error'));
  }
}

export function* saveMonthlyReport(actionData) {
  const { monthlyReport, placementId } = actionData.payload;
  try {
    yield put(actions.startSaving());
    const resp = yield call(
      apiClient.post,
      `/api/v1/manager/placements/${placementId}/monthly-report`,
      monthlyReport
    );
    if (resp && resp.data) {
      yield put(actions.setMonthlyReportSaved(resp.data));
      yield put(actions.finishSaving());
    } else {
      yield put(actions.failMonthlyReportSave(resp));
    }
  } catch (error) {
    yield put(actions.failMonthlyReportSave('error'));
  }
}

export function* sendMonthlyReport(actionData) {
  const { monthlyReport, placementId, month, year } = actionData.payload;
  yield put(actions.startSending(placementId, month, year));
  try {
    const resp = yield call(
      apiClient.post,
      `/api/v1/manager/placements/${placementId}/monthly-report/submit`,
      monthlyReport
    );
    if (resp && resp.data) {
      yield put(actions.setMonthlyReportSent(resp.data));
      yield put(actions.finishSending());
    } else {
      yield put(actions.failMonthlyReportSend(resp));
    }
  } catch (error) {
    yield put(actions.failMonthlyReportSend('error'));
  }
}

export default [
  takeLeading(types.LOAD_PLACEMENTS, loadPlacements),
  takeLeading(types.LOAD_PLACEMENT_OVERVIEW, loadPlacementOverview),
  takeLatest(types.LOAD_PLACEMENT_OVERVIEW_MONTH, loadPlacementOverviewMonth),
  takeLeading(types.SAVE_TIMETRACKING, saveTimetracking),
  takeLeading(types.SAVE_MONTHLY_REPORT, saveMonthlyReport),
  takeLeading(types.SEND_MONTHLY_REPORT, sendMonthlyReport)
];
