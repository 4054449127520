import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Detail from './Details';
import {some} from 'lodash';
import Section from '../Section';
import {useTranslation} from "hooks";
import {EmptySection, EmptyText} from 'components/pages/ResumePage/sections/CompetenceProfile/Form/style.js';

function CompetenceProfile({ className, editPath, competenceProfile, language }) {
  const {industryFocuses, managementMethods, specialistFocuses} = competenceProfile;
  const {tr} = useTranslation();

  const emptySection = <EmptySection>
    <EmptyText>{tr('message.sectionIsEmpty')}</EmptyText>
    <EmptyText>{tr('message.addMoreInformation')}</EmptyText>
  </EmptySection>;


  const industryFocusesData = useMemo(() => industryFocuses[language], [
    competenceProfile,
    language
  ]);
    const managementMethodsData = useMemo(() => managementMethods[language], [
    competenceProfile,
    language
  ]);
    const specialistFocusesData = useMemo(() => specialistFocuses[language], [
    competenceProfile,
    language
  ]);
  const competenceProfileData = {industryFocusesData, managementMethodsData, specialistFocusesData};

  const path = useMemo(() => editPath && `${editPath}/competence-profile`, [editPath]);

  const data = [!!(industryFocusesData && industryFocusesData.length), !!(managementMethodsData && managementMethodsData.length), !!(specialistFocusesData && specialistFocusesData.length)];

  return (
        <Section className={className} editPath={path}
                 title="title.competenceProfile"> {(some(data)) ?
            <Detail competenceProfile={competenceProfileData}/> :
            emptySection
        }
        </Section>
  );
}

CompetenceProfile.propTypes = {
  className: PropTypes.string,
  editPath: PropTypes.string,
  competenceProfile: PropTypes.object.isRequired,
  language: PropTypes.string.isRequired
};

export default CompetenceProfile;
