import React from 'react';
import { Button } from 'components/buttons';

const linkStyles = {
  float: 'right',
  'text-decoration': 'none',
  width: 'max-content'
};

export function getTimetrackingTableColumns(t, id, language = 'de') {
  return [
    {
      id: 'date',
      Header: t('columnTitle.day'),
      minWidth: '150px',
      width: '150px'
    },
    {
      // eslint-disable-next-line react/display-name
      Header: t('columnTitle.activity'),
      id: 'activity',
      width: '150px',
      minWidth: '150px'
    },
    {
      Header: t('columnTitle.details'),
      id: 'description',
      minWidth: '200px'
    },
    {
      Header: () => (
        <a
          href={
            id
              ? `/api/v1/manager/pdf/file/activity_report/${id}/${language}?timestamp=${new Date().getTime()}`
              : ''
          }
          style={linkStyles}
        >
          <Button color="primary">{t('button.downloadPdf')}</Button>
        </a>
      ),
      id: 'download',
      width: '150px',
      minWidth: '150px'
    }
  ];
}
