import React, {useCallback, useEffect, useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import Section from '../Section';
import Details from './Details';

function WorkExperience({ className, editPath, indexedMeta, language, workExperience }) {
  const path = useMemo(() => editPath && `${editPath}/work-experience`, [editPath]);

  const [expanded, setExpanded] = useState(false);

  function handleToggle() {
    setExpanded(expanded => !expanded);
  }

  return (
    <Section addPath={path} showExpandCollapse={true} isExpanded={expanded} handleCollapseExpand={handleToggle} className={className} title="title.workExperience">
      {/* Boolean conversion needed to avoid displaying "0" when length is 0 */}
      {!!workExperience.length && (
        <Details
          editPath={path}
          indexedMeta={indexedMeta}
          language={language}
          workExperience={workExperience}
          expandCollapse={expanded}
          handleCollapseExpand={handleToggle}
        />
      )}
    </Section>
  );
}

WorkExperience.propTypes = {
  className: PropTypes.string,
  editPath: PropTypes.string,
  indexedMeta: PropTypes.object.isRequired,
  language: PropTypes.string.isRequired,
  workExperience: PropTypes.array
};

export default WorkExperience;
