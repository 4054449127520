import React from 'react';
import PropTypes from 'prop-types';
import { Detail, DetailLabel, DetailLinkValue, DetailValue } from './style';

function SectionCell({ black, label, link, linkLabel, nowrap, size, value }) {
  return (
    <Detail size={size}>
      <DetailLabel>{label}</DetailLabel>
      {link ? (
        <DetailLinkValue black href={link}>
          {linkLabel}
        </DetailLinkValue>
      ) : (
        <DetailValue black={black} nowrap={nowrap}>
          {value || '-'}
        </DetailValue>
      )}
    </Detail>
  );
}

SectionCell.defaultProps = {
  black: false,
  label: '',
  link: null,
  linkLabel: '-',
  nowrap: false,
  size: '25%'
};

SectionCell.propTypes = {
  black: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  link: PropTypes.string,
  linkLabel: PropTypes.string,
  nowrap: PropTypes.bool.isRequired,
  size: PropTypes.string.isRequired,
  value: PropTypes.string
};

export default SectionCell;
