import React from 'react';
import PropTypes from 'prop-types';
import { TextField } from 'components/inputs';
import { FormItem, FormRow } from 'components/styledComponents';
import { useTranslation } from 'hooks';

function Form({ useInput }) {
  const { tr } = useTranslation();

  return (
    <React.Fragment>
      <FormRow>
        <FormItem
          component={TextField}
          label={tr('label.fullName')}
          required
          {...useInput('reference.name')}
        />
        <FormItem
          component={TextField}
          label={tr('label.position')}
          {...useInput('reference.position')}
        />
      </FormRow>
      <FormRow>
        <FormItem
          component={TextField}
          label={tr('label.contactDetails')}
          {...useInput('reference.contactDetails')}
        />
      </FormRow>
      <FormRow>
        <FormItem
          component={TextField}
          label={tr('label.comments')}
          multiline
          rows="4"
          {...useInput('reference.comment')}
        />
      </FormRow>
    </React.Fragment>
  );
}

Form.propTypes = {
  useInput: PropTypes.func.isRequired
};

export default Form;
