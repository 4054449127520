import React from 'react';
import PropTypes from 'prop-types';
import { SlickSlider, SliderArrow } from './style';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { ArrowLeft, ArrowRight } from 'components/icons';

function Carousel({ children }) {
  const bigScreen = useMediaQuery('@media (min-width: 1200px)');
  const mediumScreen = useMediaQuery('@media (min-width: 800px)');
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: bigScreen ? 3 : mediumScreen ? 2 : 1,
    arrows: true,
    prevArrow: (
      <SliderArrow left>
        <ArrowLeft color="primary" size="larger" />
      </SliderArrow>
    ),
    nextArrow: (
      <SliderArrow right>
        <ArrowRight color="primary" />
      </SliderArrow>
    ),
    slidesToScroll: 1
  };

  return <SlickSlider {...settings}>{children}</SlickSlider>;
}

Carousel.propTypes = {
  children: PropTypes.array
};

export default Carousel;
