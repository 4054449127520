import { connect } from 'react-redux';
import * as opportunitiesSelectors from 'redux-modules/opportunities/selectors';
import * as opportunitiesActions from 'redux-modules/opportunities/actions';
import * as uiStateSelectors from 'redux-modules/uiState/selectors';
import NDADialog from './NDADialog';

function mapStateToProps(state) {
  return {
    language: uiStateSelectors.selectLanguage(state),
    nda: opportunitiesSelectors.selectNDA(state),
    syncing: opportunitiesSelectors.selectItemSyncStatus(state)
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    onAccept: () => {
      dispatch(opportunitiesActions.acceptNDA(ownProps.opportunity));
    },
    onInit: () => {
      dispatch(opportunitiesActions.loadNDA());
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(NDADialog);
