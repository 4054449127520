import React, { useState, useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import Calendar from 'components/Calendar';
import { useTranslation } from 'hooks';
import { defaultTheme } from 'themes';
import { Container, Legend, LegendItem, FlexContainer } from './style';
import { format } from 'date-fns';
import { Loader } from 'components/styledComponents';
import TimeTrackingPopper from './TimeTrackingPopper';

const today = new Date();

function getTimeTrackingIntervals(timeTracking, placementStartDate, placementEndDate, year, month) {
  var start = new Date(year, month - 1, 1);
  var end = new Date(start);
  end.setMonth(end.getMonth() + 1);
  end.setDate(end.getDate() - 1);

  var pStartDate = placementStartDate ? new Date(placementStartDate + ' 00:00:00') : null;
  var pEndDate = placementEndDate ? new Date(placementEndDate + ' 00:00:00') : null;
  if (pStartDate && pStartDate.getTime() > start.getTime()) {
    start = pStartDate;
  }
  if (pEndDate && pEndDate.getTime() < end.getTime()) {
    end = pEndDate;
  }

  if (today < end) {
    end = today;
  }

  var date = start;

  const hasData = function (date) {
    var index = timeTracking.findIndex(item => {
      return item.date === format(date, 'yyyy-MM-dd');
    });
    return index >= 0;
  };

  var interval = {
    startDate: new Date(start),
    hasData: hasData(start)
  };

  var intervals = {
    data: [],
    find: function (date) {
      if (!this.data) {
        return null;
      }
      var index = this.data.findIndex(interval => {
        return date >= interval.startDate && date <= interval.endDate;
      });
      return index >= 0 ? this.data[index] : null;
    }
  };
  // make sure intervals with no data do not start in weekends
  if (!interval.hasDate) {
    if (interval.startDate.getDay() === 0) {
      interval.startDate.setDate(interval.startDate.getDate() + 1);
    }
    if (interval.startDate.getDay() === 6) {
      interval.startDate.setDate(interval.startDate.getDate() + 2);
    }
  }
  date.setDate(date.getDate() + 1);
  while (date <= end) {
    // logic:
    // 1) current interval is null - we open an interval if we are not in a weekend day
    // or we have timetracking data for the day
    // 2) If the current day data changes from the current interval (either has or has not timetracking)
    // we close the current interval, and mark it as null (so 1 could then create a new one when needed)
    // 3) If the current interval has no data (red interval) and the current day is weekend, we close the
    // interval on the previous day, and set interval to null (so 1 could than create a new one when needed)
    var dateHasTracking = hasData(date);
    var isWeekendDay = date.getDay() === 6 || date.getDay() === 0;
    if (interval === null) {
      if (dateHasTracking || !isWeekendDay) {
        interval = {
          startDate: new Date(date),
          hasData: dateHasTracking
        };
      }
      date.setDate(date.getDate() + 1);
      continue;
    }
    if (interval && interval.hasData !== dateHasTracking) {
      interval.endDate = new Date(date);
      interval.endDate.setDate(interval.endDate.getDate() - 1);
      intervals.data.push(interval);
      interval = null;
      continue;
    }
    if (
      interval &&
      !interval.hasData &&
      format(today, 'yyyy-MM-dd') === format(date, 'yyyy-MM-dd')
    ) {
      interval.endDate = new Date(date);
      interval.endDate.setDate(interval.endDate.getDate() - 1);
      intervals.data.push(interval);
      interval = null;
      break;
    }
    if (interval && !interval.hasData && isWeekendDay) {
      interval.endDate = new Date(date);
      interval.endDate.setDate(interval.endDate.getDate() - 1);
      intervals.data.push(interval);
      interval = null;
      continue;
    }
    date.setDate(date.getDate() + 1);
  }
  // if the month finished and the current interval does not have an end date
  // we close the interval on the last day of the month
  if (interval && !interval.endDate) {
    interval.endDate = new Date(end);
    intervals.data.push(interval);
  }
  return intervals;
}

function getDayColors(intervals, day) {
  var interval = null;
  if (intervals) interval = intervals.find(day);
  if (format(day, 'yyyy-MM-dd') === format(today, 'yyyy-MM-dd')) {
    if (!interval || !interval.hasData) {
      // if we do not have data but it is the current day, return the current day colors
      return {
        backgroundColor: defaultTheme.color.primary.main,
        textColor: defaultTheme.color.primary.contrast
      };
    }
  }
  if (interval === null) {
    return null;
  }
  if (
    format(interval.startDate, 'yyyy-MM-dd') === format(day, 'yyyy-MM-dd') &&
    format(interval.endDate, 'yyyy-MM-dd') === format(day, 'yyyy-MM-dd')
  ) {
    // this is a one day interval
    if (format(interval.startDate, 'yyyy-MM-dd') === format(today, 'yyyy-MM-dd')) {
      if (!interval.hasData) {
        // if we do not have data but it is the current day, return the current day colors
        return {
          backgroundColor: defaultTheme.color.primary.main,
          textColor: defaultTheme.color.primary.contrast
        };
      }
    }
    return {
      backgroundColor: interval.hasData
        ? defaultTheme.color.success.main
        : defaultTheme.color.danger.main,
      textColor: defaultTheme.color.primary.contrast
    };
  }
  if (format(interval.startDate, 'yyyy-MM-dd') === format(day, 'yyyy-MM-dd')) {
    // start of the interval
    return {
      backgroundColor: interval.hasData
        ? defaultTheme.color.success.main
        : defaultTheme.color.danger.main,
      textColor: defaultTheme.color.primary.contrast,
      rightSideColor:
        day.getDay() !== 0
          ? interval.hasData
            ? defaultTheme.color.success.light
            : defaultTheme.color.danger.light
          : null
    };
  }
  if (format(interval.endDate, 'yyyy-MM-dd') === format(day, 'yyyy-MM-dd')) {
    // end of the interval
    return {
      backgroundColor: interval.hasData
        ? defaultTheme.color.success.main
        : defaultTheme.color.danger.main,
      textColor: defaultTheme.color.primary.contrast,
      leftSideColor:
        day.getDay() !== 1
          ? interval.hasData
            ? defaultTheme.color.success.light
            : defaultTheme.color.danger.light
          : null
    };
  }
  // middle of the interval
  return {
    backgroundColor: interval.hasData
      ? defaultTheme.color.success.light
      : defaultTheme.color.danger.light,
    textColor: defaultTheme.color.primary.contrast,
    leftSideColor:
      day.getDay() !== 1
        ? interval.hasData
          ? defaultTheme.color.success.light
          : defaultTheme.color.danger.light
        : null,
    rightSideColor:
      day.getDay() !== 0
        ? interval.hasData
          ? defaultTheme.color.success.light
          : defaultTheme.color.danger.light
        : null
  };
}

function TimeTracking({
  className,
  meta,
  placementDetails,
  year,
  month,
  onMonthChange,
  loading,
  onSaveTimetracking,
  language,
  disableMouseControls,
  setDisableMouseControls
}) {
  const { t } = useTranslation();
  const { placement, timeTrackingOptions } = placementDetails;
  const placementMonth = placementDetails.months[`${year}_${month}`];
  const timeTracking = placementMonth ? placementMonth.timeTracking : [];
  const [open, setOpen] = useState(false);
  const [date, setDate] = useState(today);
  const [lastDayClickedEl, setLastDayClickedEl] = useState(null);
  const hasMonthlyReport = placementMonth && placementMonth.monthlyReport.id ? true : false;
  // RTEXT-620
  const monthlyReport = placementMonth && placementMonth.monthlyReport;
  // RTEXT-690
  const [disablePointer, setDisablePointer] = useState(false);

  useEffect(() => {
    !loading && setOpen(false);
  }, [loading]);

  // RTEXT-690
  useEffect(() => {
    if (!hasMonthlyReport || monthlyReport.billingStatus === '100') {
      setDisablePointer(false);
    } else {
      setDisablePointer(true);
    }
  }, [placementDetails, month, year]);

  useEffect(() => {
    setDisableMouseControls && setDisableMouseControls(open);
  }, [open]);

  var timeTrackingVisibleOptions = null;
  const timeTrackingOptionsList = meta.timeTrackingOptions;
  if (timeTrackingOptions && timeTrackingOptionsList) {
    timeTrackingVisibleOptions = [];
    timeTrackingOptionsList.map(item => {
      if (timeTrackingOptions.indexOf(item.key) >= 0) {
        timeTrackingVisibleOptions.push(item);
      }
      return null;
    });
  }

  // RTEXT-618
  var recordedTimeVisibleOptions = meta?.recordedTimeList || [];
  var placeOfWorkVisibleOptions = meta?.placeOfWorkList || [];
  var businessTripVisibleOptions = meta?.businessTripList || [];

  const calendarDayClickHandle = useCallback(
    (day, lastTarget) => {
      // RTEXT-620
      // commented to only allow daily reports to be added when billing status is open or when monthly report doesn't exist
      // if (!placementDetails.months[`${year}_${month}`].monthlyReport.wasSent) {
      //   setDate(day);
      //   setOpen(true);
      //   setLastDayClickedEl(lastTarget);
      // }
      if (!hasMonthlyReport || monthlyReport.billingStatus === '100') {
        setDate(day);
        setOpen(true);
        setLastDayClickedEl(lastTarget);
      }
    },
    [hasMonthlyReport, monthlyReport]
  );

  const saveTimetrackingData = useCallback(
    (timeTracking, description, recordedTime, placeOfWork, businessTrip, timeLog) => {
      var timeTrackingDate = format(date, 'yyyy-MM-dd');
      var timeTrackingObj = {
        date: timeTrackingDate,
        description: description,
        timeTracking: timeTracking,
        recordedTime: recordedTime,
        placeOfWork: placeOfWork,
        businessTrip: businessTrip
      };

      if (timeLog) {
        const { workFrom, workTo, pauseTimePairs } = timeLog;

        timeTrackingObj.workStart = workFrom;
        timeTrackingObj.workEnd = workTo;

        const filteredPairs = pauseTimePairs.filter(p => p.start !== '00:00' || p.end !== '00:00');

        for (var i = 0; i < 3; i++) {
          timeTrackingObj[`pauseStart${i + 1}`] = filteredPairs[i]?.start || '00:00';
          timeTrackingObj[`pauseEnd${i + 1}`] = filteredPairs[i]?.end || '00:00';
        }
      }

      onSaveTimetracking &&
        onSaveTimetracking(
          placement.id,
          Object.fromEntries(Object.entries(timeTrackingObj).filter(([_, v]) => v != null)),
          // RTEXT-696 sending correct values for month and year
          month,
          year
        );
    },
    [
      date,
      placement.type,
      placement.reportBillingViaPortal,
      placement.id,
      onSaveTimetracking,
      month,
      year
    ]
  );
  // compute max date, min date and current date
  const [maxDate, minDate, currentDate] = useMemo(() => {
    // RTEXT-620
    // allowing users to add daily reports to any of the months from start to till today
    var maxDate = today;
    // if (currentYear && currentMonth) {
    //   maxDate = new Date(currentYear, parseInt(currentMonth), 0);
    // }
    if (placement && placement.endDate != null) {
      maxDate = new Date(placement.endDate);
    }

    var minDate = today;
    if (placement && placement.startDate != null) {
      minDate = new Date(placement.startDate);
    }

    var currentDate = today;
    if (year && month) {
      currentDate = new Date(year, month - 1, 1);
    }
    return [maxDate, minDate, currentDate];
  }, [month, year, placement]);

  const dateTimetracking = useMemo(() => {
    let dateTimetracking = null;

    if (timeTracking && date) {
      var dateStr = format(date, 'yyyy-MM-dd');
      timeTracking.map(item => {
        if (dateStr === item.date) {
          dateTimetracking = item;
        }
        return true;
      });
    }
    return dateTimetracking;
  }, [timeTracking, date]);
  const hasData = useMemo(() => {
    return (
      placementDetails &&
      placementDetails !== null &&
      placementDetails.placement !== null &&
      placementDetails.placement.id != null
    );
  }, [placementDetails]);

  var intervals = useMemo(
    () =>
      getTimeTrackingIntervals(timeTracking, placement.startDate, placement.endDate, year, month),
    [timeTracking, placement, year, month]
  );
  return (
    <div className={className}>
      <Loader component={Container} loading={loading}>
        {/* RTEXT-620 */}
        {placement && placement.reportBillingViaPortal === '100' ? (
          <Calendar
            date={currentDate}
            disableFuture={true}
            disableMouseControls={disableMouseControls}
            disablePointer={disablePointer}
            getDayColors={(day, utils) => (placement ? getDayColors(intervals, day) : null)}
            maxDate={maxDate}
            minDate={minDate}
            onDayClick={calendarDayClickHandle}
            onMonthChange={onMonthChange}
          />
        ) : (
          <FlexContainer>{t('message.noReporting')}</FlexContainer>
        )}
        <TimeTrackingPopper
          anchorEl={lastDayClickedEl}
          anchorOrigin={{ vertical: 'center', horizontal: 'center' }}
          businessTripOptions={businessTripVisibleOptions}
          date={date}
          dateTimetracking={dateTimetracking}
          hasData={hasData}
          language={language}
          open={open}
          placementType={placementDetails.placement.type}
          placeOfWorkOptions={placeOfWorkVisibleOptions}
          recordedTimeOptions={recordedTimeVisibleOptions}
          reportBillingViaPortal={placementDetails.placement.reportBillingViaPortal}
          syncing={loading}
          timeTrackingOptions={timeTrackingVisibleOptions}
          travelExpenseManager={placementDetails.placement.travelExpenseManager}
          feeBilling={placementDetails.placement.feeBilling}
          onClose={() => setOpen(false)}
          onSave={saveTimetrackingData}
        />
        {placement && placement.reportBillingViaPortal === '100' && (
          <Legend>
            <LegendItem color={defaultTheme.color.success.main}>
              {t('label.timesheetSubmitted')}
            </LegendItem>
            <LegendItem color={defaultTheme.color.danger.main}>
              {t('label.timesheetMissing')}
            </LegendItem>
          </Legend>
        )}
      </Loader>
    </div>
  );
}

TimeTracking.propTypes = {
  className: PropTypes.string,
  placementDetails: PropTypes.object.isRequired,
  year: PropTypes.number.isRequired,
  month: PropTypes.number.isRequired,
  meta: PropTypes.object.isRequired,
  onMonthChange: PropTypes.func,
  loading: PropTypes.bool.isRequired,
  onSaveTimetracking: PropTypes.func.isRequired,
  language: PropTypes.string,
  disableMouseControls: PropTypes.bool,
  setDisableMouseControls: PropTypes.func
};

export default TimeTracking;
