import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import PropTypes from 'prop-types';

import { SelectField } from 'components/inputs';
import { IconButton } from 'components/buttons';
import { FlexBox, UploadButton, Text, FormRow, TextContainer } from './style';
import { FormItem } from 'components/styledComponents';
import { useSubcategories, useTranslation } from 'hooks';

function Document({
  fileName,
  error,
  index,
  language,
  subcategoryId,
  handleFileUpload,
  handleSubcategoryChange,
  handleDeleteDocument
}) {
  const { t } = useTranslation();
  const [errorMessage, setErrorMessage] = useState('');
  const subcategories = useSubcategories();

  const options = useMemo(() => {
    if (index === 0) {
      return subcategories.slice(0, 2);
    } else {
      return subcategories.slice(2);
    }
  }, [subcategories, index, language]);

  useEffect(() => {
    if (language === '100') {
      handleSubcategoryChange(0, '400');
    } else {
      handleSubcategoryChange(0, '500');
    }
  }, [language, handleSubcategoryChange]);

  useEffect(() => {
    const fileErrors = error?.files || [];
    if (fileErrors?.includes(index)) {
      setErrorMessage(t('label.uploadDocRequired'));
    } else {
      setErrorMessage('');
    }
  }, [error, index, setErrorMessage]);

  const onDrop = useCallback(
    (acceptedFiles, rejectedFiles) => {
      const error = rejectedFiles[0]?.errors?.[0];
      if (error?.code === 'file-too-large' || error?.code === 'file-invalid-type') {
        setErrorMessage(t('error.file.typeAndSize'));
        handleFileUpload(index, null);
      } else {
        setErrorMessage('');
      }

      const file = acceptedFiles[0];
      if (file) {
        handleFileUpload(index, file);
      }
    },
    [setErrorMessage, handleFileUpload]
  );

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      'application/pdf': ['.pdf']
    },
    maxFiles: 1,
    maxSize: 15728640,
    onDrop
  });

  return (
    <FormRow>
      <FormItem
        component={SelectField}
        label={t('label.category')}
        options={options}
        required
        value={subcategoryId}
        onChange={value => {
          handleSubcategoryChange(index, value);
        }}
      />
      <FormItem {...getRootProps()} className="dropzone">
        <input {...getInputProps()} />
        <UploadButton color="primary">{t('button.uploadFile')}</UploadButton>
        <TextContainer>
          <Text>{fileName}</Text>
          <Text color="red">{errorMessage}</Text>
        </TextContainer>
      </FormItem>
      {index > 0 ? (
        <FlexBox>
          <IconButton color="danger" icon="Trash" onClick={() => handleDeleteDocument(index)} />
        </FlexBox>
      ) : (
        <div style={{ width: '42px' }}></div>
      )}
    </FormRow>
  );
}

Document.propTypes = {
  fileName: PropTypes.string.isRequired,
  error: PropTypes.object,
  index: PropTypes.number.isRequired,
  language: PropTypes.string.isRequired,
  subcategoryId: PropTypes.string.isRequired,
  handleSubcategoryChange: PropTypes.func.isRequired,
  handleDeleteDocument: PropTypes.func.isRequired,
  handleFileUpload: PropTypes.func.isRequired
};

export default Document;
