import React from 'react';
import PropTypes from 'prop-types';
import Container from '@material-ui/core/Container';
import { Availability, CardsContainer, ResumeActions } from 'components/cards';
import { WidgetsSection } from './style';

function WidgetsHeader({ className }) {
  return (
    <WidgetsSection className={className}>
      <Container>
        <CardsContainer>
          <ResumeActions size="6" />
          <Availability size="6" />
        </CardsContainer>
      </Container>
    </WidgetsSection>
  );
}

WidgetsHeader.propTypes = {
  className: PropTypes.string
};

export default WidgetsHeader;
