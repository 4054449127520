import React, { useCallback, useMemo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { TextField } from 'components/inputs';
import { FormItem } from 'components/styledComponents';
import { useTranslation } from 'hooks';
import {
  DeleteButton,
  FixedSection,
  TypeSelector,
  StyledFormRow,
  StyledFormItem,
  FormItemWrapper
} from './style';

function TypeValue({
  basePath,
  defaultLabel,
  filterOption,
  fixedLabel,
  options,
  useInput,
  onDelete,
  onResetError
}) {
  const { t } = useTranslation();
  // RTEXT-856
  const [isSocialNetwork, setIsSocialNetwork] = useState(false);
  const { value: socialNetworks } = useInput('socialNetworks');
  const inputValue = useInput(`${basePath}.value`);
  const [content, setContent] = useState('');

  const handleDelete = useCallback(() => {
    onDelete(basePath);
    // RTEXT-856
    if (defaultLabel === t('label.socialNetwork') || defaultLabel === t('label.primaryEmail')) {
      onResetError && onResetError([`${basePath}.value`, `${basePath}.type`]);
    }
  }, [basePath, onDelete, onResetError]);

  const typeProps = useInput(`${basePath}.type`);

  const label = useMemo(() => {
    if (!fixedLabel && typeProps.value) {
      return typeProps.value === 'xing' || typeProps.value === 'linkedin'
        ? `${t(`label.${typeProps.value}`)} *`
        : t(`label.${typeProps.value}`);
    }
    return defaultLabel === t('label.primaryEmail') ? `${defaultLabel} *` : defaultLabel;
  }, [defaultLabel, fixedLabel, t, typeProps]);

  // RTEXT-856
  useEffect(() => {
    if (defaultLabel === t('label.socialNetwork')) {
      setIsSocialNetwork(true);
    } else {
      setIsSocialNetwork(false);
    }
  }, [defaultLabel]);

  // RTEXT-856
  useEffect(() => {
    if (isSocialNetwork) {
      const index = parseInt(basePath.replace(/[^\d.]/g, '')) || 0;
      const { type } = socialNetworks[index] || {};
      type && setContent(t(`message.${type}Profile`));
    }
  }, [socialNetworks, isSocialNetwork]);

  return (
    <StyledFormRow rowReverse={isSocialNetwork ? 1 : 0}>
      {isSocialNetwork ? (
        <>
          <FormItemWrapper>
            <StyledFormItem content={content} component={TextField} label={label} {...inputValue} />
            <DeleteButton color="danger" icon="Trash" onClick={handleDelete} />
          </FormItemWrapper>
        </>
      ) : (
        <>
          <FormItem component={TextField} label={label} {...inputValue} />
        </>
      )}
      <FormItem component={FixedSection} size="0">
        <TypeSelector
          controlShouldRenderValue={true}
          filterOption={filterOption}
          hideSelectedOptions={false}
          options={options}
          placeholder={
            defaultLabel === t('label.primaryEmail') ? `${t('label.select')} *` : t('label.select')
          }
          {...typeProps}
        />
        {!isSocialNetwork && <DeleteButton color="danger" icon="Trash" onClick={handleDelete} />}
      </FormItem>
    </StyledFormRow>
  );
}

TypeValue.propTypes = {
  basePath: PropTypes.string.isRequired,
  defaultLabel: PropTypes.string.isRequired,
  filterOption: PropTypes.func,
  fixedLabel: PropTypes.bool,
  options: PropTypes.array.isRequired,
  useInput: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired
};

export default TypeValue;
