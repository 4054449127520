import * as types from './types';

// @TODO replace all actions for server communication error with a generic UI state flag/action
export function failPlacementsLoad(error) {
  return {
    payload: {
      error
    },
    type: types.PLACEMENTS_FAILED
  };
}

export function loadPlacements() {
  return {
    type: types.LOAD_PLACEMENTS
  };
}

export function setLoadedPlacements(placements) {
  return {
    payload: {
      placements
    },
    type: types.PLACEMENTS_LOADED
  };
}

export function loadPlacementOverview(id) {
  return {
    type: types.LOAD_PLACEMENT_OVERVIEW,
    payload: id
  };
}

export function loadPlacementOverviewMonth(id, year, month, force = false) {
  return {
    type: types.LOAD_PLACEMENT_OVERVIEW_MONTH,
    payload: {
      placementId: id,
      year,
      month,
      force
    }
  };
}

export function setLoadedPlacementOverview(placement) {
  return {
    payload: placement,
    type: types.PLACEMENT_OVERVIEW_LOADED
  };
}

export function failTimetrackingSave(error) {
  return {
    payload: {
      error
    },
    type: types.TIMETRACKING_SAVE_ERROR
  };
}

export function saveTimetracking(placementId, timetracking, month, year) {
  return {
    type: types.SAVE_TIMETRACKING,
    payload: {
      placementId,
      timetracking,
      month,
      year
    }
  };
}

export function setTimetrackingSaved(placement) {
  return {
    payload: placement,
    type: types.TIMETRACKING_SAVED
  };
}

export function failMonthlyReportSave(error) {
  return {
    payload: {
      error
    },
    type: types.SAVE_MONTHLY_REPORT_FAILED
  };
}

export function failMonthlyReportSend(error) {
  return {
    payload: {
      error
    },
    type: types.SEND_MONTHLY_REPORT_FAILED
  };
}

export function saveMonthlyReport(placementId, monthlyReport) {
  return {
    type: types.SAVE_MONTHLY_REPORT,
    payload: {
      placementId,
      monthlyReport
    }
  };
}

export function sendMonthlyReport(placementId, monthlyReport, month, year) {
  return {
    type: types.SEND_MONTHLY_REPORT,
    payload: {
      placementId,
      monthlyReport,
      month,
      year
    }
  };
}

export function setMonthlyReportSaved(placement) {
  return {
    payload: placement,
    type: types.MONTHLY_REPORT_SAVED
  };
}

export function setMonthlyReportSent(placement) {
  return {
    payload: placement,
    type: types.MONTHLY_REPORT_SENT
  };
}

export function resetSyncing() {
  return {
    type: types.RESET_SYNCING
  };
}

export function resetLoadPlacementsSyncing() {
  return {
    type: types.RESET_PLACEMENTS_SYNCING
  };
}

export function startSending(placementId, month, year) {
  return {
    type: types.START_SENDING,
    payload: {
      placementId,
      month,
      year
    }
  };
}

export function finishSending() {
  return {
    type: types.FINISH_SENDING
  };
}

export function startSaving() {
  return {
    type: types.START_SAVING
  };
}

export function finishSaving() {
  return {
    type: types.FINISH_SAVING
  };
}

export function resetIsReportSaved() {
  return {
    type: types.RESET_IS_MONTHLY_REPORT_SAVED
  };
}
