import { createMuiTheme } from '@material-ui/core/styles';
import { hexToRgba, lightenDarkenColor } from 'utils/colorHelpers';

const COLORS = {
  alabaster: '#F9F9F9',
  alto: '#D8D8D8',
  black: '#000000',
  cerulean: '#00A5ED',
  doveGrey: '#666666',
  dustyGrey: '#979797',
  iceberg: '#CCEFE8',
  lochmara: '#0088D2',
  persianGreen: '#00B18D',
  red: '#FF0000',
  sandyBeach: '#FFEECC',
  seashell: '#F1F1F1',
  silver: '#CCCCCC',
  tundora: '#444444',
  webOrange: '#FFA800',
  white: '#FFFFFF',
  wildSand: '#F5F5F5',
  yellowOrange: '#FFBE41',
  yourPink: '#FFCCCC',
  transparent: 'transparent',
  blueRibbon: '#206AF5',
  toreaBay: '#14249F',
  navyBlue: '#000068',
  ultramarine: '#3679F6',
  indigoDye: '#12208F',
};

export const defaultTheme = {
  animation: {
    cubicBezier: '400ms cubic-bezier(0.23, 1, 0.32, 1) 0ms',
    standard: '200ms ease-out'
  },
  borderRadius: {
    xsmall: '',
    small: '',
    base: '1px',
    large: '2px',
    xlarge: '4px'
  },
  boxShadow: {
    base: `0 0 4px 1px ${hexToRgba(COLORS.black, 0.25)}`,
    large: `0 0 16px 1px ${hexToRgba(COLORS.black, 0.25)}`
  },
  color: {
    /* default theme color used for base components state */
    default: {
      contrast: COLORS.white,
      dark: lightenDarkenColor(COLORS.tundora, -20),
      gradient: `linear-gradient(270deg, ${COLORS.silver} 0%, ${COLORS.alto} 100%)`,
      light: COLORS.doveGrey,
      main: COLORS.tundora
    },
    /* primary theme color used for header and primary theme elements */
    primary: {
      contrast: COLORS.white,
      dark: lightenDarkenColor(COLORS.blueRibbon, -20),
      gradient: `linear-gradient(270deg, ${COLORS.blueRibbon} 0%, ${COLORS.ultramarine} 100%)`,
      light: COLORS.ultramarine,
      main: COLORS.blueRibbon
    },
    /* secondary theme color used mainly for special items and actions */
    secondary: {
      contrast: COLORS.white,
      dark: lightenDarkenColor(COLORS.webOrange, -20),
      gradient: `linear-gradient(270deg, ${COLORS.webOrange} 0%, ${COLORS.yellowOrange} 100%)`,
      light: COLORS.yellowOrange,
      main: COLORS.webOrange
    },
    background: {
      dark: COLORS.tundora,
      // main application background
      default: COLORS.wildSand,
      // background for dialogs and other overlays
      overlay: hexToRgba(COLORS.black, 0.3),
      // large containers or sections
      section: `linear-gradient(0deg, ${COLORS.wildSand} 0%, ${COLORS.white} 100%)`,
      // background for scrollable or small items
      surface: COLORS.white,
       // RTEXT-701 (for transparent cards)
       transparent: COLORS.transparent
    },
    danger: {
      contrast: COLORS.white,
      dark: lightenDarkenColor(COLORS.red, -20),
      light: hexToRgba(COLORS.red, 0.7),
      main: COLORS.red
    },
    disabled: {
      background: COLORS.wildSand,
      border: COLORS.silver,
      boxShadow: COLORS.silver,
      text: COLORS.silver,
      // @TODO replace with opaque color, transparent text should not be used
      transparentText: hexToRgba(COLORS.black, 0.15)
    },
    grey: {
      100: COLORS.alabaster,
      200: COLORS.wildSand,
      300: COLORS.seashell,
      400: COLORS.alto,
      500: COLORS.silver,
      600: '',
      700: COLORS.dustyGrey,
      800: COLORS.doveGrey,
      900: COLORS.tundora
    },
    success: {
      contrast: COLORS.white,
      dark: lightenDarkenColor(COLORS.persianGreen, -20),
      light: COLORS.iceberg,
      main: COLORS.persianGreen
    },
    text: {
      highlight: COLORS.blueRibbon,
      hint: '',
      light: COLORS.doveGrey,
      main: COLORS.tundora,
      // @TODO replace with opaque color, transparent text should not be used
      transparent: hexToRgba(COLORS.black, 0.38)
    },
    toreaBay: {
      contrast: COLORS.white,
      dark: lightenDarkenColor(COLORS.toreaBay, -20),
      gradient: `linear-gradient(270deg, ${COLORS.toreaBay} 0%, ${COLORS.indigoDye} 100%)`,
      light: COLORS.indigoDye,
      main: COLORS.toreaBay
    }
  },
  fontFamily: "Roboto, 'Helvetica Neue', Helvetica, sans-serif",
  fontSize: {
    xsmall: '12px',
    small: '14px',
    base: '16px',
    large: '20px',
    xlarge: '24px',
    xxl: '32px',
    huge: '48px',
    title: '55px',
  },
  fontWeight: {
    light: 300,
    normal: 400,
    bold: 700
  },
  layer: {
    above: 10,
    base: 0,
    modal: 1000,
    overlay: 100
  },
  opacity: {
    background: 0.88
  },
  spacing: function (...args) {
    return args.map(multiplier => `${multiplier * 8}px`).join(' ');
  }
};

const muiTheme = {
  palette: {
    primary: {
      contrastText: COLORS.white,
      light: COLORS.ultramarine,
      main: COLORS.blueRibbon
    },
    secondary: {
      contrastText: COLORS.white,
      light: COLORS.yellowOrange,
      main: COLORS.webOrange
    },
    error: {
      main: COLORS.red
    },
    background: {
      default: COLORS.wildSand
    },
    text: {
      primary: COLORS.tundora,
      secondary: COLORS.doveGrey
    }
  },
  typography: {
    fontFamily: defaultTheme.fontFamily
  }
};

const defaultMuiTheme = createMuiTheme(muiTheme);
defaultMuiTheme.overrides = {
  MuiButton: {
    contained: {
      boxShadow: 'none',
      '&:active': {
        boxShadow: 'none'
      }
    },
    containedPrimary: {
      '&:not($disabled)': {
        background: `linear-gradient(270deg, ${COLORS.lochmara} 0%, ${COLORS.cerulean} 100%)`
      }
    },
    sizeLarge: {
      fontSize: '1rem'
    }
  },
  MuiCollapse: {
    container: {
      width: '100%'
    },
    wrapper: {
      width: '100%'
    }
  },
  MuiMenuItem: {
    root: {
      paddingTop: 4,
      paddingBottom: 4,
      [defaultMuiTheme.breakpoints.up('sm')]: {
        minHeight: 48
      }
    }
  },
  MuiOutlinedInput: {
    adornedEnd: {
      paddingRight: 0
    }
  },
  MuiPickersCalendarHeader: {
    daysHeader: {
      justifyContent: 'space-between'
    }
  },
  MuiPickersCalendar: {
    week: {
      justifyContent: 'space-between',
      '& div[role="presentation"]': {
        flex: '1 1',
        display: 'flex',
        justifyContent: 'center'
      }
    }
  },
  MuiTableCell: {
    body: {
      fontSize: '16px'
    },
    head: {
      fontSize: '14px',
      fontWeight: '700',
      textTransform: 'uppercase'
    },
    stickyHeader: {
      backgroundColor: COLORS.white
    }
  }
};

export { defaultMuiTheme };
