import styled from 'styled-components';

export const Detail = styled.p`
  margin: 0;
  margin-bottom: ${props => props.theme.spacing(2)};
  ${props => props.small && `font-size: ${props.theme.fontSize.xsmall};`}

  em {
    font-style: normal;
  }
`;
