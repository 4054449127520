import { createSelector } from 'reselect';
// the values from languages keys should be language codes not numeric ids
const LANGUAGE_CODES = {
  '100': 'de',
  '200': 'en'
};

export const selectAppLanguageCodes = state => state.uiState.languages;
export const selectAppLanguages = createSelector([selectAppLanguageCodes], appLanguageCodes =>
  appLanguageCodes.map(languageCode => LANGUAGE_CODES[languageCode])
);
export const selectLanguage = state => LANGUAGE_CODES[state.uiState.language];
export const selectLanguageCode = state => state.uiState.language;
export const selectResumeLanguage = state => state.uiState.resumeLanguage;
