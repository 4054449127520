import styled, { createGlobalStyle } from 'styled-components';

export const Container = styled.div`
  overflow: hidden;
`;

export const GlobalEditorStyle = createGlobalStyle`
  .mce-content-body.mce-content-readonly {
    line-height: 1.375em;

    ul {
      margin: 0;
      margin-bottom: ${props => props.theme.spacing(1)};
      
    }
  }
`;
