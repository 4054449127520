import { connect } from 'react-redux';
import * as metaSelectors from 'redux-modules/meta/selectors';
import * as uiStateSelectors from 'redux-modules/uiState/selectors';
import Language from './Language';

function mapStateToProps(state) {
  return {
    meta: metaSelectors.selectMeta(state),
    supportedLanguages: uiStateSelectors.selectAppLanguageCodes(state)
  };
}

export default connect(mapStateToProps, null)(Language);
