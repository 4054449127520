import * as types from './types';

export function failOffersLoad(error) {
  return {
    payload: {
      error
    },
    type: types.OFFERS_FAILED
  };
}
export function loadOffers(news) {
  return {
    type: types.LOAD_OFFERS
  };
}

export function setLoadedOffers(offers) {
  return {
    payload: {
      offers
    },
    type: types.OFFERS_LOADED
  };
}
