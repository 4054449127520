import React from 'react';
import PropTypes from 'prop-types';
import MuiTable from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Editor } from '@tinymce/tinymce-react';
import { StyledTableRow } from './style';

// @TODO extend behavior, add mobile version and split into subcomponents

function Table({ className, columns, data, timeTracking, type }) {
  const tinyMceConfig = {
    menubar: false,
    plugins: ['autoresize'],
    valid_elements: 'li,br,p,ul',
    valid_children: '-li[ul]',
    content_style: `
    body {  
      font-family: Roboto,Helvetica Neue,Helvetica,sans-serif;
      vertical-align: left;
      color: #444444;
    }`,
    toolbar: false,
    width: '100%',
    branding: false,
    elementpath: false,
    statusbar: false,
    inline: true
  };

  return (
    <TableContainer className={className}>
      <MuiTable size="medium">
        <TableHead>
          <TableRow>
            {columns.map(column => (
              <TableCell
                align={column.align}
                key={column.id}
                style={{ minWidth: column.minWidth, width: column.width, ...column.headerStyle }}
              >
                {typeof column.Header === 'function' ? column.Header() : column.Header}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((rowData, index) => {
            return (
              <StyledTableRow hover key={rowData.id || index}>
                {columns.map(column => {
                  // RTEXT-684
                  // implemented in RTEXT-620 but fixed its display in 684
                  let flag = true;
                  if (type === "placements" && rowData && rowData.reportBillingViaPortal !== "100") {
                    flag = false;
                  }
                  return (
                    <TableCell align={column.align} key={column.id}>
                      {/* 
                       RTEXT-618
                       making timetracking table "Details" column as an html field
                      */}
                      {timeTracking && column.Header === 'Details' ? (
                        <Editor init={tinyMceConfig} initialValue={rowData.description} />
                      ) : column.Cell ? (
                        column.Cell(rowData, flag)
                      ) : (
                        rowData[column.id]
                      )}
                    </TableCell>
                  );
                })}
              </StyledTableRow>
            );
          })}
        </TableBody>
      </MuiTable>
    </TableContainer>
  );
}

Table.defaultProps = {
  columns: [],
  data: []
};

Table.propTypes = {
  className: PropTypes.string,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      Cell: PropTypes.oneOfType([PropTypes.func, PropTypes.node, PropTypes.object]),
      Header: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.node,
        PropTypes.object,
        PropTypes.string
      ]),
      align: PropTypes.oneOf(['inherit', 'left', 'center', 'right', 'justify']),
      id: PropTypes.string.isRequired,
      minWidth: PropTypes.string,
      width: PropTypes.string
    })
  ).isRequired,
  data: PropTypes.array.isRequired,
  timeTracking: PropTypes.bool
};

export default Table;
