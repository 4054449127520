import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import BaseCard from './cards/Card';

const SPACING = 1.875;

export const Card = styled(({ component: Component, grow, size, ...rest }) => (
  <Component {...rest} />
))`
  border-radius: ${props => props.theme.borderRadius.base};
  box-shadow: ${props => props.theme.boxShadow.base};
  flex: ${props =>
    `${props.grow || props.size} 1 calc((100% / 12 * ${props.size}) - 2 * ${props.theme.spacing(
      SPACING
    )})`};
  min-width: 300px;
`;

Card.defaultProps = {
  component: BaseCard,
  size: '1'
};

Card.propTypes = {
  component: PropTypes.oneOfType([PropTypes.func, PropTypes.node, PropTypes.object]),
  size: PropTypes.string.isRequired
};

export const CardsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: ${props => props.jc ? props.jc: null};
  margin: ${props => props.theme.spacing(-SPACING)};

  ${Card} {
    margin: ${props => props.theme.spacing(SPACING)};
  }
`;
