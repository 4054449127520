import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { DateField, MultiLanguageTextField, TextField } from 'components/inputs';
import { Switch } from 'components/buttons';
import { FormContainer, FormItem, FormRow, FormTextItem } from 'components/styledComponents';
import { useLanguagesPaths, useTranslation } from 'hooks';

function Form({ basePath, disabled, getValue, language, useInput }) {
  const { tr } = useTranslation();
  const { onChange: onChangeDateTo } = useInput(`${basePath}dateTo`);
  const isActualEngagement = getValue(`${basePath}isActualEngagement`);

  useEffect(() => {
    if (isActualEngagement) {
      onChangeDateTo('');
    }
  }, [isActualEngagement, onChangeDateTo]);

  return (
    <FormContainer>
      <FormRow>
        <FormItem
          component={TextField}
          disabled={disabled}
          label={tr('label.sisterCompanyName')}
          required
          {...useInput(`${basePath}companyName`)}
        />
        <FormItem
          component={MultiLanguageTextField}
          disabled={disabled}
          label={tr('label.jobTitle')}
          language={language}
          required
          {...useInput(`${basePath}name`, {
            alternateFields: useLanguagesPaths(`${basePath}name`)
          })}
        />
      </FormRow>
      <FormRow>
        <FormTextItem>
          {tr('message.doYouCurrentlyWork')}
          <Switch
            disabled={disabled}
            leftLabel={tr('button.no')}
            rightLabel={tr('button.yes')}
            {...useInput(`${basePath}isActualEngagement`)}
          />
        </FormTextItem>
      </FormRow>
      <FormRow>
        <FormItem
          component={DateField}
          disabled={disabled}
          disableFuture
          label={tr('label.dateFrom')}
          required
          wizardMode
          {...useInput(`${basePath}dateFrom`)}
        />
        <FormItem
          component={DateField}
          disabled={disabled || getValue(`${basePath}isActualEngagement`)}
          disableFuture
          label={tr('label.dateTo')}
          minDate={getValue(`${basePath}dateFrom`)}
          required
          wizardMode
          {...useInput(`${basePath}dateTo`)}
        />
      </FormRow>
    </FormContainer>
  );
}

Form.propTypes = {
  basePath: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  getValue: PropTypes.func.isRequired,
  language: PropTypes.string.isRequired,
  useInput: PropTypes.func.isRequired
};

export default Form;
