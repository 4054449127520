import { connect } from 'react-redux';
import * as metaActions from 'redux-modules/meta/actions';
import * as fieldsMetaActions from 'redux-modules/fieldsMeta/actions';
import AppDataLoader from './AppDataLoader';

function mapDispatchToProps(dispatch) {
  return {
    onInit: () => {
      dispatch(metaActions.loadMeta());
      dispatch(fieldsMetaActions.loadFieldsMeta());
    }
  };
}

export default connect(null, mapDispatchToProps)(AppDataLoader);
