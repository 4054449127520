import styled from 'styled-components';
import { Button as BaseButton } from 'components/buttons';

export const Button = styled(BaseButton)`
  :disabled {
    background: ${props => (props.disabledColor ? props.disabledColor : '#F5F5F5')};
  }
`;

export const FlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ButtonContainer = styled.div`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  margin: ${props => props.theme.spacing(-1.5)};
  width: calc(100% + ${props => props.theme.spacing(3)});

  ${Button} {
    margin: ${props => props.theme.spacing(1.5)};
  }
  ${Button} {
    @media (max-width: 400px) {
      flex: 1 1 100%;
    }
  }
`;

export const Explain = styled.div`
  font-size: ${props => props.theme.fontSize.normal};
  font-weight: ${props => props.theme.fontWeight.bold};
  margin: ${props => props.theme.spacing(1.5, 0, 0, 1.5)};
`;

export const FormRowContainer = styled.div`
  display: flex;
  flex: 100%;
  flex-direction: column;
  margin-bottom: ${props => props.theme.spacing(5)};
`;

export const Section = styled.div`
  display: flex;
  flex-grow: 1;
  flex-wrap: ${props => props.wrap || 'wrap'};
  ${props => props.justify && `justify-content: ${props.justify};`}

  @media (max-width: 650px) {
    justify-content: center;
  }
`;
