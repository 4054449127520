import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Container from '@material-ui/core/Container';
import { Availability, CardsContainer, Opportunities, Placements, Resume } from 'components/cards';
import { Section } from 'components/styledComponents';
import News from './News';
import Offers from './Offers';
import Dialog from 'components/Dialog';
import { Image } from 'components/pages/RegistrationPage/style.js';
import enDemo from 'components/pages/RegistrationPage/images/atreus-portal-en.jpg';
import deDemo from 'components/pages/RegistrationPage/images/atreus-portal-de.jpg';
import { useTranslation } from 'hooks';
import { useLocation, useHistory } from 'react-router-dom';

function DashboardPage({ initialInputCv, language }) {
  const { t } = useTranslation();
  const { state } = useLocation();
  const history = useHistory();
  const [open, setOpen] = useState(false);

  // RTEXT-748
  // open dialog only if network status is 200
  // or if it comes from login page
  useEffect(() => {
    if (initialInputCv === '100' && state?.fromLogin) {
      setOpen(true);
    }
  }, [initialInputCv, state]);

  const handleClose = () => {
    setOpen(false);
    // make sure that location state get cleared after closing dialog
    history.push('/dashboard');
  };

  return (
    <React.Fragment>
      <News />
      <Section padding="5 0">
        <Container>
          <CardsContainer>
            <Resume size="4" />
            <Availability grow="8" size="4" />
            <Opportunities size="12" />
          </CardsContainer>
        </Container>
      </Section>
      <Section padding="5 0 4">
        <Container>
          <Placements />
        </Container>
      </Section>
      <Offers />
      <Dialog
        actionLabel={t('button.close')}
        open={open}
        title={t('label.automaticReleaseSuccess')}
        onAction={handleClose}
        onClose={handleClose}
      >
        <Image src={language === '200' ? enDemo : deDemo} />
      </Dialog>
    </React.Fragment>
  );
}

DashboardPage.propTypes = {
  className: PropTypes.string,
  initialInputCv: PropTypes.string,
  language: PropTypes.string
};

export default DashboardPage;
