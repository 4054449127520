import React, {useEffect, useState} from "react";
import {useIdleTimer} from "react-idle-timer";
import {format} from "date-fns";

export default function AutoLogout ({onInActivity}) {
  const timeout = 60 * 60000; //converting it to milliseconds
  const [remaining, setRemaining] = useState(timeout)
  const [elapsed, setElapsed] = useState(0)
  const [lastActive, setLastActive] = useState(+new Date())
  const [isIdle, setIsIdle] = useState(false)

  const handleOnActive = () => setIsIdle(false)
  const handleOnIdle = () => {
    setIsIdle(true);
    //expire cookies and redirect to login page
    onInActivity();
  }

  const {
    reset,
    pause,
    resume,
    getRemainingTime,
    getLastActiveTime,
    getElapsedTime
  } = useIdleTimer({
    timeout,
    onActive: handleOnActive,
    onIdle: handleOnIdle
  })

  const handleReset = () => reset()
  const handlePause = () => pause()
  const handleResume = () => resume()

  const doLogoutIfTimeElapsed = () => {
    let lastActiv = localStorage.getItem('pLastActive');
    if (lastActiv) {
      let currentTime = +new Date();
      let differance = currentTime - lastActiv;
      if (differance > timeout) {
        onInActivity();
        return true;
      }
    }
    return false;
  };
  const updateLastActive = () => {
    localStorage.setItem('pLastActive', getLastActiveTime());
  };

  useEffect(() => {
    let isLoggedOut = doLogoutIfTimeElapsed();
    setRemaining(getRemainingTime());
    setLastActive(getLastActiveTime());
    setElapsed(getElapsedTime());

    let intervalID = setInterval(() => {
      setRemaining(getRemainingTime());
      setLastActive(getLastActiveTime());
      setElapsed(getElapsedTime());
      updateLastActive();
    }, 1000);

    return () => {
      if (intervalID) {
        clearInterval(intervalID);
      }
    }
  }, []);

  return (
      <div>
      </div>
  )
}