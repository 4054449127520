import styled from 'styled-components';
import Grid from '@material-ui/core/Grid';

export const Description = styled.div`
  && {
    font-weight: ${props => props.theme.fontWeight.bold};
    margin-bottom: ${props => props.theme.spacing(1)};
    margin-top: ${props => props.theme.spacing(1)};
  }
`;

export const Image = styled.img`
  width: 180px;
  height: 230px;
  object-fit: cover;
  border: 2px solid transparent;
  ${props =>
    props.active &&
    `
      border: 2px solid ${props.theme.color.primary.main};
      box-shadow: 0 0 10px ${props.theme.color.primary.main};
    `}
`;

export const Label = styled.p`
  color: #666666;
  font-size: ${props => props.theme.fontSize.small};
  margin-top: ${props => (props.mt ? props.mt + 'px' : '0px')};
  margin-bottom: ${props => (props.mb ? props.mb + 'px' : '0px')};
`;

export const MuiGrid = styled(Grid)`
  align-items: center;
`;

export const GridContainer = styled.div`
  .MuiGrid-spacing-xs-3 {
    width: 110% !important;
  }
`;

export const BoxContainer = styled.div`
  display: flex;
`;

export const Box = styled.div`
  width: 20px;
  height: 20px;
  background: ${props => (props.color ? props.color : '#fffff')};
  border: 2px solid rgba(0, 0, 0, 0.2);
  margin-left: 8px;
  ${props =>
    props.active &&
    `
      border: 2px solid ${props.theme.color.primary.main};
      box-shadow: 0 0 10px ${props.theme.color.primary.main};
    `}
`;
