import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useRouteMatch } from 'react-router-dom';

export default function AppScrollToTop({ children }) {
  const match = useRouteMatch({
    path: '/:base/',
    strict: false,
    sensitive: false
  });
  const baseRouteSegment = (match && match.params && match.params.base) ?? '';

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [baseRouteSegment]);

  return <div>{children}</div>;
}

AppScrollToTop.propTypes = {
  children: PropTypes.array
};
