import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import MuiDialog from '@material-ui/core/Dialog';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import * as BaseComponents from './components';

function Dialog({
  actionLabel,
  children,
  components,
  disabled,
  footerProps,
  fullWidth,
  maxWidth,
  open,
  syncing,
  title,
  subTitle,
  onAction,
  onClose,
  padding,
  paperClass,
  overflowX,
  ...rest
}) {
  const fullScreen = useMediaQuery('@media (max-width: 768px)');
  const paperProps = useMemo(
    () => ({ loading: syncing, className: paperClass ? paperClass : null }),
    [syncing]
  );
  const { Content, DialogPaper, Footer, Header } = useMemo(
    () => ({ ...BaseComponents, ...components }),
    [components]
  );

  return (
    <MuiDialog
      fullScreen={fullScreen}
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      open={open}
      PaperComponent={DialogPaper}
      PaperProps={paperProps}
      onClose={onClose}
      {...rest}
    >
      {title && <Header title={title} subTitle={subTitle} onClose={onClose} />}
      <Content padding={padding} overflowX={overflowX}>{children}</Content>
      <Footer actionLabel={actionLabel} disabled={disabled} onAction={onAction} {...footerProps} />
    </MuiDialog>
  );
}

Dialog.defaultProps = {
  components: {},
  disableBackdropClick: true,
  footerProps: {},
  fullWidth: true,
  maxWidth: 'md'
};

Dialog.propTypes = {
  actionLabel: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
  components: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  footerProps: PropTypes.object.isRequired,
  fullWidth: PropTypes.bool.isRequired,
  maxWidth: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  syncing: PropTypes.bool,
  title: PropTypes.oneOfType([PropTypes.func, PropTypes.node, PropTypes.string]),
  onAction: PropTypes.func,
  onClose: PropTypes.func.isRequired
};

export default Dialog;
