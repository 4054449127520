import styled from 'styled-components';

export const Content = styled.div`
  //background: ${props => props.theme.color.background.surface};
`;

export const FooterActions = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: ${props => props.theme.spacing(-1)};
  padding: ${props => props.theme.spacing(4)} 0;
`;

export const Header = styled.div`
  background: ${props => props.theme.color.primary.gradient};
  color: ${props => props.theme.color.primary.contrast};
  padding: ${props => props.theme.spacing(3)} 0;
`;

export const Title = styled.h1`
  margin: 0;
`;
