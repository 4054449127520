import { connect } from 'react-redux';
import * as metaSelectors from 'redux-modules/meta/selectors';
import * as resumeSelectors from 'redux-modules/resume/selectors';
import OtherRelatedRoles from './OtherRelatedRoles';

function mapStateToProps(state) {
  return {
    meta: metaSelectors.selectResumeMeta(state),
    workExperience: resumeSelectors.selectWorkExperience(state)
  };
}

export default connect(mapStateToProps, null)(OtherRelatedRoles);
