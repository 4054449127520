import { createSelector } from 'reselect';
import { EMPTY_META } from './constants';
import * as uiStateSelectors from 'redux-modules/uiState/selectors';

function indexMeta(meta) {
  const indexedMeta = {};

  Object.keys(meta).forEach(metaKey => {
    const metaList = meta[metaKey];
    if (Array.isArray(metaList)) {
      indexedMeta[metaKey] = metaList.reduce((indexed, item) => {
        if (item.key) {
          indexed[item.key] = item.value;
        }
        return indexed;
      }, {});
    }
  });

  return indexedMeta;
}

const selectMetaState = state => state.meta;
export const selectLoadedState = createSelector(
  [selectMetaState, uiStateSelectors.selectLanguage, uiStateSelectors.selectResumeLanguage],
  (meta, appLanguage, resumeLanguage) =>
    !!(
      meta[appLanguage] &&
      meta[appLanguage].loaded &&
      meta[resumeLanguage] &&
      meta[resumeLanguage].loaded
    )
);
export const selectMeta = createSelector(
  [selectMetaState, uiStateSelectors.selectLanguage],
  (meta, language) => Object.assign({}, EMPTY_META, meta[language])
);
export const selectResumeMeta = createSelector(
  [selectMetaState, uiStateSelectors.selectResumeLanguage],
  (meta, language) => Object.assign({}, EMPTY_META, meta[language])
);
export const selectIndexedMeta = createSelector([selectMeta], indexMeta);
export const selectIndexedResumeMeta = createSelector([selectResumeMeta], indexMeta);
