import * as types from './types';
import * as selectors from './selectors';
import * as resumeSelectors from 'redux-modules/resume/selectors';
import * as actions from './actions';
import apiClient from 'communication/apiClient';
import { call, put, select, takeLeading } from 'redux-saga/effects';

export function* fetchManagerDocuments(actionData) {
  const { refresh, successCallback } = actionData.payload;
  const documents = yield select(selectors.selectDocuments);
  if (!refresh && documents) {
    yield put(actions.resetDocumentsSyncing());
    return;
  }
  const resp = yield call(apiClient.get, `/api/v1/manager/documents`);
  if (resp.data) {
    yield put(actions.fetchDocumentsSuccess(resp.data));
    if (successCallback) {
      successCallback();
    }
  }
}

export function* uploadManagerDocument(actionData) {
  const { subcategoryId, file, successCallback, failCallback } = actionData.payload;
  const managerId = yield select(resumeSelectors.selectManagerId);
  const formData = new FormData();
  formData.append('file', file);

  const fileResp = yield call(
    apiClient.custom,
    {
      url: `/api/v1/upload-resume/${subcategoryId}/${managerId}`,
      method: 'POST',
      body: formData
    },
    failCallback
  );

  if (fileResp.data) {
    yield put(actions.uploadDocumentSuccess());
    yield put(actions.fetchDocuments(true, successCallback));
  }
}

export default [
  takeLeading(types.FETCH_MANAGER_DOCUMENTS, fetchManagerDocuments),
  takeLeading(types.UPLOAD_MANAGER_DOCUMENT, uploadManagerDocument)
];
