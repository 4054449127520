class ErrorHandler {
  handle(errorData) {
    // check if errorData is a promise
    if (
      !!errorData &&
      (typeof errorData === 'object' || typeof errorData === 'function') &&
      typeof errorData.then === 'function'
    ) {
      errorData.then(error => this.handleError(error));
    } else {
      this.handleError(errorData);
    }
  }
  handleError(error) {
    // eslint-disable-next-line no-console
    console.log(error);
  }
}

export default new ErrorHandler();
