import styled from 'styled-components';
import { Section } from 'components/styledComponents';

export const Name = styled.h1`
  word-break: break-all;
  color: ${props => props.theme.color.primary.main};
  font-weight: ${props => props.theme.fontWeight.light};
  margin: 0;
`;

export const ProfileImage = styled.img`
  object-position: top;
`;

export const UserDetails = styled.div`
  align-items: flex-start;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-right: ${props => props.theme.spacing(3)};
  
  .submitAppButton {
    width:100% 
  }
  .SubmitAppButtonLabel {
    white-space: pre-wrap;
  } 
`;

export const UserInfo = styled(Section)``;

export const DetailsWrapper = styled.div`
  padding: ${props => props.theme.spacing(8)} 0;
`;

export const DetailsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: ${props => props.theme.spacing(-2)};

  ${ProfileImage}, ${UserDetails} {
    margin: ${props => props.theme.spacing(2)};
  }

  ${ProfileImage} {
    @media (max-width: 500px) {
      margin: ${props => props.theme.spacing(2)} auto;
    }
  }

  ${UserDetails} {
    flex-grow: 999;
  }
`;
