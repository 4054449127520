import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { EditorState } from 'draft-js';
import { stateFromHTML } from 'draft-js-import-html';
import { useTranslation } from 'hooks';
import TextEditor from 'components/TextEditor';
import {ExperienceAbroadHeader, Container, DetailedView, DetailsRow, DetailsWrapper, Item, DesktopItem, MobileItem, TitleDescription, DottedHR} from './style';

function Detail({
  className,
  data,
  indexedMeta: {
    anualRevenues,
    budgets,
    companySituations,
    companyTurnoverList,
    contractTypes,
    roleList,
    coreCompetencies,
    countries,
    directReports,
    employees,
    functions,
    industries,
    ownershipStructures,
    workAbroadAssignmentTypes
  },
  language
}) {
  const { tr } = useTranslation();
  const editorState = useMemo(
    () => EditorState.createWithContent(stateFromHTML(data.description[language])),
    [data.description, language]
  );

  const experienceAbroad = useMemo(() => {
    const experience = { countries: [], months: [], types: [] };
    data.missionsAbroad.forEach(item => {
      experience.countries.push(countries[item.country]);
      experience.months.push(tr('label.numberOfMonths', { count: item.monthsSpent }));
      experience.types.push(workAbroadAssignmentTypes[item.assignmentType]);
    });
    return experience;
  }, [countries, data.missionsAbroad, tr, workAbroadAssignmentTypes]);

  const getMobileView = () => {
    return(<>
        <DetailsRow>
              <MobileItem  label={tr('label.countryAbroad')} />
        </DetailsRow>
        {experienceAbroad.types.map((item, index) => (
            <DetailsRow>
              <MobileItem content={experienceAbroad.countries[index]} months={experienceAbroad.months[index]} type= {experienceAbroad.types[index]}/>
            </DetailsRow>
            ))}
        </>)
  }

  const getDesktopView = ()=> {
    return(<>
        <DetailsRow>
          <ExperienceAbroadHeader  label={tr('label.countryAbroad')} />
          <ExperienceAbroadHeader  label={tr('label.monthsSpent')} />
          <ExperienceAbroadHeader  label={tr('label.abroadAssignmentType')} />
        </DetailsRow>
        {experienceAbroad.types.map((item, index) => (
            <DetailsRow>
              <DesktopItem content={experienceAbroad.countries[index]}  />
              <DesktopItem content={experienceAbroad.months[index]} />
              <DesktopItem content={experienceAbroad.types[index]} />
            </DetailsRow>
            ))}
        </>)
  }

  let DynamicComp;
  const mql = window.matchMedia('(max-width: 1162px)');
  let mobileView = mql.matches;

  DynamicComp =(mobileView) ?  getMobileView() : getDesktopView()
  const [screenSize, setScreenSize] = useState(mobileView);
  
  mql.addEventListener('change', (e) => {
    mobileView = e.matches;
    DynamicComp =(mobileView) ?  getMobileView() : getDesktopView()
    setScreenSize(mobileView);
  });

  return (
    <Container className={className}>
      <DetailedView>
        <DetailsWrapper>
          <DetailsRow>
            <Item
                content={contractTypes[data.contractType]}
                label={`${tr('label.contractType')}:`}
            />
            <Item content={functions[data.function]} label={`${tr('label.function')}:`} />
            <Item
                content={roleList[data.role]}
                label={`${tr('label.role')}:`}
            />
            
          </DetailsRow>
          <hr/>
          <DetailsRow>
          <Item content={data.reportingTo[language]} label={`${tr('label.reportingTo')}:`} />
            <Item
                content={anualRevenues[data.revenueResponsability]}
                label={`${tr('label.revenueResponsibility')}:`}
            />
            <Item
                content={budgets[data.costBudgetResponsability]}
                label={`${tr('label.budgetResponsibility')}:`}
            />
           
          </DetailsRow>
          <hr/>
          <DetailsRow>
           <Item
                content={employees[data.personnelResponsability]}
                label={`${tr('label.personnelResponsibility')}:`}
            />
            <Item
                content={directReports[data.noOfDirectReports]}
                label={`${tr('label.numberOfReports')}:`}
            />
            <Item
                content={companySituations[data.companySituation]}
                label={`${tr('label.companySituation')}:`}
            />
          </DetailsRow>
          <hr/>
          <DetailsRow>
            <Item
                content={
                  data.coreCompetencies.length
                      ? data.coreCompetencies.map(item => coreCompetencies[item])
                      : '-'
                }
                label={`${tr('label.coreCompetencies')}:`}
            />
          </DetailsRow>

          {data.description[language] === ''  && !data.missionsAbroad.length && !data.reference ? <DottedHR/>: <hr/>}

          {data.description[language] !== '' ? (
              <>
            <DetailsRow>
              <TitleDescription>{tr('label.description')}</TitleDescription>
              <Item
                as={TextEditor}
                className={className + ' des-content'}
                editorState={editorState}
                grow="1"
                readOnly
                value={data.description[language]}
              />
            </DetailsRow>
                {!data.missionsAbroad.length && !data.reference ? <DottedHR/>: <hr/>}
                </>

          ) : null}

          {data.missionsAbroad.length ? (
              <>
                {DynamicComp}
                {!data.reference ? <DottedHR/>: <hr/>}
            </>

          )  : null}

          {data.reference ? (
              <>
            <DetailsRow>
              {/*<Title>{tr('title.references')}</Title>*/}
              <Item content={data.reference.name} label={tr('label.referenceName')} />
              <Item content={data.reference.position} label={tr('label.referencePosition')} />
              <Item content={data.reference.contactDetails} label={tr('label.referenceContact')} />

            </DetailsRow>
            </>

          ) : null}
          {data.reference ? (
              <>
                {data.reference.comment ? <hr/>: null}
              <DetailsRow>
                {data.reference.comment ? (
                    <Item
                        content={data.reference.comment}
                        grow="1"
                        label={tr('label.referenceComment')}
                    />
                )  : null}
              </DetailsRow>
              <DottedHR className="last-reference"/>
              </>
          ) : null}
        </DetailsWrapper>
      </DetailedView>
    </Container>
  );
}

Detail.propTypes = {
  className: PropTypes.string,
  data: PropTypes.object,
  indexedMeta: PropTypes.shape({
    anualRevenues: PropTypes.object.isRequired,
    budgets: PropTypes.object.isRequired,
    companySituations: PropTypes.object.isRequired,
    companyTurnoverList: PropTypes.object.isRequired,
    contractTypes: PropTypes.object.isRequired,
    coreCompetencies: PropTypes.object.isRequired,
    countries: PropTypes.object.isRequired,
    directReports: PropTypes.object.isRequired,
    employees: PropTypes.object.isRequired,
    functions: PropTypes.object.isRequired,
    industries: PropTypes.object.isRequired,
    ownershipStructures: PropTypes.object.isRequired,
    workAbroadAssignmentTypes: PropTypes.object.isRequired,
    roleList: PropTypes.object.isRequired
  }).isRequired,
  language: PropTypes.string.isRequired
};

export default Detail;
