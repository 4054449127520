import { connect } from 'react-redux';
import * as resumeSelectors from 'redux-modules/resume/selectors';
import * as uiStateSelectors from 'redux-modules/uiState/selectors';
import * as resumeActions from 'redux-modules/resume/actions';
import ResumeActions from './ResumeActions';

function mapStateToProps(state) {
  return {
    loading: resumeSelectors.selectLoadState(state),
    initialInputCv: resumeSelectors.selectInitialInputCv(state),
    language: uiStateSelectors.selectLanguage(state)
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onMarkComplete: (data, failCallback) =>
      dispatch(resumeActions.markResumeComplete(data, failCallback))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ResumeActions);
