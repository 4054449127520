import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Details from './Details';
import Section from '../Section';

function ITSkills({ className, editPath, indexedMeta, itSkills }) {
  const itSkillsData = useMemo(() => (itSkills && itSkills.itSkills) || [], [itSkills]);
  const path = useMemo(() => editPath && `${editPath}/it-skills`, [editPath]);

  return (
    <Section className={className} editPath={path} title="title.itSkills">
      {/* Boolean conversion needed to avoid displaying "0" when length is 0 */}
      {!!itSkillsData.length && <Details indexedMeta={indexedMeta} itSkills={itSkillsData} />}
    </Section>
  );
}

ITSkills.propTypes = {
  className: PropTypes.string,
  editPath: PropTypes.string,
  indexedMeta: PropTypes.object.isRequired,
  itSkills: PropTypes.object.isRequired
};

export default ITSkills;
