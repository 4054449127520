import React from 'react';
import PropTypes from 'prop-types';
import CandidateCard from './CandidateCard';
import { ProjectStatusTitle } from './style';
import Carousel from 'components/Carousel';

function SearchingCandidates({ project }) {
  return (
    <div>
      <ProjectStatusTitle>Searching for candidates...</ProjectStatusTitle>
      <Carousel>
        <CandidateCard synching={true} />
        <CandidateCard synching={true} />
        <CandidateCard synching={true} />
        <CandidateCard synching={true} />
        <CandidateCard synching={true} />
        <CandidateCard synching={true} />
      </Carousel>
    </div>
  );
}

SearchingCandidates.propTypes = {
  project: PropTypes.object.isRequired
};

export default SearchingCandidates;
