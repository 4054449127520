import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Route, withRouter } from 'react-router-dom';
import Dialog from 'components/Dialog';
import { useForm, useTranslation } from 'hooks';
import Form from './Form';

function OtherActivities({
  history,
  language,
  match: { url },
  otherActivities,
  syncErrors,
  syncing,
  onSave
}) {
  const { t, tr } = useTranslation();
  const { useInput, handleReset, handleSubmit } = useForm({
    initialValues: otherActivities,
    onSubmit: onSave
  });
  const handleClose = useCallback(() => {
    handleReset();
    history.push(url);
  }, [handleReset, history, url]);

  useEffect(() => {
    if (!syncErrors && !syncing) {
      handleClose();
    }
  }, [handleClose, syncErrors, syncing]);

  return (
    <Route
      path={`${url}/other-activities`}
      render={() => (
        <Dialog
          actionLabel={t('button.save')}
          disabled={syncing}
          open={true}
          syncing={syncing}
          title={tr('title.otherActivities')}
          onAction={handleSubmit}
          onClose={handleClose}
        >
          <Form data-test-id="other-activities-form" language={language} useInput={useInput} />
        </Dialog>
      )}
    />
  );
}

OtherActivities.propTypes = {
  otherActivities: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  language: PropTypes.string.isRequired,
  match: PropTypes.object.isRequired,
  syncErrors: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  syncing: PropTypes.bool.isRequired,
  onSave: PropTypes.func.isRequired
};

export default withRouter(OtherActivities);
