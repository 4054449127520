import React from 'react';
import PropTypes from 'prop-types';
import { Close } from 'components/icons';
import { CloseButton, Container, Title, SubTitle } from './style';

function Header({ title, onClose, subTitle }) {
  return (
    <Container>
        <Title>{title} &nbsp; <SubTitle>{subTitle && ('('+subTitle+')')}</SubTitle> </Title>
      <CloseButton onClick={onClose}>
        <Close />
      </CloseButton>
    </Container>
  );
}

Header.propTypes = {
  title: PropTypes.oneOfType([PropTypes.func, PropTypes.node, PropTypes.string]),
  onClose: PropTypes.func.isRequired
};

export default Header;
