import React from 'react';
import PropTypes from 'prop-types';
import { ProjectStatusTitle, ElectedCandidateContainer } from './style';
import CandidateDetailedCard from './CandidateDetailedCard';

function ElectedCandidate({ project }) {
  var candidate = null;
  project.candidates.map(item => {
    if (item.isChosen) {
      candidate = item;
    }
    return item;
  });
  return (
    <div>
      <ProjectStatusTitle>Elected Candidate:</ProjectStatusTitle>
      <ElectedCandidateContainer>
        <CandidateDetailedCard candidate={candidate} />
      </ElectedCandidateContainer>
    </div>
  );
}

ElectedCandidate.propTypes = {
  project: PropTypes.object.isRequired
};

export default ElectedCandidate;
