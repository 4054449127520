import React from 'react';
import PropTypes from 'prop-types';
import { SelectField } from 'components/inputs';
import Title from 'components/Title';
import { FormContainer, FormItem, FormRow, FormSection } from 'components/styledComponents';
import { useTranslation } from 'hooks';

function NotificationInformation({
  className,
  meta: { preferredContactTypeList },
  useInput
}) {
  const { t } = useTranslation();

  return (
    <FormContainer className={className}>
      <FormSection divider="bottom" padding="4 0">
        <Title label={t('title.notificationInformation')} size="large" />
      </FormSection>
      <FormSection padding="3 0">
        <FormRow>
          <FormItem
            component={SelectField}
            label={t('label.opportunityUpdates')}
            options={preferredContactTypeList}
            {...useInput('opportunityUpdates')}
          />
          <FormItem
            component={SelectField}
            label={t('label.cvUpdates')}
            options={preferredContactTypeList}
            {...useInput('cvUpdates')}
          />
        </FormRow>
        <FormRow>
          <FormItem
            component={SelectField}
            label={t('label.appointmentUpdates')}
            options={preferredContactTypeList}
            {...useInput('appointmentUpdates')}
          />
          <FormItem />
        </FormRow>
      </FormSection>
    </FormContainer>
  );
}

NotificationInformation.propTypes = {
  className: PropTypes.string,
  meta: PropTypes.shape({
    preferredContactTypeList: PropTypes.array.isRequired
  }).isRequired,
  useInput: PropTypes.func.isRequired
};

export default NotificationInformation;
