import { connect } from 'react-redux';
import * as placementsSelectors from 'redux-modules/placements/selectors';
import * as metaSelectors from 'redux-modules/meta/selectors';
import * as authSelectors from 'redux-modules/auth/selectors';
import * as uiStateSelectors from 'redux-modules/uiState/selectors';
import * as placementsActions from 'redux-modules/placements/actions';
import PlacementsPage from './PlacementsPage';

function mapStateToProps(state, ownProps) {
  return {
    currentDate: placementsSelectors.selectCurrentDate(state, ownProps.placementId),
    placementDetails: placementsSelectors.selectPlacementDetails(state, ownProps.placementId),
    placements: placementsSelectors.selectActivePlacements(state),
    allPlacements: placementsSelectors.selectPlacements(state),
    meta: metaSelectors.selectMeta(state),
    syncErrors: placementsSelectors.selectSyncError(state),
    syncing: placementsSelectors.selectSyncState(state),
    syncingMonth: placementsSelectors.selectSyncMonthState(state),
    syncingMonthlyReport: placementsSelectors.selectSyncMonthReportState(state),
    isMonthlyReportSaved: placementsSelectors.selectIsSavedMonthlyReport(state),
    videoURLs: authSelectors.selectVideoURLs(state),
    language: uiStateSelectors.selectLanguage(state)
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onInit: id => {
      dispatch(placementsActions.loadPlacementOverview(id));
      dispatch(placementsActions.loadPlacements());
    },
    loadPlacementOverviewMonth: (id, year, month) => {
      dispatch(placementsActions.loadPlacementOverviewMonth(id, year, month));
    },
    onSaveMonthlyReport: (id, montlyReport) => {
      dispatch(placementsActions.saveMonthlyReport(id, montlyReport));
    },
    onSendMonthlyReport: (id, montlyReport, month, year) => {
      dispatch(placementsActions.sendMonthlyReport(id, montlyReport, month, year));
    },
    resetIsReportSaved: () => {
      dispatch(placementsActions.resetIsReportSaved());
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PlacementsPage);
