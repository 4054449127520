import styled from 'styled-components';

export const CloseButton = styled.button`
  align-items: center;
  background: none;
  border: none;
  color: ${props => props.theme.color.grey[500]};
  display: flex;
  margin-right: ${props => props.theme.spacing(-0.5)};
  padding: 0;

  :hover {
    cursor: pointer;
  }
`;

export const Container = styled.div`
  align-items: center;
  border-bottom: 1px solid ${props => props.theme.color.grey[500]};
  box-sizing: border-box;
  display: flex;
  height: 65px;
  justify-content: space-between;
  margin: 0 ${props => props.theme.spacing(3)};
  padding: ${props => props.theme.spacing(3)} 0;
`;

export const Title = styled.span`
  color: ${props => props.theme.color.primary.main};
  font-size: ${props => props.theme.fontSize.large};
  text-transform: uppercase;
`;
export const SubTitle = styled.span`
  color: ${props => props.theme.color.primary.main};
  font-size: ${props => props.theme.fontSize.small};
  text-transform: none;
`;
