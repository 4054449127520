import styled from 'styled-components';

export const SelectorWrapper = styled.div`
  position: relative;
`;

export const LanguageSwitch = styled.div`
  border-radius: ${props =>
    `${props.theme.borderRadius.base} ${props.theme.borderRadius.base} 0 0`};
  left: ${props => props.theme.spacing(1)};
  overflow: hidden;
  position: absolute;
  top: 0;
  transform: translateY(-100%);
`;

export const Button = styled.button`
  background-color: inherit;
  border: none;
  font-weight: ${props => props.theme.fontWeight.bold};
  padding: ${props => `${props.theme.spacing(1.75)} ${props.theme.spacing(3.75)}`};
  text-transform: uppercase;

  ${props =>
    props.active
      ? `
    color: ${props.theme.color.primary.main};
    background: ${props.theme.color.background.surface};
  `
      : `
    background: ${props.theme.color.background.default};
  `}
  :focus {
    outline: none;
  }
`;
