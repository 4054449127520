import React, { useEffect, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import Title from 'components/Title';
import { MultiLanguageRichTextField, SelectField } from 'components/inputs';
import { FormContainer, FormItem, FormRow } from 'components/styledComponents';
import { useLanguagesPaths, useTranslation } from 'hooks';

function Responsibilities({
  className,
  getValue,
  language,
  meta: {
    companySituations,
    anualRevenues,
    budgets,
    coreCompetencies,
    coreCompetenciesByFunction,
    directReports,
    employees
  },
  useInput
}) {
  const { tr } = useTranslation();
  const functionId = getValue('function');
  const functionIdRef = useRef(functionId);
  const filteredCompetencies = useMemo(
    () =>
      coreCompetencies.filter(
        competency =>
          coreCompetenciesByFunction[functionId] &&
          coreCompetenciesByFunction[functionId].includes(competency.key)
      ),
    [coreCompetencies, coreCompetenciesByFunction, functionId]
  );
  const { onChange } = useInput('coreCompetencies');
  useEffect(() => {
    if (functionIdRef.current !== functionId) {
      functionIdRef.current = functionId;
      onChange([]);
    }
  }, [functionId, onChange, useInput]);

  return (
    <FormContainer className={className}>
      <FormRow>
        <FormItem component={Title} label={tr('title.responsibilitiesAndTasks')} size="large" />
      </FormRow>
      <FormRow>
          <FormItem
              component={SelectField}
              label={tr('label.revenueResponsibility')}
              options={anualRevenues}
              {...useInput('revenueResponsability')}
          />
        <FormItem
          component={SelectField}
          label={tr('label.budgetResponsibility')}
          options={budgets}
          {...useInput('costBudgetResponsability')}
        />
      </FormRow>
      <FormRow>
        <FormItem
          component={SelectField}
          label={tr('label.personnelResponsibility')}
          options={employees}
          {...useInput('personnelResponsability')}
        />
        <FormItem
          component={SelectField}
          label={tr('label.numberOfReports')}
          options={directReports}
          {...useInput('noOfDirectReports')}
        />
      </FormRow>
        <FormRow>
            <FormItem
                component={SelectField}
                label={tr('label.companySituation')}
                options={companySituations}
                {...useInput('companySituation')}
            />
            <FormItem />
        </FormRow>
      <FormRow>
        <FormItem component="p">{tr('message.threeCoreCompetencies')}</FormItem>
      </FormRow>
      <FormRow>
        <FormItem
          component={SelectField}
          isMulti
          label={tr('label.coreCompetencies')}
          maxSelections={3}
          options={filteredCompetencies}
          {...useInput('coreCompetencies')}
        />
      </FormRow>
      <FormRow>
        <FormItem component="p">{tr('message.describeResponsibilities')}</FormItem>
      </FormRow>
      <FormRow>
        <FormItem
          component={MultiLanguageRichTextField}
          data-rttextarea-id="description-rich-editor"
          language={language}
          {...useInput('description', { alternateFields: useLanguagesPaths('description') })}
        />
      </FormRow>
    </FormContainer>
  );
}

Responsibilities.propTypes = {
  className: PropTypes.string,
  getValue: PropTypes.func.isRequired,
  language: PropTypes.string.isRequired,
  meta: PropTypes.shape({
    anualRevenues: PropTypes.array.isRequired,
    budgets: PropTypes.array.isRequired,
    coreCompetencies: PropTypes.array.isRequired,
    coreCompetenciesByFunction: PropTypes.object.isRequired,
    directReports: PropTypes.array.isRequired,
    employees: PropTypes.array.isRequired
  }).isRequired,
  useInput: PropTypes.func.isRequired
};

export default Responsibilities;
