import styled from 'styled-components';
import Slider from 'react-slick';

export const SlickSlider = styled(Slider)`
  margin-bottom: ${props => props.theme.spacing(3)};
  width: 300px;
  max-width: 100%;
  display: flex;
  align-items: center;
  .slick-track {
    display: flex;
    align-items: center;
  }
`;
export const SliderArrow = styled.div`
  align-items: center;
  display: flex !important;
  font-weight: ${props => props.theme.fontWeight.bold};
  justify-content: center;
  left: ${props => (props.left ? props.theme.spacing(-1) : 'auto')};
  position: absolute;
  right: ${props => (props.right ? props.theme.spacing(-0.25) : 'auto')};
  top: 50%;
  transform: translate(0, -50%);
  z-index: 1000;
  cursor: pointer;
  svg {
    height: 24px;
    width: 24px;
  }
`;
export const ItemContent = styled.div`
  text-align: center;
  color: ${props => props.theme.color.text.light};
  font-size: ${props => props.theme.fontSize.small};
  padding: ${props => props.theme.spacing(0.3, 3, 0, 2)};
  box-sizing: border-box;
  display: flex !important;
  justify-content: center;
  align-items: center;
`;
