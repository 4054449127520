import { useMemo } from 'react';
import { format } from 'date-fns';
import { useLocale } from 'hooks';

function useDateFormat(value, { dateFormat = 'd MMM yyyy', language } = {}) {
  const locale = useLocale(language);

  return useMemo(() => {
    if (value) {
      return format(new Date(value), dateFormat, { locale: locale });
    }
    return value;
  }, [dateFormat, locale, value]);
}

export default useDateFormat;
