import { useCallback, useEffect, useLayoutEffect, useRef, useState, useMemo } from 'react';
import _get from 'lodash/get';
import _toPath from 'lodash/toPath';
import _map from 'lodash/map';
import _cloneDeep from 'lodash/cloneDeep';
import { useTranslation } from 'hooks';
import { validate } from 'utils/validators';
import {
  cloneDeep,
  each,
  forOwn,
  isArray,
  isEmpty,
  isNaN,
  isNull,
  isObject,
  isString,
  isUndefined,
  pull,
  split,
  remove
} from 'lodash';
import $ from 'jquery';
const immutable = require('object-path-immutable');

function useEventCallback(fn) {
  const ref = useRef();
  // copy a ref to the callback scoped to the current state/props on each render
  useLayoutEffect(() => {
    ref.current = fn;
  });
  return useCallback((...args) => ref.current && ref.current.apply(void 0, args), []);
}

export const getInputValue = ({ checked, options, type, value }, file = null) => {
  switch (type) {
    case 'checkbox':
      return checked;
    case 'radio':
      return checked ? value : undefined;
    case 'select-multiple':
      return [...options]
        .map(option => ({
          value: option.value,
          selected: option.selected
        }))
        .filter(option => option.selected)
        .map(option => option.value);
    case 'file': {
      if (file && file.size && file.size > 0) {
        return { name: file.name, file: file };
      }
      return { name: null, file: null };
    }

    default:
      return value;
  }
};

const emptyObject = {};

function useForm({ initialValues = emptyObject, validationSchema, onSubmit } = {}) {
  const [values, setValues] = useState(initialValues);
  const [valuesFlag, setValuesFlag] = useState(false);
  const [errors, setErrors] = useState({});
  const { t } = useTranslation();
  const getValue = useCallback(path => _get(values, path), [values]);
  const getError = useCallback(
    (path, alternateFields) =>
      errors[path] ||
      (alternateFields && errors[alternateFields.find(fieldPath => errors[fieldPath])]),
    [errors]
  );

  const handleAdd = useEventCallback(
    (path, value) => setValues(immutable.push(values, _toPath(path), value)),
    [values, setValues]
  );

  const handleSet = useEventCallback(
    (path, value) => setValues(immutable.set(values, _toPath(path), value)),
    [values, setValues]
  );

  // RTEXT-859
  // synchronously update state(values)
  const handleAddSync = useEventCallback(
    (property, value) => {
      setValues(prevState => {
        return {
          ...prevState,
          [property]: value
        };
      });
    },
    [values, setValues]
  );

  const handleRemove = useEventCallback(
    path => {
      // @TODO remove errors for deleted object
      setValues(immutable.del(values, _toPath(path)));
    },
    [values, setValues]
  );

  const handleReset = useEventCallback(() => {
    setValues(initialValues);
    setErrors({});
  }, [initialValues]);

  // RTEXT-856
  const handleResetError = useEventCallback(
    paths => {
      let errorObj = _cloneDeep(errors);
      paths.forEach(path => {
        if (errorObj[path]) {
          delete errorObj[path];
        }
      });
      setErrors(errorObj);
    },
    [errors, setErrors]
  );

  // @TODO replace this with refs and internal state for each input
  // TODO - this is not ideal, but it does its job for now
  // This method is responsible for focusing validation error fields on submit of a form
  const handleValidationErrors = errors => {
    if (errors && errors.length > 0) {
      // just take the first error, we want the first error to be able to focus that field
      // schema needs to be modified not alphabetically but in order of the fields in the form
      let firstError = errors[0];
      var path = firstError.fieldPath;
      var field = document.getElementById(path);
      // get field, or en field
      if (!field) field = document.getElementById(path.replace('.de', ''));
      if (field) {
        // check if rich-text, rich-text needs clicking
        if (field && field.childNodes.length === 2) {
          var richTextBody = field.childNodes[1];
          if (richTextBody.getAttribute('data-js-id') === 'rich-editor-body') {
            field = richTextBody;
            field.click();
            return;
          }
        }
        // check if not input, and try to find the input inside
        if (field.tagName.toLowerCase() !== 'input' && field.tagName.toLowerCase() !== 'select') {
          var childInputs = field.getElementsByTagName('input');
          if (childInputs.length > 0) {
            field = childInputs[0];
          } else {
            childInputs = field.getElementsByTagName('select');
            if (childInputs.length > 0) {
              field = childInputs[0];
            } else {
              field = null;
            }
          }
        }
        // focus the input
        field && field.focus();
      }
    }
  };
  
  const handleSubmit = useEventCallback(event => {
    event.preventDefault();
    const formData = values;
    // @TODO add validations inside a try catch
    if (validationSchema) {
      const errorsList = validate(validationSchema, formData);
      setErrors(
        errorsList.reduce((errorsObject, error) => {
          errorsObject[error.fieldPath] = t(
            [
              `error.validation.${error.keyword}.${error.field}`,
              `error.validation.${error.keyword}.default`,
              `error.validation.invalid`
            ],
            error.params
          );
          return errorsObject;
        }, {})
      );
      handleValidationErrors(errorsList);
      if (errorsList.length) {
        return;
      }
    }
    // RTEXT-859
    for (const property in formData) {
      if (formData[property] && formData[property].hasOwnProperty('multiLangFieldActiveLang')) {
        delete formData[property]['multiLangFieldActiveLang'];
      }
    }
    onSubmit(formData);
  });

  const registrationValidate = (registrationValidationSchema, onStepOneValidation) => {
    const registrationData = values;
    const errorsList = validate(registrationValidationSchema, registrationData);
    setErrors(
      errorsList.reduce((errorsObject, error) => {
        if (error.fieldPath.includes('documents') && error.field === 'file') {
          const indexMatch = error.dataPath.match(/\[(\d+)\]/);
          if (indexMatch) {
            const index = parseInt(indexMatch[1], 10);
            if (!errorsObject['documents']) {
              errorsObject['documents'] = {
                files: []
              };
            }
            errorsObject['documents']['files'].push(index);
          }
        } else {
          errorsObject[error.fieldPath] = t(
            [
              `error.validation.${error.keyword}.${error.field}`,
              `error.validation.${error.keyword}.default`,
              `error.validation.invalid`
            ],
            error.params
          );
        }
        return errorsObject;
      }, {})
    );
    if (errorsList.length) {
      return false;
    }

    onStepOneValidation(registrationData);
  };

  const generateTreeData = (dropDownOptions, checkedValues) => {
    var items = {};
    each(dropDownOptions, function (v) {
      items[v.key] = v.value;
    });

    var sortedKeyArray = [];
    each(items, function (v, k) {
      sortedKeyArray.push(k);
    });
    sortedKeyArray.sort();
    var returnArray = [];
    each(sortedKeyArray, function (key) {
      let currentKeyArray = split(key, '.');
      //If Arraylength === 1 then this is a root element!
      if (currentKeyArray.length === 1) {
        returnArray[currentKeyArray[0]] = {
          label: items[key],
          value: key,
          checked: isArray(checkedValues)
            ? checkedValues && checkedValues.indexOf(key) !== -1
            : checkedValues == key,
          expanded: true,
          children: []
        };
      }
      if (currentKeyArray.length > 1) {
        let ebene = returnArray[currentKeyArray[0]].children;
        for (var z = 1; z < currentKeyArray.length - 1; z++) {
          ebene = ebene[currentKeyArray[z]].children;
        }
        ebene[currentKeyArray[z]] = {
          label: items[key],
          value: key,
          checked: isArray(checkedValues)
            ? checkedValues && checkedValues.indexOf(key) !== -1
            : checkedValues == key,
          expanded: true,
          children: []
        };
      }
    });

    return pruneEmpty(returnArray);
  };
  const pruneEmpty = obj => {
    return (function prune(current) {
      forOwn(current, function (value, key) {
        if (
          isUndefined(value) ||
          isNull(value) ||
          isNaN(value) ||
          (isString(value) && isEmpty(value)) ||
          (isObject(value) && isEmpty(prune(value)))
        ) {
          delete current[key];
        }
      });
      // remove any leftover undefined values from the delete
      // operation on an array
      if (isArray(current)) pull(current, undefined);

      return current;
    })(cloneDeep(obj)); // Do not modify the original object, create a clone instead
  };
  useEffect(() => handleReset(), [handleReset, initialValues]);
  const useInput = (path, { alternateFields } = {}) => {
    const handleChange = useEventCallback((event, tinymcePath = '') => {
      const value =
        event && event.target && event.target.type === 'file'
          ? getInputValue(event.target, event.target.files[0])
          : event && event.target
          ? getInputValue(event.target)
          : event;

      // remove the error for touched field
      const { [path]: _, ...rest } = errors;
      if (alternateFields) {
        alternateFields.forEach(fieldPath => {
          delete rest[fieldPath];
        });
      }
      setErrors(rest);
      if (path === 'attachmentFile') {
        const { name, file } = value;
        setValues(immutable.set(values, _toPath(path), name));
        setValues(immutable.set(values, _toPath('file'), file));
      } else {
        if (tinymcePath && tinymcePath !== 'langswitch') {
          setValuesFlag(true);
        } else {
          setValuesFlag(false);
        }
        if (tinymcePath !== 'langswitch') setValues(immutable.set(values, _toPath(path), value));
      }
    });
    const value = path ? getValue(path) : values;
    const valuePopulate = typeof value === 'boolean' ? { checked: value } : { value: value || '' };

    const formValue = useMemo(() => (path === 'attachmentFile' ? {} : valuePopulate), [valuesFlag]);
    const finalValue = valuesFlag ? formValue : valuePopulate;
    return {
      // @TODO find a better approach for alternate fields
      error: getError(path, alternateFields),
      ...finalValue,
      onChange: handleChange,
      id: path,
      onAdd: handleAdd,
      onAddSync: handleAddSync
    };
  };

  const useTree = (path, dropDownOptions, { alternateFields } = {}) => {
    const onFocus = () => {
      let error = getError(path, alternateFields);
      if (!error) {
        $('#dTreeLabel').css('color', '#206AF5');
        var style = $.parseHTML(
          '<style class="tempStyleToRemove">a#industry_trigger:before{color:#206AF5 !important;}</style>'
        );
        $('head').append(style);
      }
    };

    const onBlur = () => {
      let error = getError(path, alternateFields);
      if (!error) {
        $('#dTreeLabel').css('color', '#666666');
        $('head').find('.tempStyleToRemove').remove();
      }
    };

    const handleChange = (currentNode, selectedNodes) => {
      let value = _map(selectedNodes, 'value');
      if (path !== 'itSkills') {
        value = value[0];
        $('#' + path + '_trigger').removeAttr('style');
        $('#dTreeLabel').removeAttr('style');
        $('#dTreeLabel').css('color', '#666666');
      }
      // remove the error for touched field
      $('#' + path + '-helper-text').remove();
      const { [path]: _, ...rest } = errors;
      if (alternateFields) {
        alternateFields.forEach(fieldPath => {
          delete rest[fieldPath];
        });
      }
      $('head').find('.tempStyleToRemove').remove();
      setErrors(rest);
      setValues(immutable.set(values, _toPath(path), value));
    };

    let error = getError(path, alternateFields);
    if (error) {
      $('#' + path + '_trigger').attr('style', 'border-color: red !important; box-shadow: none');
      $('#dTreeLabel').attr('style', 'color: red !important;');
      if (!($('#' + path + '-helper-text') && $('#' + path + '-helper-text').length)) {
        $('#' + path + '_trigger').after(
          '<p class="MuiFormHelperText-root MuiFormHelperText-contained Mui-error Mui-required" id=' +
            path +
            '-helper-text>' +
            error +
            '</p>'
        );
      }
    }
    const value = path ? getValue(path) : values;
    //RTEXT-592, Fixed Issue regarding re-rendering of Tree Dropdown
    const treeDataList = useMemo(() => generateTreeData(dropDownOptions, value), [
      dropDownOptions,
      value
    ]);

    return {
      error: getError(path, alternateFields),
      data: treeDataList,
      onChange: handleChange,
      onFocus: onFocus,
      onBlur: onBlur,
      clearSearchOnChange: true,
      id: path,
      className: 'login-tree-dd'
    };
  };

  return {
    getValue,
    handleAdd,
    handleRemove,
    handleReset,
    handleSubmit,
    useInput,
    useTree,
    registrationValidate,
    values,
    handleResetError,
    errors,
    handleSet
  };
}

export default useForm;
