import localStorage from 'local-storage-fallback';
import { call, put, select, takeEvery, takeLeading } from 'redux-saga/effects';
import moment from 'moment';
import apiClient from 'communication/apiClient';
import * as actions from './actions';
import * as types from './types';
import * as selectors from './selectors';
import * as uiStateSelectors from '../uiState/selectors';
import * as uiStateActions from '../uiState/actions';

export function logout() {
  localStorage.removeItem('authToken');
  localStorage.removeItem('refreshToken');
  localStorage.removeItem('showAgreement');
  localStorage.removeItem('pLastActive');
  localStorage.removeItem('videoURLs');
}

export function* loginUser({
  accessToken,
  refreshToken,
  showAgreement,
  communicationLanguage,
  videoURLs,
  canSkipAgreement
}) {
  localStorage.setItem('authToken', accessToken);
  localStorage.setItem('refreshToken', refreshToken);
  localStorage.setItem('showAgreement', showAgreement);
  localStorage.setItem('videoURLs', JSON.stringify(videoURLs));
  localStorage.setItem('canSkipAgreement', canSkipAgreement);
  yield put(actions.loginUser(accessToken, showAgreement, videoURLs, canSkipAgreement));
  yield put(uiStateActions.changeLanguage(communicationLanguage));
}

export function* login(actionData) {
  try {
    const resp = yield call(
      apiClient.post,
      '/api/v1/login',
      actionData.payload.loginData,
      actionData.payload.failCallback
    );
    if (resp && resp.data) {
      yield* loginUser(resp.data);
    } else {
      yield put(actions.failLogin(resp.error));
    }
  } catch (error) {
    yield put(actions.failLogin({ status: 503 }));
  }
}

export function* register(actionData) {
  try {
    const { documents, ...registerData } = actionData.payload.registerData;
    registerData.isRegistratoionProcess = true;
    registerData.contractReceivedDate = moment(new Date()).format('YYYY-MM-DD');
    registerData.currentMcAccepted = true;
    yield put(actions.registerPortalUser());
    const resp = yield call(
      apiClient.post,
      '/api/v1/register',
      registerData,
      actionData.payload.failCallback
    );

    let isSuccessful = resp && resp.data.status === 200;
    if (isSuccessful && documents) {
      const filteredDocuments = documents.filter(({ file }) => file !== null);
      const formData = new FormData();
      filteredDocuments.forEach(({ subcategory, file }) => {
        formData.append('files[]', file);
        formData.append('subcategories[]', subcategory);
      });

      const fileResp = yield call(
        apiClient.custom,
        {
          url: `/api/v1/upload-resumes/${resp.data.manager_id}`,
          method: 'POST',
          body: formData
        },
        actionData.payload.failCallback
      );

      if (isSuccessful && fileResp.data === true) {
        yield put(actions.registerPortalUserSuccess(resp.data.automatic_release));
      } else {
        yield put(actions.registerPortalUserFailed());
      }
    }
  } catch (error) {
    yield put(actions.failRegister('error'));
  }
}
export function* checkEmail(actionData) {
  try {
    const { primaryEmail } = actionData.payload.registerData;

    const resp = yield call(
      apiClient.post,
      '/api/v1/check-email',
      { primaryEmail: primaryEmail },
      actionData.payload.failCallback
    );

    let isSuccessful = resp && resp.data.status === 200;
    if (isSuccessful) {
      yield put(actions.checkEmailSuccess());
    } else {
      yield put(actions.emailDuplicate());
    }
  } catch (error) {
    yield put(actions.checkEmailFailed());
  }
}

export function* resetPassword(actionData) {
  try {
    const resp = yield call(
      apiClient.post,
      '/api/v1/reset-password',
      actionData.payload.resetPwdData,
      actionData.payload.failCallback
    );
    if (resp && resp.data) {
      yield put(actions.successResetPassword(resp));
    } else {
      yield put(actions.failResetPassword(resp));
    }
  } catch (error) {
    yield put(actions.failResetPassword('error'));
  }
}

export function* updatePassword(actionData) {
  try {
    const resp = yield call(
      apiClient.post,
      '/api/v1/update-password',
      actionData.payload.updatePwdData,
      actionData.payload.failCallback
    );
    if (resp && resp.data) {
      yield put(actions.successUpdatePassword(resp));
    } else {
      yield put(actions.failUpdatePassword(resp));
    }
  } catch (error) {
    yield put(actions.failUpdatePassword('error'));
  }
}

function* validateResetToken(actionData) {
  try {
    let url = '/api/v1/validate-reset-password-token/' + actionData.payload.token;
    const resp = yield call(apiClient.post, url);
    if (resp && resp.data) {
      yield put(actions.successResetPasswordValidation(resp));
    } else {
      yield put(actions.failResetTokenValidation(resp));
    }
  } catch (error) {
    yield put(actions.failResetTokenValidation('error'));
  }
}

function* agreeTerms(actionData) {
  const contractData = {
    contractReceivedDate: actionData.payload.termsData.contractDate
  };
  const resp = yield call(
    apiClient.put,
    '/api/v1/manager/contract-info',
    contractData,
    actionData.payload.failCallback
  );

  if (resp.data && resp.data.id) {
    const agreementObj = {
      id: resp.data.id,
      isSuccess: true,
      error: resp.error
    };
    localStorage.setItem('showAgreement', false);
    const accessToken = localStorage.getItem('authToken');
    yield put(actions.loginUser(accessToken, false));
    yield put(actions.agreementSuccess(agreementObj));
  } else {
    const agreementObj = {
      id: null,
      isSuccess: false,
      error: resp.error
    };
    yield put(actions.agreementSuccess(agreementObj));
  }
}

export function* loadNDA1() {
  const nda = yield select(selectors.selectNDA1);
  if (nda) {
    return;
  }
  try {
    const language = yield select(uiStateSelectors.selectLanguage);
    const resp = yield call(
      apiClient.get,
      `/api/v1/manager/agreement-nda/${language}?timestamp=` + new Date().getTime()
    );
    if (resp && resp.data) {
      yield put(actions.setLoadedNDA1(resp.data, language));
    }
  } catch (error) {
    console.error('error', error);
  }
}
export function* loadNDA2() {
  const nda = yield select(selectors.selectNDA2);
  if (nda) {
    return;
  }
  try {
    const language = yield select(uiStateSelectors.selectLanguage);
    const resp = yield call(
      apiClient.get,
      `/api/v1/manager/protection-nda/${language}?timestamp=` + new Date().getTime()
    );
    if (resp && resp.data) {
      yield put(actions.setLoadedNDA2(resp.data, language));
    }
  } catch (error) {
    console.error('error', error);
  }
}

export function* requestNewLink({ payload }) {
  try {
    const resp = yield call(apiClient.post, '/api/v1/resend-welcome-email-token', payload);
    if (resp) {
      yield put(actions.requestNewLinkSuccess());
    }
  } catch (error) {}
}

export default [
  takeLeading(types.LOAD_NDA1, loadNDA1),
  takeLeading(types.LOAD_NDA2, loadNDA2),
  takeEvery(types.LOGIN_INITIATED, login),
  takeEvery(types.LOGOUT, logout),
  takeEvery(types.REGISTER, register),
  takeEvery(types.CHECK_EMAIL, checkEmail),
  takeEvery(types.RESET_PWD_INITIATED, resetPassword),
  takeEvery(types.UPDATE_PWD_INITIATED, updatePassword),
  takeEvery(types.VALIDATE_PWD_RESET_TOKEN, validateResetToken),
  takeEvery(types.AGREE_TERMS, agreeTerms),
  takeEvery(types.REQUEST_NEW_LINK, requestNewLink)
];
