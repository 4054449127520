import styled from 'styled-components';
import { Button as BaseButton } from 'components/buttons';
import backgroundImage from './images/header.jpg';

export const Details = styled.div`
  color: ${props => props.theme.color.primary.contrast};
  font-size: ${props => props.theme.fontSize.large};
  padding: ${props => props.theme.spacing(5, 0)};
`;

export const Headline = styled.h3`
  color: ${props => props.theme.color.primary.contrast};
  font-size: ${props => props.theme.fontSize.title};
  font-weight: ${props => props.theme.fontWeight.light};
  margin: 0;
  margin-top: 40px;
`;

export const LinkButton = styled(BaseButton)`
  text-decoration: none;
`;

export const Phrase = styled.p`
  margin: 0;
`;

export const Slide = styled.div`
  align-items: flex-end;
  background-size: cover;
  display: flex;
  min-height: 320px;
  padding: ${props => props.theme.spacing(5, 0)};
  text-align: left;
  ${props => `
    background: linear-gradient( to right, ${
      props.theme.color.primary.main
    }, rgba(0, 0, 0, 0.5) ), top right / cover no-repeat url(${props.dataImg});
    color: ${props => props.theme.color.primary.contrast};

    @media (min-width: 1024px) {
      background: top right / cover no-repeat url(${props.dataImg});
    }
  `}
`;

export const Subject = styled.h4`
  color: ${props => props.theme.color.primary.contrast};
  font-size: ${props => props.theme.fontSize.title};
  font-weight: ${props => props.theme.fontWeight.bold};
  margin: 0;
`;

export const ButtonsContainer = styled.div`
  margin: ${props => props.theme.spacing(-1)};

  ${LinkButton} {
    margin: ${props => props.theme.spacing(1)};
  }
`;

export const NewsSection = styled.div`
  background-size: cover;
  display: flex;
  min-height: 320px;
  text-align: left;
  background: center url(${backgroundImage});
`;
