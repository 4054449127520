import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Collapse from 'components/Collapse';
import Section from '../Section';
import { Details } from './style';

function Education({ className, editPath, education, indexedMeta, language }) {
  const path = useMemo(() => editPath && `${editPath}/education`, [editPath]);

  //RTEXT-599
  //Apply series of sorting after each cycle
  education &&
    education.sort(function (a, b) {
      var keyA = new Date(a.dateTo),
        keyB = new Date(b.dateTo);
      if (a.dateTo == '') return -1;
      if (b.dateTo == '') return 1;
      if (keyA > keyB) return -1;
      if (keyA < keyB) return 1;
      return 0;
    });

  //Sort based on start date if end dates are equal
  education &&
    education.sort(function (a, b) {
      var keyC = new Date(a.dateFrom),
        keyD = new Date(b.dateFrom);

      if (a.dateTo == b.dateTo && keyC > keyD) {
        return -1;
      }
      if (a.dateTo == b.dateTo && keyC < keyD) {
        return 1;
      }
      return 0;
    });

  //now again sort the sorted array for start date for open ended end date
  education &&
    education.sort(function (a, b) {
      var keyC = new Date(a.dateFrom),
        keyD = new Date(b.dateFrom);

      //keeping educations which have both start and end dates empty on top
      if (a.dateFrom == '' && a.dateTo == '') {
        return -1;
      }

      if (b.dateFrom == '' && b.dateTo == '') {
        return 1;
      }

      if (a.dateTo == '' && keyC > keyD) {
        return -1;
      }
      if (b.dateTo == '' && keyC < keyD) {
        return 1;
      }
      return 0;
    });

  return (
    <Section addPath={path} className={className} title="title.education">
      {/* Boolean conversion needed to avoid displaying "0" when length is 0 */}
      {!!education.length && (
        <Collapse>
          {education.map(item => (
            <Details
              editPath={path}
              education={item}
              indexedMeta={indexedMeta}
              key={item.id}
              language={language}
            />
          ))}
        </Collapse>
      )}
    </Section>
  );
}

Education.propTypes = {
  className: PropTypes.string,
  editPath: PropTypes.string,
  education: PropTypes.array.isRequired,
  indexedMeta: PropTypes.object.isRequired,
  language: PropTypes.string.isRequired
};

export default Education;
