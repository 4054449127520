import { connect } from 'react-redux';
import * as resumeActions from 'redux-modules/resume/actions';
import * as resumeSelectors from 'redux-modules/resume/selectors';
import Editor from './Editor';

function mapStateToProps(state) {
  return {
    syncErrors: resumeSelectors.selectSyncError(state),
    syncing: resumeSelectors.selectSyncState(state),
    workExperience: resumeSelectors.selectWorkExperience(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onDelete: (data, failCallback) =>
      dispatch(resumeActions.deleteWorkExperience(data, failCallback)),
    onSave: (data, failCallback) => dispatch(resumeActions.saveWorkExperience(data, failCallback)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Editor);
