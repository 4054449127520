import * as types from './types';

export function failLogin(error) {
  return {
    type: types.LOGIN_FAILED,
    payload: {
      error
    }
  };
}

export function failRegister(error) {
  return {
    type: types.REGISTER_FAILED,
    payload: {
      error
    }
  };
}

export function login(loginData, failCallback) {
  return {
    payload: {
      failCallback,
      loginData
    },
    type: types.LOGIN_INITIATED
  };
}

export function loginUser(token, showAgreement, videoURLs, canSkipAgreement) {
  return {
    payload: {
      token,
      showAgreement,
      videoURLs,
      canSkipAgreement
    },
    type: types.LOGIN_SUCCESSFUL
  };
}

export function logout() {
  return {
    type: types.LOGOUT
  };
}

export function agreeTerms(termsData, failCallback) {
  return {
    payload: {
      termsData,
      failCallback
    },
    type: types.AGREE_TERMS
  };
}

export function agreementSuccess(agreementData, failCallback) {
  return {
    payload: {
      agreementData,
      failCallback
    },
    type: types.AGREEMENT_SUCCESS
  };
}

export function skipAgreement() {
  return {
    type: types.SKIP_AGREEMENT
  }
}

export function setRegisterData(registerData, failCallback) {
  return {
    payload: {
      failCallback,
      registerData
    },
    type: types.SET_REGISTER_DATA
  };
}

export function getRegisterData(callback) {
  return {
    payload: {
      callback
    },
    type: types.GET_REGISTER_DATA
  };
}

export function register(registerData, successCallback, failCallback) {
  return {
    payload: {
      successCallback,
      failCallback,
      registerData
    },
    type: types.REGISTER
  };
}

export function checkEmail(registerData, successCallback, failCallback) {
  return {
    payload: {
      successCallback,
      failCallback,
      registerData,
      isNewUserEmail: null
    },
    type: types.CHECK_EMAIL
  };
}
export function registerPortalUser() {
  return {
    payload: null,
    type: types.REGISTER_PORTAL_USER
  };
}
export function registerPortalUserSuccess(automaticRelease) {
  return {
    payload: {
      registerStatus: 'success',
      // RTEXT-726
      isAutomaticRelease: automaticRelease
    },
    type: types.REGISTER_PORTAL_USER_SUCCESS
  };
}
export function registerPortalUserFailed() {
  return {
    payload: {
      registerStatus: 'failed'
    },
    type: types.REGISTER_PORTAL_USER_FAILED
  };
}
export function checkEmailSuccess() {
  return {
    payload: {
      isNewUserEmail: 'new'
    },
    type: types.CHECK_EMAIL_SUCCESS
  };
}

export function emailDuplicate() {
  return {
    payload: {
      isNewUserEmail: 'duplicate'
    },
    type: types.CHECK_EMAIL_DUPLICATE
  };
}
export function checkEmailFailed() {
  return {
    payload: {
      isNewUserEmail: 'failed'
    },
    type: types.CHECK_EMAIL_FAILED
  };
}

export function resetResetPasswordStatus() {
  return {
    payload: null,
    type: types.RESET_PWD_RESET_STATUS
  };
}

export function resetPassword(resetPwdData, failCallback) {
  return {
    payload: {
      failCallback,
      resetPwdData
    },
    type: types.RESET_PWD_INITIATED
  };
}

export function failResetPassword(error) {
  return {
    type: types.RESET_PWD_FAILED,
    payload: {
      error
    }
  };
}

export function successResetPassword() {
  return {
    payload: null,
    type: types.RESET_PWD_SUCCESSFUL
  };
}

export function updatePassword(updatePwdData, failCallback) {
  return {
    payload: {
      failCallback,
      updatePwdData
    },
    type: types.UPDATE_PWD_INITIATED
  };
}

export function failUpdatePassword(error) {
  return {
    type: types.UPDATE_PWD_FAILED,
    payload: {
      error
    }
  };
}

export function successUpdatePassword() {
  return {
    payload: null,
    type: types.UPDATE_PWD_SUCCESSFUL
  };
}

export function validateResetToken(token) {
  return {
    payload: {
      token
    },
    type: types.VALIDATE_PWD_RESET_TOKEN
  };
}

export function failResetTokenValidation(error) {
  return {
    type: types.VALIDATE_PWD_RESET_TOKEN_FAILED,
    payload: {
      error
    }
  };
}

export function successResetPasswordValidation() {
  return {
    payload: null,
    type: types.VALIDATE_PWD_RESET_TOKEN_SUCCESSFUL
  };
}

export function loadNDA1() {
  return {
    type: types.LOAD_NDA1
  };
}
export function setLoadedNDA1(nda, language) {
  return {
    payload: {
      language,
      nda
    },
    type: types.NDA_LOADED1
  };
}
export function loadNDA2() {
  return {
    type: types.LOAD_NDA2
  };
}
export function setLoadedNDA2(nda, language) {
  return {
    payload: {
      language,
      nda
    },
    type: types.NDA_LOADED2
  };
}

export function requestNewLink(token) {
  return {
    payload: {
      token
    },
    type: types.REQUEST_NEW_LINK
  };
}

export function requestNewLinkSuccess() {
  return {
    type: types.REQUEST_NEW_LINK_SUCCESS
  };
}
