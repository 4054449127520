import { connect } from 'react-redux';
import * as authSelectors from 'redux-modules/auth/selectors';
import PrivateRoute from './PrivateRoute';

function mapStateToProps(state) {
  return {
    isAuthenticated: !!authSelectors.selectAuthToken(state),
    showAgreement: authSelectors.selectShowAgreement(state)
  };
}

export default connect(mapStateToProps)(PrivateRoute);
