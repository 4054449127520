import React, { useCallback, useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { FormContainer, FormSection } from 'components/styledComponents';
import ProfilePicture from 'components/ProfilePicture';
import { ImageContainer, ImageInput, Button, ButtonContainer } from './style';
import { useTranslation } from 'hooks';

function Photo({ className, useInput }) {
  const [photoURL, setPhotoURL] = useState(null);
  const { value: photo } = useInput('photo');
  const { value: photoFile, onChange: onFileChange } = useInput('photoFile');
  const [delButton, setDelButton] = useState(false);
  // RTEXT-645
  const noteRef = useRef(null);
  const { t } = useTranslation();

  const handleChange = useCallback(
    event => {
      onFileChange(event.target.files[0]);
    },
    [onFileChange]
  );

  useEffect(() => {
    if (photoFile) {
      const reader = new FileReader();
      reader.onload = event => {
        setPhotoURL(event.target.result);
      };
      reader.readAsDataURL(photoFile);
    } else {
      setPhotoURL(photo);
    }
  }, [photo, photoFile]);

  // RTEXT-645
  useEffect(() => {
    if (noteRef.current) noteRef.current.innerHTML = t('message.profilePicNote');
  }, [t]);

  const dataURItoBlob = useCallback(dataURI => {
    // convert base64/URLEncoded data component to raw binary data held in a string
    let byteString;
    if (dataURI.split(',')[0].indexOf('base64') >= 0) byteString = atob(dataURI.split(',')[1]);
    else byteString = unescape(dataURI.split(',')[1]);

    // separate out the mime component
    let mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    // write the bytes of the string to a typed array
    let ia = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ia], { type: mimeString });
  }, []);

  const handleDelete = useCallback(() => {
    setPhotoURL('');
    const user_svg =
      'data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMzJweCIgaGVpZ2h0PSIzMnB4IiB2aWV3Qm94PSIwIDAgMzIgMzIiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8IS0tIEdlbmVyYXRvcjogU2tldGNoIDYzLjEgKDkyNDUyKSAtIGh0dHBzOi8vc2tldGNoLmNvbSAtLT4KICAgIDx0aXRsZT5pY29ucy91c2VyPC90aXRsZT4KICAgIDxkZXNjPkNyZWF0ZWQgd2l0aCBTa2V0Y2guPC9kZXNjPgogICAgPGcgaWQ9IlBhZ2UtMSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPGcgaWQ9IkF0cmV1cy0vLUljb25zIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMzM5LjAwMDAwMCwgLTM1Ny4wMDAwMDApIj4KICAgICAgICAgICAgPGcgaWQ9Imljb25zL3VzZXIiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDMzOS4wMDAwMDAsIDM1Ny4wMDAwMDApIj4KICAgICAgICAgICAgICAgIDxwYXRoIGQ9Ik0wLDAuOTkyNDYzNjc1IEMwLDAuNDQ0MzQxMTIyIDAuNDUyMzYyMDYxLDAgMC45OTI3ODY0MDcsMCBMMzEuMDA3MjEzNiwwIEMzMS41NTU1MTQ0LDAgMzIsMC40NTIzNjIwNjEgMzIsMC45OTI3ODY0MDcgTDMyLDMxLjAwNzIxMzYgQzMyLDMxLjU1NTUxNDQgMzEuNTQ3NjM3OSwzMiAzMS4wMDcyMTM2LDMyIEwwLjk5Mjc4NjQwNywzMiBDMC40NDQ0ODU2MTUsMzIgMCwzMS41NjAyNTY4IDAsMzEuMDAxOTU1MSBMMCwzLjUwOTE3MTQxIEMwLDIuOTU3OTY2NDIgMCwyLjA2MjE4NzM5IDAsMS41MTg2NjI4IEwwLDAuOTkyNDYzNjc1IFoiIGlkPSJSZWN0YW5nbGUtMy1Db3B5LTgiIGZpbGwtb3BhY2l0eT0iMC4wMSIgZmlsbD0iI0ZGRkZGRiIgZmlsbC1ydWxlPSJldmVub2RkIj48L3BhdGg+CiAgICAgICAgICAgICAgICA8cGF0aCBkPSJNMTYsMTcgQzIwLjg4NDg2MzQsMTcgMjQuODYwNzc0MSwyMS4zMjQwOTg4IDI0Ljk5NjQyMjQsMjYuNzE1Mjg1NiBMMjUsMjcgTDcsMjcgQzcsMjEuNDc3MTUyNSAxMS4wMjk0MzczLDE3IDE2LDE3IFogTTE2LDUgQzE4LjIwOTEzOSw1IDIwLDYuNzkwODYxIDIwLDkgQzIwLDExLjIwOTEzOSAxOC4yMDkxMzksMTMgMTYsMTMgQzEzLjc5MDg2MSwxMyAxMiwxMS4yMDkxMzkgMTIsOSBDMTIsNi43OTA4NjEgMTMuNzkwODYxLDUgMTYsNSBaIiBpZD0iQ29tYmluZWQtU2hhcGUiIHN0cm9rZT0iIzIwNkFGNSIgc3Ryb2tlLXdpZHRoPSIxLjUiPjwvcGF0aD4KICAgICAgICAgICAgPC9nPgogICAgICAgIDwvZz4KICAgIDwvZz4KPC9zdmc+';
    const blob = dataURItoBlob(user_svg);
    const file = new File([blob], 'user.svg', blob);
    onFileChange(file);
    setDelButton(false);
  }, [dataURItoBlob, onFileChange]);

  const setDeleteButtonState = useCallback(state => {
    setDelButton(state);
  }, []);

  // RTEXT-645
  const handleEdit = useCallback(() => {
    document.getElementById('profile-pic').click();
  });

  return (
    <FormContainer className={className}>
      <FormSection padding="3 0">
        <ImageContainer>
          <ProfilePicture setDeleteButtonState={setDeleteButtonState} size="large" src={photoURL} />
          <ImageInput accept="image/*" id="profile-pic" type="file" onChange={handleChange} />
        </ImageContainer>
        <ButtonContainer>
          <Button icon="Edit" onClick={handleEdit} />
          {delButton && <Button color="danger" icon="Trash" onClick={handleDelete} />}
        </ButtonContainer>
      </FormSection>
      <div ref={noteRef} />
    </FormContainer>
  );
}

Photo.propTypes = {
  className: PropTypes.string,
  useInput: PropTypes.func.isRequired
};

export default Photo;
