import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import Table from 'components/Table';
import { useTranslation } from 'hooks';
import { getTableColumns } from './config';

function PlacementsTable({ className, placements, syncing }) {
  const { t } = useTranslation();
  const [selection, setSelection] = useState(null);
  const handleView = useCallback(data => setSelection(data), [setSelection]);
  const columns = useMemo(() => getTableColumns(t, handleView), [handleView, t]);

  const placementsData = useMemo(
    () =>
      placements.map(placement => {
        return {
          ...placement,
          contactName: placement.contactName || '-',
          contactPhone: placement.contactPhone || '-',
          contactEmail: placement.contactEmail || '-'
        };
      }),
    [placements]
  );

  return (
    <React.Fragment>
      <Table className={className} columns={columns} data={placementsData} selection={selection} type="placements" />
    </React.Fragment>
  );
}

PlacementsTable.propTypes = {
  className: PropTypes.string,
  placements: PropTypes.array.isRequired,
  syncing: PropTypes.bool
};

export default PlacementsTable;
