import { connect } from 'react-redux';
import * as authActions from 'redux-modules/auth/actions';
import * as authSelectors from 'redux-modules/auth/selectors';
import UpdatePasswordPage from './UpdatePasswordPage';

function mapStateToProps(state) {
  return {
    isResetPasswordTokenValid: authSelectors.selectIsResetPasswordTokenValid(state),
    resetPwdStatus: authSelectors.selectResetPwdStatus(state),
    syncError: authSelectors.selectSyncError(state),
    syncing: authSelectors.selectSyncState(state),
    isWelcomeEmailResent: authSelectors.selectResendWelcomeEmail(state)
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    onInit: () => {
      dispatch(authActions.resetResetPasswordStatus());
      dispatch(authActions.validateResetToken(ownProps.token));
    },
    onUpdatePassword: (data, failCallback) =>
      dispatch(authActions.updatePassword(data, failCallback)),
    onRequestNewLink: token => dispatch(authActions.requestNewLink(token))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(UpdatePasswordPage);
