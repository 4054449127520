import { connect } from 'react-redux';
import * as resumeActions from 'redux-modules/resume/actions';
import * as resumeSelectors from 'redux-modules/resume/selectors';
import GroupEditor from './GroupEditor';

function mapStateToProps(state) {
  return {
    syncErrors: resumeSelectors.selectSyncError(state),
    syncing: resumeSelectors.selectSyncState(state),
    availableCompaniesToGroup: resumeSelectors.selectAvailableCompaniesToGroup(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onDelete: (data, failCallback) => dispatch(resumeActions.deleteCompanyGroup(data, failCallback)),
    onSaveGroup: (data, failCallback) => dispatch(resumeActions.saveCompanyGroup(data, failCallback))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(GroupEditor);
