import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Plus } from 'components/icons';
import TextField from 'components/inputs/TextField';
import Title from 'components/Title';
import TypeValue from '../TypeValue';
import { FormContainer, FormItem, FormRow, FormSection } from 'components/styledComponents';
import { useTranslation } from 'hooks';
import { socialTypes } from './config';
import { AddButton } from './style';

function AditionalInformation({ className, useInput, onAdd, onDelete, onResetError }) {
  const { t } = useTranslation();
  const { value: socialNetworks } = useInput('socialNetworks');

  const socialOptions = useMemo(
    () => socialTypes.map(socialType => ({ key: socialType, value: t(`label.${socialType}`) })),
    [t]
  );
  const selectedSocials = useMemo(
    () =>
      socialNetworks.reduce((selectionMap, socialNetwork) => {
        socialNetwork && (selectionMap[socialNetwork.type] = true);
        return selectionMap;
      }, {}),
    [socialNetworks]
  );
  const filterOption = useCallback(({ value }) => !selectedSocials[value], [selectedSocials]);
  return (
    <FormContainer className={className}>
      <FormSection divider="bottom" padding="4 0">
        <Title label={t('title.additionalInformation')} size="large" />
      </FormSection>
      <FormSection padding="3 0 1">
        <FormRow>
          <FormItem>
            <Title component="h4" label={t('title.socialNetwork')} />
          </FormItem>
        </FormRow>
        <FormRow>
          <FormItem>
            {socialNetworks.map((item, index) => (
              <TypeValue
                basePath={`socialNetworks[${index}]`}
                defaultLabel={t('label.socialNetwork')}
                filterOption={filterOption}
                key={index}
                options={socialOptions}
                useInput={useInput}
                onDelete={onDelete}
                onResetError={onResetError}
              />
            ))}
            <FormSection>
              <AddButton
                contentFirst={true}
                disabled={socialNetworks.length >= socialOptions.length}
                label={t('button.addSocialNetwork')}
                variant="outlined"
                onClick={() => onAdd('socialNetworks', null)}
              >
                <Plus fontSize="small" />
              </AddButton>
            </FormSection>
          </FormItem>
          <FormItem empty />
        </FormRow>
        <FormRow>
          <FormItem component={TextField} label={t('label.skypeId')} {...useInput('skypeId')} />
          <FormItem
            component={TextField}
            label={t('label.ownCompany')}
            {...useInput('ownCompany')}
          />
        </FormRow>
        <FormRow>
          <FormItem
            component={TextField}
            label={t('label.youtubeVideo')}
            {...useInput('profileVideo')}
          />
          <FormItem component={TextField} label={t('label.website')} {...useInput('website')} />
        </FormRow>
      </FormSection>
    </FormContainer>
  );
}

AditionalInformation.propTypes = {
  className: PropTypes.string,
  useInput: PropTypes.func.isRequired,
  onAdd: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired
};

export default AditionalInformation;
