import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Detail from './Details';
import Section from '../Section';

function ExecutiveSummary({ className, editPath, executiveSummary, language }) {
  const executiveSummaryData = useMemo(() => executiveSummary[language], [
    executiveSummary,
    language
  ]);
  const path = useMemo(() => editPath && `${editPath}/executive-summary`, [editPath]);

  return (
    <Section className={className} editPath={path} title="title.executiveSummary">
      {/* Boolean conversion needed to avoid displaying "0" when length is 0 */}
      {!!(executiveSummaryData && executiveSummaryData.length) && (
        <Detail executiveSummary={executiveSummaryData} />
      )}
    </Section>
  );
}

ExecutiveSummary.propTypes = {
  className: PropTypes.string,
  editPath: PropTypes.string,
  executiveSummary: PropTypes.object.isRequired,
  language: PropTypes.string.isRequired
};

export default ExecutiveSummary;
