import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import MuiContainer from '@material-ui/core/Container';
import { default as LinkComponent } from 'components/Link';

export const Container = styled(MuiContainer)`
  display: flex;
  flex-direction: column;
  /* do not overwrite MuiContainer left and right padding */
  padding-bottom: ${props => props.theme.spacing(4)};
  padding-top: ${props => props.theme.spacing(4)};

  @media (min-width: 650px) {
    padding-bottom: ${props => props.theme.spacing(8)};
    padding-top: ${props => props.theme.spacing(8)};
  }
`;

export const LinkContainer = styled.div`
  display: flex;
  margin-bottom: ${props => props.theme.spacing(3)};
`;

export const Link = styled(LinkComponent).attrs(props => ({
  component: NavLink,
  ...props
}))`
  align-items: center;
  border-bottom: 1px solid transparent;
  box-sizing: border-box;
  color: ${props => props.theme.color.grey[700]};
  display: inline-flex;
  font-size: ${props => props.theme.fontSize.large};
  padding: ${props => props.theme.spacing(0, 2, 1)};

  &.active {
    border-color: ${props => props.theme.color.primary.main};
    color: ${props => props.theme.color.primary.main};
  }
`;
