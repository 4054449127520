import React from 'react';
import PropTypes from 'prop-types';
import { Button as StyledButton, CustomContent, Label } from './style';

function Button({
  children,
  className,
  color,
  component: Component,
  contentFirst,
  disabled,
  fullWidth,
  label,
  size,
  type,
  variant,
  onClick,
  ...rest
}) {
  return (
    <StyledButton
      as={Component}
      className={className}
      color={color}
      disabled={disabled}
      size={size}
      type={type}
      variant={variant}
      onClick={onClick}
      {...(fullWidth ? { fullWidth, ...rest } : rest)}
    >
      {contentFirst && children}
      {label && <Label>{label}</Label>}
      {!contentFirst && children && <CustomContent>{children}</CustomContent>}
    </StyledButton>
  );
}

Button.defaultProps = {
  color: 'default',
  contentFirst: false,
  size: 'large',
  type: 'button',
  variant: 'contained'
};

Button.propTypes = {
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.node, PropTypes.object]),
  className: PropTypes.string,
  color: PropTypes.oneOf(['danger', 'default', 'primary', 'secondary']).isRequired,
  component: PropTypes.oneOfType([PropTypes.func, PropTypes.node, PropTypes.object]),
  contentFirst: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
  label: PropTypes.string,
  size: PropTypes.oneOf(['small', 'medium', 'large']).isRequired,
  type: PropTypes.oneOf(['button', 'submit']),
  variant: PropTypes.oneOf([
    'contained',
    'lightContained',
    'lightOutlined',
    'lightText',
    'outlined',
    'text'
  ]).isRequired,
  onClick: PropTypes.func
};

export default React.memo(Button);
