import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

/**
 * @TODO replace Loader with a proper react loading component in order to avoid
 * blocking the usage of before and after pseudo elements
 */
export const Loader = styled(({ component: Component, loading, spinnerSize, ...rest }) => (
  <Component {...rest} />
))`
  ${props =>
    props.loading &&
    `
      overflow: hidden;
      position: relative;

      :after,
      :before {
        content: '';
      }
    `}
  :after {
    animation: load6 1.7s infinite ease, round 1.7s infinite ease;
    border-radius: 50%;
    color: ${props => props.theme.color.primary.main};
    font-size: ${props => `${props.spinnerSize}px`};
    height: 1em;
    left: 50%;
    margin: -0.5em 0 0 -0.5em;
    overflow: hidden;
    position: absolute;
    top: 50%;
    transform: translateZ(0);
    width: 1em;
    z-index: 200;
  }
  :before {
    background: ${props => props.theme.color.background.overlay};
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 100;
  }

  @keyframes load6 {
    0% {
      box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em,
        0 -0.83em 0 -0.477em;
    }
    5%,
    95% {
      box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em,
        0 -0.83em 0 -0.477em;
    }
    10%,
    59% {
      box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em, -0.173em -0.812em 0 -0.44em,
        -0.256em -0.789em 0 -0.46em, -0.297em -0.775em 0 -0.477em;
    }
    20% {
      box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em, -0.555em -0.617em 0 -0.44em,
        -0.671em -0.488em 0 -0.46em, -0.749em -0.34em 0 -0.477em;
    }
    38% {
      box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em, -0.645em -0.522em 0 -0.44em,
        -0.775em -0.297em 0 -0.46em, -0.82em -0.09em 0 -0.477em;
    }
    100% {
      box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em,
        0 -0.83em 0 -0.477em;
    }
  }
  @keyframes round {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

Loader.defaultProps = {
  component: 'div',
  loading: false,
  spinnerSize: '40'
};

Loader.propTypes = {
  component: PropTypes.oneOfType([PropTypes.func, PropTypes.node, PropTypes.object]),
  loading: PropTypes.bool.isRequired,
  spinnerSize: PropTypes.string.isRequired
};
