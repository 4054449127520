import styled from 'styled-components';
import { Button as BaseButton } from 'components/buttons';

export const FlexBox = styled.div`
  display: flex;
  padding: 15px 70px;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  border: 3px solid ${props => props.color};
  color: ${props => props.color};
  font-weight: ${props => props.theme.fontWeight.bold};
  min-width: 24px;
  white-space: nowrap;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${props => props.theme.spacing(5)};
`;

export const TabContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TabHeader = styled.div`
  display: flex;
  position: relative;
`;

export const TabHeaderItem = styled.div`
  align-items: center;
  background: ${props => (props.selected ? 'white' : 'transparent')};
  border: 1px solid ${props => (props.selected ? props.theme.color.grey[500] : 'transparent')};
  border-bottom: none;
  color: ${props =>
    props.selected ? props.theme.color.primary.main : props.theme.color.default.main};
  cursor: pointer;
  display: flex;
  justify-content: center;
  font-size: ${props => props.theme.fontSize.xsmall};
  font-weight: ${props => props.theme.fontWeight.bold};
  margin: ${props => (props.selected ? '-1px 0' : 0)};
  padding: ${props => props.theme.spacing(2)};
  position: relative;
  text-align: center;
  text-transform: uppercase;
  width: ${props => props.theme.spacing(30)};
`;

export const TabContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${props => props.theme.spacing(2)};
  border: 1px solid ${props => props.theme.color.grey[500]};
`;

export const Button = styled(BaseButton)`
  @media (max-width: 500px) {
    align-self: center;
  }
  padding: 14px;
  margin-left: 20px;
  margin-right: 10px;
`;

export const FlexContainer = styled.div`
  display: flex;
  justify-content: ${props => (props.justifyContent ? props.justifyContent : 'normal')};
  align-items: ${props => (props.alignItems ? props.alignItems : 'normal')};
  flex: 1;
  ${props =>
    props.wrap
      ? `
      flex-wrap: wrap;
      @media (max-width: 725px) {
        margin-bottom: 10px;
      }
  `
      : ``}
`;
