import React from 'react';
import PropTypes from 'prop-types';
import { CardsContainer } from 'components/cards';
import { Loader } from 'components/styledComponents';
import {
  Education,
  ExecutiveSummary,
  CompetenceProfile,
  ITSkills,
  Languages,
  OtherActivities,
  WorkExperience
} from './sections';
import { SectionsContainer, CompetenceContainer} from './style';

function Resume({
  editPath,
  indexedMeta,
  language,
  loading,
  resume: {
    education,
    executiveSummary,
    competenceProfile,
    itSkills,
    languageProficiencies,
    otherActivities,
    workExperience
  }
}) {
  return (
      <>
    <CompetenceContainer>
      <Loader component={CardsContainer} loading={loading}>
        <CompetenceProfile
            editPath={editPath}
            competenceProfile={competenceProfile}
            language={language}
        />
      </Loader>
    </CompetenceContainer>
    <SectionsContainer>
      <Loader component={CardsContainer} loading={loading}>
        <ExecutiveSummary
          editPath={editPath}
          executiveSummary={executiveSummary}
          language={language}
        />
        <WorkExperience
          editPath={editPath}
          indexedMeta={indexedMeta}
          language={language}
          workExperience={workExperience}
        />
        <Education
          editPath={editPath}
          education={education}
          indexedMeta={indexedMeta}
          language={language}
        />
      </Loader>
      <Loader component={CardsContainer} loading={loading}>
        <Languages
          editPath={editPath}
          indexedMeta={indexedMeta}
          language={language}
          languages={languageProficiencies}
        />
        <ITSkills editPath={editPath} indexedMeta={indexedMeta} itSkills={itSkills} />
        <OtherActivities
          editPath={editPath}
          language={language}
          otherActivities={otherActivities}
        />
      </Loader>
    </SectionsContainer>
        </>
  );
}

Resume.propTypes = {
  editPath: PropTypes.string,
  indexedMeta: PropTypes.object.isRequired,
  language: PropTypes.string.isRequired,
  loading: PropTypes.bool,
  resume: PropTypes.shape({
    education: PropTypes.array.isRequired,
    executiveSummary: PropTypes.object.isRequired,
    itSkills: PropTypes.shape({
      itSkills: PropTypes.array.isRequired
    }),
    competenceProfile: PropTypes.object.isRequired,
    languageProficiencies: PropTypes.array.isRequired,
    otherActivities: PropTypes.object.isRequired,
    workExperience: PropTypes.array.isRequired
  }).isRequired
};

export default Resume;
