export const EMPTY_META = {};

/**
 * this is a mapping object between portal fields and sugar fields
 * keys represent portal field name where as value represent sugar field address
 */
export const MAPPING_P2S = {
    firstName: {module: "logma_Manager", field: "first_name"},
    lastName: {module: "logma_Manager", field: "last_name"},
    'primaryAddress.street': {module: "logma_Manager", field: "primary_address_street"},
    'primaryAddress.postalCode': {module: "logma_Manager", field: "primary_address_postalcode"},
    'primaryAddress.city': {module: "logma_Manager", field: "primary_address_city"},
    'phones[0].value': {module: "logma_Manager", field: "phone_mobile"},
    'phones[1].value': {module: "logma_Manager", field: "phone_mobile"},
    'phones[2].value': {module: "logma_Manager", field: "phone_mobile"},
    'phones[3].value': {module: "logma_Manager", field: "phone_mobile"},
    'emails[0].value': {module: "EmailMarketing", field: "reply_to_addr"},
    'emails[1].value': {module: "EmailMarketing", field: "reply_to_addr"},
    'emails[2].value': {module: "EmailMarketing", field: "reply_to_addr"},
    'emails[3].value': {module: "EmailMarketing", field: "reply_to_addr"},
    'emails[4].value': {module: "EmailMarketing", field: "reply_to_addr"},
    'emails[5].value': {module: "EmailMarketing", field: "reply_to_addr"},
    'emails[6].value': {module: "EmailMarketing", field: "reply_to_addr"},
    'emails[7].value': {module: "EmailMarketing", field: "reply_to_addr"},
    'emails[8].value': {module: "EmailMarketing", field: "reply_to_addr"},
    'emails[9].value': {module: "EmailMarketing", field: "reply_to_addr"},
    'socialNetworks[0].value': {module: "logma_Manager", field: "linkedin_account_c"},
    'socialNetworks[1].value': {module: "logma_Manager", field: "linkedin_account_c"},
    skypeId: {module: "logma_Manager", field: "skype_id_c"},
    ownCompany: {module: "logma_Manager", field: "own_company"},
    profileVideo: {module: "logma_Manager", field: "profile_video_c"},
    website: {module: "logma_Manager", field: "website_c"},
    availableFromRemarks: {module: "logma_Manager", field: "available_from_remarks"},
    mobilityRemarks: {module: "logma_Manager", field: "mobility_remarks_c"},
    dailyRateFrom: {module: "logma_Manager", field: "daily_rate_from"},
    nameAffix: {module: "logma_Manager", field: "name_suffix"},
    annualTargetIncome: {module: "logma_Manager", field: "yearly_income_c"},

    title: {module: "logma_Kandidatenliste", field: "display_title"},
    feedback: {module: "logma_Kandidatenliste", field: "brief_description_manager_c"},
    rejectedBy: {module: "logma_Kandidatenliste", field: "rejection_by"},
    rejectedReason: {module: "logma_Kandidatenliste", field: "reject_reason"},
    application: {module: "logma_Kandidatenliste", field: "candidate_application_opp_c"},

    situation: {module: "Monrp_monthly_reports", field: "situation"},
    processedTasksAndActions: {module: "Monrp_monthly_reports", field: "processed_tasks_and_actions"},
    goalAchievementsResults: {module: "Monrp_monthly_reports", field: "goals_achievements_results"},
    criticalInhibitoryFactors: {module: "Monrp_monthly_reports", field: "critical_inhibitory_factors"},
    relationshipWithClient: {module: "Monrp_monthly_reports", field: "relationship_with_the_client"},
    supportNeccesary: {module: "Monrp_monthly_reports", field: "support_necessary"},
    otherRemarks: {module: "Monrp_monthly_reports", field: "other_remarks"},

    itSkillRemarks: {module: "logma_Manager", field: "it_skills_remarks_de"},
    fieldOfStudy: {module: "logma_Ausbildung", field: "edu_education_name_de"},
    institution: {module: "logma_Ausbildung", field: "edu_school_name"},
    details: {module: "logma_Ausbildung", field: "edu_education_details_en"},

    'details-rich-editor': {module: "logma_Ausbildung", field: "edu_education_details_de"},
    'executive-summary-rich-editor': {module: "logma_Manager", field: "overall_assessment"},
    'itskill-remarks-rich-editor': {module: "logma_Manager", field: "it_skills_remarks_de"},
    'other-activities-rich-editor': {module: "logma_Manager", field: "other_activities_de"},
    'description-rich-editor': {module: "logma_Berufserfahrungen", field: "description_de_tiny_c"},

    companyName: {module: "Ssqcv_accounts", field: "name"},
    industryDescription: {module: "Ssqcv_accounts", field: "industry_description_de"},

    name: {module: "logma_Berufserfahrungen", field: "name"},
    reportingTo: {module: "logma_Berufserfahrungen", field: "reporting_to"},
    city: {module: "Ssqcv_accounts", field: "city_de"},
    description: {module: "logma_Berufserfahrungen", field: "description_de_tiny_c"},
    'missionsAbroad[0].monthsSpent': {module: "logma_Auslandseinsaetze", field: "duration_c"},
    'missionsAbroad[1].monthsSpent': {module: "logma_Auslandseinsaetze", field: "duration_c"},
    'missionsAbroad[2].monthsSpent': {module: "logma_Auslandseinsaetze", field: "duration_c"},
    'missionsAbroad[3].monthsSpent': {module: "logma_Auslandseinsaetze", field: "duration_c"},
    'missionsAbroad[4].monthsSpent': {module: "logma_Auslandseinsaetze", field: "duration_c"},
    'missionsAbroad[5].monthsSpent': {module: "logma_Auslandseinsaetze", field: "duration_c"},
    'missionsAbroad[6].monthsSpent': {module: "logma_Auslandseinsaetze", field: "duration_c"},
    'missionsAbroad[7].monthsSpent': {module: "logma_Auslandseinsaetze", field: "duration_c"},
    'missionsAbroad[8].monthsSpent': {module: "logma_Auslandseinsaetze", field: "duration_c"},
    'missionsAbroad[9].monthsSpent': {module: "logma_Auslandseinsaetze", field: "duration_c"},
    'missionsAbroad[10].monthsSpent': {module: "logma_Auslandseinsaetze", field: "duration_c"},
    'missionsAbroad[11].monthsSpent': {module: "logma_Auslandseinsaetze", field: "duration_c"},
    'missionsAbroad[12].monthsSpent': {module: "logma_Auslandseinsaetze", field: "duration_c"},
    'missionsAbroad[13].monthsSpent': {module: "logma_Auslandseinsaetze", field: "duration_c"},
    'missionsAbroad[14].monthsSpent': {module: "logma_Auslandseinsaetze", field: "duration_c"},
    'missionsAbroad[15].monthsSpent': {module: "logma_Auslandseinsaetze", field: "duration_c"},
    'missionsAbroad[16].monthsSpent': {module: "logma_Auslandseinsaetze", field: "duration_c"},
    'missionsAbroad[17].monthsSpent': {module: "logma_Auslandseinsaetze", field: "duration_c"},
    'missionsAbroad[18].monthsSpent': {module: "logma_Auslandseinsaetze", field: "duration_c"},
    'missionsAbroad[19].monthsSpent': {module: "logma_Auslandseinsaetze", field: "duration_c"},
    'missionsAbroad[20].monthsSpent': {module: "logma_Auslandseinsaetze", field: "duration_c"},

    'reference.name': {module: "logma_Berufserfahrungen", field: "reference_name_c"},
    'reference.position': {module: "logma_Berufserfahrungen", field: "reference_position_c"},
    'reference.comment': {module: "logma_Berufserfahrungen", field: "reference_comment_c"},
    'reference.contactDetails': {module: "logma_Berufserfahrungen", field: "reference_contact_details_c"},

    'sameCompanyRoles[0].name': {module: "logma_Berufserfahrungen", field: "name"},
    'sameCompanyRoles[1].name': {module: "logma_Berufserfahrungen", field: "name"},
    'sameCompanyRoles[2].name': {module: "logma_Berufserfahrungen", field: "name"},
    'sameCompanyRoles[3].name': {module: "logma_Berufserfahrungen", field: "name"},
    'sameCompanyRoles[4].name': {module: "logma_Berufserfahrungen", field: "name"},
    'sameCompanyRoles[5].name': {module: "logma_Berufserfahrungen", field: "name"},
    'sameCompanyRoles[6].name': {module: "logma_Berufserfahrungen", field: "name"},
    'sameCompanyRoles[7].name': {module: "logma_Berufserfahrungen", field: "name"},
    'sameCompanyRoles[8].name': {module: "logma_Berufserfahrungen", field: "name"},
    'sameCompanyRoles[9].name': {module: "logma_Berufserfahrungen", field: "name"},
    'sameCompanyRoles[10].name': {module: "logma_Berufserfahrungen", field: "name"},
    'sameCompanyRoles[11].name': {module: "logma_Berufserfahrungen", field: "name"},
    'sameCompanyRoles[12].name': {module: "logma_Berufserfahrungen", field: "name"},
    'sameCompanyRoles[13].name': {module: "logma_Berufserfahrungen", field: "name"},
    'sameCompanyRoles[14].name': {module: "logma_Berufserfahrungen", field: "name"},
    'sameCompanyRoles[15].name': {module: "logma_Berufserfahrungen", field: "name"},
    'sameCompanyRoles[16].name': {module: "logma_Berufserfahrungen", field: "name"},
    'sameCompanyRoles[17].name': {module: "logma_Berufserfahrungen", field: "name"},
    'sameCompanyRoles[18].name': {module: "logma_Berufserfahrungen", field: "name"},
    'sameCompanyRoles[19].name': {module: "logma_Berufserfahrungen", field: "name"},
    'sameCompanyRoles[20].name': {module: "logma_Berufserfahrungen", field: "name"},

    'relatedCompanyRoles[0].companyName': {module: "Ssqcv_accounts", field: "name"},
    'relatedCompanyRoles[1].companyName': {module: "Ssqcv_accounts", field: "name"},
    'relatedCompanyRoles[2].companyName': {module: "Ssqcv_accounts", field: "name"},
    'relatedCompanyRoles[3].companyName': {module: "Ssqcv_accounts", field: "name"},
    'relatedCompanyRoles[4].companyName': {module: "Ssqcv_accounts", field: "name"},
    'relatedCompanyRoles[5].companyName': {module: "Ssqcv_accounts", field: "name"},
    'relatedCompanyRoles[6].companyName': {module: "Ssqcv_accounts", field: "name"},
    'relatedCompanyRoles[7].companyName': {module: "Ssqcv_accounts", field: "name"},
    'relatedCompanyRoles[8].companyName': {module: "Ssqcv_accounts", field: "name"},
    'relatedCompanyRoles[9].companyName': {module: "Ssqcv_accounts", field: "name"},
    'relatedCompanyRoles[10].companyName': {module: "Ssqcv_accounts", field: "name"},
    'relatedCompanyRoles[11].companyName': {module: "Ssqcv_accounts", field: "name"},
    'relatedCompanyRoles[12].companyName': {module: "Ssqcv_accounts", field: "name"},
    'relatedCompanyRoles[13].companyName': {module: "Ssqcv_accounts", field: "name"},
    'relatedCompanyRoles[14].companyName': {module: "Ssqcv_accounts", field: "name"},
    'relatedCompanyRoles[15].companyName': {module: "Ssqcv_accounts", field: "name"},
    'relatedCompanyRoles[16].companyName': {module: "Ssqcv_accounts", field: "name"},
    'relatedCompanyRoles[17].companyName': {module: "Ssqcv_accounts", field: "name"},
    'relatedCompanyRoles[18].companyName': {module: "Ssqcv_accounts", field: "name"},
    'relatedCompanyRoles[19].companyName': {module: "Ssqcv_accounts", field: "name"},
    'relatedCompanyRoles[20].companyName': {module: "Ssqcv_accounts", field: "name"},

    'relatedCompanyRoles[0].name': {module: "logma_Berufserfahrungen", field: "job_position_de_txt"},
    'relatedCompanyRoles[1].name': {module: "logma_Berufserfahrungen", field: "job_position_de_txt"},
    'relatedCompanyRoles[2].name': {module: "logma_Berufserfahrungen", field: "job_position_de_txt"},
    'relatedCompanyRoles[3].name': {module: "logma_Berufserfahrungen", field: "job_position_de_txt"},
    'relatedCompanyRoles[4].name': {module: "logma_Berufserfahrungen", field: "job_position_de_txt"},
    'relatedCompanyRoles[5].name': {module: "logma_Berufserfahrungen", field: "job_position_de_txt"},
    'relatedCompanyRoles[6].name': {module: "logma_Berufserfahrungen", field: "job_position_de_txt"},
    'relatedCompanyRoles[7].name': {module: "logma_Berufserfahrungen", field: "job_position_de_txt"},
    'relatedCompanyRoles[8].name': {module: "logma_Berufserfahrungen", field: "job_position_de_txt"},
    'relatedCompanyRoles[9].name': {module: "logma_Berufserfahrungen", field: "job_position_de_txt"},
    'relatedCompanyRoles[10].name': {module: "logma_Berufserfahrungen", field: "job_position_de_txt"},
    'relatedCompanyRoles[11].name': {module: "logma_Berufserfahrungen", field: "job_position_de_txt"},
    'relatedCompanyRoles[12].name': {module: "logma_Berufserfahrungen", field: "job_position_de_txt"},
    'relatedCompanyRoles[13].name': {module: "logma_Berufserfahrungen", field: "job_position_de_txt"},
    'relatedCompanyRoles[14].name': {module: "logma_Berufserfahrungen", field: "job_position_de_txt"},
    'relatedCompanyRoles[15].name': {module: "logma_Berufserfahrungen", field: "job_position_de_txt"},
    'relatedCompanyRoles[16].name': {module: "logma_Berufserfahrungen", field: "job_position_de_txt"},
    'relatedCompanyRoles[17].name': {module: "logma_Berufserfahrungen", field: "job_position_de_txt"},
    'relatedCompanyRoles[18].name': {module: "logma_Berufserfahrungen", field: "job_position_de_txt"},
    'relatedCompanyRoles[19].name': {module: "logma_Berufserfahrungen", field: "job_position_de_txt"},
    'relatedCompanyRoles[20].name': {module: "logma_Berufserfahrungen", field: "job_position_de_txt"},

    companyGroupName: {module: "Ssqcv_accountsgroup", field: "name"},
    opportunitySubmitRemarks: {module: "logma_Kandidatenliste", field: "candidate_application_opp_c"},
    'ttpopper.description': {module: "Actrp_activity_reports", field: "activity_description"},
};
