import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  Checkbox,
  DateField,
  MultiLanguageRichTextField,
  MultiLanguageTextField,
  SelectField
} from 'components/inputs';
import { FormContainer, FormItem, FormRow, FormTextItem } from 'components/styledComponents';
import { useLanguagesPaths, useTranslation } from 'hooks';
import { Description } from './style';

const EDUCATION_IDS_WITH_COURSE = ['200', '300', '500', '550'];

function Form({
  className,
  language,
  meta: { certificateTypes, countries, courseTypes, educationLevels, educationTypes, schoolTypes },
  useInput
}) {
  const { tr } = useTranslation();
  const resetStudyCourseRef = useRef(false);
  const { value: dateFrom } = useInput('dateFrom');
  const { value: educationType, onChange: onEducationTypeChange } = useInput('educationType');
  const { onChange: onStudyCourseChange } = useInput('course');
  const handleEducationTypeChange = useCallback(
    event => {
      onEducationTypeChange(event);
      resetStudyCourseRef.current = true;
    },
    [onEducationTypeChange]
  );
  // course studies should be enabled only for EDUCATION_IDS_WITH_COURSE defined values
  const disableCourseOfStudies = useMemo(() => !EDUCATION_IDS_WITH_COURSE.includes(educationType), [
    educationType
  ]);
  // reset "course of studies" value on user "type of training" change
  useEffect(() => {
    if (resetStudyCourseRef.current) {
      onStudyCourseChange('');
    }
    resetStudyCourseRef.current = false;
  }, [educationType, onStudyCourseChange]);
  
  return (
    <FormContainer className={className}>
      <FormRow>
        <FormItem
          component={MultiLanguageTextField}
          label={tr('label.trainingTitle')}
          language={language}
          required
          {...useInput('fieldOfStudy', {
            alternateFields: useLanguagesPaths('fieldOfStudy')
          })}
        />
      </FormRow>
      <FormRow>
        <FormItem component={Description}>{tr('message.writeEducationDescription')}</FormItem>
      </FormRow>
      <FormRow>
        <FormItem
          component={MultiLanguageRichTextField}
          data-test-id="details-rich-editor"
          data-rttextarea-id="details-rich-editor"
          label={tr('label.details')}
          language={language}
          required
          {...useInput('details', { alternateFields: useLanguagesPaths('details') })}
        />
      </FormRow>
      <FormRow>
        <FormItem
          component={DateField}
          disableFuture
          label={tr('label.dateFrom')}
          required
          wizardMode
          {...useInput('dateFrom')}
        />
        <FormItem
          component={DateField}
          label={tr('label.dateTo')}
          minDate={dateFrom}
          required
          wizardMode
          {...useInput('dateTo')}
        />
      </FormRow>
      <FormRow>
        <FormItem
          component={SelectField}
          label={tr('label.degree')}
          options={educationLevels}
          required
          {...useInput('degree')}
        />
        <FormItem
          component={SelectField}
          label={tr('label.certificate')}
          options={certificateTypes}
          {...useInput('certificate')}
        />
      </FormRow>
      <FormRow>
        <FormTextItem>
          <Checkbox label={tr('label.highestEducation')} {...useInput('isHighestDegree')} />
        </FormTextItem>
      </FormRow>
      <FormRow>
        <FormItem
          component={SelectField}
          label={tr('label.country')}
          options={countries}
          required
          {...useInput('country')}
        />
        <FormItem
          component={SelectField}
          label={tr('label.typeOfSchool')}
          options={schoolTypes}
          {...useInput('schoolType')}
        />
      </FormRow>
      <FormRow>
        <FormItem
          component={SelectField}
          label={tr('label.typeOfTraining')}
          options={educationTypes}
          {...useInput('educationType')}
          onChange={handleEducationTypeChange}
        />
        <FormItem
          component={SelectField}
          disabled={disableCourseOfStudies}
          label={tr('label.courseOfStudies')}
          options={courseTypes}
          {...useInput('course')}
        />
      </FormRow>
      <FormRow>
        <FormItem
          component={MultiLanguageTextField}
          label={tr('label.institution')}
          language={language}
          required
          {...useInput('institution', {
            alternateFields: useLanguagesPaths('institution')
          })}
        />
      </FormRow>
    </FormContainer>
  );
}

Form.propTypes = {
  className: PropTypes.string,
  language: PropTypes.string.isRequired,
  meta: PropTypes.object.isRequired,
  useInput: PropTypes.func.isRequired
};

export default Form;
