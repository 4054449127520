import { connect } from 'react-redux';
import * as resumeSelectors from 'redux-modules/resume/selectors';
import * as uiStateSelectors from 'redux-modules/uiState/selectors';
import OtherRoles from './OtherRoles';

function mapStateToProps(state) {
  return {
    language: uiStateSelectors.selectResumeLanguage(state),
    workExperience: resumeSelectors.selectWorkExperience(state)
  };
}

export default connect(mapStateToProps, null)(OtherRoles);
