// the configureStore file
import createSagaMiddleware from 'redux-saga';
import rootReducer from './rootReducer';
import rootSaga from './rootSaga';
import { applyMiddleware, compose, createStore } from 'redux';

const composeEnhancers =
  process.env.NODE_ENV !== 'production' &&
  typeof window === 'object' &&
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        /**
         * mandatory extension options object for this usage format
         * https://github.com/reduxjs/redux-devtools/issues/397
         */
      })
    : compose;
const sagaMiddleware = createSagaMiddleware();
const enhancer = composeEnhancers(applyMiddleware(sagaMiddleware));

const rootStore = createStore(rootReducer, enhancer);
sagaMiddleware.run(rootSaga);

export default rootStore;
