import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { SelectField } from 'components/inputs';
import { FormContainer, FormItem, FormRow } from 'components/styledComponents';
import { useTranslation } from 'hooks';
import { Image, Label, MuiGrid, GridContainer, BoxContainer, Box } from './style';

function Form({
  className,
  language,
  languageMeta: { communicationLanguages, lang },
  useInput,
  supportedLanguages,
  selectedDesign,
  selectedColor,
  setSelectedDesign,
  setSelectedColor,
  colors,
  templates
}) {
  const { t } = useTranslation();

  const cvOptions = lang => {
    let langTypes = [];
    if (lang === 'en_us') {
      langTypes = [
        { key: 'short', value: 'Short' },
        { key: 'long', value: 'Full' }
      ];
    } else {
      langTypes = [
        { key: 'short', value: 'Kurz' },
        { key: 'long', value: 'Lang' }
      ];
    }
    return langTypes;
  };
  const cvTypes = cvOptions(lang);

  const languageOptions = useMemo(
    () => communicationLanguages.filter(language => supportedLanguages.includes(language.key)),
    [communicationLanguages, supportedLanguages]
  );

  const handleImageClick = ({ target }) => {
    if (target) {
      setSelectedDesign(parseInt(target.id));
    }
  };

  const handleColorClick = ({ target }) => {
    if (target) {
      setSelectedColor(target.id);
    }
  };

  return (
    <FormContainer className={className} ml="8">
      <Label mt="-5" mb="18">
        {t('label.chooseCoverSheet')}
      </Label>
      <GridContainer>
        <MuiGrid container spacing={4}>
          {templates[selectedColor] &&
            templates[selectedColor].map((value, index) => (
              <MuiGrid key={index} item>
                <Image
                  id={index}
                  active={index === selectedDesign}
                  src={value}
                  onClick={handleImageClick}
                />
              </MuiGrid>
            ))}
        </MuiGrid>
      </GridContainer>
      <Label mt="20" mb="20">
        {t('label.chooseColor')}
      </Label>
      <FormRow>
        <BoxContainer>
          {colors &&
            colors.map((color, index) => (
              <Box
                key={index}
                id={color}
                color={color}
                active={color === selectedColor}
                onClick={handleColorClick}
              ></Box>
            ))}
        </BoxContainer>
      </FormRow>
      <Label mt="20" mb="20">
        {t('label.chooseLanguage')}
      </Label>
      <FormRow>
        <FormItem
          component={SelectField}
          label={t('label.language')}
          options={languageOptions}
          {...useInput('language')}
        />
        <FormItem
          component={SelectField}
          label={t('label.cvType')}
          options={cvTypes}
          {...useInput('cvType')}
        />
      </FormRow>
    </FormContainer>
  );
}

Form.propTypes = {
  className: PropTypes.string,
  language: PropTypes.string.isRequired,
  useInput: PropTypes.func.isRequired,
  languageMeta: PropTypes.array,
  lang: PropTypes.string,
  supportedLanguages: PropTypes.array,
  selectedDesign: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  selectedColor: PropTypes.string,
  setSelectedDesign: PropTypes.func.isRequired,
  setSelectedColor: PropTypes.func.isRequired,
  colors: PropTypes.array,
  templates: PropTypes.array
};

export default Form;
