import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Redirect, Route, Switch } from 'react-router-dom';
import AppFooter from 'components/AppFooter';
import AppHeader from 'components/AppHeader';
import {
  DashboardPage,
  MyAccountPage,
  ManagementPage,
  ResumePage,
  OpportunityCandidateCVPage,
  PlacementCandidateCVPage,
  PlacementsPage,
  AcceptTermsPage,
  MyNotificationSettings,
  MyDocumentsPage
} from 'components/pages';
import { MainSection } from 'components/styledComponents';
import { useLocation } from 'react-router-dom';

function PrivateContent({ onInit, showAgreement }) {
  const { state }  = useLocation();

  useEffect(() => {
    onInit();
  }, [onInit]);

  if (typeof showAgreement == 'string') {
    showAgreement = JSON.parse(showAgreement);
  }
  return (
    <React.Fragment>
      {!!showAgreement === false && <AppHeader />}
      <MainSection>
        <Switch>
          <Route component={DashboardPage} path="/dashboard" />
          <Route component={ManagementPage} path="/management" />
          <Route component={AcceptTermsPage} path="/agreement-terms" />
          <Route component={MyAccountPage} path="/account" />
          <Route component={MyNotificationSettings} path="/notification-settings" />
          <Route component={ResumePage} path="/resume" />
          <Route component={MyDocumentsPage} path="/documents" />
          <Route
            path="/placement/:id"
            render={routeProps => <PlacementsPage placementId={routeProps.match.params.id} />}
          />
          <Route
            path="/placement-resume/:placementId/:candidateId"
            render={routeProps => (
              <PlacementCandidateCVPage
                candidateId={routeProps.match.params.candidateId}
                placementId={routeProps.match.params.placementId}
              />
            )}
          />
          <Route
            path="/opportunity-resume/:opportunityId/:candidateId"
            render={routeProps => (
              <OpportunityCandidateCVPage
                candidateId={routeProps.match.params.candidateId}
                opportunityId={routeProps.match.params.opportunityId}
              />
            )}
          />
          <Redirect to={{ pathname: '/dashboard', state: { fromLogin: state && state.fromLogin } }} />
        </Switch>
      </MainSection>
      <AppFooter />
    </React.Fragment>
  );
}

PrivateContent.propTypes = {
  onInit: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired
};

export default PrivateContent;
