import React from 'react';
import PropTypes from 'prop-types';
import Title from 'components/Title';
import { SelectField, TextField } from 'components/inputs';
import { FormContainer, FormItem, FormRow, FormSection } from 'components/styledComponents';
import { useTranslation } from 'hooks';

function Address({ className, meta: { countries }, useInput }) {
  const { t } = useTranslation();

  return (
    <FormContainer className={className}>
      <FormSection divider="bottom" padding="4 0">
        <Title label={t('title.addressInformation')} size="large" />
      </FormSection>
      <FormSection padding="3 0 1">
        <FormRow>
          <FormItem>
            <Title component="h4" label={t('title.address')} />
          </FormItem>
        </FormRow>
        <FormRow>
          <FormItem
            component={TextField}
            label={t('label.street')}
            required
            {...useInput('primaryAddress.street')}
          />
        </FormRow>
        <FormRow>
          <FormItem
            component={TextField}
            label={t('label.zip')}
            required
            {...useInput('primaryAddress.postalCode')}
          />
          <FormItem
            component={TextField}
            label={t('label.city')}
            required
            {...useInput('primaryAddress.city')}
          />
          <FormItem
            component={SelectField}
            label={t('label.country')}
            options={countries}
            required
            {...useInput('primaryAddress.country')}
          />
        </FormRow>
      </FormSection>
    </FormContainer>
  );
}

Address.propTypes = {
  className: PropTypes.string,
  meta: PropTypes.shape({
    countries: PropTypes.array.isRequired
  }).isRequired,
  useInput: PropTypes.func.isRequired
};

export default Address;
