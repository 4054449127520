import * as types from './types';

export function fetchDocuments(refresh = false, successCallback = null) {
  return {
    payload: {
      refresh,
      successCallback
    },
    type: types.FETCH_MANAGER_DOCUMENTS
  };
}

export function fetchDocumentsSuccess(documents) {
  return {
    payload: {
      documents
    },
    type: types.FETCH_MANAGER_DOCUMENTS_SUCCESS
  };
}

export function uploadDocument(subcategoryId, file, successCallback = null, failCallback = null) {
  return {
    payload: {
      subcategoryId,
      file,
      successCallback,
      failCallback
    },
    type: types.UPLOAD_MANAGER_DOCUMENT
  };
}

export function uploadDocumentSuccess() {
  return {
    type: types.UPLOAD_MANAGER_DOCUMENT_SUCCESS
  };
}

export function resetDocumentsSyncing() {
  return {
    type: types.RESET_MANAGER_DOCUMENTS_SYNCING
  };
}
