import styled from 'styled-components';

export const Container = styled.div`
  box-sizing: border-box;
  margin-left: auto;
  margin-right: auto;
  padding-left: ${props => props.theme.spacing(2)};
  padding-right: ${props => props.theme.spacing(2)};
  width: 100%;

  @media (min-width: 600px) {
    padding-left: ${props => props.theme.spacing(3)};
    padding-right: ${props => props.theme.spacing(3)};
  }
  @media (min-width: 960px) {
    padding-left: ${props => props.theme.spacing(4)};
    padding-right: ${props => props.theme.spacing(4)};
  }
`;

export const Section = styled.div`
  background: ${props => props.theme.color.background.section};
  ${props => props.fullSize && 'flex: 1 1 auto;'}
  ${props => props.padding && `padding: ${props.theme.spacing(...props.padding.split(' '))};`}
  ${props => props.margin && `margin: ${props.theme.spacing(...props.margin.split(' '))};`}
`;

export const MainSection = styled.main`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  overflow: hidden;
  z-index: ${props => props.theme.layer.base};
`;
