import React, {useEffect, useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import {EditorState} from 'draft-js';
import {useTranslation} from "hooks";
import {stateFromHTML} from 'draft-js-import-html';
import {CompetenceTextArea, Label, Button, IconLabel, ToggleIcon, TogglerContainer, EmptySection, EmptyText} from 'components/pages/ResumePage/sections/CompetenceProfile/Form/style.js';
import TextEditor from 'components/TextEditor';
import Grid from '@material-ui/core/Grid';
import { ArrowLeft, ArrowRight } from 'components/icons';
import $ from 'jquery'

function Details({className, competenceProfile}) {
    const {tr} = useTranslation();

    const [test, setTest] = useState(false);
    const [industryExpanded, setIndustryExpanded] = useState(false);
    const [specialistExpanded, setSpecialistExpanded] = useState(false);
    const [managementExpanded, setManagementExpanded] = useState(false);
    const [showMore, setShowMore] = useState(false);

    const emptySection = <EmptySection>
        <EmptyText>{tr('message.sectionIsEmpty')}</EmptyText>
        <EmptyText>{tr('message.addMoreInformation')}</EmptyText>
    </EmptySection>;

    useEffect(() => {
        if (industryExpanded) {
            $(document).find(".limit-industryFocuses-box").removeClass("limit-industryFocuses-box");
        } else {
            $(document).find(".industryFocuses-box").addClass("limit-industryFocuses-box");
        }
    }, [industryExpanded]);
    useEffect(() => {
        setTest(!test);
    }, [competenceProfile]);

    useEffect(() => {
        if (specialistExpanded) {
            $(document).find(".limit-specialistFocuses-box").removeClass("limit-specialistFocuses-box");
        } else {
            $(document).find(".specialistFocuses-box").addClass("limit-specialistFocuses-box");
        }
    }, [specialistExpanded]);

    useEffect(() => {
        if (managementExpanded) {
            $(document).find(".limit-managementMethods-box").removeClass("limit-managementMethods-box");
        } else {
            $(document).find(".managementMethods-box").addClass("limit-managementMethods-box");
        }
    }, [managementExpanded]);

    function getButtonLabel(isExpanded) {
        return [isExpanded ? 'label.showLess' : 'label.showMore'];
    }

    const {industryFocusesData, managementMethodsData, specialistFocusesData} = competenceProfile;
    const industryFocusState = useMemo(
        () => EditorState.createWithContent(stateFromHTML(competenceProfile['industryFocusesData'])),
        [competenceProfile]
    );
    const managementMethodState = useMemo(
        () => EditorState.createWithContent(stateFromHTML(competenceProfile['managementMethodsData'])),
        [competenceProfile]
    );
    const specialistFocusState = useMemo(
        () => EditorState.createWithContent(stateFromHTML(competenceProfile['specialistFocusesData'])),
        [competenceProfile]
    );

    //init function to check if "Show more" component need to be shown (used only in compotency profile section) 
    const tinyMceInit = (boxClass) => {
        if (boxClass && $('.'+boxClass).prop("scrollHeight")) {
            const scrollHeight = $('.'+boxClass).prop("scrollHeight");
            if (scrollHeight > 230) if (typeof setShowMore == 'function') setShowMore(!showMore); 
        }
    }

    return (
        <>
            <Grid container>
                <Grid item xs={12} md={4} className="col-custom-width">
                    <CompetenceTextArea>
                        <Label>{tr('label.industryFocuses')}</Label>
                        {(industryFocusesData && industryFocusesData.length) ? (
                            <>
                                <TextEditor
                                    className={className + " limit-industryFocuses-box industryFocuses-box"}
                                    data-test-id="competence-profile-text1"
                                    editorState={industryFocusState}
                                    max-height="100px"
                                    readOnly
                                    value={industryFocusesData}
                                    boxClass="industryFocuses-box"
                                    tinyMceInit={tinyMceInit}

                                />
                                {!!($(".industryFocuses-box").prop("scrollHeight") > 230) && (

                                    <TogglerContainer>
                                        <Button onClick={() => setIndustryExpanded(!industryExpanded)}>
                                            <IconLabel >{tr(...getButtonLabel(industryExpanded))}</IconLabel>
                                            <ToggleIcon as={industryExpanded ? ArrowRight : ArrowLeft} />
                                        </Button>
                                    </TogglerContainer>
                                )}
                            </>

                        ): emptySection}

                    </CompetenceTextArea>
                </Grid>
                <Grid item xs={12} md={4}>
                    <CompetenceTextArea>
                        <Label>{tr('label.specialistFocuses')}</Label>

                        {(specialistFocusesData && specialistFocusesData.length) ?  (
                            <>
                                <TextEditor
                                    className={className + " limit-specialistFocuses-box specialistFocuses-box"}
                                    data-test-id="competence-profile-text2"
                                    editorState={specialistFocusState}
                                    readOnly
                                    value={specialistFocusesData}
                                    boxClass="specialistFocuses-box"
                                    tinyMceInit={tinyMceInit}
                                />

                            {!!($(".specialistFocuses-box").prop("scrollHeight") > 230) && (

                            <TogglerContainer>
                            <Button onClick={() => setSpecialistExpanded(!specialistExpanded)}>
                            <IconLabel >{tr(...getButtonLabel(specialistExpanded))}</IconLabel>
                            <ToggleIcon as={specialistExpanded ? ArrowRight : ArrowLeft} />
                            </Button>
                            </TogglerContainer>
                            )}
                            </>
                        ): emptySection}

                    </CompetenceTextArea>
                </Grid>
                <Grid item xs={12} md={4}>
                    <CompetenceTextArea>
                        <Label>{tr('label.managementMethods')}</Label>
                        {(managementMethodsData && managementMethodsData.length) ? (
                            <>
                                <TextEditor
                                    className={className + " limit-managementMethods-box managementMethods-box"}
                                    data-test-id="competence-profile-text3"
                                    editorState={managementMethodState}
                                    readOnly
                                    value={managementMethodsData}
                                    boxClass="managementMethods-box"
                                    tinyMceInit={tinyMceInit}
                                />
                                {!!($(".managementMethods-box").prop("scrollHeight") > 230) && (

                                    <TogglerContainer>
                                        <Button onClick={() => setManagementExpanded(!managementExpanded)}>
                                            <IconLabel >{tr(...getButtonLabel(managementExpanded))}</IconLabel>
                                            <ToggleIcon as={managementExpanded ? ArrowRight : ArrowLeft} />
                                        </Button>
                                    </TogglerContainer>

                                )}

                            </>
                        ): emptySection}
                    </CompetenceTextArea>
                </Grid>
            </Grid>
        </>
    );
}

Details.propTypes = {
    className: PropTypes.string,
    competenceProfile: PropTypes.string.isRequired
};

export default Details;
