import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Table from 'components/Table';
import { NDADialog, OpportunityDialog, SubmitDialog } from './dialogs';
import { useTableColumns } from './config';

function OpportunitiesTable({ className, opportunities, syncing }) {
  const [selection, setSelection] = useState(null);
  /**
   * 0 - closed dialogs / no selected opportunity
   * 1 - NDA dialog
   * 2 - opportunity details dialog
   * 3 - submit application dialog
   */
  const [step, setStep] = useState(0);
  const prevOpportunities = useRef();
  const handleClose = useCallback(() => {
    setSelection(null);
  }, [setSelection]);
  const handleInterest = useCallback(() => setStep(3), [setStep]);
  const handleView = useCallback(
    opportunity => {
      setSelection(opportunity);
    },
    [setSelection]
  );
  const columns = useTableColumns(handleView);

  // update step based on selection
  useEffect(() => {
    if (selection) {
      if (step !== 3) {
        setStep(selection.candidate.nda ? 2 : 1);
      }
    } else {
      if (step > 0) {
        setStep(0);
      }
    }
  }, [selection, step, setStep]);
  /**
   * replace selected opportunity object with it's new version when the
   * new version is received from server
   */
  useEffect(() => {
    if (selection && prevOpportunities.current !== opportunities) {
      setSelection(opportunities.find(opportunity => opportunity.id === selection.id));
    }
    prevOpportunities.current = opportunities;
  }, [opportunities, selection]);

  return (
    <React.Fragment>
      <Table className={className} columns={columns} data={opportunities} loading={syncing} />
      {selection && (
        <React.Fragment>
          {step === 1 && <NDADialog opportunity={selection} onClose={handleClose} />}
          {step === 2 && (
            <OpportunityDialog
              opportunity={selection}
              onClose={handleClose}
              onInterest={handleInterest}
            />
          )}
          {step === 3 && <SubmitDialog opportunity={selection} onClose={handleClose} />}
        </React.Fragment>
      )}
    </React.Fragment>
  );
}

OpportunitiesTable.propTypes = {
  className: PropTypes.string,
  opportunities: PropTypes.array.isRequired,
  syncing: PropTypes.bool
};

export default OpportunitiesTable;
