import React from 'react';
import CompetencePage from 'components/pages/CompetencePage';
import PropTypes from 'prop-types';

function Step3({
  currentStep,
  loading,
  syncing,
  useInput,
  handleNext,
  handlePrev,
  handleSubmit,
  handleClose
}) {
  if (currentStep !== 3) {
    return null;
  }
  return (
    <>
      <style>
        .registration-form-page{'{max-width: 100%; width: 1170px} '}
        .competence-divider{'{margin-top: 16px;}'}
      </style>
      <CompetencePage
        useInput={useInput}
        handleClose={handleClose}
        loading={loading}
        syncing={syncing}
        handleNext={handleNext}
        handlePrev={handlePrev}
        handleSubmit={handleSubmit}
      />
    </>
  );
}

Step3.propTypes = {
  currentStep: PropTypes.number,
  handleNext: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  syncing: PropTypes.bool,
  useInput: PropTypes.func.isRequired,
  handlePrev: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired
};

export default Step3;
