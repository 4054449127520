import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Button, LanguageSwitch, SelectorWrapper } from './style';

function LanguageSelector({ className, selectedLanguage, onChange }) {
  function useLanguage(language) {
    const handleChange = useCallback(
      event => {
        event.preventDefault();
        onChange(language);
      },
      [language]
    );
    return {
      active: selectedLanguage === language,
      children: language,
      onClick: handleChange
    };
  }

  return (
    <SelectorWrapper className={className}>
      <LanguageSwitch>
        <Button color="inherit" {...useLanguage('de')} />
        <Button color="inherit" {...useLanguage('en')} />
      </LanguageSwitch>
    </SelectorWrapper>
  );
}

LanguageSelector.propTypes = {
  className: PropTypes.string,
  selectedLanguage: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
};

export default LanguageSelector;
