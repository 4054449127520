import { createSelector } from 'reselect';
import * as uiStateSelectors from 'redux-modules/uiState/selectors';

export const selectOpportunities = state => state.opportunities.opportunities;

export const selectOpportunity = createSelector(
  selectOpportunities,
  (__, opportunityId) => opportunityId,
  (opportunities, opportunityId) => opportunities.find(item => item.id === opportunityId)
);

export const selectNewOpportunities = createSelector([selectOpportunities], opportunities =>
  opportunities.filter(opportunity => opportunity.candidate.status === '100')
);

export const selectFullNDA = state => state.opportunities.nda;
export const selectNDA = createSelector(
  [selectFullNDA, uiStateSelectors.selectLanguage],
  (ndaObject, language) => ndaObject[language] && ndaObject[language].textContent
);
export const selectSyncState = state => state.placements.syncing;
export const selectItemSyncStatus = state => state.opportunities.syncingItem;
