import styled from 'styled-components';
import Link from 'components/Link';

export const MenuContainer = styled.div``;

export const Anchor = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
  flex-direction: column;
`;

export const ManagerImage = styled.img`
  /* margin needed to add spacing between icon and the opened menu */
  margin: ${props => props.theme.spacing(0.75)} 0;
`;

export const AccountLink = styled(Link)`
  display: flex;
  align-items: center;
  justify-items: center;
  margin: ${props => props.theme.spacing(0, 3)};
`;

export const ManagerName = styled.div`
  color: ${props => props.theme.color.primary.main};
`;

export const ManagerContainer = styled.div`
  display: flex;
  margin: ${props => props.theme.spacing(1.5, 3, 1.5, 3)};
  width: 230px;
  ${ManagerImage} {
    flex-shrink: 0;
  }
`;

export const ListItem = styled.div`
  align-items: center;
  bottom: 0px;
  color: ${props => props.theme.color.default.main};
  display: flex;
  margin: 0px;
  position: absolute;
  top: 0px;
  width: 100%;
`;

export const LogoutListItem = styled(ListItem)`
  text-transform: uppercase;
  justify-content: center;
  padding-top: ${props => props.theme.spacing(1.5)};
`;
