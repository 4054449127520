import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';
import { default as LinkComponent } from 'components/Link';
import { ArrowUp, ArrowDown } from 'components/icons';

const propertyCellHeight = '85px';

export const Title = styled.h1`
  margin: 0;
`;

export const Header = styled.div`
  padding: ${props => props.theme.spacing(5)};
`;

export const Content = styled.div`
  background: ${props => props.theme.color.background.surface};
  margin-bottom: ${props => props.theme.spacing(5)};
`;

export const PropertyContainer = styled.div`
  background: ${props => props.theme.color.grey['200']};
`;

export const PropertyContainerTitle = styled(Typography)`
  font-weight: ${props => props.theme.fontWeight.bold};
  padding: ${props => props.theme.spacing(5)};
`;
export const PropertyContainerText = styled.div`
  padding: ${props => props.theme.spacing(0)} ${props => props.theme.spacing(5)};
  font-size: ${props => props.theme.spacing(2)};
  line-height: ${props => props.theme.spacing(3)};
`;
export const PropertyContainerRow = styled.div`
  display: flex;
  justify-content: space-around;
  flex-flow: row wrap;

  background-image: linear-gradient(
    180deg,
    ${props => props.theme.color.grey['400']} 50%,
    ${props => props.theme.color.grey['200']} 50%
  );
  background-repeat: repeat;
  background-size: 100px ${props => `calc(2 * (${propertyCellHeight} + ${props.theme.spacing(4)}))`};
`;
export const PropertyContainerCell = styled.div`
  flex: 0 0 200px;
  height: ${propertyCellHeight};
  padding: ${props => props.theme.spacing(2)} ${props => props.theme.spacing(5)};
  label {
    display: block;
    width: 100%;
    font-size: ${props => props.theme.spacing(2)};
    font-weight: ${props => props.theme.fontWeight.bold};
    line-height: ${props => props.theme.spacing(3)};
    color: ${props => props.theme.color.text.highlight};
  }
  label:first-child {
    font-weight: ${props => props.theme.fontWeight.normal};
  }
`;

export const AttachmentsContainer = styled.div`
  margin: ${props => props.theme.spacing(5)} 0px;
  padding: ${props => props.theme.spacing(5)} ${props => props.theme.spacing(5)};
  border-top: 1px solid #ccc;
  display: flex;
  flex-flow: wrap;
  background: ${props => props.theme.color.grey['200']};
  header {
    width: 100%;
    display: block;
  }
  a {
    color: ${props => props.theme.color.text.main};
    text-decoration: none;
    display: flex;
    flex-flow: column;
    width: 130px;
    word-break: break-all;
    margin: ${props => props.theme.spacing(3)} ${props => props.theme.spacing(5)};
  }
`;

export const InformationContainer = styled.div`
  background: ${props => props.theme.color.primary.contrast};
  position: relative;
  padding-bottom: ${props => props.theme.spacing(5)};
`;

export const InformationContainerTitle = styled(Typography)`
  align-items: center;
  display: flex;
  font-weight: ${props => props.theme.fontWeight.bold};
  padding: ${props => props.theme.spacing(5)};

  label {
    margin-left: ${props => props.theme.spacing(2)};
  }
`;

export const InformationContainerSubTitle = styled(Typography)`
  padding: ${props => props.theme.spacing(0)} ${props => props.theme.spacing(5)};
  font-weight: ${props => props.theme.fontWeight.bold};
  display: flex;
  align-items: center;
  justify-content: space-between;
  label {
    margin-left: ${props => props.theme.spacing(2)};
  }
`;

export const ShowHideDetailsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-content: center;
  padding: ${props => props.theme.spacing(2)};
`;

export const Link = styled(LinkComponent)`
  align-items: center;
  cursor: pointer;
  color: #206AF5;
  display: inline-flex;
  ${props =>
    props.selected &&
    `
    font-weight: ${props.theme.fontWeight.bold};
  `}
  height: 100%;
  line-height: 100%;
  position: relative;
  width: 200px;
  justify-content: center;
`;

export const DownArrow = styled(ArrowDown)`
  width: 40px;
  height: 40px;
  color: black;
`;
export const UpArrow = styled(ArrowUp)`
  width: 40px;
  height: 40px;
  color: black;
`;
