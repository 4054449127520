import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import Dialog from 'components/Dialog';
import { useTranslation } from 'hooks';
import { detailsConfig } from './config';
import Footer from './Footer';
import {
  Content,
  Description,
  Detail,
  DetailLabel,
  Details,
  DetailValue,
  SectionTitle,
  Title
} from './style';

const components = {
  Content,
  Footer
};

function OpportunityDialog({
  indexedMeta,
  opportunity,
  syncing,
  onClose,
  onInit,
  onInterest,
  onReject,
  onNotAvailable,
  ...rest
}) {
  const { t } = useTranslation();
  const closeOnUpdate = useRef(false);
  const handleReject = useCallback(() => {
    closeOnUpdate.current = true;
    onReject();
  }, [onReject]);

  const handleNotAvailable = useCallback(() => {
    closeOnUpdate.current = true;
    onNotAvailable();
  }, [onNotAvailable]);

  const footerProps = useMemo(() => {
    var showCandidateCV = false;
    switch (opportunity.candidate.status) {
      case '150':
      case '200':
      case '250':
      case '300':
      case '350':
      case '550':
      case '600':
      case '700':
      case '800':
      case '900':
      case '1000':
        showCandidateCV = true;
        break;
      default:
        showCandidateCV = false;
        break;
    }
    return {
      opportunity,
      readOnly: opportunity.candidate.status !== '100',
      showCandidateCV,
      onClose: onClose,
      onReject: handleReject,
      onNotAvailable: handleNotAvailable,
    };
  }, [opportunity, handleReject, onClose]);
  useEffect(() => {
    onInit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (closeOnUpdate.current) {
      onClose();
    }
  }, [opportunity, onClose]);

  return (
    <Dialog
      components={components}
      disabled={syncing}
      footerProps={footerProps}
      open={true}
      syncing={syncing}
      title={t('title.atreusOpportunity')}
      onAction={onInterest}
      onClose={onClose}
      {...rest}
    >
      <Title>{t('title.opportunityGeneralInfo')}</Title>
      <Details>
        {detailsConfig.map(detail => (
          <Detail key={detail.key}>
            <DetailLabel>{t(detail.label)}:</DetailLabel>
            {detail.Value ? (
              detail.Value(opportunity, indexedMeta) || '-'
            ) : (
              <DetailValue value={opportunity[detail.key]} />
            )}
          </Detail>
        ))}
      </Details>
      <Description>
        {/* @TODO remove external HTML support when the client will agree */}
        <SectionTitle>{t('title.technicalRequirement')}</SectionTitle>
        <p dangerouslySetInnerHTML={{ __html: opportunity.technicalRequirementsComments || '-' }} />
        <SectionTitle>{t('title.commentPersonal')}</SectionTitle>
        <p dangerouslySetInnerHTML={{ __html: opportunity.personalRequirementsComments || '-' }} />
        <SectionTitle>{t('title.descriptionCompany')}</SectionTitle>
        <p dangerouslySetInnerHTML={{ __html: opportunity.companySituation || '-' }} />
      </Description>
    </Dialog>
  );
}

OpportunityDialog.propTypes = {
  indexedMeta: PropTypes.object.isRequired,
  opportunity: PropTypes.object.isRequired,
  syncing: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onInit: PropTypes.func.isRequired,
  onInterest: PropTypes.func.isRequired,
  onReject: PropTypes.func.isRequired
};

export default OpportunityDialog;
