import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Route, withRouter } from 'react-router-dom';
import Editor from './Editor';

function DeleteProfile({ history, match: { url }}) {
  const handleClose = useCallback(() => history.push(url), [history, url]);
  return (
    <Route
      path={`${url}/deleteProfile/`}
      render={({ match }) => <Editor onClose={handleClose} />}
    />
  );
}

DeleteProfile.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired
};

export default withRouter(DeleteProfile);
