import styled from 'styled-components';
import { Button as BaseButton } from 'components/buttons';

export const Button = styled(BaseButton)``;

export const ActionsFooter = styled.div`
  align-items: center;
  border-top: ${props => props.disableBorder ? 'none' : `1px solid ${props.theme.color.grey[500]}`};
  box-sizing: border-box;
  display: flex;
  justify-content: flex-end;
  margin: 0 ${props => props.theme.spacing(3)};
  padding: ${props => props.theme.spacing(3)} 0;

  @media (max-width: 768px) {
    justify-content: center;
  }
  @media (max-width: 400px) {
    ${Button} {
      flex-grow: 1;
    }
  }
`;
