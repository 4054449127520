import React, { useEffect, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import { ErrorMessage, Message, TextContainer } from '../../style';
import { useTranslation } from 'hooks';
import { AttachmentContainer, FileUploadComponent, UploadButton, List, ListItem } from './style';

function Upload({
  errors,
  reports,
  billingStatus,
  html,
  isVerified,
  travelExpense,
  setReports,
  setErrors,
  showConfirmation,
  setShowConfirmation
}) {
  const { t } = useTranslation();
  const verifiedHtmlRef = useRef(null);

  const uploadMsg1Ref = useCallback(
    node => {
      if (node) node.innerHTML = t('message.uploadFileMsg1');
    },
    [t]
  );
  const uploadMsg2Ref = useCallback(
    node => {
      if (node) node.innerHTML = t('message.uploadFileMsg2');
    },
    [t]
  );
  const uploadMsg3Ref = useCallback(
    node => {
      if (node) node.innerHTML = t('message.uploadFileMsg3');
    },
    [t]
  );
  const uploadMsg4Ref = useCallback(
    node => {
      if (node) node.innerHTML = t('message.uploadFileMsg4');
    },
    [t]
  );

  useEffect(() => {
    if (verifiedHtmlRef.current) {
      verifiedHtmlRef.current.innerHTML = html;
    }
  }, [isVerified, verifiedHtmlRef]);

  const handleReportsChange = ({ target }) => {
    if (
      target.files[0] &&
      (target.files[0].type !== 'application/pdf' || target.files[0].size / 1024 / 1024 > 15)
    ) {
      setReports({ ...reports, [target.id]: null });
      setErrors({ ...errors, [target.id]: t('error.file.typeAndSize') });
    } else {
      if (target.files[0] && target.files[0].name) {
        setErrors({ ...errors, [target.id]: null });
        setReports({ ...reports, [target.id]: target.files[0] });
      }
    }
    target.id === 'expenses' && setShowConfirmation(false);
  };

  return (
    <>
      {billingStatus && billingStatus === '100' && (
        <>
          <AttachmentContainer>
            <UploadButton color="primary" label={t('button.uploadInvoice')} disabled={isVerified} />
            {errors?.invoice && <ErrorMessage>{errors.invoice}</ErrorMessage>}
            {reports?.invoice && <Message mt="5">{reports.invoice.name}</Message>}
            <FileUploadComponent
              id="invoice"
              name="invoice"
              type="file"
              disabled={isVerified}
              onChange={handleReportsChange}
            />
          </AttachmentContainer>

          {travelExpense === '200' && (
            <>
              <AttachmentContainer>
                <UploadButton
                  color="primary"
                  label={t('button.uploadExpenses')}
                  disabled={reports?.expenses && isVerified}
                />
                {errors?.expenses && <ErrorMessage>{errors.expenses}</ErrorMessage>}
                {reports?.expenses && <Message mt="5">{reports.expenses.name}</Message>}
                <FileUploadComponent
                  id="expenses"
                  name="expenses"
                  type="file"
                  disabled={reports?.expenses && isVerified}
                  onChange={handleReportsChange}
                />
              </AttachmentContainer>
              {showConfirmation && (
                <>
                  <Message mt="5" small={1}>
                    {t('message.travelExpenseConfirmation1')}
                  </Message>
                  <Message mt="-12" small={1}>
                    {t('message.travelExpenseConfirmation2')}
                  </Message>
                </>
              )}
            </>
          )}

          {!isVerified && (
            <List mt="35">
              <ListItem mt="20" ref={uploadMsg1Ref}></ListItem>
              <ListItem mt="20" ref={uploadMsg2Ref}></ListItem>
              <ListItem mt="20" ref={uploadMsg3Ref}></ListItem>
              <ListItem mt="20" ref={uploadMsg4Ref}></ListItem>
            </List>
          )}
          {isVerified && <div ref={verifiedHtmlRef}></div>}
        </>
      )}

      {billingStatus && (billingStatus === '200' || billingStatus === '250') && (
        <>
          <TextContainer>
            <Message>{t('message.submitReportMsg1')}</Message>
            <Message>{t('message.submitReportMsg2')}</Message>
          </TextContainer>
        </>
      )}

      {billingStatus && billingStatus === '300' && (
        <>
          <TextContainer>
            <Message>{t('message.finishReportMsg1')}</Message>
            <Message>{t('message.finishReportMsg2')}</Message>
          </TextContainer>
        </>
      )}
    </>
  );
}

Upload.prototypes = {
  billingStatus: PropTypes.string,
  isAborted: PropTypes.bool,
  html: PropTypes.string,
  isVerified: PropTypes.bool,
  errors: PropTypes.object,
  reports: PropTypes.object,
  travelExpense: PropTypes.string,
  setReports: PropTypes.func,
  setErrors: PropTypes.func,
  showConfirmation: PropTypes.bool,
  setShowConfirmation: PropTypes.func
};

export default Upload;
