import * as types from './types';

export function failMngCandidateCVLoad(error) {
  return {
    payload: {
      error
    },
    type: types.MNG_CANDIDATE_CV_FAILED
  };
}
export function loadMngCandidateCV(candidateId) {
  return {
    payload: {
      candidateId
    },
    type: types.LOAD_MNG_CANDIDATE_CV
  };
}

export function setLoadedMngCandidateCV(candidate) {
  return {
    payload: candidate,
    type: types.MNG_CANDIDATE_CV_LOADED
  };
}

export function failMngCVLoad(error) {
  return {
    payload: {
      error
    },
    type: types.MNG_CV_FAILED
  };
}
export function loadMngCV(managerId) {
  return {
    payload: {
      managerId
    },
    type: types.LOAD_MNG_CV
  };
}

export function setLoadedMngCV(candidate) {
  return {
    payload: candidate,
    type: types.MNG_CV_LOADED
  };
}

export function resetSyncing() {
  return {
    type: types.RESET_SYNCING
  };
}
