import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import Title from 'components/Title';
import {MultiLanguageTextField, SelectField, TextField} from 'components/inputs';
import { FormContainer, FormItem, FormRow } from 'components/styledComponents';
import {useLanguagesPaths, useTranslation} from 'hooks';
import DropdownTreeSelect from 'react-dropdown-tree-select'
import 'react-dropdown-tree-select/dist/styles.css'
import 'components/pages/ResumePage/sections/MultiTreeDropdown.css'
import $ from 'jquery'

function CompanyInformation({
  className,
  language,
  meta: {
    companySituations,
    companyTurnoverList,
    countries,
    employees,
    industries,
    ownershipStructures
  },
  useInput,
  useTree
}) {
  const { tr } = useTranslation();
    useEffect(() => {
        $('div#industry a#industry_trigger').attr('data-industry-translated', tr('label.industry'));
    },[]);

  return (
    <FormContainer className={className}>
      <FormRow>
        <FormItem component={Title} label={tr('label.companyInformation')} size="large" />
      </FormRow>
        <FormRow>
            <FormItem
                component={TextField}
                label={tr('label.companyName')}
                required
                {...useInput('companyName')}
            />
            <FormItem
                component={DropdownTreeSelect}
                mode="radioSelect"
                keepTreeOnSearch={true}
                className="mdl-demo"
                texts={{ placeholder: ' ' }}
                label={tr('label.industry')}
                {...useTree('industry', industries)}
            />
        </FormRow>
      <FormRow>
          <FormItem
              component={MultiLanguageTextField}
              label={tr('label.industryDescription')}
              language={language}
              {...useInput('industryDescription', {
                  alternateFields: useLanguagesPaths('industryDescription')
              })}
          />
      </FormRow>
      <FormRow>
        <FormItem component={MultiLanguageTextField} label={tr('label.city')} language={language} {...useInput('city', {
            alternateFields: useLanguagesPaths('city')
        })} />
        <FormItem
          component={SelectField}
          label={tr('label.country')}
          options={countries}
          {...useInput('country')}
        />
      </FormRow>
      <FormRow>
        <FormItem
          component={SelectField}
          label={tr('label.ownershipStructure')}
          options={ownershipStructures}
          {...useInput('ownershipStructure')}
        />
          <FormItem
              component={SelectField}
              label={tr('label.numberOfEmployees')}
              options={employees}
              {...useInput('companyEmployees')}
          />
      </FormRow>
        <FormRow>
            <FormItem
                component={SelectField}
                label={tr('label.turnoverInMillion')}
                options={companyTurnoverList}
                {...useInput('companyTurnover')}
            />
            <FormItem />
        </FormRow>
    </FormContainer>
  );
}

CompanyInformation.propTypes = {
  className: PropTypes.string,
  language: PropTypes.string.isRequired,
  meta: PropTypes.shape({
    companySituations: PropTypes.array.isRequired,
    companyTurnoverList: PropTypes.array.isRequired,
    countries: PropTypes.array.isRequired,
    employees: PropTypes.array.isRequired,
    industries: PropTypes.array.isRequired,
    ownershipStructures: PropTypes.array.isRequired
  }).isRequired,
  useInput: PropTypes.func.isRequired
};

export default CompanyInformation;
