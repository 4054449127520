import { call, put, select, takeLeading } from 'redux-saga/effects';
import apiClient from 'communication/apiClient';
import * as actions from './actions';
import * as selectors from './selectors';
import * as types from './types';
import * as uiStateSelectors from '../uiState/selectors';
import moment from "moment";

export function* loadNDA() {
  const nda = yield select(selectors.selectNDA);
  if (nda) {
    return;
  }

  try {
    const language = yield select(uiStateSelectors.selectLanguage);
    const resp = yield call(apiClient.get, `/api/v1/manager/nda/${language}?timestamp=`+new Date().getTime());
    if (resp && resp.data) {
      yield put(actions.setLoadedNDA(resp.data, language));
    } else {
      yield put(actions.failOpportunitiesLoad(resp));
    }
  } catch (error) {
    yield put(actions.failOpportunitiesLoad('error'));
  }
}

export function* loadOpportunities() {
  try {
    const resp = yield call(apiClient.get, '/api/v1/manager/opportunities');
    if (resp && resp.data) {
      yield put(actions.setLoadedOpportunities(resp.data));
    } else {
      yield put(actions.failOpportunitiesLoad(resp));
    }
  } catch (error) {
    yield put(actions.failOpportunitiesLoad('error'));
  }
}

export function* loadOpportunity(actionData) {
  try {
    const resp = yield call(
      apiClient.get,
      `/api/v1/manager/opportunities/${actionData.payload.opportunity.id}`
    );
    if (resp && resp.data) {
      yield put(actions.setLoadedOpportunity(resp.data));
    } else {
      yield put(actions.failOpportunitySync(resp));
    }
  } catch (error) {
    yield put(actions.failOpportunitySync('error'));
  }
}

export function* acceptNDA(actionData) {
  try {
    const resp = yield call(
      apiClient.post,
      `/api/v1/manager/opportunities/${actionData.payload.opportunity.id}/accept-nda`
    );
    if (resp && resp.data) {
      yield put(actions.setAcceptedNDA(resp.data));
    } else {
      yield put(actions.failOpportunitySync(resp));
    }
  } catch (error) {
    yield put(actions.failOpportunitySync('error'));
  }
}

export function* acceptOpportunity(actionData) {
  const { opportunity, remarks, timestamp } = actionData.payload;

  try {
    const resp = yield call(
      apiClient.post,
      `/api/v1/manager/opportunities/${opportunity.id}/interested`,
      { 
        application: remarks,
        timestamp: moment(timestamp).format("YYYY-MM-DD")
      }
    );
    if (resp && resp.data) {
      yield put(actions.setAcceptedOpportunity(resp.data));
    } else {
      yield put(actions.failOpportunitySync(resp));
    }
  } catch (error) {
    yield put(actions.failOpportunitySync('error'));
  }
}

export function* rejectOpportunity(actionData) {
  try {
    const { timestamp } = actionData.payload;
    const resp = yield call(
      apiClient.post,
      `/api/v1/manager/opportunities/${actionData.payload.opportunity.id}/not-interested`,
        {
          timestamp: moment(timestamp).format("YYYY-MM-DD")
        }
    );
    if (resp && resp.data) {
      yield put(actions.setRejectedOpportunity(resp.data));
    } else {
      yield put(actions.failOpportunitySync(resp));
    }
  } catch (error) {
    yield put(actions.failOpportunitySync('error'));
  }
}

export function* notAvailableForOpportunity(actionData) {
  try {
    const { timestamp } = actionData.payload;
    const resp = yield call(
        apiClient.post,
        `/api/v1/manager/opportunities/${actionData.payload.opportunity.id}/not-available`,
        {
          timestamp: moment(timestamp).format("YYYY-MM-DD")
        }
    );
    if (resp && resp.data) {
      yield put(actions.setNotAvailableOpportunity(resp.data));
    } else {
      yield put(actions.failOpportunitySync(resp));
    }
  } catch (error) {
    yield put(actions.failOpportunitySync('error'));
  }
}

export default [
  takeLeading(types.LOAD_NDA, loadNDA),
  takeLeading(types.LOAD_OPPORTUNITIES, loadOpportunities),
  takeLeading(types.LOAD_OPPORTUNITY, loadOpportunity),
  takeLeading(types.ACCEPT_NDA, acceptNDA),
  takeLeading(types.ACCEPT_OPPORTUNITY, acceptOpportunity),
  takeLeading(types.REJECT_OPPORTUNITY, rejectOpportunity),
  takeLeading(types.NOT_AVAILABLE_FOR_OPPORTUNITY, notAvailableForOpportunity)
];
