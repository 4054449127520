import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { EditorState } from 'draft-js';
import { stateFromHTML } from 'draft-js-import-html';
import {EditorBody, EditorContainer, EditorHeader } from './style';
import 'draft-js/dist/Draft.css';
import { Editor } from '@tinymce/tinymce-react';

function RichText({ className, headerComponent, label, updateState, value, onChange, beforeChange, handleLanguageChange, lang, ...rest } = {beforeChange: (nextState, prevState) => nextState}) {
  const [editorState, setState] = useState(
    EditorState.createWithContent(stateFromHTML(value))
  );

  // Hide placeholder if the user changes block type before entering any text
  let hidePlaceholder = false;
  var contentState = editorState.getCurrentContent();
  if (!contentState.hasText()) {
    if (contentState.getBlockMap().first().getType() !== 'unstyled') {
      hidePlaceholder = true;
    }
  }
  //initializing tinymce editor
  const editorRef = useRef(null);

  //function to update tinymce editor value
  const log = () => {
  if (editorRef.current) onChange(editorRef.current.getContent(), 'tinymce');
  };
  //definig tinymce configurations
  const tinyMceConfig = {
    
    menubar: false,
    plugins: [
    'autolink lists link image charmap print preview anchor',
    'searchreplace visualblocks code fullscreen',
    'insertdatetime media table paste autoresize'
    ],
    valid_elements : 'li,br,p,ul',
    valid_children : "-li[ul]",
    content_style:`
    body {  
      font-family: Roboto,Helvetica Neue,Helvetica,sans-serif;
      vertical-align: left;
      color: #444444;
    }`,
    toolbar: 'bullist outdent indent',
    branding: false,
    elementpath: false,
    statusbar: false
  };

  return (
    <EditorContainer key={lang} className={className} {...rest}>
      <EditorHeader data-test-id="rich-editor-header">
      {headerComponent}   
      </EditorHeader>
      <EditorBody
        data-js-id="rich-editor-body"
        hidePlaceholder={hidePlaceholder}
      >
        <Editor
         onInit={(evt, editor) => {editorRef.current = editor;}}
         initialValue= {value}
         onEditorChange={log}
          init={tinyMceConfig}
          {...rest}
         />
      </EditorBody>
    </EditorContainer>
  );
}

RichText.propTypes = {
  className: PropTypes.string,
  headerComponent: PropTypes.oneOfType([PropTypes.func, PropTypes.node, PropTypes.object]),
  label: PropTypes.string,
  updateState: PropTypes.bool,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  lang: PropTypes.string.isRequired,
  handleLanguageChange: PropTypes.func,
  beforeChange: PropTypes.func
};

export default React.memo(RichText);
