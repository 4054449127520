import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Detail } from './style';

function Details({ indexedMeta: { languageLevels, languages }, language }) {
  const languageName = useMemo(() => languages[language.languageCode], [language, languages]);
  const languageLevel = useMemo(() => languageLevels[language.languageLevelCode], [
    language,
    languageLevels
  ]);

  if (languageName && languageLevel) {
    return (
      <Detail key={language.id}>
        <em>{languageName}</em>, {languageLevel}
      </Detail>
    );
  }

  return null;
}

Details.propTypes = {
  indexedMeta: PropTypes.shape({
    languageLevels: PropTypes.object.isRequired,
    languages: PropTypes.object.isRequired
  }).isRequired,
  language: PropTypes.object.isRequired
};

export default Details;
