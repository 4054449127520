import React from 'react';
import PropTypes from 'prop-types';
import { StatusContainer, Baseline, Ball, LabelContainer, Label, MobileStatus } from './style';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { CheckMark } from 'components/icons';

function ProjectStatus({ items, selectedIndex }) {
  const mediumScreen = useMediaQuery('@media (min-width: 800px)');
  selectedIndex = Math.floor(selectedIndex);
  return (
    <StatusContainer>
      {mediumScreen ? (
        <React.Fragment>
          <Baseline itemsCount={items.length} selectedIndex={selectedIndex}>
            {items.map((item, index) => (
              <Ball
                active={index <= selectedIndex}
                index={index}
                itemsCount={items.length}
                key={index}
              />
            ))}
          </Baseline>
          <LabelContainer>
            {items.map((item, index) => (
              <Label
                active={index < selectedIndex}
                current={index === selectedIndex}
                inactive={index > selectedIndex}
                index={index}
                itemsCount={items.length}
                key={index}
              >
                {item}
              </Label>
            ))}
          </LabelContainer>
        </React.Fragment>
      ) : (
        <MobileStatus>
          <CheckMark />
          {selectedIndex > 0 ? items[selectedIndex] : items[0]}
        </MobileStatus>
      )}
    </StatusContainer>
  );
}

ProjectStatus.propTypes = {
  items: PropTypes.array.isRequired,
  selectedIndex: PropTypes.number
};

export default ProjectStatus;
