import styled from 'styled-components';

export const StatusContainer = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
`;

export const Baseline = styled.div`
  margin-top: ${props => props.theme.spacing(5)};
  height: ${props => props.theme.spacing(1)};
  background: ${props => props.theme.color.background.default};
  width: 100%;
  display: flex;
  position: relative;
  &:after {
    content: '';
    position: absolute;
    background: ${props => props.theme.color.success.main};
    width: ${props => (100 / (props.itemsCount - 1)) * props.selectedIndex + '%'};
    bottom: 0px;
    height: ${props => props.theme.spacing(1)};
    display: block;
    left: 0px;
  }
`;

export const Ball = styled.label`
  background: ${props =>
    props.active ? props.theme.color.success.main : props.theme.color.background.default};
  height: ${props => props.theme.spacing(3)};
  width: ${props => props.theme.spacing(3)};
  border-radius: ${props => props.theme.spacing(1.5)};
  position: absolute;
  top: ${props => props.theme.spacing(-1)};
  left: ${props => (100 / (props.itemsCount - 1)) * props.index + '%'};
  transform: ${props => 'translate(' + props.theme.spacing(-1.5) + ', 0)'};
`;

export const LabelContainer = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  margin-top: ${props => props.theme.spacing(3)};
`;

export const Label = styled.label`
  font-weight: ${props => props.theme.fontWeight[props.current ? 'bold' : 'normal']};
  text-align: center;
  width: ${props => 100 / (props.itemsCount - 2) + '%'};
  color: ${props =>
    props.active || props.current ? props.theme.color.grey['800'] : props.theme.color.grey['400']};
  &:first-child {
    width: ${props => (100 / (props.itemsCount - 1)) * 0.5 + '%'};
    text-align: left;
  }
  &:last-child {
    width: ${props => (100 / (props.itemsCount - 1)) * 0.5 + '%'};
    position: relative;
    left: ${props => props.theme.spacing(1.5)};
    text-align: right;
  }
`;

export const MobileStatus = styled.div`
  margin: ${props => props.theme.spacing(3)}
  display:flex;
  flex-direction:row;
  align-content:center;
  align-items:center;
  div{
    margin-right: ${props => props.theme.spacing(1)}
  }
`;
