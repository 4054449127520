import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation as useI18Translation } from 'react-i18next';
import * as uiStateSelectors from 'redux-modules/uiState/selectors';

function useTranslation() {
  const language = useSelector(uiStateSelectors.selectLanguage);
  const resumeLanguage = useSelector(uiStateSelectors.selectResumeLanguage);

  const { i18n, ...rest } = useI18Translation();
  const tr = useMemo(() => i18n.getFixedT(resumeLanguage), [i18n, resumeLanguage]);
  useEffect(() => {
    i18n.changeLanguage(language);
  }, [i18n, language]);

  return {
    i18n,
    tr,
    ...rest
  };
}

export default useTranslation;
