import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Calendar, useUtils } from '@material-ui/pickers';
import Day from '../Day';
import { getDayColors } from './utils';

const today = new Date();

function MuiCalendar({
  date,
  disabled,
  disableFuture,
  getDayColors,
  maxDate,
  minDate,
  onClick,
  onDayClick,
  disablePointer,
  disableMouseControls,
  ...rest
}) {
  const utils = useUtils();
  const shouldDisableDate = useCallback(
    (day, minDate, maxDate) => {
      return (
        disabled ||
        (disableFuture && utils.isAfterDay(day, today)) ||
        (maxDate && utils.isAfterDay(day, maxDate)) ||
        (minDate && utils.isBeforeDay(day, minDate)) ||
        day.getMonth() !== date.getMonth()
      );
    },
    [disabled, disableFuture, utils, date]
  );
  const handleChange = useCallback(
    (day, isFinish) => {
      if (isFinish) onClick && onClick(day);
    },
    [onClick]
  );

  const handleSetLastTarget = useCallback(
    (event, day) => {
      onDayClick && onDayClick(day, event.target);
    },
    [onDayClick]
  );

  return (
    <Calendar
      date={date}
      disableFuture={disabled || disableFuture}
      disablePast={disabled}
      maxDate={maxDate}
      minDate={minDate}
      renderDay={day => {
        return (
          <Day
            disabled={shouldDisableDate(day, minDate, maxDate)}
            {...getDayColors(day, utils)}
            onClick={event => handleSetLastTarget(event, day)}
            // RTEXT-690
            disablePointer={disablePointer}
          >
            {utils.getDayText(day)}
          </Day>
        );
      }}
      onChange={handleChange}
      // RTEXT-690
      allowKeyboardControl={!disableMouseControls}
      {...rest}
    />
  );
}

MuiCalendar.defaultProps = {
  date: today,
  format: 'dd-MM-yyyy',
  getDayColors
};

MuiCalendar.propTypes = {
  date: PropTypes.instanceOf(Date).isRequired,
  disabled: PropTypes.bool,
  disableFuture: PropTypes.bool,
  format: PropTypes.string.isRequired,
  getDayColors: PropTypes.func.isRequired,
  maxDate: PropTypes.object,
  minDate: PropTypes.object,
  onClick: PropTypes.func,
  onDayClick: PropTypes.func,
  disablePointer: PropTypes.bool,
  disableMouseControls: PropTypes.bool,
};

export default MuiCalendar;
