import React from 'react';
import PropTypes from 'prop-types';
import { CustomDateField, SelectField, TextField } from 'components/inputs';
import Title from 'components/Title';
import { FormContainer, FormItem, FormRow, FormSection } from 'components/styledComponents';
import { useTranslation } from 'hooks';

function PersonalInformation({
  className,
  meta: { titles, nationalities, salutations },
  useInput,
}) {
  const { t } = useTranslation();

  return (
    <FormContainer className={className}>
      <FormSection divider="bottom" padding="4 0">
        <Title label={t('title.personalInformation')} size="large" />
      </FormSection>
      <FormSection padding="3 0">
        <FormRow>
          <FormItem
            component={TextField}
            label={t('label.firstName')}
            required
            {...useInput('firstName')}
          />
          <FormItem
            component={TextField}
            label={t('label.lastName')}
            required
            {...useInput('lastName')}
          />
        </FormRow>
        <FormRow>
          <FormItem
            component={SelectField}
            label={t('label.salutation')}
            options={salutations}
            required
            {...useInput('salutation')}
          />
          <FormItem
            component={SelectField}
            label={t('label.title')}
            options={titles}
            {...useInput('title')}
          />
        </FormRow>
        <FormRow>
          <FormItem
            cancelLabel={t('button.cancel')}
            component={CustomDateField}
            disableFuture={true}
            doneLabel={t('button.done')}
            label={t('label.dateOfBirth')}
            selectDateLabel={t('label.selectDate')}
            selectMonthLabel={t('label.selectMonth')}
            wizardMode={true}
            {...useInput('birthDate')}
          />
          <FormItem
            component={SelectField}
            isMulti={true}
            label={t('label.nationality')}
            options={nationalities}
            {...useInput('nationality')}
          />
        </FormRow>
      </FormSection>
    </FormContainer>
  );
}

PersonalInformation.propTypes = {
  className: PropTypes.string,
  meta: PropTypes.shape({
    nationalities: PropTypes.array.isRequired,
    salutations: PropTypes.array.isRequired,
    titles: PropTypes.array.isRequired
  }).isRequired,
  useInput: PropTypes.func.isRequired
};

export default PersonalInformation;
