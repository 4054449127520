import React, {useEffect, useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import { default as MuiCollapse } from '@material-ui/core/Collapse';
import { ArrowLeft, ArrowRight } from 'components/icons';
import { useTranslation } from 'hooks';
import { Button, ContentWrapper, Label, ToggleIcon, TogglerContainer } from './style';

function getLabelData(hiddenItems, showNumber, isExpanded) {
  if (showNumber) {
    return [
      isExpanded ? 'button.countedHide' : 'button.countedViewMore',
      { count: hiddenItems.length }
    ];
  }
  return [isExpanded ? 'button.hideDetails' : 'button.showDetails'];
}
/**
 * @TODO replace MuiCollapse with a different animation which is not based on height
 * in order to avoid chrome shacking behavior when the scroll is at the bottom of the page
 */
function Collapse({ children, open, togglerPosition, isWe, visible, onToggle, expandCollapse }) {
  const { t, tr } = useTranslation();
  const [expanded, setExpanded] = useState(false);
  function handleToggle() {
    setExpanded(!expanded);
  }
  useEffect(() => {
      setExpanded(expandCollapse);
  }, [expandCollapse]);
  const { hiddenItems, visibleItems } = useMemo(() => {
    const childrenList = React.Children.toArray(children);
    return {
      hiddenItems: childrenList.slice(visible, childrenList.length),
      visibleItems: childrenList.slice(0, visible)
    };
  }, [children, visible]);
  const isExpanded = useMemo(() => expanded || open, [expanded, open]);

  return (
    <React.Fragment>
      {visibleItems.map(item => item)}
      <ContentWrapper togglerPosition={togglerPosition}>
        <MuiCollapse in={isExpanded} timeout="auto" unmountOnExit>
          {hiddenItems.map(item => item)}
        </MuiCollapse>

        {!!hiddenItems.length && (
          <TogglerContainer>
            <Button onClick={handleToggle}>
              <Label>{tr(...getLabelData(hiddenItems, visible > 1, isExpanded))}</Label>
              <ToggleIcon as={isExpanded ? ArrowRight : ArrowLeft} />
            </Button>
          </TogglerContainer>
        )}
      </ContentWrapper>
    </React.Fragment>
  );
}

Collapse.defaultProps = {
  visible: 3
};

Collapse.propTypes = {
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.node, PropTypes.object]),
  open: PropTypes.bool,
  togglerPosition: PropTypes.oneOf(['top']),
  isWe: PropTypes.bool,
  visible: PropTypes.number.isRequired,
  onToggle: PropTypes.func
};

export default Collapse;
