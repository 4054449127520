import { connect } from 'react-redux';
import * as uiStateActions from 'redux-modules/uiState/actions';
import * as uiStateSelectors from 'redux-modules/uiState/selectors';
import LanguageSelector from './LanguageSelector';

function mapStateToProps(state) {
  return {
    selectedLanguage: uiStateSelectors.selectResumeLanguage(state)
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onChange: (data, failCallback) =>
      dispatch(uiStateActions.changeResumeLanguage(data, failCallback))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(LanguageSelector);
