import styled from 'styled-components';

export const Label = styled.span`
  ${props => props.checked && `color: ${props.theme.color.primary.main};`}
`;

export const SwitchBox = styled.div`
  background-color: ${props => props.theme.color.grey[200]};
  border: 1px solid ${props => props.theme.color.grey[500]};
  box-sizing: border-box;
  border-radius: 0.75em;
  display: flex;
  height: 1.5em;
  padding: 0.125em;
  width: 2.75em;
`;

export const WrappingLabel = styled.label`
  align-items: center;
  display: flex;
  font-size: 1em;

  :hover {
    cursor: pointer;
  }

  ${Label} + ${SwitchBox}, ${SwitchBox} + ${Label} {
    margin-left: 0.5em;
  }
  ${props =>
    props.disabled &&
    `
      &, ${Label} {
        color: ${props.theme.color.disabled.text};
      }
      :hover {
        cursor: default;
      }
    `}
`;

export const SwitchHandle = styled.div`
  background: ${props => props.theme.color.primary.contrast};
  box-shadow: ${props => props.theme.boxShadow.base};
  border-radius: 50%;
  height: 1.125em;
  transition: all ${props => props.theme.animation.standard};
  transition-property: transform;
  width: 1.125em;
`;

export const StyledSwitch = styled.input.attrs({
  type: 'checkbox'
})`
  display: none;
  :checked {
    & ~ ${SwitchBox} {
      ${props => `
        background: ${props.theme.color.primary.gradient};
        border-color: transparent;
      `}

      ${SwitchHandle} {
        transform: translateX(1.25em);
      }
    }
  }
  :disabled {
    & ~ ${SwitchBox} {
      background: ${props => props.theme.color.grey[400]};
      border-color: transparent;

      ${SwitchHandle} {
        box-shadow: none;
      }
    }
  }
`;
