import React from 'react';
import PropTypes from 'prop-types';
import {
  CandidateCardContainer,
  CandidateEmptyRowPlaceholder,
  CandidateEmptyRow,
  Link
} from './style';
import Typography from '@material-ui/core/Typography';

function CandidateCard({ candidate, synching }) {
  return (
    <CandidateCardContainer>
      {synching && (
        <React.Fragment>
          <CandidateEmptyRowPlaceholder long />
          <CandidateEmptyRowPlaceholder short />
          <CandidateEmptyRowPlaceholder long />
          <CandidateEmptyRowPlaceholder short />
          <CandidateEmptyRow />
          <CandidateEmptyRow />
        </React.Fragment>
      )}
      {candidate && (
        <React.Fragment>
          <CandidateEmptyRow longest>
            <Typography component="h6" variant="h6">
              {candidate.name}
            </Typography>
          </CandidateEmptyRow>
          <CandidateEmptyRow long>
            <Typography component="p" variant="caption">
              {candidate.city} ({candidate.country})
            </Typography>
          </CandidateEmptyRow>
          <CandidateEmptyRow long />
          <CandidateEmptyRow longest>{candidate.availibility}</CandidateEmptyRow>
          <CandidateEmptyRow long>
            <Link to="">VIEW DETAILS</Link>
          </CandidateEmptyRow>
        </React.Fragment>
      )}
    </CandidateCardContainer>
  );
}

CandidateCard.propTypes = {
  candidate: PropTypes.object,
  synching: PropTypes.bool
};

export default CandidateCard;
