export const GET_REGISTER_DATA = 'get_register_data';
export const LOGIN_FAILED = 'login_has_failed';
export const LOGIN_INITIATED = 'login_is_initiated';
export const LOGIN_SUCCESSFUL = 'login_was_successful';
export const LOGOUT = 'user_logout';
export const REGISTER = 'user_register';
export const REGISTER_PORTAL_USER = 'register_portal_user';
export const REGISTER_PORTAL_USER_SUCCESS = 'register_portal_user_success';
export const REGISTER_PORTAL_USER_FAILED = 'register_portal_user_failed';
export const CHECK_EMAIL = 'check_email';
export const CHECK_EMAIL_DUPLICATE = 'check_email_duplicate';
export const CHECK_EMAIL_SUCCESS = 'check_email_success';
export const CHECK_EMAIL_FAILED = 'check_email_failed';
export const REGISTER_FAILED = 'register_has_failed';
export const RESET_PWD_FAILED = 'reset_pwd_failed';
export const RESET_PWD_INITIATED = 'reset_pwd_initiated';
export const RESET_PWD_SUCCESSFUL = 'reset_pwd_successfull';
export const RESET_PWD_RESET_STATUS = 'reset_pwd_reset_status';
export const SET_REGISTER_DATA = 'set_register_data';
export const UPDATE_PWD_FAILED = 'update_pwd_failed';
export const UPDATE_PWD_INITIATED = 'update_pwd_initiated';
export const UPDATE_PWD_SUCCESSFUL = 'update_pwd_successfull';
export const VALIDATE_PWD_RESET_TOKEN = 'validate_pwd_reset_token';
export const VALIDATE_PWD_RESET_TOKEN_SUCCESSFUL = 'validate_pwd_reset_token_successfull';
export const VALIDATE_PWD_RESET_TOKEN_FAILED = 'validate_pwd_reset_token_failed';
export const AGREE_TERMS = 'agree_terms';
export const AGREEMENT_SUCCESS = 'agreement_success';
export const SKIP_AGREEMENT = 'skip_agreement';
export const LOAD_NDA1 = 'load_nda1';
export const NDA_LOADED1 = 'set_loaded_nda1';
export const LOAD_NDA2 = 'load_nda2';
export const NDA_LOADED2 = 'set_loaded_nda2';
export const REQUEST_NEW_LINK = 'request_new_link';
export const REQUEST_NEW_LINK_SUCCESS = 'request_new_link_success';
