import React, { useMemo } from 'react';
import { Button } from 'components/buttons';
import Link from 'components/Link';
import StatusText from "./StatusText";

export function getTableColumns(t, handleView) {
  return [
    {
      id: 'name',
      Header: t('columnTitle.placementName'),
      minWidth: '150px',
      width: '35%'
    },
    {
      // eslint-disable-next-line react/display-name

      Cell: data => <StatusText statusCode={data.salesStage} />,
      Header: t('columnTitle.status'),
      id: 'status',
      minWidth: '150px',
      width: '25%'
    },
    {
      Header: t('columnTitle.contactName'),
      id: 'contactName',
      minWidth: '150px',
      width: '20%'
    },
    {
      Header: t('columnTitle.contactPhone'),
      id: 'contactPhone',
      minWidth: '150px',
      width: '20%'
    },
    {
      Header: t('columnTitle.contactEmail'),
      id: 'contactEmail',
      minWidth: '150px',
      width: '20%'
    },
    {
      align: 'right',
      // eslint-disable-next-line react/display-name
      // RTEXT-684
      // flag - additional parameter for displaying button based on condition
      Cell: (data, flag) => {
        return (
          flag && <Button
            color="primary"
            component={Link}
            label={t('button.viewDetails')}
            to={`/placement/${data.id}`}
            variant="outlined"
            onClick={() => handleView(data)}
          />
        );
      },
      id: 'actions',
      Header: '',
      minWidth: '150px',
      width: '20%'
    }
  ];
}
