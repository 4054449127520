import styled from 'styled-components';
import { default as BaseDetailValue } from './DetailValue';

export const Content = styled.div`
  overflow-y: auto;
`;

export const Description = styled.div`
  font-weight: ${props => props.theme.fontWeight.light};
  padding: ${props => props.theme.spacing(1, 3, 4)};
`;

export const DetailLabel = styled.div`
  margin-bottom: ${props => props.theme.spacing(1)};
  white-space: initial;
`;

export const DetailValue = styled(BaseDetailValue)`
  font-weight: ${props => props.theme.fontWeight.bold};
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Detail = styled.div`
  box-sizing: border-box;
  font-size: ${props => props.theme.fontSize.small};
  overflow: hidden;
  padding: ${props => props.theme.spacing(2, 3)};

  ${DetailValue} {
    margin-top: ${props => props.theme.spacing(1)};
  }
`;

export const Details = styled.div`
  display: flex;
  flex-wrap: wrap;

  ${Detail} {
    background-color: ${props => props.theme.color.grey[100]};

    @media (min-width: 851px) {
      flex: 1 1 33%;
      :nth-child(6n),
      :nth-child(6n-1),
      :nth-child(6n-2) {
        background-color: ${props => props.theme.color.grey[300]};
      }
    }
    @media (max-width: 850px) and (min-width: 401px) {
      flex: 1 1 50%;
      :nth-child(4n),
      :nth-child(4n-1) {
        background-color: ${props => props.theme.color.grey[300]};
      }
    }
    @media (max-width: 400px) {
      flex: 1 1 100%;
      :nth-child(even) {
        background-color: ${props => props.theme.color.grey[300]};
      }
    }
  }
`;

export const Title = styled.h3`
  font-size: ${props => props.theme.fontSize.xlarge};
  font-weight: ${props => props.theme.fontWeight.light};
  margin: ${props => props.theme.spacing(3)};
`;

export const SectionTitle = styled.h3`
  font-size: ${props => props.theme.fontSize.large};
  font-weight: ${props => props.theme.fontWeight.light};
  margin: ${props => props.theme.spacing(3, 0, 2)};
  text-transform: uppercase;
`;
