import { connect } from 'react-redux';
import * as metaSelectors from 'redux-modules/meta/selectors';
import * as placementSelectors from 'redux-modules/placements/selectors';
import * as placementsActions from 'redux-modules/placements/actions';
import * as uiStateSelectors from 'redux-modules/uiState/selectors';
import TimeTracking from './TimeTracking';

function mapStateToProps(state) {
  return {
    meta: metaSelectors.selectMeta(state),
    language: uiStateSelectors.selectLanguage(state),
    syncErrors: placementSelectors.selectSyncError(state)
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onSaveTimetracking: (id, timetracking, month, year) => {
      dispatch(placementsActions.saveTimetracking(id, timetracking, month, year));
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(TimeTracking);
