import { combineReducers } from 'redux';
import auth from './auth';
import meta from './meta';
import news from './news';
import offers from './offers';
import opportunities from './opportunities';
import resumes from './resumes';
import placements from './placements';
import projects from './projects';
import resume from './resume';
import uiState from './uiState';
import fieldsMeta from './fieldsMeta';
import billing from './billing';
import documents from './documents';

const rootReducer = combineReducers({
  auth,
  meta,
  news,
  offers,
  opportunities,
  placements,
  projects,
  resume,
  resumes,
  uiState,
  fieldsMeta,
  billing,
  documents
});

export default rootReducer;
