import localStorage from 'local-storage-fallback';

function initRequest({ headers: requestHeaders, ...requestOptions }) {
  const authToken = localStorage.getItem('authToken');
  const headers = new Headers(requestHeaders);

  if (authToken) {
    headers.append('Authorization', `Bearer ${authToken}`);
  }
  /**
   * ensure proxy https redirect is not blocked
   */
  if (window.location.protocol.indexOf('https') !== -1) {
    headers.append('X-Forwarded-Proto', 'https');
    headers.append('X-Forwarded-Port', '443');
  }

  return Object.assign(
    {
      method: 'GET',
      headers,
      credentials: 'same-origin',
      mode: 'cors'
    },
    requestOptions || {}
  );
}

export default initRequest;
