import { connect } from 'react-redux';
import * as authActions from 'redux-modules/auth/actions';
import * as resumeSelectors from 'redux-modules/resume/selectors';
import * as metaSelectors from 'redux-modules/meta/selectors';
import TopRightMenu from './TopRightMenu';

function mapStateToProps(state) {
  return {
    indexedMeta: metaSelectors.selectIndexedMeta(state),
    personalDetails: resumeSelectors.selectPersonalDetails(state),
    syncErrors: resumeSelectors.selectSyncError(state),
    syncing: resumeSelectors.selectSyncState(state)
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onLogout: () => dispatch(authActions.logout())
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(TopRightMenu);
