import { connect } from 'react-redux';
import * as uiStateSelectors from 'redux-modules/uiState/selectors';
import * as placementsActions from 'redux-modules/placements/actions';
import MonthSelector from './MonthSelector';

function mapStateToProps(state, ownProps) {
  return {
    language: uiStateSelectors.selectLanguage(state)
  };
}

function mapDispatchToProps(dispatch) {
  return {
    loadPlacementOverviewMonth: (id, year, month) => {
      dispatch(placementsActions.loadPlacementOverviewMonth(id, year, month));
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(MonthSelector);
