import React from 'react';
import PropTypes from 'prop-types';
import Link from 'components/Link';
import { Button } from 'components/buttons';
import { Card } from 'components/cards';
import { Loader } from 'components/styledComponents';
import { useDateFormat, useTranslation } from 'hooks';
import { ActionsFooter, Content, Description, Summary, Title, Value } from './style';

function Resume({ className, loading, resume: { lastModified }, ...rest }) {
  const { t } = useTranslation();

  return (
    <Loader
      className={className}
      component={Card}
      loading={loading}
      title={t('title.myResume')}
      {...rest}
    >
      <Content>
        <Summary>
          <Title>{t('label.lastCVUpdate')}</Title>
          <Value>{useDateFormat(lastModified) || '-'}</Value>
        </Summary>
        <Description>
          {t('message.fillOutDetails')}
        </Description>
        <ActionsFooter>
          <Button
            color="primary"
            component={Link}
            label={`+ ${t('button.startWizardNow')}`}
            size="small"
            to="/resume"
            variant="text"
          />
        </ActionsFooter>
      </Content>
    </Loader>
  );
}

Resume.propTypes = {
  className: PropTypes.string,
  loading: PropTypes.bool.isRequired,
  resume: PropTypes.shape({
    lastModified: PropTypes.string
  }).isRequired
};

export default Resume;
